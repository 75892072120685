import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { InfoModalDataInterface, ModalType } from '@simOn/common/info-modal/model';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimIconModule, SimIconType } from '@simOn/ui/sim-icon';
import { SimModalModule } from '@simOn/ui/sim-modal';

@Component({
  templateUrl: './sim-info-modal.component.html',
  styleUrls: ['./sim-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SimIconModule, SimButtonModule, RouterModule, SimModalModule]
})
export class SimInfoModalComponent {
  private readonly dialogRef = inject<MatDialogRef<SimInfoModalComponent>>(MatDialogRef<SimInfoModalComponent>);
  public readonly data = inject<InfoModalDataInterface>(MAT_DIALOG_DATA);
  readonly modalContent: Record<ModalType, { image: string; icon: SimIconType; iconColor: string }> = {
    Error: { image: 'assets/images/subscription/lack_access.png', icon: 'sub-error', iconColor: '#fd3939' },
    Info: { image: 'assets/images/ui/info_image.png', icon: 'info', iconColor: '#5783D4' }
  };

  buttonClick(): void {
    this.dialogRef.close({ action: 'buttonClick' });
  }

  close(): void {
    this.dialogRef.close({ action: 'close' });
  }
}
