import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { UpdateUserState } from '@simOn/user/last-visited/models';
import { TApartmentUser, TApartmentUsers } from '@simOn/utils/signalR';
import * as UsersActions from './users.actions';
import { ClearUsersState, SetSelectedUserId } from './users.actions';
import * as UsersSelectors from './users.selectors';

@Injectable()
export class UsersFacade {
  private readonly store = inject(Store);

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(select(UsersSelectors.selectUsersLoaded));
  onlineUsers$ = this.store.pipe(select(UsersSelectors.selectAllUsers));
  selectedUser$ = this.store.pipe(select(UsersSelectors.selectEntity));
  onlineUsersCount$ = this.store.select(UsersSelectors.GetUserOnlineCount);
  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init() {
    this.store.dispatch(UsersActions.initUsers());
  }

  setSelectedApartmentUsers(selectedApartmentUsers: TApartmentUsers) {
    this.store.dispatch(UsersActions.SetSelectedApartmentUsers({ selectedApartmentUsers }));
  }
  setSelectedUserId(selectedUserId: string): void {
    this.store.dispatch(SetSelectedUserId({ selectedId: selectedUserId }));
  }

  updateApartmentUserState(userState: UpdateUserState) {
    this.store.dispatch(UsersActions.UpdateApartmentUserState({ userState }));
  }

  addApartmentUserState(apartmentUser: TApartmentUser) {
    this.store.dispatch(UsersActions.AddApartmentUserState({ apartmentUser }));
  }
  deleteApartmentUserState(apartmentUser: TApartmentUser) {
    this.store.dispatch(UsersActions.DeleteApartmentUserState({ apartmentUser }));
  }

  clearState() {
    this.store.dispatch(ClearUsersState());
  }
}
