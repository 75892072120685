export function groupBy<T extends Record<string, any>>(collection: T[], key: string): Record<string, T[]> {
  return collection.reduce((accumulator: Record<string, T[]>, element: T) => {
    (accumulator[element[key]] = accumulator[element[key]] || []).push(element);
    return accumulator;
  }, {} as Record<string, T[]>);
}

export function groupByArray<T extends Record<string, any>>(collection: T[], key: string): GroupedCollection<T> {
  const groupedCollection = groupBy<T>(collection, key);
  return Object.keys(groupedCollection).map((key: string) => ({ key, collection: groupedCollection[key] }));
}

export interface GroupByArrayElement<T> {
  key: string;
  collection: T[];
}

/**
 * issue with spread operator deep copy - that's why...
 */
export function copy<T>(collection: T): T {
  return JSON.parse(JSON.stringify(collection));
}

export interface DifferenceSettingsInterface {
  differenceInCollection: 'first' | 'second' | 'both';
}

export type GroupedCollection<T> = Array<GroupByArrayElement<T>>;
