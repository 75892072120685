import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SimFullscreenComponent } from './sim-fullscreen.component';
@NgModule({
  declarations: [SimFullscreenComponent],
  exports: [SimFullscreenComponent],
  imports: [SimIconModule, CommonModule]
})
export class SimFullscreenModule {}
