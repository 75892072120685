import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
  input
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

let uniqueIdCounter = 0;

@Component({
  selector: 'sim-slider',
  templateUrl: './sim-slider.component.html',
  styleUrls: ['./sim-slider.component.scss'],
  host: {
    '[id]': 'id'
  }
})
export class SimSliderComponent implements OnInit, OnChanges {
  private detect = inject(ChangeDetectorRef);
  readonly min = input.required<number>();
  readonly max = input.required<number>();
  readonly step = input.required<number>();
  readonly valueSlider = input<number>(0);
  @Input() showValue = false;
  @Input() unit = '';
  @Input() id: string = `sim-slider-${uniqueIdCounter++}`;
  @Output() emitSliderValue = new EventEmitter<number>();

  sliderFormControl = new UntypedFormControl();
  isSliding = false;

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['valueSlider'] &&
      this.sliderFormControl &&
      !this.isSliding &&
      this.valueSlider() !== null &&
      this.valueSlider() !== undefined
    ) {
      this.sliderFormControl.setValue(this.valueSlider(), { emitEvent: false });
      this.detect.detectChanges();
    }
  }

  ngOnInit(): void {
    this.sliderFormControl.valueChanges.subscribe((value) => {
      this.emitSliderValue.emit(this.sliderFormControl.value);
    });
  }
}
