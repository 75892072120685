import { TicketFacade } from '@simOn/ticket';
import { PrivilegesEventsCb, SignalRService, TApartmentTicket } from '@simOn/utils/signalR';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

export function ticketEventListener(
  signalR: SignalRService,
  ticketFacade: TicketFacade,
  spaceId: string,
  payloadFn: Partial<PrivilegesEventsCb>
) {
  const destroy = new Subject<void>();
  signalR
    .listenOnEvent<TApartmentTicket>('TicketCreated')
    .pipe(takeUntil(destroy))
    .subscribe(({ apartmentId, ticketId }) => {
      payloadFn.TicketCreated!({ apartmentId, ticketId });
    });

  signalR
    .listenOnEvent<TApartmentTicket>('TicketModified')
    .pipe(takeUntil(destroy))
    .subscribe(({ apartmentId, ticketId }) => {
      payloadFn.TicketModified!({ apartmentId, ticketId });
    });

  signalR
    .listenOnEvent<TApartmentTicket>('TicketDeleted')
    .pipe(takeUntil(destroy))
    .subscribe(({ apartmentId, ticketId }) => {
      payloadFn.TicketDeleted!({ apartmentId, ticketId });
    });

  signalR.status$.pipe(takeUntil(destroy)).subscribe((status) => {
    ticketFacade.getTickets(spaceId, status !== 'connected');
  });

  firstValueFrom(signalR.startConnection().pipe(takeUntil(destroy)));
}
