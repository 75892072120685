import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  AssetHyperlinksInterface,
  AssetResponseInterface,
  DeleteAssetIdInterface,
  TUpdateAssetFile,
  UploadEventsInterface
} from '@simOn/asset/element/models';
import { TAsset, TCreateAsset, TUpdateAsset } from '@simOn/asset/modifying/models';
import { UploadApiService } from '@simOn/common/upload-queue/data-access';
import { Observable, combineLatest, map } from 'rxjs';
import {
  AddAsset,
  AddAssetFailure,
  AddAssetSuccess,
  ClearAssetState,
  DeleteAsset,
  DeleteAssetFailure,
  DeleteAssetSuccess,
  DeleteEventsFromAssetSimplified,
  DeleteFilesFromAsset,
  DeleteHyperlinks,
  DeleteUploadedFileSimplified,
  DeleteUploadedFileSimplifiedSuccess,
  GetAsset,
  GetAssets,
  OpenBlobUrl,
  OpenBlobUrlSuccess,
  RenameAssetFile,
  SetSelectedId,
  UpdateAssetAction,
  UpdateAssetFailure,
  UpdateAssetSuccess,
  UploadEventsSimplified,
  UploadFileToBlob,
  UploadFileToBlobFailure,
  UploadFileWithProgress,
  UploadFileWithProgressSuccess,
  UploadHyperlinks,
  UploadHyperlinksSuccess
} from './asset.actions';
import {
  AssetEntity,
  GetAssetById,
  GetAssetsByRoomId,
  GetSelectedAsset,
  GetSelectedId,
  SelectAllAssets
} from './asset.selectors';

@Injectable({
  providedIn: 'root'
})
export class AssetFacade {
  private readonly store = inject(Store);
  private readonly mediaService = inject(UploadApiService);
  private readonly actions$ = inject(Actions);
  readonly selectAllAssets$: Observable<TAsset[]> = this.store.select(SelectAllAssets);

  readonly selectedAsset$: Observable<TAsset | undefined> = this.store.select(GetSelectedAsset);
  readonly selectedId$: Observable<string | undefined> = this.store.select(GetSelectedId);

  readonly assetEntity$ = this.store.select(AssetEntity);

  readonly deleteAssetSuccess$ = this.actions$.pipe(ofType(DeleteAssetSuccess));
  readonly deleteAssetStatus$ = this.actions$.pipe(ofType(DeleteAssetSuccess, DeleteAssetFailure));

  readonly addAssetSuccess$ = this.actions$.pipe(ofType(AddAssetSuccess));
  readonly updateAssetStatus$ = this.actions$.pipe(ofType(UpdateAssetSuccess, UpdateAssetFailure));
  readonly addAssetStatus$ = this.actions$.pipe(ofType(AddAssetSuccess, AddAssetFailure));

  readonly failureUploaded$ = this.actions$.pipe(ofType(UploadFileToBlobFailure));
  readonly updateAssetSuccess$ = this.actions$.pipe(ofType(UpdateAssetSuccess));
  readonly lastUploaded$ = this.actions$.pipe(ofType(UploadFileWithProgressSuccess));
  readonly lastDeletedFile$ = this.actions$.pipe(ofType(DeleteUploadedFileSimplifiedSuccess));
  readonly lastUploadedHyperlink$ = this.actions$.pipe(ofType(UploadHyperlinksSuccess));
  readonly generatedBlobUrl$ = this.actions$.pipe(ofType(OpenBlobUrlSuccess));
  readonly updateAssetFailure$ = this.actions$.pipe(ofType(UpdateAssetFailure));
  readonly addAssetFailure$ = this.actions$.pipe(ofType(AddAssetFailure));
  assetsForRoom$ = (...assetIds: string[]): Observable<TAsset[]> =>
    combineLatest(assetIds.map((assetId: string) => this.store.select(GetAssetById(assetId)))).pipe(
      map((assets) => assets.filter((asset): asset is TAsset => !!asset))
    );
  selectAssetById$ = (assetId: string): Observable<TAsset | undefined> => this.store.select(GetAssetById(assetId));
  selectAssetsByRoomId$ = (roomId: string): Observable<TAsset[]> => this.store.select(GetAssetsByRoomId(roomId));

  openBlobFile(url: string) {
    this.store.dispatch(OpenBlobUrl({ state: url }));
  }

  setSelectedId(selectedId: string): void {
    this.store.dispatch(SetSelectedId({ selectedId }));
  }

  addAsset(asset: TCreateAsset): void {
    this.store.dispatch(AddAsset({ state: asset }));
  }

  getAssets(): void {
    this.store.dispatch(GetAssets());
  }

  getAsset(assetId: string): void {
    this.store.dispatch(GetAsset({ state: assetId }));
  }

  updateAsset(asset: TUpdateAsset): void {
    this.store.dispatch(UpdateAssetAction({ state: asset }));
  }

  updateAssetDetail(asset: TUpdateAsset): void {
    this.store.dispatch(UpdateAssetAction({ state: asset }));
  }

  deleteAsset(deleteAssetIdInterface: DeleteAssetIdInterface): void {
    this.store.dispatch(DeleteAsset({ state: deleteAssetIdInterface }));
  }

  uploadHyperlinks(assetHyperlinks: AssetHyperlinksInterface): void {
    this.store.dispatch(UploadHyperlinks({ state: assetHyperlinks }));
  }

  deleteHyperlinks(...hyperlinkGuid: string[]): void {
    this.store.dispatch(DeleteHyperlinks({ state: { hyperlinkGuid } }));
  }

  uploadFileSimplifiedWithProgress(formData: FormData, key: string) {
    this.mediaService.fileToUpload = formData;
    this.store.dispatch(UploadFileWithProgress({ key }));
  }

  uploadFileToBlob(file: File, asset: AssetResponseInterface, key: string) {
    this.store.dispatch(UploadFileToBlob({ file, asset, key }));
  }

  deleteUploadFile(fileGuid: string): void {
    this.store.dispatch(DeleteUploadedFileSimplified({ state: { fileGuid } }));
  }

  deleteFilesFromAsset(fileGuid: string[]): void {
    this.store.dispatch(DeleteFilesFromAsset({ state: { fileGuid } }));
  }

  uploadEventsFromAssetSimplified(...uploadEvents: UploadEventsInterface[]) {
    this.store.dispatch(UploadEventsSimplified({ state: uploadEvents }));
  }

  deleteEventsFromAssetSimplified(...fileGuid: string[]): void {
    this.store.dispatch(DeleteEventsFromAssetSimplified({ state: fileGuid }));
  }

  renameAssetFile(fileType: TUpdateAssetFile, fileId: string, fileName: string): void {
    this.store.dispatch(RenameAssetFile({ state: { fileId, fileName, fileType } }));
  }

  clearState() {
    this.store.dispatch(ClearAssetState());
  }
}
