export enum Colliders {
  MODEL = 'intersectedobject.model',
  NONE = 'intersectedobject.none',
  SWEEP = 'intersectedobject.sweep',
  TAG = 'intersectedobject.tag',
  UNKNOWN = 'intersectedobject.unknown',
}

export enum Transition {
  INSTANT = 'transition.instant',
  FLY = 'transition.fly',
  FADEOUT = 'transition.fade',
}
