import { UAnimatedIcon, animatedIconUrl } from '@simOn/ui/sim-animated-icons';

export type BaseSimIconType =
  | 'accelerometer_sensor'
  | 'account_dark'
  | 'account_light'
  | 'add'
  | 'alarm'
  | 'adding_card'
  | 'door_open'
  | 'error_outline'
  | 'add-photo'
  | 'arrow_down'
  | 'asset'
  | 'add_location_alt'
  | 'asset_placemarker'
  | 'flood_sensor_alarm'
  | 'asset_add'
  | 'back'
  | 'back_new'
  | 'bulb_on'
  | 'list'
  | 'calendar'
  | 'co_sensor'
  | 'cancel'
  | 'carousel-left-slider'
  | 'carousel-right-slider'
  | 'thermometer_green'
  | 'motion_sensor_detected'
  | 'humidity'
  | 'play_scene'
  | 'rgb_on'
  | 'roller_shutter_100'
  | 'smoke_sensor_alarm'
  | 'thermostat'
  | 'seismic_sensor'
  | 'chat'
  | 'gas_detector'
  | 'tag'
  | 'heat_sensor_alarm'
  | 'check_plans'
  | 'check_outline'
  | 'check_table'
  | 'close' // close icon with border
  | 'close2' // close icon without border
  | 'close3' // close icon without border
  | 'comment'
  | 'compare_arrows'
  | 'currency'
  | 'delete'
  | 'device'
  | 'device_settings'
  | 'documents'
  | 'laundry_on'
  | 'dollar'
  | 'dot-green'
  | 'dot-red'
  | 'down'
  | 'download'
  | 'edit'
  | 'euro'
  | 'events'
  | 'exit'
  | 'fullscreen_exit_dark'
  | 'fullscreen_exit_light'
  | 'note_unresolved'
  | 'note_resolved'
  | 'note_pending'
  | 'note_info'
  | 'note_inprogress'
  | 'fullscreen_dark'
  | 'fullscreen_light'
  | 'hamburger'
  | 'info'
  | 'line'
  | 'lock_open'
  | 'lock_closed'
  | 'lock'
  | 'mail'
  | 'more'
  | 'next'
  | 'no_recurring_event'
  | 'personal_details'
  | 'photo'
  | 'pin'
  | 'play'
  | 'plus'
  | 'position_marker'
  | 'position_add'
  | 'position_edit'
  | 'position_redo'
  | 'drag_dots'
  | 'recurring_event'
  | 'refresh'
  | 'search'
  | 'send'
  | 'scene'
  | 'scenes'
  | 'sub-error'
  | 'up'
  | 'recurring'
  | 'video'
  | 'ornaments'
  | 'construction'
  | 'finishingmats'
  | 'equipment'
  | 'art'
  | 'plants'
  | 'hobby'
  | 'electric'
  | 'furniture'
  | 'pipes'
  | 'sockets'
  | 'security'
  | 'hvac'
  | 'homeappliances'
  | 'entertainment'
  | 'meters'
  | 'blinds'
  | 'sensors'
  | 'garden'
  | 'other'
  | 'assetOtherIcon'
  | 'activity_log'
  | 'connections'
  | 'general_info'
  | 'import_device'
  | 'role_templates'
  | 'rooms'
  | 'tickets'
  | 'users'
  | 'device_list'
  | 'disabled_element'
  | 'model_browser'
  | 'settings'
  | 'hide'
  | 'location_on'
  | 'show'
  | 'dont_show'
  | 'layout_manager'
  | 'image'
  | 'lights'
  | 'cross_overlay'
  | 'place'
  | 'add_room_position'
  | 'room_position'
  | 'cloud'
  | 'refresh_stroke'
  | 'pin_stroke'
  | 'error_disconnected'
  | 'error_provider_unauthorized'
  | 'item_locked'
  | 'error_home_center'
  | 'delay'
  | 'trigger'
  | 'manual'
  | 'device2'
  // scene
  | 'sceneOff'
  | 'sceneBlock'
  | 'sceneDrop'
  | 'sceneHouseLocked'
  | 'sceneHouseUnlocked'
  | 'sceneGarage'
  | 'sceneClosedDoor'
  | 'sceneOpenDoor'
  | 'sceneDay'
  | 'sceneDaySecond'
  | 'sceneNight'
  | 'sceneNightSecond'
  | 'sceneSunrise'
  | 'sceneSunset'
  | 'sceneTemperature'
  | 'sceneVentilation'
  | 'sceneLighting'
  | 'sceneSaveLight'
  | 'sceneSaveMoney'
  | 'sceneOutdoorLight'
  | 'sceneNature'
  | 'sceneSolarPanels'
  | 'sceneSaveBattery'
  | 'sceneSaveEnergy'
  | 'sceneSaveWater'
  | 'sceneMeal'
  | 'sceneMusic'
  | 'sceneBedroom'
  | 'sceneDiningRoom'
  | 'sceneGarden'
  | 'sceneKitchen'
  | 'sceneLibrary'
  | 'sceneLivingRoom'
  | 'arrow_outward'
  | 'repeat'
  | 'sceneOfficeFirst'
  | 'sceneOfficeSecond'
  | 'sceneHomeCinema'
  | 'sceneStereo'
  | 'star_filled'
  | 'holiday_village'
  | 'movie'
  | 'upload_file'
  | 'sceneVanityTable'
  | 'weather_clear_day'
  | 'weather_scattered_clouds'
  | 'weather_few_clouds'
  | 'weather_broken_clouds'
  | 'weather_shover_rain'
  | 'weather_thunder'
  | 'weather_snow'
  | 'weather_mist'
  | 'full_arrow_up'
  | 'users_online'
  | 'link'
  | 'event'
  | 'insert_drive_file'
  | 'pencil'
  | 'full_arrow_down'
  | 'arrow_upward'
  | 'arrow_downward'
  | 'check'
  | 'block'
  | 'email';

export type SimIconType = BaseSimIconType | UAnimatedIcon;
export interface SimIconDataInterface {
  url: string;
  icon: {
    [key in BaseSimIconType]: string;
  };
}

export const SIM_ICON_DATA: SimIconDataInterface = {
  url: 'assets/simon-theme/icons/',
  icon: {
    arrow_downward: 'arrow_downward.svg',
    check: 'check.svg',
    block: 'block.svg',
    arrow_upward: 'arrow_upward.svg',
    list: 'list.svg',
    check_outline: 'check_outline.svg',
    error_outline: 'error_outline.svg',
    account_dark: 'account_HEAD_dark.svg',
    account_light: 'account_HEAD_light.svg',
    location_on: 'location_on.svg',
    link: 'link.svg',
    event: 'event.svg',
    insert_drive_file: 'insert_drive_file.svg',
    image: 'image.svg',
    add: 'add.svg',
    'add-photo': 'add photo.svg',
    asset: 'asset.svg',
    asset_placemarker: 'asset_placemarker.svg',
    asset_add: 'asset_add.svg',
    add_location_alt: 'add_location_alt.svg',
    back: 'arrow_Back.svg',
    movie: 'movie.svg',
    back_new: 'arrow_back_2.svg',
    calendar: 'calendar.svg',
    'carousel-left-slider': 'carousel-left-slider.svg',
    'carousel-right-slider': 'carousel-right-slider.svg',
    chat: 'chat.svg',
    close: 'close.svg',
    close2: 'close2.svg',
    close3: 'close3.svg',
    comment: 'comment.svg',
    compare_arrows: 'compare_arrows.svg',
    tag: 'tag.svg',
    delete: 'delete.svg',
    upload_file: 'upload_file.svg',
    device: 'device.svg',
    device_settings: 'device_settings.svg',

    documents: 'documents.svg',
    'dot-green': 'dot_green.svg',
    'dot-red': 'dot_red.svg',
    down: 'arrow_down.svg',
    edit: 'icon_edit_first.svg',
    events: 'events.svg',
    exit: 'exit.svg',
    note_info: 'note_info.svg',
    note_inprogress: 'note_inprogress.svg',
    note_pending: 'note_pending.svg',
    note_resolved: 'note_resolved.svg',
    note_unresolved: 'note_unresolved.svg',
    fullscreen_exit_dark: 'fullscreen_exit_dark.svg',
    fullscreen_exit_light: 'fullscreen_exit_light.svg',
    fullscreen_dark: 'fullscreen_dark.svg',
    fullscreen_light: 'fullscreen_light.svg',
    hamburger: 'hamburger.svg',
    lock_closed: 'lock_closed.svg',
    lock_open: 'lock_open.svg',
    more: 'more.svg',
    next: 'arrow_next.svg',
    no_recurring_event: 'no_recurring_event.svg',
    photo: 'photo.svg',
    pin: 'pin.svg',
    lock: 'lock.svg',
    play: 'play.svg',
    place: 'place.svg',
    plus: 'plus.svg',
    position_marker: 'position_marker.svg',
    position_add: 'position_add.svg',
    position_edit: 'position_edit.svg',
    position_redo: 'position_redo.svg',
    recurring_event: 'recurring_event.svg',
    drag_dots: 'drag_dots.svg',
    recurring: 'recurring.svg',
    refresh: 'refresh.svg',
    scene: 'scene.svg',
    scenes: 'scenes.svg',
    search: 'search.svg',
    send: 'send.svg',
    up: 'arrow_up.svg',
    video: 'video.svg',
    adding_card: 'adding_card.svg',
    arrow_down: 'arrow_down.svg',
    cancel: 'cancel.svg',
    check_plans: 'check_plans.svg',
    check_table: 'check_table.svg',
    currency: 'currency.svg',
    dollar: 'dollar.svg',
    download: 'download.svg',
    'sub-error': 'sub-error.svg',
    euro: 'euro.svg',
    info: 'info.svg',
    line: 'line.svg',
    mail: 'mail.svg',
    personal_details: 'personal_details.svg',
    disabled_element: 'disabled_element.svg',
    ornaments: 'ornaments.svg',
    construction: 'construction.svg',
    finishingmats: 'finishingmats.svg',
    equipment: 'equipment.svg',
    art: 'art.svg',
    plants: 'plants.svg',
    hobby: 'hobby.svg',
    electric: 'electric.svg',
    furniture: 'furniture.svg',
    other: 'other.svg',
    pipes: 'pipes.svg',
    lights: 'lights.svg',
    sockets: 'sockets.svg',
    security: 'security.svg',
    hvac: 'hvac.svg',
    homeappliances: 'homeappliances.svg',
    entertainment: 'entertainment.svg',
    meters: 'meters.svg',
    blinds: 'blinds.svg',
    sensors: 'sensors.svg',
    garden: 'garden.svg',
    assetOtherIcon: 'assetOtherIcon.svg',
    dont_show: 'dont_show.svg',
    hide: 'hide.svg',
    model_browser: 'model_browser.svg',
    settings: 'settings.svg',
    show: 'show.svg',
    layout_manager: 'layout_manager.svg',
    cross_overlay: 'cross_overlay.svg',
    activity_log: 'activity_log.svg',
    connections: 'connections.svg',
    general_info: 'general_info.svg',
    import_device: 'import_device.svg',
    role_templates: 'role_templates.svg',
    rooms: 'rooms.svg',
    tickets: 'tickets.svg',
    users: 'users.svg',
    device_list: 'device_list.svg',
    add_room_position: 'add_room_position.svg',
    room_position: 'room_position.svg',
    cloud: 'cloud.svg',
    pin_stroke: 'pin_stroke.svg',
    refresh_stroke: 'refresh_stroke.svg',
    error_disconnected: 'error_disconnected.svg',
    error_provider_unauthorized: 'error_provider_unauthorized.svg',
    item_locked: 'item_locked.svg',
    error_home_center: 'error_home_center.svg',
    accelerometer_sensor: 'accelerometer_sensor.svg',
    alarm: 'alarm.svg',
    door_open: 'door_open.svg',
    flood_sensor_alarm: 'flood_sensor_alarm.svg',
    bulb_on: 'bulb_on.svg',
    co_sensor: 'co_sensor.svg',
    thermometer_green: 'thermometer_green.svg',
    motion_sensor_detected: 'motion_sensor_detected.svg',
    humidity: 'humidity.svg',
    play_scene: 'play_scene.svg',
    rgb_on: 'rgb_on.svg',
    roller_shutter_100: 'roller_shutter_100.svg',
    smoke_sensor_alarm: 'smoke_sensor_alarm.svg',
    thermostat: 'thermostat.svg',
    seismic_sensor: 'seismic_sensor.svg',
    gas_detector: 'gas_detector.svg',
    heat_sensor_alarm: 'heat_sensor_alarm.svg',
    laundry_on: 'laundry_on.svg',
    delay: 'delay.svg',
    manual: 'manual.svg',
    trigger: 'trigger.svg',
    device2: 'device2.svg',
    // scene
    sceneOff: 'icon_scene_off.svg',
    sceneBlock: 'icon_scene_block.svg',
    sceneDrop: 'icon_scene_drop.svg',
    sceneHouseLocked: 'icon_scene_house_locked.svg',
    sceneHouseUnlocked: 'icon_scene_house_unlocked.svg',
    sceneGarage: 'icon_scene_garage.svg',
    sceneClosedDoor: 'icon_scene_closed_door.svg',
    sceneOpenDoor: 'icon_scene_open_door.svg',
    sceneDay: 'icon_scene_day.svg',
    sceneDaySecond: 'icon_scene_day_second.svg',
    sceneNight: 'icon_scene_night.svg',
    sceneNightSecond: 'icon_scene_night_second.svg',
    sceneSunrise: 'icon_scene_sunrise.svg',
    sceneSunset: 'icon_scene_sunset.svg',
    sceneTemperature: 'icon_scene_temperature.svg',
    sceneVentilation: 'icon_scene_ventilation.svg',
    sceneLighting: 'icon_scene_lighting.svg',
    sceneSaveLight: 'icon_scene_save_light.svg',
    sceneSaveMoney: 'icon_scene_save_money.svg',
    sceneOutdoorLight: 'icon_scene_outdoor_light.svg',
    sceneNature: 'icon_scene_nature.svg',
    sceneSolarPanels: 'icon_scene_solar_panels.svg',
    sceneSaveBattery: 'icon_scene_save_battery.svg',
    sceneSaveEnergy: 'icon_scene_save_energy.svg',
    sceneSaveWater: 'icon_scene_save_water.svg',
    sceneMeal: 'icon_scene_meal.svg',
    sceneMusic: 'icon_scene_music.svg',
    sceneBedroom: 'icon_scene_bedroom.svg',
    sceneDiningRoom: 'icon_scene_dining_room.svg',
    sceneGarden: 'icon_scene_garden.svg',
    sceneKitchen: 'icon_scene_kitchen.svg',
    sceneLibrary: 'icon_scene_library.svg',
    sceneLivingRoom: 'icon_scene_living_room.svg',
    sceneOfficeFirst: 'icon_scene_office_first.svg',
    sceneOfficeSecond: 'icon_scene_office_second.svg',
    sceneHomeCinema: 'icon_scene_home_cinema.svg',
    sceneStereo: 'icon_scene_stereo.svg',
    sceneVanityTable: 'icon_scene_vanity_table.svg',
    star_filled: 'star_filled.svg',
    holiday_village: 'holiday_village.svg',
    weather_clear_day: '01_clear_day.svg',
    weather_few_clouds: '05_clouds.svg',
    weather_scattered_clouds: '05_clouds.svg',
    weather_broken_clouds: '06_heavy_clouds.svg',
    weather_shover_rain: '08_heavy_rain.svg',
    weather_thunder: '09_thunder.svg',
    weather_snow: '10_snow.svg',
    weather_mist: '11_fog.svg',
    full_arrow_up: 'full_arrow_up.svg',
    full_arrow_down: 'full_arrow_down.svg',
    users_online: 'users_online.svg',
    arrow_outward: 'arrow_outward.svg',
    repeat: 'repeat.svg',
    pencil: 'pencil.svg',
    email: 'email.svg'
  }
};

export type SimIconSizeType = 'tiny' | 'small' | 'medium' | 'large' | 'huge' | 'mega' | 'full-size';

export type SimIconSizeDataType = {
  [key in SimIconSizeType]: number;
};

export const SIM_ICON_SIZE_DATA: SimIconSizeDataType = {
  small: 16,
  medium: 25,
  large: 30,
  huge: 42,
  mega: 70,
  'full-size': 100,
  tiny: 10
};

export const SimIconUrl = (icon: SimIconType): string => {
  let _icon = SIM_ICON_DATA.icon[icon as BaseSimIconType];
  let _url = SIM_ICON_DATA.url;
  let url = `${_url}${_icon}`;

  if (!_icon) {
    _icon = icon;
    url = animatedIconUrl(icon as UAnimatedIcon);
  }

  return url;
};
