export enum TemperatureUnitEnum {
  Unknown = 'Unknown',
  Celsius = 'Celsius',
  Fahrenheit = 'Fahrenheit',
  Kelvin = 'Kelvin'
}

export enum EnergyUnitEnum {
  Unknown = 'Unknown',
  kWh = 'kWh',
  Joule = 'Joule'
}

export enum PowerUnitEnum {
  Unknown = 'Unknown',
  Watt = 'Watt'
}

export enum CurrencyUnitEnum {
  Currency = 'Currency'
}

export interface PreferencesUnitsInterface {
  temperature: TemperatureUnitEnum;
  energy: EnergyUnitEnum;
  power: PowerUnitEnum;
  currency: CurrencyUnitEnum;
}

export type EnergyInformation = {
  value: number | string;
  unit: Unit;
};

export type Unit =
  | 'None'
  | 'Unknown'
  | 'kWh'
  | 'Wh'
  | 'mWh'
  | 'kVAh'
  | 'Joule'
  | 'Watt'
  | 'Celsius'
  | 'Fahrenheit'
  | 'Kelvin'
  | 'MilesPerHour'
  | 'Knots'
  | 'MetersPerSecond'
  | 'FeetPerSecond'
  | 'KilometersPerHour'
  | 'Percentage'
  | 'Lux'
  | 'Lumen'
  | 'mG'
  | 'MMI'
  | 'ppm'
  | 'mgForm3'
  | 'Miles'
  | 'Kilometers'
  | 'Meters'
  | 'Volt'
  | 'Currency'
  | 'Ampere';
