<span *ngIf="wakeUpSupported && !invisibilityWakeUp" class="sim-device-not-responding">
  <sim-icon
    id="alarm"
    [@wakeUp]="animation ? 'final' : 'initial'"
    (@wakeUp.done)="onAnimationDoneEvent()"
    (click)="tryToWakeUp()"
    class="not-responding-icon"
    sizeType="huge"
    icon="refresh_stroke"
    [color]="['#F7F8F8', '#3F484D']"
  ></sim-icon>
</span>
<p>{{ propertyValue }}</p>
