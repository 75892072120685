import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeDirective } from './components/badge/badge';
import { BadgeComponent } from './components/badge/badge.component';
@NgModule({
  imports: [CommonModule, A11yModule],
  exports: [BadgeDirective, BadgeComponent],
  declarations: [BadgeDirective, BadgeComponent],
})
export class UiBadgeModule {}
