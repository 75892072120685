import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sim-modal-footer',
  templateUrl: './modal-footer.component.html',
  styleUrls: ['./modal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFooterComponent {

  get center(): BooleanInput {
    return this._center;
  }

  @Input()
  set center(value: BooleanInput) {
    this._center = coerceBooleanProperty(value);
  }
  private _center = false;

  // private _cancelButton: string;
  // @Input() set cancelButton(value: string) {
  //   this._cancelButton = value;
  // }
  // public get cancelButton(): string {
  //   return this._cancelButton;
  // }
  // private _confirmButton: string;
  // @Input() set confirmButton(value: string) {
  //   this._confirmButton = value;
  // }
  // public get confirmButton(): string {
  //   return this._confirmButton;
  // }
  // @Output() confirm: EventEmitter<void> = new EventEmitter<void>();
  // @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
}
