import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyValue',
  standalone: true
})
export class CurrencyValuePipe implements PipeTransform {
  transform(energyConsumption: number, priceForOneEnergyUnit: number): number {
    return (energyConsumption / 1000) * priceForOneEnergyUnit;
  }
}
