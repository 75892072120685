import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AssetPrivilegeTypeStringify,
  DevicePrivilegeTypeStringify,
  GeneralPrivilegeTypeStringify,
  RoomPrivilegeTypeStringify,
  ScenePrivilegeTypeStringify,
  TicketPrivilegeTypeStringify
} from '@simOn/privileges/element/models';
import { Observable, filter, switchMap } from 'rxjs';
import { ClearPrivilegesState, loadPrivileges } from './privileges.actions';
import {
  assetPrivileges,
  generalPrivileges,
  getRoomsPrivileges,
  hasAssetAccessTo,
  hasDeviceAccessTo,
  hasGeneralAccessTo,
  hasInRoomAccessTo,
  hasSceneAccessTo,
  hasTicketAccessTo,
  isOwner,
  privilegesLoaded,
  roomPrivileges
} from './privileges.selectors';

@Injectable({
  providedIn: 'root'
})
export class PrivilegesFacade {
  private readonly store = inject(Store);
  readonly isOwner$: Observable<boolean> = this.store.select(isOwner);

  readonly privilegesLoaded$ = this.store.select(privilegesLoaded);

  readonly generalPrivileges$ = this.privilegesLoaded$.pipe(
    filter((loaded: boolean) => loaded),
    switchMap(() => this.store.select(generalPrivileges))
  );
  readonly roomPrivileges$ = this.privilegesLoaded$.pipe(
    filter((loaded: boolean) => loaded),
    switchMap(() => this.store.select(getRoomsPrivileges))
  );

  readonly hasGeneralAccessTo$ = (privilege: GeneralPrivilegeTypeStringify): Observable<boolean> =>
    this.privilegesLoaded$.pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => this.store.select(hasGeneralAccessTo(privilege)))
    );

  readonly hasAssetAccessTo$ = (assetId: string, privilege: AssetPrivilegeTypeStringify): Observable<boolean> =>
    this.privilegesLoaded$.pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => this.store.select(hasAssetAccessTo(assetId, privilege)))
    );

  readonly hasTicketAccessTo$ = (ticketId: string, privilege: TicketPrivilegeTypeStringify): Observable<boolean> =>
    this.privilegesLoaded$.pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => this.store.select(hasTicketAccessTo(ticketId, privilege)))
    );

  readonly hasSceneAccessTo$ = (sceneId: string, privilege: ScenePrivilegeTypeStringify): Observable<boolean> =>
    this.privilegesLoaded$.pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => this.store.select(hasSceneAccessTo(sceneId, privilege)))
    );

  readonly hasDeviceAccessTo$ = (deviceId: string, privilege: DevicePrivilegeTypeStringify): Observable<boolean> =>
    this.privilegesLoaded$.pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => this.store.select(hasDeviceAccessTo(deviceId, privilege)))
    );

  readonly hasInRoomAccessTo$ = (roomId: string, privilege: RoomPrivilegeTypeStringify): Observable<boolean> =>
    this.store.select(hasInRoomAccessTo(roomId, privilege));

  readonly getRoomPrivileges$ = (roomId: string) => this.store.select(roomPrivileges(roomId));

  readonly getAssetPrivileges$ = (assetId: string) =>
    this.privilegesLoaded$.pipe(
      filter((loaded: boolean) => loaded),
      switchMap(() => this.store.select(assetPrivileges(assetId)))
    );

  loadPrivileges(): void {
    this.store.dispatch(loadPrivileges());
  }
  clearState() {
    this.store.dispatch(ClearPrivilegesState());
  }
}
