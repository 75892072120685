type TVector = [] | [number, number] | [number, number, number] | [Vector];

export class Vector {
  /**
   * Shorthand for writing Vector3(0, 0, -1).
   */
  static get back(): Vector {
    return new Vector(0, 0, -1);
  }

  /**
   * 	Shorthand for writing Vector3(0, -1, 0).
   */
  static get down(): Vector {
    return new Vector(0, -1, 0);
  }

  /**
   * Shorthand for writing Vector3(0, 0, 1).
   */
  static get forward(): Vector {
    return new Vector(0, 0, 1);
  }

  /**
   * Shorthand for writing Vector3(-1, 0, 0).
   */
  static get left(): Vector {
    return new Vector(-1, 0, 0);
  }

  /**
   * Shorthand for writing Vector3(1, 1, 1).
   */
  static get one(): Vector {
    return new Vector(1, 1, 1);
  }

  /**
   * Shorthand for writing Vector3(1, 0, 0).
   */
  static get right(): Vector {
    return new Vector(1, 0, 0);
  }

  /**
   * 	Shorthand for writing Vector3(0, 1, 0).
   */
  static get up(): Vector {
    return new Vector(0, 1, 0);
  }

  /**
   * Shorthand for writing Vector3(0, 0, 0).
   */
  static get zero(): Vector {
    return new Vector(0, 0, 0);
  }

  x = 0;
  y = 0;
  z = 0;

  constructor(...initialValue: TVector) {
    const instance = this._toArray(...initialValue);

    this.x = instance[0];
    this.y = instance[1];
    this.z = instance[2];
  }

  /**
   * @param value Inverse vector
   * @returns inverted vector
   */
  static inverse(point: Vector): Vector {
    return new Vector(1 / point.x, 1 / point.y, 1 / point.z);
  }

  /**
   * Returns true if the given vector is exactly equal to this vector.
   * @returns
   */
  static equals(pointA: Vector, pointB: Vector): boolean {
    const diff_x = pointA.x - pointB.x;
    const diff_y = pointA.y - pointB.y;
    const diff_z = pointA.z - pointB.z;
    const sqrMagnitude = diff_x * diff_x + diff_y * diff_y + diff_z * diff_z;

    return sqrMagnitude < 0.02;
  }

  private _toArray(...initialValue: TVector): Array<number> {
    let x = 0,
      y = 0,
      z = 0;
    if (Array.isArray(initialValue)) {
      if (
        initialValue.length === 1 &&
        typeof initialValue[0] === 'object' &&
        initialValue[0] instanceof Vector
      ) {
        x = initialValue[0].x;
        y = initialValue[0].y;
        z = initialValue[0].z;
      } else if (
        initialValue.length === 2 &&
        typeof initialValue[0] === 'number' &&
        typeof initialValue[1] === 'number'
      ) {
        x = initialValue[0];
        y = initialValue[1];
      } else if (
        initialValue.length === 3 &&
        typeof initialValue[0] === 'number' &&
        typeof initialValue[1] === 'number' &&
        typeof initialValue[2] === 'number'
      ) {
        x = initialValue[0];
        y = initialValue[1];
        z = initialValue[2];
      } else if (initialValue.length !== 0) {
        throw new Error('Invalid arguments!');
      }
    } else {
      throw new Error('Invalid arguments!');
    }
    return Array<number>(x, y, z);
  }

  /**
   * Returns a sum of 2 vectors
   * @param point addend
   * @returns sum
   */
  add(point: Vector): Vector {
    this.x += point.x;
    this.y += point.y;
    this.z += point.z;

    return this;
  }

  /**
   * Subtract vectors
   * @param point subtrahend
   * @returns difference
   */
  subtract(point: Vector): Vector {
    this.x -= point.x;
    this.y -= point.y;
    this.z -= point.z;

    return this;
  }

  /**
   * Multiply vector by scalar
   * @param value scalar
   * @returns multiplied vector
   */
  multiply(value: number): Vector {
    this.x *= value;
    this.y *= value;
    this.z *= value;

    return this;
  }

  /**
   * Divide dividend vector by divisor
   * @param value divisor
   * @returns quotient
   */
  divide(value: number): Vector {
    if (Math.abs(value) > 1.0e-6) {
      this.multiply(1.0 / value);
    } else {
      throw new Error('Division by zero');
    }
    return this;
  }

  /**
   * Returns product of two vectors
   * @param point factor
   * @returns product of 2 vectors
   */
  dotProduct(point: Vector): Vector {
    this.x *= point.x;
    this.y *= point.y;
    this.z *= point.z;

    return this;
  }

  /**
   * Returns distance between two vectors
   * @param point - destination Vector
   * @returns distance to input vector
   */
  distanceTo(point: Vector): number {
    const vx = this.x - point.x;
    const vy = this.y - point.y;
    const vz = this.z - point.z;
    return Math.sqrt(vx * vx + vy * vy + vz * vz);
  }
}
