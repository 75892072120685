import { gql } from 'apollo-angular';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  model?: Maybe<Model>;
  models?: Maybe<ModelSearchResultList>;
};

export type QueryModelArgs = {
  id: Scalars['ID'];
  field?: InputMaybe<ModelIdField>;
};

export type QueryModelsArgs = {
  include?: InputMaybe<Array<SearchInclusion>>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<ModelSort>>;
  pageSize?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
};

export type Model = ModelSummary & {
  __typename?: 'Model';
  id: Scalars['ID'];
  internalId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Photo>;
  address?: Maybe<Address>;
  geolocation?: Maybe<GeoLocation>;
  dimensions?: Maybe<Dimension>;
  state?: Maybe<ModelState>;
  visibility?: Maybe<ModelVisibility>;
  demo?: Maybe<Scalars['Boolean']>;
  floors?: Maybe<Array<ModelFloor>>;
  mls?: Maybe<ModelMlsDetails>;
  options?: Maybe<ModelOptions>;
  processing?: Maybe<ModelProcessingDetails>;
  publication?: Maybe<ModelPublicationDetails>;
  rooms?: Maybe<Array<ModelRoom>>;
  labels?: Maybe<Array<Label>>;
  mattertags?: Maybe<Array<Mattertag>>;
  measurements: Array<Measurement>;
  measurementPaths: Array<MeasurementPath>;
  notes?: Maybe<Array<Note>>;
  assets: ModelAssets;
  bundle?: Maybe<ModelBundle>;
  bundles?: Maybe<Array<ModelBundle>>;
  locations?: Maybe<Array<Maybe<AnchorLocation>>>;
  panoLocations?: Maybe<Array<PanoramicImageLocation>>;
  activeHighlightReel?: Maybe<HighlightReel>;
};

export type ModelDimensionsArgs = {
  units?: InputMaybe<Units>;
};

export type ModelFloorsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ModelRoomsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ModelLabelsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelMattertagsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelMeasurementsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelMeasurementPathsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelNotesArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  resolutionStatus?: InputMaybe<Array<ResolutionStatus>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ModelBundleArgs = {
  id: Scalars['ID'];
};

export type ModelBundlesArgs = {
  availability?: InputMaybe<Availability>;
};

export type ModelLocationsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelPanoLocationsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  variants?: InputMaybe<Array<Scalars['String']>>;
};

export enum ModelIdField {
  Id = 'id',
  InternalId = 'internalId',
  MlsId = 'mlsId',
}

export type ModelSearchResultList = {
  __typename?: 'ModelSearchResultList';
  nextOffset?: Maybe<Scalars['String']>;
  totalResults: Scalars['Int'];
  results?: Maybe<Array<ModelSearchResult>>;
};

export enum SearchInclusion {
  Inactive = 'inactive',
  Demo = 'demo',
}

export type ModelSort = {
  field: ModelSortField;
  order?: InputMaybe<SortOrder>;
};

export type ModelSummary = {
  id: Scalars['ID'];
  internalId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Scalars['ID']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  visibility?: Maybe<ModelVisibility>;
  demo?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  geolocation?: Maybe<GeoLocation>;
};

export type Asset = {
  model?: Maybe<Model>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type Image = {
  id: Scalars['ID'];
  filename?: Maybe<Scalars['String']>;
  resizeUrl?: Maybe<Scalars['String']>;
  resolutions?: Maybe<Array<PhotoResolution>>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type ImageResizeUrlArgs = {
  resolution: PhotoResolution;
};

export type Photo = {
  __typename?: 'Photo';
  model?: Maybe<Model>;
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  resizeUrl?: Maybe<Scalars['String']>;
  resolutions?: Maybe<Array<PhotoResolution>>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  snapshotLocation?: Maybe<SnapshotLocation>;
};

export type PhotoResizeUrlArgs = {
  resolution: PhotoResolution;
};

export type Address = {
  __typename?: 'Address';
  streetAddressLines?: Maybe<Array<Scalars['String']>>;
  administrativeArea?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  dependentLocality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  sortingCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  lines?: Maybe<Array<Scalars['String']>>;
  address?: Maybe<Scalars['String']>;
  status?: Maybe<AddressStatus>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  altitude?: Maybe<Scalars['String']>;
  lat: Scalars['String'];
  long: Scalars['String'];
};

export type Dimension = {
  __typename?: 'Dimension';
  areaCeiling?: Maybe<Scalars['Float']>;
  areaFloor?: Maybe<Scalars['Float']>;
  areaFloorIndoor?: Maybe<Scalars['Float']>;
  areaWall?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  units: Units;
};

export enum Units {
  Metric = 'metric',
  Imperial = 'imperial',
}

export enum ModelState {
  Staging = 'staging',
  Processing = 'processing',
  Failed = 'failed',
  InactivationPending = 'inactivation_pending',
  Inactivating = 'inactivating',
  Inactive = 'inactive',
  ActivationPending = 'activation_pending',
  Activating = 'activating',
  Active = 'active',
}

export type ModelSweep = {
  id: string;
  position: {
    x: number;
    y: number;
    z: number;
  };
};

export enum ModelVisibility {
  Public = 'public',
  Private = 'private',
}

export type ModelFloor = {
  __typename?: 'ModelFloor';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Dimension>;
  rooms?: Maybe<Array<Maybe<ModelRoom>>>;
  sequence: Scalars['Int'];
  mattertags: Array<Mattertag>;
  measurements: Array<Measurement>;
  measurementPaths: Array<MeasurementPath>;
  notes?: Maybe<Array<Note>>;
  locations?: Maybe<Array<AnchorLocation>>;
  panoLocations?: Maybe<Array<PanoramicImageLocation>>;
};

export type ModelFloorDimensionsArgs = {
  units?: InputMaybe<Units>;
};

export type ModelFloorRoomsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ModelFloorMattertagsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelFloorMeasurementsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelFloorMeasurementPathsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelFloorNotesArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  resolutionStatus?: InputMaybe<Array<ResolutionStatus>>;
};

export type ModelFloorLocationsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelFloorPanoLocationsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  variants?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelMlsDetails = {
  __typename?: 'ModelMlsDetails';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type ModelOptions = {
  __typename?: 'ModelOptions';
  socialSharingEnabled: Scalars['Boolean'];
  socialSharingOverride: SettingOverride;
  vrEnabled: Scalars['Boolean'];
  vrOverride: SettingOverride;
  vrSupported: Scalars['Boolean'];
  addressVisibility: AddressVisibility;
  tourButtonsEnabled: Scalars['Boolean'];
  tourButtonsOverride: SettingOverride;
  dollhouseEnabled: Scalars['Boolean'];
  dollhouseOverride: SettingOverride;
  floorplanEnabled: Scalars['Boolean'];
  floorplanOverride: SettingOverride;
  labelsEnabled: Scalars['Boolean'];
  labelsOverride: SettingOverride;
  dollhouseLabelsEnabled: Scalars['Boolean'];
  dollhouseLabelsOverride: SettingOverride;
  highlightReelEnabled: Scalars['Boolean'];
  highlightReelOverride: SettingOverride;
  tourAutoplayEnabled: Scalars['Boolean'];
  tourAutoplayOverride: SettingOverride;
  backgroundColor: ModelBackgroundColor;
  unitType: Units;
  measurements: MeasurementsMode;
};

export type ModelProcessingDetails = {
  __typename?: 'ModelProcessingDetails';
  processingState?: Maybe<ProcessingState>;
  processingEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ModelPublicationDetails = {
  __typename?: 'ModelPublicationDetails';
  published: Scalars['Boolean'];
  url: Scalars['String'];
  embed: Scalars['String'];
  externalUrl?: Maybe<Scalars['String']>;
  contact?: Maybe<ModelContact>;
  presentedBy?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type ModelPublicationDetailsUrlArgs = {
  branding?: InputMaybe<ModelBranding>;
  view?: InputMaybe<ModelPlayerView>;
};

export type ModelPublicationDetailsEmbedArgs = {
  branding?: InputMaybe<ModelBranding>;
};

export type ModelRoom = {
  __typename?: 'ModelRoom';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  dimensions?: Maybe<Dimension>;
  floor?: Maybe<ModelFloor>;
  measurements: Array<Measurement>;
  measurementPaths: Array<MeasurementPath>;
  notes?: Maybe<Array<Note>>;
  locations?: Maybe<Array<AnchorLocation>>;
  panoLocations?: Maybe<Array<PanoramicImageLocation>>;
};

export type ModelRoomDimensionsArgs = {
  units?: InputMaybe<Units>;
};

export type ModelRoomMeasurementsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelRoomMeasurementPathsArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
};

export type ModelRoomNotesArgs = {
  includeDisabled?: InputMaybe<Scalars['Boolean']>;
  resolutionStatus?: InputMaybe<Array<ResolutionStatus>>;
};

export type ModelRoomLocationsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelRoomPanoLocationsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  variants?: InputMaybe<Array<Scalars['String']>>;
};

export type Label = Annotation & {
  __typename?: 'Label';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  label: Scalars['String'];
  position?: Maybe<Point>;
  model: Model;
};

export type Mattertag = Annotation & {
  __typename?: 'Mattertag';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  model?: Maybe<Model>;
  floor?: Maybe<ModelFloor>;
  enabled: Scalars['Boolean'];
  color?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  mediaType?: Maybe<MattertagMediaType>;
  anchorPosition?: Maybe<Point>;
  discPosition?: Maybe<Point>;
  stemNormal?: Maybe<Point>;
  stemLength?: Maybe<Scalars['Float']>;
  stemEnabled?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Point>;
  stemDirection?: Maybe<Point>;
  icon?: Maybe<Scalars['String']>;
  fileAttachments?: Maybe<FileAttachment[]>;
};

export type FileAttachment = {
  __typename?: 'FileAttachment';
  id: Scalars['ID'];
  bytes?: Maybe<Scalars['Int']>;
  created?: Scalars['DateTime'];
  filename?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Measurement = Annotation & {
  __typename?: 'Measurement';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  model?: Maybe<Model>;
  floor?: Maybe<ModelFloor>;
  enabled: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  startPosition?: Maybe<Point>;
  endPosition?: Maybe<Point>;
  startNormal?: Maybe<Point>;
  endNormal?: Maybe<Point>;
  room?: Maybe<ModelRoom>;
  distance: Scalars['Float'];
};

export type MeasurementDistanceArgs = {
  units?: InputMaybe<Units>;
};

export type MeasurementPath = Annotation & {
  __typename?: 'MeasurementPath';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  model?: Maybe<Model>;
  enabled: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  points: Array<MeasurementPathPoint>;
  distance?: Maybe<Scalars['Float']>;
};

export type MeasurementPathDistanceArgs = {
  units?: InputMaybe<Units>;
  start?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['Int']>;
};

export type Note = Annotation & {
  __typename?: 'Note';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  label?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  createdBy: UserMetadata;
  resolution: ResolutionStatus;
  model?: Maybe<Model>;
  floor?: Maybe<ModelFloor>;
  room?: Maybe<ModelRoom>;
  color?: Maybe<Scalars['String']>;
  anchorPosition?: Maybe<Point>;
  discPosition?: Maybe<Point>;
  stemNormal?: Maybe<Point>;
  stemLength?: Maybe<Scalars['Float']>;
  stemEnabled?: Maybe<Scalars['Boolean']>;
  firstCommentAt: Scalars['DateTime'];
  lastCommentAt: Scalars['DateTime'];
  totalComments: Scalars['Int'];
  comments?: Maybe<CommentResultList>;
};

export type NoteCommentsArgs = {
  sortBy?: InputMaybe<CommentSort>;
  pageSize?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
};

export enum ResolutionStatus {
  Resolved = 'resolved',
  Open = 'open',
}

export type ModelAssets = {
  __typename?: 'ModelAssets';
  panos: Array<PanoramicImage>;
  clips: Array<Clip>;
  floorplan: Floorplan;
  floorplans?: Maybe<Array<Floorplan>>;
  photos: Array<Photo>;
  meshes: Array<Mesh>;
  textures: Array<Texture>;
  resources?: Maybe<Array<Maybe<Asset>>>;
};

export type ModelAssetsPanosArgs = {
  resolutions?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelAssetsFloorplanArgs = {
  provider?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  floor?: InputMaybe<Scalars['ID']>;
  flags?: InputMaybe<Array<FloorplanFlag>>;
};

export type ModelAssetsFloorplansArgs = {
  provider?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Array<Scalars['String']>>;
  floors?: InputMaybe<Array<Scalars['ID']>>;
  flags?: InputMaybe<Array<FloorplanFlag>>;
};

export type ModelAssetsMeshesArgs = {
  formats?: InputMaybe<Array<Scalars['String']>>;
  resolutions?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelAssetsTexturesArgs = {
  qualities?: InputMaybe<Array<TextureQuality>>;
  resolutions?: InputMaybe<Array<Scalars['String']>>;
};

export type ModelAssetsResourcesArgs = {
  format?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ModelBundle = Lockable & {
  __typename?: 'ModelBundle';
  id: Scalars['ID'];
  availability: Availability;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<Asset>>;
  supportedOptions?: Maybe<BundleOptions>;
};

export enum Availability {
  Unavailable = 'unavailable',
  Locked = 'locked',
  Unlocked = 'unlocked',
}

export type AnchorLocation = {
  __typename?: 'AnchorLocation';
  id: Scalars['ID'];
  model: Model;
  position?: Maybe<Point>;
  floor?: Maybe<ModelFloor>;
  room?: Maybe<ModelRoom>;
  label: Scalars['String'];
  neighbors?: Maybe<Array<Scalars['ID']>>;
  tags?: Maybe<Array<Scalars['String']>>;
  panoImageVariants?: Maybe<Array<Scalars['String']>>;
  pano?: Maybe<PanoramicImageLocation>;
  panos?: Maybe<Array<PanoramicImageLocation>>;
};

export type AnchorLocationPanoArgs = {
  variant?: InputMaybe<Scalars['String']>;
};

export type PanoramicImageLocation = {
  __typename?: 'PanoramicImageLocation';
  id: Scalars['ID'];
  model: Model;
  anchor?: Maybe<AnchorLocation>;
  options: Array<PanoramicImageOption>;
  resolutions?: Maybe<Array<Scalars['String']>>;
  placement?: Maybe<PanoramaPlacement>;
  source?: Maybe<PanoramaSource>;
  position?: Maybe<Point>;
  rotation?: Maybe<Quaternion>;
  variant: Scalars['String'];
  image?: Maybe<PanoramicImage>;
  images?: Maybe<Array<PanoramicImage>>;
  skybox?: Maybe<SkyboxPanorama>;
  skyboxes?: Maybe<Array<SkyboxPanorama>>;
  label: Scalars['String'];
};

export type PanoramicImageLocationResolutionsArgs = {
  format?: InputMaybe<PanoramicImageFormat>;
};

export type PanoramicImageLocationImageArgs = {
  format: PanoramicImageFormat;
  resolution?: InputMaybe<Scalars['String']>;
};

export type PanoramicImageLocationImagesArgs = {
  resolutions?: InputMaybe<Array<Scalars['String']>>;
};

export type PanoramicImageLocationSkyboxArgs = {
  resolution?: InputMaybe<Scalars['String']>;
};

export type PanoramicImageLocationSkyboxesArgs = {
  resolutions?: InputMaybe<Array<Scalars['String']>>;
};

export type HighlightReel = {
  __typename?: 'HighlightReel';
  id: Scalars['ID'];
  label: Scalars['String'];
  modified?: Maybe<Scalars['DateTime']>;
  reel: Array<ReelElement>;
};

export enum ModelSortField {
  Created = 'created',
  Modified = 'modified',
  Score = 'score',
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum AssetStatus {
  Available = 'available',
  Locked = 'locked',
  Unavailable = 'unavailable',
}

export enum PhotoResolution {
  Icon = 'icon',
  Thumbnail = 'thumbnail',
  Preview = 'preview',
  Web = 'web',
  Presentation = 'presentation',
  Original = 'original',
}

export type SnapshotLocation = {
  __typename?: 'SnapshotLocation';
  position?: Maybe<Point>;
  rotation?: Maybe<Quaternion>;
  anchor?: Maybe<AnchorLocation>;
};

export enum AddressStatus {
  Empty = 'empty',
  Raw = 'raw',
  Parsed = 'parsed',
  Verified = 'verified',
}

export enum SettingOverride {
  Default = 'default',
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export enum AddressVisibility {
  Full = 'full',
  Partial = 'partial',
  None = 'none',
}

export enum ModelBackgroundColor {
  Black = 'black',
  Grey = 'grey',
  White = 'white',
}

export enum MeasurementsMode {
  Disabled = 'disabled',
  Measure = 'measure',
  MeasureAndView = 'measureAndView',
}

export enum ProcessingState {
  Staging = 'staging',
  Inprogress = 'inprogress',
  Failed = 'failed',
  Complete = 'complete',
}

export enum ModelBranding {
  Default = 'default',
  Unbranded = 'unbranded',
  Mls = 'mls',
}

export enum ModelPlayerView {
  Default = 'default',
  Vr = 'vr',
}

export type ModelContact = {
  __typename?: 'ModelContact';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type Annotation = {
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export enum MattertagMediaType {
  Photo = 'photo',
  Video = 'video',
  Rich = 'rich',
}

export type MeasurementPathPoint = {
  __typename?: 'MeasurementPathPoint';
  position: Point;
  normal?: Maybe<Point>;
  floor?: Maybe<ModelFloor>;
  room?: Maybe<ModelRoom>;
};

export type UserMetadata = UserSummary & {
  __typename?: 'UserMetadata';
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type CommentResultList = {
  __typename?: 'CommentResultList';
  nextOffset?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Comment>>;
};

export type CommentSort = {
  field: CommentSortField;
  order?: InputMaybe<SortOrder>;
};

export type PanoramicImage = {
  anchor?: Maybe<AnchorLocation>;
  perspective?: Maybe<PanoramicImageLocation>;
  resolution?: Maybe<Scalars['String']>;
};

export type Clip = Asset & {
  __typename?: 'Clip';
  model?: Maybe<Model>;
  id: Scalars['ID'];
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

export type Floorplan = Asset & {
  __typename?: 'Floorplan';
  floor?: Maybe<ModelFloor>;
  flags: Array<FloorplanFlag>;
  provider: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  resolution?: Maybe<Scalars['Float']>;
  origin?: Maybe<Point2D>;
  model?: Maybe<Model>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export enum FloorplanFlag {
  Schematic = 'schematic',
  Photogramy = 'photogramy',
  Alpha = 'alpha',
  ColoredRooms = 'colored_rooms',
  Measurements = 'measurements',
}

export type Mesh = Asset & {
  __typename?: 'Mesh';
  model?: Maybe<Model>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  resolution: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type Texture = Asset & {
  __typename?: 'Texture';
  model?: Maybe<Model>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  resolution?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  quality?: Maybe<TextureQuality>;
  textures?: Maybe<Scalars['Int']>;
  urlTemplate?: Maybe<Scalars['String']>;
};

export enum TextureQuality {
  Low = 'low',
  High = 'high',
}

export type Lockable = {
  availability: Availability;
};

export type BundleOptions = {
  __typename?: 'BundleOptions';
  deliverySpeeds?: Maybe<Array<DeliverySpeed>>;
  measurementUnits?: Maybe<Array<Units>>;
};

export type PanoramicImageOption = {
  __typename?: 'PanoramicImageOption';
  format?: Maybe<PanoramicImageFormat>;
  resolutions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum PanoramicImageFormat {
  Cylindrical = 'cylindrical',
  Equirectangular = 'equirectangular',
  Skybox = 'skybox',
}

export enum PanoramaPlacement {
  Unplaced = 'unplaced',
  Auto = 'auto',
  Manual = 'manual',
}

export enum PanoramaSource {
  Vision = 'vision',
  Upload = 'upload',
}

export type Quaternion = {
  __typename?: 'Quaternion';
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
  w: Scalars['Float'];
};

export type SkyboxPanorama = {
  __typename?: 'SkyboxPanorama';
  model?: Maybe<Model>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  urlTemplate?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Scalars['String']>>>;
  resolution?: Maybe<Scalars['String']>;
  anchor?: Maybe<AnchorLocation>;
  perspective?: Maybe<PanoramicImageLocation>;
};

export type ReelElement = {
  __typename?: 'ReelElement';
  id: Scalars['ID'];
  asset?: Maybe<ReelElementAsset>;
};

export type UserSummary = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  createdBy: UserMetadata;
  edited: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export enum CommentSortField {
  Created = 'created',
  Modified = 'modified',
}

export type Point2D = {
  __typename?: 'Point2D';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export enum DeliverySpeed {
  Normal = 'normal',
  Fast = 'fast',
  Urgent = 'urgent',
}

export type ReelElementAsset = Photo;

export type AssetBundle = {
  urlTemplate?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ModelSearchResult = ModelSummary & {
  __typename?: 'ModelSearchResult';
  id: Scalars['ID'];
  internalId?: Maybe<Scalars['ID']>;
  organization: Scalars['ID'];
  created: Scalars['DateTime'];
  modified: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<PhotoSummary>;
  visibility: ModelVisibility;
  demo: Scalars['Boolean'];
  address: Address;
  geolocation?: Maybe<GeoLocation>;
  mls: ModelMlsDetails;
  assets?: Maybe<ModelSearchAssets>;
  floors?: Maybe<ModelSearchFloors>;
  rooms?: Maybe<ModelSearchRooms>;
  mattertags?: Maybe<ModelSearchMattertags>;
  measurements?: Maybe<ModelSearchMeasurements>;
  publication?: Maybe<ModelSearchPublicationDetails>;
  state?: Maybe<ModelState>;
  score: Scalars['Float'];
};

export type PhotoSummary = {
  __typename?: 'PhotoSummary';
  resizeUrl?: Maybe<Scalars['String']>;
  model?: Maybe<Model>;
  id: Scalars['ID'];
  status?: Maybe<AssetStatus>;
  filename?: Maybe<Scalars['String']>;
  format: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type PhotoSummaryResizeUrlArgs = {
  resolution: PhotoResolution;
};

export type ModelSearchAssets = {
  __typename?: 'ModelSearchAssets';
  panos?: Maybe<ModelSearchPanos>;
  meshes?: Maybe<ModelSearchMeshes>;
  textures?: Maybe<ModelSearchTextures>;
  locations?: Maybe<ModelSearchLocations>;
  panoLocations?: Maybe<ModelSearchPanoLocations>;
};

export type ModelSearchPanos = {
  __typename?: 'ModelSearchPanos';
  count: Scalars['Int'];
  formats?: Maybe<Array<Scalars['String']>>;
  resolutions?: Maybe<Array<Scalars['String']>>;
};

export type ModelSearchFloors = {
  __typename?: 'ModelSearchFloors';
  count: Scalars['Int'];
};

export type ModelSearchRooms = {
  __typename?: 'ModelSearchRooms';
  count: Scalars['Int'];
  tags?: Maybe<Array<Scalars['String']>>;
};

export type ModelSearchMattertags = {
  __typename?: 'ModelSearchMattertags';
  count: Scalars['Int'];
  labels?: Maybe<Array<Scalars['String']>>;
  descriptions?: Maybe<Array<Scalars['String']>>;
};

export type ModelSearchMeasurements = {
  __typename?: 'ModelSearchMeasurements';
  count: Scalars['Int'];
  labels?: Maybe<Array<Scalars['String']>>;
};

export type ModelSearchPublicationDetails = {
  __typename?: 'ModelSearchPublicationDetails';
  published: Scalars['Boolean'];
  contact?: Maybe<ModelContact>;
  presentedBy?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type ModelSearchMeshes = {
  __typename?: 'ModelSearchMeshes';
  count: Scalars['Int'];
  formats?: Maybe<Array<Scalars['String']>>;
};

export type ModelSearchTextures = {
  __typename?: 'ModelSearchTextures';
  count: Scalars['Int'];
  formats?: Maybe<Array<Scalars['String']>>;
};

export type ModelSearchLocations = {
  __typename?: 'ModelSearchLocations';
  count: Scalars['Int'];
  tags?: Maybe<Array<Scalars['String']>>;
};

export type ModelSearchPanoLocations = {
  __typename?: 'ModelSearchPanoLocations';
  count: Scalars['Int'];
  placement?: Maybe<Array<PanoramaPlacement>>;
};

export type ModelsQueryVariables = Exact<{ [key: string]: never }>;

export type GetModelTagsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;
export enum ViewType {
  UserView = 'matterport.user.view',
  DefaultView = 'matterport.model.default',
}
export type ModelView = {
  id: string;
  name: string;
  type: ViewType;
  enabled: boolean;
};
export type GetVisibilityQuery = {
  model: {
    visibility: ModelVisibility;
  };
};
export type GetViewsQuery = {
  model: {
    views: ModelView[];
  };
};
export type GetModelTagsQuery = {
  __typename?: 'Query';
  model?: {
    __typename?: 'Model';
    mattertags?: Array<{
      __typename?: 'Mattertag';
      label?: string | null;
      description?: string | null;
      enabled: boolean;
      color?: string | null;
      media?: string | null;
      mediaType?: MattertagMediaType | null;
      id: string;
      floor?: {
        __typename?: 'ModelFloor';
        id: string;
        label?: string | null;
      } | null;
      anchorPosition?: {
        __typename?: 'Point';
        x: number;
        y: number;
        z: number;
      } | null;
    }> | null;
  } | null;
};

export type ModelsQuery = {
  __typename?: 'Query';
  models?: {
    __typename?: 'ModelSearchResultList';
    nextOffset?: string;
    totalResults: Scalars['Int'];
    results?: Array<Partial<ModelSearchResult>> | null;
  } | null;
};

export const MatterportTagsDocument = gql`
  query GetModelTags($id: ID!) {
    model(id: $id) {
      mattertags(includeDisabled: false) {
        label
        description
        media
        mediaType
        enabled
        fileAttachments {
          bytes
          created
          filename
          id
          mimeType
          url
        }
        color
        discPosition {
          x
          y
          z
        }
        floor {
          id
          label
        }
        id
        stemLength
        stemNormal {
          x
          y
          z
        }
        anchorPosition {
          x
          y
          z
        }
      }
    }
  }
`;
export const ModelsDocument = gql`
  query GetModels($offset: String, $query: String, $sort: [ModelSort!]) {
    models(offset: $offset, query: $query, sortBy: $sort) {
      totalResults
      nextOffset
      results {
        id
        name
        visibility
        address {
          address
        }
        created
        image {
          resizeUrl(resolution: thumbnail)
        }

        mattertags {
          labels
          descriptions
        }
      }
    }
  }
`;
export const MatterportVisibility = gql`
  query GetModelVisibility($id: ID!) {
    model(id: $id) {
      visibility
    }
  }
`;
export const MatterportPrefetchData = gql`
  query MatterportViews($id: ID!) {
    model(id: $id) {
      views {
        id
        name
        type
        enabled
      }
    }
  }
`;
export const ModelSweeps = gql`
  query GetModelSweeps($id: ID!) {
    model(id: $id) {
      locations {
        id
        pano {
          sweepUuid
          position {
            x
            y
            z
          }
        }
      }
    }
  }
`;
