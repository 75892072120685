import {
  ChangeDetectorRef,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { TransformationInterface } from '@simOn/common/matterport';
import {
  AlarmSensorProperties,
  AlarmSensors,
  DeviceDto,
  FileBlob,
  PropertyValueChange,
  SensorValue,
  SimlabPropertyInterface,
  TDeviceStatus,
  WidgetDeviceInformation,
  WidgetExtended,
  WidgetTransformation
} from '@simOn/device/models';
import { WIDGET_COMMAND } from '@simOn/device/widget/tokens';
import { UAnimatedIcon } from '@simOn/ui/sim-animated-icons';
import {
  BehaviorSubject,
  Observable,
  Subject,
  defer,
  distinctUntilChanged,
  filter,
  first,
  map,
  of,
  switchMap,
  take
} from 'rxjs';

@Directive()
export abstract class WidgetBaseDirective<T extends SimlabPropertyInterface<any> = never> implements OnInit, OnDestroy {
  private readonly _ngZone = inject(NgZone);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _el = inject(ElementRef);
  protected _destroy: Subject<void> = new Subject<void>();

  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loading$: Observable<boolean> = this._loading.asObservable();
  readonly loaded$: Observable<boolean> = defer(() => this.loading$.pipe(filter((loading) => !loading)));
  readonly openDetails: Subject<
    Pick<WidgetExtended, 'id' | 'deviceId' | 'transformations' | 'icon' | 'name' | 'file'>
  > = new Subject<Pick<WidgetExtended, 'id' | 'deviceId' | 'transformations' | 'icon' | 'name' | 'file'>>();
  readonly status$: Observable<TDeviceStatus | undefined> = this._loading.pipe(
    switchMap((loading: boolean) => {
      if (!loading && 'status$' in this.command) {
        return this.command.status$;
      }
      return of(undefined);
    })
  );
  readonly footer$: Observable<Pick<DeviceDto, 'battery' | 'power'>> = this._loading.pipe(
    switchMap((loading: boolean) => {
      const mock: Pick<DeviceDto, 'battery' | 'power'> = {
        battery: undefined,
        power: undefined
      };
      if (!loading && 'footer$' in this.command) {
        return this.command.footer$ ?? of(mock);
      }
      return of(mock);
    })
  );

  readonly alarm$: Observable<boolean> = this._loading.pipe(
    switchMap((loading: boolean) => {
      if (!loading && this._visible.getValue()) {
        return this.command.device$.pipe(
          map(({ status, property }: DeviceDto) => {
            if (!property) return false;
            return (
              AlarmSensors.includes(property.type as AlarmSensorProperties) &&
              (property.values as SensorValue<boolean>).value
            );
          })
        );
      }
      return of(false);
    })
  );
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() deviceId: string = '';
  private _file: FileBlob | null = null;
  @Input() set file(file: FileBlob | null) {
    this._file = file;
  }
  get file() {
    return this._file;
  }

  private _transformations: WidgetTransformation[] | undefined;
  _intersectionObserver!: IntersectionObserver;
  public _visible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() set transformations(position: WidgetTransformation[] | undefined) {
    this._transformations = position;
  }
  get transformations() {
    return (this._transformations?.length && this._transformations) || [];
  }

  get firstPosition() {
    return this.transformations && this.transformations.length && this.transformations[0].transformation;
  }

  private _command: WidgetDeviceInformation<T> | undefined;
  @ContentChild(WIDGET_COMMAND) set command(command: WidgetDeviceInformation<T>) {
    if (!command) return;
    this._command = command;
    this._onComponentLoaded();
  }
  get command() {
    return this._command!;
  }
  @Output() goToPosition: EventEmitter<TransformationInterface> = new EventEmitter<TransformationInterface>();
  @Output() visible: Observable<boolean> = defer(() => this._visible.asObservable().pipe(distinctUntilChanged()));
  @Output() selectedCommand: Observable<PropertyValueChange<any> | undefined> = defer(() => {
    const command = this.command;
    if (command) {
      return command.valueChange$;
    }
    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.selectedCommand)
    );
  });

  async openDetailsModal() {
    this.openDetails.next({
      deviceId: this.deviceId,
      icon: this.icon,
      id: this.id,
      file: { name: '', blobPath: this.file?.blobPath, isBlockedBySubscriptionLimit: false },
      name: this.name,
      transformations: this.transformations
    } as Pick<WidgetExtended, 'id' | 'deviceId' | 'transformations' | 'icon' | 'name' | 'file'>);
  }
  ngOnDestroy(): void {
    this._intersectionObserver?.unobserve(this._el.nativeElement);
    this._destroy.next();
  }

  set icon(value) {
    this._icon = value;
  }

  get icon(): UAnimatedIcon {
    return this._icon || this.command?.icon || 'device_unknown';
  }

  private _icon: UAnimatedIcon | undefined;

  ngOnInit(): void {
    this._intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            !this._visible.getValue() &&
              setTimeout(() => {
                this._visible.next(true);
              }, Math.floor(Math.random() * 1000));
            this._cdr.reattach();
            this._cdr.markForCheck();
            this.command && (this.command.visible = true);
          } else {
            this._cdr.detach();
            this.command && (this.command.visible = false);
          }
        });
      },
      {
        threshold: 0.2
      }
    );
    this._intersectionObserver.observe(this._el.nativeElement);
  }
  private _onComponentLoaded() {
    this._visible
      .pipe(
        filter((visible) => visible),
        switchMap(() =>
          this.command.loading$.pipe(
            filter((loading) => !loading),
            first()
          )
        ),
        first()
      )
      .subscribe((loaded) => {
        this._loading.next(loaded);
      });
  }
}
