import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SimDividerComponent } from '@simOn/ui/sim-divider';
import { SimUserInfoComponent } from '@simOn/user/info/ui';
import { TLastVisitedUser } from '@simOn/user/last-visited/models';
import { LastActivePipe } from './sim-last-active.pipe';

@Component({
  selector: 'user-last-visited',
  template: `
    <div data-cy="active-users-container">
      <h4 i18n="@@ACTIVE_USERS" class="title">Active users</h4>
      <sim-divider></sim-divider>
      <ng-container *ngFor="let user of apartmentUsers; trackBy: trackById">
        <ng-container *ngIf="user.isActive">
          <user-info [userEmail]="user.email" [userName]="user.userName"></user-info>
          <sim-divider></sim-divider>
        </ng-container>
      </ng-container>
    </div>
    <div data-cy="last-visitors-container">
      <h5 i18n="@@LAST_VISITORS" class="title">Last visitors</h5>
      <sim-divider></sim-divider>
      <ng-container *ngFor="let user of apartmentUsers; trackBy: trackById">
        <ng-container *ngIf="!user.isActive">
          <div class="last-visitors" data-cy="last-visitors">
            <span class="last-visitors__email">{{ user.email }}</span>
            <span>{{ user.lastVisited | lastActive }}</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sim-active-users.component.scss'],
  standalone: true,
  imports: [SimDividerComponent, NgIf, NgFor, SimUserInfoComponent, LastActivePipe]
})
export class SimActiveUsersComponent implements OnChanges {
  @Input({ required: true }) apartmentUsers: TLastVisitedUser[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['apartmentUsers'])
      this.apartmentUsers = [...this.apartmentUsers].sort((a, b) => {
        if (a.lastVisited === null) return 1;
        if (b.lastVisited === null) return -1;

        return Date.parse(b.lastVisited) - Date.parse(a.lastVisited);
      });
  }

  trackById(idx: number, item: TLastVisitedUser) {
    return item.id;
  }
}
