import { Component, OnDestroy, OnInit, Renderer2, inject } from '@angular/core';

import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { BreakpointService, DeviceType, DeviceTypeEnum } from '@simOn/ui/breakpoint';
import { AuthenticatedResult, LoginResponse, OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { BehaviorSubject, Subject, Subscription, firstValueFrom, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@simOn/user/auth/service';
import { UsersFacade } from '@simOn/user/last-visited/state';
import { GIT_INFO, GitInfo } from '@simOn/utils/tokens';
import { StoreNotificationService } from './store/store-show-notification.service';

@Component({
  selector: 'sim-app-root',
  standalone: true,
  imports: [RouterModule],
  template: ` <router-outlet></router-outlet> `,
  host: {
    '[class.desktop]': 'isDesktop()'
  },
  styles: ['']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly authService = inject(AuthService);
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly router = inject(Router);
  // private readonly fibaroService = inject(FibaroApiService);
  private readonly storeNotificationService = inject(StoreNotificationService);
  private readonly renderer = inject(Renderer2);
  private readonly breakpointServer = inject(BreakpointService);
  private readonly usersFacade = inject(UsersFacade);
  private readonly gitInfo = inject<GitInfo>(GIT_INFO);
  readonly isDesktop = toSignal(this.breakpointServer.deviceType$.pipe(map((deviceType) => deviceType === 'desktop')));
  authorizationMessage = false;

  showSpacer = false;
  subscription: Subscription;

  fibaroAuthorizationSubscription: Subscription;

  urlWithApartmentId$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _destroy: Subject<void> = new Subject<void>();
  constructor() {
    firstValueFrom(this.breakpointServer.isMobile$).then((mobile) => {
      // this.fullscreenService.requestFullscreen();
      // mobile && screen?.orientation?.lock && screen?.orientation?.lock('portrait').catch((e) => alert(e));
    });
    this.router.events
      .pipe(
        filter((data) => data instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          if (event.urlAfterRedirects.includes('apartment?id')) {
            this.urlWithApartmentId$.next(true);
          } else {
            this.urlWithApartmentId$.next(false);
          }
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this._destroy.next();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e: NavigationEnd) => !!e && !e.url.includes('callbackMatterport')),
        take(1),
        switchMap(() => this.oidcSecurityService.checkAuth()),
        switchMap((loginResponse: LoginResponse) => {
          if (loginResponse.isAuthenticated) {
            const savedRouteForRedirect = localStorage.getItem('redirectUri');

            if (savedRouteForRedirect != null) {
              localStorage.removeItem('redirectUri');
              this.router.navigateByUrl(savedRouteForRedirect);
            }
            return this.oidcSecurityService.isAuthenticated$.pipe(
              switchMap((authenticated: AuthenticatedResult) => {
                if (!authenticated.isAuthenticated) {
                  return this.oidcSecurityService.logoffAndRevokeTokens();
                }
                return of(loginResponse);
              })
            );
          }
          return of(loginResponse);
        })
      )
      .subscribe();

    this.storeNotificationService.init();

    this.subscription = this.router.events
      .pipe(
        filter((el) => el instanceof NavigationEnd),
        filter((item: NavigationEnd) => item.url.indexOf('/apartment') === 0)
      )
      .subscribe(() => {
        this.showSpacer = true;
        this.subscription.unsubscribe();
      });

    this.oidcSecurityService.userData$.subscribe((isLogged) => {
      if (isLogged) {
      } else if (this.fibaroAuthorizationSubscription) {
        this.fibaroAuthorizationSubscription.unsubscribe();
      }
    });

    this.oidcSecurityService.isAuthenticated$
      .pipe(
        filter((hasValidToken: AuthenticatedResult) => hasValidToken.isAuthenticated),
        switchMap(() => this.oidcSecurityService.userData$),
        filter((user: UserDataResult) => !!user.userData),
        tap((user: UserDataResult) => {
          firstValueFrom(
            this.authService.userProfile$.pipe(
              filter((userProfile) => userProfile && userProfile.eulaAccepted && userProfile.profileExists)
            )
          ).then(() => this.usersFacade.setSelectedUserId(user.userData.sub));
        })
      )
      .subscribe();
    this._deviceTypeObserver();
  }

  // loginToFibaro() {
  //   this.fibaroAuthorize().subscribe((result: AuthorizeResponse) => {
  //     const redirectContentWindow = window.open(result.redirectUrl, '_blank');
  //     redirectContentWindow.onbeforeunload = () => this.fibaroService.userAuthorized().subscribe();
  //   });
  // }

  // fibaroAuthorize() {
  //   return this.fibaroService.loginWithAuthorize();
  // }

  // showGitInfo() {
  //   console.log(this.gitInfo.shaShort);
  // }

  private _deviceTypeObserver() {
    this.breakpointServer.deviceType$
      .pipe(
        takeUntil(this._destroy),
        tap((deviceType: DeviceType) => {
          const devices = Object.values(DeviceTypeEnum);
          devices.forEach((device) => document.documentElement.classList.remove(device));
          this.renderer.addClass(document.documentElement, deviceType);
        })
      )
      .subscribe();
  }
}
