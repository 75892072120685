import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SimlabErrorDto } from '@simOn/common/http';
import { ScenesApiService } from '@simOn/scene/element/data-access';
import {
  SceneShortcutBodyInterface,
  TSceneState,
  TSimlabSceneInterface,
  TSimlabSceneInterfaceBase
} from '@simOn/scene/element/models';
import { catchError, exhaustMap, iif, map, mergeMap, of, switchMap } from 'rxjs';
import {
  AddShortcutScene,
  AddShortcutSceneFailure,
  AddShortcutSceneSuccess,
  ChangeSimlabSceneStatus,
  ChangeSimlabSceneStatusFailure,
  ChangeSimlabSceneStatusSuccess,
  DeleteScene,
  DeleteSceneFailure,
  DeleteSceneSuccess,
  DeleteShortcutScene,
  DeleteShortcutSceneFailure,
  DeleteShortcutSceneSuccess,
  GetScenes,
  GetScenesFailure,
  GetScenesSuccess,
  GetSimlabScenes,
  GetSimlabScenesFailure,
  GetSimlabScenesSuccess,
  RunScene,
  RunSceneFailure,
  RunSceneSuccess,
  UpdateShortcutScene,
  UpdateShortcutSceneFailure,
  UpdateShortcutSceneSuccess,
  UpsertScene,
  UpsertSceneFailure,
  UpsertSceneSuccess
} from './scene.actions';
@Injectable()
export class SceneEffects {
  private readonly actions$ = inject(Actions);
  private readonly scenesApiService = inject(ScenesApiService);
  getSimlabScenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSimlabScenes),
      mergeMap((result) =>
        this.scenesApiService.loadSimlabScenes$().pipe(
          map((response: TSceneState[]) => GetSimlabScenesSuccess({ scenes: response })),
          catchError((error) => of(GetSimlabScenesFailure({ error })))
        )
      )
    )
  );

  getScenes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetScenes),
      mergeMap((result) =>
        this.scenesApiService.loadScenesFromApartment$().pipe(
          map((response: TSceneState[] | undefined) => {
            if (!response) throw new Error('TSceneState are not correctly set');
            return GetScenesSuccess({ scenes: response });
          }),
          catchError((error) => of(GetScenesFailure({ error })))
        )
      )
    )
  );

  deleteScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteScene),
      exhaustMap((result) =>
        this.scenesApiService.deleteSimlabScene(result.scene).pipe(
          map((response: string) => DeleteSceneSuccess({ sceneId: response })),
          catchError((error) => {
            return of(DeleteSceneFailure({ error }));
          })
        )
      )
    )
  );

  runScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RunScene),
      exhaustMap((result) =>
        this.scenesApiService.runSimlabScene(result.sceneId).pipe(
          map(() => RunSceneSuccess()),
          catchError((error) => of(RunSceneFailure({ error })))
        )
      )
    )
  );

  upsertScene$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpsertScene),
      exhaustMap((result) =>
        iif(
          () => !!result.scene.id,
          this.scenesApiService.updateSimlabScene(result.scene),
          this.scenesApiService.addSimlabScene(result.scene)
        ).pipe(
          map((response: void | TSimlabSceneInterface) => {
            const scene: TSimlabSceneInterfaceBase = response || result.scene;
            return UpsertSceneSuccess({ scene });
          }),
          catchError((error: SimlabErrorDto) => {
            return of(UpsertSceneFailure({ error }));
          })
        )
      )
    )
  );

  addShortcutScene = createEffect(() =>
    this.actions$.pipe(
      ofType(AddShortcutScene),
      exhaustMap((result) =>
        this.scenesApiService
          .addSceneShortcut(result.shortcut)
          .pipe(map((response: SceneShortcutBodyInterface) => response))
      ),
      map((response: SceneShortcutBodyInterface) => AddShortcutSceneSuccess({ shortcut: response })),
      catchError((error) => of(AddShortcutSceneFailure({ error })))
    )
  );

  changeSimlabSceneStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(ChangeSimlabSceneStatus),
      exhaustMap((result) =>
        this.scenesApiService.changeSimlabSceneStatus(result.sceneId, result.isActive).pipe(
          map(() => ChangeSimlabSceneStatusSuccess({ isActive: result.isActive, sceneId: result.sceneId })),
          catchError((error) => of(ChangeSimlabSceneStatusFailure({ error })))
        )
      )
    )
  );

  updateShortcutScene = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateShortcutScene),
      switchMap((result) =>
        this.scenesApiService.updateSceneShortcut(result.shortcut).pipe(map(() => result.shortcut))
      ),
      map((response: SceneShortcutBodyInterface) => UpdateShortcutSceneSuccess({ shortcut: response })),
      catchError((error) => of(UpdateShortcutSceneFailure({ error })))
    )
  );

  deleteShortcutScene = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteShortcutScene),
      exhaustMap((result) =>
        this.scenesApiService.deleteSceneShortcut(result.shortcut.id).pipe(map(() => result.shortcut.sceneId))
      ),
      map((response: string) => DeleteShortcutSceneSuccess({ sceneId: response })),
      catchError((error) => of(DeleteShortcutSceneFailure({ error })))
    )
  );
}
