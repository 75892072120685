<div
  cdk-overlay-origin
  [attr.aria-owns]="panelOpen ? id + '-panel' : null"
  class="sim-select__trigger"
  [class.sim-select__trigger--focused]="focused"
  (click)="toggle()"
  #origin="cdkOverlayOrigin"
  #trigger
  [id]="id"
  [ngClass]="{ 'suffix-padding': suffix() }"
>
  <div #element class="sim-select__icon-container" *ngIf="iconUrl">
    <div class="sim-select__icon-content">
      <sim-icon [icon]="iconUrl" [color]="iconColor" sizeType="small"></sim-icon>
    </div>
  </div>
  <div class="sim-select__select-value" [ngSwitch]="empty" [attr.id]="_valueId">
    <span class="sim-select__select-value--placeholder select-min-line" *ngSwitchCase="true">{{ placeholder }}</span>
    <span class="select-value-text" *ngSwitchCase="false">
      <span class="select-min-line">{{ triggerValue }}</span>
    </span>
  </div>

  <div class="sim-select__arrow-wrapper">
    <sim-icon icon="arrow_down" [color]="arrowColor" sizeType="small"></sim-icon>
  </div>

  @if (suffix()) {
  <span class="suffix-wrapper">
    <ng-content *ngTemplateOutlet="suffix() ?? null"> </ng-content>
  </span>
  }
</div>
<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayPanelClass]="customOverlayClass"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayPositions]="_positions"
  [cdkConnectedOverlayMinWidth]="_triggerRect?.width!"
  (backdropClick)="close()"
  (attach)="_onAttached()"
  (detach)="close()"
>
  <div
    [class]="showSearchInput ? 'select-panel-wrap-expanded' : 'select-panel-wrap'"
    [style.width]="_triggerRect?.width + 'px'"
  >
    <sim-form-field *ngIf="showSearchInput">
      <sim-input
        i18n-placeholder="@@GENERAL_SEARCH"
        id="select-search-input"
        [suffix]="searchSuffix"
        [prefix]="searchPrefix"
        [showPrefix]="!inputValue"
        [value]="inputValue"
        (valueChange)="emitInputValueChanges($event)"
        class="select-panel-wrap-expanded--input"
        placeholder="Search"
      >
      </sim-input>
    </sim-form-field>
    <div
      #panel
      role="listbox"
      tabindex="-1"
      class="select-panel select-value-text scrollbar"
      [attr.id]="id + '-panel'"
      [attr.aria-multiselectable]="multiple"
      [attr.aria-label]="ariaLabel || null"
      [attr.aria-labelledby]="_getPanelAriaLabelledby()"
      [@transformPanel]="multiple ? 'showing-multiple' : 'showing'"
      (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
      [style.transformOrigin]="_transformOrigin"
      [style.font-size.px]="_triggerFontSize"
      (keydown)="_handleKeydown($event)"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>

<ng-template #searchPrefix
  ><sim-icon color="var(--ui-secondary-lighter)" icon="search" sizeType="small"></sim-icon
></ng-template>

<ng-template #searchSuffix>
  <sim-search-suffix *ngIf="inputValue" (click)="emitInputValueChanges('')"></sim-search-suffix>
</ng-template>
