import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { filter, Observable, take } from 'rxjs';
import { ScriptLoadResponse } from '../../models/script-load-response';
import { SecureForm } from '../../models/secure-form';
import {
  ControlName,
  SecureFormChangeResponse,
  SecureFormValueChange,
} from '../../models/secure-form-change-response';
import { SecureFormOptions } from '../../models/secure-form-options';
import {
  TokenizeResultSuccess,
  TokenizeResultError,
} from '../../models/tokenize-result';
import { BasePaymentsService } from '../../services/base-payments.service';
import { Payments } from '../../services/payments.service';
@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers:[
    Payments,
    BasePaymentsService,
  ]
})
export class PaymentFormComponent implements OnDestroy {
  @Output() readonly tokenize: Observable<
    TokenizeResultSuccess | TokenizeResultError
  > = this._baseService.tokenize.asObservable();
  @Output() readonly formChanges: Observable<SecureFormValueChange> =
    this._baseService.formChanges.asObservable();
  private _options: SecureFormOptions = this._baseService.options;
  private _secureForms!: payu.SecureForms | null;
  private readonly _controlsRef: Record<ControlName,payu.SecureForm>={} as Record<ControlName,payu.SecureForm>;
  @Input() set options(value: SecureFormOptions) {
    this._baseService.options = { ...this._baseService.options, ...value };
    this._options = this._baseService.options;
  }
  @Input() cardNumberRef?: TemplateRef<any>;
  @Input() cardDateRef?: TemplateRef<any>;
  @Input() cardCvvRef?: TemplateRef<any>;
  @Input() set posId(posId:string){
    this._baseService.posId = posId
  }
  constructor(private readonly _baseService: BasePaymentsService, readonly payments: Payments ) {
    this._baseService.loadingStatus
      .asObservable()
      .pipe(
        filter((value: ScriptLoadResponse) => {
          return value.loaded && !value.error;
        }),
        take(1)
      )
      .subscribe(() => {
        if(!this._secureForms)
        this._secureForms = this._baseService.secureForms
        this._createSecureForm();
      });
  }
  ngOnDestroy(): void {
    Object.entries(this._controlsRef).forEach((e: [string, payu.SecureForm])=>{
       e[1].remove()
      })
  }
  private _renderControls = (control: SecureForm, name: ControlName) => {
    if(document.querySelector(`#payu-card-${name}`)?.children.length){
      document.querySelector(`#payu-card-${name}`)?.removeChild(document.querySelector(`#payu-card-${name}`)?.children[0]!)
    }
    return control.render(`#payu-card-${name}`);
  };

  private _addOnChangeEvent = (control: SecureForm, name: ControlName) => {
    return control.on('change', (body: SecureFormChangeResponse) =>
      this._baseService.formValueChange(body, name)
    );
  };

  private _addControl = (secureForms: payu.SecureForms, name: ControlName) =>
    secureForms.add(name, this._options);

  private _createSecureFormControl = (
    secureForms: payu.SecureForms,
    name: ControlName
  ) => {
    this._controlsRef[name] = this._addControl(secureForms, name);
    this._renderControls(this._controlsRef[name], name);
    this._addOnChangeEvent(this._controlsRef[name], name);
  };

  private _createSecureForm() {

    if (this._secureForms) {
      try {
        this._createSecureFormControl(this._secureForms, 'number');
        this._createSecureFormControl(this._secureForms, 'date');
        this._createSecureFormControl(this._secureForms, 'cvv');
        this._baseService._loadingStatusChange({
          error: false,
          message: 'Successfully loaded',
          loaded: true,
        });
      } catch (error) {
        console.log(error);
        this._baseService._loadingStatusChange({
          error: true,
          message: JSON.stringify(error),
          loaded: false,
        });
      }
    } else {
      this._baseService._loadingStatusChange({
        error: true,
        message: 'Secure form is not initialize',
        loaded: false,
      });
    }
  }
}
