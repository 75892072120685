import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { UploadApiService } from '@simOn/common/upload-queue/data-access';
import { AddTicketCommentInterface, UpdateTicketCommentInterface } from '@simOn/ticket/comments/models';
import { TicketAttachments } from '@simOn/ticket/description/models';
import { LOCAL_TICKET_ID, TTicketState, TUpdateTicket } from '@simOn/ticket/element/models';
import { map, Observable } from 'rxjs';
import {
  AddLocalTicket,
  AddTicket,
  AddTicketComment,
  AddTicketSuccess,
  ClearTicketState,
  DeleteLocalTicket,
  DeleteTicket,
  DeleteTicketComment,
  DeleteTicketFromStore,
  DeleteUploadedFileSimplified,
  GetTicket,
  GetTicketFailure,
  GetTickets,
  GetTicketSuccess,
  HandleTicketModified,
  HandleTicketModifiedSuccess,
  MarkTicketAsRead,
  PreventTicketRefresh,
  SetSelectedId,
  UpdateLocalTicket,
  UpdateTicket,
  UpdateTicketComment,
  UploadFileWithProgress,
  UploadFileWithProgressSuccess
} from './ticket.actions';
import {
  GetAttachmentSelectedTicket,
  GetLocalTicket,
  GetSelectedTicket,
  GetSelectedTicketById,
  GetSelectedTicketId,
  SelectAllTickets,
  SelectPreventRefresh,
  TicketsEntities,
  TicketsNotificationCounter
} from './ticket.selectors';

@Injectable({
  providedIn: 'root'
})
export class TicketFacade {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly mediaService = inject(UploadApiService);
  allTickets$: Observable<TTicketState[]> = this.store.select(SelectAllTickets);
  selectedTicket$: Observable<TTicketState | undefined> = this.store.select(GetSelectedTicket);
  selectedTicketAttachments$: Observable<TicketAttachments[] | undefined> =
  this.store.select(GetAttachmentSelectedTicket);
  selectedTicketId$: Observable<string | undefined> = this.store.select(GetSelectedTicketId);
  addAssetSuccess$ = this.actions$.pipe(
    ofType(AddTicketSuccess),
    map(() => true)
  );
  notesNotificationCounter$: Observable<number> = this.store.select(TicketsNotificationCounter);

  readonly localTicket$: Observable<TTicketState | undefined> = this.store.select(GetLocalTicket);
  readonly getTicketAction$ = this.actions$.pipe(ofType(GetTicketSuccess, GetTicketFailure));
  readonly preventRefresh$: Observable<boolean | undefined> = this.store.select(SelectPreventRefresh);
  readonly ticketsEntity$: Observable<Dictionary<TTicketState>> = this.store.select(TicketsEntities);
  readonly modified$ = this.actions$.pipe(ofType(HandleTicketModifiedSuccess));
  readonly lastUploaded$ = this.actions$.pipe(ofType(UploadFileWithProgressSuccess));
  readonly deletedTicketFromStore$ = this.actions$.pipe(ofType(DeleteTicketFromStore));

  setSelectedId(selectedId: string): void {
    this.store.dispatch(SetSelectedId({ selectedId }));
  }

  selectedTicketById$ = (id: string): Observable<TTicketState | undefined> =>
    this.store.select(GetSelectedTicketById(id));

  getTicket(ticketId: string): void {
    this.store.dispatch(GetTicket({ state: ticketId }));
  }

  getTickets(apartmentId: string, autoUpdate: boolean): void {
    this.store.dispatch(GetTickets({ state: apartmentId, autoUpdate }));
  }

  addTicket(ticket: TUpdateTicket): void {
    this.store.dispatch(AddTicket({ state: ticket }));
  }

  updateLocalTicket(ticket: TUpdateTicket): void {
    this.store.dispatch(UpdateLocalTicket({ state: ticket }));
  }

  addLocalTicket(ticket: TTicketState): void {
    this.store.dispatch(AddLocalTicket({ state: ticket }));
  }

  handleTicketModified(ticketId: string): void {
    this.store.dispatch(HandleTicketModified({ ticketId }));
  }

  updateTicket(ticket: TUpdateTicket): void {
    this.store.dispatch(UpdateTicket({ state: ticket }));
  }

  deleteTicket(ticketId: string): void {
    this.store.dispatch(DeleteTicket({ state: ticketId }));
  }

  addTicketComment(ticketId: string, chatMessage: AddTicketCommentInterface): void {
    this.store.dispatch(AddTicketComment({ state: { ticketId: ticketId, ticketComment: chatMessage } }));
  }

  deleteLocalTicket(): void {
    this.store.dispatch(DeleteLocalTicket({ state: LOCAL_TICKET_ID }));
  }

  deleteTicketFromStore(ticketId: string): void {
    this.store.dispatch(DeleteTicketFromStore({ ticketId }));
  }

  updateTicketComment(ticketId: string, chatMessage: UpdateTicketCommentInterface): void {
    this.store.dispatch(UpdateTicketComment({ state: { ticketId: ticketId, ticketComment: chatMessage } }));
  }

  deleteTicketComment(commentId: string): void {
    this.store.dispatch(DeleteTicketComment({ state: commentId }));
  }

  uploadFileSimplifiedWithProgress(formData: FormData, fileName: string) {
    this.mediaService.fileToUpload = formData;
    this.store.dispatch(UploadFileWithProgress({ fileName }));
  }

  deleteUploadFile(fileGuid: string): void {
    this.store.dispatch(DeleteUploadedFileSimplified({ state: { fileGuid } }));
  }

  preventRefresh(prevent: boolean): void {
    this.store.dispatch(PreventTicketRefresh({ prevent }));
  }

  markAsRead(ticketId: string): void {
    this.store.dispatch(MarkTicketAsRead({ ticketId: ticketId }));
  }

  clearState() {
    this.store.dispatch(ClearTicketState());
  }
}
