import { Injectable, NgZone, OnDestroy, Optional } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ModelSweep } from '@simlab/matterport/api';
import { TransformConverter, Vector } from '@simlab/matterport/transform';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Camera, Sweep } from 'mpSdk';
import {
  BehaviorSubject,
  Observable,
  defer,
  filter,
  firstValueFrom,
  map,
  skip,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs';
import { Vector3 } from 'three';
import { MatterportServiceBase } from '../base/matterport-base';
import { RaycasterHelper } from '../helpers/raycaster';
import { randomUUID } from '../helpers/uuid';
import {
  ComponentsTypeClass,
  MatterportComponent,
  SpriteConfiguration,
  ViewMode,
} from '../models/custom-component.type';
import { IPortals, PortalConfiguration } from '../models/portal';
import { SpriteComponent } from '../public-api';
import { MatterportManagerService } from './matterport-manager.service';
import {
  MatterportPortalsService,
  PortalPosition,
} from './matterport-portals.service';

type PortalDirection = 'up' | 'down' | 'go';
const PORTAL_ICONS: Record<PortalDirection, string> = {
  up: '/assets/icons/icon_portal_up.svg',
  down: '/assets/icons/icon_portal_down.svg',
  go: '/assets/icons/icon_portal_go.svg',
};

@Injectable()
export class MatterportPortalManagerService
  extends MatterportServiceBase
  implements OnDestroy, IPortals
{
  private _raycasterHelper!: RaycasterHelper;
  private _portals: Record<
    string,
    PortalConfiguration & Partial<MatterportComponent<ComponentsTypeClass>>
  > = {};
  private readonly _currentMtpSweeps$: Observable<ModelSweep[]> = defer(() =>
    this.matterportManager.state.availableSweeps$.pipe(
      filter((sweeps) => !!sweeps),
      map((sweeps) => sweeps as ModelSweep[])
    )
  );
  private _mode: BehaviorSubject<ViewMode> = new BehaviorSubject<ViewMode>(
    ViewMode.INSIDE
  );
  constructor(
    private readonly _ngZone: NgZone,
    @Optional()
    private readonly portal: MatterportPortalsService,
    private readonly matterportManager: MatterportManagerService
  ) {
    super(matterportManager);
    this._pointerRaycastObserver();
  }
  private _pointerRaycastObserver() {
    this.isOpen
      .asObservable()
      .pipe(
        filter((isOpen) => isOpen),
        switchMap(() => this.matterportManager.state.positionChange$),
        throttleTime(500),

        tap(() => {
          Object.keys(this._portals).forEach((portalId: string) => {
            const objects = [...this._intersectObjects(portalId)];
            const component = this._portals[portalId].comp;
            if (!component) return;
            if (this._mode.getValue() === ViewMode.INSIDE) {
              const portalIndex = objects.findIndex(
                (object) => object.object.name === portalId
              );
              const firstComponent = objects[0];
              if (
                objects.length > 1 &&
                portalIndex > 0 &&
                !Object.keys(this._portals).includes(firstComponent.object.name)
              ) {
                const direction = firstComponent.point
                  .clone()
                  .sub(component?.cameraContainer.position)
                  .multiply(new Vector3(-0.3, -0.3, -0.3));
                if (component) {
                  component.position = firstComponent.point
                    .clone()
                    .add(direction);
                }
              }
            } else {
              const calcPosition = this._portals[portalId].calculatedPosition;
              if (calcPosition)
                component.position = new Vector3(
                  calcPosition.x,
                  calcPosition.y,
                  calcPosition.z
                );
            }
          });
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }
  private _intersectObjects(portalId: string) {
    if (!this._portals[portalId]) return [];
    const component = this._portals[portalId].comp;
    if (component) {
      const cameraPosition = component.cameraContainer.position;
      if (!this._raycasterHelper) {
        const camera = component.camera;
        const scene = component.scene;
        const THREE = component.context.three;
        this._raycasterHelper = new RaycasterHelper(THREE, camera, scene);
        this._raycasterHelper.threshold = 10;
      }

      return this._raycasterHelper.getIntersectObjects(
        cameraPosition,
        component.position
      );
    }
    return [];
  }

  async createPortal(payload: PortalConfiguration) {
    const currentPosition =
      payload.currentPosition ||
      (await firstValueFrom(
        this.matterportManager.state.sweepChange$.pipe(
          map(
            (position) =>
              (position &&
                new Vector(
                  position.position.x,
                  position.position.y,
                  position.position.z
                )) ||
              new Vector(0, 0, 0)
          )
        )
      ));
    const currentMtpSweeps: ModelSweep[] =
      payload.currentMtpSweeps ||
      (await firstValueFrom(this._currentMtpSweeps$));
    const currentMtpOffset = payload.currentMtpOffset || this.currentMtpOffset;
    const id: string = payload.id || this.uuid;
    this._portals = {
      ...this._portals,
      [id]: { ...payload, id },
    };

    this.portal &&
      this.portal.createPortal({
        id,
        currentPosition,
        currentMtpOffset,
        currentMtpSweeps,
        otherMtpOffset: payload.otherMtpOffset,
        otherMtpSweeps: payload.otherMtpSweeps,
      });
  }

  get uuid(): string {
    return crypto?.randomUUID ? crypto.randomUUID() : randomUUID();
  }
  get currentMtpOffset() {
    return (this.matterportManager.transformConverter as TransformConverter)
      .transform;
  }
  override ngOnDestroy(): void {
    this.portal && this.portal.ngOnDestroy();
    this._portals = {};
    try {
      this.sdk?.off(this.sdk.Mode.Event.CHANGE_START, this._modeChange);
    } catch (e) {
      console.log(e);
    }
    super.ngOnDestroy();
  }
  private _portalsObserver() {
    this._ngZone.runOutsideAngular(() => {
      this.isOpen
        .asObservable()
        .pipe(
          filter((isOpen) => isOpen),
          takeUntil(this._destroy),
          switchMap(() => this.portal.portal$)
        )
        .subscribe((portalPosition: PortalPosition) => {
          const portal = this._portals[
            portalPosition.id
          ] as PortalConfiguration &
            Partial<MatterportComponent<ComponentsTypeClass>>;
          if (!portal) return;
          this._portalComponent(portalPosition, portal);
        });
    });
  }
  private async _portalComponent(
    portalPosition: PortalPosition,
    portal: PortalConfiguration &
      Partial<MatterportComponent<ComponentsTypeClass>>
  ) {
    const currentPosition = (await firstValueFrom(
      this.matterportManager.state.positionChange$.pipe(
        filter((camera) => !!camera)
      )
    )) as Camera.Pose;

    const { x, y, z } = portalPosition.position;
    if (portal.comp) {
      this._updateComponent(
        portal as PortalConfiguration & MatterportComponent<SpriteComponent>,
        new Vector3(x, y, z),
        currentPosition.position as Vector3
      );
    } else {
      this._addPortalComponent(
        portal,
        new Vector3(x, y, z),
        currentPosition.position as Vector3
      );
    }
  }
  private _updateComponent<T extends ComponentsTypeClass = SpriteComponent>(
    portal: PortalConfiguration & MatterportComponent<T>,
    position: Vector3,
    currentPosition: Vector3
  ) {
    this._ngZone.runOutsideAngular(async () => {
      const component = portal.comp;
      if (
        this._portalVisible(
          new Vector3(currentPosition.x, currentPosition.y, currentPosition.z),
          new Vector3(position.x, position.y, position.z)
        )
      ) {
        component.show();

        //TODO
        'icon' in portal.children[0] &&
          ((portal.children[0] as SpriteComponent).icon = this._getIcon(
            portal,
            currentPosition
          ));
      } else {
        component.hide();
      }
      component.position = position;
    });
  }

  private _portalVisible(cameraPosition: Vector3, portalPosition: Vector3) {
    const distance = cameraPosition.distanceTo(portalPosition);
    if (distance < 10 && Math.abs(cameraPosition.y - portalPosition.y) < 5) {
      return true;
    } else {
      return false;
    }
  }
  private _getIcon(portal: PortalConfiguration, cameraPosition: Vector3) {
    let avgSweepPosition = portal.otherMtpSweeps.reduce(
      (prev: { x: number; y: number; z: number }, act: ModelSweep) => ({
        x: prev.x + act.position.x,
        y: prev.y + act.position.y,
        z: prev.z + act.position.z,
      }),
      { x: 0, y: 0, z: 0 }
    );
    const tmp = new Vector(
      avgSweepPosition.x / portal.otherMtpSweeps.length,

      avgSweepPosition.z / portal.otherMtpSweeps.length,
      -(avgSweepPosition.y / portal.otherMtpSweeps.length)
    );
    avgSweepPosition = this.matterportManager.transformConverter.to3dPosition(
      tmp,
      portal.otherMtpOffset
    );
    const avgCameraPosition =
      this.matterportManager.transformConverter.to3dPosition(
        new Vector(cameraPosition.x, cameraPosition.y, cameraPosition.z)
      );
    const yDistance = avgSweepPosition.y - avgCameraPosition.y;
    if (yDistance > 1.2) return PORTAL_ICONS['up'];
    if (yDistance < -2) return PORTAL_ICONS['down'];
    return PORTAL_ICONS['go'];
  }

  private _addPortalComponent(
    portal: PortalConfiguration,
    calculatedPosition: Vector3,
    currentPosition: Vector3
  ) {
    this._ngZone.runOutsideAngular(async () => {
      await firstValueFrom(
        this.matterportManager.component
          .addComponent$({
            id: portal.id,
            position: calculatedPosition,
            normal: new Vector3(),
            stemHeight: 0,
            depthTest: false,
            userData: {
              type: 'portal',
            },
            autoScale: false,
            opacity: 0.99,
            scale: new Vector3(0.1, 0.1, 0.1),
            transparent: true,
            objects: [
              new SpriteConfiguration({
                icon: this._getIcon(portal, currentPosition),
              }),
            ],
          })
          .pipe(
            tap(
              (
                component: MatterportComponent<ComponentsTypeClass> | undefined
              ) => {
                if (
                  !this._portalVisible(
                    new Vector3(
                      currentPosition.x,
                      currentPosition.y,
                      currentPosition.z
                    ),
                    new Vector3(
                      calculatedPosition.x,
                      calculatedPosition.y,
                      calculatedPosition.z
                    )
                  )
                ) {
                  component?.comp.hide();
                }
                if (component && this._portals[portal.id]) {
                  this._portals = {
                    ...this._portals,
                    [portal.id]: {
                      ...this._portals[portal.id],
                      ...component,
                      calculatedPosition,
                    },
                  };
                }
              }
            )
          )
      );
    });
  }

  private _spawnPortalsComponents() {
    this._ngZone.runOutsideAngular(() => {
      this.isOpen
        .asObservable()
        .pipe(
          takeUntil(this._destroy),
          switchMap(() => this.matterportManager.state.sweepChange$),

          filter(
            (value: Sweep.ObservableSweepData | null) =>
              !!value && value.enabled
          ),
          skip(1),

          map((value: Sweep.ObservableSweepData | null) => {
            const position = value?.position;
            return (
              position
                ? new Vector(position.x, position.y, position.z)
                : new Vector(0, 0, 0)
            ) as Vector;
          })
        )
        .subscribe((currentPosition: Vector) => {
          Object.values(this._portals).forEach((portal) => {
            if (!portal.comp) return;
            this.createPortal({
              ...portal,
              currentPosition,
            });
          });
        });
    });
  }
  protected _init(): void {
    if (this.portal) {
      this._portalsObserver();
      this._spawnPortalsComponents();
      this.sdk.on(this.sdk.Mode.Event.CHANGE_START, this._modeChange);
    }
  }
  private _modeChange = (_: string, newMode: string) => {
    this._mode.next(newMode as ViewMode);
  };
}
