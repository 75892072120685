import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoomInterface } from '@simOn/room/models';
import { State } from '@simOn/utils';
import * as RoomsReducer from './room.reducers';

export const selectRoomsState = createFeatureSelector<State<RoomInterface>>('rooms');

export const SelectAllRooms = createSelector(selectRoomsState, RoomsReducer.selectAll);

export const SelectRooms = createSelector(SelectAllRooms, (rooms) => {
  const masterRoom = rooms.find(({ id }) => id === 'masterRoom')!;
  const filteredRooms = rooms.filter(({ id }) => id !== 'masterRoom');
  masterRoom && filteredRooms.push(masterRoom);
  return filteredRooms;
});

export const SelectAllRoomsWithoutMaster = createSelector(SelectAllRooms, (rooms) =>
  rooms.filter((room) => room.id !== 'masterRoom')
);

export const roomsLoaded = createSelector(selectRoomsState, (state) => state.isLoaded);
export const selectedRoomId = createSelector(selectRoomsState, (state) => state.selectedId);
export const selectRoomById = (roomId: string) => createSelector(selectRoomsState, (state) => state.entities[roomId]!);
export const selectRoomNameById = (roomId: string) =>
  createSelector(selectRoomsState, (state) => state.entities[roomId]!.name!);

export const SelectMasterRoom = createSelector(selectRoomsState, (state) => state.entities['masterRoom']);
export const MasterRoomsLoaded = createSelector(selectRoomsState, (state) => !!state.entities['masterRoom']);

export const RoomName = (roomGuid: string) => createSelector(selectRoomsState, (state) => state.entities[roomGuid]!);
export const SelectRoomContentById = (roomGuid: string, id: string) =>
  createSelector(RoomName(roomGuid), (state: RoomInterface) =>
    state.matterportRoomContents!.find((content) => content.matterportModelId === id)
  );

export const GetSelectedId = createSelector(selectRoomsState, ({ selectedId }) => {
  return selectedId;
});
