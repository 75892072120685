import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ComponentRef,
  Directive,
  effect,
  inject,
  Input,
  input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewContainerRef
} from '@angular/core';
import { CurrencyValuePipe } from '@simOn/common/pipes';
import { SHORT_UNITS } from '@simOn/common/units';
import { DevicesFacade } from '@simOn/device';
import {
  ComponentType,
  DEVICE_ERROR_STATUSES,
  DEVICE_STATUS_INFORMATION,
  DEVICE_TYPE_ICON,
  DeviceDto,
  Properties,
  PROVIDER_ERROR_PROPERTY_TEMPLATE,
  SimlabPropertyInterface,
  SimpleValue,
  SUBSCRIPTION_LIMIT_PROPERTY_TEMPLATE,
  TDeviceStatus,
  TypeValues,
  UserPrefEnergyConsumption,
  UserPreferencesInterface
} from '@simOn/device/models';

import { CommandValuesType, PropertyValueChange, WidgetDeviceInformation } from '@simOn/device/models';
import { WIDGET_COMMAND } from '@simOn/device/widget/tokens';
import { UAnimatedIcon } from '@simOn/ui/sim-animated-icons';
import {
  BehaviorSubject,
  defer,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  pairwise,
  Subject,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap
} from 'rxjs';
import { AccelerationComponent } from '../acceleration/acceleration.component';
import { AlarmComponent } from '../alarm/alarm.component';
import { BlockedComponent } from '../blocked/blocked.component';
import { DetectorComponent } from '../detector/detector.component';
import { DisplayComponent } from '../display/display.component';
import { LevelComponent } from '../level/level.component';
import { RGBWComponent } from '../rgbw/rgbw.component';
import { SceneComponent } from '../scene/scene.component';
import { SetBooleanComponent } from '../set-boolean/set-boolean.component';
import { SetDateComponent } from '../set-date/set-date.component';
import { SetModeComponent } from '../set-mode/set-mode.component';
import { SetNumberComponent } from '../set-number/set-number.component';
import { SetValueComponent } from '../set-value/set-value.component';
import { SwitchComponent } from '../switch/switch.component';
import { PropertyBase } from './property-base.directive';

export type PropertiesComponents = TypeValues<typeof COMPONENTS, any>;
export const COMPONENTS: {
  [name in Properties]: Type<any> | undefined;
} = {
  Unknown: AlarmComponent,
  Weather: AlarmComponent,
  MotionDetected: DetectorComponent,
  Temperature: DisplayComponent,
  WindSpeed: DisplayComponent,
  LightIntensity: DisplayComponent,
  EarthTremor: DisplayComponent,
  Acceleration3D: AccelerationComponent,
  SmokeDetected: DetectorComponent,
  HeatDetected: DetectorComponent,
  Opened: DetectorComponent,
  CoDetected: DetectorComponent,
  SetTemperature: LevelComponent,
  Clicked: DisplayComponent,
  BatteryLevel: AlarmComponent,
  PowerConsumption: DisplayComponent,
  EnergyConsumption: DisplayComponent,
  Level: LevelComponent,
  RgbwColor: RGBWComponent,
  FloodDetected: DetectorComponent,
  ExternalSensorConnected: DisplayComponent,
  RainDetector: DisplayComponent,
  NextScheduledTemperatureLevel: AlarmComponent,
  NextScheduledTemperatureTime: AlarmComponent,
  SetThermostatMode: SetModeComponent,
  ScheduleOverridden: AlarmComponent,
  ShakeDetected: DetectorComponent,
  GasDetected: DetectorComponent,
  AlarmTriggered: DetectorComponent,
  StaticLevel: DisplayComponent,
  Humidity: DisplayComponent,
  Co2Level: DisplayComponent,
  LowBatteryDetected: AlarmComponent,
  HeatingMode: AlarmComponent,
  TemperatureShift: AlarmComponent,
  HvacOperationMode: AlarmComponent,
  Scene: SceneComponent,
  AudioVolume: AlarmComponent,
  TvChannel: AlarmComponent,
  TvChannelName: AlarmComponent,
  TurnedOn: SwitchComponent,
  ProviderError: AlarmComponent,
  SubscriptionLimit: BlockedComponent,
  Boolean: SetBooleanComponent,
  Integer: SetNumberComponent,
  Number: SetNumberComponent,
  UInteger: SetNumberComponent,
  DateTime: SetDateComponent,
  String: SetValueComponent,
  LevelDimmer: LevelComponent,
  LevelRollerShutter: LevelComponent
} as const;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[simPropertyComponentLoader]',
  exportAs: 'simPropertyComponentLoader',
  standalone: true,

  providers: [
    CurrencyValuePipe,
    {
      provide: WIDGET_COMMAND,
      useExisting: PropertyComponentLoaderDirective,
      multi: true
    }
  ]
})
export class PropertyComponentLoaderDirective<T extends SimlabPropertyInterface<any> = any, W = any>
  implements OnInit, OnDestroy, WidgetDeviceInformation<T, W>
{
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _deviceFacade = inject(DevicesFacade);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private _device$: Observable<DeviceDto> = defer(() =>
    this._deviceFacade
      .getFirstDeviceWithProperties$(this.deviceId, this.ignoreVisibleProperties)
      .pipe(filter((device) => !!device))
  );

  private _componentRef: ComponentRef<PropertyBase<Properties, any, any>> | null | undefined = null;
  private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @Input() ignoreVisibleProperties: boolean = false;

  readonly userPreference = input.required<UserPreferencesInterface>();

  readonly deviceLoaded$ = defer(() => this._loading.pipe(filter((loading) => !loading)));
  readonly loading$: Observable<boolean> = this._loading.asObservable();

  readonly status$: Observable<TDeviceStatus | undefined> = this.deviceLoaded$.pipe(
    switchMap(() =>
      this._device$.pipe(map((device: DeviceDto) => (device.isBlockedBySubscriptionLimit ? 'Blocked' : device.status)))
    )
  );
  readonly device$ = this.deviceLoaded$.pipe(switchMap(() => this._device$));
  readonly footer$: Observable<any> | undefined = defer(() =>
    this.deviceLoaded$.pipe(
      switchMap(() =>
        this._device$.pipe(
          map(({ battery, power }: DeviceDto) => ({
            battery,
            power: {
              ...power,
              stringify: () =>
                power &&
                (this.userPreference().showPowerAsPrice
                  ? UserPrefEnergyConsumption(power, this.userPreference())
                  : `${power.value}${SHORT_UNITS[power.unit]}`)
            }
          }))
        )
      )
    )
  );
  private _icon: UAnimatedIcon = 'device_unknown';
  private _propertyType: string | undefined = '';
  @Input() set device(device: DeviceDto) {
    if (!device) return;
    this._device$ = of(device);
  }

  @Input() deviceId!: string;
  @Input() invisibilityWakeUp: boolean = false;
  private _providerComponentType: ComponentType | undefined;
  @Input()
  get refreshDeviceValue() {
    return this._refreshDeviceValue;
  }
  set refreshDeviceValue(value: BooleanInput) {
    this._refreshDeviceValue = coerceBooleanProperty(value);
  }
  private _refreshDeviceValue = true;
  @Output() valueChange$: Observable<PropertyValueChange<T> | undefined> = this.deviceLoaded$.pipe(
    switchMap(() => this.device$.pipe(first())),
    switchMap(({ id: deviceId, providerComponentType: componentType }: DeviceDto) => {
      return this._componentRef?.instance
        ? this._componentRef.instance.partialCommand$.pipe(
            map(
              (command) =>
                ({
                  command: { command, componentType, deviceId },
                  propertyDefinition: this.propertyDefinition
                } as PropertyValueChange<T>)
            )
          )
        : of(undefined);
    })
  );

  constructor() {
    effect(() => {
      this._componentRef?.instance && this._setInstanceUserPreferences(this.userPreference());
    });
  }
  get icon(): UAnimatedIcon {
    const icon = DEVICE_TYPE_ICON[this._propertyType! as Properties];
    return icon?.valueToIcon(this.propertyDefinition! as any, this._icon) || this._icon;
  }
  get propertyDefinition(): T {
    if (this._componentRef) return this._componentRef.instance.propertyDefinition as T;

    throw new Error('_componentRef are not set');
  }

  get providerComponentType(): ComponentType {
    return this._providerComponentType!;
  }
  public get propertyValue(): W {
    if (!this._componentRef?.instance) {
      console.error('Create property instance first');

      throw new Error('Create property instance first');
    }

    return this._componentRef.instance.propertyValue;
  }
  public set propertyValue(value: W) {
    if (!this._componentRef?.instance) {
      console.error('Create property instance first');
      return;
    }
    this._componentRef.instance.propertyValue = value;
  }

  userPreferences!: UserPreferencesInterface;

  ngOnDestroy(): void {
    this._destroyAll();
  }
  commandToValue<W>(command: { command: keyof CommandValuesType; parameters: string | number | W | undefined }): W {
    if (!this._componentRef?.instance) {
      console.error('Create property instance first');
      throw new Error('Create property instance first');
    }

    return this._componentRef.instance.commandToValue(command);
  }
  valueToCommand<W>(value: W): { command: keyof CommandValuesType; parameters: string | number | W | undefined } {
    if (!this._componentRef?.instance) {
      console.error('Create property instance first');
      throw new Error('Create property instance first');
    }

    return this._componentRef.instance.valueToCommand(value) as {
      command: keyof CommandValuesType;
      parameters: string | number | W;
    };
  }

  set visible(visible: boolean) {
    if (!this._componentRef) return;
    if (visible) {
      this._componentRef.changeDetectorRef.reattach();
      this._componentRef.changeDetectorRef.markForCheck();
    } else {
      this._componentRef.changeDetectorRef.detach();
    }
  }

  async ngOnInit(): Promise<void> {
    const device = await firstValueFrom(this._device$);
    this._createComponentRef(device);
  }
  private _createComponentRef(device: DeviceDto) {
    this._loading.next(true);
    this._destroyAll();
    this._icon = device.icon;
    this._propertyType = undefined;
    const statuses: TDeviceStatus[] = [...DEVICE_ERROR_STATUSES];
    // subscription limit
    if (device.isBlockedBySubscriptionLimit) {
      this._icon = 'item_locked';
      (this._componentRef = this._createProperty('SubscriptionLimit')) &&
        this._setInstancePropertyDefinition({
          ...SUBSCRIPTION_LIMIT_PROPERTY_TEMPLATE
        });
      return;
    }
    if (device.status === 'Subdevices_hidden') {
      this._icon = 'device_unknown';
      (this._componentRef = this._createProperty('SubscriptionLimit')) &&
        this._setInstancePropertyDefinition({
          ...device.property
        } as SimlabPropertyInterface<any>);
      return;
    }
    // incorrect status or has no property definition
    if (statuses.includes(device.status) || !device.property) {
      this._icon = DEVICE_STATUS_INFORMATION[device.status]?.icon || 'error_home_center';
      (this._componentRef = this._createProperty('ProviderError')) &&
        this._setInstancePropertyDefinition({
          ...PROVIDER_ERROR_PROPERTY_TEMPLATE,
          values: {
            ...PROVIDER_ERROR_PROPERTY_TEMPLATE.values,
            value: DEVICE_STATUS_INFORMATION[device.status]?.label || 'Unsupported'
          } as SimpleValue<string>,
          settings: {
            value: { wakeUpSupported: device.status === 'Disconnected' && device.wakeUpSupported }
          }
        }),
        this._setInvisibilityWakeUp();
      this._deviceStatusChangeObserver();
      return;
    }

    // correct device property
    this._providerComponentType = device.providerComponentType;
    this._propertyType = device.propertyType;

    (this._componentRef = this._createProperty(device.propertyType)) && this._deviceObserver(),
      this._setInstanceUserPreferences(this.userPreference());
  }

  private _createProperty = (propertyType: Properties) =>
    COMPONENTS[propertyType] && this._viewContainerRef.createComponent<PropertiesComponents>(COMPONENTS[propertyType]!);

  private _setInvisibilityWakeUp() {
    if (this._componentRef && 'invisibilityWakeUp' in this._componentRef.instance) {
      this._componentRef.instance.invisibilityWakeUp = this.invisibilityWakeUp;
    }
  }

  private _deviceStatusChangeObserver = () =>
    this._device$
      .pipe(
        pairwise(),
        filter(([prev, act]: [DeviceDto, DeviceDto]) => prev && act && prev.status !== act.status),
        map(([prev, act]: [DeviceDto, DeviceDto]) => act),
        take(1)
      )
      .subscribe((device: DeviceDto) => {
        this._createComponentRef(device);
      });
  private _deviceObserver() {
    this._device$
      .pipe(
        distinctUntilChanged(
          (previous: DeviceDto, current: DeviceDto) => JSON.stringify(previous) === JSON.stringify(current)
        ),
        tap((device: DeviceDto) => {
          if (!device.property || ([...DEVICE_ERROR_STATUSES] as TDeviceStatus[]).includes(device.status)) {
            this._createComponentRef(device);
            return;
          }
          this._setInstancePropertyDefinition({
            ...device.property
          });
        }),
        takeWhile(() => this._refreshDeviceValue),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }
  // private _userPreferencesObserver() {
  //   effect(() => {
  //     this._setInstanceUserPreferences(this.userPreference());
  //   });
  // }
  private _setInstanceUserPreferences(preferences: UserPreferencesInterface) {
    if (this._componentRef)
      if (preferences && 'userPreferences' in this._componentRef.instance) {
        if (
          this._componentRef.instance.userPreferences &&
          preferences &&
          JSON.stringify(this._componentRef.instance.userPreferences) === JSON.stringify(preferences)
        )
          return;
        this._componentRef.instance.userPreferences = preferences;
      }
  }
  private _setInstancePropertyDefinition(property: SimlabPropertyInterface<any>) {
    if (this._componentRef)
      if ('propertyDefinition' in this._componentRef.instance) {
        if (
          this._componentRef.instance.propertyDefinition &&
          property &&
          JSON.stringify(this._componentRef.instance.propertyDefinition.values) === JSON.stringify(property.values)
        )
          return;
        this._componentRef.instance.propertyDefinition = property;
      }
    this._loading.next(false);
  }
  private _destroyAll() {
    this._destroyHost();
    this._destroySource.next();
  }
  private _destroyHost() {
    if (this._componentRef) {
      this._componentRef.destroy();
      this._componentRef = null;
    }
  }
}
