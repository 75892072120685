import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { PublicLinkService } from "@simOn/public-link";
import { API_URL } from "@simOn/utils/tokens";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Observable, map, switchMap, take } from "rxjs";

@Injectable()
export class TokenInjectorInterceptor implements HttpInterceptor {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private apiUrl = inject(API_URL);
  private publicLinkService = inject(PublicLinkService);
  private router = inject(Router);
  private readonly publicWhiteList: string[] = [this.apiUrl + "/news/GetNews"];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.publicLinkService.publicLink && this.publicLinkService.getGuid()
      ? this.checkPublicLink(request, next)
      : this.checkUser(request, next);
  }
  private checkUser(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.publicWhiteList.includes(request.url)) {
      return next.handle(request);
    }
    if (this.publicLinkService.publicLink && this.publicLinkService.getGuid()) {
      return this.checkPublicLink(request, next);
    }

    if (request.responseType !== "blob") {
      return this.oidcSecurityService.getAccessToken().pipe(
        take(1),
        map((accessToken: string) => {
          return request.clone({
            headers: request.headers.set("Authorization", `Bearer ${accessToken}`)
          });
        }),
        switchMap((response) => next.handle(response))
      );
    }

    return next.handle(request);
  }
  private checkPublicLink(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
      setHeaders: {
        PublicLink: this.publicLinkService.publicLink || "",
        "Current-Routing": this.router.routerState.snapshot.url
      }
    });
    return next.handle(request);
  }
}
