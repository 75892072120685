import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { RoomInterface } from '@simOn/room/models';
import { CLEAR_STATE_REDUCER, State } from '@simOn/utils';
import {
  AddRoom,
  AddRoomFailure,
  AddRoomSuccess,
  ClearRoomsState,
  DeleteRoom,
  DeleteRoomFailure,
  DeleteRoomSuccess,
  GetMasterRoom,
  GetMasterRoomFailure,
  GetMasterRoomSuccess,
  GetRoom,
  GetRoomFailure,
  GetRooms,
  GetRoomsFailure,
  GetRoomsSuccess,
  GetRoomSuccess,
  SetRoomCamera,
  SetRoomCameraFailure,
  SetRoomCameraSuccess,
  SetSelectedId,
  UpdateRoom,
  UpdateRoomFailure,
  UpdateRoomOnStore,
  UpdateRoomSuccess
} from './room.actions';

export const roomFeatureKey = 'rooms';

export const adapter: EntityAdapter<RoomInterface> = createEntityAdapter<RoomInterface>();

export const initialState: State<RoomInterface> = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const reducer = createReducer(
  initialState,

  on(GetRooms, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(SetSelectedId, (state, action) => {
    return {
      ...state,
      selectedId: action.selectedId
    };
  }),
  on(GetRoomsSuccess, (state, action) => {
    return adapter.upsertMany(action.state, { ...state, isLoaded: true, isLoading: false });
  }),

  on(GetRoomsFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(GetRoom, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(GetMasterRoom, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(GetMasterRoomSuccess, (state, action) => {
    return adapter.upsertOne(
      { ...action.masterRoom, name: 'Master Room', id: 'masterRoom' },
      {
        ...state,
        isLoaded: true,
        isLoading: false
      }
    );
  }),

  on(GetMasterRoomFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(GetRoomSuccess, (state, action) => {
    return adapter.setOne(action.state, { ...state, isLoaded: true, isLoading: false });
  }),

  on(GetRoomFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(AddRoom, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(AddRoomSuccess, (state, action) => {
    return adapter.addOne(action.state, state);
  }),

  on(AddRoomFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(SetRoomCamera, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(SetRoomCameraSuccess, (state, action) => {
    return adapter.updateOne({ id: action.roomId, changes: { roomCamera: action.roomCamera } }, state);
  }),

  on(SetRoomCameraFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(UpdateRoom, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(UpdateRoomSuccess, (state, action) => {
    return adapter.updateOne({ id: action.state.id, changes: action.state }, state);
  }),

  on(UpdateRoomFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(DeleteRoom, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(DeleteRoomSuccess, (state, action) => {
    return adapter.removeOne(action.state.id, state);
  }),

  on(DeleteRoomFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(UpdateRoomOnStore, (state, action) => {
    return adapter.updateOne({ id: action.room.id, changes: action.room }, state);
  }),
  CLEAR_STATE_REDUCER(ClearRoomsState, initialState)
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
