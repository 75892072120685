import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { SceneShortcutBodyInterface, TSceneState, TSimlabSceneInterfaceBase } from '@simOn/scene/element/models';
import { Observable, map, race } from 'rxjs';
import {
  AddShortcutScene,
  ChangeSimlabSceneStatus,
  ChangeSimlabSceneStatusFailure,
  ClearSceneState,
  DeleteScene,
  DeleteSceneFailure,
  DeleteShortcutScene,
  GetScenes,
  GetSimlabScenes,
  RunScene,
  RunSceneFailure,
  RunSceneSuccess,
  UpdateShortcutScene,
  UpsertScene,
  UpsertSceneFailure,
  UpsertSceneSuccess
} from './scene.actions';
import {
  GetAllScenes,
  GetExternalScenes,
  GetSceneById,
  GetScenesSimlab,
  GetSimlabScenesShortcuts,
  SceneEntity
} from './scene.selectors';

@Injectable({
  providedIn: 'root'
})
export class SceneFacade {
  private readonly store = inject(Store);
  private readonly _actions = inject(Actions);
  private readonly _runSceneSuccess$ = this._actions.pipe(
    ofType(RunSceneSuccess),
    map(() => true)
  );

  private readonly _runSceneFailure$ = this._actions.pipe(
    ofType(RunSceneFailure),
    map(() => false)
  );

  readonly runScenAction$ = race(this._actions.pipe(ofType(RunSceneFailure, RunSceneSuccess)));

  allScenes$: Observable<TSceneState[]> = this.store.select(GetAllScenes);
  externalScenes$ = this.store.select(GetExternalScenes);
  simlabScenes$ = this.store.select(GetScenesSimlab);
  simlabScenesShortcuts$: Observable<SceneShortcutBodyInterface[]> = this.store.select(GetSimlabScenesShortcuts);
  readonly changeSimlabSceneStatusFailure$ = this._actions.pipe(ofType(ChangeSimlabSceneStatusFailure));
  readonly deleteSceneFailure$ = this._actions.pipe(ofType(DeleteSceneFailure));
  readonly updateActionSuccess$ = this._actions.pipe(
    ofType(UpsertSceneSuccess, UpsertSceneFailure),
    map((action) => ({
      success: action.type === '[Scenes] [Success] Upsert Scenes',
      error: action.type === '[Scenes] [Failure] Upsert Scenes' && action.error.ErrorCode
    }))
  );
  readonly scenesEntity$: Observable<Dictionary<TSceneState>> = this.store.select(SceneEntity);
  readonly runSceneObserver$ = race(this._runSceneSuccess$, this._runSceneFailure$);
  sceneById$ = (id: string) => this.store.select(GetSceneById(id));

  runScene(sceneId: string): void {
    this.store.dispatch(RunScene({ sceneId }));
  }

  getScenes(): void {
    this.store.dispatch(GetScenes());
  }

  getSimlabScenes(): void {
    this.store.dispatch(GetSimlabScenes());
  }

  deleteSimlabScene(scene: string): void {
    this.store.dispatch(DeleteScene({ scene }));
  }

  addShortcutScene(shortcut: SceneShortcutBodyInterface): void {
    this.store.dispatch(AddShortcutScene({ shortcut }));
  }

  updateShortcutScene(shortcut: SceneShortcutBodyInterface): void {
    this.store.dispatch(UpdateShortcutScene({ shortcut }));
  }

  deleteSceneShortcut(shortcut: SceneShortcutBodyInterface): void {
    this.store.dispatch(DeleteShortcutScene({ shortcut }));
  }

  upsertScene(scene: TSimlabSceneInterfaceBase): void {
    this.store.dispatch(UpsertScene({ scene }));
  }

  changeSimlabSceneStatus(sceneId: string, isActive: boolean): void {
    this.store.dispatch(ChangeSimlabSceneStatus({ sceneId, isActive }));
  }

  clearState() {
    this.store.dispatch(ClearSceneState());
  }
}
