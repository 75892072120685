import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { UsersApiService } from '@simOn/user/preferences/data-access';
import { RELOAD_PAGE_AFTER_CHANGE_LANGUAGE } from '@simOn/user/preferences/ui';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom, map } from 'rxjs';

export const languageResolver: ResolveFn<void> = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const preference = inject(UsersApiService);
  const oidcSecurityService = inject(OidcSecurityService);

  const isAuthenticated = await firstValueFrom(
    oidcSecurityService.isAuthenticated$.pipe(map((result) => result.isAuthenticated))
  );

  const language =
    isAuthenticated &&
    (await firstValueFrom(
      preference.getUserPreferences().pipe(map((preferences) => preferences.preferences.languageMode))
    ));
  return language && RELOAD_PAGE_AFTER_CHANGE_LANGUAGE(language, state.url);
};
