import { Unit } from './units';

export const SHORT_UNITS: {
  [name in Unit]: string;
} = {
  Unknown: '',
  None: '',
  kWh: 'kWh',
  Wh: 'Wh',
  mWh: 'mWh',
  kVAh: 'kVAh',
  Joule: 'J',
  Watt: 'W',
  Celsius: '°C',
  Fahrenheit: '°F',
  Kelvin: 'K',
  MilesPerHour: 'mi/h',
  Knots: 'kn',
  MetersPerSecond: 'm/s',
  FeetPerSecond: 'ft/s',
  KilometersPerHour: 'km/h',
  Percentage: '%',
  Lux: 'Lux',
  Lumen: 'Lm',
  mG: 'mG',
  ppm: 'ppm',
  mgForm3: 'mgForm3',
  Miles: 'mi',
  Kilometers: 'km',
  Meters: 'm',
  Volt: 'V',
  MMI: 'MMI',
  Currency: 'Currency',
  Ampere: 'A'
};
