<div class="ui-tooltip-content" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <ng-content></ng-content>
</div>

<ng-template
  cdkConnectedOverlay
  (overlayOutsideClick)="outsideClick()"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="setPositionStrategy"
>
  <div [ngClass]="noPadding ? 'ui-tooltip-wrapper-no-padding' : 'ui-tooltip-wrapper'">
    <ng-container *ngIf="hasEmbeddedView; else plainText">
      <ng-template *ngTemplateOutlet="template"></ng-template>
    </ng-container>
    <ng-template #plainText>
      {{ text }}
    </ng-template>
  </div>
</ng-template>
