import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { UserPreferencesFacade } from '@simOn/user';
import { IdentityUrl } from '@simOn/user/auth/service';
import { UserPreferencesModalResultInterface } from '@simOn/user/preferences/model';
import { RELOAD_PAGE_AFTER_CHANGE_LANGUAGE, UserPreferencesModalComponent } from '@simOn/user/preferences/ui';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';

import { firstValueFrom, map, tap } from 'rxjs';

// from SimLoggedUserModule
@Component({
  selector: 'user-info',
  standalone: true,
  imports: [CommonModule, SimIconModule, RouterModule, SimButtonModule, OverlayModule],
  templateUrl: './sim-logged-user.component.html',
  styleUrls: ['./sim-logged-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimLoggedUserComponent {
  private readonly dialog = inject(MatDialog);
  private readonly _router = inject(Router);
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly userPreferencesFacade = inject(UserPreferencesFacade);
  readonly simlabCloudRedirect = inject(IdentityUrl);

  @Input() lightMode = false;

  userPanelVisible = false;
  readonly user$ = this.oidcSecurityService.userData$.pipe(map((userData: UserDataResult) => userData.userData));

  openPanel() {
    this.userPanelVisible = !this.userPanelVisible;
  }
  hideAllPanels() {
    this.userPanelVisible = false;
  }

  logOut() {
    firstValueFrom(this.oidcSecurityService.logoff());
    this.userPanelVisible = false;
  }

  async openUserPreferences(): Promise<void> {
    this.userPanelVisible = false;
    const modalRef = await this._openUserPreferencesDialog();
    firstValueFrom(
      modalRef.afterClosed().pipe(
        tap((modalResult: UserPreferencesModalResultInterface | undefined) => {
          if (!modalResult || !modalResult.payload || modalResult.action !== 'save') return;
          this.userPreferencesFacade.updateUserPreferences(modalResult.payload);
          firstValueFrom(
            this.userPreferencesFacade.successUserPreferencesUpdate.pipe(
              tap(() => {
                RELOAD_PAGE_AFTER_CHANGE_LANGUAGE(
                  modalResult.payload!.preferences.languageMode,
                  this._router.routerState.snapshot.url
                );
              })
            )
          );
        })
      )
    );
  }

  private async _openUserPreferencesDialog(): Promise<
    MatDialogRef<UserPreferencesModalComponent, UserPreferencesModalResultInterface>
  > {
    const modalComponent = await import('@simOn/user/preferences/ui').then((e) => e.UserPreferencesModalComponent);
    return this.dialog.open(modalComponent, {
      panelClass: 'custom-modal',
      width: '700px',
      height: 'min(680px,100%)',
      disableClose: true
    });
  }
}
