import { Injectable, inject } from '@angular/core';
import { PayPalNamespace, loadScript } from '@paypal/paypal-js';
import { PAYPAL_CLIENT_ID } from './tokens/client-id.token';
import { PayPalButtonsOptions } from './models/pay-pal-button.config';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PayPalService {
  private clientId = inject(PAYPAL_CLIENT_ID);
  private _sdk: PayPalNamespace | null = null;
  private _buttonLoaded = new BehaviorSubject<boolean>(false);
  readonly buttonLoaded$ = this._buttonLoaded.asObservable();

  loadSkd(containerId: string, configButton: PayPalButtonsOptions) {
    this._buttonLoaded.next(false);
    return loadScript({ clientId: this.clientId })
      .then((res) => {
        this._sdk = res;
        this._renderButton(containerId, configButton);
      })
      .catch((error) => {
        return 'failed to load the PayPal JS SDK script';
      });
  }

  private _renderButton(
    containerElement: string,
    configButton: PayPalButtonsOptions
  ) {
    if (this._sdk && this._sdk?.Buttons) {
      this._buttonLoaded.next(true);
      return this._sdk
        .Buttons({ ...configButton })
        .render('#' + containerElement)
        .catch((error) => {
          return 'failed render the PayPal Buttons';
        });
    } else {
      throw 'sdk or Buttons from sdk are not loaded available';
    }
  }
}
