import * as THREE from 'three';
import {
  Euler,
  Group,
  Line,
  Material,
  MathUtils,
  Object3D,
  Quaternion,
  Vector3,
} from 'three';
import { easeOutSine } from '../helpers/math-utils';
import { disposeHierarchy, disposeNode } from '../helpers/three.helpres';
import {
  Inputs,
  NoteComponentClickData,
  Transformation,
  ViewMode,
} from '../models/custom-component.type';
import { DEFAULT_SCALE_SPACE } from '../models/matterport-data';
import {
  ComponentInteractionType,
  SceneComponentBase,
} from './scene-component-base';

const MAX_GROWTH_CAMERA_DISTANCE = 15;
export const rootNoteType = 'mp.customComponentRenderer';
export function makeCustomComponentRenderer() {
  return new CustomComponent();
}

export function getElementPosition(
  newPos: Vector3,
  newNorm: Vector3,
  scale?: number
) {
  if (!scale) scale = DEFAULT_SCALE_SPACE;
  const stem = {
    x: scale * newNorm.x,
    y: scale * newNorm.y,
    z: scale * newNorm.z,
  };

  return {
    x: newPos.x + stem.x,
    y: newPos.y + stem.y,
    z: newPos.z + stem.z,
  };
}
export class CustomComponent extends SceneComponentBase {
  inputs: Required<Inputs> = {
    id: '',
    visible: true,
    scale: { x: 1, y: 1, z: 1 } as Vector3,
    renderOrder: 4,
    isCollider: true,
    position: { x: 0, y: 0, z: 0 } as Vector3,
    rotation: { x: 0, y: 0, z: 0, w: 0 } as Quaternion,
    normal: { x: 0, y: 1, z: 0 } as Vector3,
    stemHeight: 0,
    opacity: 1,
    autoScale: true,
    userData: null,
    depthTest: true,
    transparent: false,
    lookAt: false,
    dollhouseView: true,
  };
  readonly events = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
  };
  private _viewMode: ViewMode | undefined;
  private _localAutoScale: boolean | undefined = false;
  private _group!: Group;
  private _vector!: Vector3;
  private _stem?: Line;
  _initRotation: { x: number; y: number; z: number } | undefined;

  constructor() {
    super();
  }

  get three() {
    return this.context.three;
  }
  public get viewMode(): ViewMode | undefined {
    return this._viewMode;
  }
  public set viewMode(value: ViewMode | undefined) {
    this._viewMode = value;
  }
  override onInit() {
    const THREE = this.context.three;
    this._group = new THREE.Group();

    this.opacity = this.inputs.opacity;
    const { x, y, z } = this.inputs.position;

    this.position = new THREE.Vector3(x, y, z);
    const { x: rx, y: ry, z: rz, w: wz } = this.inputs.rotation;
    this.rotation = new THREE.Quaternion(rx, ry, rz, wz);
    this.scale = this.inputs.scale;
    this._localAutoScale = this.inputs.autoScale;
    this._group.name = this.inputs.id;

    this.renderOrder = this.inputs.renderOrder;
    this._group.layers.set(3);

    this.outputs.objectRoot = this._group;
    if (this.inputs.isCollider) {
      this.outputs.collider = this._group;
    }
    this._group.visible = this.inputs.visible;
  }
  private _drawStem() {
    if (!this._stem && this.inputs.stemHeight > 0) {
      this._createStemObject();
    }
    const { x, y, z } = this.inputs.position;
    const points = [this.position, new Vector3(x, y, z)];
    this._stem?.geometry.setFromPoints(points);
  }
  private _createStemObject(): void {
    if (!this._group.parent) return;
    const material = new this.three.LineBasicMaterial({ color: 'white' });
    material.stencilWrite = true;
    material.stencilFunc = THREE.AlwaysStencilFunc;
    material.depthWrite = false;
    const points = [new Vector3(0, 0, 0), new Vector3(0, 0, 0)];
    const geometry = new this.three.BufferGeometry().setFromPoints(points);
    this._stem = new this.three.Line(geometry, material);
    this._stem.renderOrder = 999;
    this._group.parent?.add(this._stem);
  }

  public drawStem() {
    this._drawStem();
  }

  set rotation(rotation: Quaternion) {
    if (this._group) {
      const { x, y, z } = new Euler().setFromQuaternion(rotation);
      this._group.rotation.set(x, y, z);
    }
  }

  get rotation() {
    return new Quaternion().setFromEuler(this._group.rotation);
  }
  set position(position: Vector3) {
    const normal = new Vector3(
      this.inputs.normal.x,
      this.inputs.normal.y,
      this.inputs.normal.z
    );
    const { x, y, z } = getElementPosition(
      position as Vector3,
      normal,
      this.inputs.stemHeight
    );
    const { x: gx, y: gy, z: gz } = this._group.position;
    this._initRotation = {
      x: this._group.rotation.x,
      y: this._group.rotation.y,
      z: this._group.rotation.z,
    };
    if (x !== gx || y !== gy || z !== gz) {
      this._group.position.set(x, y, z);
      this.inputs.position = new Vector3(position.x, position.y, position.z);
      //TODO test
      this._lookAt(this.inputs.position);
      this.rotateYByPi();
      this._drawStem();
    }
  }

  get position() {
    const { x, y, z } = this._group.position;
    return new Vector3(x, y, z);
  }
  set lookAt(lookAt: boolean) {
    this.inputs.lookAt = lookAt;
  }
  transformation(transformation: Transformation) {
    const { x: nx, y: ny, z: nz } = transformation.normal;
    if (
      nx !== this.inputs.normal.x ||
      ny !== this.inputs.normal.y ||
      nz !== this.inputs.normal.z
    ) {
      this.inputs.normal = transformation.normal;
    }
    if (transformation.stemHeight !== this.inputs.stemHeight) {
      this.inputs.stemHeight = transformation.stemHeight;
    }
    const { x, y, z } = transformation.position;
    this.position = new this.three.Vector3(x, y, z);
  }

  set layer(layer: number) {
    this._group.layers.set(layer);
  }
  get scene(): THREE.Scene {
    return this.context.scene as THREE.Scene;
  }
  hide() {
    if (this._group.visible) {
      this._group.visible = false;
      if (this.inputs.stemHeight > 0 && this._stem) {
        this._stem.visible = false;
      }
    }
  }

  show() {
    if (!this._group.visible) {
      this._group.visible = true;
      if (this.inputs.stemHeight > 0 && this._stem) {
        this._stem.visible = true;
      }
    }
  }
  addChild(...object: Object3D[]) {
    this._group.add(...object);
    this.opacity = this.inputs.opacity;
  }

  getChild(index: number) {
    return this._group.children[index];
  }

  set renderOrder(renderOrder: number) {
    this._group.renderOrder = renderOrder;
  }

  set opacity(opacity: number) {
    this._group.children.forEach((child: any) => {
      child.material.opacity = opacity;
      if (opacity < 1 && !this.inputs.depthTest) {
        child.material.depthTest = false;
        child.material.depthWrite = false;
        child.material.transparent = true;
      } else {
        child.material.depthTest = true;
        child.material.depthWrite = true;
        child.material.transparent = false;
      }
    });
  }

  get camera(): THREE.Camera {
    return this.context.camera as THREE.Camera;
  }
  get cameraContainer(): THREE.Camera {
    return this.context.camera.parent as THREE.Camera;
  }
  override onEvent(
    eventType: ComponentInteractionType,
    eventData: NoteComponentClickData
  ) {
    this.notify(eventType, {
      ...eventData,
      ...{ userData: this.inputs.userData },
    });
  }

  rotateYByPi() {
    this._group.rotateY(Math.PI);
  }
  _lookAt(vector: Vector3 = this._vector) {
    if (!this._group || !this._isVectorValid(vector)) {
      return;
    }
    try {
      if (this.viewMode && this.viewMode === ViewMode.FLOORPLAN) {
        this._group.lookAt(this.position.x, vector.y, this.position.z);
        this._group.rotateZ(this.camera.rotation.z);
      } else {
        this._group.lookAt(vector.x, vector.y, vector.z);
      }

      this.outputs.objectRoot = this._group;
      this._vector = { ...vector } as Vector3;
    } catch (e) {
      console.error('[ERROR] PlaneRenderer, error: ', e);
    }
  }

  override onInputsUpdated(oldInputs: Inputs) {
    // if (oldInputs.icon !== this.inputs.icon) {
    //   this.icon = this.inputs.icon;
    // }
    if (oldInputs.opacity !== this.inputs.opacity) {
      this.opacity = this.inputs.opacity;
    }
    if (oldInputs.visible !== this.inputs.visible) {
      this._group.visible = this.inputs.visible;
    }
    if (oldInputs.scale !== this.inputs.scale) {
      this.scale = this.inputs.scale;
    }
    if (oldInputs.renderOrder !== this.inputs.renderOrder) {
      this.renderOrder = this.inputs.renderOrder;
    }
  }

  set scale(scale: Vector3) {
    const { x, y, z } = scale;
    this._group.scale.set(x, y, z);
  }

  override onTick(delta: number) {
    if (this.inputs.autoScale) {
      const distance = this.cameraContainer.position
        .clone()
        .distanceTo(this._group.position);

      const clampedDistance = MathUtils.clamp(
        distance,
        0,
        MAX_GROWTH_CAMERA_DISTANCE
      );
      const percentageValueOfDistance =
        ((clampedDistance * 100) / MAX_GROWTH_CAMERA_DISTANCE) * 0.01;
      const lerped = MathUtils.lerp(0.06, 0.3, percentageValueOfDistance);
      const scale = easeOutSine(lerped);
      this._group.scale.set(scale, scale, 1);
    } else {
      const { x, y, z } = this.inputs.scale;
      this._group.scale.set(x, y, z);
    }

    if (this.inputs.lookAt) {
      this._lookAt(this.cameraContainer.position);
      this._localAutoScale === undefined &&
        (this._localAutoScale = this.inputs.autoScale);
      this.inputs.autoScale = true;
    } else {
      this._lookAt(this.inputs.position);
      this.rotateYByPi();
      this.inputs.autoScale = this._localAutoScale || false;
      this._localAutoScale = undefined;
    }
  }

  private _isVectorValid(vector: Vector3) {
    return (
      vector.x !== null &&
      vector.x !== undefined &&
      vector.y !== null &&
      vector.y !== undefined &&
      vector.z !== null &&
      vector.z !== undefined
    );
  }
  override onDestroy() {
    this.outputs.collider = null;
    this.outputs.objectRoot = null;
    if (this._stem) {
      (this._stem.material as Material).dispose();
      this._stem.geometry.dispose();
      this.scene.remove(this._stem);
    }
    this._group.children.forEach((childObj: Object3D) => {
      disposeHierarchy(childObj, disposeNode);
      this.scene.remove(childObj);
    });
    this._context = null;
  }
}
