import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SimNewPropertyUploadImageComponent } from '@simOn/space/image/ui';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { AssetsImage } from '@simOn/ui/sim-footer';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { ImagePathPipe } from './image-path.pipe';
import { ImagePipe } from './image.pipe';

@Component({
  standalone: true,
  selector: 'layout-sidenav',
  templateUrl: './sim-sidenav.component.html',
  styleUrls: ['./sim-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SimButtonModule,
    RouterModule,
    SimNewPropertyUploadImageComponent,
    SimIconModule,
    ImagePathPipe,
    ImagePipe
  ]
})
export class SimSidenavComponent {
  private readonly _oidcSecurityService = inject(OidcSecurityService);

  readonly publicMode = input<boolean>();
  readonly apartmentName = input<string>();
  readonly providersDisabled = input.required<boolean>();
  readonly apartmentPhotoUrl = input<string | undefined>();
  readonly interiorDesignerVisible = input.required<boolean>();
  readonly onClose = output<void>();

  open = false;

  toggle() {
    this.open = !this.open;
  }

  readonly AssetsImage = AssetsImage;

  closeSidenav() {
    this.open = false;
    this.onClose.emit();
  }

  logout(): void {
    firstValueFrom(this._oidcSecurityService.logoff());
  }
}
