<div name="sim-input" class="flex-d">
  <input
    #input
    type="checkbox"
    role="checkbox"
    (change)="emitChangeEvent($event)"
    (touch)="_onTouched()"
    [disabled]="disabled"
    [id]="id"
    class="input"
  />
  <ng-content select="label"></ng-content>
</div>
