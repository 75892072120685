export type RecurringPaymentResponse = {
  status: CreatingRecurringPaymentStatus,
  transactionId: string
}

export enum CreatingRecurringPaymentStatus {
   'Success',
   'Error',
   'Warning'
}
