import { Injectable } from '@angular/core';
import { filter, fromEvent, map, Observable, race, Subject, tap } from 'rxjs';
import { AuthorizationResponse } from '../models/matterport-api';
import { MatterportApiConfig } from '../models/matterport-api-config';
import {
  MatterportAuthorization,
  MatterportAuthorizationResponse,
} from '../models/matterport-authorization';
import { MatterportApiService } from './matterport-api.service';

@Injectable()
export class AuthorizationService {
  private _windowRef!: Window | null;
  private readonly _onWindowClose: Subject<void> = new Subject<void>();
  constructor(
    private readonly matterportApiConfig: MatterportApiConfig,
    private readonly matterportApi: MatterportApiService
  ) {}

  openAuthorizationWindow$(
    authorizationUrl?: string
  ): Observable<
    MatterportAuthorizationResponse<MatterportAuthorization> | undefined
  > {
    this._windowRef = window.open(
      authorizationUrl ||
        `https://authn.matterport.com/oauth/authorize?client_id=${this.matterportApiConfig.clientId}&response_type=code&scope=ViewDetails`,
      'OAuth',
      'width=800,height=800'
    );
    const timer = setInterval(() => {
      if (this._windowRef?.closed) {
        this._onWindowClose.next();
        clearInterval(timer);
      }
    }, 1000);
    return race(
      fromEvent(window, 'message').pipe(
        filter(
          (e: any) =>
            e.source === this._windowRef && e.data.type === 'auth_code'
        ),
        map((e: any) => {
          if (!this._windowRef) return;

          const { token, authCode } = e.data;
          sessionStorage.setItem('matterport-code', authCode);
          if (token) {
            this.setMatterportToken(token);
          }
          clearInterval(timer);
          return {
            status: 'SUCCESS',
            response: { token, authCode },
          } as MatterportAuthorizationResponse<MatterportAuthorization>;
        })
      ),
      this._onWindowClose.asObservable().pipe(
        map(
          () =>
            ({
              status: 'CLOSE',
              response: undefined,
            } as MatterportAuthorizationResponse<MatterportAuthorization>)
        )
      )
    );
  }

  setMatterportToken(token: string) {
    if (token) this._windowRef?.close();
    sessionStorage.setItem('matterport-token', token);
  }

  getOauthToken$(code: string): Observable<AuthorizationResponse> {
    return this.matterportApi.authorize$(code).pipe(
      tap((token: AuthorizationResponse) => {
        this.sendWindowMessage(code, token.access_token);
      })
    );
  }

  checkTokenExist(): boolean {
    return sessionStorage.getItem('matterport-token') ? true : false;
  }

  deleteToken(): void {
    sessionStorage.removeItem('matterport-token');
    sessionStorage.removeItem('matterport-code');
  }
  sendWindowMessage(code: string, token?: string) {
    window.opener.postMessage(
      {
        type: 'auth_code',
        token: token,
        authCode: code,
      },
      this.matterportApiConfig.host ?? '*'
    );
  }
}
