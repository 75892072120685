import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { Camera, Intersection, Layers, Scene, Vector2 } from 'three';

export class RaycasterHelper {
  readonly raycaster: THREE.Raycaster = new this.THREE.Raycaster();
  private readonly _destroy: Subject<void> = new Subject<void>();
  private readonly _selectedLayers: BehaviorSubject<number[]> =
    new BehaviorSubject<number[]>([2, 3, 4, 9]);

  constructor(
    private readonly THREE: typeof import('three'),
    private readonly camera: Camera,
    private readonly scene: Scene
  ) {
    this._initRaycaster();
  }

  private _initRaycaster() {
    this._selectedLayersObserver();
    this.setFromCamera();

    /**
     * How far raycaster get objects
     */
    this.raycaster.far = 4;
  }

  set threshold(threshold: number) {
    this.raycaster.params.Mesh = threshold;
    this.raycaster.params.Sprite = threshold;
    if (this.raycaster.params?.Points)
      this.raycaster.params.Points.threshold = threshold;
  }
  setFromCamera(pointer?: Vector2) {
    this.raycaster.setFromCamera(
      pointer ?? new this.THREE.Vector2(1, 1),
      this.camera
    );
  }
  get layers(): Layers {
    return this.raycaster.layers;
  }
  private _setActiveLayers(layers: number[]) {
    /**
     * Looking for walls, floors and our note sprite.
     * Disable all layers and select only needed
     */
    this.raycaster.layers.disableAll();
    layers.forEach((layer: number) => {
      this.raycaster.layers.enable(layer);
    });
    // this.raycaster.layers.enableAll();
  }

  destroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  private _selectedLayersObserver() {
    this._selectedLayers
      .asObservable()
      .pipe(
        tap((layers: number[]) => this._setActiveLayers(layers)),
        takeUntil(this._destroy)
      )
      .subscribe();
  }
  set selectedLayers(layers: number[]) {
    this._selectedLayers.next(layers);
  }

  enableAllLayers() {
    this.raycaster.layers.enableAll();
  }

  set far(far: number) {
    this.raycaster.far = far;
  }

  getIntersectObjects(
    cameraPosition: THREE.Vector3,
    notePosition: THREE.Vector3,
    pointer?: THREE.Vector2,
    far?: number
  ): Intersection[] {
    this.far = far || cameraPosition.distanceTo(notePosition) + 0.5;
    this.camera.updateMatrixWorld();
    if (pointer) {
      this.setFromCamera(pointer);
    } else {
      this.raycaster.set(
        cameraPosition,
        notePosition.clone().sub(cameraPosition).normalize()
      );
    }

    return this.raycaster.intersectObjects(this.scene.children, true);
  }

  getIntersectObjectsWithPoint(pointer: THREE.Vector3): Intersection[] {
    const cameraPosition = this.camera.parent?.position;
    if (!cameraPosition) throw Error('unknown camera position');
    this.raycaster.params = {
      Mesh: {},
      Line: { threshold: 0.1 },
      LOD: {},
      Points: { threshold: 0.1 },
      Sprite: { threshold: 0.3 },
    };
    this.raycaster.set(
      cameraPosition,
      pointer.clone().sub(cameraPosition).normalize()
    );

    return this.raycaster.intersectObjects(this.scene.children);
  }
}
