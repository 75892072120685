<sim-select
  i18n-placeholder="@@DEVICE_PROPERTIES_SELECT_OPTION"
  id="set-boolean-select"
  [(value)]="propertyValue"
  placeholder="Select option..."
>
  <ng-container *ngFor="let option of values; let i = index">
    <sim-option id="set-boolean-select-option-{{ i }}" [value]="option">{{ option }}</sim-option>
  </ng-container>
</sim-select>
