/* eslint-disable @angular-eslint/no-host-metadata-property */
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { DrawerContentComponent } from './drawer-content.component';

@Component({
  selector: 'sim-sidenav-content',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-drawer-content ui-sidenav-content',
    '[style.margin-left.px]': '_container.contentMargins.left',
    '[style.margin-right.px]': '_container.contentMargins.right'
  }
})
export class SidenavContentComponent extends DrawerContentComponent {}
