import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { NotificationService } from '@simOn/common/notification';
import { IMAGE_SIZE_URL_SUFFIX, SimUploadImageComponent } from '@simOn/common/upload-image';
import { SpaceFacade, UploadFileWithProgressFailure, UploadFileWithProgressSuccess } from '@simOn/space';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SimSpinnerComponent } from '@simOn/ui/sim-spinner';
import { Observable, firstValueFrom, tap } from 'rxjs';

// from SimNewPropertyUploadImageModule
@Component({
  selector: 'space-upload-image', //sim-new-property-upload-image
  standalone: true,
  imports: [
    CommonModule,
    SimUploadImageComponent,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    SimSpinnerComponent,
    SimIconModule
  ],
  templateUrl: './sim-new-property-upload-image.component.html',
  styleUrls: ['./sim-new-property-upload-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SimNewPropertyUploadImageComponent {
  private readonly apartmentFacade = inject(SpaceFacade);
  private readonly notificationService = inject(NotificationService);
  private readonly action$ = inject(Actions);
  readonly isLoading$: Observable<boolean> = this.apartmentFacade.loading$;
  readonly imageSizeUrlSuffix = IMAGE_SIZE_URL_SUFFIX;

  @HostBinding('class.select-image-on-hover') get imageOnHover(): boolean {
    return this._selectImageOnHover;
  }
  @HostBinding('class.sim-place-image-small') get smallPlacePicture(): boolean {
    return this._smallImage;
  }

  @Input() id!: string;

  @Input()
  get selectImageOnHover(): boolean {
    return this._selectImageOnHover;
  }
  set selectImageOnHover(value: BooleanInput) {
    this._selectImageOnHover = coerceBooleanProperty(value);
  }
  private _selectImageOnHover = false;

  @Input()
  get imageUrl(): string | ArrayBuffer | undefined {
    return this._imageUrl;
  }
  set imageUrl(value: string | ArrayBuffer | undefined) {
    if (!value) return;
    this._imageUrl = value;
  }
  private _imageUrl!: string | ArrayBuffer;
  readonly defaultImageUrl = 'assets/simon-theme/images/apartment_circle.png';

  @Input()
  get uploadDisabled(): boolean {
    return this._uploadDisabled;
  }
  set uploadDisabled(value: BooleanInput) {
    this._uploadDisabled = coerceBooleanProperty(value);
  }
  private _uploadDisabled = false;

  @Input()
  get small(): boolean {
    return this._smallImage;
  }
  set small(value: BooleanInput) {
    this._smallImage = coerceBooleanProperty(value);
  }
  private _smallImage = false;

  @Output() selectedImageUrl = new EventEmitter<string>();
  @Output() localImage = new EventEmitter<string | ArrayBuffer>();

  @ViewChild('upload') private _image!: SimUploadImageComponent;

  get image(): any {
    return this._image?.value;
  }

  clearUploadImage(): void {
    this._image.value = null;
  }

  showError(event: string): void {
    this.notificationService.openErrorSnackBar(event);
  }

  uploadFile(file: File) {
    const formData = new FormData();
    formData.append('File', file);
    firstValueFrom(
      this.action$.pipe(
        ofType(UploadFileWithProgressFailure, UploadFileWithProgressSuccess),
        tap((action) => {
          this._image.value && this.localImage.emit(this._image.value);
          if ((action.state as any).photoUrl) {
            this.selectedImageUrl.emit((action.state as any).photoUrl);
          }
        })
      )
    );
    this.apartmentFacade.uploadFileSimplifiedWithProgress(formData, file.name);
  }

  dropFile(event: DragEvent) {
    event.dataTransfer?.files[0] && this.uploadFile(event.dataTransfer.files[0]);
  }
}
