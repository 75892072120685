import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { SimCheckboxModule } from '@simOn/ui/sim-checkbox';
import { SimFormModule } from '@simOn/ui/sim-form-filed';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SimInputModule } from '@simOn/ui/sim-input';
import { SimSearchSuffixComponent } from '@simOn/ui/sim-search-suffix';
import { OptionComponent } from './components/option/option.component';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER, SelectComponent } from './components/select.component';

@NgModule({
  declarations: [SelectComponent, OptionComponent],
  imports: [
    CommonModule,
    OverlayModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    MatPseudoCheckboxModule,
    MatIconModule,
    SimCheckboxModule,
    SimIconModule,
    SimInputModule,
    SimSearchSuffixComponent,
    SimFormModule
  ],
  exports: [OverlayModule, ScrollingModule, SelectComponent, OptionComponent],
  providers: [MAT_SELECT_SCROLL_STRATEGY_PROVIDER]
})
export class SimSelectModule {}
