import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { PayPalService } from './pay-pal.service';
import { PayPalButtonsOptions } from './models/pay-pal-button.config';
import { Subject, takeUntil } from 'rxjs';

const PAYPAL_BUTTON_CONTAINER_ID = 'paypal-button-container';

@Component({
  selector: 'app-pay-pal',
  template: ` <div id="{{ containerButtonId }}"></div> `,
  standalone: true,
  styles: [
    `
      .disabled {
        opacity: 0.4;
        cursor: default;
        pointer-events: none;
      }
    `,
  ],
  providers: [PayPalService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.disabled]': 'disabled',
  },
})
export class PayPalComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _payPalService = inject(PayPalService);
  private _config: PayPalButtonsOptions | undefined;
  readonly containerButtonId = PAYPAL_BUTTON_CONTAINER_ID;

  @Input() set config(value: PayPalButtonsOptions) {
    this._config = value;
  }

  private _disabled: boolean = false;
  public get disabled(): boolean {
    return this._disabled;
  }
  @Input() set disabled(value: boolean) {
    this._disabled = value;
    this._cdr.markForCheck();
  }

  @Output() onButtonRendered = new EventEmitter<boolean>();

  ngOnInit(): void {
    this._payPalService.buttonLoaded$
      .pipe(takeUntil(this._destroySource))
      .subscribe((e) => {
        this.onButtonRendered.emit(e);
      });
  }

  ngAfterViewInit(): void {
    this._payPalService.loadSkd(this.containerButtonId, this._config!);
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
}
