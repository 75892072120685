import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Vector } from '@simlab/matterport/transform';
import {
  BehaviorSubject,
  catchError,
  defer,
  filter,
  fromEvent,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { CreatePortal } from '../models/portal';
const WORKER_V = 1;
const WORKER = 'worker_wasm_portals.js?v=';

export type PortalPosition = {
  id: string;
  position: Vector;
};
@Injectable()
export class MatterportPortalsService implements OnDestroy {
  private readonly _workerName = `${WORKER}${WORKER_V}`;
  private readonly _portal$: Observable<PortalPosition | 'ready'> = defer(() =>
    this._worker$.pipe(
      switchMap((worker: Worker) =>
        fromEvent<MessageEvent>(worker, 'message').pipe(
          map(
            (workerMessage: MessageEvent) =>
              workerMessage.data as PortalPosition
          ),
          takeUntil(this._destroy)
        )
      )
    )
  );
  readonly portal$: Observable<PortalPosition> = defer(() =>
    this._portal$.pipe(
      filter(
        (serviceWorkerMessage: PortalPosition | 'ready') =>
          typeof serviceWorkerMessage !== 'string'
      ),
      map(
        (serviceWorkerMessage: PortalPosition | 'ready') =>
          serviceWorkerMessage as PortalPosition
      )
    )
  );
  private _worker = new BehaviorSubject<Worker | undefined>(undefined);
  private _worker$: Observable<Worker> = defer(
    () =>
      this._worker.pipe(
        filter((worker: Worker | undefined) => !!worker)
      ) as Observable<Worker>
  );
  private readonly _wasmReady = new BehaviorSubject<boolean>(false);

  private readonly _destroy: Subject<void> = new Subject<void>();
  constructor(private readonly _ngZone: NgZone) {
    this._portal$
      .pipe(
        filter((isReady) => typeof isReady === 'string' && isReady === 'ready'),
        take(1)
      )
      .subscribe(() => {
        console.log('ready');
        this._wasmReady.next(true);
      });
    this._registerServiceWorker();

    navigator.serviceWorker?.startMessages();
  }

  async ngOnDestroy(): Promise<void> {
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.getRegistrations().then((registrations) => {
    //     for (const registration of registrations) {
    //       if (
    //         registration?.active?.scriptURL ===
    //         `${window.origin}/${this._workerName}`
    //       ) {
    //         'removeAllListeners' in registration &&
    //           (registration as any).removeAllListeners();
    //         registration.unregister();
    //       }
    //     }
    //   });
    // }
    const worker = this._worker.getValue();
    worker && worker.terminate();
    this._worker.next(undefined);
    this._worker.complete();
    this._destroy.next();
  }

  createPortal(payload: CreatePortal) {
    this._ngZone.runOutsideAngular(() => {
      this._worker$ &&
        this._worker$
          .pipe(
            filter((worker: Worker) => !!worker),
            switchMap((worker) =>
              this._wasmReady.pipe(
                filter((isReady: boolean) => isReady),
                tap(() => {
                  worker.postMessage(payload);
                })
              )
            ),
            take(1),

            catchError((error) => {
              console.log(error);
              return of(error);
            })
          )
          .subscribe();
    });
  }
  private _registerServiceWorker() {
    this._ngZone.runOutsideAngular(async () => {
      const myWorker = new Worker(this._workerName);
      this._worker.next(myWorker);
      // if ('serviceWorker' in navigator) {
      //   try {
      //     await navigator.serviceWorker.register(this._workerName);
      //   } catch (error) {
      //     console.error(`Registration failed with ${error}`);
      //   }
      // }
    });
  }
}
