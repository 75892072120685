import { Component, HostBinding, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sim-form-field',
  templateUrl: './sim-form-field.component.html',
  styleUrls: ['./sim-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimFormFieldComponent {
  @HostBinding() class = 'sim-form-field';
}
