import { Injectable, OnDestroy } from '@angular/core';
import { Vector } from '@simlab/matterport/transform';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IDisposable, MpSdk } from 'mpSdk';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  defer,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  firstValueFrom,
  from,
  fromEvent,
  iif,
  map,
  merge,
  of,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { Object3D, Vector3 } from 'three';
import {
  TransformControls as ThreeTransformControls,
  TransformControls,
} from 'three/examples/jsm/controls/TransformControls';
import {
  makeMeasurementToolComponentRenderer,
  measurementToolType,
} from '../base/area-mesh';
import { Pointer } from '../models/dto';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Units } from '@simlab/matterport/api';
import { MatterportServiceBase } from '../base/matterport-base';
import { ExtendedMap } from '../helpers/extended-map';
import { mobileTouch$ } from '../helpers/mobile-event';
import { RaycasterHelper } from '../helpers/raycaster';
import { randomUUID } from '../helpers/uuid';
import {
  IAreaTool,
  IMeasurementComponent,
  MeasurementMode,
  MeshTriangle,
  TAreaListenersAction,
  TAreaMesh,
  TAreaMeshChange,
  TAreaMeshInputs,
  TMeasurementElements,
  Vertice,
} from '../models/measurement-tool.interface';

export type MeasurementComponent = {
  object: MpSdk.Scene.IObject;
  node: MpSdk.Scene.INode;
  comp: MpSdk.Scene.IComponent & TAreaMesh & IMeasurementComponent;
};

const mockData: TAreaMesh = {
  id: '123',
  color: '#1459d2',
  vertices: [
    {
      index: 0,
      x: -0.6928358,
      y: 1.9658695,
      z: -7.0804915,
    },
    {
      index: 1,
      x: -0.69233066,
      y: 2.0796583,
      z: -6.302995,
    },
    {
      index: 3,
      x: -0.6908549,
      y: 1.5582411,
      z: -7.0839143,
    },
    {
      index: 2,
      x: -0.6884511,
      y: 1.1422898,
      z: -6.806777,
    },
  ],
  triangles: [
    {
      index: 0,
      firstVertice: 0,
      secondVertice: 1,
      thirdVertice: 2,
    },
    {
      index: 3,
      firstVertice: 2,
      secondVertice: 1,
      thirdVertice: 3,
    },
  ],

  surfaceSize: 0.39293933,
};

@Injectable()
export class MeasurementToolService
  extends MatterportServiceBase
  implements OnDestroy, IAreaTool
{
  private _raycasterHelper!: RaycasterHelper;
  private readonly _measurementMap: ExtendedMap<string, MeasurementComponent> =
    new ExtendedMap<string, MeasurementComponent>();
  private readonly _componentRegistered: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _transformControls!: ThreeTransformControls;
  private readonly _selectedComponentId: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  readonly selectedArea$: Observable<string | undefined> =
    this._selectedComponentId.asObservable();
  readonly selectedAreaMode$: Observable<MeasurementMode> = defer(() =>
    this.selectedArea$.pipe(
      switchMap((selectedArea) => {
        if (!selectedArea) return of(undefined);
        const component = this._measurementMap.get(selectedArea);
        if (!component) return of(undefined);
        return component.comp.mode$;
      }),
      distinctUntilChanged()
    )
  );
  readonly selectedAreaChange$: Observable<TAreaMeshChange | undefined> = defer(
    () =>
      this.selectedArea$.pipe(
        switchMap((selectedArea) => {
          if (!selectedArea) return of(undefined);
          const component = this._measurementMap.get(selectedArea);
          if (!component) return of(undefined);
          return component.comp.areaChange$;
        })
      )
  );
  private readonly _removeListeners: Subject<void> = new Subject<void>();
  private _listenersEnabled = false;
  private _component!: IDisposable | null | undefined;
  private _surfaceSizeUnit: Units | undefined;
  private _hiddenAreasOmit: undefined | string[] = undefined;
  private _matTransformControl?: {
    node: MpSdk.Scene.INode;
    control: MpSdk.Scene.IComponent;
    transformControls: TransformControls;
  };

  private get _selectedComponent(): MeasurementComponent | undefined {
    const id = this._selectedComponentId.getValue();
    if (!id) return;
    return this._measurementMap.get(id);
  }
  set selectedAreaMode(mode: MeasurementMode) {
    const component = this._selectedComponent;
    component && (component.comp.mode = mode);
  }
  get selectedAreaMode(): MeasurementMode {
    return this._selectedComponent?.comp.mode;
  }
  addListener<T = any>(
    listenerActionType: TAreaListenersAction
  ): Observable<T> {
    this._transformControls && this._transformControls.detach();
    this._matterportManager.position.setSweepsActive(false);
    switch (listenerActionType) {
      case 'ADD_POINT': {
        this._listenersEnabled = true;

        return this._addPointListener() as Observable<T>;
      }
      case 'REMOVE_POINT': {
        this._listenersEnabled = true;
        return this._removePointListener() as Observable<T>;
      }
      case 'CREATION_POINT': {
        this._listenersEnabled = true;
        return this._areaCreationPointListener() as Observable<T>;
      }
      default: {
        throw new Error('unknown listener type');
      }
    }
  }
  finish() {
    const component = this._selectedComponent;
    if (!component) return;
    component.comp.finish();
    this._transformControls &&
      this._transformControls.detach() &&
      (component.comp.collider = false);
    setTimeout(() => {
      this._matterportManager.position.setSweepsActive(true);
    }, 100);
  }

  cancelAreaCreation() {
    this._removeListeners.next();
    const component = this._selectedComponent;
    if (!component) return;
    if (component.comp.mode === 'create') this.deleteSelectedArea();
    if (component.comp.mode === 'update') component.comp.cancelAllChanges();
    this._transformControls &&
      this._transformControls.detach() &&
      (component.comp.collider = false);
    setTimeout(() => {
      this._matterportManager.position.setSweepsActive(true);
    }, 100);
  }
  set surfaceSizeUnit(unit: Units) {
    this._measurementMap.forEach((component) => {
      component.comp.surfaceSizeUnit = unit;
    });
    this._surfaceSizeUnit = unit;
  }

  get canRedo(): boolean {
    const component = this._selectedComponent;
    if (!component) return false;
    return component.comp.canRedo;
  }
  get canUndo(): boolean {
    const component = this._selectedComponent;
    if (!component) return false;
    return component.comp.canUndo;
  }
  private _removePointListener(): Observable<string | undefined> {
    const component = this._selectedComponent;
    if (!component) return of(undefined);
    component.comp.mode = 'update';
    const tempSprite = component.comp.createTemporaryPoint('REMOVE_POINT');
    component.comp.disableLayers = {
      lines: true,
      mesh: true,
      points: false,
    };
    this._matterportManager.events.pointer$
      .pipe(
        map((pointer: Pointer) => {
          const intersect = this._getPointerIntersection(pointer);
          return intersect?.filter((object) => object.object.visible);
        }),
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            tempSprite.visible = false;
          } else {
            tempSprite.visible = true;
          }
        }),
        filter((intersection) => !!intersection && intersection.length > 0),
        map((intersects) => {
          if (!intersects) return;
          return intersects[0];
        }),
        takeUntil(this._removeListeners),
        finalize(() => {
          component.comp.removeTemporaryPoint();
          component.comp.disableLayers = {
            lines: false,
            mesh: true,
            points: false,
          };
        })
      )
      .subscribe((intersectionObject) => {
        if (
          !intersectionObject ||
          (intersectionObject.object.name as TMeasurementElements) !==
            'AREA_POINT'
        ) {
          tempSprite.visible = false;
          return;
        }
        tempSprite.visible = true;
        tempSprite.position.copy(intersectionObject.object.position);
      });
    const isMobile = this.isTouchDevice();
    return iif(
      () => isMobile,
      this._onMatterportHold$(),
      this._onMatterportClick$()
    ).pipe(
      filter((intersects) => !!intersects && intersects.length > 0),
      map((intersects) => {
        if (!intersects || !intersects.length) return;
        const intersect = intersects.find(
          (object) =>
            (object.object.name as TMeasurementElements) === 'AREA_POINT'
        );
        if (intersect) {
          const areaComponentId = intersect.object.parent?.name;
          if (!areaComponentId) return;
          const component = this._measurementMap.get(areaComponentId)?.comp;

          if (!component) return;
          if (component.selected === false) {
            component.selected = true;
            return;
          }
          component.removePoint(intersect.object.userData['id']);
          return intersect.object.userData['id'];
        }
      }),
      takeUntil(this._removeListeners)
    );
  }
  set segmentsVisibility(visible: boolean) {
    const selected = this._selectedComponent;
    if (!selected) return;
    selected.comp.segmentsVisibility = visible;
  }
  isTouchDevice() {
    return (
      ('ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        ('msMaxTouchPoints' in navigator &&
          ((navigator as any).msMaxTouchPoints as number) > 0)) &&
      !window.matchMedia('(pointer:fine)').matches
    );
  }
  private _areaCreationPointListener(): Observable<Pointer | undefined> {
    const component = this._selectedComponent;
    if (!component) return of(undefined);

    const tempSprite = component.comp.createTemporaryPoint('CREATION_POINT');
    if (component.comp.mode !== 'create') {
      throw Error('You cannot add after creation end');
    }
    this._matterportManager.events.pointer$
      .pipe(
        takeUntil(this._removeListeners),
        finalize(() => {
          component.comp.removeTemporaryPoint();
        })
      )
      .subscribe((intersectionObject) => {
        const { position } = intersectionObject;
        tempSprite.position.set(position.x, position.y, position.z);
      });
    const isMobile = this.isTouchDevice();

    return iif(
      () => isMobile,
      mobileTouch$(this.proggress, this.renderer.domElement),
      this._matterportManager.events.matterportClick$
    ).pipe(
      switchMap(() => this._matterportManager.events.pointer$.pipe(take(1))),
      first(),
      takeUntil(this._removeListeners),
      tap((pointer) => {
        component.comp.addPoint(pointer.position, pointer.normal);
      })
    );
  }
  private _addPointListener(): Observable<string | undefined> {
    const component = this._selectedComponent;
    if (!component) return of(undefined);
    component.comp.mode = 'update';
    const tempSprite = component.comp.createTemporaryPoint('ADD_POINT');
    component.comp.disableLayers = {
      lines: false,
      mesh: true,
      points: true,
    };
    this._matterportManager.events.pointer$
      .pipe(
        map((pointer: Pointer) => {
          const intersect = this._getPointerIntersection(pointer);
          return intersect?.filter((object) => object.object.visible);
        }),
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            tempSprite.visible = false;
          } else {
            tempSprite.visible = true;
          }
        }),
        filter((intersection) => !!intersection && intersection.length > 0),
        map((intersects) => {
          if (!intersects) return;
          return intersects[0];
        }),
        takeUntil(this._removeListeners),
        finalize(() => {
          component.comp.removeTemporaryPoint();
          component.comp.disableLayers = {
            lines: false,
            mesh: true,
            points: false,
          };
        })
      )
      .subscribe((intersectionObject) => {
        if (!intersectionObject) return;
        const position = intersectionObject.point.add(
          new Vector3(
            intersectionObject.normal?.x || 0,
            intersectionObject.normal?.y || 0,
            intersectionObject.normal?.z || 0
          ).multiply(new Vector3(0.03, 0.03, 0.03))
        );
        const { x, y, z } = component.comp.getClosestPoint(
          intersectionObject.object.userData['id'],
          position
        );
        tempSprite.position.set(x, y, z);
      });
    const isMobile = this.isTouchDevice();
    return iif(
      () => isMobile,
      this._onMatterportHold$(),
      this._onMatterportClick$()
    ).pipe(
      filter((intersects) => !!intersects && intersects.length > 0),
      map((intersects) => {
        if (!intersects || !intersects.length) return;
        const intersect = intersects.find(
          (object) =>
            (object.object.name as TMeasurementElements) === 'AREA_LINE'
        );
        if (intersect) {
          const areaComponentId = intersect.object.parent?.name;
          if (!areaComponentId) return;
          const component = this._measurementMap.get(areaComponentId)?.comp;
          if (!component) return;
          if (component.selected === false) {
            component.selected = true;
            return;
          }

          return component.addClosestPoint(
            intersect.object.userData['id'],
            intersect.point,
            intersect.normal || new Vector3()
          );
        }
        return undefined;
      }),
      takeUntil(this._removeListeners)
    );
  }
  removeListener() {
    this._removeListeners.next();
    this._listenersEnabled = false;
    setTimeout(() => {
      this._matterportManager.position.setSweepsActive(true);
    }, 100);
  }
  hideAreas(omitIds: string[] = []) {
    this._hiddenAreasOmit = omitIds;
    omitIds.forEach((omit) => {
      this._measurementMap.get(omit)?.comp.show();
    });
    this._measurementMap.forEach(
      (component: MeasurementComponent) =>
        !omitIds.includes(component.comp.id) && component.comp.hide()
    );
  }
  showAreas(omitIds: string[] = []) {
    this._hiddenAreasOmit = undefined;
    this._measurementMap.forEach(
      (component: MeasurementComponent) =>
        !omitIds.includes(component.comp.id) && component.comp.show()
    );
  }
  deleteAllAreas() {
    Array.from(this._measurementMap.keys()).forEach((id) =>
      this.deleteArea(id)
    );
  }

  undo() {
    this._transformControls && this._transformControls.detach();
    const component = this._selectedComponent;
    component && (component.comp.collider = false);
    component?.comp.undo();
  }
  redo() {
    this._transformControls && this._transformControls.detach();
    const component = this._selectedComponent;
    component && (component.comp.collider = false);
    component?.comp.redo();
  }

  protected async _init() {
    this._measurementMap.mapChange$
      .pipe(
        switchMap((entries) => {
          const selectedId = this._selectedComponentId.getValue();
          const component = selectedId && this._measurementMap.get(selectedId);
          component && (component.comp.selected = true);
          return merge(
            ...Array.from(entries.values()).map((component) => {
              if (
                this._hiddenAreasOmit &&
                !this._hiddenAreasOmit.includes(component.comp.id) &&
                selectedId !== component.comp.id
              ) {
                component.comp.hide();
              }
              return component.comp.selectionChange$;
            })
          ).pipe(startWith(selectedId));
        }),
        takeUntil(this._destroy)
      )
      .subscribe((selectedComponentId: string | undefined) => {
        this._selectedComponentId.next(selectedComponentId);

        this._measurementMap.forEach((component) => {
          if (selectedComponentId !== component.comp.id)
            component.comp.selected = false;
        });
      });
    this._component = await firstValueFrom(
      this.registerComponents$(
        measurementToolType,
        makeMeasurementToolComponentRenderer
      )
    ).catch(() => null);

    if (!this._component) return;
    this._componentRegistered.next(true);
    /////////////test
    // const transformedPositions: Vertice[] = mockData.vertices.map(
    //   ({ x, y, z, index }) => {
    //     const calcPosition = this.transformConverter.to3dPosition(
    //       new Vector(x, y, z)
    //     );
    //     return {
    //       x: calcPosition.x,
    //       y: calcPosition.y,
    //       z: calcPosition.z,
    //       index,
    //     };
    //   }
    // );
    // const transformedDate: TAreaMesh = {
    //   ...mockData,
    //   vertices: transformedPositions,
    // };
    // this.addArea(transformedDate);
    /////////////////////////////
    this._onMatterportClickObserver();
  }

  override ngOnDestroy(): void {
    this._component?.dispose();

    super.ngOnDestroy();
  }

  set selectedArea(id: TAreaMesh['id'] | undefined) {
    if (!id) {
      const selectedId = this._selectedComponentId.getValue();
      if (!selectedId) return;
      const selectedComponent = this._measurementMap.get(selectedId);
      selectedComponent && (selectedComponent.comp.selected = false);
      return;
    }
    const component = this._measurementMap.get(id);
    if (component) {
      component.comp.selected = true;
    } else this._selectedComponentId.next(id);
  }
  get selectedArea(): TAreaMesh['id'] | undefined {
    const id = this._selectedComponentId.getValue();
    return id;
  }
  editSelectedArea() {
    const component = this._selectedComponent;
    if (!component) return;
    component && (component.comp.mode = 'update');
  }
  deleteArea(id: TAreaMesh['id']) {
    const component = this._measurementMap.get(id);
    if (component) {
      component.node.stop();
      component.object.stop();
      this._measurementMap.delete(id);
    }
  }
  deleteSelectedArea() {
    const selectedId = this._selectedComponentId.getValue();
    selectedId && this.deleteArea(selectedId);
    this._selectedComponentId.next(undefined);
  }
  updateAreaColor = (id: string, color: string | undefined) => {
    const component = this._measurementMap.get(id);
    if (component) {
      component.comp.inputs && (component.comp.inputs['color'] = color);
    }
  };

  updateSelectedAreaColor(color: string | undefined) {
    const component = this._selectedComponent;
    if (component) {
      component.comp.inputs && (component.comp.inputs['color'] = color);
    }
  }
  createArea(): string {
    const area: TAreaMesh = {
      id: randomUUID(),
      surfaceSize: 0,
      triangles: [],
      vertices: [],
      color: '#4374E4',
    };
    firstValueFrom(this._createAreaNode$(area));
    this.selectedArea = area.id;
    return area.id;
  }
  addArea(data: TAreaMesh): string {
    const orderedIndexes: { prev: number; acc: number }[] = [];
    const transformedPositions: Vertice[] = data.vertices
      .map(({ x, y, z, index }, arrIdx) => {
        const calcPosition = this.transformConverter.toMatterportPosition(
          new Vector(x, y, z)
        );
        arrIdx !== index && orderedIndexes.push({ prev: arrIdx, acc: index });
        return {
          x: calcPosition.x,
          y: calcPosition.y,
          z: calcPosition.z,
          index,
        };
      })
      .sort((a, b) => a.index - b.index);

    let sortedTriangles: MeshTriangle[] = [];
    sortedTriangles = data.triangles.map(
      ({ firstVertice, secondVertice, thirdVertice }, index) => {
        const changes = (verticeIdx: number) => {
          const change = orderedIndexes.find(
            (orderedIdx) => orderedIdx.prev === verticeIdx
          );
          if (!change) return verticeIdx;
          return change.acc;
        };
        firstVertice = changes(firstVertice);
        secondVertice = changes(secondVertice);
        thirdVertice = changes(thirdVertice);
        return {
          index,
          firstVertice,
          secondVertice,
          thirdVertice,
        };
      }
    );

    const transformedDate: TAreaMesh = {
      ...data,
      vertices: transformedPositions,
      triangles: sortedTriangles,
    };
    firstValueFrom(this._createAreaNode$(transformedDate));
    return data.id;
  }
  private _onMatterportClick$() {
    return fromEvent(this.renderer.domElement, 'pointerdown').pipe(
      switchMap(() => this._matterportManager.events.pointer$.pipe(take(1))),
      map((pointer: Pointer) => {
        const intersect = this._getPointerIntersection(pointer);
        return intersect?.filter((object) => object.object.visible);
      })
    );
  }
  private _onMatterportHold$() {
    return mobileTouch$(this.proggress, this.renderer.domElement).pipe(
      switchMap(() => this._matterportManager.events.pointer$.pipe(take(1))),
      map((pointer: Pointer) => {
        const intersect = this._getPointerIntersection(pointer);
        return intersect?.filter((object) => object.object.visible);
      })
    );
  }
  private _getPointerIntersection(pointer: Pointer) {
    if (!pointer || !this._raycasterHelper) return;
    this._raycasterHelper.selectedLayers = [21];
    return this._raycasterHelper.getIntersectObjectsWithPoint(pointer.position);
  }
  private _onMatterportClickObserver() {
    this._onMatterportClick$()
      .pipe(
        filter(() => {
          const component = this._selectedComponent;
          return (
            this._listenersEnabled === false &&
            (!component || component.comp.mode !== 'create')
          );
        }),
        tap((intersects) => {
          if (!intersects || !intersects.length) {
            this._transformControls && this._transformControls.detach();
            const component = this._selectedComponent;
            component && (component.comp.collider = false);
            return;
          }
          const [intersect] = intersects.filter((object) => {
            return object.object.parent?.visible;
          });

          if (intersect) {
            const areaComponentId = intersect.object.parent?.name;
            if (!areaComponentId) return;
            const component = this._measurementMap.get(areaComponentId)?.comp;
            if (!component) return;
            if (component.selected === false) {
              component.selected = true;
              return;
            }
            if (!this._transformControls) {
              this._transformControls = this._transformControl;
              component.context.scene.add(this._transformControls);
            }
            this._matTransformControl &&
              this._matTransformControl.control.inputs &&
              (this._matTransformControl.control.inputs['selection'] =
                this._measurementMap.get(areaComponentId)?.node);
            this._transformControls.detach();

            component.onComponentClick(this._transformControls, intersect);
          } else {
            this._transformControls && this._transformControls.detach();
            const component = this._selectedComponent;
            component && (component.comp.collider = false);
          }
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  private _createAreaNode$(data: TAreaMesh) {
    return combineLatest([this.isOpen, this._componentRegistered]).pipe(
      filter(([isOpen, isRegistered]) => isOpen && isRegistered),
      first(),
      switchMap(() =>
        from(this.sdk.Scene.createObjects(1)).pipe(
          map(([object]: MpSdk.Scene.IObject[]) => {
            const node = object.addNode();
            const comp = node.addComponent(
              measurementToolType,
              {
                ...data,
                surfaceSizeUnit: this._surfaceSizeUnit,
              } as TAreaMeshInputs,
              data.id
            ) as MpSdk.Scene.IComponent &
              TAreaMeshInputs &
              IMeasurementComponent;

            node.start();
            if (!this._raycasterHelper) {
              this._raycasterHelper = new RaycasterHelper(
                comp.context.three,
                comp.context.camera,
                comp.context.scene
              );
            }
            this._measurementMap.set(data.id, { object, node, comp });
            return { object, node, comp };
          }),
          take(1),
          catchError((e) => {
            console.log(e);
            return of(null);
          })
        )
      )
    );
  }

  private get _transformControl() {
    if (this._matTransformControl) {
      return this._matTransformControl.transformControls;
    }
    if (!this._selectedComponent) return;
    const node = this._selectedComponent.object.addNode();
    const control = node.addComponent('mp.transformControls');
    if (!control.inputs) return;
    control.inputs['selection'] = this._selectedComponent.node;
    control.inputs['mode'] = 'translate';
    node.start();
    const transformControls = (control as any)['transformControls'];
    transformControls.layers.set(21);
    transformControls.traverse((object: Object3D<Event>) => {
      ['X', 'Y', 'Z'].includes(object.name) && object.layers.enable(21);
    });

    this._matTransformControl = {
      node,
      transformControls,
      control,
    };
    return transformControls;
  }
}
