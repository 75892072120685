import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { inject, Injectable } from '@angular/core';
import { ExtendedMap } from '@simOn/ui/sim-icon';
import { filter, map, merge, Observable, Subject, Subscription } from 'rxjs';

const DEFAULT_THROTTLE_TIME = 500;

type ScrolledEventConfig = {
  readonly containerId: string | undefined;
  readonly throttleTime: number;
};

type ScrolledDownEventConfig = {
  readonly scrollDistancePercentTrigger: number;
} & ScrolledEventConfig;

interface ScrollObserver {
  readonly _defaultScrolledDownEventConfig: ScrolledDownEventConfig;
  readonly _defaultScrolledUpEventConfig: ScrolledEventConfig;

  scrolledDown$(config?: Partial<ScrolledDownEventConfig>): Observable<HTMLElement>;
  scrolledUp$(config?: Partial<ScrolledEventConfig>): Observable<HTMLElement>;
}

@Injectable({
  providedIn: 'root'
})
export class StagesScrollDispatcher extends ScrollDispatcher {
  override scrollContainers: ExtendedMap<CdkScrollable, Subscription> = new ExtendedMap<CdkScrollable, Subscription>();

  override register(scrollable: CdkScrollable): void {
    super.register(scrollable);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ScrollObserverService implements ScrollObserver {
  private readonly _scrollDispatcher = inject(ScrollDispatcher) as StagesScrollDispatcher;
  private readonly _checkContainers = new Subject<void>();
  readonly _defaultScrolledDownEventConfig: ScrolledDownEventConfig = {
    scrollDistancePercentTrigger: 80,
    throttleTime: DEFAULT_THROTTLE_TIME,
    containerId: undefined
  };
  readonly _defaultScrolledUpEventConfig: ScrolledEventConfig = {
    throttleTime: DEFAULT_THROTTLE_TIME,
    containerId: undefined
  };

  check() {
    this._checkContainers.next();
  }

  getScrolledContainer$(containerId: string): Observable<HTMLElement | undefined> {
    return merge(this._checkContainers, this._scrollDispatcher.scrollContainers.mapChange$).pipe(
      map(() => {
        const element = Array.from(this._scrollDispatcher.scrollContainers.keys()).find((value) => {
          return value.getElementRef().nativeElement.id === containerId;
        });
        if (element === undefined) return undefined;

        return element.getElementRef().nativeElement;
      })
    );
  }

  isNotScrollableContent$(containerId: string) {
    return this.getScrolledContainer$(containerId).pipe(
      filter((ele): ele is HTMLElement => ele !== undefined),
      map((ele) => {
        const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
        const overflowYStyle = window.getComputedStyle(ele).overflowY;
        const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

        return hasScrollableContent && !isOverflowHidden;
      }),
      filter((isScrollableContent) => !isScrollableContent)
    );
  }

  /**
   * You should set containerId if you want to subscribe specific container.
   * Example:
   * ```
   * <div id="scroll-container" cdkScrollable>...</div>
   * instance.scrolledDown$({containerId: 'scroll-container'})
   * ```
   */
  scrolledDown$(config: Partial<ScrolledDownEventConfig> = {}): Observable<HTMLElement> {
    const _config = {
      ...this._defaultScrolledDownEventConfig,
      ...config
    };
    let prevScrollTop = 0;

    return this._scrolled$(_config).pipe(
      map((dispatcher) => {
        const element = dispatcher.getElementRef().nativeElement;
        const { scrollTop, clientHeight, scrollHeight } = this._getScrolledContainerData(element);

        const scrollPercentage = scrollHeight !== 0 ? ((scrollTop + clientHeight) / scrollHeight) * 100 : 0;

        const isScrolledInDownDirection = scrollTop > prevScrollTop;

        prevScrollTop = scrollTop;

        return { scrollPercentage, isScrolledInDownDirection, element };
      }),
      filter(
        ({ scrollPercentage, isScrolledInDownDirection }) =>
          scrollPercentage > _config.scrollDistancePercentTrigger && isScrolledInDownDirection
      ),
      map(({ element }) => element)
    );
  }

  /**
   * You should set containerId if you want to subscribe specific container.
   * Example:
   * ```
   * <div id="scroll-container" cdkScrollable>...</div>
   * instance.scrolledUp$({containerId: 'scroll-container'})
   * ```
   */
  scrolledUp$(config: Partial<ScrolledEventConfig> = {}): Observable<HTMLElement> {
    const _config = {
      ...this._defaultScrolledUpEventConfig,
      ...config
    };
    let prevScrollTop = 0;

    return this._scrolled$(_config).pipe(
      map((dispatcher) => {
        const element = dispatcher.getElementRef().nativeElement;

        const { scrollTop } = this._getScrolledContainerData(element);
        const isScrolledInUpDirection = scrollTop < prevScrollTop;

        prevScrollTop = scrollTop;

        return { isScrolledInUpDirection, element };
      }),
      filter(({ isScrolledInUpDirection }) => isScrolledInUpDirection),
      map(({ element }) => element)
    );
  }

  private _scrolled$({ throttleTime, containerId }: ScrolledEventConfig): Observable<CdkScrollable> {
    return this._scrollDispatcher.scrolled(throttleTime).pipe(
      filter((dispatcher): dispatcher is CdkScrollable => typeof dispatcher === 'object'),
      filter((dispatcher) => {
        if (containerId === undefined) return true;

        const element = dispatcher.getElementRef().nativeElement;
        const isContainerExists = element.id === containerId;
        if (!isContainerExists) {
          console.error(`Container scroll with id: "${containerId}" doesn't exists`);
        }

        return isContainerExists;
      })
    );
  }

  private _getScrolledContainerData(element: HTMLElement) {
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const clientHeight = element.clientHeight;

    return { scrollTop, scrollHeight, clientHeight };
  }
}
