import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { MediaApiService } from '@simOn/common/media';
import { IMAGE_SIZE_URL_SUFFIX, SupportedImageTypes } from '@simOn/common/upload-image';
import { FileBlob } from '@simOn/device/models';
import { UAnimatedIcon, animatedIconUrl } from '@simOn/ui/sim-animated-icons';
import { SimIconComponent } from '@simOn/ui/sim-icon';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'device-widget-image',
  standalone: true,
  imports: [CommonModule, SimIconComponent],
  templateUrl: './widget-image.component.html',
  styleUrls: ['./widget-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetImageComponent {
  private readonly _mediaApiService = inject(MediaApiService);
  private _icon: UAnimatedIcon | undefined | null;
  image$: Observable<unknown> | null = null;
  @Input() set file(value: FileBlob | undefined | null) {
    if (value?.blobPath) {
      if (value.blobPath?.includes('base64')) {
        this.image$ = of(value.blobPath);
      } else {
        this.image$ = this._mediaApiService.generateBlobUrlForDownload(this.getUrl(value.blobPath));
      }
    } else {
      this.image$ = null;
    }
  }

  @Input() set icon(value: UAnimatedIcon | undefined | null) {
    this._icon = value;
  }
  get icon(): UAnimatedIcon | undefined {
    return this._icon || 'device_unknown';
  }

  get iconUrl(): string {
    return this._icon ? animatedIconUrl(this._icon) : '';
  }

  getUrl(url: string): string {
    let locExtension: string | undefined = undefined;
    for (let extension of SupportedImageTypes) {
      if (url.includes(extension.fileExtension)) {
        locExtension = extension.fileExtension;
        break;
      }
    }

    if (locExtension) {
      return url.replace(locExtension, IMAGE_SIZE_URL_SUFFIX.Medium + locExtension);
    } else {
      return url + IMAGE_SIZE_URL_SUFFIX.Medium;
    }
  }
}
