import { createAction, props } from '@ngrx/store';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import { DevicesAddDtoInterface, DeviceStructure, NotImportedDevice } from '@simOn/device/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const LoadDevices = createAction('[New Devices] Loaded Devices');
export const LoadDevicesSuccess = createAction(
  '[New Devices] Loaded Devices Success',
  props<{ devices: DeviceStructure[] }>()
);
export const LoadDevicesFailure = createAction('[New Devices] Loaded Devices Failure', props<{ error: Error }>());

export const AddDevices = createAction('[New Devices] Add Devices', props<{ device: DevicesAddDtoInterface }>());
export const AddDevicesSuccess = createAction('[New Devices][Success] Add Devices');
export const AddDevicesFailure = createAction('[New Devices][Failure] Add Devices', props<{ error: Error }>());

export const GetDevices = createAction('[New Devices] Get Devices');
export const GetDevicesSuccess = createAction(
  '[New Devices] Get Devices Success',
  props<{ device: DeviceStructure[] }>()
);
export const GetDevicesFailure = createAction('[New Devices] Get Devices Failure', props<{ error: Error }>());

export const GetDevice = createAction('[New Devices] Get Device', props<{ id: string }>());
export const GetDeviceSuccess = createAction(
  '[New Devices] Get Device Success',
  props<{ device: DeviceStructure[] }>()
);
export const GetDeviceFailure = createAction('[New Devices] Get Device Failure', props<{ error: Error }>());

export const GetNotAssignedDevices = createAction(
  '[New Devices] Get Not Assigned Devices',
  props<{ providers: { masterDeviceId: string; smartApiProvider: SmartApiProviderEnum }[] }>()
);
export const GetNotAssignedDevicesSuccess = createAction(
  '[New  Devices][Success] Get Not Assigned Devices',
  props<{ unassignedDevices: NotImportedDevice[] }>()
);
export const GetNotAssignedDevicesFailure = createAction(
  '[New  Devices][Failure] Get Not Assigned Devices',
  props<{ error: Error }>()
);

export const ClearDeviceState = CLEAR_STATE_ACTION('Device');

//UpdateDeviceDTO
export const UpdateDevice = createAction('[New Devices] Update Device', props<{ updateDevice: any }>());
export const UpdateDeviceSuccess = createAction('[New Devices] Update Device Success', props<{ updateDevice: any }>());
export const UpdateDeviceFailure = createAction('[New Devices] Update Device Failure', props<{ error: Error }>());

export const DeleteDevice = createAction('[New Devices] Delete Devices', props<{ id: string }>());
export const DeleteDeviceSuccess = createAction('[New Devices][Success] Delete Devices', props<{ id: string }>());
export const DeleteDeviceFailure = createAction('[New Devices][Failure] Delete Devices', props<{ error: Error }>());
export const RemoveDevicesAfterDeleteProvider = createAction(
  '[Devices] Remove Devices After Delete Provider',
  props<{ masterDeviceId: string }>()
);
