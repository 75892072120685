<span class="prefix-suffix-wrapper prefix" style="z-index: 1" *ngIf="prefix && showPrefix">
  <ng-content *ngTemplateOutlet="prefix ?? null"> </ng-content> </span
><input
  #input
  trim="blur"
  [type]="type"
  [disabled]="disabled"
  [required]="required"
  [value]="value"
  [autocomplete]="autocomplete"
  [ngClass]="{ 'prefix-padding': prefix && showPrefix, 'suffix-padding': suffix }"
  placeholder="{{ placeholder }}"
  readonly="{{ readonly || null }}"
  name="sim-input"
  [id]="inputId"
  (focusout)="_onTouched()"
  (focus)="_focusChanged(true)"
  (blur)="_focusChanged(false)"
  (input)="emitChangeEvent($event)"
  [min]="min"
  [max]="max"
  class="sim-text-ellipsis sim-input"
  [autocomplete]="type === 'password' ? 'new-password' : 'off'"
/>
<span class="prefix-suffix-wrapper suffix">
  <span *ngIf="suffix">
    <ng-content *ngTemplateOutlet="suffix ?? null"> </ng-content>
  </span>
  @if(isError()){
  <sim-icon class="error-suffix" icon="info" color="var(--ui-accent-darker, #cd3f3e)" sizeType="small" />
  }
</span>
