import { Directive, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  filter,
  from,
  interval,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import THREE, { Camera, Raycaster, WebGLRenderer } from 'three';
import { OrbitControls as ThreeOrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  TransformControls as ThreeTransformControls,
  TransformControlsPlane,
} from 'three/examples/jsm/controls/TransformControls';
import { IDisposable, MpSdk } from '../../../assets/bundle/sdk';
import { MatterportManagerService } from '../services/matterport-manager.service';
export type ExtendedThree = typeof THREE & {
  TransformControls: typeof ThreeTransformControls;
  OrbitControls: typeof ThreeOrbitControls;
};

@Directive()
export abstract class MatterportServiceBase implements OnDestroy {
  protected _sdk!: MpSdk | null;
  protected readonly _destroy: Subject<void> = new Subject<void>();
  protected readonly isOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _webglRenderer!: WebGLRenderer;
  private _threeRef!: ExtendedThree;
  constructor(readonly _matterportManager: MatterportManagerService) {
    _matterportManager.sdk$
      .pipe(
        filter((sdk: MpSdk | undefined) => !!sdk),
        take(1),
        takeUntil(this._destroy)
      )
      .subscribe((sdk: MpSdk | undefined) => {
        if (sdk) {
          this._sdk = sdk;
          this._sdk?.Scene.configure((renderer, three) => {
            this._webglRenderer = renderer;
            this._threeRef = three as ExtendedThree;
          });

          this._init();
          this.isOpen.next(true);
        }
      });
  }

  get renderer() {
    return this._webglRenderer;
  }

  get THREE() {
    return this._threeRef;
  }

  get sdk() {
    return this._sdk as MpSdk;
  }
  get transformConverter() {
    return this._matterportManager.transformConverter;
  }
  get proggress() {
    return this._matterportManager.componentRef.instance.progress.nativeElement;
  }

  get TransformControlExtended(): typeof ThreeTransformControls {
    const raycaster = new this.THREE.Raycaster();
    const Transform = class extends this.THREE.TransformControls {
      _onPointerDownRef: (e: any) => void;
      constructor(readonly _object: Camera, readonly _domElement: HTMLElement) {
        super(_object, _domElement);
        this._onPointerDownRef = (this as any)._onPointerDown;
        _domElement.removeEventListener('pointerdown', this._onPointerDownRef);
        _domElement.addEventListener(
          'pointerdown',
          (e) => this.extendOnPointerDown(e),
          {
            capture: true,
          }
        );
      }
      intersectObjectWithRay(
        object: TransformControlsPlane,
        raycaster: Raycaster,
        includeInvisible: boolean
      ) {
        const allIntersections = raycaster.intersectObject(object, true);
        for (let i = 0; i < allIntersections.length; i++) {
          if (allIntersections[i].object.visible || includeInvisible) {
            return allIntersections[i];
          }
        }
        return false;
      }

      extendOnPointerDown(e: any) {
        if (!this.object) return;
        const self = this as any;
        const pointer = (this as any)._getPointer(e);
        raycaster.setFromCamera(pointer, this.camera);

        const intersect = this.intersectObjectWithRay(
          self._gizmo.picker[this.mode],
          raycaster,
          true
        );
        if (!intersect) return;
        e.stopPropagation();
        this._onPointerDownRef(e);
      }
    };

    return Transform;
  }
  protected abstract _init(): void;

  ngOnDestroy(): void {
    this.isOpen.next(false);
    this._destroy.next();
    this._destroy.complete();
    this._sdk = null;
  }

  protected registerComponents$(
    name: string,
    factory: () => MpSdk.Scene.IComponent
  ): Observable<IDisposable | null | undefined> {
    return from(
      this.sdk.App.state.waitUntil((state: MpSdk.App.State) => {
        return state.phase === this.sdk.App.Phase.PLAYING;
      })
    ).pipe(
      switchMap(() =>
        interval(300).pipe(
          switchMap(() =>
            from(this.sdk.Scene.register(name, factory)).pipe(
              catchError((e) => {
                console.log(e);
                return of(undefined);
              })
            )
          ),
          catchError((e) => {
            console.log(e);
            return of(null);
          }),
          filter(
            (componentRef) => !!componentRef || componentRef === undefined
          ),
          take(1)
        )
      )
    );
  }
}
