import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Renderer2 } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Scene } from 'mpSdk';
import { firstValueFrom, from, map, Observable } from 'rxjs';
import { Vector3 } from 'three';
import { IBlueprint } from '../models/blueprint.interface';
import {
  ComponentConfiguration,
  ComponentsTypeClass,
  MatterportComponent,
  PlaneConfiguration,
} from '../models/custom-component.type';
import { TransformMode } from '../models/object-loader.type';
import { MatterportCustomComponentService } from './custom-component.service';
import { FileLoaderCacheService } from './file-loader-cache.service';

export type TextureResolution = {
  width: number;
  height: number;
};
export const InterceptorSkipHeader = 'X-Skip-Interceptor';
const MAX_PHOTO_PLANE_SIDE_SIZE = 4;
const MAX_SVG_PLANE_SIDE_SIZE = 20;

@Injectable()
export class BlueprintService implements IBlueprint {
  private _transformControl: Scene.IComponent | undefined;
  constructor(
    private readonly _componentLoader: MatterportCustomComponentService,
    private readonly _httpClient: HttpClient,
    private readonly _renderer: Renderer2,
    private readonly _fileLoaderCacheService: FileLoaderCacheService
  ) {}
  async addBlueprint(
    component: ComponentConfiguration<PlaneConfiguration>
  ): Promise<MatterportComponent<ComponentsTypeClass> | undefined> {
    if (
      !component.objects.some((config) => config instanceof PlaneConfiguration)
    )
      throw new Error(
        'Cannot find any component configuration satisfied PlaneConfiguration'
      );
    const texturePath = component.objects[0].config.texture;
    const isSVG = texturePath.includes('.svg');

    const createComponent = (
      component: ComponentConfiguration<PlaneConfiguration>
    ) =>
      firstValueFrom(
        this._componentLoader.addComponentWithOffset$(
          {
            ...component,
            autoScale: false,
            isCollider: false,
            dollhouseView: false,
            userData: {
              type: 'blueprint',
            },
            lookAt: false,
          },
          true
        )
      );
    const file = await firstValueFrom(
      this._fileLoaderCacheService.getModel$(texturePath)
    );
    if (file) component.objects[0].config.texture = file;
    if (isSVG) {
      return this._getSvgResolution(texturePath).then((prop) => {
        component.scale = this._getSvgPlaneScale(prop, component.scale);
        return createComponent(component);
      });
    } else {
      return this._getPhotoResolution(texturePath).then((prop) => {
        component.scale = this._getPhotoPlaneScale(prop, component.scale);
        return createComponent(component);
      });
    }
  }
  deleteBlueprint(blueprintId: string) {
    this._componentLoader.deleteNote(blueprintId);
  }
  transformBlueprint(mode: TransformMode) {
    this._transformControl &&
      this._transformControl.inputs &&
      (this._transformControl.inputs['mode'] = mode);
  }

  private _getPhotoPlaneScale(
    resolution: TextureResolution,
    baseScale: Vector3
  ): Vector3 {
    let { width, height } = resolution;
    const biggerSide = Math.max(width, height);
    const divideFactor = biggerSide / MAX_PHOTO_PLANE_SIDE_SIZE;
    width /= divideFactor;
    height /= divideFactor;
    width *= MAX_PHOTO_PLANE_SIDE_SIZE;
    height *= MAX_PHOTO_PLANE_SIDE_SIZE;
    return new Vector3(width * baseScale.x, height * baseScale.y, 0.001);
  }
  private _getSvgPlaneScale(
    resolution: TextureResolution,
    baseScale: Vector3
  ): Vector3 {
    let { width, height } = resolution;
    width /= MAX_SVG_PLANE_SIDE_SIZE;
    height /= MAX_SVG_PLANE_SIDE_SIZE;
    return new Vector3(width * baseScale.x, height * baseScale.y, 1.001);
  }
  private _getSvgResolution(svgPath: string): Promise<TextureResolution> {
    return firstValueFrom(this._getSvgResolution$(svgPath));
  }

  private _getSvgResolution$(svgPath: string): Observable<TextureResolution> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');
    return this._httpClient
      .get(svgPath, {
        headers,
        responseType: 'text',
      })
      .pipe(
        map((svg: string) => {
          const div: HTMLDivElement = this._renderer.createElement('div');
          div.innerHTML = svg;
          const svgElement: SVGSVGElement | null = div.querySelector('svg');
          if (svgElement) {
            return {
              width: svgElement.viewBox.baseVal.width,
              height: svgElement.viewBox.baseVal.height,
            } as TextureResolution;
          }
          return {
            width: 0,
            height: 0,
          } as TextureResolution;
        })
      );
  }

  private _getPhotoResolution$(
    photoPath: string
  ): Observable<TextureResolution> {
    return from(this._getPhotoResolution(photoPath));
  }

  private _getPhotoResolution(photoPath: string): Promise<TextureResolution> {
    return new Promise<TextureResolution>((resolve) => {
      const photo = new Image();

      photo.onload = (e: Event) => {
        const { width, height }: HTMLImageElement =
          e.target as HTMLImageElement;
        resolve({ width, height });
      };
      photo.src = photoPath;
    });
  }
}
