import * as THREE from 'three';
import { Vector3 } from 'three';
import { DeviceCanvasTextTexture } from '../helpers/canvas.render.helper';
import { TextConfiguration } from '../models/custom-component.type';

import { ThreeObjectBase } from './three-object.base';
export class TextComponent extends ThreeObjectBase {
  set rotation(position: THREE.Euler) {
    throw new Error('Method not implemented.');
  }
  private _mesh!: THREE.Mesh;
  constructor(
    private readonly three: typeof THREE,
    private readonly configuration: TextConfiguration['config']
  ) {
    super();

    this.init(three, configuration);
  }
  init(
    threeContext: typeof THREE,
    configuration: TextConfiguration['config']
  ): void {
    const canvasTextureIcon = new DeviceCanvasTextTexture(
      { text: configuration.text },
      this._textLength(configuration.text)
    );
    const texture = new THREE.CanvasTexture(canvasTextureIcon.ctx.canvas);
    const geometry = new threeContext.PlaneGeometry(1, 1);
    const material = new threeContext.MeshBasicMaterial({
      alphaTest: 0.2,
      polygonOffset: false,
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      side: THREE.DoubleSide,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    });
    this._mesh = new threeContext.Mesh(geometry, material);
    this._mesh.layers.set(3);
    this._mesh.visible =
      configuration.visible !== undefined ? configuration.visible : true;
    configuration.position && (this.position = configuration.position);
    configuration.scale && (this.scale = configuration.scale);
    this._text = configuration.text;
  }

  private set _text(text: string) {
    const canvasTexture = new DeviceCanvasTextTexture(
      { text },
      this._textLength(text)
    );
    const map = new this.three.CanvasTexture(canvasTexture.ctx.canvas);
    const material = this._mesh.material as THREE.MeshBasicMaterial;
    if (material.dispose) material.dispose();
    material.map = map;
    material.needsUpdate = true;
    material.stencilWrite = true;
    material.stencilZPass = THREE.ReplaceStencilOp;
    this.configuration.text = text;
  }

  set text(text: string) {
    if (text !== this.configuration.text) {
      this._text = text;
    }
  }

  private _textLength(text: string): number {
    let textLength = 0;
    if (text) {
      textLength = text.length < 5 ? 5 : text.length;
    }
    return textLength;
  }
  set position(position: Vector3) {
    this._mesh.position.set(position.x, position.y, position.z);
  }
  set scale(scale: Vector3) {
    const { x, y, z } = scale;
    this._mesh.scale.set(x, y, z);
  }

  hide() {
    if (this._mesh.visible) {
      this._mesh.visible = false;
    }
  }

  show() {
    if (!this._mesh.visible) {
      this._mesh.visible = true;
    }
  }

  get object3D(): any {
    return this._mesh;
  }
}
