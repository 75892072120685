import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModelSweep } from '@simlab/matterport/api';
import { TransformationInterface } from '@simOn/common/matterport';
import { BehaviorSubject, Observable, Subject, defer, firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApplicationEventsDispatcher {
  // private readonly apartmentFacade = inject(ApartmentFacade);
  private readonly _route = inject(ActivatedRoute);

  private readonly _apartmentId$: Observable<string | undefined> = this._route.params.pipe(
    map((params) => params['apartmentId'] as string | undefined)
  );
  private readonly _tagEditorMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  set tagEditorMode(value: boolean) {
    this._tagEditorMode.next(value);
  }

  get tagEditorMode(): boolean {
    return this._tagEditorMode.getValue();
  }

  private readonly _goToPosition: Subject<TransformationInterface> = new Subject<TransformationInterface>();
  // private readonly _openWidgetMarkerModal: Subject<Pick<any, 'id' | 'transformationId'>> = new Subject<
  //   Pick<any, 'id' | 'transformationId'>
  // >();
  /**
   * @description
   * shortcutId - widgetId + transformation order index
   */

  readonly deviceMoveToPosition$: Subject<string> = new Subject<string>();
  // readonly widgetPositionModal$: Observable<Pick<any, 'id' | 'transformationId'>> = defer(
  //   () => this._openWidgetMarkerModal
  // );
  readonly moveToDeviceTag$ = new Subject<string>();
  readonly goToPosition$: Observable<TransformationInterface> = defer(() => this._goToPosition);
  readonly tagEditorMode$: Observable<boolean> = this._tagEditorMode.asObservable();
  readonly goToSweep: Subject<string> = new Subject<string>();

  // readonly apartment$: Observable<ApartmentInterface> = this.apartmentFacade.selectedApartment$;
  readonly currentSweepChange: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  readonly loadingScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  // openWidgetMarkerModal(marker: Pick<any, 'id' | 'transformationId'>) {
  //   this._openWidgetMarkerModal.next(marker);
  // }

  goToPosition(position: TransformationInterface) {
    this._goToPosition.next(position);
  }

  async updateModelSweeps(payload: { matterportModelId: string; sweeps: ModelSweep[] }) {
    const apartmentId = await firstValueFrom(this._apartmentId$);
    throw Error('change this');
    // this.apartmentFacade.updateModelSweeps({ ...payload, apartmentId });
  }
}
