<section class="wrapper">
  <ng-container [ngTemplateOutlet]="cardNumberRef ?? cardNumber"></ng-container>
  <ng-container [ngTemplateOutlet]="cardDateRef ?? cardDate"></ng-container>
  <ng-container [ngTemplateOutlet]="cardCvvRef ?? cardCvv"></ng-container>
</section>
<ng-template #cardNumber>
  <div class="form-field-container">
    <aside>Card Number</aside>
    <div class="payu-card-form" id="payu-card-number"></div>
  </div>
</ng-template>
<ng-template #cardDate>
  <div class="form-field-container">
    <aside>Valid Thru</aside>
    <div class="payu-card-form" id="payu-card-date"></div>
  </div>
</ng-template>
<ng-template #cardCvv>
  <div class="form-field-container">
    <aside>CVV</aside>
    <div class="payu-card-form" id="payu-card-cvv"></div>
  </div>
</ng-template>
