import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, iif, map, of, switchMap } from 'rxjs';

import { Widget, WidgetTransformation } from '@simOn/device/models';
import { WidgetApiService } from '@simOn/device/widget/data-access';
import {
  addTransformation,
  addTransformationFailure,
  addTransformationSuccess,
  deleteTransformation,
  deleteTransformationFailure,
  deleteTransformationSuccess,
  loadWidgets,
  loadWidgetsFailure,
  loadWidgetsSuccess,
  updateTransformation,
  updateTransformationFailure,
  updateTransformationSuccess
} from './widget.actions';

@Injectable()
export class WidgetEffects {
  private actions$ = inject(Actions);
  private readonly widgetApi = inject(WidgetApiService);
  loadWidgets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadWidgets),
      switchMap(() =>
        this.widgetApi.getWidgets$().pipe(
          map((data: Widget[]) => loadWidgetsSuccess({ data })),
          catchError((error) => of(loadWidgetsFailure({ error })))
        )
      )
    )
  );
  updateTransformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTransformation),
      switchMap(({ data }) =>
        this.widgetApi
          .updateWidgetTransformations$({
            transformation: data.transformation,
            widgetTransformationId: data.widgetTransformationId
          })
          .pipe(
            map(() => updateTransformationSuccess({ data })),
            catchError((error) => of(updateTransformationFailure({ error })))
          )
      )
    )
  );

  addTransformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTransformation),
      exhaustMap(({ data }) =>
        this.widgetApi.addWidgetTransformation$(data).pipe(
          map((widgetTransformation: WidgetTransformation) =>
            addTransformationSuccess({
              data: {
                transformation: widgetTransformation,
                widgetId: data.widgetId
              }
            })
          ),
          catchError((error) => of(addTransformationFailure({ error })))
        )
      )
    )
  );

  deleteTransformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTransformation),
      exhaustMap(({ data }) =>
        iif(() => data.temporary!, of(true), this.widgetApi.deleteWidgetTransformation$(data.transformationId)).pipe(
          map(() =>
            deleteTransformationSuccess({
              data
            })
          ),
          catchError((error) => of(deleteTransformationFailure({ error })))
        )
      )
    )
  );
}
