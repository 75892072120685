import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TLastVisitedUser } from '@simOn/user/last-visited/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LastVisitedApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);
  getUsersInApartment$(): Observable<TLastVisitedUser[]> {
    return this.http.get<TLastVisitedUser[]>(`${this._apiUrl}/Apartments/GetApartmentUsers`);
  }
}
