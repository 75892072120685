import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TConfirmationResponse } from '@simOn/common/confirmation-modal/models';
import { ConfirmationModalComponent } from '@simOn/common/confirmation-modal/ui';
import { TransformationInterface } from '@simOn/common/matterport';
import { TicketCommentInterface } from '@simOn/ticket/comments/models';
import { TicketAttachments } from '@simOn/ticket/description/models';
import { TForm } from '@simOn/utils/form';

export const LOCAL_TICKET_ID = 'local-ticket'

export const DESCRIPTIONS = {
  confirmModalMessage: (name: string) =>
    $localize`:@@3D_TICKET_MODAL_DELETE_INFORMATION:Are you sure to delete ticket ${name}?`
};

export type ActionTicketType =
  | 'createTicket'
  | 'editMarker'
  | 'editNote'
  | 'deleteNote'
  | 'close'
  | 'goToNoteMarker'
  | 'ticketRemoved';

export type TicketFormType = FormGroup<TForm<TUpdateTicketForm>>;

export type TTicketState = {
  id: string | undefined;
  apartmentId: string;
  authorId: string;
  authorName: string;
  createdTimestamp: string;
  ticketName: string;
  ticketHtmlText: string;
  transformation: TransformationInterface | undefined;
  positionDeleted: boolean;
  hasUpdates: boolean;
  containsBlockedAttachments: boolean;
  dueDate: Date | string | undefined;
  status: TTicketStatuses | undefined;
  ticketComments: TicketCommentInterface[];
  attachments: TicketAttachments[];
  ticketAssignments: TTicketAssignment[];
};

export type TUpdateTicket = Pick<
  TTicketState,
  'ticketName' | 'ticketHtmlText' | 'transformation' | 'positionDeleted' | 'dueDate' | 'status'
> & {
  id: string;
  assigneeIds: string[];
  uploadedAttachments: string[];
};

export type TUpdateTicketForm = Pick<TUpdateTicket, 'assigneeIds'> &
  Pick<TTicketState, 'ticketName' | 'ticketHtmlText' | 'status' | 'dueDate'>;

export type TTicketAssignment = {
  id: string;
  assigneeId: string;
  assigneeName: string;
};

export const EMPTY_TICKET: TTicketState = {
  id: undefined,
  apartmentId: '',
  authorName: '',
  transformation: undefined,
  createdTimestamp: '',
  ticketName: '',
  ticketHtmlText: '',
  authorId: '',
  positionDeleted: false,
  ticketComments: [],
  attachments: [],
  status: undefined,
  ticketAssignments: [],
  dueDate: undefined,
  hasUpdates: false,
  containsBlockedAttachments: false
};

export interface TicketModalResultInterface {
  action: ActionTicketType;
  payload?: TUpdateTicket;
  created?: boolean;
}

export interface UsersForTicketInterface {
  id: string;
  email: string;
  userName: string;
}

export type DialogConfirmation = MatDialogRef<ConfirmationModalComponent, TConfirmationResponse<{ noteId: string }>>;

export type TTicketStatuses = 'Unknown' | 'Open' | 'InProgress' | 'Closed';

export type TTicketStatusesSelect = { label: string; value: Exclude<TTicketStatuses, 'Unknown'> }[];

export const TicketStatusesLabel: Record<TTicketStatuses, string> = {
  Unknown: $localize`:@@GENERAL_UNKNOWN:Unknown`,
  Open: $localize`:@@3D_TICKET_MODAL_STATUSES_OPEN:Open`,
  InProgress: $localize`:@@3D_TICKET_MODAL_STATUSES_IN_PROGRESS:In progress`,
  Closed: $localize`:@@3D_TICKET_MODAL_STATUSES_CLOSED:Closed`
};

export const TicketStatusesSelect: TTicketStatusesSelect = [
  {
    label: $localize`:@@3D_TICKET_MODAL_STATUSES_OPEN:Open`,
    value: 'Open'
  },
  {
    label: $localize`:@@3D_TICKET_MODAL_STATUSES_IN_PROGRESS:In progress`,
    value: 'InProgress'
  },
  {
    label: $localize`:@@3D_TICKET_MODAL_STATUSES_CLOSED:Closed`,
    value: 'Closed'
  }
];
