import {
  fromEvent,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

export function mobileTouch$(progress: HTMLElement, domElement: HTMLElement) {
  const el = domElement as HTMLCanvasElement;

  // const progress = this.componentRef.instance.progress.nativeElement;
  const mouseUp$ = fromEvent(el as HTMLCanvasElement, 'touchend').pipe(
    tap(() => {
      //   this._renderer.setStyle(progress, 'display', 'none');
      progress.style.display = 'none';
    })
  );
  const mouseMove$ = fromEvent(el as HTMLCanvasElement, 'touchmove').pipe(
    tap(() => {
      //   this._renderer.setStyle(progress, 'display', 'none');
      progress.style.display = 'none';
    })
  );
  const mouseDown$ = fromEvent<TouchEvent>(
    el as HTMLCanvasElement,
    'touchstart'
  ).pipe(
    switchMap((e: Event) =>
      timer(200).pipe(
        takeUntil(mouseUp$ || mouseMove$),
        mergeMap(() => of(e))
      )
    ),
    tap((e: Event) => {
      //   this._renderer.setStyle(progress, 'display', 'flex');
      progress.style.display = 'flex';

      //   this._renderer.setStyle(
      //     progress,
      //     'top',
      //     `${(e as TouchEvent).changedTouches[0].clientY - 120}px`
      //   );
      progress.style.top = `${
        (e as TouchEvent).changedTouches[0].clientY - 120
      }px`;

      //   this._renderer.setStyle(
      //     progress,
      //     'left',
      //     `${(e as TouchEvent).changedTouches[0].clientX - 50}px`
      //   );
      progress.style.left = `${
        (e as TouchEvent).changedTouches[0].clientX - 50
      }px`;
    })
  );
  return mouseDown$.pipe(
    switchMap((down: Event) =>
      timer(1000).pipe(
        takeUntil(mouseUp$ || mouseMove$),
        mergeMap(() => of(down))
      )
    ),
    tap(() => {
      // this._renderer.setStyle(progress, 'display', 'none');
      progress.style.display = 'none';
    })
    //   takeUntil(this._touchObserverDestroy)
  );
  // .subscribe(() => {
  //   this.matterportEvents.emitValue();
  // });
}
