import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DeviceProperties } from '@simOn/device/models';
import { CLEAR_STATE_REDUCER, State } from '@simOn/utils';
import * as DevicePropertyActions from './device-property.actions';

export const devicePropertyFeatureKey = 'device_property';

export const adapter: EntityAdapter<DeviceProperties> = createEntityAdapter<DeviceProperties>();
export const initialState: State<DeviceProperties> = adapter.getInitialState({
  preventRefresh: false,
  entities: {},
  isLoaded: false,
  isLoading: false
});

export const reducer = createReducer(
  initialState,
  on(DevicePropertyActions.LoadDeviceProperties, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(DevicePropertyActions.LoadDevicePropertiesSuccess, (state, action) => {
    return adapter.upsertMany(action.data, { ...state, isLoaded: action.allLoaded, isLoading: false });
  }),
  on(DevicePropertyActions.LoadDevicePropertiesFailure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.error
  })),
  on(DevicePropertyActions.ExecuteCommand, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(DevicePropertyActions.ExecuteCommandSuccess, (state, action) => {
    const devicePropertyUpdate: Update<DeviceProperties> = {
      id: action.data.command.deviceId,
      changes: {
        property: action.data.propertyDefinition
      }
    };
    return adapter.updateOne(devicePropertyUpdate, { ...state, isLoaded: true, isLoading: false });
  }),
  on(DevicePropertyActions.ExecuteCommandFailure, (state, action) => {
    return {
      ...state,
      isLoading: false
    };
  }),
  CLEAR_STATE_REDUCER(DevicePropertyActions.ClearDevicePropertiesState, initialState)
);
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
