import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TransformationInterface } from '@simOn/common/matterport';
import { Widget, WidgetTransformation } from '@simOn/device/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getWidgets$(): Observable<Widget[]> {
    return this.http.get<Widget[]>(`${this._apiUrl}/Devices/GetWidgets`);
  }
  getWidget$(id: string): Observable<Widget> {
    return this.http.get<Widget>(`${this._apiUrl}/Devices/GetWidget?id=${id}`);
  }

  updateWidgetTransformations$(payload: { widgetTransformationId: string; transformation: TransformationInterface }) {
    return this.http.put<void>(`${this._apiUrl}/Devices/UpdateWidgetTransformation`, payload);
  }

  addWidgetTransformation$(payload: {
    widgetId: Widget['id'];
    transformation: TransformationInterface;
  }): Observable<WidgetTransformation> {
    return this.http.post<WidgetTransformation>(`${this._apiUrl}/Devices/AddWidgetTransformation`, payload);
  }

  deleteWidgetTransformation$(id: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Devices/DeleteWidgetTransformation?id=${id}`);
  }
}
