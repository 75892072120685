import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DeviceDto, Widget } from '@simOn/device/models';
import { State } from '@simOn/utils';
import * as fromWidget from './widget.reducer';
export const selectWidgetState = createFeatureSelector<State<Widget>>('widget');
export const selectDeviceState = createFeatureSelector<State<DeviceDto>>('device');
export const selectAllWidgets = createSelector(selectWidgetState, fromWidget.selectAll);
export const widgetsLoaded = createSelector(selectWidgetState, (state) => state.isLoaded);
export const widgetsIsLoading = createSelector(selectWidgetState, (state) => state.isLoading);

export const selectAllIdInRoom = (roomId: string) =>
  createSelector(selectAllWidgets, (state) =>
    state.filter((widget) => widget.roomId === roomId).map((widget) => widget.id)
  );
export const selectWidgetByDeviceId = (deviceId: string) =>
  createSelector(selectAllWidgets, (state) => {
    return state.find((widget) => widget.deviceId === deviceId);
  });

export const selectWidgetById = (widgetId: string) =>
  createSelector(selectWidgetState, (state) => state.entities[widgetId]!);
export const selectWidgetsByIds = (...widgetsId: string[]) =>
  createSelector(selectWidgetState, (state) => widgetsId.map((widgetId: string) => state.entities[widgetId]));
export const selectWidgetsBySimlabDeviceId = (deviceId: string) =>
  createSelector(selectAllWidgets, (state) => {
    return state.filter((widget) => widget.simlabDeviceId === deviceId);
  });
