import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

export const DeviceTypeEnum = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  unknown: 'unknown'
} as const;
export type DeviceType = keyof typeof DeviceTypeEnum;
@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  private readonly _breakpoint = inject(BreakpointObserver);
  readonly breakpointChange$: Observable<BreakpointState> = this._breakpoint.observe(Object.values(Breakpoints));

  readonly deviceType$: Observable<DeviceType> = this._breakpoint
    .observe([
      Breakpoints.Handset,
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait,
      Breakpoints.Tablet,
      Breakpoints.TabletLandscape,
      Breakpoints.TabletPortrait,
      Breakpoints.WebPortrait,
      Breakpoints.Web,
      Breakpoints.XSmall
    ])
    .pipe(
      map((breakpoints: BreakpointState) => {
        if (breakpoints.matches) {
          for (let i = 0; i < Object.entries(breakpoints.breakpoints).length; i++) {
            const entries = Object.entries(breakpoints.breakpoints);
            if (entries[i][1]) {
              if (
                [
                  Breakpoints.Handset,
                  Breakpoints.HandsetPortrait,
                  Breakpoints.HandsetLandscape,
                  Breakpoints.XSmall
                ].includes(entries[i][0])
              ) {
                return DeviceTypeEnum.mobile;
              } else if (
                [
                  Breakpoints.Tablet,
                  Breakpoints.TabletLandscape,
                  Breakpoints.TabletPortrait,
                  Breakpoints.WebPortrait
                ].includes(entries[i][0])
              ) {
                return DeviceTypeEnum.tablet;
              } else {
                return DeviceTypeEnum.desktop;
              }
            }
          }
          return DeviceTypeEnum.unknown;
        } else {
          return DeviceTypeEnum.desktop;
        }
      })
    );

  readonly isMobile$: Observable<boolean> = this.deviceType$.pipe(
    map((deviceType: DeviceType) => deviceType === 'mobile')
  );

  readonly isMobileOrTablet$: Observable<boolean> = this.deviceType$.pipe(
    map((deviceType: DeviceType) => deviceType === 'mobile' || deviceType === 'tablet')
  );
}
