import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BlueprintService } from '../../services/blueprint.service';
import { MatterportCustomComponentService } from '../../services/custom-component.service';
import { DollhouseManagerService } from '../../services/dollhouse-manager.service';
import { MatterportEventsService } from '../../services/matterport-events.service';
import { MatterportPortalManagerService } from '../../services/matterport-portal-manager.service';
import { MatterportPositionControllerService } from '../../services/matterport-position-controller.service';
import { MatterportSceneInitService } from '../../services/matterport-scene-init.service';
import { MatterportSceneStateAccessService } from '../../services/matterport-scene-state-access.service';
import { MatterportTagsService } from '../../services/matterport-tags.service';
import { MeasurementToolService } from '../../services/measurement-tool.service';
import { ObjectLoaderService } from '../../services/object-loader.service';

@Component({
  selector: 'matterport-iframe',
  templateUrl: './matterport-iframe.component.html',
  styleUrls: ['./matterport-iframe.component.scss'],
  providers: [
    MatterportTagsService,
    MatterportSceneStateAccessService,
    MatterportEventsService,
    MatterportPositionControllerService,
    MatterportSceneInitService,
    MatterportCustomComponentService,
    ObjectLoaderService,
    BlueprintService,
    MatterportPortalManagerService,
    DollhouseManagerService,
    MeasurementToolService,
  ],
})
export class MatterportIframeComponent implements OnDestroy, AfterViewInit {
  private _url!: string;
  get url(): string {
    return this._url;
  }
  @Input() set url(value: string) {
    this._url = value;
    this.changeDetectorRef.detectChanges();
  }
  @ViewChild('iframeRef', { static: true })
  iframeRef!: ElementRef;

  @ViewChild('hint', { static: true })
  hintRef!: ElementRef;

  @ViewChild('progress', { static: true })
  progress!: ElementRef;
  hint!: Observable<
    | {
        hintMessage: string | undefined | Element;
        position:
          | {
              top?: string | undefined;
              bottom?: string | undefined;
              left?: string | undefined;
              right?: string | undefined;
            }
          | undefined;
        backgroundColor?: string;
        fontColor?: string;
      }
    | undefined
  >;
  private readonly _destroy: Subject<void> = new Subject<void>();
  readonly loaded: Subject<boolean> = new Subject<boolean>();
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    readonly matterportTags: MatterportTagsService,
    readonly matterportEvents: MatterportEventsService,
    readonly matterportSceneStateAccess: MatterportSceneStateAccessService,
    readonly matterportPositionController: MatterportPositionControllerService,
    readonly matterportCustomComponentService: MatterportCustomComponentService,
    readonly objectLoaderService: ObjectLoaderService,
    readonly matterportPortalManagerService: MatterportPortalManagerService,
    readonly blueprintService: BlueprintService,
    readonly measurementToolService: MeasurementToolService,
    readonly dollHouseService: DollhouseManagerService,
    private readonly sceneInit: MatterportSceneInitService,

    private readonly renderer: Renderer2,
    readonly elementRef: ElementRef
  ) {
    changeDetectorRef.detach();
  }

  ngAfterViewInit(): void {
    this.hintObserver();
  }

  ngOnDestroy(): void {
    this._url = '';
    this.changeDetectorRef.detectChanges();
    this._destroy.next();
    this._destroy.complete();
  }

  hintObserver() {
    this._destroy.next();
    this.hint.pipe(takeUntil(this._destroy)).subscribe((hint) => {
      if (hint) this.setHint(hint);
    });
  }

  pointerEvents(itemHovered: boolean) {
    const matterport = (
      this.iframeRef.nativeElement as HTMLIFrameElement
    ).contentWindow?.document.querySelector('.showcase');
    if (matterport)
      this.renderer.setStyle(
        matterport,
        'cursor',
        itemHovered ? 'pointer' : 'auto'
      );
  }
  onError($event: any) {
    console.log($event);
  }
  setHint(hint: {
    hintMessage: string | undefined | Element;
    position:
      | {
          top?: string | undefined;
          bottom?: string | undefined;
          left?: string | undefined;
          right?: string | undefined;
        }
      | undefined;
    backgroundColor?: string;
    fontColor?: string;
  }) {
    const el = this.hintRef.nativeElement as HTMLElement;
    const { position, hintMessage, backgroundColor, fontColor } = hint;
    if (position) {
      if (position.top) {
        this.renderer.setStyle(el, 'top', position.top);
      }
      if (position.bottom) {
        this.renderer.setStyle(el, 'bottom', position.bottom);
      }
      if (position.left) {
        this.renderer.setStyle(el, 'left', position.left);
      }
      if (position.right) {
        this.renderer.setStyle(el, 'right', position.right);
      }
    }
    if (backgroundColor) {
      this.renderer.setStyle(el, 'background-color', backgroundColor);
    }
    if (fontColor) {
      this.renderer.setStyle(el, 'color', fontColor);
    }
    if (hintMessage) {
      if (!(hintMessage instanceof Element)) {
        (el as HTMLElement).innerHTML = hintMessage;
      } else {
        el.childNodes.forEach((node) => el.removeChild(node));
        el.appendChild(hintMessage);
      }
      this.renderer.setStyle(el, 'display', 'flex');
    } else {
      this.renderer.setStyle(el, 'display', 'none');
    }
  }
}
