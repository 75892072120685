import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SimlabPropertyInterface } from '@simOn/device/models';
import { SimBatteryIndicatorComponent } from '@simOn/ui/battery-indicator';
import { SimIconComponent } from '@simOn/ui/sim-icon';
import { takeUntil } from 'rxjs';
import { WidgetImageComponent } from '../widget-image/widget-image.component';
import { WidgetBaseDirective } from './widget-base.directive';
let uniqueIdCounter = 0;
@Component({
  selector: 'device-widget',
  standalone: true,
  imports: [CommonModule, SimIconComponent, WidgetImageComponent, SimBatteryIndicatorComponent],
  templateUrl: './sim-widget.component.html',
  styleUrls: ['./sim-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.alarm]': 'alarm',
    '[id]': 'widgetDetailsId'
    // class: ' sim-widget'
  }
})
export class SimWidgetComponent<T extends SimlabPropertyInterface<any> = never>
  extends WidgetBaseDirective<T>
  implements OnInit
{
  @Input() widgetDetailsId: string = `sim-widget-${uniqueIdCounter++}`;
  alarm: boolean = false;
  override ngOnInit(): void {
    super.ngOnInit();
    this.alarm$.pipe(takeUntil(this._destroy)).subscribe((alarm) => (this.alarm = alarm));
  }
}
