import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
  selector: 'sim-checkbox',
  templateUrl: './sim-checkbox.component.html',
  styleUrls: ['./sim-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SimCheckboxComponent
    }
  ]
})
export class SimCheckboxComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  private readonly _elementRef = inject(ElementRef);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  @HostBinding('class.ui-checkbox') hostClass = true;

  @HostBinding('class.checked') get checkedClass(): boolean {
    return this._checked;
  }
  @HostBinding('class.disabled') get disabledClass(): boolean {
    return this._disabled;
  }
  @HostBinding('class.label-before') get labelPositionBeforeClass(): boolean {
    return this.labelPosition === 'before';
  }
  @HostBinding('class.danger') get dangerClass(): boolean {
    return this._danger;
  }
  private _uniqueId = `ui-checkbox-${++nextUniqueId}`;

  @Input() id: string = this._uniqueId;

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  /** Whether the checkbox is required. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  private _required = false;

  @Output() readonly valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Whether the label should appear after or before the checkbox. Defaults to 'after' */
  @Input() labelPosition: 'before' | 'after' = 'after';

  /** Whether this checkbox should be displayed with danger color */
  @Input()
  get danger(): boolean {
    return this._danger;
  }
  set danger(value: boolean) {
    this._danger = coerceBooleanProperty(value);
  }
  private _danger = false;

  /** The native `<input type="checkbox">` element */
  @ViewChild('input') private _inputElement!: ElementRef<HTMLInputElement>;

  /**
   * Whether the checkbox is checked.
   */
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (value != this.checked) {
      this._checked = value;
      this._changeDetectorRef.markForCheck();
    }
    if (this._inputElement?.nativeElement) {
      this._inputElement.nativeElement.checked = this.checked;
    }
  }
  private _checked = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this.disabled) {
      this._disabled = newValue;
      this._changeDetectorRef.markForCheck();
    }
  }
  private _disabled = false;

  @Input()
  get touched(): boolean {
    return this._touched;
  }
  set touched(value: boolean) {
    this._touched = value;
  }
  private _touched = false;

  ngAfterViewInit(): void {
    this._inputElement.nativeElement.checked = this.checked;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouched: () => any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onChange: (value: any) => void = () => {};

  writeValue(value: any) {
    this.checked = !!value;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  emitChangeEvent(event: Event) {
    event.stopPropagation();
    this._toggle();
    this._onChange(this.checked);
    this._markAsTouched();
    this.valueChange.emit((event.target as HTMLInputElement).checked);
  }

  private _markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this.touched = true;
    }
  }

  private _toggle(): void {
    this.checked = !this.checked;
  }

  ngOnInit(): void {}
}
