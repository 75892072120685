import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@simOn/common/notification';
import { WidgetExtended } from '@simOn/device/models';
import { PropertyComponentLoaderDirective } from '@simOn/device/properties/ui';
import { SimWidgetComponent } from '@simOn/device/widget/ui';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimCheckboxModule } from '@simOn/ui/sim-checkbox';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SimModalModule } from '@simOn/ui/sim-modal';
import { UserPreferencesFacade } from '@simOn/user';
import { SimDeviceWarningLocalStorage, SimDeviceWarningModalResult } from './sim-device-warning-modal.model';
import { WarningRoomDescription } from './sim-device-warning-modal.service';

const POSITION_NOT_ASSIGNED = (name: string) =>
  $localize`:@@WARNING_WIDGET_POSITION_NOT_ASSIGNED:Position "${name}" is not assigned.`;

@Component({
  templateUrl: './sim-device-warning-modal.component.html',
  styleUrls: ['./sim-device-warning-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SimModalModule,
    SimIconModule,
    SimCheckboxModule,
    ReactiveFormsModule,
    SimWidgetComponent,
    PropertyComponentLoaderDirective,
    SimButtonModule
  ]
})
export class SimDeviceWarningModalComponent implements OnInit {
  private readonly dialogRef = inject<MatDialogRef<SimDeviceWarningModalComponent, SimDeviceWarningModalResult>>(
    MatDialogRef<SimDeviceWarningModalComponent, SimDeviceWarningModalResult>
  );
  private readonly notificationService = inject(NotificationService);
  public readonly data = inject<{
    device: WidgetExtended;
    roomDescription: WarningRoomDescription;
  }>(MAT_DIALOG_DATA);
  readonly userPreferences = inject(UserPreferencesFacade).preferences$;

  readonly alertDelayControl: UntypedFormControl = new UntypedFormControl(false);
  readonly today: Date = new Date();
  localStorageWarningInfo: SimDeviceWarningLocalStorage | undefined;

  get roomName() {
    return this.data.roomDescription.name;
  }

  get roomColor() {
    return this.data.roomDescription.color;
  }

  ngOnInit(): void {
    this.localStorageWarningInfo = this._getFromLocalStorage(this.data.device);
  }

  closeAction(): void {
    this.dialogRef.close({ action: 'close', isAlertDelay: this.alertDelayControl.value });
  }

  buttonClick(): void {
    this.dialogRef.close({ action: 'buttonClick', isAlertDelay: this.alertDelayControl.value });
  }
  masterRoomInformation(): void {
    this.notificationService.openWarnSnackBar(POSITION_NOT_ASSIGNED(this.data.device.name || ''));
  }

  private _getFromLocalStorage(device: WidgetExtended): SimDeviceWarningLocalStorage | undefined {
    const localStorageDeviceAlarm = localStorage.getItem(device.id);

    if (localStorageDeviceAlarm) {
      const deviceAlarm = JSON.parse(localStorageDeviceAlarm);
      return deviceAlarm;
    }
    return undefined;
  }
}
