import { NavBarDataInterface, NavLinkInterface } from '@simOn/common/containers';
import { PrivilegesFacade } from '@simOn/privileges';
import { GeneralPrivilegeTypeStringify } from '@simOn/privileges/element/models';
import { combineLatest, map } from 'rxjs';

export const PROVIDERS_SETTINGS_PAGE_DATA: NavBarDataInterface = {
  headerTitle: $localize`:@@PROVIDERS_CONNECTIONS_MAIN_TITLE:Providers settings`,
  shortTitle: $localize`:@@PROVIDERS_CONNECTIONS_SHORT_TITLE:Providers`,
  navLinks: [
    {
      label: $localize`:@@PROVIDERS_CONNECTIONS:Connections`,
      name: `Connections`,
      path: 'connections',
      icon: 'connections'
    },
    {
      label: $localize`:@@PROVIDERS_IMPORT_DEVICES:Import device`,
      name: `Import device`,
      path: 'import-new-device',
      icon: 'import_device'
    },
    {
      label: $localize`:@@PROVIDERS_DEVICE_LIST:Device list`,
      name: `Device list`,
      path: 'devices-list',
      icon: 'device_list'
    }
  ],
  activeLinkIndex: 0
};

export function PROVIDERS_SETTINGS_PAGE_DATA_WITH_PRIVILEGES(privilegesFacade: PrivilegesFacade) {
  const navBar = { ...PROVIDERS_SETTINGS_PAGE_DATA };
  return combineLatest([privilegesFacade.generalPrivileges$, privilegesFacade.isOwner$]).pipe(
    map(([generalPrivileges, isOwner]: [GeneralPrivilegeTypeStringify[], boolean]) => {
      navBar.navLinks?.forEach((navLink: NavLinkInterface) => {
        if (navLink.name === 'Connections' || navLink.name === 'Device list') {
          navLink.hasPrivilege = generalPrivileges.includes('CanViewProviders') || isOwner;
        }

        if (navLink.name === 'Import device') {
          navLink.hasPrivilege = generalPrivileges.includes('CanImportDevices') || isOwner;
        }
      });
      return navBar;
    })
  );
}
