import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SimFormFieldComponent } from './form-field/sim-form-field.component';
import { SimHintComponent } from './sim-hint/sim-hint.component';

@NgModule({
  declarations: [SimFormFieldComponent, SimHintComponent],
  exports: [SimFormFieldComponent, SimHintComponent],
  imports: [CommonModule, ReactiveFormsModule]
})
export class SimFormModule {}
