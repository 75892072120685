import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class FibaroApiInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // grant type - 'authorization_code'
    if (req.url.includes('/fibaroUsers/token') || req.url.includes('/fibaro/token')) {
      req = req.clone({
        setHeaders: {
          grant_type: 'authorization_code'
        }
      });
    }
    return next.handle(req);
  }
}
