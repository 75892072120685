import { DOCUMENT } from '@angular/common';
import {  Inject, Injectable } from '@angular/core';
import { ScriptLoadResponse } from '../models/script-load-response';

@Injectable()
export class LoadPayUSdkService {
  constructor(@Inject(DOCUMENT) private readonly document: Document){}
  loadPayUSdk(url: string): Promise<ScriptLoadResponse> {
    return new Promise((resolve, reject) => {
      const scriptElement = this.document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = url;

      scriptElement.onload = () => {
        resolve({ loaded: true, message: 'Loaded', error: false });
      };

      scriptElement.onerror = () => {
        reject({ loaded: false, message: `SDK doesn't load`, error: true });
      };
      this.document.body.appendChild(scriptElement);
    });
  }
}
