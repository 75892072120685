import { Injectable, InjectionToken, Injector, Optional, SkipSelf } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { RecurringPaymentResponse } from '../models/recurring-payment-response';
import { ScriptLoadResponse } from '../models/script-load-response';
import { SecureFormValueChange } from '../models/secure-form-change-response';
import { TokenizeResultSuccess, TokenizeResultError } from '../models/tokenize-result';
import { BasePaymentsService } from './base-payments.service';
export const TOKEN = new InjectionToken<BasePaymentsService>('BasePaymentsService')
@Injectable()
export class Payments {

  formChanges$: Observable<SecureFormValueChange> = defer(()=>this.baseService.formChanges.asObservable());
  tokenize$:Observable<TokenizeResultSuccess | TokenizeResultError> = defer(()=>this.baseService.tokenize.asObservable());
  loadingStatus$:Observable<ScriptLoadResponse> = defer(()=>this.baseService.loadingStatus.asObservable());
  loading$:Observable<boolean> = defer(()=>this.baseService.loading.asObservable());
  constructor(private readonly baseService: BasePaymentsService) { }

  submitFormWithoutSave():Promise<TokenizeResultSuccess | TokenizeResultError>{
    return this.baseService.submitFormWithoutSave();
  }
  submitForm$(payload:Record<string, string | number>): Observable<RecurringPaymentResponse | TokenizeResultError>{
    return this.baseService.submitForm$(payload);
  }
  submitFormWithoutSave$(): Observable<TokenizeResultSuccess | TokenizeResultError>{
    return this.baseService.submitFormWithoutSave$();
  }

}
