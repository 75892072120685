import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType } from '@simOn/device/models';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { PropertyBase } from '../directives/property-base.directive';
import { wakeUpAnimation } from './alarm.animation';

@Component({
  standalone: true,
  imports: [CommonModule, SimIconModule],
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [wakeUpAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-alarm'
  }
})
export class AlarmComponent extends PropertyBase<'ProviderError', string, never> {
  private _animation = false;
  public get animation() {
    return this._animation;
  }
  public set animation(value: boolean) {
    this._animation = value;
  }

  onAnimationDoneEvent(): void {
    this.animation = false;
  }

  valueToCommand(params: string | undefined): {
    command: keyof CommandValuesType;
    parameters: string | number | undefined;
  } {
    return {
      command: 'TryWakeUpDevice',
      parameters: undefined
    };
  }
  commandToValue(params: { command: keyof CommandValuesType; parameters: string | number }): string {
    throw new Error('Method not implemented.');
  }
  valueToParameter(params: string): never {
    throw new Error('Method not implemented.');
  }
  set propertyValue(params: string) {
    throw new Error('Method not implemented.');
  }
  get propertyValue(): string {
    return this.propertyDefinition.values.value.toString() || '';
  }

  get wakeUpSupported(): boolean {
    return this.propertyDefinition.settings?.value?.wakeUpSupported || false;
  }

  tryToWakeUp(): void {
    this.animation = true;
    this.partialCommand$.emit(this.valueToCommand(undefined));
  }
}
