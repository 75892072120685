import { CurrencyUnitEnum, EnergyUnitEnum, PowerUnitEnum, TemperatureUnitEnum } from '@simOn/common/units';

export const DEFAULT_USER_PREFERENCES = {
  showUnauthorizedUserModal: false,
  autoReportErrors: false,
  showPowerAsPrice: false,
  priceForOneEnergyUnit: 0,
  priceCurrency: '$',
  openAttachmentInNewTab: false,
  units: {
    temperature: TemperatureUnitEnum.Celsius,
    energy: EnergyUnitEnum.kWh,
    power: PowerUnitEnum.Watt,
    currency: CurrencyUnitEnum.Currency
  }
} as const;
