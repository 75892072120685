import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from '../../../../../../environments';
@Injectable()
export class DraftAccessGuard implements CanActivate {
  // can activate if app is not in production mode
  canActivate(): boolean {
    return !environment.production;
  }
}
