import { Directive, OnDestroy, ViewContainerRef } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  GetModelTagsQuery,
  MatterportApiFacadeService,
  MatterportAuthorization,
  MatterportAuthorizationResponse,
  Mattertag,
  ModelAccess,
  ModelsQuery,
  ModelSweep,
  ModelView,
  ModelVisibility,
} from '@simlab/matterport/api';

import {
  combineLatest,
  filter,
  firstValueFrom,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { Quaternion, Vector3 } from 'three';
import { Camera, Mode, Sweep } from '../../../assets/bundle/sdk';
import {
  ClickEventEmitter,
  ComponentConfiguration,
  ComponentsType,
  ComponentsTypeClass,
  MatterportComponent,
  PlaneConfiguration,
} from '../models/custom-component.type';
import {
  CustomSpriteComponent,
  MoveToClosestSweepConfig,
  MoveToSweepConfig,
  StartPlacingConfig,
  TagNote,
} from '../models/dto';
import { CustomPhases, MatterportScanStatus } from '../models/matterport-data';
import { SupportedLinks } from '../models/supported-language';

import { IAreaTool } from '../models/measurement-tool.interface';
import { PortalConfiguration } from '../models/portal';
import { MatterportManagerService } from '../services/matterport-manager.service';
import { MatterportService } from '../services/matterport.service';
import { UnityAreaTool } from './unity-area-tool';

type Promisified<T extends (...args: any[]) => any> = (
  ...args: Parameters<T>
) => void;
export type IUnityInputs<T> = {
  [prop in keyof T]: T[prop] extends (...args: any[]) => any
    ? Promisified<T[prop]>
    : T[prop];
};
type IsObservable<T> = T extends Observable<infer R> ? R : any;

type ReturnType<T> = T extends (...args: any[]) => infer R
  ? R
  : IsObservable<T>;
export type IUnityResponse<T> = {
  [prop in keyof T as prop extends string ? `on${Capitalize<prop>}` : prop]: (
    args: FunctionResponse<ReturnType<T[prop]>>
  ) => void;
};

export type Status = 'Success' | 'Failure';
export interface FunctionResponse<T = any> {
  status: Status;
  data?: T;
  error?: any;
}

declare global {
  interface Window {
    openScanWithOffset: (
      scanId: string,
      uniqueHash: string,
      offset: string | null,
      showMattertags: boolean,
      language: SupportedLinks,
      enablePortals: boolean
    ) => void;

    openScanWithOffsetForTestInLib: (
      scanId: string,
      uniqueHash: string,
      offset: string | null,
      showMattertags: boolean,
      language: SupportedLinks,
      enablePortals: boolean,
      element: number
    ) => void;

    addBlueprint: (payload: {
      id: string;
      offset: {
        position: { x?: number; y?: number; z?: number };
        rotation: { x?: number; y?: number; z?: number; w?: number };
        normal: { x?: number; y?: number; z?: number };
        scale: { x?: number; y?: number; z?: number };
      };
      blueprint: string;
    }) => void;
    onAddBlueprint: (payload: FunctionResponse) => void;
    deleteBlueprint: (id: string) => void;
    onDeleteBlueprint: (payload: FunctionResponse) => void;
    addNote: (tagNote: TagNote) => void;
    createPortal: (payload: PortalConfiguration[]) => void;
    onCreatePortal: (payload: FunctionResponse) => void;
    addNotes: (tagNotes: TagNote[]) => void;
    removeNote: (id: string) => void;
    clearAllTagNotes: () => void;
    abandonPlacing: () => void;
    acceptPlacingPosition: () => void;
    startPlacing: (startPlacingConfig: StartPlacingConfig) => void;
    moveToSweep: (config: MoveToSweepConfig) => void;
    moveToClosestSweep: (config: MoveToClosestSweepConfig) => void;
    getAvailableSweeps: () => void;
    setSweepActive: (enabled: boolean) => void;
    goToPositionAndLookAt: (position: Vector3) => void;
    disposeAll: () => void;
    noteClicked: () => void;
    onOpenScanWithOffset: (payload: FunctionResponse) => void;
    onGoToPositionAndLookAt: (payload: FunctionResponse) => void;
    onAddNote: (payload: FunctionResponse) => void;
    onAddNotes: (payload: FunctionResponse) => void;
    onRemoveNote: (payload: FunctionResponse) => void;
    onClearAllTagNotes: (payload: FunctionResponse) => void;
    onAbandonPlacing: (payload: FunctionResponse) => void;
    onAcceptPlacingPosition: (payload: FunctionResponse) => void;
    onStartPlacing: (payload: FunctionResponse) => void;
    onMoveToSweep: (payload: FunctionResponse) => void;
    onMoveToClosestSweep: (payload: FunctionResponse) => void;
    onGetAvailableSweeps: (payload: FunctionResponse) => void;
    onSetSweepActive: (payload: FunctionResponse) => void;

    getModels: (offset?: string, query?: string) => void;
    onGetModels: (payload: FunctionResponse) => void;
    getTags: (modelId: string) => void;
    moveToMattertag: (tagId: string) => void;
    onMoveToMattertag: (payload: FunctionResponse) => void;
    onGetTags: (payload: FunctionResponse) => void;
    positionChange: () => void;
    onPositionChange: (position: Camera.Pose | null) => void;
    onNoteClicked: (note: string) => void;
    matterportAuthorization: () => void;
    onMatterportAuthorization: (payload: FunctionResponse) => void;
    setMatterportToken: (token: string) => void;
    onSetMatterportToken: (payload: FunctionResponse) => void;
    onSetSelectedNote: (payload: FunctionResponse) => void;
    setSelectedNote: (noteId: string) => void;
    selectedNote: (noteId: string) => void;
    getModelAccess: (scanId: string) => void;
    onGetModelAccess: (payload: FunctionResponse<ModelAccess>) => void;
    getModelSweeps: (scanId: string) => void;
    onGetModelSweeps: (payload: FunctionResponse<Array<ModelSweep>>) => void;
    deleteToken: () => void;
    onDeleteToken: (payload: FunctionResponse) => void;
    startPlacingComponent: (startPlacingConfig: StartPlacingConfig) => void;
    componentClicked: () => void;
    portalClicked: () => void;
    addComponent: (tagNote: TagNote) => void;
    addComponents: (tagNote: TagNote[]) => void;
    clearAllComponents: () => void;
    removeComponent: (id: string) => void;
    updateComponent: (
      component: Pick<
        ComponentConfiguration<ComponentsType>,
        'id' | 'position' | 'normal' | 'stemHeight'
      >
    ) => void;
    acceptPlacingPositionAndReplaceWithComponent: () => void;
    onComponentClicked: (note: string) => void;
    onPortalClicked: (note: string) => void;
    onStartPlacingComponent: (payload: FunctionResponse) => void;
    onAddComponent: (payload: FunctionResponse) => void;
    onAddComponents: (payload: FunctionResponse) => void;
    onClearAllComponents: (payload: FunctionResponse) => void;
    onRemoveComponent: (payload: FunctionResponse) => void;
    onUpdateComponent: (payload: FunctionResponse) => void;
    onAcceptPlacingPositionAndReplaceWithComponent: (
      payload: FunctionResponse
    ) => void;
    destroy: () => void;
    loadMattertags: (mattertags: Mattertag[]) => void;
    onLoadMattertags: (payload: FunctionResponse<string[]>) => void;
    getViews: (scanId: string) => void;
    onGetViews: (payload: FunctionResponse<ModelView[]>) => void;
    onCheckScanVisibility: (payload: FunctionResponse<ModelVisibility>) => void;
    checkScanVisibility: (id: string) => void;
    areaMeasurement: IUnityInputs<IAreaTool> & IUnityResponse<IAreaTool>;
    moveToPose(pose: Camera.Pose): void;
    onMoveToPose: (payload: FunctionResponse<Mode.Mode>) => void;

    changeFloor(mode: number): void;
    onFloorChanged: (payload: FunctionResponse<number | void>) => void;

    changeMode(mode: Mode.Mode): void;
    onModeChanged: (payload: FunctionResponse<Mode.Mode>) => void;
  }
}

@Directive()
export abstract class MatterportAccessBase implements OnDestroy {
  abstract container: ViewContainerRef;
  private readonly _destroy: Subject<void> = new Subject<void>();
  private readonly _destroyClick: Subject<void> = new Subject<void>();
  private _areaTool: UnityAreaTool | undefined;
  public get areaTool(): UnityAreaTool {
    return this._areaTool ?? ({} as UnityAreaTool);
  }
  public set areaTool(value: UnityAreaTool) {
    this._areaTool = value;
    window.areaMeasurement = this.areaTool;
    console.log(window.areaMeasurement);
  }
  constructor(
    private readonly _matterport: MatterportService,
    private readonly _matterportManager: MatterportManagerService,
    private readonly _matterportApi: MatterportApiFacadeService
  ) {
    this._registerFunctions();
  }
  ngOnDestroy(): void {
    this._areaTool?.destroyer.next();
    this._destroy.next();
    this._destroyClick.next();
  }

  private _registerFunctions() {
    window.openScanWithOffset = (
      scanId: string,
      uniqueHash: string,
      offset: string | null,
      showMattertags: boolean,
      language: SupportedLinks,
      enablePortals: boolean
    ) =>
      this._openScanWithOffset(
        scanId,
        uniqueHash,
        offset,
        showMattertags,
        language,
        enablePortals
      );
    window.addNote = (tagNote: TagNote) => this._addNote(tagNote);
    window.addNotes = (tagNotes: TagNote[]) => this._addNotes(tagNotes);
    window.removeNote = (id: string) => this._removeNote(id);
    window.clearAllTagNotes = () => this._clearAllTagNotes();
    window.abandonPlacing = () => this._abandonPlacing();
    // window.acceptPlacingPosition = () => this._acceptPlacingPosition();
    // window.startPlacing = (startPlacingConfig: StartPlacingConfig) =>
    //   this._startPlacing(startPlacingConfig, 'TAG');
    window.moveToSweep = (config: MoveToSweepConfig) =>
      this._moveToSweep(config);
    window.moveToClosestSweep = (config: MoveToClosestSweepConfig) =>
      this._moveToClosestSweep(config);
    window.getAvailableSweeps = () => this._getAvailableSweeps();
    window.setSweepActive = (enable: boolean) => this._setSweepActive(enable);
    window.goToPositionAndLookAt = (position: Vector3) =>
      this._goToPositionAndLookAt(position);
    window.getModels = (offset?: string, query?: string) =>
      this._getModels(offset, query);
    window.getTags = (modelId: string) => this._getTags(modelId);
    window.positionChange = () => this._positionChange();
    window.noteClicked = () => this._noteClicked();
    window.moveToMattertag = (tagId: string) => this._moveToMattertag(tagId);
    window.matterportAuthorization = () => this._matterportAuthorization();
    window.setMatterportToken = (token: string) =>
      this._setMatterportToken(token);
    window.setSelectedNote = (noteId: string) => this._setSelectedNote(noteId);
    window.destroy = () => this._destroyMatterport();

    window.getModelAccess = (scanId: string) => this._getModelAccess(scanId);
    window.getModelSweeps = (scanId: string) => this._getModelSweeps(scanId);
    window.deleteToken = () => this._deleteToken();
    window.componentClicked = () => this._componentClicked();
    window.portalClicked = () => this._portalClicked();

    window.createPortal = (payload: PortalConfiguration[]) =>
      this._createPortal(payload);
    window.addComponent = (tagNote: TagNote) => this._addComponent(tagNote);
    window.addComponents = (tagNotes: TagNote[]) =>
      this._addComponents(tagNotes);
    window.clearAllComponents = () => this._clearAllComponents();
    window.removeComponent = (id: string) => this._removeComponent(id);
    window.addBlueprint = (payload: {
      id: string;
      offset: {
        position: { x?: number; y?: number; z?: number };
        rotation: { x?: number; y?: number; z?: number; w?: number };
        normal: { x?: number; y?: number; z?: number };
        scale: { x?: number; y?: number; z?: number };
      };
      blueprint: string;
    }) => this._addBlueprint(payload);
    window.deleteBlueprint = (id: string) => this._deleteBlueprint(id);

    window.updateComponent = (
      component: Pick<
        ComponentConfiguration<ComponentsType>,
        'id' | 'position' | 'normal' | 'stemHeight'
      >
    ) => this._updateComponent(component);

    window.loadMattertags = (mattertags: Mattertag[]) =>
      this._loadMattertags(mattertags);
    window.startPlacingComponent = (startPlacingConfig: StartPlacingConfig) =>
      this._startPlacingComponent(startPlacingConfig);

    window.getViews = (scanId: string) => this._getViews(scanId);
    window.checkScanVisibility = (scanId: string) =>
      this._checkScanVisibility(scanId);
    window.areaMeasurement = this.areaTool;
    window.changeMode = (mode: Mode.Mode) => this._changeMode(mode);
    window.changeFloor = (floor: number) => this._changeFloor(floor);
    window.moveToPose = (pose: Camera.Pose) => this._moveToPose(pose);
  }
  private _deleteBlueprint(id: string): void {
    this._matterport.deleteBlueprint(id);
  }
  private _addBlueprint(payload: {
    id: string;
    offset: {
      position: { x?: number; y?: number; z?: number };
      rotation: { x?: number; y?: number; z?: number; w?: number };
      normal: { x?: number; y?: number; z?: number };
      scale: { x?: number; y?: number; z?: number };
    };
    blueprint: string;
  }): void {
    const blueprint: ComponentConfiguration<PlaneConfiguration> = {
      id: payload.id,
      position: new Vector3(
        payload.offset.position.x,
        payload.offset.position.y,
        payload.offset.position.z
      ),
      rotation: new Quaternion(
        payload.offset.rotation.x,
        payload.offset.rotation.y,
        payload.offset.rotation.z,
        payload.offset.rotation.w
      ),
      normal: new Vector3(
        payload.offset.normal.x,
        payload.offset.normal.y,
        payload.offset.normal.z
      ),
      scale: new Vector3(
        payload.offset.scale.x,
        payload.offset.scale.y,
        payload.offset.scale.z
      ),
      stemHeight: 0,
      objects: [
        new PlaneConfiguration({
          texture: `${payload.blueprint}`,
        }),
      ],
    };
    this._matterport
      .addBlueprint(blueprint)
      .then(() => {
        if (window.onAddBlueprint)
          window.onAddBlueprint({
            status: 'Success',
          });
      })
      .catch((error) => {
        if (window.onAddBlueprint)
          window.onAddBlueprint({ status: 'Failure', error });
      });
  }

  private _moveToPose(pose: Camera.Pose): void {
    firstValueFrom(this._matterportManager.dollHouse.moveToPose$(pose))
      .then((data) => {
        if (window.onMoveToPose && data !== undefined)
          window.onMoveToPose({
            status: 'Success',
            data: data,
          });
      })
      .catch((error: any) => {
        if (window.onMoveToPose)
          window.onMoveToPose({ status: 'Failure', error });
      });
  }

  private _changeMode(mode: Mode.Mode): void {
    firstValueFrom(this._matterportManager.dollHouse.changeMode$(mode))
      .then((data) => {
        if (window.onModeChanged && data !== undefined)
          window.onModeChanged({
            status: 'Success',
            data: data,
          });
      })
      .catch((error: any) => {
        if (window.onModeChanged)
          window.onModeChanged({ status: 'Failure', error });
      });
  }

  private _changeFloor(number: number): void {
    firstValueFrom(this._matterportManager.dollHouse.changeFloor$(number))
      .then((data) => {
        if (window.onFloorChanged && data !== undefined)
          window.onFloorChanged({
            status: 'Success',
            data: data,
          });
      })
      .catch((error: any) => {
        if (window.onFloorChanged)
          window.onFloorChanged({ status: 'Failure', error });
      });
  }

  private _createPortal(payload: PortalConfiguration[]): void {
    const portalPromises = payload.map((portal) =>
      this._matterport.createPortal(portal)
    );
    Promise.all(portalPromises)
      .then(() => {
        if (window.onCreatePortal)
          window.onCreatePortal({
            status: 'Success',
          });
      })
      .catch((error: any) => {
        if (window.onCreatePortal)
          window.onCreatePortal({ status: 'Failure', error });
      });
  }
  private _checkScanVisibility(scanId: string): void {
    firstValueFrom(this._matterportApi.getScanVisibility$(scanId))
      .then((data) => {
        if (window.onCheckScanVisibility)
          window.onCheckScanVisibility({
            status: 'Success',
            data: data.data.model.visibility,
          });
      })
      .catch((error: any) => {
        if (window.onCheckScanVisibility)
          window.onCheckScanVisibility({ status: 'Failure', error });
      });
  }
  private _getViews(scanId: string): void {
    firstValueFrom(this._matterportApi.getModelViews$(scanId))
      .then((data) => {
        if (window.onGetViews)
          window.onGetViews({ status: 'Success', data: data.data.model.views });
      })
      .catch((error: any) => {
        console.log(error);
        if (window.onGetViews) window.onGetViews({ status: 'Failure', error });
      });
  }

  private _destroyMatterport() {
    this._matterport.destroy();
  }
  private _componentClicked(): void {
    this._matterport.componentClicked$
      .pipe(
        filter(
          (component: ClickEventEmitter) =>
            component.userData?.type !== 'portal'
        ),
        takeUntil(this._destroy)
      )
      .subscribe((note: ClickEventEmitter) => {
        if (window.onComponentClicked) window.onComponentClicked(note.id);
      });
  }

  private _portalClicked(): void {
    this._matterport.componentClicked$
      .pipe(
        filter(
          (component: ClickEventEmitter) =>
            component.userData?.type === 'portal'
        ),
        takeUntil(this._destroy)
      )
      .subscribe((note: ClickEventEmitter) => {
        if (window.onPortalClicked) window.onPortalClicked(note.id);
      });
  }
  private _addComponent(tagNote: TagNote): void {
    const componentConfiguration = new CustomSpriteComponent(tagNote);
    this._matterport
      .addComponent$(componentConfiguration)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onAddComponent)
            window.onAddComponent({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onAddComponent)
            window.onAddComponent({ status: 'Failure', error });
        },
      });
  }

  private _loadMattertags(mattertags: Mattertag[]): void {
    firstValueFrom(this._matterport.loadMattertags$(mattertags))
      .then((simlabIds: string[]) => {
        if (window.onLoadMattertags)
          window.onLoadMattertags({
            status: 'Success',
            data: simlabIds,
          });
      })
      .catch((error: any) => {
        console.log(error);
        if (window.onLoadMattertags)
          window.onLoadMattertags({ status: 'Failure', error });
      });
  }
  private _addComponents(tagNotes: TagNote[]): void {
    const components$ = tagNotes.map((note) => {
      const componentConfiguration = new CustomSpriteComponent(note);
      return this._matterport
        .addComponent$(componentConfiguration)
        .pipe(take(1), takeUntil(this._destroy));
    });
    combineLatest(components$).subscribe({
      next: () => {
        if (window.onAddComponents)
          window.onAddComponents({ status: 'Success' });
      },
      error: (error: any) => {
        console.log(error);
        if (window.onAddComponents)
          window.onAddComponents({ status: 'Failure', error });
      },
    });
  }
  private _clearAllComponents(): void {
    try {
      this._matterport.clearAllNotes();
      if (window.onClearAllComponents)
        window.onClearAllComponents({ status: 'Success' });
    } catch (error) {
      if (window.onClearAllComponents)
        window.onClearAllComponents({ status: 'Failure', error });
    }
  }
  private _removeComponent(id: string): void {
    this._matterport
      .deleteNote$(id)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onRemoveComponent)
            window.onRemoveComponent({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onRemoveComponent)
            window.onRemoveComponent({ status: 'Failure', error });
        },
      });
  }
  private _updateComponent(
    component: Pick<
      ComponentConfiguration<ComponentsType>,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ): void {
    this._matterport
      .updateNote$(component)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onUpdateComponent)
            window.onUpdateComponent({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onUpdateComponent)
            window.onUpdateComponent({ status: 'Failure', error });
        },
      });
  }
  // /**
  //  * @deprecated
  //  */
  // private _acceptPlacingPositionAndReplaceWithComponent(): void {
  //   this._matterport
  //     .acceptPlacingPositionAndReplaceWithComponent$()
  //     .pipe(take(1), takeUntil(this._destroy))
  //     .subscribe({
  //       next: (tagNote: TagNote) => {
  //         if (window.onAcceptPlacingPositionAndReplaceWithComponent)
  //           window.onAcceptPlacingPositionAndReplaceWithComponent({
  //             status: 'Success',
  //             data: tagNote,
  //           });
  //       },
  //       error: (error: any) => {
  //         console.log(error);
  //         if (window.onAcceptPlacingPositionAndReplaceWithComponent)
  //           window.onAcceptPlacingPositionAndReplaceWithComponent({
  //             status: 'Failure',
  //             error,
  //           });
  //       },
  //     });
  // }

  private _deleteToken(): void {
    this._matterportApi.deleteToken();

    if (window.onDeleteToken) {
      window.onDeleteToken({ status: 'Success' });
    }
  }

  private _getModelSweeps(scanId: string): void {
    this._matterportApi
      .getModelSweeps$(scanId)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: (result: Array<ModelSweep>) => {
          if (window.onGetModelSweeps) {
            window.onGetModelSweeps({ status: 'Success', data: result });
          }
        },
        error: (error: any) => {
          if (window.onGetModelSweeps) {
            window.onGetModelSweeps({ status: 'Failure', error });
          }
        },
      });
  }

  private _getModelAccess(scanId: string): void {
    this._matterportApi
      .getModelAccess$(scanId)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: (data: ModelAccess) => {
          if (window.onGetModelAccess) {
            window.onGetModelAccess({ status: 'Success', data });
          }
        },
        error: (error: any) => {
          if (window.onGetModelAccess) {
            window.onGetModelAccess({ status: 'Failure', error });
          }
        },
      });
  }

  private _setSelectedNote(noteId: string): void {
    try {
      this._matterport.selectedNote = noteId;

      if (window.onSetSelectedNote)
        window.onSetSelectedNote({ status: 'Success' });
    } catch (error) {
      if (window.onSetSelectedNote)
        window.onSetSelectedNote({ status: 'Failure', error });
    }
  }

  private _setMatterportToken(token: string): void {
    try {
      if (token) {
        this._matterportApi.setMatterportToken(token);
      } else {
        this._matterportApi.deleteToken();
      }

      if (window.onSetMatterportToken)
        window.onSetMatterportToken({ status: 'Success' });
    } catch (error: any) {
      if (window.onSetMatterportToken)
        window.onSetMatterportToken({ status: 'Failure', error });
    }
  }
  private _matterportAuthorization(): void {
    this._matterportApi
      .authorization$()
      .pipe(
        take(1),
        takeUntil(this._destroy),
        switchMap(
          (
            auth:
              | MatterportAuthorizationResponse<MatterportAuthorization>
              | undefined
          ) => this._matterportApi.clearCache$().pipe(map(() => auth))
        )
      )
      .subscribe({
        next: (
          auth:
            | MatterportAuthorizationResponse<MatterportAuthorization>
            | undefined
        ) => {
          if (window.onMatterportAuthorization)
            window.onMatterportAuthorization({
              status: 'Success',
              data: auth,
            } as FunctionResponse<MatterportAuthorizationResponse<MatterportAuthorization>>);
        },
        error: (error: any) => {
          if (window.onMatterportAuthorization)
            window.onMatterportAuthorization({ status: 'Failure', error });
        },
      });
  }
  private _moveToMattertag(tagId: string): void {
    this._matterport
      .moveToMattertag$(tagId)
      .pipe(takeUntil(this._destroy), take(1))
      .subscribe(() => {
        if (window.onMoveToMattertag) {
          window.onMoveToMattertag({
            status: 'Success',
          });
        }
      });
  }
  private _noteClicked(): void {
    this._matterport.noteClicked$
      .pipe(takeUntil(this._destroy))
      .subscribe((note: string) => {
        if (window.onNoteClicked) window.onNoteClicked(note);
      });
  }
  private _positionChange(): void {
    this._matterport.positionChange$
      .pipe(takeUntil(this._destroy))
      .subscribe((position: Camera.Pose | null) => {
        if (window.onPositionChange) window.onPositionChange(position);
      });
  }
  private _getTags(modelId: string): void {
    this._matterportApi
      .getTags$(modelId)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: (data: ApolloQueryResult<GetModelTagsQuery>) => {
          if (window.onGetTags)
            window.onGetTags({
              status: 'Success',
              data: data.data.model?.mattertags,
            });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onGetTags) window.onGetTags({ status: 'Failure', error });
        },
      });
  }
  private _getModels(offset?: string, query?: string): void {
    this._matterportApi
      .getModels$(query, offset)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: (models: ApolloQueryResult<ModelsQuery>) => {
          if (window.onGetModels)
            window.onGetModels({ status: 'Success', data: models.data.models });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onGetModels)
            window.onGetModels({ status: 'Failure', error });
        },
      });
  }
  private _goToPositionAndLookAt(position: Vector3): void {
    this._matterport
      .goToPositionAndLookAt$(position)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onGoToPositionAndLookAt)
            window.onGoToPositionAndLookAt({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onGoToPositionAndLookAt)
            window.onGoToPositionAndLookAt({ status: 'Failure', error });
        },
      });
  }
  private _setSweepActive(enable: boolean): void {
    this._matterport
      .setSweepsActive$(enable)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onSetSweepActive)
            window.onSetSweepActive({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onSetSweepActive)
            window.onSetSweepActive({ status: 'Failure', error });
        },
      });
  }
  private _getAvailableSweeps(): void {
    this._matterport
      .getAvailableSweeps$()
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: (data: Sweep.ObservableSweepData[] | null) => {
          if (window.onGetAvailableSweeps)
            window.onGetAvailableSweeps({ status: 'Success', data });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onGetAvailableSweeps)
            window.onGetAvailableSweeps({ status: 'Failure', error });
        },
      });
  }
  private _moveToClosestSweep(config: MoveToClosestSweepConfig): void {
    this._matterport
      .moveToClosestSweep$(config)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onMoveToClosestSweep)
            window.onMoveToClosestSweep({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onMoveToClosestSweep)
            window.onMoveToClosestSweep({ status: 'Failure', error });
        },
      });
  }
  private _moveToSweep(config: MoveToSweepConfig): void {
    this._matterport
      .moveToSweep$(config)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onMoveToSweep) window.onMoveToSweep({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onMoveToSweep)
            window.onMoveToSweep({ status: 'Failure', error });
        },
      });
  }

  private _startPlacingComponent(startPlacingConfig: StartPlacingConfig) {
    this._destroyClick.next();
    if (startPlacingConfig.autoFinishByClick) {
      this._matterport.tagPlacementAccepted$
        .pipe(
          tap(
            (value: {
              tagNote: TagNote;
              comp: MatterportComponent<ComponentsTypeClass> | undefined;
            }) => {
              if (window.onAcceptPlacingPositionAndReplaceWithComponent)
                window.onAcceptPlacingPositionAndReplaceWithComponent({
                  status: 'Success',
                  data: value.tagNote,
                });
            }
          ),

          takeUntil(this._destroyClick),
          take(1)
        )
        .subscribe();
    }
    this._matterport
      .startPlacingComponent$(startPlacingConfig)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onStartPlacingComponent)
            window.onStartPlacingComponent({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onStartPlacingComponent)
            window.onStartPlacingComponent({ status: 'Failure', error });
        },
      });
  }

  // /**
  //  * @deprecated
  //  */
  // private _startPlacing(
  //   startPlacingConfig: StartPlacingConfig,
  //   type: 'COMPONENT' | 'TAG' = 'TAG'
  // ): void {
  //   this._destroyClick.next();
  //   if (startPlacingConfig.autoFinishByClick) {
  //     this._matterport.matterportClick$
  //       .pipe(
  //         tap(() => {
  //           if (type === 'TAG') {
  //             this._acceptPlacingPosition();
  //           } else {
  //             this._acceptPlacingPositionAndReplaceWithComponent();
  //           }
  //         }),

  //         takeUntil(this._destroyClick),
  //         take(1)
  //       )
  //       .subscribe();
  //   }
  //   this._matterport
  //     .startPlacing$(startPlacingConfig)
  //     .pipe(take(1), takeUntil(this._destroy))
  //     .subscribe({
  //       next: () => {
  //         if (window.onStartPlacing)
  //           window.onStartPlacing({ status: 'Success' });
  //       },
  //       error: (error: any) => {
  //         console.log(error);
  //         if (window.onStartPlacing)
  //           window.onStartPlacing({ status: 'Failure', error });
  //       },
  //     });
  // }

  // /**
  //  * @deprecated
  //  */
  // private _acceptPlacingPosition(): void {
  //   this._matterport
  //     .acceptPlacingPosition$()
  //     .pipe(take(1), takeUntil(this._destroy))
  //     .subscribe({
  //       next: (tagNote: TagNote) => {
  //         if (window.onAcceptPlacingPosition)
  //           window.onAcceptPlacingPosition({
  //             status: 'Success',
  //             data: tagNote,
  //           });
  //       },
  //       error: (error: any) => {
  //         console.log(error);
  //         if (window.onAcceptPlacingPosition)
  //           window.onAcceptPlacingPosition({ status: 'Failure', error });
  //       },
  //     });
  // }
  private _abandonPlacing(): void {
    try {
      this._destroyClick.next();
      this._matterport.abandonPlacingComponent();

      if (window.onAbandonPlacing)
        window.onAbandonPlacing({ status: 'Success' });
    } catch (error) {
      if (window.onAbandonPlacing)
        window.onAbandonPlacing({ status: 'Failure', error });
    }
  }
  private _clearAllTagNotes(): void {
    try {
      this._matterport.clearAllTagNotes();
      if (window.onClearAllTagNotes)
        window.onClearAllTagNotes({ status: 'Success' });
    } catch (error) {
      if (window.onClearAllTagNotes)
        window.onClearAllTagNotes({ status: 'Failure', error });
    }
  }
  private _removeNote(id: string): void {
    this._matterport
      .removeNote$(id)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onRemoveNote) window.onRemoveNote({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onRemoveNote)
            window.onRemoveNote({ status: 'Failure', error });
        },
      });
  }
  private _addNote(tagNote: TagNote): void {
    this._matterport
      .addNote$(tagNote)
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: () => {
          if (window.onAddNote) window.onAddNote({ status: 'Success' });
        },
        error: (error: any) => {
          console.log(error);
          if (window.onAddNote) window.onAddNote({ status: 'Failure', error });
        },
      });
  }
  private _addNotes(tagNotes: TagNote[]): void {
    const notes$ = tagNotes.map((note) =>
      this._matterport.addNote$(note).pipe(take(1), takeUntil(this._destroy))
    );
    combineLatest(notes$).subscribe({
      next: () => {
        if (window.onAddNotes) window.onAddNotes({ status: 'Success' });
      },
      error: (error: any) => {
        console.log(error);
        if (window.onAddNotes) window.onAddNotes({ status: 'Failure', error });
      },
    });
  }
  private _openScanWithOffset(
    scanId: string,
    uniqueHash: string,
    offset: string | null,
    showMattertags: boolean = false,
    language: SupportedLinks = SupportedLinks.EN,
    enablePortals: boolean = false
  ) {
    this._destroy.next();
    this._destroy.complete();
    this._matterport
      .createAndOpenScan$(
        this.container,
        scanId,
        offset || undefined,
        showMattertags,
        language,
        enablePortals
      )
      .pipe(take(1), takeUntil(this._destroy))
      .subscribe({
        next: (appStatus: MatterportScanStatus) => {
          this._selectedNoteObserver();
          this.areaTool = new UnityAreaTool(this._matterportManager.areaMesh);
          if (window.onOpenScanWithOffset) {
            if (appStatus === CustomPhases.PLAYING) {
              window.onOpenScanWithOffset({
                status: 'Success',
                data: {
                  uniqueHash,
                },
              });
            } else {
              window.onOpenScanWithOffset({
                status: 'Failure',
                data: {
                  uniqueHash,
                  error: appStatus,
                },
              });
            }
          }
        },
        error: (error: any) => {
          if (window.onOpenScanWithOffset)
            window.onOpenScanWithOffset({
              status: 'Failure',
              data: {
                uniqueHash,
                error: error,
              },
            });
        },
      });
  }
  private _selectedNoteObserver() {
    this._matterport.componentClicked$
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (component: ClickEventEmitter | undefined) => {
          if (window.selectedNote && component?.id) {
            window.selectedNote(component?.id);
          }
        },
        error: (e: Error) => {
          console.log('selectedNote', e);
        },
      });
  }
}
