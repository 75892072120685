import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
  effect,
  inject,
  input
} from '@angular/core';
import { Subject, shareReplay, take, takeUntil } from 'rxjs';
import { IconCacheService } from './icon-cache.service';
import { SIM_ICON_SIZE_DATA, SimIconSizeType, SimIconType, SimIconUrl } from './sim-icon.data';
let uniqueIdCounter = 0;
@Component({
  selector: 'sim-icon',
  template: '',
  standalone: true,
  imports: [CommonModule],
  styleUrls: ['./sim-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[id]': 'id'
  }
})
export class SimIconComponent implements OnInit, OnChanges, OnDestroy {
  private readonly iconCache = inject(IconCacheService);
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private _iconPath!: string;
  private _invertColor: number = 0;
  private _icon!: SimIconType;

  readonly defaultClass = 'sim-icon';
  @HostBinding() class = this.defaultClass;

  @Input() set icon(value: SimIconType | string) {
    if (this._iconPath) throw new Error(`You can't set both path and icon`);
    this._icon = value as SimIconType;
    const wrapper = (this.elementRef.nativeElement as Element).firstChild;
    if (wrapper && this.iconCache && value) {
      const icon = SimIconUrl(this._icon);
      this.iconCache
        .getSvg(icon)
        .pipe(take(1))
        .subscribe((svg: string) => {
          (wrapper as Element).innerHTML = svg;
        });
    }
  }
  get icon(): SimIconType {
    return this._icon;
  }
  @Input() set icon_url(value: string) {
    if (this._icon) throw new Error(`You can't set both path and icon`);
    this._iconPath = value;
    const wrapper = (this.elementRef.nativeElement as Element).firstChild;
    if (wrapper && this.iconCache && value) {
      this.iconCache
        .getSvg(value)
        .pipe(takeUntil(this._destroySource), shareReplay(1))
        .subscribe((svg: string) => {
          (wrapper as Element).innerHTML = svg;
        });
    }
  }
  @Input() set sizeType(value: SimIconSizeType) {
    const wrapper = (this.elementRef.nativeElement as Element).firstChild;
    if (wrapper) {
      (wrapper as HTMLSpanElement).classList.forEach((className: string) => {
        if (className.startsWith('ui-icon-size-')) this.renderer.removeClass(wrapper, className);
      });
      this.renderer.addClass(wrapper, `ui-icon-size-${value}`);
    }
  }

  readonly sizeInPx = input<number>();

  @Input() set color(value: string | string[]) {
    const wrapper = (this.elementRef.nativeElement as Element).firstChild;
    if (wrapper) {
      if (Array.isArray(value)) {
        value.forEach((color, idx) => {
          this._setIconColor(wrapper, color, idx);
        });
      } else {
        this._setIconColor(wrapper, value, 0);
      }
    }
  }
  @Input() set invertColor(value: number) {
    this._invertColor = value;
  }

  get invert(): string {
    return `invert(${this._invertColor})`;
  }

  @Input() id: string = `sim-icon-${uniqueIdCounter++}`;

  iconUrl!: string;
  sizePixel!: number;
  constructor() {
    const element = this.renderer.createElement('span');
    this.renderer.addClass(element, 'ui-icon-wrapper');
    this.renderer.appendChild(this.elementRef.nativeElement, element);
    this.renderer.addClass(this.elementRef.nativeElement, 'icon');
    this.renderer.addClass(element, `ui-icon-size-medium`);

    effect(() => {
      const value = this.sizeInPx();
      if (!value) return;
      const wrapper = (this.elementRef.nativeElement as Element).firstChild;
      if (wrapper) {
        (wrapper as HTMLSpanElement).classList.forEach((className: string) => {
          if (className.startsWith('ui-icon-size-')) this.renderer.removeClass(wrapper, className);
        });
        this.renderer.setStyle(wrapper, `width`, `${value}px`);
        this.renderer.setStyle(wrapper, `height`, `${value}px`);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  ngOnInit(): void {
    this.sizePixel = SIM_ICON_SIZE_DATA[this.sizeType];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['sizeType']) {
      this.sizePixel = SIM_ICON_SIZE_DATA[this.sizeType];
    }
    if (changes['icon']) {
      this.iconUrl = SimIconUrl(this.icon);
    }
  }

  private _setIconColor(wrapper: ChildNode, value: string, idx: number) {
    this.renderer.addClass(wrapper, `icon-clr-${idx}`);
    this.renderer.setStyle(this.elementRef.nativeElement, `--clr${idx}`, value, 2);
  }
}
