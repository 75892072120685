<button (click)="fullScreen()" mat-icon-button class="cursor-pointer" *ngIf="isAndroidOrDesktop$ | async">
  <ng-container *ngIf="lightMode; else darkMode">
    <sim-icon sizeType="medium" *ngIf="!isFullScreen" icon="fullscreen_light"></sim-icon>
    <sim-icon sizeType="medium" *ngIf="isFullScreen" icon="fullscreen_exit_light"></sim-icon>
  </ng-container>
  <ng-template #darkMode>
    <sim-icon sizeType="medium" *ngIf="!isFullScreen" icon="fullscreen_dark"></sim-icon>
    <sim-icon sizeType="medium" *ngIf="isFullScreen" icon="fullscreen_exit_dark"></sim-icon>
  </ng-template>
</button>
