import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation,
  inject
} from '@angular/core';

@Component({
  selector: 'ui-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  @Input('context')
  get context(): number | undefined {
    return this._context;
  }

  set context(value: number | undefined) {
    if (value === 0) {
      this._context = undefined;
      return;
    }
    this._context = value;
  }
  private _context: number | undefined;
}
