import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { AssetFacade } from '@simOn/asset';
import { ProviderErrorType } from '@simOn/common/http';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import { DevicesFacade, PoveterLocal, WidgetFacade } from '@simOn/device';
import { GetLastPosition, PositionChange } from '@simOn/matterport/viewer/models';
import { privilegesEventListener } from '@simOn/privileges/element/services';
import { ProviderConnectionStatusEnum } from '@simOn/provider/connection/models';
import { PublicLinkService } from '@simOn/public-link';
import { RoomFacade } from '@simOn/room';
import { SceneFacade } from '@simOn/scene';
import { SpaceFacade } from '@simOn/space';
import { apartmentEventListener } from '@simOn/space/element/services';
import { ProviderConnectionInterface } from '@simOn/space/information/models';
import { TicketFacade } from '@simOn/ticket';
import { ticketEventListener } from '@simOn/ticket/element/services';
import { UpdateUserState } from '@simOn/user/last-visited/models';
import { UsersFacade } from '@simOn/user/last-visited/state';
import { SignalRService } from '@simOn/utils/signalR';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { combineLatest, map, switchMap, take, tap } from 'rxjs';
import { DeviceErrorListener } from '../app.routes';
import { PrivilegesFacade } from '@simOn/privileges';

export const apartmentResolverFn: ResolveFn<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const apartmentId = route.params.id ?? route.params.apartmentId;
  const spaceFacade = inject(SpaceFacade);
  const assetFacade = inject(AssetFacade);
  const roomFacade = inject(RoomFacade);
  const privilegesFacade = inject(PrivilegesFacade);
  const devicesFacade = inject(DevicesFacade);
  const widgetFacade = inject(WidgetFacade);
  const sceneFacade = inject(SceneFacade);
  const deviceErrorListener = inject(DeviceErrorListener);
  const ticketFacade = inject(TicketFacade);
  const signalR = inject(SignalRService);
  const publicLinkService = inject(PublicLinkService);
  const router = inject(Router);
  const usersFacade = inject(UsersFacade);
  deviceErrorListener.subscribe();
  const loggedUser = inject(OidcSecurityService).getUserData();
  spaceFacade.getApartment();
  !publicLinkService.isPublicLinkModeActive() &&
    apartmentEventListener(signalR, spaceFacade.selectedApartmentId$, loggedUser, {
      CurrentApartmentUsers: (apartmentUsers) => {
        usersFacade.setSelectedApartmentUsers(apartmentUsers);
      },
      UserEnteredApartment: ({ userId }) => {
        const userState: UpdateUserState = {
          id: userId,
          isActive: true,
          lastVisited: new Date().toJSON()
        };
        usersFacade.updateApartmentUserState(userState);
      },
      UserLeftApartment: ({ userId }) => {
        const userState: UpdateUserState = {
          id: userId,
          isActive: false,
          lastVisited: new Date().toJSON()
        };
        usersFacade.updateApartmentUserState(userState);
      },
      UserGrantAccessToApartment: (apartmentUser) => {
        usersFacade.addApartmentUserState(apartmentUser);
      },
      UserLostAccessToApartment: (apartmentUser) => {
        usersFacade.deleteApartmentUserState(apartmentUser);
      }
    });
  localStorage.setItem('objectDefaultVisibility', 'false');
  return spaceFacade.selectedApartmentId$.pipe(
    tap((selectedApartmentId: string) => {
      if (selectedApartmentId) {
        const position: PositionChange = GetLastPosition(apartmentId);
        if (position && position?.modelId) spaceFacade.setSelectedMatterportModelId(position.modelId);
        !publicLinkService.isPublicLinkModeActive()
          ? privilegesEventListener(signalR, router, {
              UsersLostAccessToApartment: ({ apartmentId, userIds }) => {
                userIds.forEach((userId) => usersFacade.deleteApartmentUserState({ apartmentId, userId }));
              },
              LostAccessToApartment: ({ apartmentId }) => {
                spaceFacade.removeApartment(apartmentId);
                spaceFacade.leaveApartment();
              },
              UserApartmentAccessModified: () => {
                privilegesFacade.loadPrivileges();
              }
            })
          : ticketFacade.getTickets(selectedApartmentId, true);
        !publicLinkService.isPublicLinkModeActive() &&
          ticketEventListener(signalR, ticketFacade, selectedApartmentId, {
            TicketCreated: ({ ticketId }) => {
              ticketFacade.getTicket(ticketId);
            },
            TicketModified: ({ ticketId }) => {
              ticketFacade.handleTicketModified(ticketId);
            },
            TicketDeleted: ({ ticketId }) => {
              ticketFacade.deleteTicketFromStore(ticketId);
            }
          });
        roomFacade.getRooms();
        roomFacade.getMasterRooms();
        widgetFacade.loadWidgets();
        devicesFacade.loadDevicesStructure();
        sceneFacade.getSimlabScenes();
        assetFacade.getAssets();
      }
    }),
    switchMap((selectedApartmentId) =>
      spaceFacade.apartmentProviders$.pipe(
        tap((apartmentProviders: ProviderConnectionInterface[]) => {
          const providerConnections = apartmentProviders
            .filter(
              (providers: ProviderConnectionInterface) =>
                providers.smartApiProvider !== SmartApiProviderEnum.MATTERPORT &&
                providers.smartApiProvider !== SmartApiProviderEnum.NONE &&
                providers.smartApiProvider !== SmartApiProviderEnum.GOOGLE &&
                providers.smartApiProvider !== SmartApiProviderEnum.MAXIMO
            )
            .filter(
              (provider: ProviderConnectionInterface) =>
                provider.providerConnectionStatus !== ProviderConnectionStatusEnum.LoggedOut
            );

          if (selectedApartmentId) devicesFacade.loadDevicesProperties(providerConnections as PoveterLocal[]);
          devicesFacade.LoadDevicePropertiesFailure$.pipe(
            tap(({ error, masterDeviceId }) => {
              if (
                [
                  ProviderErrorType.SmartApiAuthorizationProviderNoAuthorization,
                  ProviderErrorType.SmartApiAuthorization,
                  ProviderErrorType.SmartApiAuthorizationProviderLoggedOut
                ].includes(error.error.ErrorCode)
              ) {
                spaceFacade.updateApartmentProviderStatus(
                  'apartmentId',
                  masterDeviceId,
                  ProviderConnectionStatusEnum.LoggedOut
                );
              }
            })
          );
        })
      )
    ),
    switchMap(() => {
      return combineLatest([spaceFacade.defaultMatterportModelId$, spaceFacade.selectedMatterportModelId$]).pipe(
        take(1),
        tap(([defaultMatterportModelId, selectedMatterportModelId]) => {
          spaceFacade.setSelectedMatterportModelId(selectedMatterportModelId || defaultMatterportModelId);
        })
      );
    }),
    map(() => true)
  );
};
