import { HttpBackend, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { MatterportApiConfig } from './models/matterport-api-config';
import { HttpClientBypass } from './tokens/http-bypass';

export function createApollo(
  httpLink: HttpLink,
  apiConfig: MatterportApiConfig
): ApolloClientOptions<any> {
  if (!apiConfig.matterportModelApiUrl || !apiConfig.matterportShowcaseApiUrl) {
    throw new Error('Set urls for api and showcase');
  }
  const token = () => {
    return sessionStorage.getItem('matterport-token');
  };
  const auth = setContext((operation, context) => {
    const headers = {
      'x-matterport-application-key': apiConfig.applicationKey,
    };
    if (token() !== null) {
      return {
        headers: {
          ...headers,
          Authorization: token() ? `Bearer ${token()}` : '',
        },
      };
    }
    return { headers };
  });
  return {
    link: ApolloLink.from([
      auth,
      httpLink.create({
        uri:
          token() !== null
            ? apiConfig.matterportModelApiUrl
            : apiConfig.matterportShowcaseApiUrl,
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Mattertag: {
          fields: {
            media: {
              read(media) {
                // Return the cached name, transformed to upper case
                return media || null;
              },
            },

            mediaType: {
              read(mediaType) {
                // Return the cached name, transformed to upper case
                return mediaType || null;
              },
            },
          },
        },
        ModelSearchResultList: {
          fields: {
            nextOffset: {
              read(nextOffset) {
                // Return the cached name, transformed to upper case
                return nextOffset || null;
              },
            },
          },
        },
        Address: {
          fields: {
            address: {
              read(address) {
                // Return the cached name, transformed to upper case
                return address || null;
              },
            },
          },
        },
      },
    }),
  };
}
export function createNewHttpClient(httpBackend: HttpBackend) {
  return new HttpClient(httpBackend);
}
export function createNewHttpLink(httpClient: HttpClient) {
  return new HttpLink(httpClient);
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: HttpClientBypass,
      useFactory: createNewHttpClient,
      deps: [HttpBackend],
    },
    {
      provide: HttpLink,
      useFactory: createNewHttpLink,
      deps: [HttpClientBypass],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, MatterportApiConfig],
    },
  ],
})
export class GraphQLModule {}
