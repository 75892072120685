export type Environment = typeof environment;
export const environment = {
  production: false,
  authApi: 'https://dev-identity.simlab.pl',
  simlabApi: 'https://simlab-simon-dev-api-app.azurewebsites.net/api',

  simlabApiV2: 'https://simlab-simon-dev-api-app.azurewebsites.net/api',
  simlabApiSignal: 'https://simlab-simon-dev-socketserver-app.azurewebsites.net',
  singalHubs: {
    SimonHub: 'SimonHub'
  },
  // MISAVA
  // authApi: 'https://identity.simlab.pl',
  // simlabApi: 'https://prod-smarthome-api.simlab.pl/api',

  payment: {
    payU: {
      posId: {
        USD: '412599',
        EURO: '412599'
      },
      redirect: '/payment-summary',
      url: 'https://secure.snd.payu.com/javascript/sdk',
      endpoints: {
        saveCard: 'https://simlab-simon-dev-api-app.azurewebsites.net/api/Payment/CreateRecurringPayUPaymentMethod'
      }
    },
    payPal: {
      clientId: 'AR57s4FmyFjPUDKMCDJAXQB7eQJy7di93QSBbyeZKuYH_l-GWjZR4fLr8RE2KlyY9e1ilY1Hnv_MDCBe'
    }
  },
  config: {
    imgMaxSize: 31457280,
    docMaxSize: 31457280
  },
  store: {
    fetchDeviceInterval: 30 * 1000 // every 30sa
  },
  matterport: {
    sdkKey: '083ea9dbab9f47fc9a7e638bca8c32e0'
  },
  matterportAuth: {
    apiKey: '083ea9dbab9f47fc9a7e638bca8c32e0',
    clientId: 'r2zw69m1r1k94ps0ysmhrxa1c',
    redirectAfterLoginUrl: '/callbackMatterport',
    // apiGraphUrl: 'https://api.matterport.com/api/models/graph', //WAIT for matterport release attachment on model API
    apiGraphUrl: 'https://my.matterport.com/api/mp/models/graph'
  },

  demoApartments: [
    {
      name: 'Demo Public SIMLAB Office',
      photoUrl: 'assets/images/office.png',
      demoUrl:
        'https://app.sim-on.com/public/eyJwdWJsaWNMaW5rIjoiNzZjNGZiMzYtZDlhNi00ODY1LWJhNWMtNDVhZGM1NWQ5YjIwIiwidXJsIjoiYXBhcnRtZW50P2lkPTU5YTFmZjMzLTVkMjUtNDJlNi1hMTdiLTc5ZDQ4MDVhYWQ3ZSIsImV4cGlyZSI6IjIwMjEtMDgtMzFUMDk6NDk6MTYuNjA2Mzg2NVoifQ=='
    },
    {
      name: 'Demo Family House',
      photoUrl: 'assets/images/family_house.png',
      demoUrl:
        'https://app.sim-on.com/public/eyJwdWJsaWNMaW5rIjoiNzZjNGZiMzYtZDlhNi00ODY1LWJhNWMtNDVhZGM1NWQ5YjIwIiwidXJsIjoiYXBhcnRtZW50P2lkPTU5YTFmZjMzLTVkMjUtNDJlNi1hMTdiLTc5ZDQ4MDVhYWQ3ZSIsImV4cGlyZSI6IjIwMjEtMDgtMzFUMDk6NDk6MTYuNjA2Mzg2NVoifQ=='
    }
  ]
} as const;
