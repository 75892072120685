import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Vector } from '@simlab/matterport/transform';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Scene } from 'mpSdk';
import {
  catchError,
  defer,
  filter,
  finalize,
  firstValueFrom,
  from,
  fromEvent,
  iif,
  last,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  race,
  retry,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
  timer,
} from 'rxjs';
import { Box3, Euler, Event, Object3D, Vector3 } from 'three';

import { TransformControls as ThreeTransformControls } from 'three/examples/jsm/controls/TransformControls';
import { getElementPosition } from '../base/custom-component-base';
import { MatterportServiceBase } from '../base/matterport-base';
import { ComponentInteractionType } from '../base/scene-component-base';
import { disposeHierarchy, disposeNode } from '../helpers/three.helpres';
import {
  ComponentsTypeClass,
  MatterportComponent,
  SpriteConfiguration,
} from '../models/custom-component.type';
import { Pointer } from '../models/dto';
import {
  AmbientLight,
  DirectionalLightConfig,
  GLTFLoader,
  GLTFLoaderExtended,
  GLTFLoaderOutputs,
  INodeExtension,
  IObjectLoader,
  LightComponent,
  LoadingStatus,
  LoadingStatusChange,
  ObjectConfiguration,
  ObjectUpdate,
  PointLight,
  ThreeObject3d,
  TransformControls,
  TransformMode,
} from '../models/object-loader.type';
import { MatterportCustomComponentService } from './custom-component.service';
import { FileLoaderCacheService } from './file-loader-cache.service';
import { MatterportEventsService } from './matterport-events.service';
import { MatterportManagerService } from './matterport-manager.service';
import { MatterportPositionControllerService } from './matterport-position-controller.service';
import { MatterportSceneStateAccessService } from './matterport-scene-state-access.service';
const RESET_MATTERPORT_OBJECT_POSITION: Partial<GLTFLoader> = {
  localPosition: { x: 0, y: 0, z: 0 },
  localRotation: { x: 0, y: 0, z: 0 },
};
@Injectable()
export class ObjectLoaderService
  extends MatterportServiceBase
  implements OnDestroy, IObjectLoader
{
  private readonly _sceneObjects: Record<
    string,
    ObjectConfiguration<ThreeObject3d>
  > = {};
  private readonly _mobileTouchDestroyer: Subject<void> = new Subject<void>();
  private readonly _cancelAddingItem: Subject<void> = new Subject<void>();

  private _transformControls: ObjectConfiguration<{
    transformControls: ThreeTransformControls;
  }> = {} as ObjectConfiguration<{ transformControls: ThreeTransformControls }>;

  private _selectedObject: string | undefined;
  private _createNodeObject: Observable<(Scene.INode & INodeExtension) | null> =
    defer(() =>
      this.isOpen.asObservable().pipe(
        filter((isOpen) => isOpen),
        switchMap(() =>
          from(this.sdk.Scene.createObjects(1)).pipe(
            map(([obj]: Scene.IObject[]) => {
              const node = obj.addNode() as Scene.INode & INodeExtension;
              return node;
            }),
            catchError((e) => {
              console.log(e);
              return of(null);
            })
          )
        ),
        take(1)
      )
    );
  private _shadowBox: Box3 = new Box3();
  private readonly _selectedObjectChange: Subject<ObjectUpdate> =
    new Subject<ObjectUpdate>();

  readonly selectedObjectChange$: Observable<ObjectUpdate> =
    this._selectedObjectChange.asObservable();
  private readonly _objectLoadingStatusChange: Subject<LoadingStatusChange> =
    new Subject<LoadingStatusChange>();
  readonly objectLoadingStatusChange$: Observable<LoadingStatusChange> =
    this._objectLoadingStatusChange.asObservable();
  private _insertObjectMode = false;
  private _lightObject: LightComponent | undefined;
  private _lightNode: Scene.INode | undefined;
  private _lastKnownCameraPosition:
    | { x: number; y: number; z: number }
    | undefined;
  readonly objectBadgeClick$ = defer(
    () => this.matterportComponents.componentClicked$
  );
  constructor(
    private readonly matterportManager: MatterportManagerService,
    private readonly matterportPositionController: MatterportPositionControllerService,
    private readonly matterportState: MatterportSceneStateAccessService,
    private readonly matterportEvents: MatterportEventsService,
    private readonly matterportComponents: MatterportCustomComponentService,
    private readonly cache: FileLoaderCacheService,
    private readonly _renderer: Renderer2
  ) {
    super(matterportManager);
  }

  protected _init(): void {
    this.addDirectionalLight({
      color: {
        r: 1,
        g: 1,
        b: 1,
      },

      intensity: 0.3,
      castShadow: true, //enable shadow
    });
    this._clickObserver();
    this._shadowEnabledObserver(); //enable shadow
  }
  private _clickObserver() {
    this.matterportEvents.matterportClick$
      .pipe(
        filter(() => !this._insertObjectMode),
        takeUntil(this._destroy)
      )
      .subscribe((intersectionData: Pointer) => {
        if (intersectionData.object !== 'intersectedobject.unknown') {
          this.selectedObject = undefined;
        }
      });
  }

  override ngOnDestroy(): void {
    this.deleteAllObjects();

    this._lightNode?.stop();

    this._lightObject = undefined;
    this._lightNode = undefined;
    super.ngOnDestroy();
    this._transformControls = {} as ObjectConfiguration<{
      transformControls: ThreeTransformControls;
    }>;
  }

  private _shadowEnabledObserver() {
    this.matterportState.positionChange$
      .pipe(
        filter((pose) => !!pose),
        takeUntil(this._destroy),
        throttleTime(300)
      )
      .subscribe((pose) => {
        if (!pose) return;

        const { x, y, z } = pose.position;
        this._lastKnownCameraPosition = { x, y, z };
        const inputs = this._lightObject?.inputs;
        if (inputs) {
          (inputs as DirectionalLightConfig).position = {
            ...{ x, y, z },
            y: 100,
          };
          (inputs as DirectionalLightConfig).target = {
            ...{ x, y, z },
            y: -10,
          };
        }
        this._shadowBox.set(
          new Vector3(x - 10, y - 1.5, z - 10),
          new Vector3(x + 10, y + 0.3, z + 10)
        );
        Object.keys(this._sceneObjects).forEach((keys) => {
          const obj = this._sceneObjects[keys].node.obj3D;
          if (obj) {
            const tmpBox = new Box3().setFromObject(obj);
            if (this._sceneObjects[keys].badge !== undefined) {
              (
                this._sceneObjects[keys]
                  .badge as MatterportComponent<ComponentsTypeClass>
              ).comp.position = this._findClosest(
                tmpBox,
                new Vector3(x, y, z)
              ) as unknown as Vector3;
            }
            const mesh = this._sceneObjects[keys].meshRef;
            if (this._shadowBox.intersectsBox(tmpBox)) {
              mesh &&
                mesh.forEach((item) => {
                  item.castShadow = true;
                });
            } else {
              mesh &&
                mesh.forEach((item) => {
                  item.castShadow = false;
                });
            }
          }
        });

        this.matterportState.renderMesh(this._shadowBox);
      });
  }
  private _onLoaded(object: Object3D, objectId: string) {
    if (object) {
      object.traverse((node: any) => {
        if (node.type === 'Mesh') {
          node.castShadow = true;
          if (
            !this._sceneObjects[objectId].meshRef?.find(
              (mesh) => mesh.uuid === node.uuid
            )
          ) {
            this._sceneObjects[objectId].meshRef = [
              ...(this._sceneObjects[objectId].meshRef || []),
              node,
            ];
          }
        }
      });
    }
  }
  addGLTFObjectWithOffset(objectConfig: GLTFLoader) {
    return firstValueFrom(this.loadGltf$(objectConfig));
  }

  cancelAdding() {
    this._cancelAddingItem.next();
  }
  loadGltf$(objectConfig: GLTFLoader) {
    const {
      x: px,
      y: py,
      z: pz,
    } = this.matterportManager.transformConverter.toMatterportPosition(
      new Vector(
        objectConfig.localPosition.x,
        objectConfig.localPosition.y,
        objectConfig.localPosition.z
      )
    );
    const {
      x: rx,
      y: ry,
      z: rz,
    } = this.matterportManager.transformConverter.toMatterportObjectRotation(
      new Vector(
        objectConfig.localRotation.x,
        objectConfig.localRotation.y,
        objectConfig.localRotation.z
      )
    );
    const objectConfigWithOffset: GLTFLoader = {
      ...objectConfig,
      localPosition: { x: px, y: py, z: pz },
      localRotation: { x: rx, y: ry, z: rz },
    };
    return this.addGLTFObject$(objectConfigWithOffset);
  }
  addGLTFObject(objectConfig: GLTFLoader) {
    return firstValueFrom(this.addGLTFObject$(objectConfig));
  }

  setObjectVisibility(objectId: string, visible: boolean) {
    const object = this._sceneObjects[objectId];
    if (!object) return;
    (object.component.inputs as GLTFLoader).visible = visible;
    if (object.badge) object.badge.comp.inputs.visible = visible;
  }

  setAllObjectsVisibility(visible: boolean) {
    Object.keys(this._sceneObjects).forEach((objectId: string) => {
      this.setObjectVisibility(objectId, visible);
    });
  }

  goToObject(objectId: string) {
    const object = this._sceneObjects[objectId];
    if (!object) return;
    firstValueFrom(
      this.matterportPositionController.goToPositionAndLookAt$(
        new Vector3(
          object.node.position.x,
          object.node.position.y,
          object.node.position.z
        )
      )
    );
  }

  addGLTFObject$(objectConfig: GLTFLoader): Observable<string> {
    this.loadingState('Loading', objectConfig.id);
    return this.cache.getModel$(objectConfig.url).pipe(
      switchMap((url: string | null) =>
        this._createNodeObject.pipe(
          tap((node: (Scene.INode & INodeExtension) | null) => {
            if (!node) return;
            const component = node.addComponent('mp.gltfLoader', {
              ...objectConfig,
              ...RESET_MATTERPORT_OBJECT_POSITION, //NOTE:(olek) - sdk pivoting and grouping object then operate on changed vector. Recalculate position below
              url,
              onLoaded: (obj) => this._onLoaded(obj, objectConfig.id), //enable shadow
            } as GLTFLoaderExtended);

            (
              component.outputs as Scene.PredefinedOutputs & GLTFLoaderOutputs
            ).onPropertyChanged('loadingState', (status: LoadingStatus) =>
              this.loadingState(status, objectConfig.id)
            );

            this._sceneObjects[objectConfig.id] = {
              component,
              node: node as Scene.INode & INodeExtension,
            };

            this._registerClickEvent(component, objectConfig.id);
            node.start();
            if (objectConfig.localPosition && objectConfig.localRotation) {
              this._objectPositionUpdate({
                objectId: objectConfig.id,
                localPosition: new Vector3(
                  objectConfig.localPosition.x,
                  objectConfig.localPosition.y,
                  objectConfig.localPosition.z
                ),
                localRotation: new Euler(
                  objectConfig.localRotation.x,
                  objectConfig.localRotation.y,
                  objectConfig.localRotation.z
                ),
              });
            }
          }),

          mergeMap((node: (Scene.INode & INodeExtension) | null) => {
            if (!Object.keys(this._transformControls).length) {
              return this.addTransformControls$(
                {
                  selection: node as any,
                  visible: false,
                  size: 1,
                },
                objectConfig.id
              ).pipe(
                tap((component: Scene.IComponent | undefined) => {
                  this._transformControls.component =
                    component as Scene.IComponent & {
                      transformControls: ThreeTransformControls;
                    };
                  this.hideTransform();
                })
              );
            }
            return of(true);
          })
        )
      ),
      map(() => objectConfig.id)
    );
  }
  private _findClosest(box: Box3, cameraPosition: Vector3) {
    let distance: number | undefined;
    const position: Vector3 = new Vector3(0, 0, 0);

    const corners: Record<
      string,
      {
        position: Vector3;
        neighbors: { cornerId: string; moveX: boolean; moveZ: boolean }[];
      }
    > = {
      lbf: {
        position: new Vector3(box.min.x, box.min.y, box.min.z),
        neighbors: [
          { cornerId: 'rbf', moveX: true, moveZ: false },
          { cornerId: 'lbb', moveX: false, moveZ: true },
        ],
      },
      rbf: {
        position: new Vector3(box.max.x, box.min.y, box.min.z),
        neighbors: [
          { cornerId: 'lbf', moveX: true, moveZ: false },
          { cornerId: 'rbb', moveX: false, moveZ: true },
        ],
      },
      ltf: {
        position: new Vector3(box.min.x, box.max.y, box.min.z),
        neighbors: [
          { cornerId: 'rtf', moveX: true, moveZ: false },
          { cornerId: 'ltb', moveX: false, moveZ: true },
        ],
      },
      rtf: {
        position: new Vector3(box.max.x, box.max.y, box.min.z),
        neighbors: [
          { cornerId: 'ltf', moveX: true, moveZ: false },
          { cornerId: 'rtb', moveX: false, moveZ: true },
        ],
      },

      lbb: {
        position: new Vector3(box.min.x, box.min.y, box.max.z),
        neighbors: [
          { cornerId: 'lbf', moveX: true, moveZ: false },
          { cornerId: 'rbb', moveX: false, moveZ: true },
        ],
      },
      rbb: {
        position: new Vector3(box.max.x, box.min.y, box.max.z),
        neighbors: [
          { cornerId: 'lbb', moveX: true, moveZ: false },
          { cornerId: 'rbf', moveX: false, moveZ: true },
        ],
      },
      ltb: {
        position: new Vector3(box.min.x, box.max.y, box.max.z),
        neighbors: [
          { cornerId: 'ltf', moveX: true, moveZ: false },
          { cornerId: 'rtb', moveX: false, moveZ: true },
        ],
      },
      rtb: {
        position: new Vector3(box.max.x, box.max.y, box.max.z),
        neighbors: [
          { cornerId: 'ltb', moveX: true, moveZ: false },
          { cornerId: 'rtf', moveX: false, moveZ: true },
        ],
      },
    };
    const edgesLengths = [
      corners['lbf'].position.distanceTo(corners['rbf'].position), //width,
      corners['lbf'].position.distanceTo(corners['ltf'].position), //height,
      corners['lbf'].position.distanceTo(corners['lbb'].position), //depth,
    ];
    let nearestCornerKey = '';
    Object.keys(corners).forEach((key) => {
      const distanceToCamera = cameraPosition.distanceTo(corners[key].position);
      if (!distance || distanceToCamera < distance) {
        distance = distanceToCamera;
        nearestCornerKey = key;
      }
    });
    const moveToNearestNeighbor = (corner: {
      position: Vector3;
      neighbors: { cornerId: string; moveX: boolean; moveZ: boolean }[];
    }) => {
      let distanceNearestToNeighbors: number | undefined;
      let moveX = false;
      let moveZ = false;
      let distance = 0;
      corner.neighbors
        .map((neighbor) => neighbor.cornerId)
        .forEach((cornerKey, idx) => {
          distance = corner.position.distanceTo(corners[cornerKey].position);
          if (
            !distanceNearestToNeighbors ||
            distanceNearestToNeighbors < distance
          ) {
            distanceNearestToNeighbors = distance;
            moveZ = corner.neighbors[idx].moveZ;
            moveX = corner.neighbors[idx].moveX;
          }
        });
      return {
        x: moveX ? distance * 0.2 : 0,
        z: moveZ ? distance * 0.2 : 0,
      };
    };
    position.copy(corners[nearestCornerKey].position);
    const moveXY = moveToNearestNeighbor(corners[nearestCornerKey]);
    const center = box.getCenter(cameraPosition);
    const direction = new Vector3().subVectors(center, position);
    position.x += direction.x * moveXY.x;
    position.y += direction.y * (edgesLengths[1] * 0.2);
    position.z += direction.z * moveXY.z;
    return position;
  }
  addBadgeToObject$(id: string) {
    return of(true).pipe(
      switchMap(() => {
        const box = new Box3().setFromObject(
          this._sceneObjects[id].node.obj3D,
          true
        );
        if (!isFinite(box.min.x)) {
          //object is not ready to create box
          throw false;
        }
        const { x, y, z } = this._lastKnownCameraPosition || {
          x: 0,
          y: 0,
          z: 0,
        };
        const position = this._findClosest(box, new Vector3(x, y, z));
        return this.matterportComponents.addComponent$({
          id,
          position: position as unknown as Vector3,
          scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3,
          autoScale: true,
          normal: {
            x: 0,
            y: 0,
            z: 0,
          } as Vector3,
          stemHeight: 0,
          objects: [
            new SpriteConfiguration({
              icon: 'assets/icons/tag.svg',
              scale: {
                x: 1,
                y: 1,
                z: 1,
              } as Vector3,
            }),
          ],
        });
      }),
      tap(
        (component: MatterportComponent<ComponentsTypeClass> | undefined) =>
          (this._sceneObjects[id].badge = component)
      ),
      retry({ count: 10, delay: 500 })
    );
  }

  loadingState(status: LoadingStatus, id: string) {
    this._objectLoadingStatusChange.next({ status, id });
  }

  addObject$(payload: {
    objectConfig: GLTFLoader;
    isMobile: boolean;
    mobileHintElement?: Element;
  }): Observable<ObjectUpdate | undefined> {
    if (this._insertObjectMode) return of(undefined);
    this._insertObjectMode = true;
    this.selectedObject = undefined;
    if (payload.isMobile)
      this.matterportManager.setHint(payload.mobileHintElement, undefined);
    return this.loadGltf$({
      ...payload.objectConfig,
    }).pipe(
      tap(() => {
        this.matterportPositionController.setSweepsActive(false);
        this.enableCollision(payload.objectConfig.id, false);
        if (payload.isMobile) {
          this._mobileTouchObserver(
            this.matterportManager.componentRef.instance.progress.nativeElement
          );
        }
      }),
      switchMap((objectId: string) => {
        return race(
          this.matterportEvents.pointer$.pipe(
            map((pointer: Pointer | undefined) => {
              if (!pointer) return;
              return this._objectPositionUpdate({
                objectId,
                localPosition: pointer.position as Vector3,
                localNormal: pointer.normal as Vector3,
              });
            }),
            takeUntil(
              iif(
                () => payload.isMobile,
                this.matterportEvents.matterportHold$,
                this.matterportEvents.matterportClick$
              )
            ),
            last()
          ),
          merge(
            fromEvent<KeyboardEvent>(document, 'keydown').pipe(
              filter((event) => event.key === 'Escape')
            ),
            this._cancelAddingItem
          ).pipe(
            map(() => {
              throw new Error('Cancel');
            })
          )
        );
      }),
      tap(() => {
        this.enableCollision(payload.objectConfig.id);
        this.selectedObject = payload.objectConfig.id;
      }),

      catchError((e) => {
        this.deleteObject(payload.objectConfig.id);
        this.selectedObject = undefined;
        return of(undefined);
      }),
      take(1),

      finalize(() => {
        this.matterportPositionController.setSweepsActive(true);
        if (payload.isMobile) {
          this.matterportManager.setHint(undefined, undefined);
          this._mobileTouchDestroyer.next();
        }
        this._insertObjectMode = false;
      })
    );
  }
  private _objectPositionUpdate(payload: {
    objectId: string;
    localPosition: Vector3;
    localNormal?: Vector3;
    localRotation?: Euler;
  }): ObjectUpdate | undefined {
    const selectedObject = this._sceneObjects[payload.objectId];
    if (!selectedObject) return;
    if (!payload.localNormal) payload.localNormal = new Vector3(0, 0, 0);

    selectedObject.node.position.set(
      payload.localPosition.x,
      payload.localPosition.y,
      payload.localPosition.z
    );
    const { x, y, z } = getElementPosition(
      { ...payload.localPosition } as Vector3,
      { ...payload.localNormal } as Vector3
    );
    if (payload.localRotation) {
      selectedObject.node.quaternion.setFromEuler(payload.localRotation);
    } else {
      const position = new Vector3(x, y, z);
      selectedObject.node.obj3D.lookAt(position.x, position.y, position.z);
      selectedObject.node.obj3D.rotateX(Math.PI / 2);
    }

    return this._objectChange(payload.objectId);
  }

  private _objectChange(objectId: string | undefined): ObjectUpdate {
    if (!objectId)
      return { id: undefined, position: undefined, rotation: undefined };
    const selectedObject = this._sceneObjects[objectId];
    const { x, y, z } = new Euler().setFromQuaternion(
      selectedObject.node.quaternion
    );
    const position = selectedObject.node.position;

    return {
      position,
      rotation: { x, y, z } as Vector3,
      id: objectId,
    };
  }

  enableCollision(objectId: string, enable: boolean = true) {
    const selectedObject = this._sceneObjects[objectId];
    if (!selectedObject) return;
    const inputs = this._sceneObjects[objectId].component.inputs;
    if (!inputs) return;
    inputs['colliderEnabled'] = enable;
  }

  set selectedObject(objectId: string | undefined) {
    if (this._selectedObject === objectId) return;
    this._removeSelection();

    this._selectedObject = objectId;
    this._selectedObjectChange.next(this._objectChange(objectId));
  }
  private _removeSelection() {
    if (this._selectedObject) {
      this.hideTransform();
      const previousSelected = this._transformControls.component;
      if (previousSelected && 'transformControls' in previousSelected) {
        this._unregisterTransformEvent(previousSelected['transformControls']);
      }
    }
  }

  hideTransform() {
    const control = this._transformControls.component;
    const inputs: TransformControls = this._transformControls.component
      .inputs as TransformControls;
    inputs.selection = null;

    if (control && 'transformControls' in control) {
      (control['transformControls'] as TransformControls).visible = false;
    }
  }
  transformObject(objectId: string, transform: TransformMode) {
    if (!objectId) return;
    const control = this._transformControls.component;
    const inputs: TransformControls = this._transformControls.component
      .inputs as TransformControls;
    inputs.selection = this._sceneObjects[objectId].node as any;
    if (control && 'transformControls' in control) {
      (control['inputs'] as TransformControls).mode = transform;
      (control['transformControls'] as TransformControls).visible = true;
      this._registerTransformEvent(control['transformControls']);
    }
  }

  addTransformControls(
    controlsConfig: TransformControls = {},
    objectId: string
  ) {
    return firstValueFrom(this.addTransformControls$(controlsConfig, objectId));
  }

  addTransformControls$(
    controlsConfig: TransformControls = {},
    objectId: string
  ): Observable<Scene.IComponent | undefined> {
    return this._createNodeObject.pipe(
      map((node: Scene.INode | null) => {
        if (!node) return;
        this._transformControls.node = node as Scene.INode & INodeExtension;
        const component = node.addComponent(
          'mp.transformControls',
          controlsConfig as any,
          objectId
        );
        node.start();
        return component;
      })
    );
  }

  deleteObject(objectId: string) {
    if (this._sceneObjects[objectId]) {
      if (this._selectedObject === objectId) this.selectedObject = undefined; //remove component selection
      const scene = this._sceneObjects[objectId].component.context.scene;
      const sceneObj = this._sceneObjects[objectId].node.obj3D;
      disposeHierarchy(sceneObj, disposeNode);
      if (this._sceneObjects[objectId].badge)
        this.matterportComponents.deleteNote(objectId);
      if (sceneObj) {
        // setTimeout(() => {
        this._sceneObjects[objectId]?.node.stop();
        scene.remove(sceneObj);
        // }, 100); //Matterport has a problem when TransformControl component is on deleted object. I cant find any async function so I wait 300ms. Please change when find better solution
      }
    }
  }
  deleteAllObjects() {
    Object.keys(this._sceneObjects).forEach((objectId: string) => {
      this.deleteObject(objectId);
    });
  }

  private _registerTransformEvent(component: ThreeTransformControls) {
    component.addEventListener('dragging-changed', this._draggingEnd);
  }
  private _draggingEnd = (event: any) => {
    if (event['value'] === false) {
      this._selectedObjectChange.next(this._objectChange(this._selectedObject));
    }
  };
  private _unregisterTransformEvent(component: ThreeTransformControls) {
    component.removeEventListener('dragging-changed', this._draggingEnd);
  }
  private _registerClickEvent(comp: Scene.IComponent, objectId: string) {
    comp.notify = (eventType: ComponentInteractionType, eventData?: any) =>
      this._eventListener(eventType, eventData, objectId);
  }
  private _eventListener(
    eventType: ComponentInteractionType,
    eventData: any,
    objectId: string
  ): void {
    if (eventType === ComponentInteractionType.CLICK) {
      if (eventData) {
        this.selectedObject = objectId;
      }
    }
    if (eventType === ComponentInteractionType.HOVER) {
      this.matterportManager.componentRef.instance.pointerEvents(
        eventData?.hover || false
      );
    }
  }
  private _mobileTouchObserver(progress: HTMLElement) {
    const el = (
      document.getElementById('matterport') as HTMLIFrameElement
    )?.contentWindow?.document.getElementsByTagName('canvas')[0];

    // const progress = this.componentRef.instance.progress.nativeElement;
    const mouseUp$ = fromEvent(el as HTMLCanvasElement, 'touchend').pipe(
      tap(() => {
        this._renderer.setStyle(progress, 'display', 'none');
      })
    );
    const mouseMove$ = fromEvent(el as HTMLCanvasElement, 'touchmove').pipe(
      tap(() => {
        this._renderer.setStyle(progress, 'display', 'none');
      })
    );
    const mouseDown$ = fromEvent(el as HTMLCanvasElement, 'touchstart').pipe(
      switchMap((e: Event) =>
        timer(200).pipe(
          takeUntil(mouseUp$ || mouseMove$),
          mergeMap(() => of(e))
        )
      ),
      tap((e: Event) => {
        this._renderer.setStyle(progress, 'display', 'flex');
        this._renderer.setStyle(
          progress,
          'top',
          `${(e as TouchEvent).changedTouches[0].clientY - 120}px`
        );
        this._renderer.setStyle(
          progress,
          'left',
          `${(e as TouchEvent).changedTouches[0].clientX - 50}px`
        );
      })
    );
    mouseDown$
      .pipe(
        switchMap((down: Event) =>
          timer(1000).pipe(
            takeUntil(mouseUp$ || mouseMove$),
            mergeMap(() => of(down))
          )
        ),
        tap(() => {
          this._renderer.setStyle(progress, 'display', 'none');
        }),
        takeUntil(this._mobileTouchDestroyer)
      )
      .subscribe(() => {
        this.matterportEvents.emitValue();
      });
  }

  addDirectionalLight(lightConfig: DirectionalLightConfig = {}) {
    firstValueFrom(this.addDirectionalLight$(lightConfig));
  }
  addDirectionalLight$(lightConfig: DirectionalLightConfig = {}) {
    return this._createNodeObject.pipe(
      tap((node: Scene.INode | null) => {
        if (!node) return;
        this._lightNode = node;
        this._lightObject = this._lightNode.addComponent(
          'mp.directionalLight',
          lightConfig
        ) as LightComponent;

        this._lightNode.start();
        this._lightObject.light.shadow.mapSize.x = 2048;
        this._lightObject.light.shadow.mapSize.y = 2048;
        this._lightObject.light.shadow.camera.top = 15;
        this._lightObject.light.shadow.camera.bottom = -15;
        this._lightObject.light.shadow.camera.left = 15;
        this._lightObject.light.shadow.camera.right = -15;
      })
    );
  }

  addAmbientLight(lightConfig: AmbientLight = {}) {
    firstValueFrom(this.addAmbientLight$(lightConfig));
  }
  addAmbientLight$(lightConfig: AmbientLight = {}) {
    return this._createNodeObject.pipe(
      tap((node: Scene.INode | null) => {
        if (!node) return;
        node.addComponent('mp.ambientLight', lightConfig);
        node.start();
      })
    );
  }

  addPointLight(lightConfig: PointLight = {}) {
    firstValueFrom(this.addPointLight$(lightConfig));
  }
  addPointLight$(lightConfig: PointLight = {}) {
    return this._createNodeObject.pipe(
      tap((node: Scene.INode | null) => {
        if (!node) return;
        node.addComponent('mp.pointLight', lightConfig);
        node.start();
      })
    );
  }
}
