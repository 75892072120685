import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TAsset } from '@simOn/asset/modifying/models';
import { State } from '@simOn/utils';
import * as AssetReducer from './asset.reducers';

export const SelectAssetState = createFeatureSelector<State<TAsset>>('assets');

export const SelectAllAssets = createSelector(SelectAssetState, AssetReducer.selectAll);
export const AssetEntity = createSelector(SelectAssetState, (state) => state.entities);

export const GetAssetsByRoomId = (roomId: string) =>
  createSelector(SelectAllAssets, (assets) => {
    return assets.filter((asset) => asset.roomId === roomId);
  });
export const GetAssetById = (assetId: string) =>
  createSelector(SelectAssetState, (state) => {
    return state.entities[assetId];
  });

export const GetSelectedAsset = createSelector(SelectAssetState, (assets) => {
  return assets.selectedId ? assets.entities[assets.selectedId] : undefined;
});

export const GetSelectedId = createSelector(SelectAssetState, ({ selectedId }) => {
  return selectedId;
});
