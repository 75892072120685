import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { IPayPalConfig } from '../models/pay-pal.config';
import { PAYPAL_CLIENT_ID } from '../tokens/client-id.token';

export function providePayPalConfig(
  config: IPayPalConfig
): EnvironmentProviders {
  return makeEnvironmentProviders([
    { provide: PAYPAL_CLIENT_ID, useValue: config.clientId },
  ]);
}
