import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
  signal
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationService } from '@simOn/common/notification';
import { AttachmentSizesToken } from '@simOn/utils/tokens';
import { SupportedDocumentTypes, SupportedFileTypesInterface, SupportedImageTypes } from './supported-extensions.const';

const DESCRIPTIONS = {
  sizeGreaterThen: (name: string, size: number) =>
    $localize`:@@TOASTS_SIZE_GREATER_THEN_25MB:Size of '${name}' is greater than ${size}MB.`,

  notSupportedExtension: $localize`:@@TOASTS_UNSUPPORTED_EXTENSION:This extension is not supported`
};

let uniqueIdCounter = 0;
@Component({
  selector: 'common-upload-image',
  standalone: true,
  templateUrl: './sim-upload-image.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SimUploadImageComponent
    }
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[id]': 'id'
  }
})
export class SimUploadImageComponent implements OnInit, ControlValueAccessor {
  private readonly config = inject(AttachmentSizesToken);
  private notificationService = inject(NotificationService);
  private docMaxSize = this.config.docMaxSize;
  private imgMaxSize = this.config.imgMaxSize;
  readonly dragOver = signal<boolean>(false);
  @Input() id: string = `sim-upload-image-${uniqueIdCounter++}`;
  @Input() type: 'photo' | 'document' = 'photo';
  @Output() file = new EventEmitter<File>();
  @Output() uploadError = new EventEmitter<string>();
  @Output() fileLoaded = new EventEmitter<string | ArrayBuffer | null>();

  @Output() fileWithUrlLoaded = new EventEmitter<{ url: string | ArrayBuffer | null; file: File; key: string }>();
  @ViewChild('inputImage') inputImage!: ElementRef<HTMLInputElement>;

  private _disabled = false;
  @Input()
  public get uploadDisabled(): boolean {
    return this._disabled;
  }
  public set uploadDisabled(value: boolean) {
    this._disabled = value;
  }

  private _value: string | ArrayBuffer | null = null;
  public get value(): string | ArrayBuffer | null {
    return this._value;
  }
  public set value(value: string | ArrayBuffer | null) {
    this._value = value;
  }

  extensions: string | undefined = undefined;

  @HostListener('click')
  upload() {
    this.inputImage.nativeElement.click();
  }

  @HostListener('dragover', ['$event'])
  dropOver(event: Event) {
    this.stopPropagation(event);
  }

  @HostListener('dragleave', ['$event'])
  dragLeave(event: Event) {
    this.stopPropagation(event);
  }

  @HostListener('drop', ['$event'])
  dropUpload(event: DragEvent) {
    this.stopPropagation(event);
    if (!event.dataTransfer) return;
    Array.from(event.dataTransfer.files).forEach((file) => {
      this.processFile(file);
    });
    this.dragOver.set(false);
  }

  imageFromHtmlElement(inputImage: HTMLInputElement) {
    if (!inputImage?.files?.[0]) throw new Error('File not found');

    this.processFile(inputImage.files[0]);
  }

  bytesToMB = (bytes: number) => bytes / 1024 / 1024;

  processFile(value: File) {
    const file = new File([value], encodeURIComponent(value.name), {
      type: value.type
    });
    if (this.type === 'photo' || this.type === undefined) {
      const supportedMimeTypes = SupportedImageTypes.map((type: SupportedFileTypesInterface) => type.mimeType);

      if (!supportedMimeTypes.includes(file.type)) {
        this.uploadError.emit(DESCRIPTIONS.notSupportedExtension);
        this.inputImage.nativeElement.value = '';
        return;
      }
      if (file.size > this.imgMaxSize) {
        const sizeInMB = this.bytesToMB(this.imgMaxSize);
        this.notificationService.openErrorSnackBar(DESCRIPTIONS.sizeGreaterThen(file.name, sizeInMB));
        this.inputImage.nativeElement.value = '';
        return;
      }
    } else {
      const supportedMimeTypes = SupportedDocumentTypes.map((type: SupportedFileTypesInterface) => type.mimeType);
      if (!supportedMimeTypes.includes(file.type)) {
        this.uploadError.emit(DESCRIPTIONS.notSupportedExtension);
        this.inputImage.nativeElement.value = '';
        return;
      }

      if (file.size > this.docMaxSize) {
        const sizeInMB = this.bytesToMB(this.docMaxSize);

        this.notificationService.openErrorSnackBar(DESCRIPTIONS.sizeGreaterThen(file.name, sizeInMB));
        this.inputImage.nativeElement.value = '';
        return;
      }
    }

    const reader = new FileReader();
    this.file.emit(file);
    reader.addEventListener('load', (event: ProgressEvent) => {
      this._value = (event.target as FileReader).result;
      this.onChange(this._value);
      this._value && this.fileLoaded.emit(this._value);
      this.fileWithUrlLoaded.emit({ file: file, url: this._value, key: '' });
      this.inputImage.nativeElement.value = '';
    });

    reader.readAsDataURL(file);
  }

  onTouch = () => {};
  onChange: (value: any) => void = () => {};

  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  stopPropagation(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit(): void {
    if (this.type === 'photo' || this.type === undefined) {
      this.extensions = SupportedImageTypes.map((type: SupportedFileTypesInterface) => type.fileExtension).join();
    } else {
      const allowedExtensions = ['.pdf', '.xls', '.xlsx', '.xlsm', '.doc', '.docx', '.pptx', '.txt'];
      this.extensions = SupportedDocumentTypes.filter((item: SupportedFileTypesInterface) =>
        allowedExtensions.includes(item.fileExtension)
      )
        .map((type: SupportedFileTypesInterface) => type.fileExtension)
        .join();
    }
  }

  getFileType(file: File): string {
    if (file.type) {
      return file.type;
    }
    const ext = file.name.split('.');
    return '.' + ext[ext.length - 1];
  }
}
