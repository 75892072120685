import { Injectable, OnDestroy } from '@angular/core';
import { Vector } from '@simlab/matterport/transform';

import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  Observable,
  take,
} from 'rxjs';
import { Box3 } from 'three';
import { IDisposable, MpSdk } from '../../../assets/bundle/sdk';
import { MatterportServiceBase } from '../base/matterport-base';
import {
  CustomScene,
  customSceneType,
  makeCustomSceneRenderer,
} from '../base/scene-hdr';
import { ISceneState } from '../models/scene-state.interface';
import { MatterportManagerService } from './matterport-manager.service';
@Injectable()
export class MatterportSceneStateAccessService
  extends MatterportServiceBase
  implements OnDestroy, ISceneState
{
  private _currentSweepSubscription: MpSdk.ISubscription | undefined;
  private _availableSweepsSubscription: MpSdk.ISubscription | undefined;
  private _currentPoseSubscription: MpSdk.ISubscription | undefined;
  private _sceneComponent: BehaviorSubject<CustomScene | undefined> =
    new BehaviorSubject<CustomScene | undefined>(undefined);
  private _nodeRef!: MpSdk.Scene.INode;
  private readonly _currentSweep: BehaviorSubject<MpSdk.Sweep.ObservableSweepData | null> =
    new BehaviorSubject<MpSdk.Sweep.ObservableSweepData | null>(null);
  private readonly _currentPose: BehaviorSubject<MpSdk.Camera.Pose | null> =
    new BehaviorSubject<MpSdk.Camera.Pose | null>(null);
  private readonly _availableSweeps: BehaviorSubject<
    MpSdk.Sweep.ObservableSweepData[] | null
  > = new BehaviorSubject<MpSdk.Sweep.ObservableSweepData[] | null>(null);
  readonly sweepChange$ = this._currentSweep.asObservable();
  readonly positionChange$ = this._currentPose.asObservable();
  readonly availableSweeps$ = this._availableSweeps.asObservable();
  private _component: IDisposable | null | undefined;

  constructor(private readonly matterportManager: MatterportManagerService) {
    super(matterportManager);
  }

  override ngOnDestroy(): void {
    this._clear();
    this._nodeRef?.stop();
    this._sceneComponent.next(undefined);

    // console.log('MatterportSceneStateAccessService - destory');
    super.ngOnDestroy();
  }
  protected async _init() {
    this._clear();
    this._currentSweepSubscription = this.sdk.Sweep.current.subscribe(
      (currentSweep: MpSdk.Sweep.ObservableSweepData) => {
        this._currentSweep.next(currentSweep);
      }
    );

    this._currentPoseSubscription = this.sdk.Camera.pose.subscribe(
      (currentPose: MpSdk.Camera.Pose) => {
        this._currentPose.next(currentPose);
      }
    );

    this._availableSweepsSubscription = this.sdk.Sweep.data.subscribe({
      onCollectionUpdated: (
        sweepsCollection: MpSdk.Dictionary<MpSdk.Sweep.ObservableSweepData>
      ) => {
        this._availableSweeps.next(
          Array.from(Object.entries(sweepsCollection), (v) => v[1])
        );
      },
    });

    this._component = await firstValueFrom(
      this.registerComponents$(customSceneType, makeCustomSceneRenderer)
    ).catch(() => null);
    if (!this._component) return;
    this._registerHDR();
  }

  getAvailableSweeps$(): Observable<MpSdk.Sweep.ObservableSweepData[] | null> {
    return this.availableSweeps$.pipe(
      filter((v) => !!v),
      take(1)
    );
  }

  lastKnownCameraPose(cameraPose: MpSdk.Camera.Pose): MpSdk.Camera.Pose {
    const transformConverter = this.matterportManager.transformConverter;

    const cameraPoseWithOffset: MpSdk.Camera.Pose = {
      ...cameraPose,
      position: transformConverter.to3dPosition(
        new Vector(
          cameraPose.position.x,
          cameraPose.position.y,
          cameraPose.position.z
        )
      ),
      rotation: transformConverter.to3dRotation(
        new Vector(cameraPose.rotation.x, cameraPose.rotation.y)
      ),
    };
    return cameraPoseWithOffset;
  }

  private _clear() {
    if (
      this._currentSweepSubscription &&
      Object.keys(this._currentSweepSubscription).length == 0
    )
      this._currentSweepSubscription.cancel();

    if (
      this._currentPoseSubscription &&
      Object.keys(this._currentPoseSubscription).length == 0
    )
      this._currentPoseSubscription.cancel();

    if (this._availableSweepsSubscription)
      this._availableSweepsSubscription.cancel();
  }

  private _registerHDR() {
    this.sdk.Scene.createObjects(1).then(([object]: MpSdk.Scene.IObject[]) => {
      this._nodeRef = object.addNode();
      const component = this._nodeRef.addComponent(
        customSceneType,
        {}
      ) as CustomScene;
      this._nodeRef.start();
      this._sceneComponent.next(component);
    });
  }

  renderMesh(shadowBox: Box3) {
    firstValueFrom(
      this._sceneComponent
        .asObservable()
        .pipe(filter((customScene) => !!customScene))
    ).then((customScene) => {
      (customScene as CustomScene).renderMesh(shadowBox);
    });
  }
}
