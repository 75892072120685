import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { UsersApiService } from '@simOn/user/preferences/data-access';
import { DEFAULT_USER_PREFERENCES, UserApartmentPreferencesInterface } from '@simOn/user/preferences/model';
import {
  GetUserPreferences,
  GetUserPreferencesFailure,
  GetUserPreferencesSuccess,
  UpdateUserPreferences,
  UpdateUserPreferencesSuccess
} from './user-preferences.actions';

@Injectable()
export class UserPreferencesEffect {
  private readonly actions$ = inject(Actions);
  private readonly usersApiService = inject(UsersApiService);
  getUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetUserPreferences),
      mergeMap(() =>
        this.usersApiService.getUserPreferences().pipe(
          map((userPreferences: UserApartmentPreferencesInterface) => {
            return userPreferences || DEFAULT_USER_PREFERENCES;
          }),
          map((userPreferences: UserApartmentPreferencesInterface) => GetUserPreferencesSuccess({ userPreferences })),
          catchError((error) => of(GetUserPreferencesFailure({ error })))
        )
      )
    )
  );

  updateUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateUserPreferences),
      mergeMap((preference) =>
        this.usersApiService.updateUserPreferences(preference.userPreferences).pipe(map(() => preference))
      ),
      map((preference) => UpdateUserPreferencesSuccess(preference)),
      catchError((error) => of(GetUserPreferencesFailure({ error })))
    )
  );
}
