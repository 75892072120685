import { Injectable, OnDestroy } from '@angular/core';

import { Camera, Mode, MpSdk } from 'mpSdk';
import {
  Observable,
  catchError,
  defer,
  filter,
  from,
  of,
  switchMap,
  take,
} from 'rxjs';
import { MatterportServiceBase } from '../base/matterport-base';
import { TDollhouseManager } from '../models/dollhouse.model';
@Injectable()
export class DollhouseManagerService
  extends MatterportServiceBase
  implements OnDestroy, TDollhouseManager
{
  private _modeCurrent: MpSdk.Mode.Mode | null = null;
  private _currentFloor: MpSdk.Floor.ObservableFloorData | null = null;
  public get currentFloor(): MpSdk.Floor.ObservableFloorData | null {
    return this._currentFloor;
  }
  public set currentFloor(value: MpSdk.Floor.ObservableFloorData | null) {
    this._currentFloor = value;
  }

  readonly currentMode$: Observable<MpSdk.Mode.Mode | null> = defer(() =>
    this.isOpen.pipe(
      filter((isOpen) => isOpen),
      switchMap(
        () =>
          new Observable<MpSdk.Mode.Mode | null>((subscriber) => {
            const floorSubRef = this.sdk.Mode.transition.subscribe(
              (e: MpSdk.Mode.TransitionData) => {
                subscriber.next(e.to);
                this._modeCurrent = e.to;
              }
            );
            return function unsubscribe() {
              floorSubRef.cancel();
            };
          })
      )
    )
  );
  readonly currentFloor$: Observable<number | undefined> = defer(() =>
    this.isOpen.pipe(
      filter((isOpen) => isOpen),
      switchMap(
        () =>
          new Observable<number | undefined>((subscriber) => {
            const floorSubRef = this.sdk.Floor.current.subscribe(
              (e: MpSdk.Floor.ObservableFloorData) => {
                e.sequence !== undefined && subscriber.next(e.sequence);
                this._currentFloor = e;
              }
            );
            return function unsubscribe() {
              floorSubRef.cancel();
            };
          })
      )
    )
  );

  protected _init(): void {}

  changeMode$(mode: Mode.Mode): Observable<Mode.Mode | undefined> {
    if (!this.sdk) throw new Error('Sdk not loaded');

    return this.currentFloor$.pipe(
      filter((currentFloor: number | undefined) => currentFloor !== undefined),
      take(1),
      switchMap(() => this.currentMode$),
      filter((currentMode: Mode.Mode | null) => currentMode === null),
      take(1),
      switchMap(() =>
        from(this.sdk.Mode.moveTo(mode)).pipe(
          take(1),
          catchError((e) => {
            return of(undefined);
          })
        )
      )
    );
  }

  changeFloor$(mode: number): Observable<number | void | undefined> {
    if (!this.sdk) throw new Error('Sdk not loaded');

    return this.currentFloor$.pipe(
      filter((currentFloor: number | undefined) => currentFloor !== undefined),
      take(1),
      switchMap(() =>
        mode === -1
          ? from(this.sdk.Floor.showAll())
          : from(this.sdk.Floor.moveTo(mode))
      ),
      take(1),
      catchError((e) => {
        console.log(e);
        return of(undefined);
      })
    );
  }

  moveToPose$(pose: Camera.Pose): Observable<Mode.Mode | undefined> {
    if (!this.sdk) throw new Error('Sdk not loaded');
    if (
      this._currentFloor?.sequence === undefined ||
      this._modeCurrent !== null
    ) {
      return of(undefined);
    }

    return from(
      this.sdk.Mode.moveTo(pose.mode, {
        position: pose.position,
        rotation: pose.rotation,
        transition: this.sdk.Mode.TransitionType.INSTANT,
      })
    ).pipe(
      take(1),
      catchError((e) => {
        console.log(e);
        return of(undefined);
      })
    );
  }
}
