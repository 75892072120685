import { Inject, Injectable, Optional } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  exhaustMap,
  from,
  Observable,
  of,
  Subject,
  tap,
  throwError,
} from 'rxjs';
import { Endpoints } from '../models/configuration';
import { ErrorCode, Languages, LOCALIZATION } from '../models/localization';
import { PayUSdk } from '../models/payu';
import { RecurringPaymentResponse } from '../models/recurring-payment-response';
import { ScriptLoadResponse } from '../models/script-load-response';
import {
  SecureFormChangeResponse,
  ControlName,
  SecureFormValueChange,
} from '../models/secure-form-change-response';
import { SecureFormOptions } from '../models/secure-form-options';
import {
  TokenizeResultSuccess,
  TokenizeResultError,
} from '../models/tokenize-result';
import { ENDPOINTS } from '../tokens/endpoints.token';
import { POS_ID } from '../tokens/pos-id.token';
import { PAYU_SDK_URL } from '../tokens/url.token';
import { LoadPayUSdkService } from './load-pay-usdk.service';
import { PaymentApiService } from './payment-api.service';

@Injectable()
export class BasePaymentsService {
  private _payUSdk!: PayUSdk;
  private readonly _loaded: BehaviorSubject<ScriptLoadResponse> =
    new BehaviorSubject<ScriptLoadResponse>({
      loaded: false,
      message: '',
      error: false,
    });
  private readonly _tokenize: Subject<
    TokenizeResultSuccess | TokenizeResultError
  > = new Subject<TokenizeResultSuccess | TokenizeResultError>();
  private readonly _formChanges: Subject<SecureFormValueChange> =
    new Subject<SecureFormValueChange>();
  private readonly _loading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private _secureForms!: payu.SecureForms | null;
  private _options: SecureFormOptions = {
    cardIcon: true,
    style: {
      basic: {
        fontSize: '14px',
      },
    },
    placeholder: {
      number: '',
      date: 'MM/YY',
      cvv: '',
    },
    lang: 'en',
  };

  private _tokenizeFn!:(value: any) => Promise<any>
  private _saveCardEndpoint: string | undefined;
  private _posId!: string;

  constructor(
    private readonly _sdkLoaderService: LoadPayUSdkService,
    @Optional() @Inject(ENDPOINTS) private readonly _endpoints: Endpoints,
    // @Inject(POS_ID) private readonly _posId: string,
    @Inject(PAYU_SDK_URL) private  readonly _sdkUrl:string,
    private readonly _paymentApi: PaymentApiService
  ) {
    this._saveCardEndpoint = _endpoints?.saveCard

  }
  public get posId(): string {
    return this._posId;
  }
  public set posId(value: string) {
    this._posId = value;
    this._loadSdk(this._sdkUrl,this._posId);
  }
  public _loadingStatusChange(status: ScriptLoadResponse) {
    this._loaded.next(status);
  }
  public get options(): SecureFormOptions {
    return this._options;
  }
  public set options(value: SecureFormOptions) {
    this._options = value;
  }
  public set payUSdk(value: PayUSdk) {
    this._payUSdk = value;
  }
  public get payUSdk(): PayUSdk {
    return this._payUSdk;
  }
  public get loadingStatus(): BehaviorSubject<ScriptLoadResponse> {
    return this._loaded;
  }

  public get secureForms(): payu.SecureForms{
    if(!this._secureForms)this._secureForms =  this._payUSdk.secureForms()
    return this._secureForms
  }

  destroyForms(){
    this._secureForms
  }

  public get tokenize(): Subject<TokenizeResultSuccess | TokenizeResultError> {
    return this._tokenize;
  }
  public get formChanges(): Subject<SecureFormValueChange> {
    return this._formChanges;
  }
  public get loading(): BehaviorSubject<boolean> {
    return this._loading;
  }

  private _loadSdk(sdkUrl:string,posId:string):Promise<ScriptLoadResponse>{
    return new Promise((resolve, reject)=>{
      this._sdkLoaderService
      .loadPayUSdk(sdkUrl)
      .then(() => {
        this._payUSdk = PayU(posId,{dev:true});
        this.registerTestTokenizeFn(this._payUSdk.tokenize)
        this._loadingStatusChange({error:false,loaded:true, message:'Payu SDK loaded'})
        resolve({error:false,loaded:true, message:'Payu SDK loaded'})
      })
      .catch((error) => {
        console.log(error);
        this._loadingStatusChange({error:true,message: JSON.stringify(error),loaded:false})
        reject(error)
      });
    })
  }

  message = (error:ErrorCode) => LOCALIZATION[this._options.lang ?? 'en'][error];

  submitFormWithoutSave(): Promise<
    TokenizeResultSuccess | TokenizeResultError
  > {

    return new Promise((resolve, rejects) => {
      if (this.allComplete()) {
      this._loading.next(true);

      this._tokenizeFn('MULTI')
        .then((result: TokenizeResultSuccess | TokenizeResultError) => {
          this._tokenize.next(result);
          resolve(result);
        })
        .catch((error: TokenizeResultError) => {
          this._tokenize.next(error);
          rejects(error);
        })
        .finally(() => this._loading.next(false));
      }else {
        rejects({
          status: 'ERROR',
          error:{
            messages: [{
             code:'error.tokenization',
             message:this.message(ErrorCode.NOT_LOADED),
             type:'technical',
             source:'card',
             parameters:{}
            }]
          }
        } as TokenizeResultError)
      }
    });
  }

  private _saveCard$(payload:Record<string, string | number> & {cardToken:string}): Observable<RecurringPaymentResponse> {
    if(this._saveCardEndpoint){
      return this._paymentApi.createRecurringPayUPaymentMethod(payload);
    }else{
      return throwError(() => ( { error:'Endpoints is not sets. Please set configuration of all endpoints in NgModule definition'}));
    }
  }

  submitFormWithoutSave$(): Observable<
    TokenizeResultSuccess | TokenizeResultError
  > {
    return from(this.submitFormWithoutSave())

  }

  allComplete = ():boolean => !this._loading.getValue() && this._loaded.getValue().loaded;

  submitForm$(payload:Record<string, string | number>): Observable<RecurringPaymentResponse | TokenizeResultError> {
    if (this.allComplete()) {
      return this.submitFormWithoutSave$().pipe(
        exhaustMap((response: TokenizeResultSuccess | TokenizeResultError) => {
          if (response.status === 'SUCCESS') {
            const mergedPayload = {...payload, ...{cardToken:response.body.token}};
            return this._saveCard$(mergedPayload);
          } else {
            return throwError(() => response);
          }
        }),
        tap(() => this._loading.next(false)),
        catchError((error: any) => {
          this._loading.next(false);
          return throwError(() => error);
        })
      );
    }else {
      return of({
        status: 'ERROR',
        error:{
          messages: [{
           code:'error.tokenization',
           message:this.message(ErrorCode.NOT_LOADED),
           type:'technical',
           source:'card',
           parameters:{}
          }]
        }
      } as TokenizeResultError)
    }
  }

  registerTestTokenizeFn(fn: (value: any) => Promise<any>){
    this._tokenizeFn = fn;
  }

  formValueChange(
    response: SecureFormChangeResponse,
    controlName: ControlName
  ): void {
    this._formChanges.next({ controlName, response } as SecureFormValueChange);
  }
}
