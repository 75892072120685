import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { CanColor, mixinColor } from '@angular/material/core';
import { Subject, fromEvent, switchMap, takeUntil, timer } from 'rxjs';
// Boilerplate for applying mixins to UiButton.
const _UiTooltipBase = mixinColor(
  class {
    constructor(public _elementRef: ElementRef) {}
  }
);

export type UiTooltipPosition = 'top' | 'right' | 'bottom' | 'left';

@Component({
  selector: 'sim-tooltip',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  templateUrl: './sim-tooltip.component.html',
  styleUrls: ['./sim-tooltip.component.scss'],
  host: {
    class: 'ui-tooltip ui-tooltip-position-bottom',
    '[attr.tabindex]': '0 ',
    '(mouseenter)': 'mouseEnter()',
    '(mouseleave)': 'mouseOut()'
  },
  inputs: ['color'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimTooltipComponent extends _UiTooltipBase implements CanColor, AfterViewInit, OnDestroy {
  public readonly elementRef = inject(ElementRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private _timerClose: NodeJS.Timeout | undefined;
  private _timerOpen: NodeJS.Timeout | undefined;
  private _isOpen = false;
  private _delayedClosure = false;
  public get delayedClosure() {
    return this._delayedClosure;
  }
  @Input()
  public set delayedClosure(value: BooleanInput) {
    this._delayedClosure = coerceBooleanProperty(value);
  }

  private _openTime = 0;
  public get openTime() {
    return this._openTime;
  }
  @Input()
  public set openTime(value: number) {
    this._openTime = value;
  }

  @ViewChild('tooltip') eleTooltip!: ElementRef<HTMLDivElement>;
  public get isOpen() {
    return this._isOpen;
  }
  public set isOpen(value) {
    this._isOpen = value;
  }
  @Input()
  set tooltip(value: string | TemplateRef<any>) {
    this._hasEmbeddedView = value instanceof TemplateRef;
    this._uiTooltip = value;
  }
  /**@deprecated  Please set the padding on the outer element.
   * The default padding on the tooltip will be removed */
  @Input() noPadding = false;
  @Input() setPositionStrategy: ConnectedPosition[] = [];

  get text(): string {
    return <string>this._uiTooltip;
  }

  get template(): TemplateRef<any> {
    return <TemplateRef<any>>this._uiTooltip;
  }

  private _uiTooltip!: string | TemplateRef<any>;

  get hasEmbeddedView(): boolean {
    return this._hasEmbeddedView;
  }
  private _hasEmbeddedView: boolean = false;

  @Input() openOnClick = false;

  @Input()
  set position(value: UiTooltipPosition) {
    const cachedPosition = value;

    if (cachedPosition !== this._position) {
      if (this._position) {
        this._elementRef.nativeElement.classList.remove(`ui-tooltip-position-${this._position}`);
      }

      if (cachedPosition) {
        this._elementRef.nativeElement.classList.add(`ui-tooltip-position-${cachedPosition}`);
      }

      this._position = cachedPosition;
    }
  }
  _position: UiTooltipPosition = 'bottom';

  set focused(value: boolean) {
    if (value !== this._focused) {
      this._focused = value;
      this.changeDetectorRef.markForCheck();
    }
  }

  _focused: boolean = false;

  constructor() {
    const elementRef = inject(ElementRef);
    super(elementRef);
  }
  ngAfterViewInit(): void {
    fromEvent(this.elementRef.nativeElement, 'touchstart')
      .pipe(takeUntil(this._destroySource))
      .subscribe(() => {
        this.isOpen = true;
        this.changeDetectorRef.markForCheck();
      });

    if (!this.delayedClosure)
      fromEvent(this.elementRef.nativeElement, 'touchend')
        .pipe(
          takeUntil(this._destroySource),
          switchMap(() => timer(2000))
        )
        .subscribe(() => {
          this.isOpen = false;
          this.changeDetectorRef.markForCheck();
        });
  }

  ngOnDestroy(): void {
    this._timerOpen = undefined;
    this._timerClose = undefined;
    this._destroySource.next();
    this._destroySource.complete();
  }

  outsideClick() {
    this.close();
  }

  stopClosing() {
    clearTimeout(this._timerClose);
  }

  mouseEnter() {
    if (this.isOpen || this.openOnClick) return;
    clearTimeout(this._timerClose);
    this._timerOpen = setTimeout(() => {
      this.open();
    }, this.openTime);
  }

  mouseOut() {
    if (this.isOpen || !this._timerOpen) {
      clearTimeout(this._timerOpen);
      if (!this.delayedClosure) this.close();

      this._timerClose = setTimeout(() => {
        this.close();
      }, 100);
    }
  }

  open() {
    this.isOpen = true;
    this.changeDetectorRef.markForCheck();
  }

  close(): void {
    this._timerOpen = undefined;
    this._timerClose = undefined;
    this.isOpen = false;
    this.changeDetectorRef.markForCheck();
  }
}
