import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DeviceProperties } from '@simOn/device/models';
import { State } from '@simOn/utils';
import * as fromDeviceProperty from './device-property.reducer';

export const selectDevicePropertyState = createFeatureSelector<State<DeviceProperties>>(
  fromDeviceProperty.devicePropertyFeatureKey
);
export const selectDevicesPropertiesEntities = createSelector(
  selectDevicePropertyState,
  fromDeviceProperty.selectEntities
);
export const LoadedPropertiesState = createSelector(selectDevicePropertyState, (state) => state.isLoaded);
export const selectAllDevicesProperty = createSelector(selectDevicePropertyState, fromDeviceProperty.selectAll);
export const selectDevicePropertyById = (deviceId: string) =>
  createSelector(selectDevicesPropertiesEntities, (state) => {
    return state[deviceId];
  });
