import { StateDefault } from '@simOn/utils';

export enum GeneralPrivilegeType {
  CanEditApartmentInfo = 1,
  CanViewProviders = 2,
  CanEditProviders = 3,
  CanCreateRoomsAndUsersAndRoles = 4,
  CanImportDevices = 5,
  CanCreateScenes = 6,
  CanViewApartmentEvents = 7,
  CanCreateTickets = 8,
  CanManageInteriorDesignerEditor = 9,
  CanManageMaximoLinks = 10
}

export enum ScenePrivilegeType {
  AccessRevoked = 1,
  CanView = 2,
  CanStart = 3,
  CanEdit = 4
}

export enum TicketPrivilegeType {
  AccessRevoked = 1,
  CanView = 2,
  CanComment = 3,
  CanEdit = 4
}

export enum RoomPrivilegeType {
  AccessRevoked = 1,
  CanView = 2,
  CanEdit = 3,
  CanCreateAssets = 4,
  CanCreateDevices = 5
}

export enum DevicePrivilegeType {
  AccessRevoked = 1,
  CanView = 2,
  CanControl = 3,
  CanEdit = 4
}
export enum AssetPrivilegeType {
  AccessRevoked = 1,
  CanView = 2,
  CanEdit = 3
}

export type DevicePrivilegeTypeStringify = keyof typeof DevicePrivilegeType;
export type RoomPrivilegeTypeStringify = keyof typeof RoomPrivilegeType;
export type TicketPrivilegeTypeStringify = keyof typeof TicketPrivilegeType;
export type ScenePrivilegeTypeStringify = keyof typeof ScenePrivilegeType;
export type GeneralPrivilegeTypeStringify = keyof typeof GeneralPrivilegeType;
export type AssetPrivilegeTypeStringify = keyof typeof AssetPrivilegeType;

export type PrivilegeTypes = 'room' | 'scene' | 'ticket' | 'device' | 'asset';

export type AvailablePrivileges = {
  room: RoomPrivilegeTypeStringify;
  scene: ScenePrivilegeTypeStringify;
  ticket: TicketPrivilegeTypeStringify;
  device: DevicePrivilegeTypeStringify;
  asset: AssetPrivilegeTypeStringify;
};

export type PrivilegesStoreDefault = {
  isOwner: boolean;
  userGuid: string;
  general: GeneralPrivilegeTypeStringify[];
};

export type PrivilegesByType = {
  [Property in PrivilegeTypes]: Privileges<AvailablePrivileges[Property] | undefined>;
};

export type UserFullAccessMapped = PrivilegesByType & PrivilegesStoreDefault;
export type PrivilegesStore = UserFullAccessMapped & StateDefault;

export interface AccessBase {
  roomAccesses: RoomAccess[];
  defaultRoomPrivileges: RoomPrivilegeTypeStringify[];
  sceneAccesses: SceneAccess[];
  defaultScenePrivileges: ScenePrivilegeTypeStringify[];
  ticketAccesses: TicketAccess[];
  defaultTicketPrivileges: TicketPrivilegeTypeStringify[];
}
export interface UserFullAccess extends AccessBase {
  userGuid: string;
  isOwner: boolean;
  generalPrivileges: GeneralPrivilegeTypeStringify[];
}
export interface RoleFullAccess extends AccessBase {
  roleGuid?: string;
  roleName?: string;
  apartmentGuid: string;
  generalRolePrivileges: GeneralPrivilegeTypeStringify[];
}

export interface RoomAccess {
  roomName?: string;
  roomGuid: string;
  isMasterRoom?: boolean;
  roomPrivileges: RoomPrivilegeTypeStringify[];
  deviceAccesses: DeviceAccess[];
  assetAccesses: AssetAccess[];
  defaultDevicePrivileges: DevicePrivilegeTypeStringify[];
  defaultAssetPrivileges: AssetPrivilegeTypeStringify[];
}

export interface DeviceAccess {
  deviceName?: string;
  deviceGuid: string;
  privileges: DevicePrivilegeTypeStringify[];
}

export interface AssetAccess {
  assetName?: string;
  assetGuid: string;
  privileges: AssetPrivilegeTypeStringify[];
}

export interface SceneAccess {
  sceneName?: string;
  sceneGuid: string;
  privileges: ScenePrivilegeTypeStringify[];
}

export interface TicketAccess {
  ticketName?: string;
  ticketGuid: string;
  privileges: TicketPrivilegeTypeStringify[];
}

export interface PrivilegesDefault<T> {
  [guid: string]: {
    privileges: T[];
    guid: string;
  } & (T extends RoomPrivilegeTypeStringify
    ? {
        defaultDevicePrivileges: DevicePrivilegeTypeStringify[];
        deviceIds: string[];
        defaultAssetPrivileges: AssetPrivilegeTypeStringify[];
        assetIds: string[];
      }
    : {});
}

export interface Privileges<T> {
  default: T[];
  access: PrivilegesDefault<T>;
}

export function privilegesTransformationToNested(
  access: UserFullAccessMapped,
  roleDetails: RoleFullAccess
): RoleFullAccess {
  const privileges: RoleFullAccess = {
    apartmentGuid: roleDetails.apartmentGuid,
    roleGuid: roleDetails.roleGuid || access.userGuid,
    generalRolePrivileges: access.general,
    roomAccesses: Object.entries(access.room.access).map((roomEntry: any) => ({
      roomGuid: roomEntry[0],
      roomPrivileges: roomEntry[1].privileges,
      defaultAssetPrivileges: roomEntry[1].defaultAssetPrivileges,
      defaultDevicePrivileges: roomEntry[1].defaultDevicePrivileges,
      assetAccesses: roomEntry[1].assetIds.map(
        (assetId: string) =>
          ({
            assetGuid: access.asset.access[assetId].guid,
            privileges: access.asset.access[assetId].privileges
          } as AssetAccess)
      ),
      deviceAccesses: roomEntry[1].deviceIds.map(
        (deviceId: string) =>
          ({
            deviceGuid: access.device.access[deviceId].guid,
            privileges: access.device.access[deviceId].privileges
          } as DeviceAccess)
      )
    })),
    defaultRoomPrivileges: access.room.default as RoomPrivilegeTypeStringify[],
    sceneAccesses: Object.entries(access.scene.access).map((sceneEntry) => ({
      sceneGuid: sceneEntry[0],
      privileges: sceneEntry[1].privileges
    })) as SceneAccess[],
    defaultScenePrivileges: access.scene.default as ScenePrivilegeTypeStringify[],
    ticketAccesses: Object.entries(access.ticket.access).map((ticketEntry) => ({
      ticketGuid: ticketEntry[0],
      privileges: ticketEntry[1].privileges
    })) as TicketAccess[],
    defaultTicketPrivileges: access.ticket.default as TicketPrivilegeTypeStringify[]
  };
  return privileges as RoleFullAccess;
}
export function privilegesTransformationToFlat(access: UserFullAccess): UserFullAccessMapped {
  const room: Privileges<AvailablePrivileges['room']> = {
    default: access.defaultRoomPrivileges,
    access: access.roomAccesses.reduce(
      (a: PrivilegesDefault<RoomPrivilegeTypeStringify>, v) => ({
        ...a,
        [v.roomGuid]: {
          guid: v.roomGuid,
          privileges: v.roomPrivileges,
          defaultAssetPrivileges: v.defaultAssetPrivileges,
          defaultDevicePrivileges: v.defaultDevicePrivileges,
          assetIds: [v.assetAccesses.map((assetAccess) => assetAccess.assetGuid)].flat(),
          deviceIds: [v.deviceAccesses.map((deviceAccess) => deviceAccess.deviceGuid)].flat()
        }
      }),
      {}
    )
  };
  const asset: Privileges<AvailablePrivileges['asset']> = {
    default: [],
    access: access.roomAccesses
      .map((roomAccess) => roomAccess.assetAccesses)
      .flat()
      .reduce((a, v) => ({ ...a, [v.assetGuid]: { guid: v.assetGuid, privileges: v.privileges } }), {})
  };
  const device: Privileges<AvailablePrivileges['device']> = {
    default: [],
    access: access.roomAccesses
      .map((roomAccess) => roomAccess.deviceAccesses)
      .flat()
      .reduce((a, v) => ({ ...a, [v.deviceGuid]: { guid: v.deviceGuid, privileges: v.privileges } }), {})
  };
  const ticket: Privileges<AvailablePrivileges['ticket']> = {
    default: access.defaultTicketPrivileges || [],
    access: access.ticketAccesses.reduce(
      (a, v) => ({ ...a, [v.ticketGuid]: { guid: v.ticketGuid, privileges: v.privileges } }),
      {}
    )
  };
  const scene: Privileges<AvailablePrivileges['scene']> = {
    default: access.defaultScenePrivileges || [],
    access: access.sceneAccesses.reduce(
      (a, v) => ({ ...a, [v.sceneGuid]: { guid: v.sceneGuid, privileges: v.privileges } }),
      {}
    )
  };
  return {
    asset,
    device,
    room,
    scene,
    ticket,
    general: access.generalPrivileges,
    isOwner: access.isOwner,
    userGuid: access.userGuid
  };
}
