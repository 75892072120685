import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Observable } from 'rxjs';
import {
  GetModelTagsQuery,
  GetViewsQuery,
  ModelSort,
  ModelsQuery,
  ModelSweep,
} from '../models/graph_queries';
import { AuthorizationResponse, ModelAccess } from '../models/matterport-api';
import {
  MatterportAuthorization,
  MatterportAuthorizationResponse,
} from '../models/matterport-authorization';
import { AuthorizationService } from './authorization.service';
import { MatterportApiService } from './matterport-api.service';

@Injectable()
export class MatterportApiFacadeService {
  constructor(
    private readonly authorizationService: AuthorizationService,
    private readonly apiService: MatterportApiService
  ) {}
  readonly authorization$ = (
    authorizationUrl?: string
  ): Observable<
    MatterportAuthorizationResponse<MatterportAuthorization> | undefined
  > => this.authorizationService.openAuthorizationWindow$(authorizationUrl);
  // readonly refreshToken$ = () => this.apiService.refreshToken$();
  readonly getOauthToken$ = (code: string): Observable<AuthorizationResponse> =>
    this.authorizationService.getOauthToken$(code);

  readonly getModels$ = (
    query?: string,
    offset?: string,
    sortBy?: ModelSort
  ): Observable<ApolloQueryResult<ModelsQuery>> =>
    this.apiService.getModels$(query, offset, sortBy);

  readonly getModelSweeps$ = (id: string): Observable<Array<ModelSweep>> =>
    this.apiService.getModelSweeps$(id);
  readonly clearCache$ = () => this.apiService.clearCache$();
  readonly getTags$ = (
    id: string
  ): Observable<ApolloQueryResult<GetModelTagsQuery>> =>
    this.apiService.getTags$(id);
  readonly getModelViews$ = (
    id: string
  ): Observable<ApolloQueryResult<GetViewsQuery>> =>
    this.apiService.getModelViews$(id);
  readonly getScanVisibility$ = (id: string) =>
    this.apiService.getScanVisibility$(id);
  readonly sendWindowMessageToParent = (code: string): void =>
    this.authorizationService.sendWindowMessage(code);
  readonly setMatterportToken = (token: string): void =>
    this.authorizationService.setMatterportToken(token);
  readonly checkTokenExist = () => this.authorizationService.checkTokenExist();
  readonly deleteToken = () => this.authorizationService.deleteToken();

  readonly getModelAccess$ = (
    scanId: string
  ): Observable<ModelAccess | never> => this.apiService.getModelAccess$(scanId);
}
