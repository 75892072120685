import { createReducer, on } from '@ngrx/store';
import {
  AssetPrivilegeTypeStringify,
  DevicePrivilegeTypeStringify,
  Privileges,
  PrivilegesStore,
  RoomPrivilegeTypeStringify,
  ScenePrivilegeTypeStringify,
  TicketPrivilegeTypeStringify
} from '@simOn/privileges/element/models';
import { CLEAR_STATE_REDUCER } from '@simOn/utils';
import {
  ClearPrivilegesState,
  loadPrivileges,
  loadPrivilegesFailure,
  loadPrivilegesSuccess
} from './privileges.actions';
export const privilegesFeatureKey = 'privileges';

export const initialState: PrivilegesStore = {
  userGuid: '',
  isOwner: false,
  isLoaded: false,
  isLoading: false,
  general: [],
  device: {} as Privileges<DevicePrivilegeTypeStringify>,
  asset: {} as Privileges<AssetPrivilegeTypeStringify>,
  room: {} as Privileges<RoomPrivilegeTypeStringify>,
  scene: {} as Privileges<ScenePrivilegeTypeStringify>,

  ticket: {} as Privileges<TicketPrivilegeTypeStringify>
};

export const reducer = createReducer(
  initialState,
  on(loadPrivileges, (state) => ({ ...state, isLoading: true })),
  on(loadPrivilegesFailure, (state, action) => ({ ...state, isLoading: false, isLoaded: false, error: action.error })),
  on(loadPrivilegesSuccess, (state, action) => {
    return {
      ...state,
      ...action.data,
      isLoaded: true,
      isLoading: false,
      error: null
    };
  }),
  CLEAR_STATE_REDUCER(ClearPrivilegesState, initialState)
);
