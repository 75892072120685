import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { SimSliderComponent } from './components/sim-slider.component';

@NgModule({
  declarations: [SimSliderComponent],
  exports: [SimSliderComponent],
  imports: [CommonModule, ReactiveFormsModule, MatSliderModule]
})
export class SimSliderModule {}
