import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TTicketState } from '@simOn/ticket/element/models';
import { CLEAR_STATE_REDUCER, State } from '@simOn/utils';
import {
  AddLocalTicket,
  AddTicket,
  AddTicketComment,
  AddTicketCommentFailure,
  AddTicketCommentSuccess,
  AddTicketFailure,
  AddTicketSuccess,
  ClearTicketState,
  DeleteLocalTicket,
  DeleteTicket,
  DeleteTicketComment,
  DeleteTicketCommentFailure,
  DeleteTicketCommentSuccess,
  DeleteTicketFailure,
  DeleteTicketFromStore,
  DeleteTicketSuccess,
  GetTicket,
  GetTicketFailure,
  GetTickets,
  GetTicketsFailure,
  GetTicketsSuccess,
  GetTicketSuccess,
  MarkTicketAsReadSuccess,
  PreventTicketRefresh,
  SetSelectedId,
  UpdateLocalTicket,
  UpdateTicket,
  UpdateTicketComment,
  UpdateTicketCommentFailure,
  UpdateTicketCommentSuccess,
  UpdateTicketFailure,
  UpdateTicketSuccess
} from './ticket.actions';

export const ticketFeatureKey = 'tickets';
export const adapter: EntityAdapter<TTicketState> = createEntityAdapter<TTicketState>();

export const initialState: State<TTicketState> = adapter.getInitialState({
  preventRefresh: false,
  isLoaded: false,
  isLoading: false
});

export const reducer = createReducer(
  initialState,

  on(GetTicket, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(GetTicketSuccess, (state, action) => {
    return adapter.setOne(action.state, { ...state, isLoading: false, isLoaded: true });
  }),
  on(PreventTicketRefresh, (state, action) => {
    return {
      ...state,
      preventRefresh: action.prevent
    };
  }),
  on(GetTicketFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(GetTickets, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(GetTicketsSuccess, (state, action) => {
    return adapter.upsertMany(action.state, { ...state, isLoading: false, isLoaded: true });
  }),

  on(GetTicketsFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(AddTicket, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(AddTicketSuccess, (state, action) => {
    return { ...state, isLoading: false, isLoaded: true };
  }),

  on(AddLocalTicket, (state, action) => {
    return adapter.upsertOne(action.state, { ...state, isLoading: false, isLoaded: true });
  }),

  on(UpdateLocalTicket, (state, action) => {
    return adapter.updateOne(
      { id: action.state.id, changes: action.state },
      { ...state, isLoading: false, isLoaded: true }
    );
  }),

  on(DeleteLocalTicket, (state, action) => {
    return adapter.removeOne(action.state, { ...state, isLoading: false, isLoaded: true });
  }),

  on(AddTicketFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(UpdateTicket, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(UpdateTicketSuccess, (state, action) => {
    return { ...state, isLoading: false, isLoaded: true };
  }),

  on(UpdateTicketFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(DeleteTicket, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(DeleteTicketSuccess, (state, action) => {
    return adapter.removeOne(action.state, { ...state, isLoading: false, isLoaded: true });
  }),

  on(DeleteTicketFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(AddTicketComment, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(AddTicketCommentSuccess, (state, action) => {
    return adapter.updateOne(
      {
        id: action.state.ticketId,
        changes: {
          ticketComments: [...(state.entities[action.state.ticketId]?.ticketComments || []), action.state.ticketComment]
        }
      },
      { ...state, isLoading: false, isLoaded: true }
    );
  }),

  on(AddTicketCommentFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(UpdateTicketComment, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(UpdateTicketCommentSuccess, (state, action) => {
    let note = state.entities[action.state.ticketId]!;

    const index: number = note.ticketComments.indexOf(
      note.ticketComments.find((el) => el.id === action.state.ticketComment.ticketCommentId)!
    );
    note.ticketComments[index].htmlText = action.state.ticketComment.htmlText;
    return adapter.updateOne(
      { id: action.state.ticketId, changes: note },
      { ...state, isLoading: false, isLoaded: true }
    );
  }),

  on(UpdateTicketCommentFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(DeleteTicketComment, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(DeleteTicketCommentSuccess, (state, action) => {
    return adapter.removeOne(action.state, { ...state, isLoading: false, isLoaded: true });
  }),

  on(DeleteTicketCommentFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(MarkTicketAsReadSuccess, (state, action) => {
    const ticket = { ...state.entities[action.ticketId], hasUpdates: false };
    return adapter.updateOne({ id: action.ticketId, changes: ticket }, { ...state, isLoading: false, isLoaded: true });
  }),

  on(DeleteTicketFromStore, (state, action) => {
    return adapter.removeOne(action.ticketId, { ...state, isLoading: false, isLoaded: true });
  }),

  on(SetSelectedId, (state, action) => {
    return {
      ...state,
      selectedId: action.selectedId
    };
  }),
  CLEAR_STATE_REDUCER(ClearTicketState, initialState)
);
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
