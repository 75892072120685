import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MatterportRoomContentDtoInterface, RoomInterface } from '@simOn/room/models';
import { Observable, combineLatest, filter, map, switchMap } from 'rxjs';
import {
  AddRoom,
  AddRoomFailure,
  AddRoomSuccess,
  ClearRoomsState,
  DeleteRoom,
  DeleteRoomFailure,
  DeleteRoomSuccess,
  GetMasterRoom,
  GetRoom,
  GetRooms,
  SetRoomCamera,
  SetRoomCameraFailure,
  SetRoomCameraSuccess,
  SetSelectedId,
  UpdateRoom,
  UpdateRoomFailure,
  UpdateRoomOnStore,
  UpdateRoomSuccess,
  UpdateSweepInRoom
} from './room.actions';
import {
  GetSelectedId,
  MasterRoomsLoaded,
  RoomName,
  SelectAllRoomsWithoutMaster,
  SelectMasterRoom,
  SelectRoomContentById,
  SelectRooms,
  roomsLoaded,
  selectRoomById,
  selectRoomNameById,
  selectRoomsState,
  selectedRoomId
} from './room.selectors';

@Injectable({
  providedIn: 'root'
})
export class RoomFacade {
  private readonly store = inject(Store);
  private readonly action = inject(Actions);
  readonly masterRoom$: Observable<RoomInterface | undefined> = this.store.select(SelectMasterRoom);
  readonly masterRoomsLoaded$: Observable<boolean> = this.store
    .select(MasterRoomsLoaded)
    .pipe(filter((loaded) => loaded));

  readonly selectedId$: Observable<string | undefined> = this.store.select(GetSelectedId);
  readonly rooms$: Observable<RoomInterface[]> = this.store.select(SelectRooms);
  readonly roomsWithoutMaster$: Observable<RoomInterface[]> = this.store.select(SelectAllRoomsWithoutMaster);
  readonly roomsEntity$ = this.store.select(selectRoomsState);
  readonly roomLoaded$: Observable<boolean> = this.store.select(roomsLoaded).pipe(
    map((loaded) => loaded || false),
    filter((loaded) => loaded)
  );
  readonly selectedRoomId$: Observable<string | undefined> = this.store.select(selectedRoomId);

  readonly selectAllRoomsWithMasterRoom$: Observable<RoomInterface[]> = combineLatest([
    this.masterRoomsLoaded$,
    this.roomLoaded$
  ]).pipe(switchMap(() => this.store.select(SelectRooms)));
  readonly addRoomActionFinish$ = this.action.pipe(ofType(AddRoomSuccess, AddRoomFailure));
  readonly updateRoomActionFinish$ = this.action.pipe(ofType(UpdateRoomSuccess, UpdateRoomFailure));
  readonly deleteRoomActionFinish$ = this.action.pipe(ofType(DeleteRoomSuccess, DeleteRoomFailure));
  readonly setCameraAction$ = this.action.pipe(ofType(SetRoomCameraFailure, SetRoomCameraSuccess));
  roomById$ = (roomId: string): Observable<RoomInterface> => this.store.select(selectRoomById(roomId));
  selectRoomNameById$ = (roomId: string): Observable<string> => this.store.select(selectRoomNameById(roomId));

  getRoomContentById$ = (roomId: string, id: string): Observable<MatterportRoomContentDtoInterface | undefined> =>
    this.store.select(SelectRoomContentById(roomId, id));
  readonly roomName$ = (roomGuid: string) => this.store.select(RoomName(roomGuid));

  setSelectedId(selectedId: string): void {
    this.store.dispatch(SetSelectedId({ selectedId }));
  }

  getMasterRooms(): void {
    this.store.dispatch(GetMasterRoom());
  }
  getRooms(): void {
    this.store.dispatch(GetRooms());
  }

  getRoom(roomId: string): void {
    this.store.dispatch(GetRoom({ state: roomId }));
  }

  addRoom(room: RoomInterface): void {
    this.store.dispatch(AddRoom({ state: room }));
  }

  updateRoom(room: RoomInterface): void {
    this.store.dispatch(UpdateRoom({ room }));
  }

  updateRoomOnStore(room: RoomInterface): void {
    this.store.dispatch(UpdateRoomOnStore({ room }));
  }

  updateSweepInRoom(roomId: string, matterportModelId: string, sweepId: string): void {
    this.store.dispatch(UpdateSweepInRoom({ roomId, matterportModelId, sweepId }));
  }

  deleteRoom(roomId: string): void {
    this.store.dispatch(DeleteRoom({ state: roomId }));
  }

  clearState() {
    this.store.dispatch(ClearRoomsState());
  }
  setRoomCamera(payload: {
    roomId: string;
    roomCamera: {
      matterportModelId: string;
      position: import('libs/simlab-matterport/assets/mpSdk').Vector3;
      rotation: import('libs/simlab-matterport/assets/mpSdk').Vector2;
    };
  }) {
    this.store.dispatch(SetRoomCamera(payload));
  }
}
