import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType, ModeValueParameter } from '@simOn/device/models';
import { SimSelectModule } from '@simOn/ui/sim-select';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule, SimSelectModule],
  templateUrl: './set-mode.component.html',
  styleUrls: ['./set-mode.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-set-mode'
  }
})
export class SetModeComponent extends PropertyBase<'SetThermostatMode', string, ModeValueParameter> {
  valueToCommand(params: string): { command: keyof CommandValuesType; parameters: string | number } {
    throw new Error('Method not implemented.');
  }
  commandToValue(params: {
    command: keyof CommandValuesType;
    parameters: string | number | ModeValueParameter;
  }): string {
    throw new Error('Method not implemented.');
  }
  valueToParameter(setMode: string): ModeValueParameter {
    return {
      setMode
    };
  }

  set propertyValue(params: string) {
    throw new Error('Method not implemented.');
  }
  get propertyValue(): string {
    return this.propertyDefinition.values.value;
  }

  get options() {
    return this.propertyDefinition.settings?.value?.availableModes || ['Max'];
  }
}
