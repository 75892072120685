<input
  #inputImage
  (change)="imageFromHtmlElement(inputImage)"
  [disabled]="uploadDisabled"
  (touch)="onTouch()"
  [accept]="extensions"
  type="file"
  name="photo"
  style="display: none"
/>

<ng-content></ng-content>
