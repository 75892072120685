import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { filter, map, Observable, startWith, Subject, tap } from 'rxjs';
export class ExtendedMap<K, V> extends Map<K, V> {
  private readonly _mapChange = new Subject<typeof this>();
  readonly mapChange$ = this._mapChange.asObservable();
  override set(key: K, value: V) {
    const self = super.set(key, value);
    this._mapChange.next(self);
    return self;
  }
  override delete(key: K) {
    const deleted = super.delete(key);
    this._mapChange.next(this);
    return deleted;
  }
  override clear() {
    super.clear();
    this._mapChange.next(this);
  }
}

@Injectable({
  providedIn: 'root'
})
export class IconCacheService {
  private readonly httpClient = inject(HttpClient);
  private _cached: ExtendedMap<string, 'pending' | (string & {})> = new ExtendedMap<
    string,
    'pending' | (string & {})
  >();
  public getSvg(iconUrl: string): Observable<string> {
    if (this._cached.has(iconUrl)) {
      return this._cached.mapChange$.pipe(
        startWith(this._cached),
        map(() => this._cached.get(iconUrl) as string),
        filter((value) => value !== 'pending')
      );
    }
    this._cached.set(iconUrl, 'pending');
    return this.fetch(iconUrl).pipe(
      tap((result: string) => {
        this._cached.set(iconUrl, result);
      })
    );
  }

  private fetch(iconUrl: string): Observable<string> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');

    return this.httpClient.get(iconUrl, {
      headers,
      responseType: 'text'
    });
  }
}
