import { createAction, props } from '@ngrx/store';
import {
  AssetCreateInterface,
  AssetHyperlinksInterface,
  AssetResponseInterface,
  DeleteAssetHyperlinkInterface,
  DeleteAssetIdInterface,
  DeleteUploadedEventsInterface,
  TUpdateAssetFile,
  UploadEventsInterface
} from '@simOn/asset/element/models';
import { TAsset, TCreateAsset, TUpdateAsset } from '@simOn/asset/modifying/models';
import { SimlabErrorDto } from '@simOn/common/http';
import { DeleteUploadedFileInterface, Pending } from '@simOn/common/media';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const SetSelectedId = createAction('[Assets] Set selectedId', props<{ selectedId: string }>());

export const GetAssets = createAction('[Assets] Load Assets');
export const GetAssetsSuccess = createAction('[Assets][Success] Load Assets', props<{ state: TAsset[] }>());
export const GetAssetsFailure = createAction('[Assets][Failure] Load Assets', props<{ state: Error }>());

export const AddAsset = createAction('[Assets] New Add Asset on API', props<{ state: TCreateAsset }>());
export const OpenBlobUrl = createAction('[Assets] Get Blob SAS token', props<{ state: string }>());
export const OpenBlobUrlSuccess = createAction('[Assets] Get Blob SAS token Success', props<{ state: string }>());
export const AddAssetSuccess = createAction('[Assets][Success] New Add Asset', props<{ state: TAsset }>());
export const AddAssetFailure = createAction('[Assets][Failure] New Add Asset', props<{ state: SimlabErrorDto }>());

export const UpdateAssetAction = createAction('[Assets] Update Asset', props<{ state: TUpdateAsset }>());
export const UpdateAssetSuccess = createAction('[Assets][Success] Update Asset', props<{ state: any }>());
export const UpdateAssetFailure = createAction('[Assets][Failure] Update Asset', props<{ state: SimlabErrorDto }>());

export const DeleteAsset = createAction('[Assets] Delete Asset', props<{ state: DeleteAssetIdInterface }>());
export const DeleteAssetSuccess = createAction(
  '[Assets][Success] Delete Asset',
  props<{ state: DeleteAssetIdInterface }>()
);
export const DeleteAssetFailure = createAction('[Assets][Failure] Delete Asset', props<{ state: Error }>());

export const UploadHyperlinks = createAction(
  '[Assets] Upload Hyperlinks Asset',
  props<{ state: AssetHyperlinksInterface }>()
);
export const UploadHyperlinksSuccess = createAction(
  '[Assets][Success] Upload Hyperlinks Asset',
  props<{ state: AssetHyperlinksInterface }>()
);
export const UploadHyperlinksFailure = createAction(
  '[Assets][Failure] Upload Hyperlinks Asset',
  props<{ state: Error }>()
);

export const DeleteHyperlinks = createAction(
  '[Assets] Delete Hyperlinks Asset',
  props<{ state: DeleteAssetHyperlinkInterface }>()
);
export const DeleteHyperlinksSuccess = createAction(
  '[Assets][Success] Delete Hyperlinks Asset',
  props<{ state: string }>()
);
export const DeleteHyperlinksFailure = createAction(
  '[Assets][Failure] Delete Hyperlinks Asset',
  props<{ state: Error }>()
);

export const GetAsset = createAction('[Assets] Get Asset', props<{ state: string }>());
export const GetAssetSuccess = createAction('[Assets][Success] Get Asset', props<{ state: TAsset }>());
export const GetAssetFailure = createAction('[Assets][Failure] Get Asset', props<{ state: Error }>());

export const UploadFileToBlob = createAction(
  '[Assets] Upload File To Blob',
  props<{ asset: AssetResponseInterface; file: File; key: string }>()
);
export const UploadFileToBlobFailure = createAction(
  '[Assets][Failure] Upload File To Blob',
  props<{ fileKey: string }>()
);

export const UploadFileWithProgress = createAction('[Assets] Upload File With Progress', props<{ key: string }>());
export const UploadFileWithProgressStarted = createAction('[Assets][Started] Upload File With Progress');
export const UploadFileWithProgressCompleted = createAction('[Assets][Completed] Upload File With Progress');
export const UploadFileWithProgressPending = createAction(
  '[Assets][Pending] Upload File With Progress',
  props<{ state: Pending }>()
);
export const UploadFileWithProgressSuccess = createAction(
  '[Assets][Success] Upload File With Progress',
  props<{ state: AssetCreateInterface; fileKey: string }>()
);
export const UploadFileWithProgressFailure = createAction(
  '[Assets][Failure] Upload File With Progress',
  props<{ state: string }>()
);

export const DeleteUploadedFileSimplified = createAction(
  '[Assets] Delete Uploaded File Simplified',
  props<{ state: DeleteUploadedFileInterface }>()
);

export const DeleteFilesFromAsset = createAction(
  '[Assets] Delete Attachments from Asset',
  props<{ state: DeleteUploadedEventsInterface }>()
);

export const DeleteUploadedFileSimplifiedSuccess = createAction('[Assets][Success] Delete Uploaded File Simplified');
export const DeleteUploadedFileSimplifiedFailure = createAction(
  '[Assets][Failure] Delete Uploaded File Simplified',
  props<{ state: Error }>()
);

export const UploadEventsSimplified = createAction(
  '[Assets] Upload Events From Asset Simplified',
  props<{ state: UploadEventsInterface[] }>()
);
export const UploadEventsSimplifiedSuccess = createAction('[Assets][Success] Upload Events From Asset Simplified');
export const UploadEventsSimplifiedFailure = createAction(
  '[Assets][Failure] Upload Events From Asset Simplified',
  props<{ state: Error }>()
);

export const DeleteEventsFromAssetSimplified = createAction(
  '[Assets] Delete Events From Asset Simplified',
  props<{ state: string[] }>()
);
export const DeleteEventsFromAssetSimplifiedSuccess = createAction(
  '[Assets][Success] Delete Events From Asset Simplified'
);
export const DeleteEventsFromAssetSimplifiedFailure = createAction(
  '[Assets][Failure] Delete Events From Asset Simplified',
  props<{ state: Error }>()
);

export const RenameAssetFile = createAction(
  '[Assets] Rename Asset File',
  props<{ state: { fileId: string; fileName: string; fileType: TUpdateAssetFile } }>()
);
export const RenameAssetFileSuccess = createAction(
  '[Assets][Success] Rename Asset File',
  props<{ state: { fileId: string; fileName: string; assetId: string } }>()
);
export const RenameAssetFileFailure = createAction('[Assets][Failure] Rename Asset File', props<{ state: Error }>());

export const ClearAssetState = CLEAR_STATE_ACTION('Asset');
