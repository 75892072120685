import * as THREE from 'three';
import { Vector3 } from 'three';
import { DeviceCanvasIconTexture } from '../helpers/canvas.render.helper';
import { MeshConfiguration } from '../models/custom-component.type';
import { ThreeObjectBase } from './three-object.base';

export class MeshComponent extends ThreeObjectBase {
  set rotation(position: THREE.Euler) {
    throw new Error('Method not implemented.');
  }
  private _mesh!: THREE.Mesh;
  constructor(
    private readonly three: typeof THREE,
    private readonly configuration: MeshConfiguration['config']
  ) {
    super();
    this.init(three, configuration);
  }

  init(
    threeContext: typeof THREE,
    configuration: MeshConfiguration['config']
  ): void {
    this._mesh = this.createObject(threeContext);
    this._icon = this.configuration.icon;

    this._mesh.layers.set(3);
    configuration.position ?? ({ x: 0, y: 0, z: 0 } as Vector3);
  }

  private _getTexture(
    iconPath: MeshConfiguration['config']['icon']
  ): Promise<THREE.Texture> {
    const image = new Image();
    return new Promise((resolve) => {
      if (image) {
        image.onload = (e) => {
          const canvasTextureIcon = new DeviceCanvasIconTexture(
            image,
            iconPath.color
          );
          const texture = new THREE.CanvasTexture(canvasTextureIcon.ctx.canvas);
          texture.minFilter = THREE.NearestFilter;
          texture.encoding = THREE.sRGBEncoding;
          resolve(texture);
        };
      }
      image.src = iconPath.url;
    });
  }
  private createObject(threeContext: typeof THREE): THREE.Mesh {
    const geometry = new threeContext.PlaneGeometry(1, 1);

    const canvasTextureIcon = new DeviceCanvasIconTexture(
      new Image(),
      this.configuration.icon.color
    );
    const texture = new THREE.CanvasTexture(canvasTextureIcon.ctx.canvas);
    texture.encoding = THREE.sRGBEncoding;
    const material = new threeContext.MeshBasicMaterial({
      map: texture,
      alphaTest: 0.2,
      polygonOffset: false,
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      side: THREE.DoubleSide,
      toneMapped: false,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    });
    material.stencilWrite = true;
    material.stencilZPass = THREE.ReplaceStencilOp;
    return new threeContext.Mesh(geometry, material);
  }
  get object3D(): any {
    return this._mesh;
  }
  set position(position: Vector3) {
    const { x, y, z } = this._mesh.position;
    if (x !== position.x || y !== position.y || position.z !== z) {
      this._mesh.position.set(position.x, position.y, position.z);
    }
  }

  private set _icon(iconPath: MeshConfiguration['config']['icon']) {
    (this._mesh.material as THREE.MeshBasicMaterial).dispose();
    const material = this._mesh.material as THREE.MeshBasicMaterial;
    this._getTexture(iconPath).then((texture: THREE.Texture) => {
      texture.minFilter = THREE.NearestFilter;
      texture.minFilter = THREE.NearestMipmapNearestFilter;
      material.map = texture;
      material.needsUpdate = true;
      this.configuration.icon.color = iconPath.color;
      this.configuration.icon.url = iconPath.url;
    });
  }

  set icon(iconPath: MeshConfiguration['config']['icon']) {
    if (
      this.configuration.icon.color !== iconPath.color ||
      this.configuration.icon.url !== iconPath.url
    ) {
      this._icon = iconPath;
    }
  }
  set scale(scale: Vector3) {
    const { x, y, z } = scale;
    this._mesh.scale.set(x, y, z);
  }

  hide() {
    if (this._mesh.visible) {
      this._mesh.visible = false;
    }
  }

  show() {
    if (!this._mesh.visible) {
      this._mesh.visible = true;
    }
  }
}
