import { ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation } from '@angular/core';
import { SimProgressSpinnerComponent } from '@simOn/ui/sim-progress-spinner';

const HEIGHT_SCALE_MULTIPLIER = 0.6;
const STROKE_SCALE_MULTIPLIER = 0.08;

export abstract class ButtonLoaderBase {
  abstract setHeight(value: number): void;
}

@Component({
  selector: 'sim-button-loader',
  template: `<sim-progress-spinner [diameter]="diameter" [strokeWidth]="stroke"></sim-progress-spinner>`,
  styleUrls: ['./button-loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ButtonLoaderBase, useExisting: ButtonLoaderComponent }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'sim-button-loader'
  }
})
export class ButtonLoaderComponent extends ButtonLoaderBase {
  diameter = 0;
  stroke = 0;

  setHeight(value: number): void {
    this.diameter = value * HEIGHT_SCALE_MULTIPLIER;
    this.stroke = value * STROKE_SCALE_MULTIPLIER;
  }
}

@NgModule({
  declarations: [ButtonLoaderComponent],
  imports: [SimProgressSpinnerComponent],
  exports: [ButtonLoaderComponent]
})
export class SimButtonLoaderModule {}
