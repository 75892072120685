import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  generateBlobUrlForDownload(url: string): Observable<string> {
    return this.http.get(`${this._apiUrl}/Media/GenerateBlobUrlForDownload?blobUrl=${url}`, {
      responseType: 'text'
    });
  }

  deleteFileUpload(fileId: string) {
    return this.http.delete(`${this._apiUrl}/Media/CancelFileUpload?fileGuids=${fileId}`); // mozna usunąć kilka plików naraz, wklejając ich id w queryParams ...&fileGuids=
  }
}
