/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  ViewEncapsulation
} from '@angular/core';
import { tap } from 'rxjs';
import { DRAWER_CONTAINER } from '../tokens/drawer-container.token';

@Component({
  selector: 'sim-drawer-content',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'ui-drawer-content',
    '[style.margin-left.px]': '_container.contentMargins.left',
    '[style.margin-right.px]': '_container.contentMargins.right'
  }
})
export class DrawerContentComponent implements AfterContentInit {
  public readonly _container = inject(forwardRef(() => DRAWER_CONTAINER));
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);

  ngAfterContentInit(): void {
    this._container._contentMarginChanges
      .pipe(
        tap(() => {
          this._changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }
}
