import { AfterViewInit, Directive, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

export type ColorPickerPosition = { x: number; y: number };
export interface RGBWValueParameter {
  r: string | number;
  g: string | number;
  b: string | number;
  w?: string | number;
}
@Directive({
  selector: '[simColorPicker]'
})
export class SimColorPickerDirective implements AfterViewInit {
  private _palettePointerDown: boolean = false;
  private _paletteCanvasContext!: CanvasRenderingContext2D;
  private _selectedPositionOnPalette!: ColorPickerPosition;
  private _sliderCanvasContext!: CanvasRenderingContext2D;
  private _selectedHeightOnSlider = 0;
  private _color: string | undefined;
  private readonly centerPalettePicker: number = 12;
  readonly canvasMobileSize: number = 150;
  readonly canvasSize: number = 250;

  @ViewChild('slider', { static: false }) slider!: ElementRef<HTMLCanvasElement>;
  @ViewChild('palette', { static: false }) palette!: ElementRef<HTMLCanvasElement>;
  @ViewChild('sliderPointer') sliderPointer!: ElementRef<HTMLElement>;
  @ViewChild('palettePointer') palettePointer!: ElementRef<HTMLElement>;

  @Output() selectedColor: EventEmitter<RGBWValueParameter> = new EventEmitter<RGBWValueParameter>();

  set color(value: string) {
    this._drawPalette();
    this._color = value;
  }

  set palettePointerDown(value: boolean) {
    this._palettePointerDown = value;
  }

  ngAfterViewInit(): void {
    this._drawPalette();
    this._drawSlider();
  }

  onPointerDownPalette(evt: PointerEvent): void {
    this.palettePointerDown = true;
    this._selectedPositionOnPalette = { x: evt.offsetX, y: evt.offsetY };
    this._drawPalette();
  }

  onPointerUpPalette(evt: PointerEvent): void {
    if (!this._overCanvas(evt)) return;
    this.selectedColor.emit(this._getColorFromPalette(evt.offsetX, evt.offsetY));
  }

  onPointerMovePalette(evt: PointerEvent): void {
    if (this._palettePointerDown && this._overCanvas(evt)) {
      this._selectedPositionOnPalette = { x: evt.offsetX, y: evt.offsetY };
      this._drawPalette();
    } else {
      this._palettePointerDown = false;
    }
  }

  trackSlider(ev: Event): void {
    this._selectedHeightOnSlider = Number((ev.target as HTMLInputElement).value);
    this.color = this._getColorFromSlider(0, this._selectedHeightOnSlider);
    this._drawSlider();
    this._drawPalette();
  }

  private _overCanvas = (evt: PointerEvent) => {
    const rect = (evt.target as HTMLCanvasElement).getBoundingClientRect();
    return evt.offsetX > 0 && evt.offsetY > 0 && evt.offsetY < rect.height && evt.offsetX < rect.width;
  };

  private _getColorFromSlider(x: number, y: number): string {
    const imageData: Uint8ClampedArray = this._sliderCanvasContext.getImageData(x, y, 1, 1).data;
    return `rgba(${imageData[0]}, ${imageData[1]}, ${imageData[2]},1)`;
  }

  private _getColorFromPalette(x: number, y: number): RGBWValueParameter {
    const imageData: Uint8ClampedArray = this._paletteCanvasContext.getImageData(x, y, 1, 1).data;

    return {
      r: imageData[0],
      g: imageData[1],
      b: imageData[2],
      w: 1
    } as RGBWValueParameter;
  }

  private _drawSlider(): void {
    if (!this._sliderCanvasContext) {
      this._sliderCanvasContext = this.slider.nativeElement.getContext('2d')!;
    }

    const width = this.slider.nativeElement.width;
    const height = this.slider.nativeElement.height;
    if (this._sliderCanvasContext) {
      this._sliderCanvasContext.clearRect(0, 0, width, height);

      const gradient = this._sliderCanvasContext.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');
      gradient.addColorStop(0.83, 'rgba(255, 255, 0, 1)');
      gradient.addColorStop(0.66, 'rgba(0, 255, 0, 1)');
      gradient.addColorStop(0.5, 'rgba(0, 255, 255, 1)');
      gradient.addColorStop(0.33, 'rgba(0, 0, 255, 1)');
      gradient.addColorStop(0.17, 'rgba(255, 0, 255, 1)');
      gradient.addColorStop(0, 'rgba(255, 0, 0, 1)');

      this._sliderCanvasContext.beginPath();
      this._sliderCanvasContext.rect(0, 0, width, height);
      this._sliderCanvasContext.fillStyle = gradient;
      this._sliderCanvasContext.fill();
      this._sliderCanvasContext.closePath();
      if (this._selectedHeightOnSlider >= 0 && this._selectedHeightOnSlider < this.canvasSize) {
        this.sliderPointer.nativeElement.style.top = `${this._selectedHeightOnSlider}px`;
      }
    }
  }

  private _drawPalette(): void {
    if (this.palette) {
      if (!this._paletteCanvasContext) {
        this._paletteCanvasContext = this.palette.nativeElement.getContext('2d')!;
      }

      const width = this.palette.nativeElement.width;
      const height = this.palette.nativeElement.height;

      this._paletteCanvasContext.fillStyle = this._color || 'rgba(255,255,255,1)';
      this._paletteCanvasContext.fillRect(0, 0, width, height);

      const whiteGradient = this._paletteCanvasContext.createLinearGradient(0, 0, width, 0);
      whiteGradient.addColorStop(0, 'rgba(255,255,255,1)');
      whiteGradient.addColorStop(1, 'rgba(255,255,255,0)');

      this._paletteCanvasContext.fillStyle = whiteGradient;
      this._paletteCanvasContext.fillRect(0, 0, width, height);

      const blackGradient = this._paletteCanvasContext.createLinearGradient(0, 0, 0, height);
      blackGradient.addColorStop(0, 'rgba(0,0,0,0)');
      blackGradient.addColorStop(1, 'rgba(0,0,0,1)');

      this._paletteCanvasContext.fillStyle = blackGradient;
      this._paletteCanvasContext.fillRect(0, 0, width, height);

      this.palettePointer.nativeElement.style.left = `${
        this._selectedPositionOnPalette?.x + this.centerPalettePicker
      }px`;
      this.palettePointer.nativeElement.style.top = `${
        this._selectedPositionOnPalette?.y + this.centerPalettePicker
      }px`;
    }
  }
}
