import { Injectable, InjectionToken, inject } from '@angular/core';
import {
  DefaultHttpClient,
  HttpError,
  HttpRequest,
  HttpResponse,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions
} from '@microsoft/signalr';
import { SIGNAL_R_API_URL } from '@simOn/utils/tokens';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  firstValueFrom,
  from,
  map,
  of,
  retry,
  switchMap,
  timer
} from 'rxjs';
import { ApartmentRequestEvents, ApartmentResponseEvents } from './signalR.model';

//1s,5s,10s,30s,2min,10min,20min,30min,1hr
const RETRY_DELAY = [1000, 5000, 10000, 30000, 120000, 600000, 1200000, 1800000, 3600000] as const;
class CustomHttpClient extends DefaultHttpClient {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  constructor() {
    super(console); // the base class wants a signalR.ILogger
  }
  getAuthHeaders = async () => {
    return {
      Authorization: `Bearer ${await firstValueFrom(this.oidcSecurityService.getAccessToken())}`
    };
  };

  override get(url: string): Promise<HttpResponse>;
  override get(url: string, options: HttpRequest): Promise<HttpResponse>;
  override async get(url: string, options?: HttpRequest): Promise<HttpResponse> {
    const authHeaders = await this.getAuthHeaders();
    if (options) {
      options.headers = { ...options.headers, ...authHeaders };
      return super.get(url, options);
    }
    return super.get(url);
  }

  public override async send(request: HttpRequest): Promise<HttpResponse> {
    const authHeaders = await this.getAuthHeaders();
    request.headers = { ...request.headers, ...authHeaders };
    try {
      const response = await super.send(request);
      return response;
    } catch (er) {
      if (er instanceof HttpError) {
        const error = er as HttpError;
        if (error.statusCode == 401) {
          const authHeaders = await this.getAuthHeaders();
          request.headers = { ...request.headers, ...authHeaders };
        }
      } else {
        throw er;
      }
    }

    return super.send(request);
  }
}
type ConnectionStatus = 'disconected' | 'pending' | 'connected';

export const HubName = new InjectionToken<string>('SignalR Hub Name');
// TODO: (olek) change to web worker
@Injectable()
export class SignalRService {
  private readonly _status = new BehaviorSubject<ConnectionStatus>('disconected');
  readonly status$ = this._status;
  private _hubConnection: HubConnection | undefined;
  readonly apiUrl = inject(SIGNAL_R_API_URL);
  private _hubName: string = inject(HubName);
  private readonly _oidcSecurityService = inject(OidcSecurityService);
  constructor() {
    this._buildConnection();
  }

  get isHubConnected(): boolean {
    return !!this._hubConnection && this._hubConnection.state !== HubConnectionState.Disconnected;
  }

  startConnection(): Observable<void> {
    if (this._status.getValue() === 'pending')
      return this._status.pipe(
        filter((status) => status === 'connected'),
        map(() => undefined)
      );

    return of(true).pipe(
      switchMap(() => {
        if (this.isHubConnected) return of(undefined);
        return new Observable<void>((observer) => {
          this._status.next('pending');
          this._hubConnection &&
            this._hubConnection
              .start()
              .then(() => {
                console.log('Connection established with SignalR hub');
                observer.next();
                this._status.next('connected');

                observer.complete();
              })
              .catch((error) => {
                observer.error(error);
              });
        });
      }),
      retry({
        delay: (_, retryCount) => {
          const retryArr = [...RETRY_DELAY];
          const delay = retryCount < RETRY_DELAY.length ? retryArr[retryCount - 1] : retryArr.pop();
          return timer(delay ?? RETRY_DELAY[8]);
        }
      }),
      catchError((e) => {
        console.log(e);
        return of(e);
      })
    );
  }

  listenOnEvent<T>(event: ApartmentResponseEvents): Observable<T> {
    return this._status.pipe(
      filter((connected) => connected === 'connected'),
      switchMap(() => {
        return new Observable<T>((observer) => {
          this._hubConnection &&
            this._hubConnection.on(event, (message: T) => {
              observer.next(message);
            });
        });
      })
    );
  }

  sendEvent(event: ApartmentRequestEvents, apartmentId: string) {
    return this._status.pipe(
      filter((connected) => connected === 'connected'),
      switchMap(() => {
        return this._hubConnection ? from(this._hubConnection.invoke(event, apartmentId)) : of(undefined);
      })
    );
  }

  private async _buildConnection() {
    const options: IHttpConnectionOptions = {
      withCredentials: false,
      httpClient: new CustomHttpClient(),
      accessTokenFactory: () => {
        return firstValueFrom(this._oidcSecurityService.getAccessToken());
      }
    };
    this._hubConnection = new HubConnectionBuilder().withUrl(`${this.apiUrl}/${this._hubName}`, options).build();
  }
}
