export enum SmartApiProviderEnum {
  NONE = 'NONE',
  FIBARO = 'FIBARO',
  KNX = 'SE',
  SAMSUNG_ST = 'SAMSUNG_ST',
  MATTERPORT = 'MATTERPORT',
  WISER_HOME = 'WISER_HOME',
  NETX = 'NETX',
  GOOGLE = 'GOOGLE',
  MAXIMO = 'MAXIMO'
}

export const SmartApiProviderLabel = {
  [SmartApiProviderEnum.FIBARO]: $localize`:@@PROVIDERS_FIBARO:Fibaro`,
  [SmartApiProviderEnum.KNX]: $localize`:@@PROVIDERS_KNX:KNX`,
  [SmartApiProviderEnum.SAMSUNG_ST]: $localize`:@@PROVIDERS_SMART_THINGS:SmartThings`,
  [SmartApiProviderEnum.MATTERPORT]: $localize`:@@PROVIDERS_OTHERS:Others`,
  [SmartApiProviderEnum.WISER_HOME]: $localize`:@@PROVIDERS_WISER:Wiser`,
  [SmartApiProviderEnum.NETX]: $localize`:@@PROVIDERS_NETX:NETx`,
  [SmartApiProviderEnum.NONE]: $localize`Not implemented`,
  [SmartApiProviderEnum.GOOGLE]: $localize`Not implemented`,
  [SmartApiProviderEnum.MAXIMO]: $localize`:@@IBM_MAXIMO:IBM Maximo`
};
