import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AssetsApiService } from '@simOn/asset/element/data-access';
import { AssetCreateInterface } from '@simOn/asset/element/models';
import { TAsset } from '@simOn/asset/modifying/models';
import { MediaApiService } from '@simOn/common/media';
import { UploadApiService } from '@simOn/common/upload-queue/data-access';
import { concat, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mergeMap,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import {
  AddAsset,
  AddAssetFailure,
  AddAssetSuccess,
  DeleteAsset,
  DeleteAssetFailure,
  DeleteAssetSuccess,
  GetAsset,
  GetAssetFailure,
  GetAssetSuccess,
  GetAssets,
  GetAssetsFailure,
  GetAssetsSuccess,
  OpenBlobUrl,
  OpenBlobUrlSuccess,
  SetSelectedId,
  UpdateAssetAction,
  UpdateAssetFailure,
  UpdateAssetSuccess,
  UploadFileWithProgress,
  UploadFileWithProgressCompleted,
  UploadFileWithProgressFailure,
  UploadFileWithProgressPending,
  UploadFileWithProgressStarted,
  UploadFileWithProgressSuccess
} from './asset.actions';
import { GetSelectedAsset } from './asset.selectors';

@Injectable()
export class AssetEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly assetsApiService = inject(AssetsApiService);
  private readonly mediaApiService = inject(MediaApiService);
  private readonly uploadApiService = inject(UploadApiService);

  selectedAssetId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedId),
      distinctUntilChanged((previous, current) => previous.selectedId === current.selectedId),
      mergeMap((action) => of(GetAsset({ state: action.selectedId })))
    )
  );

  getAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAsset),
      filter((result) => !!result.state),
      mergeMap((result) =>
        this.assetsApiService.getAsset(result.state).pipe(
          map((response) => GetAssetSuccess({ state: response })),
          catchError((error) => of(GetAssetFailure(error)))
        )
      )
    )
  );

  getAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAssets),
      mergeMap(() =>
        this.assetsApiService.getAssets().pipe(
          map((response) => GetAssetsSuccess({ state: response })),
          catchError((error) => of(GetAssetsFailure(error)))
        )
      )
    )
  );

  openBlobUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OpenBlobUrl),
      mergeMap((result) =>
        this.mediaApiService.generateBlobUrlForDownload(result.state).pipe(
          map((response) => OpenBlobUrlSuccess({ state: response })),
          catchError((error) => of(GetAssetsFailure(error)))
        )
      )
    )
  );

  createAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddAsset),
      exhaustMap((result) =>
        this.assetsApiService.createAsset(result.state).pipe(
          mergeMap((response) =>
            concat([AddAssetSuccess({ state: response }), SetSelectedId({ selectedId: response.id })])
          ),
          catchError((error) => of(AddAssetFailure(error)))
        )
      )
    )
  );

  updateAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateAssetAction),
      mergeMap((result) =>
        this.assetsApiService.updateAsset(result.state).pipe(
          mergeMap((response) => [
            UpdateAssetSuccess({ state: response }),
            GetAsset({ state: response.id }),
            SetSelectedId({ selectedId: response.id })
          ]),
          catchError((error) => of(UpdateAssetFailure(error)))
        )
      )
    )
  );

  uploadFileWithProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadFileWithProgress),
      withLatestFrom(this.store.select(GetSelectedAsset)),
      concatMap(([props, asset]) => {
        if (!asset) {
          throw new Error("GetSelectedAsset doesn't return correctly asset");
        }
        return this.uploadApiService.uploadFileWithProgress().pipe(
          takeUntil(this.actions$.pipe(ofType(UploadFileWithProgressSuccess))),
          concatMap((event) => [this.getActionFromHttpEvent(event, asset, props.key)]),
          catchError((error) => of(UploadFileWithProgressFailure(error)))
        );
      })
    )
  );

  deleteAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteAsset),
      exhaustMap((result) =>
        this.assetsApiService.deleteAsset(result.state.id).pipe(
          mergeMap((id) => [DeleteAssetSuccess({ state: { id } })]),
          catchError((error) => of(DeleteAssetFailure(error)))
        )
      )
    )
  );

  //#endregion

  private getActionFromHttpEvent(event: HttpEvent<any>, asset: TAsset, fileName: string) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return UploadFileWithProgressPending({
          state: { total: event.total || 0, loaded: event.loaded, fileName: fileName }
        });
      case HttpEventType.ResponseHeader: {
        return UploadFileWithProgressCompleted();
      }
      case HttpEventType.Response: {
        if (event.status === 200) {
          const fileGuid: string = event.body;
          const body: AssetCreateInterface = {
            id: asset.id,
            name: asset.name,
            uploadedFiles: [fileGuid],
            roomId: asset.roomId
          };

          return UploadFileWithProgressSuccess({ state: body, fileKey: fileName });
        } else {
          return UploadFileWithProgressFailure({ state: asset.name });
        }
      }
      case HttpEventType.DownloadProgress:
        return UploadFileWithProgressPending({
          state: { total: event.total || 0, loaded: event.loaded, fileName: fileName }
        });
      case HttpEventType.Sent:
        return UploadFileWithProgressStarted();
      default:
        return UploadFileWithProgressFailure({ state: asset.name });
    }
  }
}
