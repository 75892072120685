import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  AddTicketCommentInterface,
  TicketCommentInterface,
  UpdateTicketCommentInterface
} from '@simOn/ticket/comments/models';
import { TTicketState, TUpdateTicket, UsersForTicketInterface } from '@simOn/ticket/element/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getTicket$(ticketId: string): Observable<TTicketState> {
    return this.http.get<TTicketState>(`${this._apiUrl}/Tickets/GetTicket?TicketId=${ticketId}`);
  }

  getTickets$(): Observable<Partial<TTicketState[]>> {
    return this.http.get<Partial<TTicketState[]>>(`${this._apiUrl}/Tickets/GetTickets`);
  }

  getUsersForTicket$(ticketId: string): Observable<UsersForTicketInterface[]> {
    return this.http.get<UsersForTicketInterface[]>(
      `${this._apiUrl}/Tickets/GetUsersForTicket${
        ticketId === null ? '' : ticketId !== undefined ? `?TicketId=${ticketId}` : ''
      }`
    );
  }

  createTicket$(payload: TUpdateTicket): Observable<string> {
    return this.http.post<string>(`${this._apiUrl}/Tickets/CreateTicket`, payload);
  }

  addTicketComment$(payload: AddTicketCommentInterface): Observable<TicketCommentInterface> {
    return this.http.post<TicketCommentInterface>(`${this._apiUrl}/Tickets/AddTicketComment`, payload);
  }

  updateTicket$(payload: TUpdateTicket): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Tickets/UpdateTicket`, payload);
  }

  markTicketAsRead$(ticketId: string): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Tickets/MarkTicketAsRead?Id=${ticketId}`, {});
  }

  updateTicketComment$(payload: UpdateTicketCommentInterface): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Tickets/UpdateTicketComment`, payload);
  }

  deleteTicket$(ticketId: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Tickets/DeleteTicket?TicketId=${ticketId}`);
  }

  deleteAttachmentsFromTicket$(ticketId: string, attachmentsIds: string[]): Observable<string> {
    return this.http.delete<string>(
      `${this._apiUrl}/Tickets/DeleteAttachmentsFromTicket?TicketId=${ticketId}&AttachmentIds=${attachmentsIds}`
    );
  }

  deleteTicketComment$(ticketCommentId: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Tickets/DeleteTicketComment?TicketCommentId=${ticketCommentId}`);
  }
}
