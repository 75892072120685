import { Subject } from 'rxjs';

export class ExtendedMap<K, V> extends Map<K, V> {
  private readonly _mapChange = new Subject<Map<K, V>>();
  readonly mapChange$ = this._mapChange.asObservable();
  override set(key: any, value: any) {
    const self = super.set(key, value);
    this._mapChange.next(self);
    return self;
  }
  override delete(key: K): boolean {
    const deleted = super.delete(key);
    this._mapChange.next(this);
    return deleted;
  }
  override clear(): void {
    super.clear();
    this._mapChange.next(this);
  }
}
