import { Directive, ElementRef, Input, inject } from '@angular/core';
import { ThemeColors } from '../models/theme-colors';

@Directive({
  selector: '[simColor]'
})
export class ColorDirective {
  private readonly el = inject(ElementRef);

  @Input() set simColor(value: ThemeColors) {
    this.el.nativeElement.classList.forEach((el: any) => {
      if (el.includes('sim-color-')) {
        this.el.nativeElement.classList.remove(el);
      }
    });
    this.el.nativeElement.classList.add(`sim-color-${value}`);
  }
}
