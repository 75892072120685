import { CdkPortalOutlet, ComponentPortal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  ViewChild,
  inject,
  viewChild
} from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import {
  NavBarDataInterface,
  NavBarDataInterfaceI,
  NavLinkInterface,
  SimContentComponent,
  SimNavbarComponent
} from '@simOn/common/containers';
import { BreakpointService, DeviceType } from '@simOn/ui/breakpoint';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimFullscreenModule } from '@simOn/ui/sim-fullscreen';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SidenavComponent, SimSidenavModule } from '@simOn/ui/sim-sidenav';
import { SimUserOnlineComponent } from '@simOn/user/last-visited/ui';
import { SimLoggedUserComponent } from '@simOn/user/logged/ui';
import { Observable, firstValueFrom, map, of, switchMap } from 'rxjs';
import { PageTemplateLayout } from '../tokens/layout.token';

export function provideNavData<T>(navData: NavBarDataInterfaceI<T>, injector: Injector): Observable<T> {
  const tokens = navData.tokens?.map((token) => injector.get<any>(token)) as any[];
  return typeof navData.data === 'function' ? (navData.data as Function)(...tokens) : of(navData.data);
}

@Component({
  selector: 'layout-page-template',
  templateUrl: './sim-page-template.component.html',
  styleUrls: ['./sim-page-template.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: PageTemplateLayout,
      useExisting: SimPageTemplateComponent
    }
  ],
  imports: [
    CommonModule,
    SimNavbarComponent,
    SimContentComponent,
    RouterModule,
    PortalModule,
    SimIconModule,
    SimButtonModule,
    SimSidenavModule,
    SimLoggedUserComponent,
    SimFullscreenModule,
    SimUserOnlineComponent
  ]
})
export class SimPageTemplateComponent {
  private readonly _injector = inject(Injector);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _breakpointService = inject(BreakpointService);
  readonly deviceName$: Observable<DeviceType> = this._breakpointService.deviceType$;
  readonly navigation$: Observable<NavBarDataInterface> = this._activatedRoute.data.pipe(
    switchMap((navBar) => {
      return provideNavData<NavBarDataInterface>(navBar as NavBarDataInterfaceI<NavBarDataInterface>, this._injector);
    })
  );
  @ViewChild('sidenavLeft') sidenav!: SidenavComponent;
  selectedPortal: ComponentPortal<any> | undefined;
  readonly sidenavRight = viewChild.required<SidenavComponent>('sidenavRight');
  readonly outletRight = viewChild.required<CdkPortalOutlet>('outletRight');
  activeRouterLink = '';
  readonly hideBottomToolbar$ = this._activatedRoute.data.pipe(map((data) => data['hideBottomToolbar']));
  private _hideNavbar: boolean = false;
  public get hideNavbar(): boolean {
    return this._hideNavbar;
  }
  public set hideNavbar(value: boolean) {
    this._hideNavbar = value;
  }
  constructor() {
    firstValueFrom(this._activatedRoute.data);
  }

  trackByPath(idx: number, path: NavLinkInterface) {
    return path.path;
  }
  toggleMenu(): void {
    this.sidenav.toggle();
    this._cdr.detectChanges();
  }

  changeActiveRouterLink(event: boolean, navName: string): void {
    if (event) {
      this.activeRouterLink = navName;
    }
  }
}
