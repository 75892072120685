import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  ViewEncapsulation
} from '@angular/core';
import { CommandValuesType, ExecuteSceneValueParameter, paramJSON } from '@simOn/device/models';
import { SimFormModule } from '@simOn/ui/sim-form-filed';
import { SimInputModule } from '@simOn/ui/sim-input';
import { Subject } from 'rxjs';
import { PropertyBase } from '../directives/property-base.directive';

const CommandByProperty: Record<string, keyof CommandValuesType> = {
  Number: 'SetNumber',
  Integer: 'SetInteger',
  UInteger: 'SetUInteger'
};

@Component({
  standalone: true,
  imports: [CommonModule, SimInputModule, SimFormModule],
  styleUrls: ['./set-number.component.scss'],
  template: `<sim-form-field>
    <sim-input
      i18n-placeholder="@@DEVICE_PROPERTIES_SET_NUMBER"
      id="set-number-input"
      required
      #input
      type="number"
      placeholder="Set number..."
      (focusChange)="sendCommand(input.value)"
      [value]="(propertyValueToView | async)!"
      (valueChange)="propertyValueToView.next(+$event)"
      [min]="settingsValue?.min!"
      [max]="settingsValue?.max!"
    ></sim-input>
  </sim-form-field> `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-set-number'
  }
})
export class SetNumberComponent
  extends PropertyBase<'Number', number, ExecuteSceneValueParameter>
  implements AfterViewInit
{
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  readonly propertyValueToView = new Subject<number>();
  commandToValue(params: {
    command: keyof CommandValuesType;
    parameters: string | number | ExecuteSceneValueParameter;
  }): number {
    const paramToJSON = paramJSON(params.parameters);
    return paramToJSON.value as number;
  }
  valueToCommand(params: number): { command: keyof CommandValuesType; parameters: string | number } {
    return {
      command: CommandByProperty[this.propertyDefinition.type],
      parameters: JSON.stringify(this.valueToParameter(params))
    };
  }

  set propertyValue(params: number) {
    let value: number;
    if (params > this.settingsValue.max) {
      value = this.settingsValue.max;
    } else if (params < this.settingsValue.min) {
      value = this.settingsValue.min;
    } else {
      value = params;
    }
    this.propertyValueToView.next(this._convertToTwoDecimalPlaces(value));
    this.propertyDefinition = {
      ...this.propertyDefinition,
      values: {
        ...this.propertyDefinition.values,
        value
      }
    };
  }

  ngAfterViewInit() {
    this.propertyValueToView.next(this._convertToTwoDecimalPlaces(this.propertyValue));
    this._changeDetectorRef.detectChanges();
  }

  get propertyValue(): number {
    return this.propertyDefinition?.values?.value!;
  }

  get settingsValue() {
    return this.propertyDefinition.settings!.value;
  }

  valueToParameter(value: string | number): ExecuteSceneValueParameter {
    return { value };
  }

  sendCommand(inputValue: string | number | undefined): void {
    this.propertyValue = inputValue as number;
    this.partialCommand$.emit(this.valueToCommand(this.propertyValue));
  }

  private _convertToTwoDecimalPlaces(value: number) {
    return Math.round(value * 100) / 100;
  }
}
