import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { Unit, UNIT_TRANSFORM } from '@simOn/common/units';
import {
  CommandKeys,
  ComponentType,
  ExecuteCommandPayload,
  Properties,
  SimlabPropertyInterface,
  WidgetProperties
} from '@simOn/device/models';
import { UserPreferencesInterface } from '@simOn/user/preferences/model';

/**
 * @typeParam  T - type of property name satisfied  {@link Properties}
 * @typeParam W - type of value used as param in setPropertyValue fnc
 */
@Directive()
export abstract class PropertyBase<T extends Properties, W, P> implements WidgetProperties<SimlabPropertyInterface<T>> {
  private readonly _cdr = inject(ChangeDetectorRef);
  private _suppressSendCommand: boolean = false;
  private _invisibilityWakeUp: boolean = false;
  private _propertyDefinition!: SimlabPropertyInterface<T>;
  private _userPreferences!: UserPreferencesInterface;

  @Input() set propertyDefinition(value: SimlabPropertyInterface<T>) {
    this._propertyDefinition = value;
    this._cdr.markForCheck();
  }
  get propertyDefinition(): SimlabPropertyInterface<T> {
    return this._propertyDefinition;
  }
  @Input() set invisibilityWakeUp(value: boolean) {
    this._invisibilityWakeUp = value;
  }
  get invisibilityWakeUp(): boolean {
    return this._invisibilityWakeUp;
  }

  @Input() set suppressSendCommand(value: boolean) {
    this._suppressSendCommand = value;
  }
  get suppressSendCommand(): boolean {
    return this._suppressSendCommand;
  }
  @Input() set userPreferences(userPreferences: UserPreferencesInterface) {
    this._userPreferences = userPreferences;
    this._cdr.markForCheck();
  }
  get userPreferences() {
    return this._userPreferences;
  }

  get supportedCommands(): CommandKeys[] {
    return this._propertyDefinition.supportedCommands;
  }

  @Output() partialCommand$: EventEmitter<ExecuteCommandPayload<ComponentType>['command']> = new EventEmitter<
    ExecuteCommandPayload<ComponentType>['command']
  >();

  userPrefValue(srcUnit: Unit, dstUnit: Unit, value: number | string | boolean): number | string | boolean {
    return UNIT_TRANSFORM[srcUnit]?.[dstUnit] ? UNIT_TRANSFORM[srcUnit]![dstUnit]!(value as number) : value;
  }

  abstract set propertyValue(params: W);
  abstract get propertyValue(): W;

  abstract valueToParameter(params: W): P;
  abstract valueToCommand(params: W): ExecuteCommandPayload<ComponentType>['command'];
  abstract commandToValue(params: ExecuteCommandPayload<ComponentType, P>['command']): W;
}
