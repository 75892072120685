import { FocusableOption, FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { BUTTON_HOST_ATTRIBUTES } from '../../models/attributes';
import { OnLoading } from '../../models/on-loading';
let uniqueIdCounter = 0;
export abstract class ButtonBase implements OnLoading {
  loading = false;
  abstract loadingBegin(): void;
  abstract loadingEnd(): void;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `button[sim-button], button[sim-raised-button], button[sim-stroked-button],  button[sim-flat-button], button[sim-icon-button], button[sim-fab-button], button[sim-mini-fab-button]`,
  exportAs: 'simButton',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ButtonBase, useExisting: ButtonComponent }],
  host: {
    '[id]': 'id'
  }
})
export class ButtonComponent extends ButtonBase implements FocusableOption, AfterViewInit, OnDestroy {
  readonly elementRef = inject(ElementRef);
  private focusMonitor = inject(FocusMonitor);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  @Input()
  disabled?: boolean = false;

  @HostBinding('class.sim-button-disabled')
  get uiButtonDisabled(): boolean {
    return (this.disabled ?? false) || (this.loading ?? false);
  }
  private _iTagElement?: HTMLElement;
  private _iconElement?: Element;
  @HostBinding('attr.disabled')
  get attrDisabled(): boolean | null {
    return this.disabled || this.loading || null;
  }

  @HostBinding('attr.aria-disabled')
  get attrAriaDisabled(): string {
    return ((this.disabled ?? false) || (this.loading ?? false)).toString();
  }

  @Input() id: string = `sim-button-${uniqueIdCounter++}`;

  constructor() {
    super();
    const hostElement = this.getHostElement();
    BUTTON_HOST_ATTRIBUTES.forEach((attribute: string) => {
      if (hostElement.hasAttribute(attribute)) {
        hostElement.classList.add(attribute);
      }
    });

    hostElement.classList.add('sim-button-base');
  }

  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef, true);

    this._iTagElement = this.getHostElement().getElementsByTagName('i')[0];
    this._iconElement = this.getHostElement().getElementsByTagName('sim-icon')[0];
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  loadingBegin(): void {
    this.loading = true;
    if (this._iTagElement) {
      this._iTagElement.style.display = 'none';
    }
    if (this._iconElement) {
      (this._iconElement as HTMLElement).style.display = 'none';
    }
    this.changeDetectorRef.markForCheck();
  }

  loadingEnd(): void {
    this.loading = false;
    if (this._iTagElement) {
      this._iTagElement.style.display = '';
    }
    if (this._iconElement) {
      (this._iconElement as HTMLElement).style.display = '';
    }
    this.changeDetectorRef.markForCheck();
  }

  focus(origin?: FocusOrigin, options?: FocusOptions): void {
    if (origin) {
      this.focusMonitor.focusVia(this.getHostElement(), origin, options);
    } else {
      this.getHostElement().focus(options);
    }
  }

  private getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
