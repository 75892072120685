<common-content-container>
  <div class="split-screen-container">
    <div class="split-screen-container__content" [class.screen-hide]="smallSizeDisplayContent !== 'secondary'">
      <router-outlet></router-outlet>
    </div>

    <div class="split-screen-container__content" [class.screen-hide]="smallSizeDisplayContent === 'secondary'">
      <router-outlet name="sub"></router-outlet>
    </div>
  </div>
</common-content-container>
