import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  CreateUserProfileInterface,
  UserApartmentPreferencesInterface,
  UserProfileInterface
} from '@simOn/user/preferences/model';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getUserEnsureProfile(userGuid: string): Observable<UserProfileInterface> {
    return this.http.get<UserProfileInterface>(`${this._apiUrl}/Users/EnsureProfileValid?userGuid=${userGuid}`);
  }

  createUserProfile(body: CreateUserProfileInterface): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Users/CreateUserProfile`, body);
  }

  userAcceptEula(userGuid: string, eulaVersion: string): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Users/AcceptEula`, { userGuid: userGuid, eulaVersion });
  }

  getEulaUrl(): Observable<string> {
    return this.http.get<string>(`${this._apiUrl}/Users/GetLatestEula`);
  }

  getUserPreferences(): Observable<UserApartmentPreferencesInterface> {
    return this.http.get<UserApartmentPreferencesInterface>(`${this._apiUrl}/Users/GetUserPreferences`);
  }

  updateUserPreferences(requestBody: UserApartmentPreferencesInterface): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Users/UpdateUserPreferences`, requestBody);
  }

  resetUserPreferences(apartmentId: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/Users/ResetUserPreferences?ApartmentId=${apartmentId}`);
  }

  getCurrentEula(eulaUrl: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(eulaUrl, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }
}
