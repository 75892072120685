import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation, inject } from '@angular/core';
import { CommandValuesType, SENSOR_MAPPER } from '@simOn/device/models';
import { PropertyBase } from '../directives/property-base.directive';

const LAST_BREACHED = (date: string) => $localize`:@@LAST_BREACHED:last ${date}`;

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `<p
    [attr.propertyValue]="propertyValue"
    [attr.lastBreached]="lastBreached"
    [ngClass]="lastBreached ? 'detector-animation' : 'detector'"
  ></p> `,
  styleUrls: ['./detector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe],
  host: {
    class: 'sim-detector alarm'
  }
})
export class DetectorComponent extends PropertyBase<'MotionDetected', string, never> {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly datePipe = inject(DatePipe);

  valueToCommand(params: string): { command: keyof CommandValuesType; parameters: string | number } {
    throw new Error('Method not implemented.');
  }
  commandToValue(params: { command: keyof CommandValuesType; parameters: string | number }): string {
    throw new Error('Method not implemented.');
  }
  valueToParameter(params: string): never {
    throw new Error('Method not implemented.');
  }

  set propertyValue(params: string) {
    throw new Error('Method not implemented.');
  }
  get propertyValue(): string {
    const sensorValues = SENSOR_MAPPER[this.propertyDefinition.type];
    return this.propertyDefinition.values.value ? sensorValues[1] : sensorValues[0];
  }

  get lastBreached(): string | undefined {
    if (!this.propertyDefinition?.values?.lastBreached === undefined) return undefined;
    const lastBreached = this.propertyDefinition.values.lastBreached;
    return lastBreached !== undefined && lastBreached !== 0
      ? LAST_BREACHED(this.datePipe.transform(lastBreached, 'dd.MM.yy H:mm') || '')
      : undefined;
  }
}
