import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { SimIconModule } from '@simOn/ui/sim-icon';

@Component({
  selector: 'sim-search-suffix',
  templateUrl: './sim-search-suffix.component.html',
  styleUrls: ['./sim-search-suffix.component.scss'],
  standalone: true,
  imports: [CommonModule, SimIconModule]
})
export class SimSearchSuffixComponent {
  @Output() onclick = new EventEmitter<void>();
}
