<div class="wrapper" id="matt-wrapper">
  <iframe
    id="matterport"
    #iframeRef
    [src]="url | bypassSecurity"
    (load)="loaded.next(true)"
    (error)="onError($event)"
    frameborder="0"
    allowfullscreen
  ></iframe>
  <div class="hint" #hint>
    <div class="hint-container"></div>
  </div>
  <div class="circular" #progress>
    <div class="inner"></div>
    <div class="number">Hold</div>
    <div class="circle">
      <div class="bar left">
        <div class="progress"></div>
      </div>
      <div class="bar right">
        <div class="progress"></div>
      </div>
    </div>
  </div>
</div>
