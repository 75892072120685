import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType, ExecuteSceneValueParameter, paramJSON } from '@simOn/device/models';
import { SimSelectModule } from '@simOn/ui/sim-select';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule, SimSelectModule],
  templateUrl: './set-boolean.component.html',
  styleUrls: ['./set-boolean.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-set-boolean'
  }
})
export class SetBooleanComponent extends PropertyBase<
  'Boolean',
  string | null | undefined,
  ExecuteSceneValueParameter
> {
  valueToCommand(params: string): { command: keyof CommandValuesType; parameters: string | number } {
    return {
      command: 'SetBoolean',
      parameters: JSON.stringify(this.valueToParameter(params))
    };
  }
  commandToValue(params: {
    command: keyof CommandValuesType;
    parameters: string | number | ExecuteSceneValueParameter;
  }): string {
    const paramToJson: ExecuteSceneValueParameter = paramJSON(params.parameters);
    return paramToJson.value.toString();
  }
  set propertyValue(params: string) {
    this.propertyDefinition = {
      ...this.propertyDefinition,
      values: {
        ...this.propertyDefinition.values,
        value: params === 'true'
      }
    };
    this.partialCommand$.emit(this.valueToCommand(params));
  }

  get propertyValue(): string {
    return this.propertyDefinition.values.value?.toString()!;
  }
  get values(): string[] {
    return this.propertyDefinition.settings!.value.value.map((value) => value.toString());
  }
  valueToParameter(value: string): ExecuteSceneValueParameter {
    return { value };
  }
}
