import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppendBlobAppendBlockOptions, BlobServiceClient } from '@azure/storage-blob';
import { NameWithLimitLength } from '@simOn/common/helpers';
import { GenerateBlobUrlForUpload } from '@simOn/common/upload-queue/models';
import { API_URL } from '@simOn/utils/tokens';
import md5 from 'md5';
import { from, map, mergeMap, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);
  private _fileToUpload: FormData[] = [];

  set fileToUpload(formData: FormData) {
    this._fileToUpload.push(this.getFileToUpload(formData));
  }

  getFileToUpload(formData: FormData) {
    const file = formData.get('File') as File;
    formData.delete('File');
    formData.append('File', file, NameWithLimitLength(file));
    return formData;
  }

  get fileToUpload(): FormData | undefined {
    return this._fileToUpload.pop();
  }

  generateBlobUrlForUpload(fileName: string, extension: string, size: number) {
    return this.http.get<GenerateBlobUrlForUpload>(
      `${this._apiUrl}/Media/GenerateBlobUrlForUpload?Name=${encodeURIComponent(
        fileName
      )}&Extension=${extension}&Size=${size}`
    );
  }

  sendFileToBlob(file: File, cb?: AppendBlobAppendBlockOptions['onProgress']) {
    const fileExtension: string = file.name.split('.').pop() ?? '';
    if (!fileExtension) return of(undefined);
    return this.generateBlobUrlForUpload(NameWithLimitLength(file), fileExtension, file.size).pipe(
      mergeMap((uploadBlobData: GenerateBlobUrlForUpload) => {
        return from(this.uploadToBlob(uploadBlobData, file, cb)).pipe(
          map(() => {
            return uploadBlobData.uploadFileId;
          })
        );
      })
    );
  }

  uploadFile(formData: FormData): Observable<string> {
    let headers = new HttpHeaders();
    // should be Content-type, multipart
    headers = headers.set('Cache-Control', 'no-cache');
    return this.http.post<string>(`${this._apiUrl}/Media/UploadFile`, formData, { headers });
  }
  uploadFileWithProgress$(fileToUpload: FormData): Observable<HttpEvent<Object>> {
    let headers = new HttpHeaders();
    headers = headers.set('Cache-Control', 'no-cache');

    return this.http.post(`${this._apiUrl}/Media/UploadFile`, fileToUpload, {
      reportProgress: true,
      observe: 'events',
      responseType: 'json' as 'json',
      headers: headers
    });
  }

  uploadFileWithProgress(): Observable<HttpEvent<Object>> {
    let headers = new HttpHeaders();
    headers = headers.set('Cache-Control', 'no-cache');

    return this.http.post(`${this._apiUrl}/Media/UploadFile`, this.fileToUpload, {
      reportProgress: true,
      observe: 'events',
      responseType: 'json' as 'json',
      headers: headers
    });
  }
  private _getHashFromFile(fileData: ArrayBuffer, blockSize: number): string {
    let hashBlock = new Uint8Array(blockSize);
    if (fileData.byteLength <= 0) {
      throw new Error('File is corrupted!');
    }
    for (let i = 0; i < fileData.byteLength; i += blockSize) {
      const block = new Uint8Array(fileData.slice(i, i + blockSize));

      hashBlock = hashBlock.map((hashElement, indexElement) => hashElement ^ block[indexElement]);
    }
    return md5(hashBlock);
  }

  private async uploadToBlob(
    uploadBlobData: GenerateBlobUrlForUpload,
    file: File,
    cb?: AppendBlobAppendBlockOptions['onProgress']
  ): Promise<void> {
    const blockSize = 8388608;
    const url = `${uploadBlobData.origin}${uploadBlobData.sas}`;
    const blobServiceClient = new BlobServiceClient(url);
    const containerClient = blobServiceClient.getContainerClient(uploadBlobData.containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(uploadBlobData.blobName);
    try {
      const mdHash = this._getHashFromFile(await file.arrayBuffer(), blockSize);

      await blockBlobClient.upload(file, file.size, {
        blobHTTPHeaders: { blobContentType: file.type, blobContentDisposition: `filename="${file.name}"` },
        onProgress: cb,
        metadata: {
          blockSize: `${blockSize}`,
          currentHash: mdHash,
          contentType: file.type
        }
      });
    } catch (err) {
      throw 'Error during upload file';
    }
  }
}
