<div class="sim-input-container" fxLayout="column">
  <ng-content select="label"></ng-content>
  <ng-content select="mat-label"></ng-content>
  <ng-content select="sim-input"></ng-content>
  <ng-content select="input"></ng-content>
  <ng-content select="sim-datepicker"></ng-content>
  <ng-content select="sim-checkbox"></ng-content>
  <ng-content select="textarea"></ng-content>

  <!-- @deprecated -->
  <ng-content select="mat-select"></ng-content>
  <ng-content select="hint"></ng-content>
  <ng-content select="mat-chip-list"></ng-content>
  <ng-content select="mat-checkbox"></ng-content>
  <!-- /@deprecated -->

  <ng-content select="sim-select"></ng-content>
  <ng-content select="sim-hint"></ng-content>
  <ng-content select="ui-hint, [uiHint]"></ng-content>
  <ng-content select="sim-chip-list"></ng-content>
</div>
