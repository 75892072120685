import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { UploadApiService } from '@simOn/common/upload-queue/data-access';
import { TicketsApiService } from '@simOn/ticket/element/data-access';
import { TTicketState, TUpdateTicket } from '@simOn/ticket/element/models';
import {
  Subject,
  catchError,
  concat,
  distinctUntilChanged,
  exhaustMap,
  filter,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  timer,
  withLatestFrom
} from 'rxjs';
import {
  AddTicket,
  AddTicketComment,
  AddTicketCommentSuccess,
  AddTicketFailure,
  AddTicketSuccess,
  ClearTicketState,
  DeleteTicket,
  DeleteTicketComment,
  DeleteTicketCommentFailure,
  DeleteTicketCommentSuccess,
  DeleteTicketFailure,
  DeleteTicketSuccess,
  DeleteUploadedFileSimplified,
  DeleteUploadedFileSimplifiedFailure,
  DeleteUploadedFileSimplifiedSuccess,
  GetTicket,
  GetTicketFailure,
  GetTicketSuccess,
  GetTickets,
  GetTicketsFailure,
  GetTicketsSuccess,
  HandleTicketModified,
  HandleTicketModifiedSuccess,
  MarkTicketAsRead,
  MarkTicketAsReadSuccess,
  SetSelectedId,
  UpdateTicket,
  UpdateTicketComment,
  UpdateTicketCommentFailure,
  UpdateTicketCommentSuccess,
  UpdateTicketFailure,
  UpdateTicketSuccess,
  UploadFileWithProgress,
  UploadFileWithProgressCompleted,
  UploadFileWithProgressFailure,
  UploadFileWithProgressPending,
  UploadFileWithProgressStarted,
  UploadFileWithProgressSuccess
} from './ticket.actions';
import { TicketFacade } from './ticket.facade';
import { GetSelectedTicket } from './ticket.selectors';

@Injectable()
export class TicketEffects {
  private readonly actions$ = inject(Actions);
  private readonly ticketsApiService = inject(TicketsApiService);
  private readonly mediaService = inject(UploadApiService);
  private readonly ticketFacade = inject(TicketFacade);
  private readonly store = inject(Store);
  private readonly _destroySource: Subject<void> = new Subject<void>();

  getTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTicket),
      mergeMap((result) =>
        this.ticketsApiService.getTicket$(result.state).pipe(
          map((response) => GetTicketSuccess({ state: response })),
          catchError((error) => of(GetTicketFailure(error)))
        )
      )
    )
  );

  markTicketAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarkTicketAsRead),
      mergeMap((result) =>
        this.ticketsApiService.markTicketAsRead$(result.ticketId).pipe(
          map(() => MarkTicketAsReadSuccess({ ticketId: result.ticketId })),
          catchError((error) => of(GetTicketFailure(error)))
        )
      )
    )
  );

  getTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetTickets),
      tap(() => this._destroySource.next()),
      mergeMap((action) => {
        if (action.autoUpdate) {
          //TODO: (olek) sprawdzić zalogowanie
          return timer(0, 30000).pipe(
            switchMap(() => this.ticketFacade.preventRefresh$.pipe(take(1))),
            filter((preventRefresh) => preventRefresh === false),
            switchMap(() => this.ticketsApiService.getTickets$()),
            takeUntil(this._destroySource)
          );
        } else {
          return this.ticketsApiService.getTickets$();
        }
      }),
      filter((tickets): tickets is TTicketState[] => !!tickets),
      map((response) => GetTicketsSuccess({ state: response })),
      catchError((error) => of(GetTicketsFailure(error)))
    )
  );

  addTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTicket),
      exhaustMap((result) =>
        this.ticketsApiService.createTicket$(result.state).pipe(
          tap(() => this.ticketFacade.deleteLocalTicket()),
          mergeMap((response) => concat([AddTicketSuccess(), GetTicket({ state: response })])),
          catchError((error) =>
            of(
              AddTicketFailure({
                state: {
                  message: error,
                  name: 'error'
                }
              })
            )
          )
        )
      )
    )
  );

  updateTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateTicket),
      mergeMap((result) =>
        iif(
          () => result.state.id === '',
          of(result.state).pipe(map(() => UpdateTicketSuccess())),
          of(true).pipe(
            switchMap(() => this.ticketsApiService.updateTicket$(result.state)),
            switchMap(() => concat([UpdateTicketSuccess(), GetTicket({ state: result.state.id! })])),
            catchError((error) => {
              return of(
                UpdateTicketFailure({
                  state: {
                    message: error,
                    name: 'error'
                  }
                })
              );
            })
          )
        )
      )
    )
  );

  handleTicketModified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HandleTicketModified),
      mergeMap((result) => this.ticketsApiService.getTicket$(result.ticketId)),
      switchMap((response) =>
        concat([GetTicketSuccess({ state: response }), HandleTicketModifiedSuccess({ ticket: response })])
      )
    )
  );

  deleteTicket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteTicket),
      exhaustMap((result) =>
        this.ticketsApiService.deleteTicket$(result.state).pipe(
          map(() => result),
          map((response) => DeleteTicketSuccess(response)),
          catchError((error) => of(DeleteTicketFailure(error)))
        )
      )
    )
  );

  addTicketComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddTicketComment),
      mergeMap((result) =>
        this.ticketsApiService.addTicketComment$(result.state.ticketComment).pipe(
          map((ticketComment) => ({
            result,
            ticketComment
          }))
        )
      ),
      map((response) => {
        return AddTicketCommentSuccess({
          state: { ticketId: response.result.state.ticketId, ticketComment: response.ticketComment }
        });
      }),
      catchError((error) => of(AddTicketCommentSuccess(error)))
    )
  );

  updateTicketComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateTicketComment),
      mergeMap((result) =>
        this.ticketsApiService.updateTicketComment$(result.state.ticketComment).pipe(
          map(() => result),
          map((response) => UpdateTicketCommentSuccess(response)),
          catchError((error) => of(UpdateTicketCommentFailure(error)))
        )
      )
    )
  );

  deleteTicketComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteTicketComment),
      exhaustMap((result) => this.ticketsApiService.deleteTicketComment$(result.state).pipe(map(() => result))),
      switchMap((result) =>
        this.ticketFacade.selectedTicketId$.pipe(
          map((id: string | undefined) => {
            this.ticketFacade.getTicket(id!);
            return result;
          })
        )
      ),
      map((response) => DeleteTicketCommentSuccess(response)),
      catchError((error) => of(DeleteTicketCommentFailure(error)))
    )
  );

  uploadFileWithProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadFileWithProgress),
      withLatestFrom(this.store.select(GetSelectedTicket)),

      mergeMap(([props, ticket]) => {
        return this.mediaService.uploadFileWithProgress().pipe(
          takeUntil(this.actions$.pipe(ofType(UploadFileWithProgressSuccess))),
          map((event) => {
            return this._getActionFromHttpEvent(event, ticket!, props.fileName);
          }),
          catchError((error) => of(UploadFileWithProgressFailure(error)))
        );
      }),
      catchError((error) => of(UploadFileWithProgressFailure(error)))
    )
  );

  uploadFileWithProgressSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadFileWithProgressSuccess),
      map((result) => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const ticket: TUpdateTicket = {
          ...result.state,
          dueDate: tomorrow.getTime() > new Date(result.state.dueDate!).getTime() ? undefined : result.state.dueDate
        };
        return UpdateTicket({ state: ticket });
      }),
      catchError((error) => of(UploadFileWithProgressFailure(error)))
    )
  );

  deleteUploadedFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteUploadedFileSimplified),
      withLatestFrom(this.store.select(GetSelectedTicket)),
      exhaustMap(([result, ticket]) =>
        this.ticketsApiService
          .deleteAttachmentsFromTicket$(ticket!.id!, [result.state.fileGuid])
          .pipe(map(() => ticket!.id))
      ),
      mergeMap((ticketId) => of(GetTicket({ state: ticketId! }), DeleteUploadedFileSimplifiedSuccess())),
      catchError((error) => of(DeleteUploadedFileSimplifiedFailure(error)))
    )
  );

  selectedTicketId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedId),
      distinctUntilChanged((previous, current) => previous.selectedId === current.selectedId),
      mergeMap(({ selectedId }) => of(GetTicket({ state: selectedId })))
    )
  );

  clearState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClearTicketState),
        tap(() => this._destroySource.next())
      ),
    { dispatch: false }
  );

  private _getActionFromHttpEvent(event: HttpEvent<any>, ticket: TTicketState, fileName: string) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return UploadFileWithProgressPending({
          state: { total: event.total!, loaded: event.loaded, fileName: fileName }
        });
      case HttpEventType.ResponseHeader: {
        return UploadFileWithProgressCompleted();
      }
      case HttpEventType.Response: {
        if (event.status === 200) {
          const fileGuid: string = event.body;
          const body: TUpdateTicket = {
            ...ticket,
            assigneeIds: ticket.ticketAssignments.map((assignee) => assignee.assigneeId),
            id: ticket.id!,
            uploadedAttachments: [fileGuid]
          };

          return UploadFileWithProgressSuccess({ state: body, fileName: fileName });
        } else {
          return UploadFileWithProgressFailure({ state: ticket.ticketName });
        }
      }

      case HttpEventType.Sent:
        return UploadFileWithProgressStarted();
      case HttpEventType.DownloadProgress: {
        return GetTicket({ state: ticket.id! });
      }
      default:
        return UploadFileWithProgressFailure({ state: ticket.ticketName });
    }
  }
}
