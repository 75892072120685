import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import {
  EVerifyComponentEnum,
  TExternalScene,
  TFibaroScene,
  TSamsungScene,
  UExternalScenes
} from '@simOn/scene/common';

import {
  SceneShortcutBodyInterface,
  TSceneState,
  TSimlabSceneInterface,
  TSimlabSceneInterfaceBase,
  TSimlabSceneInterfaceDTO,
  TSimlabScenesInterfaceDTO
} from '@simOn/scene/element/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable, catchError, map, merge, of, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScenesApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getExternalProviderScene(
    provider: SmartApiProviderEnum,
    cb: () => Observable<TExternalScene<UExternalScenes>[]>
  ): Observable<TSceneState[] | undefined> {
    return cb().pipe(
      map((response: TExternalScene<Omit<UExternalScenes, 'masterDeviceId'>>[]) => {
        if (!response) return undefined;
        return response.reduce(
          (previousValue: TSceneState[], currentValue: TExternalScene<Omit<UExternalScenes, 'masterDeviceId'>>) => {
            if (!currentValue.scenes) return previousValue;
            previousValue.push(
              ...currentValue.scenes.map(
                (scene: Omit<UExternalScenes, 'masterDeviceId'>) =>
                  ({
                    ...scene,
                    masterDeviceId: currentValue.masterDeviceId,
                    smartApiProvider: provider,
                    id: `${currentValue.masterDeviceId}${scene.sceneId}`
                  } as TSceneState)
              )
            );
            return previousValue;
          },
          [] as TSceneState[]
        );
      }),
      take(1),
      catchError(() => of([]))
    );
  }
  loadScenesFromApartment$(): Observable<TSceneState[] | undefined> {
    const samsungScenes$ = this.getExternalProviderScene(
      SmartApiProviderEnum.SAMSUNG_ST,
      this.getSamsungScenes.bind(this)
    );
    const fibaroScenes$ = this.getExternalProviderScene(SmartApiProviderEnum.FIBARO, this.getFibaroScenes.bind(this));

    return merge(this.loadSimlabScenes$(), samsungScenes$, fibaroScenes$);
  }

  loadSimlabScenes$(): Observable<TSceneState[]> {
    return this.getSimlabScenes().pipe(
      map((scenes: TSimlabScenesInterfaceDTO[]) =>
        scenes.map((scene: TSimlabScenesInterfaceDTO) => ({ ...scene, smartApiProvider: 'SIMLAB' } as TSceneState))
      )
    );
  }

  getSamsungScenes(): Observable<TExternalScene<TSamsungScene>[]> {
    return this.http.get<TExternalScene<TSamsungScene>[]>(`${this._apiUrl}/SamsungScenes/GetSamsungScenes`);
  }

  getFibaroScenes(): Observable<TExternalScene<TFibaroScene>[]> {
    return this.http.get<TExternalScene<TFibaroScene>[]>(`${this._apiUrl}/FibaroScenes/GetFibaroScenes`);
  }

  getSimlabScenes(): Observable<TSimlabScenesInterfaceDTO[]> {
    return this.http.get<TSimlabScenesInterfaceDTO[]>(`${this._apiUrl}/Scenes/GetSimlabScenes`);
  }

  getSimlabScene(
    simlabSceneId: string,
    verifyComponents: EVerifyComponentEnum = EVerifyComponentEnum.NONE
  ): Observable<TSimlabSceneInterfaceDTO> {
    return this.http.get<TSimlabSceneInterfaceDTO>(`${this._apiUrl}/Scenes/GetSimlabScene?SceneId=${simlabSceneId}`);
  }

  runSimlabScene(simlabSceneId: string): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Scenes/RunSimlabScene`, { simlabSceneId });
  }

  addSimlabScene(scene: TSimlabSceneInterfaceBase): Observable<TSimlabSceneInterface> {
    return this.http.post<TSimlabSceneInterface>(`${this._apiUrl}/Scenes/AddSimlabScene`, scene);
  }

  updateSimlabScene(scene: TSimlabSceneInterfaceBase): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Scenes/UpdateSimlabScene`, scene);
  }

  deleteSimlabScene(simlabSceneId: string): Observable<string> {
    //@TODO ŁM add type
    return this.http.delete<string>(`${this._apiUrl}/Scenes/DeleteSimlabScene?simlabSceneId=${simlabSceneId}`);
  }

  addSceneShortcut(shortcut: SceneShortcutBodyInterface): Observable<SceneShortcutBodyInterface> {
    return this.http.post<SceneShortcutBodyInterface>(`${this._apiUrl}/Scenes/AddSimlabSceneShortcut`, shortcut);
  }

  updateSceneShortcut(shortcut: SceneShortcutBodyInterface): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Scenes/UpdateSimlabSceneShortcut`, shortcut);
  }

  changeSimlabSceneStatus(sceneId: string, isActive: boolean): Observable<void> {
    return this.http.put<void>(`${this._apiUrl}/Scenes/ChangeSimlabSceneActivityStatus`, { sceneId, isActive });
  }

  deleteSceneShortcut(shortcutId: string): Observable<void> {
    return this.http.delete<void>(
      `${this._apiUrl}/Scenes/DeleteSimlabSceneShortcut?simlabSceneShortcutId=${shortcutId}`
    );
  }
}
