import { Injectable } from '@angular/core';
import { PaymentPlan } from '@simOn/subscription/plans/models';

//NOTE:(olek) - move to order ep ?
@Injectable()
export class SimSubscriptionControllerService {
  private _selectedCountry!: string;
  private _selectedPlan!: PaymentPlan;

  public get selectedPlan(): PaymentPlan {
    return this._selectedPlan;
  }
  public set selectedPlan(value: PaymentPlan) {
    this._selectedPlan = value;
  }

  public get selectedCountry(): string {
    return this._selectedCountry;
  }
  public set selectedCountry(value: string) {
    this._selectedCountry = value;
  }
}
