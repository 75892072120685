import { ESceneComponentType, TSceneError } from '@simOn/common/scene-models';

export interface TExternalScene<T extends Omit<UExternalScenes, 'masterDeviceId'>> {
  masterDeviceId: string;
  masterDeviceName: string;
  connectionFailed: boolean;
  connectionFailReason: string;
  scenes: T[] | null;
}

export type TExternalSceneBase = {
  sceneId: string;
  name: string;
  iconId: number;
  masterDeviceId: string;
};
export type TFibaroScene = {
  password: string;
  protectedByPIN: boolean;
} & TExternalSceneBase;

export type TSamsungScene = TExternalSceneBase;
export type UExternalScenes = TFibaroScene | TSamsungScene;

export type SceneComponent = {
  deviceAction: any;
  delayAction: TDelayAction;
  samsungScene: TSamsungScene;
  fibaroScene: TFibaroScene;
};

export type TDelayAction = {
  delayInMilliseconds: number;
};
export type TSceneExecutionComponentBase<T extends keyof typeof ESceneComponentType> = {
  id: string;
  executionIndex: number;
  trackByIdx: string | number | undefined;
  componentType: (typeof ESceneComponentType)[T];
} & TSceneError;

export type TSceneComponentKeys = keyof typeof ESceneComponentType;

export type TSceneExecutionComponent<T extends TSceneComponentKeys = any> = TSceneExecutionComponentBase<T> &
  Pick<SceneComponent, T>;

export enum EVerifyComponentEnum {
  ALL,
  NONE
}
