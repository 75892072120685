import { createFeatureSelector, createSelector } from '@ngrx/store';
import { copy, IsCorrectTransformation } from '@simOn/common/helpers';
import { SceneShortcutBodyInterface, TSceneState, TSimlabScenesInterfaceDTO } from '@simOn/scene/element/models';
import * as ScenesReducer from './scene.reducers';

export const scenesSelectors = createFeatureSelector<ScenesReducer.State>('scenes');

export const GetAllScenes = createSelector(scenesSelectors, ScenesReducer.selectAll);
export const SceneEntity = createSelector(scenesSelectors, (state) => state.entities);
export const GetExternalScenes = createSelector(scenesSelectors, GetAllScenes, (state: ScenesReducer.State) => {
  return Object.values(state.entities).filter((scene) => scene && scene.smartApiProvider !== 'SIMLAB');
});

export const GetScenesSimlab = createSelector(scenesSelectors, GetAllScenes, (state: ScenesReducer.State) => {
  return Object.values(state.entities).filter(
    (scene) => scene && scene.smartApiProvider === 'SIMLAB'
  ) as TSceneState<TSimlabScenesInterfaceDTO>[];
});

export const GetSimlabScenesShortcuts = createSelector(GetScenesSimlab, (scenes) => {
  return scenes
    .filter((scene) => scene.shortcuts && scene.shortcuts.length > 0)
    .reduce((acc: SceneShortcutBodyInterface[], el: TSceneState | undefined) => {
      const _scene: TSimlabScenesInterfaceDTO = el as TSimlabScenesInterfaceDTO;
      let _shortcuts = copy(_scene.shortcuts);
      _shortcuts =
        _shortcuts &&
        _shortcuts
          .map((shortcut: SceneShortcutBodyInterface) => ({
            ...shortcut,
            smartApiProvider: el && el.smartApiProvider
          }))
          .filter((shortcut: SceneShortcutBodyInterface) => IsCorrectTransformation(shortcut.transformation));
      return [...acc, ...(_shortcuts || ([] as SceneShortcutBodyInterface[]))];
    }, [] as SceneShortcutBodyInterface[]);
});

export const GetSimlabSceneShortcutsBySceneId = (id: string) =>
  createSelector(GetSimlabScenesShortcuts, (scenesShortcuts: SceneShortcutBodyInterface[]) => {
    return scenesShortcuts.filter((sceneShortcut: SceneShortcutBodyInterface) => sceneShortcut.sceneId === id);
  });

// export const GetDeletedTriggers = (existingIds: string[], sceneId: string) =>
//   createSelector(scenesSelectors, (state) => {
//     return (state.entities[sceneId] as TSceneState)?.scheduledTriggers
//       ?.filter((trigg: TriggerInterface) => !existingIds.includes(trigg.id))
//       .map((trigg: TriggerInterface) => {
//         return {
//           triggerId: trigg.id,
//           sceneId: trigg.sceneId,
//           apartmentId: (state.entities[sceneId] as TSceneState).apartmentId
//         };
//       });
//   });

export const GetSceneById = (sceneId: string) => createSelector(scenesSelectors, (state) => state.entities[sceneId]);
