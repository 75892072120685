import { Euler, Vector3 } from 'three';

export abstract class ThreeObjectBase {
  _threeContext: any;
  abstract init(threeContext: any, configuration: any): void;
  abstract set position(position: Vector3);
  abstract set rotation(rotation: Euler);
  abstract set scale(scale: Vector3);
  abstract show(): void;
  abstract hide(): void;
  abstract get object3D(): THREE.Sprite | THREE.Mesh | THREE.Group;
  set threeContext(threeContext: any) {
    this._threeContext = threeContext;
  }
}
