import { TLanguage } from '@simOn/common/language';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import { EnergyInformation, PreferencesUnitsInterface, SHORT_UNITS, Unit, UNIT_TRANSFORM } from '@simOn/common/units';

import { Dictionary } from '@ngrx/entity';
import { TransformationInterface } from '@simOn/common/matterport';

import { UAnimatedIcon } from '@simOn/ui/sim-animated-icons';
import { Observable } from 'rxjs';
import {
  DeviceCategoryCollectionInterface,
  DeviceCategoryEntityInterface,
  DeviceCategoryValueType
} from './device-category';
import { SimlabDeviceTypeEnum } from './device-simlab-enum';

export const ThermostatMode = {
  Unknown: 0,
  Off: 1,
  Heat: 2,
  Max: 3
} as const;
export type ThermostatModeType = keyof typeof ThermostatMode;

export const SENSOR_MAPPER: Record<
  SensorProperties,
  {
    1: string;
    0: string;
  }
> = {
  MotionDetected: {
    1: $localize`:@@DEVICE_MOTION_DETECTED:MOTION DETECTED`,
    0: $localize`:@@DEVICE_NO_MOTION:NO MOTION`
  },
  SmokeDetected: {
    1: $localize`:@@DEVICE_SMOKE_DETECTED:SMOKE DETECTED`,
    0: $localize`:@@DEVICE_NO_WARNING:NO WARNING`
  },
  HeatDetected: {
    1: $localize`:@@DEVICE_HEAT_DETECTED:HEAT DETECTED`,
    0: $localize`:@@DEVICE_NO_WARNING:NO WARNING`
  },
  CoDetected: {
    1: $localize`:@@DEVICE_CO_DETECTED:CO DETECTED`,
    0: $localize`:@@DEVICE_NO_WARNING:NO WARNING`
  },
  FloodDetected: {
    1: $localize`:@@DEVICE_FLOOD_DETECTED:FLOOD DETECTED`,
    0: $localize`:@@DEVICE_NO_WARNING:NO WARNING`
  },
  ShakeDetected: {
    1: $localize`:@@DEVICE_SHAKING:SHAKING`,
    0: $localize`:@@DEVICE_STEADY:STEADY`
  },
  GasDetected: {
    1: $localize`:@@DEVICE_GAS_DETECTED:GAS DETECTED`,
    0: $localize`:@@DEVICE_NO_WARNING:NO WARNING`
  },
  LowBatteryDetected: {
    1: '',
    0: ''
  },
  Opened: {
    1: $localize`:@@DEVICE_OPENED:OPENED`,
    0: $localize`:@@DEVICE_CLOSED:CLOSED`
  },
  AlarmTriggered: {
    1: $localize`:@@DEVICE_ALARM_TRIGGERED:ALARM TRIGGERED`,
    0: $localize`:@@DEVICE_NO_WARNING:NO WARNING`
  }
};

export const paramJSON = <K>(parameters: string | number | K): K =>
  typeof parameters === 'string' || typeof parameters === 'number' ? JSON.parse(parameters.toString()) : parameters;

export type TDeviceStatus = (typeof DEVICE_ALL_STATUS)[number];

export type TDeviceErrorAndStatus = {
  [Prop in TDeviceStatus]: { label: string; status: Prop; icon: UAnimatedIcon | undefined; propertyType: Properties };
};

export const STATUS_DEVICE: Record<TDeviceStatus, string> = {
  OK: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_OK:OK`,
  Subdevices_hidden: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_SUBDEVICES_HIDDEN:Subdevices hidden`,
  Unknown: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_UNKNOWN:Unknown`,
  Disconnected: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_DISCONNECTED:Disconnected`,
  Disabled: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_DISABLED:Disabled`,
  Unauthorized: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_UNAUTHORIZED:Unauthorized`,
  CantFetchHomeCenterInfo: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_CANT_FETCH_HOME_CENTER_INFO:Can't fetch home center info`,
  HomeCenterMalfunction: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_CANT_FETCH_HOME_CENTER_MALFUNCTION:Home central malfunction`,
  Unsupported: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_UNSUPPORTED:Unsupported`,
  NotFound: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_NOT_FOUND:Not found`,
  Blocked: $localize`:@@PROVIDERS_DEVICE_LIST_STATUS_BLOCKED:Blocked`
};

export const ProviderComponentWithProperties = {
  SmartThingsProperty: 13,
  WiserKNXProperty: 23,
  WiserHomeProperty: 31,
  FibaroProperty: 42,
  NETxProperty: 53
};
export const ProviderComponentType = {
  Unknown: 0,
  SmartThingsDevice: 10,
  SmartThingsComponent: 11,
  SmartThingsCapability: 12,
  WiserKNXWidget: 20,
  WiserKNXFunction: 21,
  WiserKNXDatapoint: 22,
  WiserHomeDevice: 30,
  FibaroParentDevice: 40,
  FibaroChildDevice: 41,
  NETxElement: 50,
  ...ProviderComponentWithProperties
} as const;

export type ComponentType = keyof typeof ProviderComponentType;

export interface DeviceProperties {
  id: string;
  status: TDeviceStatus;
  battery: DeviceFooterInformation | undefined;
  power: DeviceFooterInformation | undefined;
  energy: DeviceFooterInformation | undefined;
  wakeUpSupported: boolean;
  property: SimlabPropertyInterface<Properties> | null;
  commands: SimlabCommandInterface<CommandKeys>[] | null;
}

export const UNIT_TO_USER_PREF: {
  [name in Unit]: keyof PreferencesUnitsInterface | undefined;
} = {
  Unknown: undefined,
  None: undefined,
  kWh: 'energy',
  Wh: 'energy',
  mWh: 'energy',
  kVAh: undefined,
  Joule: 'energy',
  Watt: 'power',
  Celsius: 'temperature',
  Fahrenheit: 'temperature',
  Kelvin: 'temperature',
  MilesPerHour: undefined,
  Knots: undefined,
  MetersPerSecond: undefined,
  FeetPerSecond: undefined,
  KilometersPerHour: undefined,
  Percentage: undefined,
  Lux: undefined,
  Lumen: undefined,
  mG: undefined,
  ppm: undefined,
  mgForm3: undefined,
  Miles: undefined,
  Kilometers: undefined,
  Meters: undefined,
  Volt: undefined,
  MMI: undefined,
  Currency: 'currency',
  Ampere: undefined
};

export interface UserPreferencesInterface {
  showUnauthorizedUserModal: boolean;
  autoReportErrors: boolean;
  units: PreferencesUnitsInterface;
  priceForOneEnergyUnit: number;
  priceCurrency: string;
  showPowerAsPrice: boolean;
  openAttachmentInNewTab: boolean;
  languageMode: TLanguage;
}

export function UserPrefEnergyConsumption(energy: EnergyInformation, preferences: UserPreferencesInterface) {
  const timeUnit = '/h';
  const currency = 'Currency' as Unit;
  const value = energy.value;
  const srcUnit = energy.unit as Unit;
  const unit = UNIT_TO_USER_PREF[srcUnit];
  const dstUnit = preferences.showPowerAsPrice ? currency : unit && preferences.units[unit];
  const shortUnit = preferences.showPowerAsPrice
    ? preferences.priceCurrency + timeUnit
    : dstUnit
    ? SHORT_UNITS[dstUnit]
    : SHORT_UNITS[srcUnit];
  const userPrefValue = preferences.showPowerAsPrice
    ? '≈ ' + userPrefValueTransform(srcUnit, dstUnit, value, preferences.priceForOneEnergyUnit)
    : userPrefValueTransform(srcUnit, dstUnit, value);

  return `${userPrefValue} ${shortUnit}`;
}

export const userPrefValueTransform = (
  srcUnit: Unit,
  dstUnit: Unit | undefined,
  value: number | string | boolean,
  currency?: number | string | boolean
): number | string | boolean => {
  if (!dstUnit) return value;
  return UNIT_TRANSFORM[srcUnit]?.[dstUnit]
    ? UNIT_TRANSFORM[srcUnit]![dstUnit]!(value as number, currency as number)
    : value;
};

export type SimpleValue<T> = {
  value: T;
};
export type SensorValue<T> = SimpleValue<T> & {
  lastBreached: 0 | string | undefined;
};
export type XYZValue<T> = {
  x: T;
  y: T;
  z: T;
};
export type RGBWValue<T> = {
  r: T;
  g: T;
  b: T;
  w: T;
};

export type LevelSettings = {
  min: number;
  max: number;
  step: number;
};

export type SetThermostatMode = { availableModes: string[] };
export type WakeUpSupported = { wakeUpSupported: boolean };
export type SetNumber = { min: number; max: number };
export interface ProviderErrorProperty {
  ProviderError: SimpleValue<string>;
}

export interface SubscriptionLimitProperty {
  SubscriptionLimit: SimpleValue<string>;
}

export const AlarmSensors = [
  'AlarmTriggered',
  'SmokeDetected',
  'HeatDetected',
  'CoDetected',
  'FloodDetected',
  'ShakeDetected',
  'GasDetected'
] as const;
export type AlarmSensorPropertyValuesType = {
  [Prop in AlarmSensorProperties]: SensorValue<boolean>;
};

export const OtherSensors = ['MotionDetected', 'LowBatteryDetected', 'Opened'] as const;

export const AllSensors: Partial<Properties>[] = [...AlarmSensors, ...OtherSensors];
export type OtherSensorPropertyValuesType = {
  [Prop in OtherSensorProperties]: SensorValue<boolean>;
};

export type SensorPropertyValuesType = AlarmSensorPropertyValuesType & OtherSensorPropertyValuesType;

export type PropertyValuesType = ProviderErrorProperty &
  SubscriptionLimitProperty &
  SensorPropertyValuesType & {
    Unknown: undefined;
    Weather: string;
    Temperature: SimpleValue<number>;
    WindSpeed: SimpleValue<number>;
    LightIntensity: SimpleValue<number>;
    EarthTremor: SimpleValue<number>;
    Acceleration3D: XYZValue<number>;
    TurnedOn: SimpleValue<boolean>;
    SetTemperature: SimpleValue<number>;
    Clicked: string;
    BatteryLevel: SimpleValue<number>;
    PowerConsumption: SimpleValue<number>;
    EnergyConsumption: SimpleValue<number>;
    Level: SimpleValue<number>;
    RgbwColor: RGBWValue<number | string>;
    ExternalSensorConnected: SimpleValue<boolean>;
    RainDetector: SimpleValue<boolean>;
    NextScheduledTemperatureLevel: SimpleValue<number>;
    NextScheduledTemperatureTime: SimpleValue<number>;
    SetThermostatMode: SimpleValue<ThermostatModeType>;
    ScheduleOverridden: SimpleValue<boolean>;
    StaticLevel: SimpleValue<number>;
    Humidity: SimpleValue<number>;
    Co2Level: SimpleValue<number>;
    HeatingMode: SimpleValue<HeatingModeType>;
    TemperatureShift: SimpleValue<number>;
    HvacOperationMode: SimpleValue<HvacOperationModeType>;
    Scene: SimpleValue<number>;
    AudioVolume: SimpleValue<number>;
    TvChannel: SimpleValue<string>;
    TvChannelName: SimpleValue<string>;
    Boolean: SimpleValue<boolean | null | undefined>;
    Integer: SimpleValue<number | null | undefined>;
    String: SimpleValue<string | null | undefined>;
    UInteger: SimpleValue<number | null | undefined>;
    Number: SimpleValue<number | null | undefined>;
    DateTime: SimpleValue<string | null | undefined>;
    LevelRollerShutter: SimpleValue<number>;
    LevelDimmer: SimpleValue<number>;
  };

export type PropertiesSettings = {
  AlarmTriggered: '';
  SmokeDetected: '';
  HeatDetected: '';
  CoDetected: '';
  FloodDetected: '';
  ShakeDetected: '';
  GasDetected: '';
  MotionDetected: '';
  ProviderError: WakeUpSupported;
  LowBatteryDetected: '';
  Opened: '';
  Unknown: '';
  Weather: '';
  WindSpeed: '';
  Temperature: LevelSettings;
  LightIntensity: '';
  EarthTremor: '';
  Acceleration3D: '';
  TurnedOn: '';
  SetTemperature: LevelSettings;
  Clicked: '';
  BatteryLevel: '';
  PowerConsumption: '';
  EnergyConsumption: '';
  LastBreached: '';
  Level: LevelSettings;
  RgbwColor: '';
  ExternalSensorConnected: '';
  RainDetector: '';
  NextScheduledTemperatureLevel: '';
  NextScheduledTemperatureTime: '';
  SetThermostatMode: SetThermostatMode;
  ScheduleOverridden: '';
  StaticLevel: '';
  Humidity: '';
  Co2Level: '';
  HeatingMode: '';
  TemperatureShift: '';
  HvacOperationMode: '';
  Scene: '';
  AudioVolume: '';
  TvChannel: '';
  TvChannelName: '';
  SubscriptionLimit: '';
  // NETx
  Boolean: { value: string[] };
  Integer: SetNumber;
  String: '';
  UInteger: SetNumber;
  Number: SetNumber;
  DateTime: '';
  LevelRollerShutter: LevelSettings;
  LevelDimmer: LevelSettings;
};
export type AlarmSensorProperties = (typeof AlarmSensors)[number];
export type OtherSensorProperties = (typeof OtherSensors)[number];

export type SensorProperties = keyof SensorPropertyValuesType;
export type Properties = keyof PropertyValuesType;
export type TypeValues<TObj, TProp extends keyof TObj> = TObj[TProp];

export type PropertySettings<T extends Properties> = {
  [Prop in keyof TypeValues<PropertyValuesType, T>]: TypeValues<PropertiesSettings, T>;
};

export type SimlabPropertyInterface<T extends Properties> = {
  type: T;
  values: TypeValues<PropertyValuesType, T>;
  settings: PropertySettings<T> | undefined | never;
  unit: Unit;
  supportedCommands: CommandKeys[];
};
export const DEVICE_ERROR_STATUSES = [
  `Disconnected`,
  `Disabled`,
  `Unauthorized`,
  `CantFetchHomeCenterInfo`,
  `HomeCenterMalfunction`,
  `Unsupported`,
  `NotFound`
] as const;

export const SIMLAB_STATUS = [`Blocked`] as const;
export const DEVICE_ALL_STATUS = [
  'OK',
  'Subdevices_hidden',
  'Unknown',
  ...DEVICE_ERROR_STATUSES,
  ...SIMLAB_STATUS
] as const;

// export type TDeviceStatus = (typeof DEVICE_ALL_STATUS)[number];

// export type TDeviceErrorAndStatus = {
//   [Prop in TDeviceStatus]: { label: string; status: Prop; icon: UAnimatedIcon | undefined; propertyType: Properties };
// };

export const SUPPORTED_PROPERTIES: Record<Properties, boolean> = {
  MotionDetected: true,
  SmokeDetected: true,
  HeatDetected: true,
  Opened: true,
  CoDetected: true,
  FloodDetected: true,
  WindSpeed: true,
  LightIntensity: true,
  EarthTremor: true,
  Clicked: true,
  Temperature: true,
  Acceleration3D: true,
  TurnedOn: true,
  SetTemperature: true,
  Level: true,
  BatteryLevel: true,
  RgbwColor: true,
  PowerConsumption: true,
  EnergyConsumption: true,
  ShakeDetected: true,
  GasDetected: true,
  AlarmTriggered: true,
  StaticLevel: true,
  Humidity: true,
  Co2Level: true,
  Scene: true,
  Unknown: false,
  Weather: false,
  ExternalSensorConnected: false,
  RainDetector: false,
  NextScheduledTemperatureLevel: false,
  NextScheduledTemperatureTime: false,
  SetThermostatMode: false,
  ScheduleOverridden: false,
  LowBatteryDetected: false,
  HeatingMode: false,
  TemperatureShift: false,
  HvacOperationMode: false,
  AudioVolume: false,
  TvChannel: false,
  TvChannelName: false,
  ProviderError: false,
  SubscriptionLimit: false,
  Boolean: false,
  Integer: false,
  String: false,
  UInteger: false,
  Number: false,
  DateTime: false,
  LevelRollerShutter: false,
  LevelDimmer: false
};

export const UNSUPPORTED_DEVICE_MOCK: DeviceDto = {
  id: '',
  apartmentId: '',
  roomId: '',
  parentId: '',
  simlabDeviceId: '',
  smartApiProvider: SmartApiProviderEnum.FIBARO,
  positionDeleted: false,
  status: 'Unsupported',
  orderIndex: 0,
  visible: false,
  isBlockedBySubscriptionLimit: false,
  simlabDeviceType: 'Unsupported',
  providerComponentType: 'Unknown',
  commands: [],
  property: {
    type: 'Unknown',
    values: {
      value: 'Unsupported'
    },
    settings: undefined,
    unit: 'None',
    supportedCommands: []
  },
  shortcuts: [],
  icon: 'device_unknown',
  name: '',
  inProviderName: '',
  masterDeviceId: '',
  inProviderId: '',
  children: [],
  propertyType: 'Unknown',
  battery: undefined,
  power: undefined,
  energy: undefined,
  wakeUpSupported: false
};
export const ALL_PROPERTIES_HIDDEN_MOCK: DeviceDto = {
  id: '',
  apartmentId: '',
  roomId: '',
  parentId: '',
  simlabDeviceId: '',
  smartApiProvider: SmartApiProviderEnum.FIBARO,
  positionDeleted: false,
  status: 'Subdevices_hidden',
  orderIndex: 0,
  visible: false,
  isBlockedBySubscriptionLimit: false,
  simlabDeviceType: 'Unsupported',
  providerComponentType: 'Unknown',
  commands: [],
  property: {
    type: 'Unknown',
    values: {
      value: 'Hidden subdevices'
    },
    settings: undefined,
    unit: 'None',
    supportedCommands: []
  },
  shortcuts: [],
  icon: 'device_unknown',
  name: '',
  inProviderName: '',
  masterDeviceId: '',
  inProviderId: '',
  children: [],
  propertyType: 'Unknown',
  battery: undefined,
  power: undefined,
  energy: undefined,
  wakeUpSupported: false
};

export interface DeviceStructure {
  id: string;
  apartmentId: string;
  roomId: string;
  parentId: string | null;
  simlabDeviceId: string;
  smartApiProvider: SmartApiProviderEnum;
  positionDeleted: boolean;
  orderIndex: number | null;
  isBlockedBySubscriptionLimit: boolean;
  simlabDeviceType: SimlabDeviceType;
  providerComponentType: ComponentType;
  propertyType: Properties;
  shortcuts: SimlabDeviceShortcutDto[] | null;
  name: string;
  inProviderName: string | null;
  masterDeviceId: string | null;
  inProviderId: string | null;
  children: string[];
  visible: boolean;
  icon: UAnimatedIcon;
  fullPath?: string;
}

export type SimlabDeviceType = keyof typeof SimlabDeviceTypeEnum;

export type DeviceDto = DeviceProperties & DeviceStructure;

export interface ImportedDevicesProvider
  extends Pick<DeviceDto, 'id' | 'smartApiProvider' | 'name' | 'status' | 'masterDeviceId'> {
  category: DeviceCategoryValueType;
  roomName: string;
}

export type TUpdateDevice = {
  name: string;
  roomId: string;
  category: DeviceCategoryValueType;
  showIn3D: boolean;
  showInDashboard: boolean;
  showInScenes: boolean;
};

export const HeatingMode = {
  Unknown: 0,
  Heat: 1,
  Cool: 2,
  Off: 3,
  Max: 4
} as const;
export type HeatingModeType = keyof typeof HeatingMode;

export const HvacOperationMode = {
  Auto: 0,
  Comfort: 1,
  Standby: 2,
  Economy: 3,
  BuildingProtection: 4
} as const;
export type HvacOperationModeType = keyof typeof HvacOperationMode;

export const DeviceCategoryCollection: DeviceCategoryCollectionInterface[] = [
  { value: 'Lights', name: $localize`:@@GENERAL_DEVICE_CATEGORY_LIGHTS:Lights`, icon: 'lights' },
  { value: 'Sockets', name: $localize`:@@GENERAL_DEVICE_CATEGORY_SOCKETS:Sockets`, icon: 'sockets' },
  { value: 'Security', name: $localize`:@@GENERAL_DEVICE_CATEGORY_SECURITY:Security`, icon: 'security' },
  { value: 'HVAC', name: $localize`:@@GENERAL_DEVICE_CATEGORY_HVAC:HVAC`, icon: 'hvac' },
  {
    value: 'HomeAppliances',
    name: $localize`:@@GENERAL_DEVICE_CATEGORY_HOME_APPLIANCES:Home Appliances`,
    icon: 'homeappliances'
  },
  {
    value: 'Entertainment',
    name: $localize`:@@GENERAL_DEVICE_CATEGORY_ENTERTAINMENT:Entertainment`,
    icon: 'entertainment'
  },
  { value: 'Meters', name: $localize`:@@GENERAL_DEVICE_CATEGORY_METERS:Meters`, icon: 'meters' },
  { value: 'Blinds', name: $localize`:@@GENERAL_DEVICE_CATEGORY_BLINDS:Blinds`, icon: 'blinds' },
  { value: 'Sensors', name: $localize`:@@GENERAL_DEVICE_CATEGORY_SENSORS:Sensors`, icon: 'sensors' },
  { value: 'Garden', name: $localize`:@@GENERAL_DEVICE_CATEGORY_GARDEN:Garden`, icon: 'garden' },
  { value: 'Other', name: $localize`:@@GENERAL_DEVICE_CATEGORY_OTHER:Other`, icon: 'other' }
];

export const DeviceCategoryEntity: DeviceCategoryEntityInterface = DeviceCategoryCollection.reduce(
  (acc: DeviceCategoryEntityInterface, el: DeviceCategoryCollectionInterface) => {
    acc[el.value] = el;
    return acc;
  },
  {} as DeviceCategoryEntityInterface
);

export const DEVICE_STATUS_INFORMATION: TDeviceErrorAndStatus = {
  Disconnected: {
    label: STATUS_DEVICE.Disconnected,
    status: 'Disconnected',
    icon: 'error_disconnected',
    propertyType: 'ProviderError'
  },
  Disabled: {
    label: STATUS_DEVICE.Disabled,
    status: 'Disabled',
    icon: undefined,
    propertyType: 'ProviderError'
  },
  Unauthorized: {
    label: STATUS_DEVICE.Unauthorized,
    status: 'Unauthorized',
    icon: 'error_provider_unauthorized',
    propertyType: 'ProviderError'
  },
  CantFetchHomeCenterInfo: {
    label: STATUS_DEVICE.CantFetchHomeCenterInfo,
    status: 'CantFetchHomeCenterInfo',
    icon: undefined,
    propertyType: 'ProviderError'
  },
  HomeCenterMalfunction: {
    label: STATUS_DEVICE.HomeCenterMalfunction,
    status: 'HomeCenterMalfunction',
    icon: 'error_home_center',
    propertyType: 'ProviderError'
  },
  Unsupported: {
    label: STATUS_DEVICE.Unsupported,
    status: 'Unsupported',
    icon: undefined,
    propertyType: 'Unknown'
  },
  Blocked: {
    label: STATUS_DEVICE.Blocked,
    status: 'Blocked',
    icon: 'item_locked',
    propertyType: 'SubscriptionLimit'
  },
  OK: {
    label: STATUS_DEVICE.OK,
    status: 'OK',
    icon: undefined,
    propertyType: 'Unknown'
  },
  NotFound: {
    label: STATUS_DEVICE.NotFound,
    status: 'NotFound',
    icon: 'error_disconnected',
    propertyType: 'ProviderError'
  },
  Subdevices_hidden: {
    label: STATUS_DEVICE.Subdevices_hidden,
    status: 'Subdevices_hidden',
    icon: 'device_unknown',
    propertyType: 'Unknown'
  },
  Unknown: {
    label: STATUS_DEVICE.Unknown,
    status: 'Unknown',
    icon: 'device_unknown',
    propertyType: 'Unknown'
  }
};

export const PROVIDER_ERROR_PROPERTY_TEMPLATE: SimlabPropertyInterface<'ProviderError'> = {
  type: 'ProviderError',
  values: {
    value: ''
  },
  settings: undefined,
  unit: 'None',
  supportedCommands: []
};

export const SUBSCRIPTION_LIMIT_PROPERTY_TEMPLATE: SimlabPropertyInterface<'SubscriptionLimit'> = {
  type: 'SubscriptionLimit',
  values: {
    value: 'Device blocked'
  },
  settings: undefined,
  unit: 'None',
  supportedCommands: []
};

export type Widget = {
  id: string;
  roomId: string;
  simlabDeviceId: string;
  deviceId: string;
  showIn3D: boolean;
  showInDashboard: boolean;
  showInScenes: boolean;
  orderIndex: number;
  transformations: WidgetTransformation[];
  category: DeviceCategoryValueType;
  file: FileBlob | null;
};
export type WidgetShortcut = {
  transformationId: WidgetTransformation['id'] | undefined;
  transformation: WidgetTransformation['transformation'] | undefined;
} & Omit<Widget, 'transformations'> &
  Pick<DeviceDto, 'icon'>;

export type WidgetExtended = Widget &
  Pick<DeviceDto, 'name' | 'visible' | 'simlabDeviceType' | 'icon' | 'status' | 'propertyType'>;

export type WidgetTransformation = {
  id: string;
  transformation: TransformationInterface;
  orderIndex: number;
};

export type WidgetExtendedWithRoomName = WidgetExtended & { roomName: string };

export type WidgetByRoom = Dictionary<{
  roomName: string;
  widgets: WidgetExtended[];
}>;

export interface FileBlob {
  id: string;
  blobPath: string;
  name: string;
  isBlockedBySubscriptionLimit: boolean;
}
export interface Vector3 {
  x: number;
  y: number;
  z: number;
}

export type TWidgetShortcut<T extends Widget | WidgetShortcut = Widget> = {
  widget: T;
  device: DeviceDto;
};

export type WidgetMarkerUserData = {
  type: 'widgets';
  widgetId: string;
  transformationId: string;
  deviceId: string;
};

export type TWidgetDetailOptions = {
  editModeEnable?: boolean;
  canEditDevice?: boolean;
};
export type TWidgetDetail = {
  widget: Pick<WidgetExtended, 'id' | 'deviceId' | 'transformations' | 'icon' | 'name' | 'file'>;
  options?: TWidgetDetailOptions;
  localChanges?: Dictionary<DeviceDto>;
  userPreferences: UserPreferencesInterface;
};
export type TWidgetDetailAction = 'close' | 'goToPosition' | 'editPosition' | 'addPosition' | 'goToDeviceManager';
export type TWidgetDetailResponse = {
  action: TWidgetDetailAction;
  widget: TWidgetDetail['widget'];
  options?: {
    transformation?: WidgetTransformation;
  };
};

export interface SimlabDeviceShortcutDto {}
export interface DeviceWidgetDto {}

export type DeviceFooterInformation = {
  value: number;
  unit: Unit;
  stringify: () => string;
};

export interface DeviceDtoTest {
  id: string;
  parentId: string | null;
  name: string | null;
  simlabDeviceType: SimlabDeviceType;
  orderIndex: number;
  level?: number;
  children: DeviceDtoTest[];
}

export interface UpdateDevice {
  rootComponentId: string;
  providerComponentType: number;
  components: UpdateDeviceItem[];
}

export interface UpdateDeviceItem {
  id: string;
  name: string;
  visible: boolean;
  orderIndex: number;
  icon?: string;
  widget: UpdateWidgetItem;
}

export interface UpdateWidgetItem {
  showIn3D: boolean;
  showInDashboard: boolean;
  showInScenes: boolean;
  category: DeviceCategoryValueType;
  uploadedFile?: string;
  roomId: string;
}

export interface UpdateLocalWidgetItem extends UpdateWidgetItem {
  id: string;
}
export interface NotImportedMasterDevice {
  masterDeviceId: string;
  masterDeviceName: string;
  connectionFailed: boolean;
  connectionFailReason: string;
  smartApiProvider: SmartApiProviderEnum;
  notImportedDevices: NotImportedDevice[];
}
export interface NotImportedDeviceProvider {
  smartApiProvider: SmartApiProviderEnum;
  providerStatus: TDeviceStatus;
  notImportedMasterDevices: NotImportedMasterDevice[];
}
export interface NotImportedDevice {
  name: string;
  masterDeviceId: string;
  masterDeviceName: string;
  inProviderId: string;
  smartApiProvider: SmartApiProviderEnum;
  inProviderRoomName: string;
}
export interface DeviceInNotAssignedDevice {
  inProviderId: string;
  name: string;
  simlabDeviceType: string;
}
export interface NotAssignedDevice {
  name: string;
  masterDeviceId: string;
  inProviderId: string;
  smartApiProvider: SmartApiProviderEnum;
  devices: DeviceInNotAssignedDevice[];
}

export interface AddDevicesResponseInterface {
  successfullyImportedPhysicalDevices: DeviceDto[];
  unsuccessfullyImportedPhysicalDevices: Partial<NotImportedDevice>[];
}

export interface DeviceAddDtoInterface {
  simlabDeviceCode?: string;
  apartmentId?: string;
  roomId: string;
  name: string;
  inProviderId: string;
  masterDeviceId: string;
  smartApiProvider: string;
}

export interface DevicesAddDtoInterface {
  roomId: string;
  devicesToImport: Partial<DeviceAddDtoInterface>[];
}

export interface PropertyValueChange<T extends SimlabPropertyInterface<any>> {
  command: ExecuteCommandPayload<ComponentType>;
  propertyDefinition: T;
}
export type WidgetProperties<T extends SimlabPropertyInterface<any> = any, W = any> = {
  readonly partialCommand$: Observable<ExecuteCommandPayload<ComponentType>['command']>;
  readonly propertyDefinition: T;
  readonly userPreferences: UserPreferencesInterface;
  propertyValue: W;
};

export type WidgetDeviceInformation<T extends SimlabPropertyInterface<any> = any, W = any> = Omit<
  WidgetProperties<T, W>,
  'partialCommand$' | 'propertyDefinition'
> & {
  deviceId: string;
  readonly valueChange$: Observable<PropertyValueChange<T> | undefined>;
  readonly loading$: Observable<boolean>;
  readonly status$: Observable<TDeviceStatus | undefined>;
  readonly device$: Observable<DeviceDto>;
  readonly footer$: Observable<Pick<DeviceDto, 'battery' | 'power'>> | undefined;
  readonly commandToValue: (command: ExecuteCommandPayload<ComponentType, W>['command']) => W;
  readonly valueToCommand: (value: W) => ExecuteCommandPayload<ComponentType, W>['command'];
  get providerComponentType(): ComponentType;
  get icon(): UAnimatedIcon;
  visible: boolean;
};

export interface LevelCommand {
  min: number;
  max: number;
  step: number;
}
export interface CommandValuesType {
  Unsupported: undefined;
  TurnOn: undefined;
  TurnOff: undefined;
  Reconfigure: undefined; // not supported
  Reset: undefined; // not supported
  AbortUpdate: undefined; // not supported
  ForceArm: undefined;
  RetryUpdate: undefined; // not supported
  SetArmed: undefined;
  SetInterval: undefined; // not supported
  StartUpdate: undefined; // not supported
  UpdateFirmware: undefined; // not supported
  MeetArmConditions: undefined; // not supported
  SetSchedule: undefined; // not supported;
  SetScheduleOverride: {
    temperatureMin: number;
    temperatureMax: number;
    temperatureStep: number;
    periodMin: number;
    periodMax: number;
    periodStep: number;
  };
  SetTemperature: LevelCommand;
  SetLevel: LevelCommand;
  Toggle: undefined;
  StartLevelIncrease: undefined;
  StartLevelDecrease: undefined;
  StopLevelChange: undefined;
  SetRgbwColor: undefined;
  RemoveScheduleOverride: undefined;
  SetThermostatMode: {
    availableModes: string[];
    periodMin: number;
    periodMax: number;
    periodStep: number;
  };
  TryWakeUpDevice: undefined;
  SetHeatingMode: undefined;
  SetTemperatureShift: undefined;
  SetHvacOperationMode: undefined;
  RunScene: {
    value: number;
  };
  VolumeUp: undefined;
  VolumeDown: undefined;
  SetVolume: undefined;
  ChannelDown: undefined;
  ChannelUp: undefined;
  SetTvChannel: undefined;
  SetTvChannelName: undefined;
  SetMute: undefined;
  SetInputSource: undefined;
  SetPlaybackStatus: undefined;
  NextTrack: undefined;
  PreviousTrack: undefined;
  SetPictureMode: undefined;
  SetSoundMode: undefined;
  RecordStart: undefined;
  RecordStop: undefined;
  SamsungvdSetInputSource: undefined;
  SamsungvdRemoteControlSend: undefined;
  // NETx
  SetBoolean: undefined;
  SetInteger: undefined;
  SetUInteger: undefined;
  SetNumber: undefined;
  SetString: undefined;
  SetDateTime: undefined;
}
export type CommandKeys = keyof CommandValuesType;
export type CommandValuesValue = string | number;

export interface AxisValueParameter {
  x: string | number;
  y: string | number;
  z: string | number;
}

export interface RGBWValueParameter {
  r: string | number;
  g: string | number;
  b: string | number;
  w?: string | number;
}

export interface TemperaturePeriodValueParameter {
  temperature: number;
  period?: number;
}

export interface ArmedValueParameter {
  armed: boolean;
  password: string;
}

export interface LevelValueParameter {
  level: number;
}

export interface ModeValueParameter {
  setMode: string;
}

export interface ExecuteSceneValueParameter {
  value: string | number;
}
export interface SimlabCommandInterface<T extends CommandKeys> {
  commandType: T;
  values: TypeValues<CommandValuesType, T> | null;
  propertyTypes: Properties[];
}

export interface ExecuteCommandPayload<T extends ComponentType, W = string> {
  deviceId: string;
  command: {
    command: CommandKeys;
    parameters: string | number | undefined | W;
  };
  componentType: T;
}
