import { createAction, props } from '@ngrx/store';

export const SetSelectedAssetId = createAction(
  '[Application] Set Current Asset Id',
  props<{ selectedAssetId: string }>())
export const SetSelectedUserId = createAction(
  '[Application] Set Selected User',
  props<{ selectedUserId: string | 'publicLink' }>()
);

export const SetSelectedTicketId = createAction(
  '[Application] Set Selected Ticket',
  props<{ selectedTicketId: string }>()
);

export const SetSelectedWidgetId = createAction('[Application] Selected Widget', props<{ selectedWidgetId: string }>());
export const LeaveApartment = createAction('[Application] Leave Apartment');
