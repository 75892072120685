import { Camera } from '@simlab/matterport/assets/mpSdk';

export type PositionChange = Camera.Pose & { modelId: string };

export interface LastSweepInterface {
  [key: string]: PositionChange | undefined;
}

export function GetLastPosition(apartmentId: string): PositionChange | undefined {
  const lastPos = localStorage.getItem('lastPositionInApartment');
  const lastKnownPosition: LastSweepInterface = lastPos && JSON.parse(lastPos);
  return lastKnownPosition !== null ? lastKnownPosition[apartmentId] : undefined;
}
