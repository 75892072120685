<div class="sim-datepicker" [class.sim-datepicker--focused]="focused">
  <input
    [disabled]="disabled"
    [required]="required"
    [value]="value"
    placeholder="{{ placeholder }}"
    readonly="{{ readonly || null }}"
    (touch)="_onTouched()"
    (focus)="_focusChanged(true)"
    (blur)="_focusChanged(false)"
    (dateChange)="emitChangeEvent($event)"
    [matDatepicker]="datePicker"
    (click)="datePicker.open()"
    (keydown)="_handleKeydown($event)"
    [min]="min"
    [max]="max"
    #input
  />
  <div id="datepicker-open-icon" class="sim-datepicker__icon" (click)="datePicker.open()">
    <sim-icon icon="calendar" sizeType="small"></sim-icon>
  </div>
  <mat-datepicker #datePicker></mat-datepicker>
</div>
