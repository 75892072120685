import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatterportIframeComponent } from './components/matterport-iframe/matterport-iframe.component';
import { ByPassSecurityPipe } from './pipes/bypass-security.pipe';
import { FileLoaderCacheService } from './services/file-loader-cache.service';

@NgModule({
  imports: [CommonModule],
  exports: [MatterportIframeComponent],
  providers: [FileLoaderCacheService],
  declarations: [MatterportIframeComponent, ByPassSecurityPipe],
})
export class MatterportSdkBundleModule {}
