import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { TreeNode } from '@simOn/ui/sim-tree';

import {
  Observable,
  Subject,
  combineLatest,
  filter,
  first,
  forkJoin,
  map,
  of,
  race,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';

import { SmartApiProviderEnum } from '@simOn/common/providers';
import {
  DeviceDto,
  DeviceProperties,
  DeviceStructure,
  DevicesAddDtoInterface,
  PropertyValueChange,
  SimlabDeviceType,
  Widget
} from '@simOn/device/models';
import {
  CheckDevicesAlarmStatus,
  ClearDevicePropertiesState,
  ExecuteCommand,
  LoadDeviceProperties,
  LoadDevicePropertiesFailure,
  LoadDevicePropertiesSuccess,
  PoveterLocal
} from '../property/device-property.actions';
import {
  LoadedPropertiesState,
  selectAllDevicesProperty,
  selectDevicePropertyById
} from '../property/device-property.selectors';
import {
  AddDevices,
  AddDevicesFailure,
  AddDevicesSuccess,
  ClearDeviceState,
  DeleteDevice,
  DeleteDeviceFailure,
  DeleteDeviceSuccess,
  GetDevice,
  GetDeviceSuccess,
  GetNotAssignedDevices,
  GetNotAssignedDevicesFailure,
  GetNotAssignedDevicesSuccess,
  LoadDevices,
  RemoveDevicesAfterDeleteProvider,
  UpdateDevice,
  UpdateDeviceFailure,
  UpdateDeviceSuccess
} from './devices.actions';
import {
  SelectMainDevice as FindAndSelectMainDevice,
  LoadedState,
  SelectAllChildrenWithProperties,
  SelectAllMainDevices,
  SelectDeviceById,
  SelectDevicesWidgets,
  SelectFirstDeviceWithProperties,
  SelectFullDeviceTree,
  SelectReducedDeviceTree
} from './devices.selectors';

@Injectable()
export class DevicesFacade {
  private readonly store = inject(Store);
  private readonly actions = inject(Actions);
  readonly checkPropertiesLoaded$: Observable<boolean> = this.store
    .select(LoadedPropertiesState)
    .pipe(map((e: boolean | undefined) => e || false));
  private readonly _destroySource: Subject<void> = new Subject<void>();
  readonly checkLoaded$: Observable<boolean> = this.store
    .select(LoadedState)
    .pipe(map((e: boolean | undefined) => e || false));
  readonly devicePropertiesLoaded$: Observable<boolean> = this.actions.pipe(
    ofType(LoadDevicePropertiesSuccess),
    map(() => {
      return true;
    })
  );
  readonly clearState$: Observable<boolean> = this.actions.pipe(
    ofType(ClearDevicePropertiesState, ClearDeviceState),
    map(() => {
      return true;
    })
  );
  readonly updateDeviceSuccess$: Observable<boolean> = this.actions.pipe(
    ofType(UpdateDeviceSuccess, UpdateDeviceFailure),
    map((action) => {
      if (action.type === '[New Devices] Update Device Failure') {
        throw new Error('Update Device Failure');
      }
      return true;
    })
  );

  readonly UpdateDeviceFailure$: Observable<boolean> = this.actions.pipe(
    ofType(UpdateDeviceFailure),
    map(() => {
      return true;
    })
  );

  readonly LoadDevicePropertiesFailure$ = this.actions.pipe(
    ofType(LoadDevicePropertiesFailure),
    map(({ error, masterDeviceId }) => {
      return { error, masterDeviceId };
    })
  );

  readonly GetDeviceSuccess$: Observable<boolean> = this.actions.pipe(
    ofType(GetDeviceSuccess),
    map((action) => {
      return true;
    })
  );
  readonly deleteDeviceStatus$ = this.actions.pipe(ofType(DeleteDeviceSuccess, DeleteDeviceFailure));
  readonly ImportDeviceSuccess$: Observable<boolean> = this.actions.pipe(
    ofType(AddDevicesFailure),
    map((action) => {
      return true;
    })
  );
  readonly getNotAssignedDevicesSuccess$ = this.actions.pipe(ofType(GetNotAssignedDevicesSuccess));
  readonly AddDevicesSuccess$ = this.actions.pipe(
    ofType(AddDevicesSuccess),
    map((action) => {
      return true;
    })
  );
  readonly getNotAssignedDevicesFailure$ = this.actions.pipe(ofType(GetNotAssignedDevicesFailure));
  readonly selectAllMainDevices$: Observable<DeviceStructure[]> = this.checkLoaded$.pipe(
    filter((isLoaded: boolean) => isLoaded),
    switchMap(() => this.store.select(SelectAllMainDevices))
  );

  readonly selectDeviceAndWidget$: Observable<{
    devices: DeviceStructure[];
    widgets: Widget[];
  }> = this.store.select(SelectDevicesWidgets);

  // readonly selectImportedDevicesProvider$: Observable<ImportedDevicesProvider[]> = this.store
  //   .select(SelectImportedDevicesProvider)
  //   .pipe(
  //     switchMap((importedDevices: ImportedDevicesProvider[]) =>
  //       importedDevices && importedDevices.length
  //         ? forkJoin(
  //             importedDevices.map((device: ImportedDevicesProvider, idx: number) =>
  //               this.getFirstDeviceWithProperties$(device.id).pipe(
  //                 map(
  //                   (deviceProperties: DeviceDto) =>
  //                     ({ ...importedDevices[idx], status: deviceProperties.status } as ImportedDevicesProvider)
  //                 ),
  //                 first()
  //               )
  //             )
  //           )
  //         : of([])
  //     )
  //   );

  readonly getDeviceById$ = (deviceId: string): Observable<DeviceDto> =>
    combineLatest([this.store.select(LoadedState), this.checkPropertiesLoaded$]).pipe(
      filter((isLoaded) => isLoaded.every((value) => value)),
      take(1),
      switchMap(() => combineLatest([this.getDeviceStructure(deviceId), this.getDeviceProperty(deviceId)])),
      map(([structure, property]: [DeviceStructure, DeviceProperties]) => ({ ...structure, ...property } as DeviceDto))
    );
  readonly getDeviceStructure = (deviceId: string) =>
    this.store.select(LoadedState).pipe(
      filter((isLoaded) => isLoaded || false),
      take(1),
      switchMap(() => this.store.select(SelectDeviceById(deviceId)))
    );
  readonly getDeviceProperty = (deviceId: string) =>
    this.checkPropertiesLoaded$.pipe(
      filter((propertiesLoaded) => !!propertiesLoaded),
      switchMap(() =>
        this.store
          .select(selectDevicePropertyById(deviceId))
          .pipe(
            map((deviceProperty: DeviceProperties | undefined) =>
              !!deviceProperty ? deviceProperty : ({ status: 'NotFound' } as DeviceProperties)
            )
          )
      )
    );
  readonly getAllDevicesWithProperties$ = (deviceId: string): Observable<DeviceDto[]> =>
    this.store.select(SelectAllChildrenWithProperties(deviceId)).pipe(
      filter((devicesStructure: DeviceStructure[]) => devicesStructure.length > 0),
      first(),
      switchMap((devicesStructure: DeviceStructure[]) =>
        combineLatest(
          devicesStructure.map((deviceStructure: DeviceStructure) =>
            this.getDeviceProperty(deviceStructure.id).pipe(
              map((deviceProperty: DeviceProperties) => ({ ...deviceStructure, ...deviceProperty }))
            )
          )
        )
      ),

      first()
    );

  readonly getFirstDeviceWithProperties$ = (
    deviceId: string,
    ignoreVisibleProperties?: boolean
  ): Observable<DeviceDto> =>
    this.store.select(SelectFirstDeviceWithProperties(deviceId, ignoreVisibleProperties)).pipe(
      filter((deviceStructure): deviceStructure is DeviceStructure => !!deviceStructure),
      switchMap((deviceStructure) => {
        //MOCK DEVICE
        if (!deviceStructure!.id) return of(deviceStructure as DeviceDto);
        return this.getDeviceProperty(deviceStructure!.id).pipe(
          map((deviceProperty: DeviceProperties) => ({ ...deviceStructure, ...deviceProperty }))
        );
      }),
      takeUntil(this.clearState$)
    );

  readonly getTreeNodeElement$ = (devices: string): Observable<TreeNode<SimlabDeviceType>> =>
    this.store.select(SelectFullDeviceTree(devices));

  readonly getReducedTreeNodeElement$ = (devices: string): Observable<TreeNode<SimlabDeviceType> | undefined> =>
    this.store.select(SelectReducedDeviceTree(devices));

  getFullElement$ = (devices: string) =>
    combineLatest([this.getReducedTreeNodeElement$(devices), this.getTreeNodeElement$(devices)]).pipe(
      map(([a, b]) => {
        return { reduced: a, full: b };
      })
    );

  readonly getMainDevice$ = (deviceId: string): Observable<DeviceStructure | undefined> =>
    this.store.select(FindAndSelectMainDevice(deviceId));

  loadDevicesStructure() {
    this.store.dispatch(LoadDevices());
  }
  loadDevicesProperties(apartmentProviders: PoveterLocal[]) {
    this._deviceAlarmObserver();
    this.store.dispatch(LoadDeviceProperties({ apartmentProviders }));
  }

  addDevices(device: DevicesAddDtoInterface): void {
    this.store.dispatch(AddDevices({ device }));
  }

  getNotAssignedDevices(
    providers: {
      masterDeviceId: string;
      smartApiProvider: SmartApiProviderEnum;
    }[]
  ): void {
    this.store.dispatch(GetNotAssignedDevices({ providers }));
  }

  clearState() {
    this.store.dispatch(ClearDeviceState());
    this.store.dispatch(ClearDevicePropertiesState());
  }

  getDevice(id: string) {
    this.store.dispatch(GetDevice({ id }));
  }

  updateDevice(updateDevice: unknown) {
    this.store.dispatch(UpdateDevice({ updateDevice }));
  }

  deleteDevice(id: string) {
    this.store.dispatch(DeleteDevice({ id }));
  }
  removeDevicesAfterDeleteProvider(masterDeviceId: string): void {
    this.store.dispatch(RemoveDevicesAfterDeleteProvider({ masterDeviceId }));
  }

  executeCommand(data: PropertyValueChange<any>) {
    this.store.dispatch(ExecuteCommand({ data }));
  }

  private _deviceAlarmObserver() {
    this._destroySource.next();
    this.devicePropertiesLoaded$
      .pipe(
        switchMap(() =>
          this.store.select(selectAllDevicesProperty).pipe(
            switchMap((deviceProperties: DeviceProperties[]) =>
              forkJoin(
                deviceProperties.map((property) =>
                  this.getDeviceById$(property.id).pipe(
                    first(),
                    map((deviceStructure: DeviceStructure) => ({ ...deviceStructure, ...property }))
                  )
                )
              )
            ),
            tap((devices: DeviceDto[]) => {
              this.store.dispatch(CheckDevicesAlarmStatus({ devices }));
            })
          )
        ),
        takeUntil(race(this.clearState$, this._destroySource))
      )
      .subscribe();
  }
}
