import { Pipe, PipeTransform } from '@angular/core';

/**
 * const values represents time in second
 */
export const TIME_KEYS = [
  'YEAR',
  'MONTH',
  'WEEK',
  'DAY',
  'HOUR',
  'MINUTE'
  //, 'SECOND'
];
export const TIME_LONGAR_THAN: {
  [keys in (typeof TIME_KEYS)[number]]: number;
} = {
  // SECOND: 1,
  MINUTE: 60,
  HOUR: 3600,
  DAY: 86400,
  WEEK: 604800,
  MONTH: 2629743,
  YEAR: 31556926
} as const;

const TIME_LOCALIZATION: Record<
  keyof typeof TIME_LONGAR_THAN,
  { single: (value: number) => string; multiple: (value: number) => string }
> = {
  //   SECOND: {
  //     single: $localize`:@@TIME_LOCALIZATION_SECOND_AGO:second ago`,
  //     multiple: $localize`:@@TIME_LOCALIZATION_SECONDS_AGO:seconds ago`
  //   },
  MINUTE: {
    single: (value: number) => $localize`:@@TIME_LOCALIZATION_MINUT_AGO:${value} minute ago`,
    multiple: (value: number) => $localize`:@@TIME_LOCALIZATION_MINUTS_AGO:${value} minutes ago`
  },
  HOUR: {
    single: (value: number) => $localize`:@@TIME_LOCALIZATION_HOUR_AGO:${value} hour ago`,
    multiple: (value: number) => $localize`:@@TIME_LOCALIZATION_HOURS_AGO:${value} hours ago`
  },
  DAY: {
    single: (value: number) => $localize`:@@TIME_LOCALIZATION_DAY_AGO:${value} day ago`,
    multiple: (value: number) => $localize`:@@TIME_LOCALIZATION_DAYS_AGO:${value} days ago`
  },
  WEEK: {
    single: (value: number) => $localize`:@@TIME_LOCALIZATION_WEEK_AGO:${value} week ago`,
    multiple: (value: number) => $localize`:@@TIME_LOCALIZATION_WEEKS_AGO:${value} weeks ago`
  },
  MONTH: {
    single: (value: number) => $localize`:@@TIME_LOCALIZATION_MONTH_AGO:${value} month ago`,
    multiple: (value: number) => $localize`:@@TIME_LOCALIZATION_MONTHS_AGO:${value} months ago`
  },
  YEAR: {
    single: (value: number) => $localize`:@@TIME_LOCALIZATION_YEAR_AGO:${value} year ago`,
    multiple: (value: number) => $localize`:@@TIME_LOCALIZATION_YEARS_AGO:${value} years ago`
  }
} as const;

export const getLastActiveLabel = (epochDiffrenceInSecond: number) => {
  if (typeof epochDiffrenceInSecond !== 'number' || isNaN(epochDiffrenceInSecond))
    return $localize`:@@TIME_LOCALIZATION_UNKNOWN:unknown`;
  for (let key of TIME_KEYS) {
    if (epochDiffrenceInSecond > TIME_LONGAR_THAN[key]) {
      const value = Math.floor(epochDiffrenceInSecond / TIME_LONGAR_THAN[key]);
      return `${value > 1 ? TIME_LOCALIZATION[key].multiple(value) : TIME_LOCALIZATION[key].single(value)}`;
    }
  }
  return `< ${TIME_LOCALIZATION['MINUTE'].single(1)}`;
};
@Pipe({
  name: 'lastActive',
  standalone: true
})
export class LastActivePipe implements PipeTransform {
  transform(value: string | null) {
    if (!value) return $localize`:@@TIME_LOCALIZATION_UNKNOWN:unknown`;
    const dateNow = new Date().valueOf();

    const lastActive = new Date(value).valueOf();
    const differenceInSecond = (dateNow - lastActive) / 1000;
    return getLastActiveLabel(differenceInSecond);
  }
}
