import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public snackBar = inject(MatSnackBar);

  openSnackBar(message: string, action: string, className: string, config = {}) {
    return this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: [className],
      ...config
    });
  }

  openErrorSnackBar(message: string) {
    this.openSnackBar(message, '', 'snack-bar-error');
  }

  openSuccessSnackBar(message: string) {
    this.openSnackBar(message, 'x', 'snack-bar-success');
  }

  openWarnSnackBar(message: string) {
    this.openSnackBar(message, 'x', 'snack-bar-warn');
  }

  openFromComponent<T>(component: ComponentType<T>, config?: MatSnackBarConfig<any> | undefined) {
    return this.snackBar.openFromComponent(component, {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'right',
      panelClass: 'component-snackbar',
      ...config
    });
  }
}
