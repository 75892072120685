// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Mattertag } from '@simlab/matterport/api';
import { TransformConverter } from '@simlab/matterport/transform';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MpSdk } from 'libs/simlab-matterport/assets/mpSdk';

export class MatterportModelTag implements MpSdk.Mattertag.MattertagDescriptor {
  anchorPosition: MpSdk.Vector3;
  stemVector: MpSdk.Vector3;
  stemVisible?: boolean | undefined;
  label?: string | undefined;
  description?: string | undefined;
  media?: { type: MpSdk.Mattertag.MediaType; src: string } | undefined;
  color?: MpSdk.Color | undefined;
  floorId?: number | undefined;
  floorIndex?: number | undefined;
  iconId?: string | undefined;
  transform = new TransformConverter(undefined);
  constructor(tag: Mattertag) {
    this.anchorPosition = {
      x: tag.anchorPosition?.x || 0,
      y: tag.anchorPosition?.z || 0,
      z: -(tag.anchorPosition?.y || 0),
    };
    this.stemVector = {
      x: (tag.stemLength || 0) * (tag.stemNormal?.x || 0),
      y: (tag.stemLength || 0) * (tag.stemNormal?.z || 0),
      z: -(tag.stemLength || 0) * (tag.stemNormal?.y || 0),
    };
    this.stemVisible = tag.stemEnabled || true;
    this.label = tag.label || undefined;
    this.description = tag.description || undefined;
    this.color = tag.color ? { ...this._hexToRGB(tag.color) } : undefined;
    this.iconId = tag.icon || undefined;
  }

  private _hexToRGB(hex: string): MpSdk.Color {
    const r = parseInt(hex.slice(1, 3), 16) / 255,
      g = parseInt(hex.slice(3, 5), 16) / 255,
      b = parseInt(hex.slice(5, 7), 16) / 255;

    return {
      r,
      g,
      b,
    };
  }
}
