import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProblemType, SimlabErrorDto } from '@simOn/common/http';
import { PublicLinkService } from '@simOn/public-link';
import { SpaceFacade } from '@simOn/space';
import { API_URL } from '@simOn/utils/tokens';
import { catchError, mergeMap, Observable, of, take } from 'rxjs';

@Injectable()
export class ApartmentIdApiInterceptor implements HttpInterceptor {
  private readonly simlabApi = inject(API_URL);
  private readonly spaceFacade = inject(SpaceFacade);
  private readonly router = inject(Router);

  private readonly _publicLink = inject(PublicLinkService, { optional: true });

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.spaceFacade.selectedApartmentId$.pipe(
      take(1),
      mergeMap((selectedApartmentId) => {
        if (!req.url.includes(this.simlabApi)) {
          return next.handle(req);
        }
        const apartmentId = this._publicLink?.apartmentId || selectedApartmentId || '';
        const newRequest = req.clone({
          setHeaders: {
            apartmentId
          }
        });
        return apartmentId ? next.handle(newRequest) : next.handle(req);
      }),
      catchError((err) => {
        const simlabError = err.error as SimlabErrorDto;
        if (simlabError.ErrorCode === ProblemType.InsufficientPermissions) {
          this.router.navigate(['/']);
        }
        return of(err);
      })
    );
  }
}
