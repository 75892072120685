import { Injectable, inject } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InfoModalDataInterface, ModalTypeEnum } from '@simOn/common/info-modal/model';
import { SimInfoModalComponent } from '@simOn/common/info-modal/ui';
import { SubscriptionApiService } from '@simOn/subscription/space-limit/data-access';
import { UsersLeft } from '@simOn/subscription/space-limit/models';
import { Observable, from, iif, map, mergeMap, of, switchMap, take } from 'rxjs';

const DESCRIPTIONS = {
  scenesLeft: {
    title: $localize`:@@MODALS_ADD_NEW_SCENE_ERROR:You can’t add new scenes`,
    text: $localize`:@@MODALS_ADD_NEW_SCENE_ERROR_INFORMATION:Your current subscription plan doesn’t allow you to create new scenes. Remove existing scene(s) or upgrade your subscription plan.`
  },
  usersLeft: {
    title: $localize`:@@MODALS_ADD_NEW_USER_ERROR:You can’t add new users`,
    text: $localize`:@@MODALS_ADD_NEW_USER_ERROR_INFORMATION:Your current subscription plan doesn’t allow you to add new users. Remove existing user(s) or upgrade your subscription plan.`
  },
  devicesLeft: {
    title: (numberOfDevice: number | string, canAdd: number | string) =>
      $localize`:@@MODALS_LIMIT_DEVICES_ERROR_INFORMATION:You can't add ${numberOfDevice} devices. Your current plan allows you to add ${canAdd} more devices. Remove existing devices or upgrade your plan.`,
    text: $localize`:@@MODALS_LIMIT_DEVICES_ERROR_EXIT_BUTTON:Upgrade your plan`
  },
  devicesLeftNoValue: {
    title: $localize`:@@MODALS_ADD_NEW_DEVICES_ERROR:You can’t add new devices`,
    text: $localize`:@@MODALS_ADD_NEW_DEVICES_ERROR_INFORMATION:Your current subscription plan doesn’t allow you to add new devices. Remove existing device(s) or upgrade your subscription plan.`
  },
  spacesLeft: {
    title: $localize`:@@MODALS_ADD_NEW_SPACES_ERROR:You can’t add new spaces`,
    text: $localize`:@@MODALS_ADD_NEW_SPACES_ERROR_INFORMATION:Your current subscription plan doesn’t allow you to create new spaces. Remove existing space or upgrade your subscription plan.`
  },
  blobBytesLeft: {
    title: $localize`:@@MODALS_STORAGE_LIMIT_ERROR:Storage limit has been exceeded`,
    text: $localize`:@@MODALS_STORAGE_LIMIT_ERROR_INFORMATION:The file you want to add exceeds the space available in your storage limit. Remove files or upgrade your subscription plan.`
  },
  buttonText: $localize`:@@MODALS_ADD_NEW_SCENE_ERROR_EXIT_BUTTON:Upgrade your plan`
};

@Injectable()
export class SubscriptionInfoService {
  private readonly subscriptionApiService = inject(SubscriptionApiService);
  private readonly dialog = inject(MatDialog);
  private readonly router = inject(Router);

  checkOrShowInfoScenesLeft$(): Observable<boolean> {
    return this.subscriptionApiService.getScenesLeft$().pipe(
      mergeMap((value: number) =>
        iif(
          () => value > 0,
          of(true),
          of(false).pipe(
            switchMap(() => {
              return from(
                this._openInfoModal(
                  ModalTypeEnum.Error,
                  DESCRIPTIONS.scenesLeft.title,
                  DESCRIPTIONS.scenesLeft.text,
                  DESCRIPTIONS.buttonText
                )
              ).pipe(
                switchMap((modalRef) =>
                  modalRef.afterClosed().pipe(
                    map((modalResult) => {
                      if (modalResult?.action === 'buttonClick') {
                        this.router.navigate(['subscriptions/plans']);
                      }
                      return false;
                    })
                  )
                )
              );
            })
          )
        )
      ),
      take(1)
    );
  }

  checkOrShowInfoUsersLeft$(): Observable<boolean> {
    return this.subscriptionApiService.getUsersLeft$().pipe(
      mergeMap((value: UsersLeft) =>
        iif(
          () => value.usersLeft > 0,
          of(true),
          of(false).pipe(
            switchMap(() => {
              return from(
                this._openInfoModal(
                  ModalTypeEnum.Error,
                  DESCRIPTIONS.usersLeft.title,
                  DESCRIPTIONS.usersLeft.text,
                  DESCRIPTIONS.buttonText
                )
              ).pipe(
                switchMap((modalRef) =>
                  modalRef.afterClosed().pipe(
                    map((modalResult) => {
                      if (modalResult?.action === 'buttonClick') {
                        this.router.navigate(['subscriptions/plans']);
                      }
                      return false;
                    })
                  )
                )
              );
            })
          )
        )
      ),
      take(1)
    );
  }

  checkOrShowInfoDevicesLeft$(numberOfDevice: number): Observable<boolean> {
    return this.subscriptionApiService.getDevicesLeft$().pipe(
      mergeMap((value: number) =>
        iif(
          () => numberOfDevice <= value && value > 0,
          of(true),
          of(false).pipe(
            mergeMap(() =>
              iif(
                () => value > 0,
                of(false).pipe(
                  switchMap(() => {
                    return from(
                      this._openInfoModal(
                        ModalTypeEnum.Error,
                        DESCRIPTIONS.devicesLeft.title(numberOfDevice, value),
                        DESCRIPTIONS.devicesLeft.text,
                        DESCRIPTIONS.buttonText
                      )
                    ).pipe(
                      switchMap((modalRef) =>
                        modalRef.afterClosed().pipe(
                          map((modalResult) => {
                            if (modalResult?.action === 'buttonClick') {
                              this.router.navigate(['subscriptions/plans']);
                            }
                            return false;
                          })
                        )
                      )
                    );
                  })
                ),
                of(false).pipe(
                  switchMap(() => {
                    return from(
                      this._openInfoModal(
                        ModalTypeEnum.Error,
                        DESCRIPTIONS.devicesLeftNoValue.title,
                        DESCRIPTIONS.devicesLeftNoValue.text,
                        DESCRIPTIONS.buttonText
                      )
                    ).pipe(
                      switchMap((modalRef) =>
                        modalRef.afterClosed().pipe(
                          map((modalResult) => {
                            if (modalResult?.action === 'buttonClick') {
                              this.router.navigate(['subscriptions/plans']);
                            }
                            return false;
                          })
                        )
                      )
                    );
                  })
                )
              )
            )
          )
        )
      ),
      take(1)
    );
  }

  checkOrShowInfoUserApartmentsLeft$(): Observable<boolean> {
    return this.subscriptionApiService.getUserApartmentsLeft$().pipe(
      mergeMap((value: number) =>
        iif(
          () => value > 0,
          of(true),
          of(false).pipe(
            switchMap(() =>
              from(
                this._openInfoModal(
                  ModalTypeEnum.Error,
                  DESCRIPTIONS.spacesLeft.title,
                  DESCRIPTIONS.spacesLeft.text,
                  DESCRIPTIONS.buttonText
                )
              ).pipe(
                switchMap((modalRef) =>
                  modalRef.afterClosed().pipe(
                    map((modalResult) => {
                      if (modalResult?.action === 'buttonClick') {
                        this.router.navigate(['subscriptions/plans']);
                      }
                      return false;
                    })
                  )
                )
              )
            )
          )
        )
      ),
      take(1)
    );
  }

  checkOrShowInfoBlobBytesLeft$(sizeOfFile: number): Observable<boolean> {
    return this.subscriptionApiService.getBlobBytesLeft$().pipe(
      mergeMap((value: number) =>
        iif(
          () => value > 0 && sizeOfFile <= value,
          of(true),
          of(false).pipe(
            switchMap(() => {
              return from(
                this._openInfoModal(
                  ModalTypeEnum.Error,
                  DESCRIPTIONS.blobBytesLeft.title,
                  DESCRIPTIONS.blobBytesLeft.text,
                  DESCRIPTIONS.buttonText
                )
              ).pipe(
                switchMap((modalRef) =>
                  modalRef.afterClosed().pipe(
                    map((modalResult) => {
                      if (modalResult?.action === 'buttonClick') {
                        this.router.navigate(['subscriptions/plans']);
                      }
                      return false;
                    })
                  )
                )
              );
            })
          )
        )
      ),
      take(1)
    );
  }

  private async _openInfoModal(modalType: ModalTypeEnum, title: string, text: string, buttonText: string) {
    return this.dialog.open<SimInfoModalComponent, InfoModalDataInterface>(SimInfoModalComponent, {
      width: '1100px',
      data: {
        modalType,
        title,
        text,
        buttonText
      }
    });
  }
}
