type ReverseMap<T> = T[keyof T];

export enum CustomPhases {
  // FORBIDDEN = 'appphase.error_forbidden',
  // NOTFOUND = 'appphase.error_notfound',
  UNINITIALIZED = 'appphase.uninitialized',
  WAITING = 'appphase.waiting',
  LOADING = 'appphase.loading',
  STARTING = 'appphase.starting',
  PLAYING = 'appphase.playing',
  ERROR = 'appphase.error',
}
type CustomMatterportPhases = ReverseMap<typeof CustomPhases>;
export type MatterportScanStatus = CustomMatterportPhases;
export const DEFAULT_SCALE_SPACE = 0.05;
