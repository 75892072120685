import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ModelAccess } from '@simlab/matterport/api';
import { AuthorizeResponse, UserAccessTokenResponse } from '@simOn/matterport/auth/models';
import { API_URL } from '@simOn/utils/tokens';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MatterportOauthApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  isAuthorized$(): Observable<boolean> {
    return this.http.get<boolean>(`${this._apiUrl}/MatterportUsers/IsAuthorized`);
  }

  getUserAccessToken$(): Observable<UserAccessTokenResponse> {
    return this.http.get<UserAccessTokenResponse>(`${this._apiUrl}/MatterportUsers/GetUserAccessToken`);
  }

  logout$(): Observable<void> {
    return this.http.get<void>(`${this._apiUrl}/MatterportUsers/Logout`);
  }

  authorize$(returnUrl: string): Observable<AuthorizeResponse> {
    return this.http.post<AuthorizeResponse>(`${this._apiUrl}/MatterportUsers/Authorize`, { returnUrl });
  }

  updateToken$(code: string, tokenDescription: string): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/MatterportUsers/UpdateToken`, { code, tokenDescription });
  }

  getMatterportModelAccess$(scanId: string): Observable<ModelAccess> {
    return this.http
      .get(`${this._apiUrl}/MatterportModel/GetMatterportModelAccess?ScanId=${scanId}`, {
        responseType: 'text'
      })
      .pipe(map((response: string) => <ModelAccess>response));
  }
  getMatterportApartmentAccessToken(): Observable<{ accessToken: string }> {
    return this.http.get<{ accessToken: string }>(`${this._apiUrl}/Providers/GetMatterportApartmentAccessToken`);
  }
}
