import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType } from '@simOn/device/models';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule, SimIconModule],
  template: `<div class="blocked-overlay"></div>
    <p>{{ propertyValue }}</p>`,
  styleUrls: ['./blocked.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-blocked'
  }
})
export class BlockedComponent extends PropertyBase<'SubscriptionLimit', string, never> {
  valueToCommand(params: string): { command: keyof CommandValuesType; parameters: string | number } {
    throw new Error('Method not implemented.');
  }
  commandToValue(params: { command: keyof CommandValuesType; parameters: string | number }): string {
    throw new Error('Method not implemented.');
  }
  valueToParameter(params: string): never {
    throw new Error('Method not implemented.');
  }
  set propertyValue(params: string) {
    throw new Error('Method not implemented.');
  }
  get propertyValue(): string {
    return this.propertyDefinition.values.value;
  }
}
