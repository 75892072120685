import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TreeNode } from '@simOn/ui/sim-tree';

import { groupByArray, GroupByArrayElement } from '@simOn/common/helpers';

import {
  ALL_PROPERTIES_HIDDEN_MOCK,
  DeviceStructure,
  ProviderComponentWithProperties,
  SimlabDeviceType,
  UNSUPPORTED_DEVICE_MOCK
} from '@simOn/device/models';
import { State } from '@simOn/utils';
import { selectAllWidgets } from '../widgets/widget.selectors';
import * as DeviceReducer from './devices.reducers';
export const selectDeviceState = createFeatureSelector<State<DeviceStructure>>('devices_structure');

export const SelectAllDevices = createSelector(selectDeviceState, DeviceReducer.selectAll);
export const SelectAllMainDevices = createSelector(SelectAllDevices, (state) => {
  return state.filter((device: DeviceStructure) => !device.parentId);
});

export const LoadedState = createSelector(selectDeviceState, (state) => state.isLoaded);
export function sortDevicesByOrder(a: any, b: any) {
  return a?.order !== undefined && b?.order !== undefined ? a.order - b.order : 0;
}
export const SelectDeviceById = (deviceId: string) =>
  createSelector(selectDeviceState, (state) => state.entities[deviceId] || ({} as DeviceStructure));
export const SelectAllChildrenWithProperties = (deviceId: string) =>
  createSelector(selectDeviceState, (state) => {
    const providersType = Object.keys(ProviderComponentWithProperties);
    let device = state.entities[deviceId];
    if (!device) return [];
    let childrenIds = [device.id];
    const devices: DeviceStructure[] = [];
    while (childrenIds.length) {
      const currentId = childrenIds.shift();
      let currentDevice = state.entities[currentId!];
      if (providersType.includes(currentDevice!.providerComponentType)) devices.push(currentDevice!);
      currentDevice!.children &&
        currentDevice!.children.length &&
        (childrenIds = [...childrenIds, ...currentDevice!.children]);
    }

    return devices;
  });

export const SelectFirstDeviceWithProperties = (deviceId: string, ignoreVisibleProperties?: boolean) =>
  createSelector(selectDeviceState, (state) => {
    const providersType = Object.keys(ProviderComponentWithProperties);
    let device = state.entities[deviceId];
    const name = device?.name;
    if (!device) return device;
    const firstVisibleChildDevice = { ...findFirstVisibleChild(device!.id, state, ignoreVisibleProperties), name } || {
      ...ALL_PROPERTIES_HIDDEN_MOCK,
      name
    };

    device =
      ((providersType.includes(firstVisibleChildDevice!.providerComponentType!) &&
        firstVisibleChildDevice) as DeviceStructure) ||
      ({
        ...UNSUPPORTED_DEVICE_MOCK,
        name
      } as DeviceStructure);

    return device;
  });

export const SelectFullDeviceTree = (deviceId: string) =>
  createSelector(selectDeviceState, (state) => {
    return getDeviceTree(state, deviceId);
  });

export const SelectReducedDeviceTree = (deviceId: string) =>
  createSelector(selectDeviceState, (state) => {
    return getReducedDeviceTree(state, deviceId);
  });

export const SelectMainDevice = (deviceId: string) =>
  createSelector(selectDeviceState, (state) => {
    return getMainDevice(state, deviceId);
  });

export const SelectMainDevices = createSelector(SelectAllDevices, (devices: DeviceStructure[]) => {
  return devices.filter((device) => device.parentId === null);
});

export const SelectMainDevicesGroupedByRoom = createSelector(SelectMainDevices, (devices: DeviceStructure[]) => {
  return groupByArray<DeviceStructure>(devices, 'roomId').map((devicesInRoom: GroupByArrayElement<DeviceStructure>) => {
    return {
      ...devicesInRoom,
      collection: [...devicesInRoom.collection.sort(sortDevicesByOrder)]
    };
  });
});
export const SelectDevicesWidgets = createSelector(
  SelectMainDevices,
  selectAllWidgets,
  (devices: DeviceStructure[], widgets) => {
    return { devices, widgets };
  }
);

//export const SelectImportedDevicesProvider = createSelector(
//   SelectMainDevices,
//   SelectAllRooms,
//   selectAllWidgets,
//   (devices: DeviceStructure[], rooms: RoomInterface[], widgets) => {
//     return devices.map((device: DeviceStructure) => {
//       const roomName = rooms.find((room: RoomInterface) => room.id === device.roomId)?.name;
//       return {
//         category: widgets.find((widget) => widget.deviceId === device.id)?.category || 'Other',
//         id: device.id,
//         smartApiProvider: device.smartApiProvider,
//         name: device.name,
//         masterDeviceId: device.masterDeviceId,
//         roomName: roomName
//       } as ImportedDevicesProvider;
//     });
//   }
// );

const getDeviceTree = (state: State<DeviceStructure>, deviceId: string): TreeNode<SimlabDeviceType> => {
  let dev = state.entities[deviceId];
  if (dev === undefined) throw 'Device not found!';

  const z = dev.children.map((childId: string) => getDeviceTree(state, childId));
  z.sort((a, b) => a.orderIndex - b.orderIndex);

  return {
    id: dev.id,
    simlabDeviceId: dev.simlabDeviceId,
    name: dev.name,
    simlabDeviceType: dev.simlabDeviceType,
    parentId: dev.parentId,
    orderIndex: dev.orderIndex,
    fullPath: dev.fullPath,
    visible: dev.visible,
    children: z
  } as TreeNode<SimlabDeviceType>;
};

const getReducedDeviceTree = (
  state: State<DeviceStructure>,
  deviceId: string
): TreeNode<SimlabDeviceType> | undefined => {
  let dev = state.entities[deviceId];
  if (dev === undefined) throw 'Device not found!';

  let children: TreeNode<SimlabDeviceType>[] = [];
  if (dev.children.length === 1 && dev.parentId !== null && dev.simlabDeviceType === 'Container') {
    const parentId = dev.parentId;
    const parentOrderIndex = dev.orderIndex;
    dev = getFirstChildWithFewChildren(state, dev.children[0], dev.name || '');
    if (dev === undefined) return undefined;

    dev.parentId = parentId;
    dev.orderIndex = parentOrderIndex;
  } else {
    if (dev === undefined) return undefined;

    children = dev.children
      .filter((deviceId: string) => {
        const device = dev;
        if (device!.children.length === 0 && device!.simlabDeviceType === 'Container') {
          return undefined;
        }
        return deviceId;
      })
      .map((childId: string) => {
        const t = getReducedDeviceTree(state, childId);
        if (t) return t;

        throw new Error('getReducedDeviceTree are not work ok');
      });
    children.sort((a, b) => a.orderIndex - b.orderIndex);
  }

  return {
    id: dev.id,
    simlabDeviceId: dev.simlabDeviceId,
    name: dev.name,
    simlabDeviceType: dev.simlabDeviceType,
    parentId: dev.parentId,
    orderIndex: dev.orderIndex,
    fullPath: dev.fullPath,
    visible: dev.visible,
    children: children
  } as TreeNode<SimlabDeviceType>;
};

const getFirstChildWithFewChildren = (
  state: State<DeviceStructure>,
  deviceId: string,
  path: string
): DeviceStructure | undefined => {
  let device = state.entities[deviceId];
  if (device === undefined) throw 'Device not found!';

  path += '/' + device.name;

  if (device.children.length > 1 || device.children.length === 0) {
    return { ...device, fullPath: path };
  }
  if (device.children.length === 1) {
    return getFirstChildWithFewChildren(state, device.children[0], path);
  }

  return undefined;
};

const getMainDevice = (state: State<DeviceStructure>, deviceId: string): DeviceStructure => {
  const device = state.entities[deviceId];

  if (device === undefined) throw 'Device not found!';

  if (device.parentId) {
    return getMainDevice(state, device.parentId);
  }
  return device;
};

const findFirstVisibleChild = (
  deviceId: string,
  state: State<DeviceStructure>,
  ignoreVisibleProperties: boolean = false
): DeviceStructure | null => {
  const device = state.entities[deviceId];

  if (device === undefined) throw 'Device not found!';

  if (
    deviceId &&
    device.simlabDeviceType !== 'Container' &&
    (ignoreVisibleProperties || device.visible) &&
    device.children.length === 0
  ) {
    return device;
  }

  for (const child of device.children) {
    const foundedNode = findFirstVisibleChild(child, state, ignoreVisibleProperties);
    if (foundedNode) {
      return foundedNode;
    }
  }

  return null;
};
