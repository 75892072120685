export type ImageSize = 'Thumbnail' | 'Small' | 'Medium' | 'Big' | 'Hd';

export const IMAGE_SIZE_URL_SUFFIX: Record<ImageSize, string> = {
  Thumbnail: '_Thumbnail',
  Small: '_Small',
  Medium: '_Medium',
  Big: '_Big',
  Hd: '_Hd'
};

export interface UploadImage {
  id: string;
  image: string;
}
