import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { LeaveApartment } from './application.actions';
@Injectable({
  providedIn: 'root'
})
export class ApplicationFacade {
  private readonly store = inject(Store);

  leaveApartment() {
    this.store.dispatch(LeaveApartment());
  }
}
