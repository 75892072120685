export const DeviceAlarms: string[] = [
  'AlarmTriggered',
  'GasDetected',
  'FloodDetected',
  'CoDetected',
  'HeatDetected',
  'SmokeDetected'
];
export interface SimDeviceWarningModalResult {
  action: 'close' | 'buttonClick';
  isAlertDelay?: boolean;
}

export interface SimDeviceWarningLocalStorage {
  apartmentId: string;
  deviceId: string;
  alertDate: string;
}
