import { CommonModule } from '@angular/common';
import { forwardRef, ModuleWithProviders, NgModule } from '@angular/core';
import { PaymentFormComponent } from './components/payment-form/payment-form.component';
import { Configuration } from './models/configuration';
import { BasePaymentsService } from './services/base-payments.service';
import { LoadPayUSdkService } from './services/load-pay-usdk.service';
import { Payments, TOKEN } from './services/payments.service';
import { ENDPOINTS } from './tokens/endpoints.token';
import { POS_ID } from './tokens/pos-id.token';
import { PAYU_SDK_URL } from './tokens/url.token';
import { HttpClientModule } from '@angular/common/http';
import { PaymentApiService } from './services/payment-api.service';

@NgModule({
  declarations: [
    PaymentFormComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  exports: [
    PaymentFormComponent,
  ]
})
export class PayUModule {
 /**
 * @param configuration - {@link Configuration}
 */
  static forRoot(configuration:Configuration): ModuleWithProviders<PayUModule> {
    return {
      ngModule: PayUModule,
      providers: [
        { provide: PAYU_SDK_URL, useValue: configuration.url },
        { provide: POS_ID, useValue: configuration.posId },
        { provide: ENDPOINTS, useValue: configuration.endpoints },
        LoadPayUSdkService,
        PaymentApiService,

        { provide: TOKEN, useExisting: forwardRef(()=> BasePaymentsService) },
      ],
    };
  }
}
