import { createAction, props } from '@ngrx/store';
import { DeleteUploadedFileInterface, Pending } from '@simOn/common/media';
import {
  AddTicketCommentInterface,
  TicketCommentInterface,
  UpdateTicketCommentInterface
} from '@simOn/ticket/comments/models';
import { TTicketState, TUpdateTicket } from '@simOn/ticket/element/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const GetTicket = createAction('[Tickets] Get Ticket', props<{ state: string }>());
export const GetTicketSuccess = createAction('[Tickets][Success] Get Ticket', props<{ state: TTicketState }>());
export const GetTicketFailure = createAction('[Tickets][Failure] Get Ticket', props<{ state: Error }>());

export const GetTickets = createAction('[Tickets] Get Tickets', props<{ state: string; autoUpdate: boolean }>());
export const GetTicketsSuccess = createAction('[Tickets][Success] Get Tickets', props<{ state: TTicketState[] }>());
export const GetTicketsFailure = createAction('[Tickets][Failure] Get Tickets', props<{ state: Error[] }>());

export const AddTicket = createAction('[Tickets] Add Ticket', props<{ state: TUpdateTicket }>());
export const AddTicketSuccess = createAction('[Tickets][Success] Add Ticket');
export const AddTicketFailure = createAction('[Tickets][Failure] Add Ticket', props<{ state: Error }>());

export const AddLocalTicket = createAction('[Tickets][Local] Add Ticket', props<{ state: TTicketState }>());
export const UpdateLocalTicket = createAction('[Tickets][Local] Update Ticket', props<{ state: TUpdateTicket }>());
export const DeleteLocalTicket = createAction('[Tickets][Local] Delete Ticket', props<{ state: string }>());

export const UpdateTicket = createAction('[Tickets] Update Ticket', props<{ state: TUpdateTicket }>());
export const UpdateTicketSuccess = createAction('[Tickets][Success] Update Ticket');
export const UpdateTicketFailure = createAction('[Tickets][Failure] Update Ticket', props<{ state: Error }>());

export const DeleteTicket = createAction('[Tickets] Delete Ticket', props<{ state: string }>());
export const DeleteTicketSuccess = createAction('[Tickets][Success] Delete Ticket', props<{ state: string }>());
export const DeleteTicketFailure = createAction('[Tickets][Failure] Delete Ticket', props<{ state: Error }>());
export const PreventTicketRefresh = createAction(
  '[Tickets][Dashboard] Prevent AutoRefresh',
  props<{ prevent: boolean }>()
);
export const AddTicketComment = createAction(
  '[Tickets] Add Ticket Comment',
  props<{ state: { ticketId: string; ticketComment: AddTicketCommentInterface } }>()
);
export const AddTicketCommentSuccess = createAction(
  '[Tickets][Success] Add Ticket Comment',
  props<{ state: { ticketId: string; ticketComment: TicketCommentInterface } }>()
);
export const AddTicketCommentFailure = createAction('[Tickets][Failure] Add Message', props<{ state: Error }>());

export const UpdateTicketComment = createAction(
  '[Tickets] Update Ticket Comment',
  props<{ state: { ticketId: string; ticketComment: UpdateTicketCommentInterface } }>()
);
export const UpdateTicketCommentSuccess = createAction(
  '[Tickets][Success] Update Ticket Comment',
  props<{ state: { ticketId: string; ticketComment: UpdateTicketCommentInterface } }>()
);
export const UpdateTicketCommentFailure = createAction(
  '[Tickets][Failure] Update Ticket Comment',
  props<{ state: Error }>()
);

export const DeleteTicketComment = createAction('[Tickets] Delete Ticket Comment', props<{ state: string }>());
export const DeleteTicketCommentSuccess = createAction(
  '[Tickets][Success] Delete Ticket Comment',
  props<{ state: string }>()
);
export const DeleteTicketCommentFailure = createAction(
  '[Tickets][Failure] Delete Ticket Comment',
  props<{ state: Error }>()
);

export const UploadFileWithProgress = createAction(
  '[Tickets] Upload File With Progress',
  props<{ fileName: string }>()
);
export const UploadFileWithProgressStarted = createAction('[Tickets][Started] Upload File With Progress');
export const UploadFileWithProgressCompleted = createAction('[Tickets][Completed] Upload File With Progress');
export const UploadFileWithProgressPending = createAction(
  '[Tickets][Pending] Upload File With Progress',
  props<{ state: Pending }>()
);
export const UploadFileWithProgressSuccess = createAction(
  '[Tickets][Success] Upload File With Progress',
  props<{ state: TUpdateTicket; fileName: string }>()
);
export const UploadFileWithProgressFailure = createAction(
  '[Tickets][Failure] Upload File With Progress',
  props<{ state: string }>()
);

export const DeleteUploadedFileSimplified = createAction(
  '[Tickets] Delete Uploaded File Simplified',
  props<{ state: DeleteUploadedFileInterface }>()
);
export const DeleteUploadedFileSimplifiedSuccess = createAction('[Tickets][Success] Delete Uploaded File Simplified');
export const DeleteUploadedFileSimplifiedFailure = createAction(
  '[Tickets][Failure] Delete Uploaded File Simplified',
  props<{ state: Error }>()
);
export const MarkTicketAsRead = createAction('[Tickets] Mark Ticket As Read', props<{ ticketId: string }>());
export const MarkTicketAsReadSuccess = createAction(
  '[Tickets][Success] Mark Ticket As Read',
  props<{ ticketId: string }>()
);
export const DeleteTicketFromStore = createAction(
  '[Tickets][Local] Delete Ticket by Id',
  props<{ ticketId: string }>()
);
export const HandleTicketModified = createAction('[Tickets][SignalR] Ticked Modified', props<{ ticketId: string }>());
export const HandleTicketModifiedSuccess = createAction(
  '[Tickets][SignalR][Success] Ticked Modified',
  props<{ ticket: TTicketState }>()
);

export const SetSelectedId = createAction('[Tickets] Set Selected Id', props<{ selectedId: string }>());

export const ClearTicketState = CLEAR_STATE_ACTION('Tickets');
