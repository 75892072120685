<span i18n="@@MATTERPORT_HINT_MODAL_TITLE" class="hint-title">Please provide login session hint</span>
<div class="hint-container">
  <sim-form-field>
    <label i18n="@@3D_MAIN_PRIVATE_SCAN_HINT">Scan access hint: </label>
    <sim-input
      i18n-placeholder="@@MATTERPORT_HINT_INPUT_PLACEHOLDER"
      id="matterport-token-description-input"
      [(ngModel)]="tokenDescription"
      type="text"
      placeholder="Ex. My Matterport organization"
    ></sim-input>
  </sim-form-field>
  <sim-tooltip position="left" [tooltip]="tooltip">
    <sim-icon class="hint-container__tooltip-icon" icon="info" color="#596870" sizeType="medium"></sim-icon>
  </sim-tooltip>
</div>
<div class="hint-footer-buttons">
  <button id="matterport-token-description-confirm" sim-flat-button simColor="primary" (click)="next()">
    <ng-container i18n="@@GENERAL_NEXT">Next</ng-container>
  </button>
</div>
