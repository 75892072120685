import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export declare type ValidationErrorMessage = {
  [key: string]: string;
};

@Component({
  selector: 'sim-hint',
  templateUrl: './sim-hint.component.html',
  styleUrls: ['./sim-hint.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SimHintComponent {
  private _extendedErrorTable: ValidationErrorMessage = errorTable;
  private _errorMessage: ValidationErrorMessage | undefined;
  private _hint: string = '';
  private _hintColor: string = '';
  private _formControlErrors: ValidationErrors | undefined | null;
  private _msg: string = '';

  @HostBinding() class = 'sim-hint';

  @Input() set errorMessage(errorMessage: ValidationErrorMessage) {
    this._errorMessage = errorMessage;
    this._extendedErrorTable = { ...this._extendedErrorTable, ...errorMessage };
    this.mapErrors();
  }

  @Input() set formControlErrors(formControlErrors: ValidationErrors | undefined | null) {
    this._formControlErrors = formControlErrors;
    this.mapErrors();
  }

  @Input() set hint(value: string) {
    this._hint = value;
    this.mapErrors();
  }

  @Input() set hintColor(value: string) {
    this._hintColor = value;
  }

  get hintColor(): string {
    return this._hintColor;
  }

  get hint(): string {
    return this._hint;
  }

  get formControlErrors(): ValidationErrors | undefined | null {
    return this._formControlErrors;
  }

  public get hintMessage(): string {
    return this._msg;
  }

  private mapErrors() {
    if (this.hint && !this._formControlErrors) {
      this._msg = this.hint;
    } else if (this._formControlErrors) {
      -Object.keys(this._formControlErrors || {}).map((errProp) => {
        this._msg = this._extendedErrorTable[errProp];
      });
    } else {
      this._msg = '';
    }
  }
}

export const errorTable: ValidationErrorMessage = {
  required: $localize`:@@VALIDATIONS_FIELD_REQUIRED:* field required.`,
  minlength: $localize`:@@VALIDATIONS_TEXT_TOO_SHORT:Text is too short.`,
  maxlength: $localize`:@@VALIDATIONS_TEXT_TOO_LONG:Text is too long.`,
  pattern: $localize`:@@VALIDATIONS_ILLEGAL_FORMAT:Illegal format.`,
  password: $localize`:@@VALIDATIONS_DIFFERENT_PASSWORD:Different Password`,
  oneOrMore: $localize`:@@VALIDATIONS_SELECT_ONE_OR_MORE:Select one or more`,
  vatNumberRegexp: $localize`:@@VALIDATIONS_INCORRECT_VAT_NUMBER:Incorrect VAT number for selected country`,
  wordIsNotEqual: $localize`:@@VALIDATIONS_WORD_NOT_EQUAL:Word is not equal`
};
