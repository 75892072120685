<ng-container *ngIf="visible | async">
  <div class="fade-in widget-content-wrapper" *ngIf="{ status: (status$ | async) } as status">
    <device-widget-image
      data-cy="widgetIcon"
      [attr.data-cy-icon]="icon"
      (click)="openDetailsModal()"
      class="image-container"
      [class.image-container__disabled]="alarm$ | async"
      [file]="file"
      [icon]="icon"
    >
    </device-widget-image>

    <div class="widget-body">
      <div class="widget-body__title-container">
        <p *ngIf="name" class="widget-body__title-container--title" data-cy="widgetName" [title]="name">{{ name }}</p>
        <div
          class="widget-body__title-container__position-marker-container"
          *ngIf="status.status !== 'Blocked' && firstPosition"
        >
          <sim-icon
            id="widget-go-to-position"
            data-cy="widgetPositionIcon"
            (click)="goToPosition.emit(firstPosition)"
            class="widget-body__title-container__position-marker-container--icon"
            icon="pin_stroke"
            [color]="['#3F484D', '#F7F8F8']"
            sizeType="large"
          ></sim-icon>
        </div>
      </div>
      <div class="widget-body__command-container" data-cy="widgetBody">
        <ng-container *ngIf="loading$ | async; else loaded">
          <div class="widget-body__command-container--loding"><span i18n="@@GENERAL_LOADING">Loading...</span></div>
        </ng-container>
        <ng-template #loaded>
          <div class="fade-in widget-body__command-container--command"><ng-content></ng-content></div>
        </ng-template>
      </div>
      <div class="widget-body__footer-container">
        <ng-container *ngIf="footer$ | async as footer">
          <ng-container *ngIf="footer.battery">
            <span>{{ footer.battery.value || 0 }}%</span>
            <sim-battery-indicator [batteryLevel]="footer.battery.value"></sim-battery-indicator>
          </ng-container>
          <ng-container *ngIf="footer.power?.value">
            <span>{{ footer.power?.stringify() }}</span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
