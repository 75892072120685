export interface PublicLinkData {
  publicLink: string;
  url: string;
  expire: Date;
  startingScreen: PublicLinkStartingScreen;
  interior?: string;
  plhidemenu: boolean;
  plhideexitlabel: boolean;
}
export enum PublicLinkStartingScreen {
  widgets = 0,
  assets = 1,
  scenes = 2,
  events = 3,
  tickets = 4
}
