import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SHORT_UNITS } from '@simOn/common/units';
import { CommandValuesType, UNIT_TO_USER_PREF } from '@simOn/device/models';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `<p>{{ propertyValue }} {{ propertyUnit }}</p> `,
  styleUrls: ['./display.component.scss'],
  host: {
    class: 'sim-display'
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayComponent extends PropertyBase<'PowerConsumption', string, never> {
  valueToCommand(params: string): { command: keyof CommandValuesType; parameters: string | number } {
    throw new Error('Method not implemented.');
  }
  commandToValue(params: { command: keyof CommandValuesType; parameters: string | number }): string {
    throw new Error('Method not implemented.');
  }
  valueToParameter(params: string): never {
    throw new Error('Method not implemented.');
  }
  set propertyValue(params: string) {
    throw new Error('Method not implemented.');
  }
  get propertyValue(): string {
    const srcUnit = this.propertyDefinition.unit;
    const userPrefUnit = UNIT_TO_USER_PREF[srcUnit];
    const dstUnit = userPrefUnit ? this.userPreferences?.units[userPrefUnit] || srcUnit : srcUnit;
    const value = 'value' in this.propertyDefinition.values && this.propertyDefinition.values.value;
    return this.userPrefValue(srcUnit, dstUnit, value).toString();
  }
  get propertyUnit(): string {
    const srcUnit = this.propertyDefinition.unit;
    const userPrefUnit = UNIT_TO_USER_PREF[srcUnit];
    const dstUnit = userPrefUnit ? this.userPreferences?.units[userPrefUnit] || srcUnit : srcUnit;
    return (dstUnit || srcUnit) !== 'None' ? SHORT_UNITS[dstUnit || srcUnit] : '';
  }
}
