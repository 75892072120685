import {
  BehaviorSubject,
  finalize,
  fromEvent,
  Observable,
  Subject,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { Float32BufferAttribute, Sprite, Vector3 } from 'three';
import { clamp } from 'three/src/math/MathUtils';

import { TransformControls as ThreeTransformControls } from 'three/examples/jsm/controls/TransformControls';
import {
  areaPlanText,
  calculateSurfaceArea,
  convert3DPointsTo2DShape,
  handlePointPositionModification,
} from '../helpers/area-plane.helper';
import {
  CircleCanvasTexture,
  DeviceCanvasTextTexture,
} from '../helpers/canvas.render.helper';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EventEmitter } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Units } from '@simlab/matterport/api';
import { randomUUID } from '../helpers/uuid';
import { Dict } from '../models/dictionary';
import {
  AreaMeshSource,
  IMeasurementComponent,
  IndexedVertice,
  MeasurementMode,
  MeshTriangle,
  TAreaListenersAction,
  TAreaMesh,
  TAreaMeshChange,
  TAreaMeshInputs,
  TEMPORARY_POINT_ICONS,
  TMeasurementElements,
  Vertice,
} from '../models/measurement-tool.interface';
import { segmentAreaMesh } from './area-mesh-segments';
import { ExtendedThree } from './matterport-base';
import {
  ComponentInteractionType,
  SceneComponentBase,
} from './scene-component-base';

export const measurementToolType = 'mp.measurementToolType';
export function makeMeasurementToolComponentRenderer() {
  return new AreaMeshComponent();
}
const LAYER = 21;
const DISABLE_LAYER = 22;

const DISTANCE_TO_CLOSEST_POINT = 0.1;
export default class AreaMeshComponent
  extends SceneComponentBase
  implements TAreaMesh, IMeasurementComponent
{
  inputs: TAreaMeshInputs = {
    id: randomUUID(),
    surfaceSize: 0,
    triangles: [],
    vertices: [],
    color: '#ff0000',
    surfaceSizeUnit: Units.Metric,
  };
  private clockNow = 0;

  public events: Dict<boolean> = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
  };
  private _group!: THREE.Group;
  private _vertices: IndexedVertice[] = [];
  private _triangles: MeshTriangle[] = [];
  private _surfaceSize = 0;
  private _mesh!: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>;
  // private _linesRefs: Map<
  //   string,
  //   THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>
  // > = new Map();
  private _linesRefs: Map<
    string,
    THREE.Line<THREE.BufferGeometry, THREE.LineBasicMaterial>
  > = new Map();
  private _pointsRefs: Map<string, THREE.Sprite> = new Map();
  private _selected = false;

  // private _lineMaterial!: THREE.MeshBasicMaterial;
  private _lineMaterial!: THREE.LineBasicMaterial;
  private _pointMaterial!: THREE.SpriteMaterial;
  private _areaMeshMaterial!: THREE.MeshBasicMaterial;
  private _temporarySpriteMeshRef: THREE.Sprite | undefined;
  private readonly _surfaceSizeFrontPosition = new Vector3();
  private readonly _surfaceSizeBackPosition = new Vector3();
  // private _surfaceSizeMesh!: THREE.Sprite;
  private _surfaceSizeMesh!: THREE.Mesh<
    THREE.BufferGeometry,
    THREE.MeshBasicMaterial
  >;
  private _mode: MeasurementMode = 'create';
  private _undo: TAreaMesh[] = [];
  private _redo: TAreaMesh[] = [];
  private readonly _selectionChange: Subject<string | undefined> = new Subject<
    string | undefined
  >();
  readonly selectionChange$: Observable<string | undefined> =
    this._selectionChange.asObservable();
  public needUpdate = false;
  private readonly _destroyTransformControl: Subject<void> =
    new Subject<void>();
  private _color = '#4374E4';
  private _segmentsLabelGroup: THREE.Group | undefined;
  constructor() {
    super();
  }
  set segmentsVisibility(visible: boolean) {
    if (visible) {
      this._segmentsLabelGroup = new this.THREE.Group();
      this._vertices.forEach((vertex: IndexedVertice, idx: number) => {
        const nextIdx =
          this._vertices.length === vertex.index + 1 ? 0 : vertex.index + 1;
        const firstVertexPosition = new Vector3(vertex.x, vertex.y, vertex.z);
        const secondVertex = this._vertices[nextIdx];
        const secondVertexPosition = new Vector3(
          secondVertex.x,
          secondVertex.y,
          secondVertex.z
        );
        const labelPosition = firstVertexPosition
          .clone()
          .lerp(secondVertexPosition, 0.5);
        const distance = firstVertexPosition.distanceTo(secondVertexPosition);
        const mesh = segmentAreaMesh(
          this.THREE,
          distance,
          labelPosition,
          this.inputs.surfaceSizeUnit
        );
        // this.context.camera.parent?.position &&
        //   mesh.lookAt(this.context.camera.parent?.position);

        this._segmentsLabelGroup && this._segmentsLabelGroup.add(mesh);
      });

      this._segmentsLabelGroup &&
        this.context.scene.add(this._segmentsLabelGroup);
      this._segmentsToCamera();
    } else {
      if (this._segmentsLabelGroup) {
        this._segmentsLabelGroup.traverse((object) => {
          this.context.scene.remove(object);
        });
        this.context.scene.remove(this._segmentsLabelGroup);
      }
    }
  }
  get canUndo(): boolean {
    return this._undo.length > 0;
  }
  get canRedo(): boolean {
    return this._redo.length > 0;
  }
  readonly areaChange$: EventEmitter<TAreaMeshChange> =
    new EventEmitter<TAreaMeshChange>();
  readonly mode$: BehaviorSubject<MeasurementMode> =
    new BehaviorSubject<MeasurementMode>(this.mode);
  public get surfaceSizeUnit(): Units | undefined {
    return this.inputs.surfaceSizeUnit;
  }
  public set surfaceSizeUnit(value: Units | undefined) {
    this.inputs.surfaceSizeUnit = value;
  }
  set disableLayers(layers: {
    points: boolean;
    lines: boolean;
    mesh: boolean;
  }) {
    this._linesRefs.forEach((line) =>
      line.layers.set(layers.lines ? DISABLE_LAYER : LAYER)
    );
    this._pointsRefs.forEach((point) =>
      point.layers.set(layers.points ? DISABLE_LAYER : LAYER)
    );
    this._mesh && this._mesh.layers.set(layers.mesh ? DISABLE_LAYER : LAYER);
  }
  createTemporaryPoint(action: TAreaListenersAction) {
    const material = new this.THREE.SpriteMaterial({
      depthTest: false,
      depthWrite: false,
    });

    const image = new Image();
    const icon = TEMPORARY_POINT_ICONS[action];

    if (image) {
      image.onload = (e) => {
        const canvasTextureIcon = new CircleCanvasTexture(
          icon.background || this.color,
          icon.background || this.color,
          100,
          100,
          image
        );
        const texture = new this.THREE.CanvasTexture(
          canvasTextureIcon.ctx.canvas
        );
        texture.minFilter = this.THREE.NearestFilter;
        texture.encoding = this.THREE.sRGBEncoding;

        material.map = texture;
        material.needsUpdate = true;
      };
    }
    image.src = icon.iconUrl;

    this._temporarySpriteMeshRef = new this.THREE.Sprite(material);
    this._temporarySpriteMeshRef.scale.set(0.06, 0.06, 0.06);

    this.context.scene.add(this._temporarySpriteMeshRef);
    return this._temporarySpriteMeshRef;
  }
  removeTemporaryPoint() {
    this._temporarySpriteMeshRef &&
      this.context.scene.remove(this._temporarySpriteMeshRef) &&
      (this._temporarySpriteMeshRef = undefined);
  }
  cancelAllChanges() {
    if (this._undo.length) {
      const { surfaceSize, triangles, vertices } =
        this._undo.shift() as TAreaMesh;
      this._surfaceSize = surfaceSize;
      this._triangles = triangles;
      if (this._vertices.length > vertices.length) {
        const objectToDelete = this._vertices.filter(
          (actVertex) =>
            !vertices.some(
              (prevVertex) => (prevVertex as IndexedVertice).id === actVertex.id
            )
        );
        objectToDelete.forEach((object) => this._deletedObject(object.id));
      }
      this._vertices = vertices as IndexedVertice[];
      this._createMesh();
    }
    this._undo = [];
    this._redo = [];
    this.mode = 'read';
    this._emitChanges('cancelAllChanges');
  }

  private _emitChanges(changesSource: AreaMeshSource) {
    this.areaChange$.emit(
      new TAreaMeshChange(
        {
          id: this.id,
          surfaceSize: this.surfaceSize,
          triangles: this.triangles,
          vertices: this.vertices,
          color: this.color,
        },
        changesSource
      )
    );
  }

  hide = () => (this._group.visible = false);
  show = () => (this._group.visible = true);
  undo() {
    if (!this._undo.length) return;
    this._pushRedo();
    const { surfaceSize, triangles, vertices } = this._undo.pop() as TAreaMesh;
    this._surfaceSize = surfaceSize;
    this._triangles = triangles;
    if (this._vertices.length > vertices.length) {
      const objectToDelete = this._vertices.filter(
        (vertex) => !vertices.includes(vertex)
      );
      objectToDelete.forEach((object) => this._deletedObject(object.id));
    }
    this._vertices = vertices as IndexedVertice[];

    this._createMesh();
    this._emitChanges('undo');
  }

  redo() {
    if (!this._redo.length) return;
    this._pushUndo();
    const { surfaceSize, triangles, vertices } =
      this._redo.shift() as TAreaMesh;
    this._surfaceSize = surfaceSize;
    if (this._vertices.length > vertices.length) {
      const objectToDelete = this._vertices.filter(
        (vertex) => !vertices.includes(vertex)
      );
      objectToDelete.forEach((object) => this._deletedObject(object.id));
    }
    this._triangles = triangles;
    this._vertices = vertices as IndexedVertice[];
    this._createMesh();
    this._emitChanges('redo');
  }
  public get color(): string {
    return this._color;
  }
  public set color(value: string) {
    this._color = value;
    this._emitChanges('color');
  }
  public get id(): string {
    return this.inputs.id;
  }
  public set id(value: string) {
    this.inputs.id = value;
  }
  public get mode(): MeasurementMode {
    return this._mode;
  }
  public set mode(value: MeasurementMode) {
    switch (value) {
      case 'read': {
        this._redo = [];
        this._undo = [];
        this._mesh && this._mesh.layers.set(LAYER);

        break;
      }
      case 'update': {
        this._mesh && this._mesh.layers.set(DISABLE_LAYER);
      }
    }
    this._mode = value;
    this.mode$.next(this.mode);
  }
  public get pointMaterial(): THREE.SpriteMaterial {
    return this._pointMaterial;
  }
  public set pointMaterial(value: THREE.SpriteMaterial) {
    this._pointMaterial = value;
  }
  // public get lineMaterial(): THREE.MeshBasicMaterial {
  //   return this._lineMaterial;
  // }
  // public set lineMaterial(value: THREE.MeshBasicMaterial) {
  //   this._lineMaterial = value;
  // }
  public get lineMaterial(): THREE.LineBasicMaterial {
    return this._lineMaterial;
  }
  public set lineMaterial(value: THREE.LineBasicMaterial) {
    this._lineMaterial = value;
  }
  public get selected() {
    return this._selected;
  }
  public set selected(value: boolean) {
    if (this._selected === value) return;
    if (value) {
      this._selectionChange.next(this.id);
      this.showEditHelperMesh = true;
    } else {
      this.showEditHelperMesh = false;
      this._destroyTransformControl.next();
      this._undo = [];
      this._redo = [];
      this.segmentsVisibility = false;
    }
    this._selected = value;
    this._setAreaMeshMaterial();
  }

  private set showEditHelperMesh(value: boolean) {
    if (value) {
      this._linesRefs.forEach((line) => (line.visible = true));
      this._pointsRefs.forEach((point) => (point.visible = true));
    } else {
      this._linesRefs.forEach((line) => (line.visible = false));
      this._pointsRefs.forEach((point) => (point.visible = false));
    }
  }
  get THREE(): ExtendedThree {
    return this.context.three as ExtendedThree;
  }
  get vertices(): Vertice[] {
    return this._vertices.map(({ x, y, z }, index) => ({ index, x, y, z }));
  }
  get triangles(): MeshTriangle[] {
    return this._triangles;
  }
  get surfaceSize(): number {
    return this._surfaceSize;
  }

  private get linearColor() {
    return new this.THREE.Color(this.color).convertSRGBToLinear();
  }
  override onInputsUpdated(previousInputs: TAreaMeshInputs) {
    if (this.inputs.color && previousInputs.color !== this.inputs.color) {
      this.color = this.inputs.color;
      this._setLineMaterial();
      this._setPointMaterial();
      this._setAreaMeshMaterial();
    }
    if (
      this.inputs.surfaceSizeUnit &&
      previousInputs.color !== this.inputs.surfaceSizeUnit
    ) {
      this.surfaceSizeMesh && this._setSurfaceAreaTexture();
      this._segmentsLabelGroup &&
        ((this.segmentsVisibility = false), (this.segmentsVisibility = true));
    }
    super.onInputsUpdated && super.onInputsUpdated(previousInputs);
  }
  override onInit() {
    this._group = new this.THREE.Group();
    this._group.name = this.inputs.id;
    if (this.inputs.color) {
      this.color = this.inputs.color;
    }
    this._setLineMaterial();
    this._setPointMaterial();
    this._setAreaMeshMaterial();
    if (
      this.inputs &&
      'triangles' in this.inputs &&
      this.inputs.triangles.length
    ) {
      this.mode = 'read';
      const { vertices, triangles, surfaceSize } = this.inputs;
      this._surfaceSize = surfaceSize;
      this._triangles = triangles;
      this._vertices = vertices.map((vertice) => ({
        ...vertice,
        id: randomUUID(),
      }));
      this._createMesh();
      this.showEditHelperMesh = false;
    }
    this.outputs.objectRoot = this._group;
  }

  set collider(colision: boolean) {
    this.outputs.collider = colision ? this._group : null;
  }
  private _setAreaMeshMaterial() {
    !this._areaMeshMaterial &&
      (this._areaMeshMaterial = new this.THREE.MeshBasicMaterial({
        opacity: 0.5,
        side: this.THREE.DoubleSide,
        transparent: true,
      }));
    const color = this.selected ? '#FFFFFF' : this.linearColor;
    this._areaMeshMaterial.color.set(color);
  }
  private _setLineMaterial() {
    !this._lineMaterial &&
      // (this._lineMaterial = new this.THREE.MeshBasicMaterial({
      //   clipShadows: false,
      //   reflectivity: 0,
      //   refractionRatio: 0,
      //   alphaMap: null,
      //   aoMapIntensity: 0,
      //   lightMap: null,
      //   toneMapped: false,
      //   envMap: null,
      // }));
      (this._lineMaterial = new this.THREE.LineBasicMaterial({
        color: new this.THREE.Color(this.color),
        clipShadows: false,
        toneMapped: false,
        linewidth: 1, // in world units with size attenuation, pixels otherwise
      }));
    this._lineMaterial.color.set(this.linearColor);
  }
  private _setPointMaterial() {
    const canvasTextureIcon = new CircleCanvasTexture(
      this.color,
      '#ffffff',
      100,
      100
    );
    const texture = new this.THREE.CanvasTexture(canvasTextureIcon.ctx.canvas);
    texture.minFilter = this.THREE.NearestFilter;
    texture.encoding = this.THREE.sRGBEncoding;
    !this._pointMaterial &&
      (this._pointMaterial = new this.THREE.SpriteMaterial({
        depthTest: false,
        depthWrite: false,
      }));
    this._pointMaterial.map = texture;
  }

  onComponentClick(
    transformControlsRef: ThreeTransformControls,
    intersect: THREE.Intersection<THREE.Object3D<THREE.Event>>
  ) {
    let intersectObjectType: TMeasurementElements = intersect.object
      .name as TMeasurementElements;
    if (this.mode !== 'update' || this._temporarySpriteMeshRef) return false;
    if (this.selected === false) {
      this.selected = true;
      return false;
    }
    let intersectObject = intersect.object;
    let distanceToClosestPoint = 999;
    this._vertices.forEach(({ x, y, z, id }) => {
      const distance = new Vector3(x, y, z).distanceTo(intersect.point);
      if (
        distance < DISTANCE_TO_CLOSEST_POINT &&
        distanceToClosestPoint > distance
      ) {
        distanceToClosestPoint = distance;
        intersectObject = this._pointsRefs.get(id) as Sprite;
        intersectObjectType = 'AREA_POINT';
      }
    });
    switch (intersectObjectType) {
      case 'AREA_POINT': {
        this._pushUndo();
        transformControlsRef && transformControlsRef.attach(intersectObject);
        this.collider = true;
        this._areaPointPositionObserver(transformControlsRef);
        break;
      }
      case 'AREA_BACKGROUND': {
        break;
      }
      case 'AREA_LINE': {
        break;
      }
    }
    return true;
  }
  removePoint(objectId: string) {
    if (this._vertices.length <= 3) return;
    this._pushUndo();
    const deletedElement = {
      ...this._vertices.find((vertex) => vertex.id === objectId),
    };
    if (!deletedElement) return;
    const deletedIndex = deletedElement.index;
    if (deletedIndex === undefined) return;
    let nextVertexIdx =
      deletedIndex === this._vertices.length - 1 ? 0 : deletedIndex + 1;

    this._vertices = this._vertices
      .filter((vertex) => vertex.id !== objectId)
      .map((vertex) => ({
        ...vertex,
        index: vertex.index > deletedIndex ? --vertex.index : vertex.index,
      }));
    nextVertexIdx > deletedIndex && --nextVertexIdx;

    this._triangles = this._triangles
      .map(({ index, firstVertice, secondVertice, thirdVertice }) => ({
        index,
        firstVertice: firstVertice === deletedIndex ? -1 : firstVertice,
        secondVertice: secondVertice === deletedIndex ? -1 : secondVertice,
        thirdVertice: thirdVertice === deletedIndex ? -1 : thirdVertice,
      }))
      .map(({ index, firstVertice, secondVertice, thirdVertice }) => ({
        index,
        firstVertice:
          firstVertice > deletedIndex ? --firstVertice : firstVertice,
        secondVertice:
          secondVertice > deletedIndex ? --secondVertice : secondVertice,
        thirdVertice:
          thirdVertice > deletedIndex ? --thirdVertice : thirdVertice,
      }))
      .map(({ index, firstVertice, secondVertice, thirdVertice }) => ({
        index,
        firstVertice: firstVertice === -1 ? nextVertexIdx : firstVertice,
        secondVertice: secondVertice === -1 ? nextVertexIdx : secondVertice,
        thirdVertice: thirdVertice === -1 ? nextVertexIdx : thirdVertice,
      }))
      .filter(({ index, firstVertice, secondVertice, thirdVertice }) => {
        return (
          [firstVertice, secondVertice, thirdVertice].filter(
            (value, index, array) => array.indexOf(value) === index
          ).length === 3
        );
      })
      .map((triangle, index) => ({
        ...triangle,
        index,
      }));
    this._deletedObject(objectId);
    this._surfaceSize = calculateSurfaceArea(this._triangles, this._vertices);
    this._createMesh();
    this._emitChanges('removePoint');
  }
  private _deletedObject(objectId: string) {
    const deletedObject = this._pointsRefs.get(objectId);
    if (deletedObject) {
      deletedObject.geometry.dispose();
      this._group.remove(deletedObject);
      this.context.scene.remove(deletedObject);
      this._pointsRefs.delete(objectId);
    }
    const deletedObjectLine = this._linesRefs.get(objectId);

    if (deletedObjectLine) {
      deletedObjectLine.geometry.dispose();
      this._group.remove(deletedObjectLine);
      this.context.scene.remove(deletedObjectLine);
      this._linesRefs.delete(objectId);
    }
  }

  addPoint(point: THREE.Vector3, normal: THREE.Vector3, nextIndex?: number) {
    this._pushUndo();

    const position = new Vector3(point.x, point.y, point.z).add(
      new Vector3(normal.x, normal.y, normal.z).multiply(
        new Vector3(0.01, 0.01, 0.01)
      )
    );
    const calculatedPosition = handlePointPositionModification(
      position,
      this._vertices.map(({ x, y, z }) => new Vector3(x, y, z))
    );
    const distanceToFirst = this.vertices.length
      ? new Vector3(
          this.vertices[0].x,
          this.vertices[0].y,
          this.vertices[0].z
        ).distanceTo(calculatedPosition)
      : 99999;
    const id = randomUUID();

    if (distanceToFirst > 0.05) {
      const { x, y, z } = calculatedPosition;
      const index = nextIndex || this.vertices.length;
      this.vertices.push({ x, y, z, index });
      this._vertices.push({ x, y, z, index, id });
      this._drawPoints();
      this._drawLines();
    } else {
      this.finish();
    }
    if (this.vertices.length >= 3) {
      this.recalculateTriangles();
      this._drawMesh();
      this._drawLines();

      this._surfaceSize = calculateSurfaceArea(this._triangles, this._vertices);
      this._drawSurfaceSize();
    }
    this._emitChanges('addPoint');

    return this._pointsRefs.get(id);
  }
  finish() {
    this.mode = 'read';
    this.selected = true;

    this._emitChanges('areaCreated');
  }
  private _pushUndo() {
    this._undo.push({
      id: this.id,
      surfaceSize: this._surfaceSize,
      triangles: JSON.parse(JSON.stringify(this._triangles)),
      vertices: JSON.parse(JSON.stringify(this._vertices)),
      color: this._color,
    });
  }
  private _pushRedo() {
    const actual: TAreaMesh = {
      id: this.id,
      surfaceSize: this.surfaceSize,
      triangles: JSON.parse(JSON.stringify(this._triangles)),
      vertices: JSON.parse(JSON.stringify(this._vertices)),
      color: this._color,
    };
    this._redo.splice(0, 0, actual);
  }
  recalculateTriangles() {
    const contour = convert3DPointsTo2DShape(
      this._vertices.map(({ x, y, z, index }) => {
        return new Vector3(x, y, z);
      })
    );
    const triangles = this.THREE.ShapeUtils.triangulateShape(contour, []);
    this._triangles = triangles.map(
      (face, index) =>
        ({
          firstVertice: face[0],
          secondVertice: face[1],
          thirdVertice: face[2],
          index,
        } as MeshTriangle)
    );
  }

  addClosestPoint(
    lineId: string,
    point: THREE.Vector3,
    normal: THREE.Vector3
  ): string | undefined {
    const line = this._linesRefs.get(lineId);
    if (!line) return;
    this._pushUndo();
    const position = point.add(
      new Vector3(normal.x, normal.y, normal.z).multiply(
        new Vector3(0.01, 0.01, 0.01)
      )
    );
    const fromVerticeIdx = this._vertices.findIndex(
      (vertice) => vertice.id === lineId
    );
    const toVerticeIdx =
      this._vertices.length === fromVerticeIdx + 1 ? 0 : fromVerticeIdx + 1;
    const { index: fromIndex } = this._vertices[fromVerticeIdx];
    const { x, y, z } = this.getClosestPoint(lineId, position);
    this._vertices = this._vertices.map((vertice) => ({
      ...vertice,
      index: vertice.index > fromIndex ? vertice.index + 1 : vertice.index,
    }));
    const newPointIndex = this._vertices[fromIndex].index + 1;
    const newVertice = {
      index: newPointIndex,
      x,
      y,
      z,
      id: randomUUID(),
    };
    this._vertices.splice(fromIndex + 1, 0, newVertice);
    let [triangleCpy] = [
      ...this._triangles
        .filter(({ firstVertice, secondVertice, thirdVertice }) =>
          [firstVertice, secondVertice, thirdVertice].includes(fromVerticeIdx)
        )
        .filter(({ firstVertice, secondVertice, thirdVertice }) => {
          const trianglesIdx: number[] = [
            firstVertice,
            secondVertice,
            thirdVertice,
          ];
          const fromVertice = trianglesIdx.findIndex(
            (vertexIdx) => vertexIdx === fromVerticeIdx
          );

          const nextIdx = fromVertice === 2 ? 0 : fromVertice + 1;
          const prevIdx = fromVertice === 0 ? 2 : fromVertice - 1;
          return (
            trianglesIdx[nextIdx] === toVerticeIdx ||
            trianglesIdx[prevIdx] === toVerticeIdx
          );
        }),
    ];
    this._triangles = this._triangles.map(
      ({ firstVertice, index, secondVertice, thirdVertice }) => ({
        index,

        firstVertice:
          firstVertice > fromIndex ? firstVertice + 1 : firstVertice,
        secondVertice:
          secondVertice > fromIndex ? secondVertice + 1 : secondVertice,
        thirdVertice:
          thirdVertice > fromIndex ? thirdVertice + 1 : thirdVertice,
      })
    );

    triangleCpy = {
      index: triangleCpy.index,

      firstVertice:
        triangleCpy.firstVertice > fromIndex
          ? triangleCpy.firstVertice + 1
          : triangleCpy.firstVertice,
      secondVertice:
        triangleCpy.secondVertice > fromIndex
          ? triangleCpy.secondVertice + 1
          : triangleCpy.secondVertice,
      thirdVertice:
        triangleCpy.thirdVertice > fromIndex
          ? triangleCpy.thirdVertice + 1
          : triangleCpy.thirdVertice,
    };
    let newTriangle: MeshTriangle = {} as MeshTriangle;
    if (!triangleCpy) return;
    if (triangleCpy.firstVertice == fromVerticeIdx) {
      newTriangle = {
        index: this._triangles.length + 1,
        firstVertice: newPointIndex,
        secondVertice: triangleCpy.secondVertice,
        thirdVertice: triangleCpy.thirdVertice,
      };
    } else if (triangleCpy.secondVertice == fromVerticeIdx) {
      newTriangle = {
        index: this._triangles.length + 1,
        firstVertice: triangleCpy.firstVertice,
        secondVertice: newPointIndex,
        thirdVertice: triangleCpy.thirdVertice,
      };
    } else if (triangleCpy.thirdVertice == fromVerticeIdx) {
      newTriangle = {
        index: this._triangles.length + 1,
        firstVertice: triangleCpy.firstVertice,
        secondVertice: triangleCpy.secondVertice,
        thirdVertice: newPointIndex,
      };
    }

    this._triangles.push(newTriangle);
    const prevIdx =
      toVerticeIdx >= newPointIndex ? toVerticeIdx + 1 : toVerticeIdx;
    if (triangleCpy.firstVertice == prevIdx) {
      triangleCpy.firstVertice = newPointIndex;
    } else if (triangleCpy.secondVertice == prevIdx) {
      triangleCpy.secondVertice = newPointIndex;
    } else if (triangleCpy.thirdVertice == prevIdx) {
      triangleCpy.thirdVertice = newPointIndex;
    }

    this._triangles = this._triangles.filter(
      (triangle) => triangle.index !== triangleCpy.index
    );
    this._triangles.push(triangleCpy);
    this._triangles = this._triangles.map((triangle, index) => ({
      ...triangle,
      index,
    }));
    this._createMesh();
    this._emitChanges('addPoint');

    return newVertice.id;
  }
  getClosestPoint(
    lineId: string,
    position: Vector3
  ): { x: number; y: number; z: number } {
    const fromVerticeIdx = this._vertices.findIndex(
      (vertice) => vertice.id === lineId
    );
    const toVerticeIdx =
      this._vertices.length === fromVerticeIdx + 1 ? 0 : fromVerticeIdx + 1;
    const { x: x1, y: y1, z: z1 } = this._vertices[fromVerticeIdx];
    const { x: x2, y: y2, z: z2 } = this._vertices[toVerticeIdx];
    const from = new Vector3(x1, y1, z1);
    const to = new Vector3(x2, y2, z2);
    const heading = to.clone().sub(from);
    const distance = heading.length();
    heading.normalize();
    const t = position.clone().sub(from).dot(heading);

    const dotP = clamp(t, 0, distance);
    return from.clone().add(heading.multiplyScalar(dotP));
  }
  private _areaPointPositionObserver(
    transformControlsRef: ThreeTransformControls
  ) {
    const destroy = new Subject<void>();
    fromEvent(transformControlsRef, 'objectChange')
      .pipe(
        tap(() => {
          destroy.next();
          timer(1000)
            .pipe(takeUntil(destroy))
            .subscribe((e) => this._pushUndo());
        }),
        takeUntil(this._destroyTransformControl),
        finalize(() => {
          this.collider = false;
        })
      )
      .subscribe(() => {
        this.needUpdate = true;
      });
  }
  private _createMesh() {
    this._drawMesh();
    this._drawPoints();
    this._drawLines();
    this._drawSurfaceSize();
  }

  private _drawMesh() {
    if (this._vertices.length < 3) return;
    const { geometry } = this.areaMesh;
    geometry.dispose();

    const verticesVectors: number[] = this._vertices
      .map(({ x, y, z }) => [x, y, z])
      .flat();
    const positions: Float32BufferAttribute =
      new this.THREE.Float32BufferAttribute(verticesVectors, 3);

    const indices = this._triangles
      .map((triangle) => [
        triangle.firstVertice,
        triangle.secondVertice,
        triangle.thirdVertice,
      ])
      .flat();
    geometry.setIndex(indices);

    geometry.setAttribute('position', positions);
    geometry.computeVertexNormals();
    geometry.computeBoundingBox();
  }

  private _drawPoints() {
    if (!this._vertices.length) return;
    this._vertices.forEach((vertex: IndexedVertice, idx: number) => {
      const point = this._getPointMesh(vertex.id);
      if (!point) return;
      point.position.set(vertex.x, vertex.y, vertex.z);
    });
  }

  private get _backgroundNormal() {
    const normal = new Vector3();
    const normalsPoints: number[] = Array.from(
      (this._mesh.geometry.getAttribute('normal') as THREE.BufferAttribute)
        .array
    );
    for (let i = 0; i < normalsPoints.length; i += 3) {
      normal.add(
        new Vector3(
          normalsPoints[i],
          normalsPoints[i + 1],
          normalsPoints[i + 2]
        )
      );
    }

    normal.divideScalar(normalsPoints.length / 3);
    return normal;
  }
  private _drawSurfaceSize() {
    if (!this._surfaceSize) return;
    this._setSurfaceAreaTexture();

    const center = new Vector3();

    this._mesh.geometry.boundingBox?.getCenter(center);
    const normal = this._backgroundNormal;
    this._surfaceSizeFrontPosition.copy(
      center.clone().add(normal.clone().multiplyScalar(0.08))
    );
    this._surfaceSizeBackPosition.copy(
      center.clone().sub(normal.clone().multiplyScalar(0.08))
    );
    this._faceSurfaceSizeToCamera();

    this._surfaceSizeMesh.material.needsUpdate = true;
  }
  private _setSurfaceAreaTexture() {
    const text = areaPlanText(
      this._surfaceSize,
      this.inputs.surfaceSizeUnit || Units.Metric
    );
    const textLength = text.length < 5 ? 5 : text.length;
    const canvasTextureIcon = new DeviceCanvasTextTexture(
      {
        text: `${text}`,
        backgroundColor: new this.THREE.Color('#FFFFFF'),
      },
      textLength
    );
    const texture = new this.THREE.CanvasTexture(canvasTextureIcon.ctx.canvas);
    const mesh = this.surfaceSizeMesh;
    mesh.material.dispose();
    mesh.material.map = texture;
    mesh.scale.set(textLength * 0.03, 0.08, 0);
  }
  private _segmentsToCamera() {
    const segments = this._segmentsLabelGroup?.children;
    if (!segments || !segments.length) return;
    const cameraPosition =
      this.context.camera.parent?.position || new Vector3();
    segments.forEach((mesh) => {
      const pos1 =
        mesh.userData['front'] ||
        mesh.position
          .clone()
          .sub(this._backgroundNormal.clone().multiplyScalar(0.08));
      mesh.userData['front'] = pos1;
      const pos2 =
        mesh.userData['back'] ||
        mesh.position
          .clone()
          .add(this._backgroundNormal.clone().multiplyScalar(0.08));
      mesh.userData['back'] = pos2;

      if (cameraPosition.distanceTo(pos1) < cameraPosition.distanceTo(pos2)) {
        mesh.position.copy(pos1);
        mesh.lookAt(pos2);
      } else {
        mesh.position.copy(pos2);
        mesh.lookAt(pos1);
      }
      mesh.rotateY(-Math.PI);
    });
  }
  private _faceSurfaceSizeToCamera() {
    if (!this._surfaceSizeMesh) return;
    const cameraPosition =
      this.context.camera.parent?.position || new Vector3();
    if (
      cameraPosition.distanceTo(this._surfaceSizeFrontPosition) <
      cameraPosition.distanceTo(this._surfaceSizeBackPosition)
    ) {
      this._surfaceSizeMesh.position.copy(this._surfaceSizeFrontPosition);
      this._surfaceSizeMesh.lookAt(this._surfaceSizeBackPosition);
    } else {
      this._surfaceSizeMesh.position.copy(this._surfaceSizeBackPosition);
      this._surfaceSizeMesh.lookAt(this._surfaceSizeFrontPosition);
    }
    this._surfaceSizeMesh.rotateY(-Math.PI);
  }

  private _drawLines() {
    if (this._vertices.length < 2) return;
    this._vertices.forEach((vertex: IndexedVertice, idx: number) => {
      const nextIdx =
        this._vertices.length === vertex.index + 1 ? 0 : vertex.index + 1;
      const lineGeometry = this._getLineGeometry(vertex.id);

      const nextVertex = this._vertices.find(
        (vertex) => vertex.index === nextIdx
      );
      if (!nextVertex) return;
      // const geometry = this.calculateGeometry(
      //   new Vector3(vertex.x, vertex.y, vertex.z),
      //   new Vector3(nextVertex.x, nextVertex.y, nextVertex.z),
      //   0.005
      // );
      // lineGeometry.setIndex(geometry.triangles);
      // const positions: Float32BufferAttribute =
      //   new this.THREE.Float32BufferAttribute(geometry.vertices, 3);
      const positions: Float32BufferAttribute =
        new this.THREE.Float32BufferAttribute(
          [
            vertex.x,
            vertex.y,
            vertex.z,
            nextVertex.x,
            nextVertex.y,
            nextVertex.z,
          ],
          3
        );
      lineGeometry.dispose();
      lineGeometry.setAttribute('position', positions);

      lineGeometry.computeBoundingBox();
    });
  }

  private get surfaceSizeMesh() {
    if (!this._surfaceSizeMesh) {
      const material = new this.THREE.MeshBasicMaterial({
        alphaTest: 0.2,
        polygonOffset: false,
        opacity: 1,
        transparent: false,
        side: this.THREE.DoubleSide,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: 0,
      });
      // this._surfaceSizeMesh = new this.THREE.Sprite(material);
      const geometry = new this.THREE.PlaneGeometry(1, 1);
      this._surfaceSizeMesh = new this.THREE.Mesh(geometry, material);
      this._surfaceSizeMesh.name = 'AREA_SURFACE_SIZE';
      this._group.add(this._surfaceSizeMesh);
    }
    return this._surfaceSizeMesh;
  }
  override onTick(tickDelta: number) {
    if (this.needUpdate) {
      this._vertices = this._vertices.map(({ id, index }) => {
        const { position }: THREE.Sprite = this._pointsRefs.get(
          id
        ) as THREE.Sprite;

        const { x, y, z } = position;
        return { x, y, z, id, index } as IndexedVertice;
      });

      this._createMesh();
      this._surfaceSize = calculateSurfaceArea(this._triangles, this._vertices);
      this.needUpdate = false;
      this._emitChanges('pointPosition');
    }

    if (this.clockNow > 1000) {
      this._faceSurfaceSizeToCamera();
      this._segmentsLabelGroup && this._segmentsToCamera();
      this.clockNow = 0;
    }
    this.clockNow += tickDelta;

    super.onTick && super.onTick(tickDelta);
  }
  private get areaMesh() {
    if (!this._mesh) {
      const geometry: THREE.BufferGeometry = new this.THREE.BufferGeometry();
      this._mesh = new this.THREE.Mesh(geometry, this._areaMeshMaterial);
      this._mesh.layers.set(LAYER);
      this._mesh.name = 'AREA_BACKGROUND';
      this._group.add(this._mesh);
    }
    return this._mesh;
  }
  private _getPointMesh(id: string): THREE.Sprite {
    const pointsMaterial = this._pointMaterial;

    if (!this._pointsRefs.get(id)) {
      const point = new this.THREE.Sprite(pointsMaterial);
      point.scale.set(0.05, 0.05, 0.05);
      this._group.add(point);
      point.layers.set(LAYER);
      point.name = 'AREA_POINT';
      point.userData = {
        id,
      };
      this._pointsRefs.set(id, point);
      return point;
    }
    return this._pointsRefs.get(id) as THREE.Sprite;
  }
  private _getLineGeometry(id: string): THREE.BufferGeometry {
    const lineMaterial = this._lineMaterial;
    const lineGeometry =
      (this._linesRefs.get(id) && this._linesRefs.get(id)?.geometry) ||
      new this.THREE.BufferGeometry();
    if (!this._linesRefs.get(id)) {
      // const line = new this.THREE.Mesh(lineGeometry, lineMaterial);
      const line = new this.THREE.Line(lineGeometry, lineMaterial);
      line.layers.set(LAYER);

      line.name = 'AREA_LINE';
      line.userData = {
        id,
      };
      this._group.add(line);
      this._linesRefs.set(id, line);
    }
    return lineGeometry;
  }
  calculateGeometry(startPoint: Vector3, endPoint: Vector3, width: number) {
    const firstPerpendicularVector = this.directionVector(startPoint, endPoint)
      .clone()
      .cross(new Vector3(0, 1, 0))
      .normalize()
      .multiplyScalar(width);
    const secondPerpendicularVector = this.directionVector(startPoint, endPoint)
      .clone()
      .cross(new Vector3(-1, 0, 0))
      .normalize()
      .multiplyScalar(width);

    const verticesVectors = [
      startPoint.clone().add(firstPerpendicularVector),
      startPoint.clone().sub(firstPerpendicularVector),
      startPoint.clone().add(secondPerpendicularVector),
      startPoint.clone().sub(secondPerpendicularVector),
      endPoint.clone().add(firstPerpendicularVector),
      endPoint.clone().sub(firstPerpendicularVector),
      endPoint.clone().add(secondPerpendicularVector),
      endPoint.clone().sub(secondPerpendicularVector),
    ];
    const vertices = verticesVectors.map(({ x, y, z }) => [x, y, z]).flat();
    const triangles = [
      0, 2, 1, 0, 1, 3, 5, 6, 4, 5, 4, 7, 1, 5, 3, 3, 5, 7, 3, 7, 0, 0, 7, 4, 0,
      4, 2, 2, 4, 6, 2, 6, 1, 1, 6, 5,
    ];
    return {
      triangles,
      vertices,
    };
  }

  private directionVector(startPoint: Vector3, endPoint: Vector3) {
    return startPoint.clone().sub(endPoint);
  }
  override onDestroy() {
    this.outputs.collider = null;
    this.outputs.objectRoot = null;
    this._lineMaterial.dispose();
    this._pointMaterial.dispose();
    this._vertices.forEach((vertex) => {
      this._deletedObject(vertex.id);
    });
    this._context = null;

    super.onDestroy && super.onDestroy();
  }
}
