import { createReducer, on } from '@ngrx/store';
import { UserApartmentPreferencesInterface } from '@simOn/user/preferences/model';
import {
  GetUserPreferences,
  GetUserPreferencesFailure,
  GetUserPreferencesSuccess,
  UpdateUserPreferences,
  UpdateUserPreferencesFailure,
  UpdateUserPreferencesSuccess
} from './user-preferences.actions';

export const initialState: UserApartmentPreferencesInterface = {} as UserApartmentPreferencesInterface;
export const USER_PREFERENCES_FEATURE_KEY = 'userPreferences';
export const reducer = createReducer(
  initialState,

  on(GetUserPreferences, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(GetUserPreferencesSuccess, (state, action) => {
    {
      return {
        ...state,
        ...action.userPreferences,
        isLoaded: true,
        isLoading: false,
        error: null
      };
    }
  }),

  on(GetUserPreferencesFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  }),

  on(UpdateUserPreferences, (state, action) => {
    return { ...state, isLoading: true };
  }),

  on(UpdateUserPreferencesSuccess, (state, action) => {
    return {
      ...state,
      ...action.userPreferences,
      isLoaded: true,
      isLoading: false,
      error: null
    };
  }),

  on(UpdateUserPreferencesFailure, (state, action) => {
    return { ...state, isLoading: false, isLoaded: false };
  })
);
