import { ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewEncapsulation, inject } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { BreakpointService } from '@simOn/ui/breakpoint';
import { Subject, takeUntil, tap } from 'rxjs';
@Component({
  standalone: true,
  selector: 'common-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentContainerComponent implements OnInit {
  private readonly breakpointService = inject(BreakpointService);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  @HostBinding() class = 'sim-container common-content-container';
  @HostBinding('class.fixed-footer') fixedFooterClass: boolean = false;

  ngOnInit(): void {
    this.breakpointService.deviceType$
      .pipe(
        tap((viewType) => (this.fixedFooterClass = viewType === 'tablet' || viewType === 'mobile')),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }
}
