import { createAction, props } from '@ngrx/store';
import { TLastVisitedUser, UpdateUserState } from '@simOn/user/last-visited/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';
import { TApartmentUser, TApartmentUsers } from '@simOn/utils/signalR';

export const initUsers = createAction('[Users] Init');

export const loadUsersSuccess = createAction('[Users/API] Load Users Success', props<{ users: TLastVisitedUser[] }>());

export const loadUsersFailure = createAction('[Users/API] Load Users Failure', props<{ error: any }>());

export const SetSelectedApartmentUsers = createAction(
  '[Users] Selected Apartment Users',
  props<{ selectedApartmentUsers: TApartmentUsers }>()
);
export const SetSelectedApartmentUsersSuccess = createAction(
  '[Users] Selected Apartment Users Success',
  props<{ selectedApartmentUsers: TLastVisitedUser[] }>()
);

export const SetSelectedApartmentUsersFailure = createAction(
  '[Users] Selected Apartment Users Failure',
  props<{ error: any }>()
);

export const UpdateApartmentUserState = createAction(
  '[Users] Update Apartment User State',
  props<{ userState: UpdateUserState }>()
);

export const AddApartmentUserState = createAction(
  '[Users] Add Apartment User',
  props<{ apartmentUser: TApartmentUser }>()
);
export const AddApartmentUserStateSuccess = createAction(
  '[Users] Add Apartment User Success',
  props<{ apartmentUser: TLastVisitedUser }>()
);

export const AddApartmentUserStateFailure = createAction('[Users] Add Apartment User Failure', props<{ error: any }>());

export const DeleteApartmentUserState = createAction(
  '[Users] Delete Apartment User',
  props<{ apartmentUser: TApartmentUser }>()
);
export const SetSelectedUserId = createAction(
  '[Application] Set Selected User',
  props<{ selectedId: string | 'publicLink' }>()
);
export const ClearUsersState = CLEAR_STATE_ACTION('Users');
