import { Unit } from './units';

export const UNIT_TRANSFORM: {
  [srcType in Unit]:
    | {
        [dstType in Unit]?: (value: number, currency?: number) => string | number;
      }
    | undefined;
} = {
  None: undefined,
  Unknown: undefined,
  kWh: {
    Joule: (value: number) => Math.round(value * 3600000)
  },
  Wh: {
    Joule: (value: number) => Math.round(value * 3600),
    kWh: (value: number) => value / 1000
  },
  mWh: {
    Joule: (value: number) => Math.round(value * 3600 * 1000),
    kWh: (value: number) => value * 1000
  },
  kVAh: {
    Joule: (value: number) => Math.round(value * 3600 * 1000),
    kWh: (value: number) => value
  },
  Joule: {
    kWh: (value: number) => (value * 0.0000002778).toFixed(2)
  },
  Watt: {
    Currency: (value: number, currency?: number) => {
      return ((value / 1000) * (currency || 0)).toFixed(2);
    }
  },
  Celsius: {
    Fahrenheit: (value: number) => (value * (9 / 5) + 32).toFixed(2),
    Kelvin: (value: number) => (value + 273.15).toFixed(2)
  },
  Fahrenheit: {
    Celsius: (value: number) => ((value - 32) * (5 / 9)).toFixed(2),
    Kelvin: (value: number) => ((value + 459.67) * (5 / 9)).toFixed(2)
  },
  Kelvin: {
    Celsius: (value: number) => (value + 273.15).toFixed(2),
    Fahrenheit: (value: number) => (value - 273.15).toFixed(2)
  },
  MilesPerHour: undefined,
  Knots: undefined,
  MetersPerSecond: undefined,
  FeetPerSecond: undefined,
  KilometersPerHour: undefined,
  Percentage: undefined,
  Lux: undefined,
  Lumen: undefined,
  mG: undefined,
  ppm: undefined,
  mgForm3: undefined,
  Miles: undefined,
  Kilometers: undefined,
  Meters: undefined,
  Volt: undefined,
  MMI: undefined,
  Currency: undefined,
  Ampere: undefined
};
