import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimSwitcherComponent } from './components/sim-switcher.component';

@NgModule({
  declarations: [SimSwitcherComponent],
  exports: [SimSwitcherComponent],
  imports: [CommonModule]
})
export class SimSwitcherModule {}
