import { Router } from '@angular/router';
import { PrivilegesEventsCb, SignalRService, TApartmentEvent, TApartmentUsers } from '@simOn/utils/signalR';
import { Subject, firstValueFrom, takeUntil } from 'rxjs';

export function privilegesEventListener(
  signalR: SignalRService,
  router: Router,
  payloadFn: Partial<PrivilegesEventsCb>
) {
  const destroy = new Subject<void>();

  signalR
    .listenOnEvent<TApartmentUsers>('UsersLostAccessToApartment')
    .pipe(takeUntil(destroy))
    .subscribe(({ apartmentId, userIds }) => {
      payloadFn.UsersLostAccessToApartment!({ apartmentId, userIds });
    });

  signalR
    .listenOnEvent<TApartmentEvent>('LostAccessToApartment')
    .pipe(takeUntil(destroy))
    .subscribe(({ apartmentId }) => {
      payloadFn.LostAccessToApartment!({ apartmentId });
      router.navigate(['/permission-conflict']).then(() => destroy.next());
    });

  signalR
    .listenOnEvent<TApartmentEvent>('UserApartmentAccessModified')
    .pipe(takeUntil(destroy))
    .subscribe(({ apartmentId }) => {
      payloadFn.UserApartmentAccessModified!({ apartmentId });
    });

  firstValueFrom(signalR.startConnection().pipe(takeUntil(destroy)));
}
