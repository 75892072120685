import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
  input
} from '@angular/core';

export const BATTERY_ICONS = {
  battery_100: {
    iconUrl: 'assets/images/device_interface_icons/Battery_100.svg'
  },
  battery_75: {
    iconUrl: 'assets/images/device_interface_icons/Battery_75.svg'
  },
  battery_50: {
    iconUrl: 'assets/images/device_interface_icons/Battery_50.svg'
  },
  battery_25: {
    iconUrl: 'assets/images/device_interface_icons/Battery_25.svg'
  },
  battery_10: {
    iconUrl: 'assets/images/device_interface_icons/Battery_10.svg'
  },
  battery_empty: {
    iconUrl: 'assets/images/device_interface_icons/Battery_empty.svg'
  },
  battery_empty_red: {
    iconUrl: 'assets/images/device_interface_icons/Battery_empty_red.svg'
  }
};
@Component({
  selector: 'sim-battery-indicator',
  templateUrl: './battery-indicator.component.html',
  styleUrls: ['./battery-indicator.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimBatteryIndicatorComponent implements OnChanges {
  private detect = inject(ChangeDetectorRef);
  //To Check
  readonly batteryLevel = input<number>(Infinity);

  redBorderIconUrl = BATTERY_ICONS.battery_empty_red.iconUrl;
  batteryStatus: BatteryStatusInterface | undefined;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['batteryLevel']) {
      this.batteryStatus = this.getBatteryIcon();
      this.detect.detectChanges();
    }
  }

  getBatteryIcon(): BatteryStatusInterface {
    if (this.batteryLevel() > 75) {
      return {
        redBorder: false,
        contentIconUrl: BATTERY_ICONS.battery_100.iconUrl
      };
    } else if (this.batteryLevel() <= 75 && this.batteryLevel() > 50) {
      return {
        redBorder: false,
        contentIconUrl: BATTERY_ICONS.battery_75.iconUrl
      };
    } else if (this.batteryLevel() <= 50 && this.batteryLevel() > 25) {
      return {
        redBorder: false,
        contentIconUrl: BATTERY_ICONS.battery_50.iconUrl
      };
    } else if (this.batteryLevel() <= 25 && this.batteryLevel() > 10) {
      return {
        redBorder: false,
        contentIconUrl: BATTERY_ICONS.battery_25.iconUrl
      };
    } else if (this.batteryLevel() <= 10 && this.batteryLevel() > 0) {
      return {
        redBorder: true,
        contentIconUrl: BATTERY_ICONS.battery_25.iconUrl
      };
    } else {
      return {
        redBorder: true,
        contentIconUrl: BATTERY_ICONS.battery_empty_red.iconUrl
      };
    }
  }
}

export interface BatteryStatusInterface {
  redBorder: boolean;
  contentIconUrl: string;
}
