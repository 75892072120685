import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { SimInitialsPipe } from './sim-initials.pipe';

@Component({
  selector: 'user-info',
  standalone: true,
  imports: [SimInitialsPipe, NgIf, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./sim-user-info.component.scss'],
  template: `
    <div class="user-info">
      <div class="user-info__initials">
        <div>{{ userName | initials }}</div>
      </div>
      <div class="user-info__content">
        <div class="user-info__content--name sim-text-ellipsis" data-cy="user-info-name">{{ userName }}</div>
        <div class="user-info__content--email sim-text-ellipsis" data-cy="user-info-email">{{ userEmail }}</div>
        <div class="user-info__content--info" *ngIf="userInfo">
          <ng-container *ngTemplateOutlet="userInfo ?? null"> </ng-container>
        </div>
      </div>
    </div>
  `
})
export class SimUserInfoComponent {
  @Input() userName!: string;
  @Input() userEmail!: string;
  @Input() userInfo: TemplateRef<any> | undefined;
}
