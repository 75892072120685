import { Euler, Quaternion as TQuaternion } from 'three';
import { Quaternion } from './quaternion';
import { Stem } from './stem';
import { Transform } from './transform';
import { Vector } from './vector';
export class TransformConverter {
  private _transform!: Transform;
  public get transform(): Transform {
    return this._transform;
  }
  public set transform(value: Transform) {
    this._transform = value;
  }
  constructor(offset: string | undefined) {
    try {
      this._transform = offset
        ? new Transform(JSON.parse(offset))
        : Transform.default;
    } catch (e) {
      //NOTE: defaults to Transform.default when offset is invalid.
      this._transform = Transform.default;
      console.error(e);
    }
  }

  /**
   * Translates 3d vector position to matterport vector position
   * @param basePosition 3d vector position
   * @returns matterport vector position
   */
  toMatterportPosition(basePosition: Vector): Vector {
    const {
      position: offsetPosition,
      rotation: offsetRotation,
      scale: offsetScale,
    } = this._transform;

    //objectPosition - offsetPosition;

    const pointMoved = new Vector(basePosition).subtract(offsetPosition);

    //Quaternion.Inverse(offsetRotation) * movedVector;
    const pointRotated = Quaternion.multiplyByVector(
      Quaternion.inverse(offsetRotation),
      pointMoved
    );

    //Vector3.Scale(rotatedVector, InverseScale(offsetScale));
    const pointScaled = pointRotated.dotProduct(Vector.inverse(offsetScale));

    //new Vector3(-scaledVector.x, scaledVector.y, scaledVector.z)
    return new Vector(-pointScaled.x, pointScaled.y, pointScaled.z);
  }

  /**
   * Translates matterport vector position to 3d vector position
   * @param basePosition matterport vector position
   * @param offset matterport vector position
   *
   * @returns 3d vector position
   */
  to3dPosition(basePosition: Vector, offset?: Transform): Vector {
    const {
      position: offsetPosition,
      rotation: offsetRotation,
      scale: offsetScale,
    } = offset ?? this._transform;

    //new Vector3(-mtpPosition.x, mtpPosition.y, mtpPosition.z)
    const mtpFixedPoint = new Vector(basePosition).dotProduct(
      new Vector(-1, 1, 1)
    );

    //Vector3.Scale(mtpFixedVector, offsetScale);
    const pointScaled = mtpFixedPoint.dotProduct(offsetScale);

    //offsetRotation * scaledVector;
    const pointRotated = Quaternion.multiplyByVector(
      offsetRotation,
      pointScaled
    );

    //rotatedVector + offsetPosition;
    const pointMoved = pointRotated.add(offsetPosition);

    return pointMoved;
  }

  /**
   * Translates matterport vector position to 3d vector position with stem correction
   * @param basePosition matterport vector position
   * @returns 3d vector position
   */
  to3dPositionWithStem(basePosition: Vector, stem: Stem): Vector {
    const positionWithStemCorrection = new Vector(stem.normal)
      .multiply(0.1)
      .add(basePosition);
    // .dotProduct(Vector.back);

    return this.to3dPosition(
      new Vector(
        positionWithStemCorrection.x,
        positionWithStemCorrection.z,
        -positionWithStemCorrection.y
      )
    );
  }

  /**
   * Translates 3d euler angles to matterport euler angles
   * @param baseRotation 3d euler angles (2 dimensional vector)
   * @returns matterport euler angles (2 dimensional vector)
   */
  toMatterportRotation(baseRotation: Vector): Vector {
    const { rotation: offsetRotation } = this._transform;

    //Quaternion.Inverse(rotationOffset) * Quaternion.Euler(objectRotation);
    const rotationSummed = Quaternion.multiply(
      Quaternion.inverse(offsetRotation),
      Quaternion.euler(baseRotation)
    );

    //sumedRotation.eulerAngles;
    const { x, y } = rotationSummed.eulerAngles;

    //new Vector2(-euler.x, -euler.y - 180f)
    return new Vector(-x, -y - 180);
  }

  /**
   * Translates matterport euler angles to 3d euler angles
   * @param baseRotation matterport euler angles (2 dimensional vector)
   * @returns 3d euler angles
   */
  to3dRotation(baseRotation: Vector): Vector {
    const { x: xr, y: yr } = baseRotation;
    const { rotation: offsetRotation } = this._transform;

    //Quaternion.Euler(new Vector3(-mtpRotation.x, -mtpRotation.y + 180f, 0));
    const rotationMatterport = Quaternion.euler(new Vector(-xr, -yr + 180));

    //rotationOffset * mtpQuaternion;
    const rotationSummed = Quaternion.multiply(
      offsetRotation,
      rotationMatterport
    );

    //new Vector3(sumedRotation.eulerAngles.x, sumedRotation.eulerAngles.y, 0f)
    const { x, y } = rotationSummed.eulerAngles;

    return new Vector(x, y);
  }

  /**
   * Translates matterport vector rotation to 3d vector rotation
   * @param baseRotation matterport vector rotation
   * @returns 3d vector rotation
   */
  to3dObjectRotation(baseRotation: Vector): Vector {
    const { rotation: offsetRotation, scale: offsetScale } = this._transform;
    const { x, y, z } = baseRotation;
    const baseRotationQuaternion = new TQuaternion().setFromEuler(
      new Euler(x, -y + Math.PI, z)
    );
    const offsetRotationQuaternion = new TQuaternion(
      offsetRotation.x,
      offsetRotation.y,
      offsetRotation.z,
      offsetRotation.w
    );

    const pointRotated = new TQuaternion().multiplyQuaternions(
      baseRotationQuaternion,
      offsetRotationQuaternion
    );

    const euler = new Euler().setFromQuaternion(pointRotated);
    return new Vector(euler.x, euler.y, euler.z);
  }
  /**
   * Translates 3d vector rotation to matterport vector rotation
   * @param baseRotation 3d vector rotation
   * @returns matterport vector rotation
   */
  toMatterportObjectRotation(baseRotation: Vector): Vector {
    const { rotation: offsetRotation } = this._transform;
    const { x, y, z } = baseRotation;
    const baseRotationQuaternion = new TQuaternion().setFromEuler(
      new Euler(x, y, z)
    );
    const offsetRotationQuaternion = new TQuaternion(
      offsetRotation.x,
      offsetRotation.y,
      offsetRotation.z,
      offsetRotation.w
    ).invert();

    const rotationSummed = new TQuaternion().multiplyQuaternions(
      offsetRotationQuaternion,
      baseRotationQuaternion
    );

    //sumedRotation.eulerAngles;

    const euler = new Euler().setFromQuaternion(rotationSummed);
    return new Vector(euler.x, -euler.y - Math.PI, euler.z);
  }

  /**
   * Translates 3d vector rotation to matterport vector rotation
   * @param baseRotation 3d vector rotation
   * @returns matterport vector rotation
   */
  toMatterportObjectRotationFromQuaternion(
    baseRotation: TQuaternion
  ): TQuaternion {
    const { rotation: offsetRotation } = this._transform;
    const { x, y, z, w } = baseRotation;
    const baseRotationQuaternion = new TQuaternion(x, y, z, w);
    const offsetRotationQuaternion = new TQuaternion(
      offsetRotation.x,
      offsetRotation.y,
      offsetRotation.z,
      offsetRotation.w
    ).invert();

    const rotationSummed = new TQuaternion().multiplyQuaternions(
      baseRotationQuaternion,
      offsetRotationQuaternion
    );
    return rotationSummed;
  }

  toMatterportScale(baseScale: Vector) {
    const { scale } = this._transform;

    const { x, y, z } = {
      x: baseScale.x * (1 / scale.x),
      y: baseScale.y * (1 / scale.y),
      z: baseScale.z * (1 / scale.z),
    };

    return new Vector(x, y, z);
  }
}
