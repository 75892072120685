<sim-modal (close)="close()" *ngIf="modalContent[data.modalType] as content">
  <sim-modal-header></sim-modal-header>
  <sim-modal-template class="modal-template">
    <div class="info-modal-container">
      <div class="left-side">
        <span class="left-side--title">{{ data.title }}</span>

        <div class="left-side__container">
          <sim-icon [icon]="content.icon" [color]="content.iconColor"></sim-icon>
          <div class="left-side__container__info">
            <span class="left-side__container__info--text">{{ data.text }}</span>
            <button
              id="info-modal-confirm"
              sim-flat-button
              class="left-side__container__info--button"
              simColor="primary"
              (click)="buttonClick()"
            >
              <span>{{ data.buttonText }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="right-side">
        <img [src]="content.image" alt="" />
      </div>
    </div>
  </sim-modal-template>
  <sim-modal-footer></sim-modal-footer>
</sim-modal>
