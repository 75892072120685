import { EventEmitter } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Units } from '@simlab/matterport/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { Vector3 } from 'three';
import { TransformControls as ThreeTransformControls } from 'three/examples/jsm/controls/TransformControls';

export type TMeasurementElements =
  | 'AREA_SURFACE_SIZE'
  | 'AREA_BACKGROUND'
  | 'AREA_POINT'
  | 'AREA_LINE';
export type TAreaMesh = {
  id: string;
  color?: string;
  vertices: Vertice[];
  triangles: MeshTriangle[];
  surfaceSize: number;
};
export type TAreaMeshInputs = TAreaMesh & {
  surfaceSizeUnit: Units | undefined;
};
export type MeshTriangle = {
  index: number;
  firstVertice: number;
  secondVertice: number;
  thirdVertice: number;
};
export type Vertice = {
  index: number;
  x: number;
  y: number;
  z: number;
};

export type IndexedVertice = Vertice & { id: string };
export type TUserData = Pick<IndexedVertice, 'id'>;
export type MeasurementMode = 'read' | 'update' | 'create' | undefined;
export type AreaMeshSource =
  | 'addPoint'
  | 'removePoint'
  | 'cancelAllChanges'
  | 'color'
  | 'undo'
  | 'redo'
  | 'areaCreated'
  | 'color'
  | 'pointPosition';
export class TAreaMeshChange {
  constructor(
    public changes: Required<TAreaMesh>,
    public source: AreaMeshSource
  ) {}
}
export interface IMeasurementComponent {
  id: string;
  mode$: BehaviorSubject<MeasurementMode>;

  mode: MeasurementMode;
  selected: boolean;
  selectionChange$: Observable<string | undefined>;
  areaChange$: EventEmitter<TAreaMeshChange>;
  color: string;
  needUpdate: boolean;
  recalculateTriangles: () => void;
  onComponentClick: (
    transformControlsRef: ThreeTransformControls,
    intersect: THREE.Intersection<THREE.Object3D<THREE.Event>>
  ) => boolean;
  get canUndo(): boolean;
  get canRedo(): boolean;
  set collider(colision: boolean);
  cancelAllChanges: () => void;
  undo: () => void;
  redo: () => void;
  hide: () => void;
  show: () => void;
  finish: () => void;
  createTemporaryPoint: (action: TAreaListenersAction) => THREE.Sprite;
  removeTemporaryPoint: () => void;
  surfaceSizeUnit: TAreaMeshInputs['surfaceSizeUnit'];
  addClosestPoint: (
    lineId: string,
    point: THREE.Vector3,
    normal: THREE.Vector3
  ) => string | undefined;
  removePoint: (objectId: string) => void;
  set disableLayers(layers: { points: boolean; lines: boolean; mesh: boolean });
  getClosestPoint: (
    lineId: string,
    position: Vector3
  ) => { x: number; y: number; z: number };
  addPoint: (
    point: THREE.Vector3,
    normal: THREE.Vector3,
    nextIndex?: number
  ) => THREE.Sprite | undefined;

  set segmentsVisibility(visible: boolean);
}
export type TAreaMeshUpdate = Pick<TAreaMesh, 'id' | 'color'>;

export const AREA_MESH_LISTENERS_ACTION = [
  'ADD_POINT',
  'REMOVE_POINT',
  'CREATION_POINT',
] as const;

export type TAreaListenersAction = typeof AREA_MESH_LISTENERS_ACTION[number];
export const TEMPORARY_POINT_ICONS: Record<
  TAreaListenersAction,
  { iconUrl: string; background?: string }
> = {
  ADD_POINT: {
    iconUrl: 'assets/icons/k_add.svg',
    background: '#4374E4',
  },
  REMOVE_POINT: {
    iconUrl: 'assets/icons/k_remove.svg',
    background: '#C42934',
  },
  CREATION_POINT: {
    iconUrl: 'assets/icons/k_add.svg',
    background: '#4374E4',
  },
};
export type IAreaTool = {
  readonly selectedArea$: Observable<string | undefined>;
  readonly selectedAreaMode$: Observable<MeasurementMode>;
  readonly selectedAreaChange$: Observable<TAreaMeshChange | undefined>;
  addArea: (area: TAreaMesh) => string;
  createArea: () => string;
  cancelAreaCreation: () => void;
  deleteArea: (areaId: string) => void;
  deleteSelectedArea: () => void;
  editSelectedArea: () => void;
  isTouchDevice: () => boolean;
  updateAreaColor: (id: TAreaMesh['id'], color: TAreaMesh['color']) => void;
  updateSelectedAreaColor: (color: TAreaMesh['color']) => void;
  /**
   * @description add listener on area mesh click
   * @param listenerActionType - set listener action type
   */
  addListener: (
    listenerActionType: TAreaListenersAction
  ) => Observable<string | undefined>;
  /**
   * @description remove all listeners
   */
  removeListener: () => void;
  hideAreas: (omit?: string[]) => void;
  showAreas: (omit?: string[]) => void;

  deleteAllAreas: () => void;
  set surfaceSizeUnit(unit: Units);
  set selectedAreaMode(mode: MeasurementMode);
  get selectedAreaMode(): MeasurementMode;
  set selectedArea(areaId: string | undefined);
  get selectedArea(): string | undefined;
} & Pick<
  IMeasurementComponent,
  'undo' | 'redo' | 'finish' | 'canRedo' | 'canUndo' | 'segmentsVisibility'
>;
