import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SidenavService {
  private readonly _opened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get opened(): boolean {
    return this._opened$.getValue();
  }

  readonly opened$: Observable<boolean> = this._opened$.asObservable();

  public toggle() {
    this._opened$.next(!this._opened$.getValue());
  }

  public close() {
    this._opened$.next(false);
  }

  public open() {
    this._opened$.next(true);
  }
}
