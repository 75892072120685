<div class="missing-room-privileges">
  <span i18n="@@MODALS_MISSING_ROOM_PRIVILEGES_TITLE" class="missing-room-privileges__title">Access denied</span>
  <div class="missing-room-privileges__content">
    <span i18n="@@MODALS_MISSING_ROOM_PRIVILEGES_CONTENT" class="missing-room-privileges__content--text">
      Your permissions do not allow you to enter any room in this space.
    </span>
    <span i18n="@@MODALS_MISSING_ROOM_PRIVILEGES_CONTENT_01"
      >If you think this is a mistake, please contact the owner of this space.</span
    >
  </div>
  <div class="missing-room-privileges__footer">
    <button sim-flat-button simColor="primary" (click)="exit()">
      <ng-container i18n="@@GENERAL_OK">Ok</ng-container>
    </button>
  </div>
</div>
