import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Endpoints } from '../models/configuration';
import { RecurringPaymentResponse } from '../models/recurring-payment-response';
import { ENDPOINTS } from '../tokens/endpoints.token';

@Injectable()
export class PaymentApiService {
  constructor(
    private readonly http: HttpClient,
    @Inject(ENDPOINTS) private readonly _endpoints: Endpoints
  ) {}
  createRecurringPayUPaymentMethod(
    payload: Record<string, string | number> & { cardToken: string }
  ): Observable<RecurringPaymentResponse> {
    if (this._endpoints.saveCard) {
      return this.http.post<RecurringPaymentResponse>(this._endpoints.saveCard, payload);
    } else {
      throw new Error(
        'Endpoints is not sets. Please set configuration of all endpoints in NgModule definition'
      );
    }
  }
}
