import { createAction, props } from '@ngrx/store';
import { TransformationInterface } from '@simOn/common/matterport';
import { UpdateLocalWidgetItem, Widget, WidgetTransformation } from '@simOn/device/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const loadWidgets = createAction('[Widget] Load Widgets');

export const loadWidgetsSuccess = createAction('[Widget] Load Widgets Success', props<{ data: Widget[] }>());

export const loadWidgetsFailure = createAction('[Widget] Load Widgets Failure', props<{ error: any }>());

export const updateTransformation = createAction(
  '[Widget] Update Widget Transformation',
  props<{ data: { widgetId: string; widgetTransformationId: string; transformation: TransformationInterface } }>()
);

export const updateTransformationSuccess = createAction(
  '[Widget] Update Widget Transformation Success',
  props<{ data: { widgetId: string; widgetTransformationId: string; transformation: TransformationInterface } }>()
);

export const updateTransformationFailure = createAction(
  '[Widget] Update Widget Transformation Failure',
  props<{ error: any }>()
);

export const addTransformation = createAction(
  '[Widget] Add Widget Transformation',
  props<{ data: { widgetId: string; transformation: TransformationInterface } }>()
);

export const addTransformationSuccess = createAction(
  '[Widget] Add Widget Transformation Success',
  props<{ data: { widgetId: string; transformation: WidgetTransformation } }>()
);

export const addTransformationFailure = createAction(
  '[Widget] Add Widget Transformation Failure',
  props<{ error: any }>()
);

export const deleteTransformation = createAction(
  '[Widget] Delete Widget Transformation',
  props<{ data: { widgetId: string; transformationId: string; temporary?: boolean } }>()
);

export const deleteTransformationSuccess = createAction(
  '[Widget] Delete Widget Transformation Success',
  props<{ data: { widgetId: string; transformationId: string; temporary?: boolean } }>()
);

export const deleteTransformationFailure = createAction(
  '[Widget] Delete Widget Transformation Failure',
  props<{ error: any }>()
);

export const UpdateWidget = createAction(
  '[Widget/Local] Update Widgets Success',
  props<{ widgets: UpdateLocalWidgetItem[] }>()
);

export const DeleteWidgets = createAction('[Widget/Local] Delete Widgets', props<{ simlabDeviceId: string }>());

export const ClearWidgetState = CLEAR_STATE_ACTION('Widget');
