import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ButtonBase, ButtonComponent } from '../button/button.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `a[sim-button], a[sim-raised-button], a[sim-stroked-button], a[sim-flat-button], a[sim-icon-button], a[sim-fab-button], a[sim-mini-fab-button]`,
  exportAs: 'simButton, simAnchor',
  templateUrl: '../button/button.component.html',
  styleUrls: ['../button/button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ButtonBase, useExisting: AnchorComponent }]
})
export class AnchorComponent extends ButtonComponent {
  @Input() tabIndex!: number;

  @HostBinding('attr.tabindex')
  get attrTabindex() {
    return this.disabled ? -1 : this.tabIndex || 0;
  }
}
