import { UAnimatedIcon } from '@simOn/ui/sim-animated-icons';
import { Color } from 'three';
import { Properties, SimlabPropertyInterface } from './worek';

/**
 * @description
 *
 * 0 - disable/close/off/false/0%/DEFAULT
 *
 * 1 - enable/true/25%;
 *
 * 2 - half open/50%;
 *
 * 3 - three quarter/75%;
 *
 * 4 - open/100%;
 */
export type TAnimatedIconStatus = 0 | 1 | 2 | 3 | 4;

export type DeviceTypeIcon = {
  [Type in Properties]: PropertyIconDefinition<Type>;
};
export const booleanToIconStatus = (value: boolean): TAnimatedIconStatus => Number(value) as TAnimatedIconStatus;

export const stringToIconStatus = (value: string): TAnimatedIconStatus => 0;
export const numberToIconStatus = (
  value: number,
  min: number,
  max: number,
  numberOfStatuses: number
): TAnimatedIconStatus => {
  const range = max - min;
  if (range === 0) return 0;
  const rangeValue = value - min;
  return Math.floor((rangeValue / range) * numberOfStatuses) as TAnimatedIconStatus;
};

const valueToIconVariant = (icon: UAnimatedIcon, status: TAnimatedIconStatus) => {
  const iconSuffix = status === 0 ? '' : status < 10 ? `_0${status}` : `_${status}`;
  return `${icon}${iconSuffix}` as UAnimatedIcon;
};
export type PropertyIconDefinition<T extends Properties> = {
  type: T;
  default: Readonly<UAnimatedIcon>;
  alternativeIcons: Readonly<UAnimatedIcon[]>;
  icons: () => UAnimatedIcon[];
  valueToIcon: (value: SimlabPropertyInterface<T>, icon: UAnimatedIcon) => UAnimatedIcon;
  valueToBGColor: (value: SimlabPropertyInterface<T>) => Color;
};

export const THREE_WALK_ICON_BACKGROUND_TYPE = {
  normal: new Color('#25292C'),
  red: new Color('#BE3745'),
  green: new Color('#18AA5C')
} as const;
export const DEVICE_TYPE_ICON: DeviceTypeIcon = {
  WindSpeed: {
    type: 'WindSpeed',
    default: 'device_windspeed',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'WindSpeed'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'WindSpeed'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  RainDetector: {
    type: 'RainDetector',
    default: 'device_raindetector',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'RainDetector'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(icon, booleanToIconStatus(value.values?.value || false));
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'RainDetector'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  AlarmTriggered: {
    type: 'AlarmTriggered',
    default: 'device_alarm',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'AlarmTriggered'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'AlarmTriggered'>): Color {
      return value?.values?.value ? THREE_WALK_ICON_BACKGROUND_TYPE['red'] : THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  SmokeDetected: {
    type: 'SmokeDetected',
    default: 'device_smokedetector',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'SmokeDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'SmokeDetected'>): Color {
      return value?.values?.value ? THREE_WALK_ICON_BACKGROUND_TYPE['red'] : THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  HeatDetected: {
    type: 'HeatDetected',
    default: 'device_firedetector',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'HeatDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'HeatDetected'>): Color {
      return value?.values?.value ? THREE_WALK_ICON_BACKGROUND_TYPE['red'] : THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  CoDetected: {
    type: 'CoDetected',
    default: 'device_COdetector',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'CoDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'CoDetected'>): Color {
      return value?.values?.value ? THREE_WALK_ICON_BACKGROUND_TYPE['red'] : THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  FloodDetected: {
    type: 'FloodDetected',
    default: 'device_flooddetector',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'FloodDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'FloodDetected'>): Color {
      return value?.values?.value ? THREE_WALK_ICON_BACKGROUND_TYPE['red'] : THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  ShakeDetected: {
    type: 'ShakeDetected',
    default: 'device_shakedetector',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'ShakeDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'ShakeDetected'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  GasDetected: {
    type: 'GasDetected',
    default: 'device_gasdetector',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'GasDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'GasDetected'>): Color {
      return value?.values?.value ? THREE_WALK_ICON_BACKGROUND_TYPE['red'] : THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  MotionDetected: {
    type: 'MotionDetected',
    default: 'device_motion',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'MotionDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(icon, booleanToIconStatus(value.values?.value || false));
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'MotionDetected'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  LowBatteryDetected: {
    type: 'LowBatteryDetected',
    default: 'device_lowbattery',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'LowBatteryDetected'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'LowBatteryDetected'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Opened: {
    type: 'Opened',
    default: 'device_door',
    alternativeIcons: ['device_garagedoor', 'device_window'],
    valueToIcon: function (value: SimlabPropertyInterface<'Opened'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(icon, booleanToIconStatus(value.values?.value || false));
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Opened'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  ProviderError: {
    type: 'ProviderError',
    default: 'error_disconnected',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'ProviderError'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'ProviderError'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  SubscriptionLimit: {
    type: 'SubscriptionLimit',
    default: 'item_locked',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'SubscriptionLimit'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'SubscriptionLimit'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Unknown: {
    type: 'Unknown',
    default: 'device_unknown',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Unknown'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Unknown'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Weather: {
    type: 'Weather',
    default: 'device_unknown',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Weather'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      throw new Error('Function not implemented.');
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Weather'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Temperature: {
    type: 'Temperature',
    default: 'device_thermometer',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Temperature'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Temperature'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  LightIntensity: {
    type: 'LightIntensity',
    default: 'device_lux',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'LightIntensity'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'LightIntensity'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  EarthTremor: {
    type: 'EarthTremor',
    default: 'device_earthtremor',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'EarthTremor'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'EarthTremor'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Acceleration3D: {
    type: 'Acceleration3D',
    default: 'device_acceleration',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Acceleration3D'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Acceleration3D'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  TurnedOn: {
    type: 'TurnedOn',
    default: 'device_socket',
    alternativeIcons: [
      'device_TV',
      'device_powerstrip',
      'device_bulb',
      'device_AC',
      'device_coffee_machine',
      'device_dishwasher',
      'device_dryer_machine',
      'device_fridgeA',
      'device_fridgeB',
      'device_gamepad',
      'device_hood',
      'device_kettle',
      'device_oven',
      'device_printer',
      'device_roomba',
      'device_valve',
      'device_washing_machine',
      'device_projector'
    ],
    valueToIcon: function (value: SimlabPropertyInterface<'TurnedOn'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(icon, booleanToIconStatus(value.values?.value || false));
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'TurnedOn'>): Color {
      return value?.values?.value
        ? THREE_WALK_ICON_BACKGROUND_TYPE['green']
        : THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  SetTemperature: {
    type: 'SetTemperature',
    default: 'device_thermometer',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'SetTemperature'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'SetTemperature'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Clicked: {
    type: 'Clicked',
    default: 'device_button',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Clicked'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Clicked'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  BatteryLevel: {
    type: 'BatteryLevel',
    default: 'device_unknown',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'BatteryLevel'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      throw new Error('Function not implemented.');
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'BatteryLevel'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  PowerConsumption: {
    type: 'PowerConsumption',
    default: 'device_powerlevel',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'PowerConsumption'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'PowerConsumption'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  EnergyConsumption: {
    type: 'EnergyConsumption',
    default: 'device_energyconsumption',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'EnergyConsumption'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'EnergyConsumption'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Level: {
    type: 'Level',
    default: 'device_defaultlevel',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Level'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(
        icon,
        numberToIconStatus(value.values.value || 0, value.settings!.value.min, value.settings!.value.max, 4)
      );
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Level'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  RgbwColor: {
    type: 'RgbwColor',
    default: 'device_RGBW',
    alternativeIcons: ['device_RGBW_B', 'device_RGBW_C'],
    valueToIcon: function (value: SimlabPropertyInterface<'RgbwColor'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'RgbwColor'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  ExternalSensorConnected: {
    type: 'ExternalSensorConnected',
    default: 'device_externalsensor',
    alternativeIcons: [],
    valueToIcon: function (
      value: SimlabPropertyInterface<'ExternalSensorConnected'>,
      icon: UAnimatedIcon
    ): UAnimatedIcon {
      return valueToIconVariant(icon, booleanToIconStatus(value.values?.value || false));
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'ExternalSensorConnected'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  NextScheduledTemperatureLevel: {
    type: 'NextScheduledTemperatureLevel',
    default: 'device_thermostatdefault',
    alternativeIcons: [],
    valueToIcon: function (
      value: SimlabPropertyInterface<'NextScheduledTemperatureLevel'>,
      icon: UAnimatedIcon
    ): UAnimatedIcon {
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'NextScheduledTemperatureLevel'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  NextScheduledTemperatureTime: {
    type: 'NextScheduledTemperatureTime',
    default: 'device_thermostatdefault',
    alternativeIcons: [],
    valueToIcon: function (
      value: SimlabPropertyInterface<'NextScheduledTemperatureTime'>,
      icon: UAnimatedIcon
    ): UAnimatedIcon {
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'NextScheduledTemperatureTime'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  SetThermostatMode: {
    type: 'SetThermostatMode',
    default: 'device_thermostatdefault',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'SetThermostatMode'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'SetThermostatMode'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  ScheduleOverridden: {
    type: 'ScheduleOverridden',
    default: 'device_thermostatdefault',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'ScheduleOverridden'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'ScheduleOverridden'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  StaticLevel: {
    type: 'StaticLevel',
    default: 'device_defaultlevel',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'StaticLevel'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'StaticLevel'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Humidity: {
    type: 'Humidity',
    default: 'device_humidity',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Humidity'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Humidity'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Co2Level: {
    type: 'Co2Level',
    default: 'device_CO2level',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Co2Level'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Co2Level'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  HeatingMode: {
    type: 'HeatingMode',
    default: 'device_HVAC',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'HeatingMode'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'HeatingMode'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  TemperatureShift: {
    type: 'TemperatureShift',
    default: 'device_thermometer',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'TemperatureShift'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'TemperatureShift'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  HvacOperationMode: {
    type: 'HvacOperationMode',
    default: 'device_HVAC',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'HvacOperationMode'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'HvacOperationMode'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Scene: {
    type: 'Scene',
    default: 'device_sceneplay',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Scene'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Scene'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  AudioVolume: {
    type: 'AudioVolume',
    default: 'device_audiovolume',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'AudioVolume'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      throw new Error('Function not implemented.');
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'AudioVolume'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  TvChannel: {
    type: 'TvChannel',
    default: 'device_TVcontrol',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'TvChannel'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'TvChannel'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  TvChannelName: {
    type: 'TvChannelName',
    default: 'device_TVcontrol',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'TvChannelName'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'TvChannelName'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Boolean: {
    type: 'Boolean',
    default: 'device_netx_boolean',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Boolean'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(icon || 'device_netx_boolean', booleanToIconStatus(value.values.value!));
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Boolean'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Integer: {
    type: 'Integer',
    default: 'device_netx_meter',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Integer'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Integer'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  String: {
    type: 'String',
    default: 'device_netx_text',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'String'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'String'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  UInteger: {
    type: 'UInteger',
    default: 'device_netx_meter',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'UInteger'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'UInteger'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  Number: {
    type: 'Number',
    default: 'device_netx_meter',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'Number'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'Number'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  DateTime: {
    type: 'DateTime',
    default: 'device_netx_datetime',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'DateTime'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return icon;
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'DateTime'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  LevelRollerShutter: {
    type: 'LevelRollerShutter',
    default: 'device_rollershutter_V',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'LevelRollerShutter'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(
        icon,
        numberToIconStatus(value.values.value || 0, value.settings!.value.min, value.settings!.value.max, 4)
      );
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'LevelRollerShutter'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  },
  LevelDimmer: {
    type: 'LevelDimmer',
    default: 'device_dimmer',
    alternativeIcons: [],
    valueToIcon: function (value: SimlabPropertyInterface<'LevelDimmer'>, icon: UAnimatedIcon): UAnimatedIcon {
      if (!icon) icon = this.default;
      return valueToIconVariant(
        icon,
        numberToIconStatus(value.values.value || 0, value.settings?.value.min ?? 0, value.settings?.value.max ?? 0, 4)
      );
    },
    icons: function () {
      return [this.default, ...this.alternativeIcons];
    },
    valueToBGColor: function (value: SimlabPropertyInterface<'LevelDimmer'>): Color {
      return THREE_WALK_ICON_BACKGROUND_TYPE['normal'];
    }
  }
};
