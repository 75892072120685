import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { PrivilegesFacade } from '@simOn/privileges';
import { catchError, map, of } from 'rxjs';

export const connectionsAccessGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const privilegesFacade = inject(PrivilegesFacade);
  const router = inject(Router);

  return privilegesFacade.hasGeneralAccessTo$('CanViewProviders').pipe(
    map((canActivate: boolean) => {
      if (!canActivate) {
        router.navigate([`/space/${route.params.apartmentId}/apartment`]);
      }
      return canActivate;
    }),
    catchError((error) => {
      console.error(error);
      router.navigate([`/space/${route.params.apartmentId}/apartment`]);
      return of(false);
    })
  );
};
