import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType, ExecuteSceneValueParameter, paramJSON } from '@simOn/device/models';
import { DatepickerComponent } from '@simOn/ui/sim-datepicker';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule, DatepickerComponent],
  template: `<sim-datepicker
    i18n-placeholder="@@DEVICE_PROPERTIES_SET_DATE"
    id="set-date-datepicker"
    [readonly]="true"
    placeholder="Set Date..."
    [(value)]="propertyValue"
  ></sim-datepicker> `,
  styleUrls: ['./set-date.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-set-date'
  }
})
export class SetDateComponent extends PropertyBase<'DateTime', string, ExecuteSceneValueParameter> {
  valueToCommand(value: string): { command: keyof CommandValuesType; parameters: string | number } {
    return {
      command: 'SetDateTime',
      parameters: JSON.stringify(this.valueToParameter(value))
    };
  }
  commandToValue(params: {
    command: keyof CommandValuesType;
    parameters: string | number | ExecuteSceneValueParameter;
  }): string {
    const paramToJson: ExecuteSceneValueParameter = paramJSON(params.parameters);
    return paramToJson.value.toString();
  }
  set propertyValue(value: string) {
    if (!this.propertyDefinition) {
      console.error('Set property definition input');
      return;
    }
    this.propertyDefinition = {
      ...this.propertyDefinition,
      values: {
        ...this.propertyDefinition.values,
        value
      }
    };
    this.partialCommand$.emit(this.valueToCommand(value));
  }

  get propertyValue(): string {
    return this.propertyDefinition?.values?.value!;
  }

  valueToParameter(value: string): ExecuteSceneValueParameter {
    return { value };
  }
}
