import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TSceneState, TSimlabScenesInterfaceDTO } from '@simOn/scene/element/models';
import {
  AddShortcutScene,
  AddShortcutSceneFailure,
  AddShortcutSceneSuccess,
  ChangeSimlabSceneStatus,
  ChangeSimlabSceneStatusFailure,
  ChangeSimlabSceneStatusSuccess,
  ClearSceneState,
  DeleteScene,
  DeleteSceneFailure,
  DeleteSceneSuccess,
  DeleteShortcutScene,
  DeleteShortcutSceneFailure,
  DeleteShortcutSceneSuccess,
  GetScenes,
  GetScenesFailure,
  GetScenesSuccess,
  GetSimlabScenes,
  GetSimlabScenesFailure,
  GetSimlabScenesSuccess,
  UpdateShortcutScene,
  UpdateShortcutSceneFailure,
  UpdateShortcutSceneSuccess,
  UpsertScene,
  UpsertSceneFailure,
  UpsertSceneSuccess
} from './scene.actions';
import { CLEAR_STATE_REDUCER } from '@simOn/utils';

export const ScenesFeatureKey = 'scenes';

export interface State extends EntityState<TSceneState> {}

export const adapter: EntityAdapter<TSceneState> = createEntityAdapter<TSceneState>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(GetScenes, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(GetScenesSuccess, (state, action) => {
    return adapter.addMany(action.scenes, { ...state, isLoaded: true, isLoading: false });
  }),

  on(GetScenesFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(GetSimlabScenes, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(GetSimlabScenesSuccess, (state, action) => {
    return adapter.upsertMany(action.scenes, { ...state, isLoaded: true, isLoading: false });
  }),

  on(GetSimlabScenesFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(DeleteScene, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(DeleteSceneSuccess, (state, action) => {
    return adapter.removeOne(action.sceneId, { ...state, isLoaded: true, isLoading: false });
  }),

  on(DeleteSceneFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(AddShortcutScene, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(AddShortcutSceneSuccess, (state, action) => {
    const _scene: TSimlabScenesInterfaceDTO = {
      ...state.entities[action.shortcut.sceneId]
    } as TSimlabScenesInterfaceDTO;
    const _sceneShortcuts = [...(_scene.shortcuts || []), action.shortcut];
    const scene: TSimlabScenesInterfaceDTO = {
      ..._scene,
      shortcuts: [..._sceneShortcuts]
    };
    return adapter.updateOne({ id: action.shortcut.sceneId, changes: scene }, state);
  }),

  on(AddShortcutSceneFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(UpdateShortcutScene, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(UpdateShortcutSceneSuccess, (state, action) => {
    const _scene = { ...state.entities[action.shortcut.sceneId] } as TSimlabScenesInterfaceDTO;
    const _sceneShortcuts = [
      {
        ...(_scene.shortcuts && _scene.shortcuts[0]),
        ...action.shortcut
      }
    ];
    const scene: TSimlabScenesInterfaceDTO = {
      ..._scene,
      shortcuts: [..._sceneShortcuts]
    };
    return adapter.updateOne(
      { id: action.shortcut.sceneId, changes: scene },
      { ...state, isLoaded: true, isLoading: false }
    );
  }),

  on(UpdateShortcutSceneFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(DeleteShortcutScene, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(DeleteShortcutSceneSuccess, (state, action) => {
    const _scene: TSimlabScenesInterfaceDTO = { ...state.entities[action.sceneId] } as TSimlabScenesInterfaceDTO;
    const scene: TSimlabScenesInterfaceDTO = {
      ..._scene,
      shortcuts: []
    };
    return adapter.updateOne({ id: action.sceneId, changes: scene }, { ...state, isLoaded: true, isLoading: false });
  }),

  on(DeleteShortcutSceneFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(UpsertScene, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(UpsertSceneSuccess, (state, action) => {
    const scene: TSceneState = {
      ...action.scene,
      id: action.scene.id || '',
      smartApiProvider: 'SIMLAB',
      isBlockedBySubscriptionLimit: false
    };
    return adapter.upsertOne(scene, { ...state, isLoaded: false, isLoading: false });
  }),
  on(UpsertSceneFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(ChangeSimlabSceneStatus, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(ChangeSimlabSceneStatusSuccess, (state, action) => {
    const _scene = { ...state.entities[action.sceneId] };
    return adapter.updateOne(
      { id: action.sceneId, changes: { ..._scene, isActive: action.isActive } },
      { ...state, isLoaded: true, isLoading: false }
    );
  }),

  on(ChangeSimlabSceneStatusFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  CLEAR_STATE_REDUCER(ClearSceneState, initialState)
);
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
