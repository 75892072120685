export const BUTTON_HOST_ATTRIBUTES = [
  'sim-button',
  'sim-raised-button',
  'sim-stroked-button',
  'sim-flat-button',
  'sim-icon-button',
  'sim-fab-button',
  'sim-mini-fab-button'
];

export type BadgeSize = 'small' | 'medium' | 'large';
