import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PrivilegesApiService } from '@simOn/privileges/element/data-access';
import { UserFullAccess, privilegesTransformationToFlat } from '@simOn/privileges/element/models';
import { catchError, map, of, switchMap } from 'rxjs';
import { loadPrivileges, loadPrivilegesFailure, loadPrivilegesSuccess } from './privileges.actions';

@Injectable()
export class PrivilegesEffects {
  private actions$ = inject(Actions);
  private readonly accessService = inject(PrivilegesApiService);
  loadPrivileges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPrivileges),
      switchMap(() =>
        this.accessService.getUserFullAccesses().pipe(
          map((access: UserFullAccess) => {
            return loadPrivilegesSuccess({ data: privilegesTransformationToFlat(access) });
          }),
          catchError((e) => of(loadPrivilegesFailure(e)))
        )
      )
    )
  );
}
