<ng-container *ngIf="user$ | async; let user">
  <div
    id="user-panel-trigger"
    class="logged-user-panel"
    [ngClass]="{ 'user-panel-icon-content-visible': userPanelVisible }"
    (click)="openPanel()"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
  >
    <div class="logged-user-panel-icon">
      <span class="logged-user-panel-email" [ngClass]="{ 'light-text': lightMode }">{{ user.preferred_username }}</span>
      <button *ngIf="lightMode" sim-icon-button class="sim-icon-button-basic" id="user-panel-button">
        <sim-icon icon="account_light" sizeType="medium"></sim-icon>
      </button>
      <button *ngIf="!lightMode" sim-icon-button class="sim-icon-button-basic" id="user-panel-button-dark">
        <sim-icon icon="account_dark" sizeType="medium" color="#676767"></sim-icon>
      </button>
    </div>
    <ng-template
      cdkConnectedOverlay
      cdkOverlayOuts
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="userPanelVisible"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'transparent-backdrop'"
      (backdropClick)="userPanelVisible = !userPanelVisible"
    >
      <div class="logged-user-panel-content" [ngClass]="{ 'logged-user-panel-content-visible': userPanelVisible }">
        <div class="panel-text-place">
          <span class="sim-text-ellipsis panel-text" i18n="@@MAIN_SCREEN_USER_PANEL_TITLE">SIM-ON account</span>
          <span class="sim-text-ellipsis panel-bold-text">{{ user.name }}</span>
          <span class="sim-text-ellipsis panel-text">{{ user.email }}</span>
        </div>
        <div>
          <a
            id="user-panel-subscription"
            sim-button
            simColor="primary"
            class="panel-button"
            routerLink="/subscriptions"
            queryParamsHandling="preserve"
            (click)="hideAllPanels()"
            ><sim-icon icon="dollar"></sim-icon
            ><ng-container i18n="@@MAIN_SCREEN_USER_PANEL_SUBSCRIPTION_BUTTON">My subscription plan</ng-container></a
          >
          <button
            id="user-panel-preferences"
            sim-button
            simColor="primary"
            class="panel-button"
            (click)="openUserPreferences()"
          >
            <sim-icon icon="settings"></sim-icon>
            <ng-container i18n="@@MAIN_SCREEN_USER_PANEL_PREFERENCES_BUTTON">Personal preferences</ng-container>
          </button>
          <a
            [href]="simlabCloudRedirect"
            target="_blank"
            id="user-panel-cloud"
            sim-button
            simColor="secondary"
            class="panel-button"
          >
            <sim-icon icon="cloud"></sim-icon>
            <ng-container i18n="@@MAIN_SCREEN_USER_PANEL_CLOUD_BUTTON">SIMLAB cloud</ng-container>
          </a>
        </div>

        <hr class="panel-hr" />

        <a
          href="https://sim-on.com/contact-us-form/"
          target="_blank"
          id="user-panel-contact"
          sim-button
          simColor="secondary"
          class="panel-button"
        >
          <sim-icon icon="mail"></sim-icon>
          <ng-container i18n="@@MAIN_SCREEN_USER_PANEL_CONTACT_BUTTON">Contact us</ng-container>
        </a>

        <button i18n="@@GENERAL_LOG_OUT" id="user-panel-logout" sim-flat-button simColor="accent" (click)="logOut()">
          Log Out
        </button>
      </div>
    </ng-template>
  </div>
</ng-container>
