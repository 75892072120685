export interface AuthorizeResponse {
  redirectUrl: string;
}

export interface UserAccessTokenResponse {
  accessToken: string;
}

export interface BlockedScanText {
  title: string;
  subTitle: string;
}

export enum OauthState {
  INITIAL = 'state.initial',
  LOADING = 'state.loading',
  LOADED = 'state.loaded',
  EXTERNAL = 'state.external',
  ERROR = 'state.error',
  ERROR_PUBLIC = 'state.error_public',
  ERROR_PROTECTED = 'state.error_protected',
  ERROR_PRIVATE = 'state.error_private',
  ERROR_CLIENT = 'state.error_client',
  UNDEFINED_COMPONENT = 'state.error_undefined_component'
}
