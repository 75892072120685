import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PublicLinkService } from '@simOn/public-link';
import { SpaceFacade } from '@simOn/space';
import { AuthService } from '@simOn/user/auth/service';
import { UserProfileInterface } from '@simOn/user/preferences/model';
import { Localstorage } from '@simOn/utils/localstorage';
import { AuthenticatedResult, OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { Observable, catchError, iif, map, of, switchMap, take } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
  private JwtHelper = new JwtHelperService();
  private readonly oidcSecurityService: OidcSecurityService = inject(OidcSecurityService);
  private router: Router = inject(Router);
  private publicLinkService: PublicLinkService = inject(PublicLinkService);
  private spaceFacade: SpaceFacade = inject(SpaceFacade);
  private readonly authService: AuthService = inject(AuthService);
  isAuthorized = false;
  backToNavigationUrl = new Localstorage('BackToNavigationUrl').load();

  canActivate(
    activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean> | boolean {
    const publicLinkGuid = this.publicLinkService.getGuid();
    const apartmentId = activatedRoute.params['id'] || activatedRoute.params['apartmentId'];
    if (publicLinkGuid) {
      if (!apartmentId || !publicLinkGuid) {
        this.publicLinkService.removeGuid();
        return of(false);
      }
      this.publicLinkService.setSelectedApartmentId(apartmentId);

      return this.publicLinkService.isGuidValid$(publicLinkGuid, apartmentId).pipe(
        map((isValid: boolean) => {
          if (!isValid) {
            this.publicLinkService.removeGuid();
            this.router.navigate(['']).then();
          }
          this.publicLinkService.setSelectedApartmentId(apartmentId, false);
          this.spaceFacade.setSelectedId(apartmentId);
          return isValid;
        })
      );
    }
    // logged user path
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((isAuthorized: AuthenticatedResult) => isAuthorized.isAuthenticated),
      switchMap((isAuthenticated: boolean) => {
        return iif(() => isAuthenticated, this.oidcSecurityService.userData$, of({})); //!!user ? this.authService.checkUserProfile(user?.profile?.sub) : of({});
      }),
      switchMap((userData: UserDataResult) =>
        iif(() => userData.userData, this.authService.checkUserProfile(userData.userData.sub), of({}))
      ),
      catchError((error) => {
        const userProfileExpired = {};
        return of(userProfileExpired);
      }),
      map((userProfile: UserProfileInterface) => {
        if (userProfile?.profileExists === false) {
          this.router.navigate(['eula'], { queryParams: { createUser: 'createUser' } }).then();
        } else if (userProfile?.profileExists === true && userProfile?.eulaAccepted === false) {
          // save navigation url
          this.setUserLastNavigationUrl(state.url);
          this.router.navigate(['eula']).then();
        }
        return userProfile;
      }),
      take(1),
      map((userProfile: UserProfileInterface) => {
        if (!!(userProfile && userProfile?.profileExists && userProfile?.eulaAccepted)) return true;
        return false;
      })
    );
  }

  canLoad(): Observable<boolean> {
    return this.oidcSecurityService.getAccessToken().pipe(
      map((accessToken) => {
        const parsedToken = this.JwtHelper.decodeToken(accessToken);
        const roles = parsedToken.role;
        return roles === 'Tester' ? true : false;
      })
    );
  }

  private setUserLastNavigationUrl(stateUrl) {
    this.backToNavigationUrl.set(stateUrl);
  }
}
