import { Units } from '@simlab/matterport/api';
import THREE, { Vector3 } from 'three';
import { distanceText } from '../helpers/area-plane.helper';
import { DeviceCanvasTextTexture } from '../helpers/canvas.render.helper';

export function segmentAreaMesh(
  threeContext: typeof THREE,
  size: number,
  position: Vector3,
  unit: Units = Units.Metric
) {
  const { texture, textLength } = segmentsTexture(threeContext, size, unit);
  const material = new threeContext.MeshBasicMaterial({
    alphaTest: 0.2,
    polygonOffset: false,
    opacity: 1,
    transparent: false,
    side: threeContext.DoubleSide,
    polygonOffsetFactor: 0,
    polygonOffsetUnits: 0,
  });
  const geometry = new threeContext.PlaneGeometry(1, 1);
  const mesh = new threeContext.Mesh(geometry, material);
  mesh.material.dispose();
  mesh.material.map = texture;
  mesh.scale.set(textLength * 0.03, 0.08, 0);
  mesh.position.copy(position);
  return mesh;
}

export function segmentsTexture(
  threeContext: typeof THREE,
  size: number,
  unit: Units = Units.Metric
) {
  const text = distanceText(size, unit);
  const textLength = text.length < 5 ? 5 : text.length;
  const canvasTextureIcon = new DeviceCanvasTextTexture(
    {
      text: `${text}`,
      backgroundColor: new threeContext.Color('#FFFFFF'),
    },
    textLength
  );
  return {
    texture: new threeContext.CanvasTexture(canvasTextureIcon.ctx.canvas),
    textLength,
  };
}
