<div class="badge-wrapper">
  <div
    ui-badge
    [badge]="context"
    ui-badgeSize="small"
    ui-badgePosition="above after"
    badgeRoundBackground
  ></div>
  <ng-content></ng-content>
</div>
