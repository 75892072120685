import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, concatMap, map, Observable, of } from 'rxjs';
import { MatterportConfig } from '../public-api';

export const CACHE_DEFAULT_SIZE = 500 * 1000000;
@Injectable()
export class FileLoaderCacheService implements OnDestroy {
  private readonly _cachedFile: Record<string, string> = {};
  private _cacheSize = 0;
  private _fileLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  constructor(
    private readonly httpClient: HttpClient,
    private readonly config: MatterportConfig
  ) {}
  ngOnDestroy(): void {
    this.deleteAll();
  }

  getModel$(url: string): Observable<string | null> {
    return of(true).pipe(
      concatMap(() => {
        if (this._cachedFile[url]) {
          return of(this._cachedFile[url]);
        } else {
          return this._getFile$(url).pipe(
            map((e) => {
              if (e) {
                if (this.isCacheFull) {
                  const keys = Object.keys(this._cachedFile);
                  delete this._cachedFile[keys[keys.length - 1]];
                }

                this._cachedFile[url] = URL.createObjectURL(e);
                return this._cachedFile[url];
              }
              return null;
            })
          );
        }
      })
    );
  }

  delete(url: string) {
    if (this._cachedFile[url]) delete this._cachedFile[url];
  }

  deleteAll() {
    Object.keys(this._cachedFile).forEach((url) => this.delete(url));
  }
  private _getFile$(url: string): Observable<Blob | null> {
    return this.httpClient
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((e) => {
          this._cacheSize += e.body?.size || 0;

          return e.body;
        })
      );
  }

  get isCacheFull() {
    return this._cacheSize >= (this.config.fileCacheSize || CACHE_DEFAULT_SIZE);
  }

  clearCache() {
    Object.keys(this._cachedFile).forEach((key) => {
      delete this._cachedFile[key];
    });
  }

  get cacheSize() {
    return this._cacheSize;
  }
}
