import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { RoleFullAccess, UserFullAccess } from '@simOn/privileges/element/models';
import { Role } from '@simOn/privileges/role/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PrivilegesApiService {
  private http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getUserFullAccesses(apartmentId?: string): Observable<UserFullAccess> {
    let header;
    if (apartmentId) {
      header = new HttpHeaders().set('apartmentId', apartmentId);
    }
    return this.http.get<UserFullAccess>(`${this._apiUrl}/Access/GetUserFullAccesses`, {
      headers: header
    });
  }

  getRoleFullAccesses(roleGuid: string): Observable<RoleFullAccess> {
    return this.http.get<RoleFullAccess>(`${this._apiUrl}/Access/GetRoleFullAccesses?roleGuid=${roleGuid}`);
  }

  getPropertyRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${this._apiUrl}/Access/GetApartmentRoles`);
  }
}
