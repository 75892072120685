import { EntityAdapter, EntityState, Update, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { TLastVisitedUser } from '@simOn/user/last-visited/models';
import { CLEAR_STATE_REDUCER } from '@simOn/utils';
import * as UsersActions from './users.actions';
import { ClearUsersState, SetSelectedUserId } from './users.actions';

export const USERS_FEATURE_KEY = 'users';

export interface UsersState extends EntityState<TLastVisitedUser> {
  selectedId?: string | number; // which Users record has been selected
  loaded: boolean; // has the Users list been loaded
  error?: string | null; // last known error (if any)
}

export interface UsersPartialState {
  readonly [USERS_FEATURE_KEY]: UsersState;
}

export const usersAdapter: EntityAdapter<TLastVisitedUser> = createEntityAdapter<TLastVisitedUser>();

export const initialUsersState: UsersState = usersAdapter.getInitialState({
  // set initial required properties
  loaded: false
});

const reducer = createReducer(
  initialUsersState,
  on(UsersActions.initUsers, (state) => ({ ...state, loaded: false, error: null })),
  on(UsersActions.loadUsersSuccess, (state, { users }) => usersAdapter.setAll(users, { ...state, loaded: true })),
  on(UsersActions.loadUsersFailure, (state, { error }) => ({ ...state, error })),
  on(UsersActions.SetSelectedApartmentUsers, (state, action) => {
    return {
      ...state
    };
  }),
  on(SetSelectedUserId, (state, action) => {
    return {
      ...state,
      selectedId: action.selectedId
    };
  }),
  on(UsersActions.SetSelectedApartmentUsersSuccess, (state, action) => {
    return usersAdapter.setAll(action.selectedApartmentUsers, { ...state, loaded: true });
  }),
  on(UsersActions.AddApartmentUserState, (state, action) => {
    return {
      ...state
    };
  }),
  on(UsersActions.UpdateApartmentUserState, (state, action) => {
    const updateUser: Update<TLastVisitedUser> = {
      id: action.userState.id,
      changes: {
        ...state.entities[action.userState.id],
        ...action.userState
      }
    };
    return usersAdapter.updateOne(updateUser, state);
  }),
  on(UsersActions.AddApartmentUserStateSuccess, (state, action) => {
    return usersAdapter.upsertOne(action.apartmentUser, state);
  }),
  on(UsersActions.DeleteApartmentUserState, (state, action) => {
    return usersAdapter.removeOne(action.apartmentUser.userId, state);
  }),
  CLEAR_STATE_REDUCER(ClearUsersState, initialUsersState)
);

export function usersReducer(state: UsersState | undefined, action: Action) {
  return reducer(state, action);
}
