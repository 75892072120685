import { Component, ElementRef, Input, Renderer2, ViewChild, ViewEncapsulation, inject } from '@angular/core';

@Component({
  selector: 'sim-progress-spinner',
  exportAs: 'progressSpinner',
  standalone: true,
  template: '<div #spinner class="loading"></div>',
  styleUrls: ['./sim-progress-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'sim-progress-spinner'
  }
})
export class SimProgressSpinnerComponent {
  private readonly renderer = inject(Renderer2);

  @ViewChild('spinner') set spinner(value: ElementRef<HTMLDivElement>) {
    this.renderer.setStyle(value.nativeElement, 'display', 'block');
    this.renderer.setStyle(value.nativeElement, 'height', `${this.diameter}px`);
    this.renderer.setStyle(value.nativeElement, 'width', `${this.diameter}px`);
    this.renderer.setStyle(value.nativeElement, 'border-width', `${this.strokeWidth}px`);
  }

  private _diameter = 100;

  @Input()
  set diameter(value: number) {
    this._diameter = value;
  }

  get diameter(): number {
    return this._diameter;
  }

  private _strokeWidth = 10;

  @Input()
  get strokeWidth(): number {
    return this._strokeWidth;
  }
  set strokeWidth(value: number) {
    this._strokeWidth = value;
  }
}
