export class MatterportApiConfig {
  applicationKey!: string;
  clientId!: string;
  redirectOrigin!: string;
  matterportModelApiUrl?: string;
  matterportShowcaseApiUrl?: string;

  serverApiUrl?: string;
  host?: string;
}
