import { ApolloQueryResult } from '@apollo/client';
import { Observable } from 'rxjs';
import { GetModelTagsQuery, ModelsQuery } from './graph_queries';

export interface MatterportApi {
  // authorize$(code: string): Observable<AuthorizationResponse>;
  getModels$(
    query?: string,
    offset?: string
  ): Observable<ApolloQueryResult<ModelsQuery>>;
  getTags$(modelId: string): Observable<ApolloQueryResult<GetModelTagsQuery>>;
}

export interface AuthorizationResponse {
  access_token: string;
  expires_in: number;
}

export enum ModelAccess {
  Public = 'access.public',
  Private = 'access.private',
  Protected = 'access.protected',
}
