import { Injectable } from '@angular/core';
import {
  defer,
  filter,
  firstValueFrom,
  Observable,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  GLTFLoader,
  IObjectLoader,
  LoadingStatusChange,
  ObjectUpdate,
  TransformMode,
} from '../models/object-loader.type';
import { ICustomComponent } from '../public-api';
import { MatterportManagerService } from './matterport-manager.service';

@Injectable()
export class MatterportInteriorDesignerService implements IObjectLoader {
  private get _objects(): IObjectLoader {
    return this.manager.objects;
  }

  private get _component(): ICustomComponent {
    return this.manager.component;
  }

  public set selectedObject(objectId: string) {
    this._objects.selectedObject = objectId;
  }
  readonly objectLoadingStatusChange$: Observable<LoadingStatusChange> = defer(
    () => {
      if (this._objects) {
        return this._objects?.objectLoadingStatusChange$;
      }
      return this.manager.sdk$.pipe(
        filter((sdk) => !!sdk),
        take(1),
        switchMap(() => this.objectLoadingStatusChange$)
      );
    }
  );
  readonly selectedObjectChange$: Observable<ObjectUpdate> = defer(() => {
    if (this._objects) {
      return this._objects?.selectedObjectChange$;
    }
    return this.manager.sdk$.pipe(
      filter((sdk) => !!sdk),
      take(1),
      switchMap(() => this.selectedObjectChange$)
    );
  });

  readonly objectBadgeClick$ = this._afterScanLoaded$(
    () => this._component.componentClicked$
  );

  readonly addBadgeToObject$ = (objectId: string) =>
    this._afterScanLoaded$(() => this._objects.addBadgeToObject$(objectId));

  readonly setAllObjectsVisibility = (visible: boolean) =>
    firstValueFrom(
      this._afterScanLoaded(() =>
        this._objects.setAllObjectsVisibility(visible)
      )
    );
  readonly setObjectVisibility = (objectId: string, visible: boolean) => {
    firstValueFrom(
      this._afterScanLoaded(() =>
        this._objects.setObjectVisibility(objectId, visible)
      )
    );
  };

  readonly addObject$ = (payload: {
    objectConfig: GLTFLoader;
    isMobile: boolean;
    mobileHintElement: Element;
  }) => this._afterScanLoaded$(() => this._objects.addObject$(payload));
  readonly loadGltf$ = (objectConfig: GLTFLoader) =>
    this._afterScanLoaded$(() => this._objects.loadGltf$(objectConfig));

  readonly transformObject = (objectId: string, transform: TransformMode) =>
    firstValueFrom(
      this._afterScanLoaded(() =>
        this._objects?.transformObject(objectId, transform)
      )
    );

  readonly deleteAllObjects = () =>
    firstValueFrom(
      this._afterScanLoaded(() => this._objects.deleteAllObjects())
    );
  readonly goToObject = (objectId: string) =>
    firstValueFrom(
      this._afterScanLoaded(() => this._objects.goToObject(objectId))
    );
  readonly deleteObject = (objectId: string) =>
    firstValueFrom(
      this._afterScanLoaded(() => this._objects.deleteObject(objectId))
    );
  readonly cancelAdding = () => {
    this._objects.cancelAdding();
  };
  constructor(private readonly manager: MatterportManagerService) {}

  private _afterScanLoaded(cb: () => void) {
    return this.manager.sdk$.pipe(
      filter((sdk) => !!sdk),
      take(1),
      tap(() => cb())
    );
  }
  private _afterScanLoaded$<T>(cb: () => Observable<T>) {
    return this.manager.sdk$.pipe(
      filter((sdk) => !!sdk),
      take(1),
      switchMap(() => cb())
    );
  }
}
