import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { UiHelperModule } from '@simOn/ui/ui-helper';

@Component({
  selector: 'sim-spinner',
  exportAs: 'simSpinner',
  template: `<div #spinner class="loading"></div>`,
  styleUrls: ['./sim-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiHelperModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'sim-spinner'
  }
})
export class SimSpinnerComponent {
  private readonly renderer = inject(Renderer2);

  @ViewChild('spinner') set spinner(value: ElementRef<HTMLDivElement>) {
    if (this.buttonSpinner) {
      this.renderer.addClass(value.nativeElement, 'button-spinner');
    } else {
      this.renderer.setStyle(value.nativeElement, 'display', 'block');
      this.renderer.setStyle(value.nativeElement, 'height', `${this.diameter}px`);
      this.renderer.setStyle(value.nativeElement, 'width', `${this.diameter}px`);
      this.renderer.setStyle(value.nativeElement, 'border-width', `${this.strokeWidth}px`);
    }
  }

  private _diameter = 100;
  private _buttonSpinner: boolean = false;

  @Input()
  set diameter(value: number) {
    this._diameter = value;
  }

  get diameter(): number {
    return this._diameter;
  }

  @Input()
  set buttonSpinner(value: BooleanInput) {
    this._buttonSpinner = coerceBooleanProperty(value);
  }

  get buttonSpinner(): boolean {
    return this._buttonSpinner;
  }

  private _strokeWidth = 10;

  @Input()
  get strokeWidth(): number {
    return this._strokeWidth;
  }
  set strokeWidth(value: number) {
    this._strokeWidth = value;
  }
}
