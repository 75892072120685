import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType } from '@simOn/device/models';
import { SimSwitcherModule } from '@simOn/ui/sim-switcher';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule, SimSwitcherModule],
  template: `<sim-switcher (action)="propertyValue = $event" [switchState]="propertyValue"></sim-switcher> `,
  styleUrls: ['./switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-switch'
  }
})
export class SwitchComponent extends PropertyBase<'TurnedOn', boolean, never> {
  valueToCommand(value: boolean): { command: keyof CommandValuesType; parameters: string | number | undefined } {
    return {
      command: value ? 'TurnOn' : 'TurnOff',
      parameters: undefined
    };
  }
  commandToValue(params: { command: keyof CommandValuesType; parameters: string | number }): boolean {
    return params.command === 'TurnOn';
  }
  valueToParameter(params: boolean): never {
    throw new Error('Method not implemented');
  }
  get propertyValue(): boolean {
    if (!this.propertyDefinition) console.error('Set property definition input');
    return this.propertyDefinition?.values?.value || false;
  }
  set propertyValue(value: boolean) {
    if (!this.propertyDefinition) {
      console.error('Set property definition input');
      return;
    }
    this.propertyDefinition = {
      ...this.propertyDefinition,
      values: {
        ...this.propertyDefinition.values,
        value
      }
    };
    this.partialCommand$.emit(this.valueToCommand(value));
  }
}
