export interface InfoModalResult {
  action: 'buttonClick' | 'close';
}
export type ModalType = keyof typeof ModalTypeEnum;

export enum ModalTypeEnum {
  Error = 'Error',
  Info = 'Info'
}
export interface InfoModalDataInterface {
  title: string;
  text: string;
  buttonText: string;
  modalType: ModalTypeEnum;
}
