import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimFormModule } from '@simOn/ui/sim-form-filed';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { SimInputModule } from '@simOn/ui/sim-input';
import { MODAL_DATA, ModalRef } from '@simOn/ui/sim-modal';
import { SimTooltipComponent } from '@simOn/ui/sim-tooltip';

@Component({
  selector: 'matterport-token-description-modal',
  standalone: true,
  imports: [
    CommonModule,
    SimButtonModule,
    SimFormModule,
    SimInputModule,
    SimTooltipComponent,
    SimIconModule,
    FormsModule
  ],
  templateUrl: './matterport-token-description-modal.component.html',
  styleUrls: ['./matterport-token-description-modal.component.scss']
})
export class MatterportTokenDescriptionModalComponent {
  private readonly modalRef = inject<ModalRef<string>>(ModalRef<string>);
  private readonly data = inject<string>(MODAL_DATA);
  readonly tooltip: string = $localize`:@@MATTERPORT_HINT_TOOLTIP:Sign your scan with a name that will help you reorganize the "Matterport Account" or "Matterport Organization" which you are connecting the space.`;
  tokenDescription: string = '';
  constructor() {
    this.tokenDescription = this.data;
  }

  next(): void {
    this.modalRef.emit({
      state: true,
      result: this.tokenDescription
    });
    this.modalRef.close();
  }
}
