import { SmartApiProviderEnum } from '@simOn/common/providers';

export type TProviderType = 'UNKNOWN' | 'IOT' | 'INTEGRATION' | 'SCAN';
export type TIntegrationProviderType = 'UNKNOWN' | 'MAXIMO';

export interface ISupportedProviderBase {
  logo: string;
  providerType: TProviderType;
}

export interface ISupportedIntegrationProvider extends ISupportedProviderBase {
  integrationProviderType: TIntegrationProviderType;
}

export interface IAuthorizationDeletionData {
  elementsCount: number;
}

export interface IConnectionStatus {
  status: TProviderConnectionStatus;
  lastEditorEmail: string;
}
export interface RequestedMasterDevice {
  smartApiProvider: SmartApiProviderEnum;
  masterDeviceId: string;
  masterDeviceName: string;
}
export type SupportedProviderDisplayConfig<T extends SmartApiProviderEnum> = {
  id: T;
  logo: string;
};
export type SupportedProvidersDictionary = {
  [name in keyof typeof SmartApiProviderEnum]: SupportedProviderDisplayConfig<(typeof SmartApiProviderEnum)[name]>;
};

export const ProviderConnectionStatusEnum = {
  Unknown: 'Unknown',
  LoggedIn: 'LoggedIn',
  ConnectionLost: 'ConnectionLost',
  LoggedOut: 'LoggedOut'
} as const;

export type TProviderConnectionStatus = keyof typeof ProviderConnectionStatusEnum;

export interface ProviderConnectionsStatus {
  status: TProviderConnectionStatus;
  lastEditorEmail: string;
  matterportTokenDescription?: string;
}

export interface ApartmentAuthorizationConnectionRequestInterface {
  smartApiProvider: string;
  masterDeviceId: string;
}

export interface TryDeleteApartmentAuthorizationResponseInterface {
  numberOfDevicesToDelete: number;
}
export interface MasterDeviceInterface {
  name: string;
  serialNumber: string;
}
export interface CentralsInterface {
  masterDevices: MasterDeviceInterface[];
  providerStatus: string;
  smartApiProvider: string;
}
export interface ApartmentAuthorizationConnectionsRequestInterface {
  smartApiProvider: SmartApiProviderEnum;
  requestedMasterDevices: string[];
  rooms?: roomsAddApartmentAuthorizations[];
}

export interface roomsAddApartmentAuthorizations {
  smartApiProvider: SmartApiProviderEnum;
  masterDeviceId: string;
  inProviderId: string;
  name: string;
}
