import { Component, HostBinding } from '@angular/core';
export enum AssetsImage {
  LogoSimlabBlack = 'Logo_simlab_black.png',
  LogoSimon = 'Logo_simon.svg'
}
@Component({
  selector: 'sim-footer',
  templateUrl: './sim-footer.component.html',
  styleUrls: ['./sim-footer.component.sass']
})
export class SimFooterComponent {
  @HostBinding() class = 'sim-footer';
  readonly AssetsImage = AssetsImage;
}
