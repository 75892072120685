import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import {
  AddDevicesResponseInterface,
  ComponentType,
  DeviceDto,
  DeviceProperties,
  DevicesAddDtoInterface,
  DeviceStructure,
  ExecuteCommandPayload,
  NotImportedMasterDevice,
  UpdateDevice
} from '@simOn/device/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DevicesApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getDevice(deviceId: string): Observable<DeviceDto[]> {
    return this.http.get<DeviceDto[]>(`${this._apiUrl}/Devices/GetDevice?deviceId=${deviceId}`);
  }

  addDevices(device: DevicesAddDtoInterface): Observable<AddDevicesResponseInterface> {
    return this.http.post<AddDevicesResponseInterface>(`${this._apiUrl}/Devices/ImportDevices`, device);
  }

  getNotAssignedDevices<T = NotImportedMasterDevice>(payload: {
    masterDeviceId: string;
    smartApiProvider: SmartApiProviderEnum;
  }): Observable<T> {
    return this.http.get<T>(
      `${this._apiUrl}/Devices/GetNotImportedDevices?SmartApiProvider=${payload.smartApiProvider}&MasterDeviceId=${payload.masterDeviceId}`
    );
  }

  updateDevice(updateDevice: UpdateDevice): Observable<any> {
    return this.http.put<any>(`${this._apiUrl}/Devices/UpdateDevice`, updateDevice);
  }

  deleteDevice(deviceId: string): Observable<unknown> {
    return this.http.delete<any>(`${this._apiUrl}/Devices/DeleteDevice?id=${deviceId}`);
  }

  executeCommand(executeCommandPayload: ExecuteCommandPayload<ComponentType>): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Devices/executeCommand`, executeCommandPayload);
  }

  getDevicesStructure(): Observable<DeviceStructure[]> {
    return this.http.get<DeviceStructure[]>(`${this._apiUrl}/Devices/GetDevicesStructure`);
  }

  getDevicesProperties(payload: {
    masterDeviceId: string;
    smartApiProvider: SmartApiProviderEnum;
  }): Observable<DeviceProperties[]> {
    return this.http.get<DeviceProperties[]>(
      `${this._apiUrl}/Devices/GetDevicesProperties?SmartApiProvider=${payload.smartApiProvider}&MasterDeviceId=${payload.masterDeviceId}`
    );
  }

  getDeviceProperties(payload: {
    deviceId: string;
    masterDeviceId: string;
    smartApiProvider: SmartApiProviderEnum;
  }): Observable<DeviceProperties[]> {
    return this.http.get<DeviceProperties[]>(
      `${this._apiUrl}/Devices/GetDeviceProperties?SimlabDeviceId=${payload.deviceId}&SmartApiProvider=${payload.smartApiProvider}&MasterDeviceId=${payload.masterDeviceId}`
    );
  }
}
