import { Directive, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatterportComponent, MatterportService } from '@simlab/matterport';
import { TransformConverter } from '@simlab/matterport/transform';
import { Observable, Subject, distinctUntilChanged, takeUntil, tap } from 'rxjs';

@Directive()
export abstract class ComponentControllerBase implements OnDestroy {
  private readonly _router = inject(Router);
  private readonly _activatedRoute = inject(ActivatedRoute);
  selectedModel!: Observable<string | undefined>;
  readonly _modeChange: Subject<string> = new Subject<string>();
  readonly _destroy: Subject<void> = new Subject<void>();
  abstract _nodes: Record<string, MatterportComponent>;
  constructor(private readonly matterportService: MatterportService) {}
  set mode(context: string | string[]) {
    if (typeof context !== 'string') return;
    this._modeChange.next(context);
  }
  ngOnDestroy(): void {
    this._nodes = {};
    this._destroy.next();
    this._destroy.complete();
  }
  deleteAllMarkers() {
    Object.keys(this._nodes).map((key) => delete this._nodes[key]);
  }
  protected _deleteMarkerFromScene(id: string) {
    if (this._nodes[id]) {
      this.matterportService.deleteNote(id);
      delete this._nodes[id];
    }
  }

  get matterportTranslator(): TransformConverter {
    return this.matterportService.transformConverter();
  }
  protected _modeChangeObserver(...visibleState: string[]) {
    this._modeChange
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        tap((state: string) => (visibleState.includes(state) ? this._showAll() : this._hideAll())),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  protected _hideAll(): void {
    Object.keys(this._nodes).forEach((compId: string) => this._nodes[compId].comp.hide());
  }

  protected _showAll(): void {
    Object.keys(this._nodes).forEach((compId: string) => this._nodes[compId].comp.show());
  }
}
