import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials',
  standalone: true
})
export class SimInitialsPipe implements PipeTransform {
  transform(value: string | undefined) {
    if (value === undefined) return value;

    return value
      .split(' ')
      .map((e) => e[0])
      .join('')
      .slice(0, 2)
      .toLocaleUpperCase();
  }
}
