export const SupportedDocumentTypes: SupportedFileTypesInterface[] = [
  { fileExtension: '.7z', mimeType: 'application/x-7z-compressed' },
  { fileExtension: '.pdf', mimeType: 'application/pdf' },
  { fileExtension: '.aac', mimeType: 'audio/x-aac' },
  { fileExtension: '.avi', mimeType: 'video/x-msvideo' },
  { fileExtension: '.bin', mimeType: 'application/octet-stream' },
  { fileExtension: '.csv', mimeType: 'text/csv' },
  { fileExtension: '.epub', mimeType: 'application/epub+zip' },
  { fileExtension: '.h261', mimeType: 'video/h261' },
  { fileExtension: '.h263', mimeType: 'video/h263' },
  { fileExtension: '.h264', mimeType: 'video/h264' },
  { fileExtension: '.html', mimeType: 'text/html' },
  { fileExtension: '.json', mimeType: 'application/json' },
  { fileExtension: '.latex', mimeType: 'application/x-latex' },
  { fileExtension: '.potm', mimeType: 'application/vnd.ms-powerpoint.template.macroenabled.12' },
  { fileExtension: '.docm', mimeType: 'application/vnd.ms-word.document.macroenabled.12' },
  { fileExtension: '.dotm', mimeType: 'application/vnd.ms-word.template.macroenabled.12' },
  { fileExtension: '.xls', mimeType: 'application/vnd.ms-excel' },
  { fileExtension: '.xlam', mimeType: 'application/vnd.ms-excel.addin.macroenabled.12' },
  { fileExtension: '.xlsb', mimeType: 'application/vnd.ms-excel.sheet.binary.macroenabled.12' },
  { fileExtension: '.xltm', mimeType: 'application/vnd.ms-excel.template.macroenabled.12' },
  { fileExtension: '.xlsm', mimeType: 'application/vnd.ms-excel.sheet.macroenabled.12' },
  { fileExtension: '.pptx', mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
  { fileExtension: '.sldx', mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.slide' },
  { fileExtension: '.pages', mimeType: 'application/vnd.apple.pages' },
  { fileExtension: '.ppsx', mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow' },
  { fileExtension: '.potx', mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.template' },
  { fileExtension: '.xlsx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  { fileExtension: '.xltx', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template' },
  { fileExtension: '.docx', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
  { fileExtension: '.dotx', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template' },
  { fileExtension: '.ppt', mimeType: 'application/vnd.ms-powerpoint' },
  { fileExtension: '.ppam', mimeType: 'application/vnd.ms-powerpoint.addin.macroenabled.12' },
  { fileExtension: '.sldm', mimeType: 'application/vnd.ms-powerpoint.slide.macroenabled.12' },
  { fileExtension: '.pptm', mimeType: 'application/vnd.ms-powerpoint.presentation.macroenabled.12' },
  { fileExtension: '.ppsm', mimeType: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12' },
  { fileExtension: '.mpp', mimeType: 'application/vnd.ms-project' },
  { fileExtension: '.pub', mimeType: 'application/x-mspublisher' },
  { fileExtension: '.scd', mimeType: 'application/x-msschedule' },
  { fileExtension: '.vsd', mimeType: 'application/vnd.visio' },
  { fileExtension: '.doc', mimeType: 'application/msword' },
  { fileExtension: '.odc', mimeType: 'application/vnd.oasis.opendocument.chart' },
  { fileExtension: '.otc', mimeType: 'application/vnd.oasis.opendocument.chart-template' },
  { fileExtension: '.odb', mimeType: 'application/vnd.oasis.opendocument.database' },
  { fileExtension: '.odf', mimeType: 'application/vnd.oasis.opendocument.formula' },
  { fileExtension: '.odft', mimeType: 'application/vnd.oasis.opendocument.formula-template' },
  { fileExtension: '.odg', mimeType: 'application/vnd.oasis.opendocument.graphics' },
  { fileExtension: '.otg', mimeType: 'application/vnd.oasis.opendocument.graphics-template' },
  { fileExtension: '.odi', mimeType: 'application/vnd.oasis.opendocument.image' },
  { fileExtension: '.oti', mimeType: 'application/vnd.oasis.opendocument.image-template' },
  { fileExtension: '.odp', mimeType: 'application/vnd.oasis.opendocument.presentation' },
  { fileExtension: '.otp', mimeType: 'application/vnd.oasis.opendocument.presentation-template' },
  { fileExtension: '.ods', mimeType: 'application/vnd.oasis.opendocument.spreadsheet' },
  { fileExtension: '.ots', mimeType: 'application/vnd.oasis.opendocument.spreadsheet-template' },
  { fileExtension: '.odt', mimeType: 'application/vnd.oasis.opendocument.text' },
  { fileExtension: '.odm', mimeType: 'application/vnd.oasis.opendocument.text-master' },
  { fileExtension: '.ott', mimeType: 'application/vnd.oasis.opendocument.text-template' },
  { fileExtension: '.rar', mimeType: 'application/x-rar-compressed' },
  { fileExtension: '.rtf', mimeType: 'application/rtf' },
  { fileExtension: '.rtx', mimeType: 'text/richtext' },
  { fileExtension: '.txt', mimeType: 'text/plain' },
  { fileExtension: '.wav', mimeType: 'audio/x-wav' },
  { fileExtension: '.xml', mimeType: 'application/xml' },
  { fileExtension: '.fbx', mimeType: 'application/octet-stream' },
  { fileExtension: '.obj', mimeType: 'application/octet-stream' },
  { fileExtension: '.mtl', mimeType: 'application/octet-stream' },
  { fileExtension: '.ifc', mimeType: 'application/octet-stream' },
  { fileExtension: '.svgmesh', mimeType: 'application/octet-stream' },
  { fileExtension: '.zip', mimeType: 'application/zip' }
];

export const SupportedImageTypes: SupportedFileTypesInterface[] = [
  { fileExtension: '.bmp', mimeType: 'image/bmp' },
  { fileExtension: '.gif', mimeType: 'image/gif' },
  { fileExtension: '.jpeg', mimeType: 'image/jpeg' },
  { fileExtension: '.jpg', mimeType: 'image/jpeg' },
  { fileExtension: '.jpe', mimeType: 'image/jpeg' },
  { fileExtension: '.png', mimeType: 'image/png' },
  { fileExtension: '.tiff', mimeType: 'image/tiff' },
  { fileExtension: '.tif', mimeType: 'image/tiff' },
  { fileExtension: '.webp', mimeType: 'image/webp' },
  { fileExtension: '.jfif', mimeType: 'image/jpeg' }
];

export const SupportedCommonTypes: SupportedFileTypesInterface[] = SupportedImageTypes.concat(SupportedDocumentTypes);

export interface SupportedFileTypesInterface {
  fileExtension: string;
  mimeType: string;
}
