import { Subject } from 'rxjs';

//TODO (olek) delete this
export class Localstorage<T> {
  private _value: T | null;
  private readonly _name: string;
  private listener: ((e: StorageEvent) => void) | undefined;

  public emitter$ = new Subject<T>();

  constructor(name: string, value?: T) {
    this._name = name;
    this._value = value ? value : null;
  }

  get value() {
    return this._value;
  }

  set value(value: T | null) {
    this._value = value;
    this.save();
  }

  get name() {
    return this._name;
  }

  set(value: T) {
    this.value = value;
    this.save();
    return this;
  }

  load() {
    const item = localStorage.getItem(this._name);
    if (!item) return this;
    this.value = JSON.parse(item);
    return this;
  }

  save() {
    localStorage.setItem(this._name, JSON.stringify(this._value));
    return this;
  }

  delete() {
    localStorage.removeItem(this._name);
    return this;
  }

  listen() {
    const listener = (e: StorageEvent) => {
      if (e.key === this._name) {
        this._value = JSON.parse(e.newValue!);
        this._value && this.emitter$.next(this._value);
      }
    };

    this.listener = listener;
    window.addEventListener('storage', listener);
    return this;
  }

  stopListen() {
    this.listener && window.removeEventListener('storage', this.listener);
  }
}
