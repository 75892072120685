import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType } from '@simOn/device/models';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `<p>{{ propertyValue }}</p> `,
  styleUrls: ['./acceleration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-acceleration'
  }
})
export class AccelerationComponent extends PropertyBase<'Acceleration3D', string, never> {
  valueToCommand(params: string): { command: keyof CommandValuesType; parameters: string | number } {
    throw new Error('Method not implemented.');
  }
  commandToValue(params: { command: keyof CommandValuesType; parameters: string | number }): string {
    throw new Error('Method not implemented.');
  }
  valueToParameter(params: string): never {
    throw new Error('Method not implemented.');
  }
  set propertyValue(params: string) {
    throw new Error('Method not implemented.');
  }
  get propertyValue(): string {
    const { x, y, z } = this.propertyDefinition.values;
    return `x:${x} y:${y} z:${z}`;
  }
}
