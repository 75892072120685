export function easeOutSine(x: number): number {
  return Math.sin((x * Math.PI) / 2);
}
export function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}
export function lerp(value1: number, value2: number, amount: number) {
  return (1 - amount) * value1 + amount * value2;
}

export function inverseLerp(begin: number, end: number, v: number): number {
  return (v - begin) / (end - begin);
}
