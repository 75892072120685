import { Vector3 } from 'three';
import { MpSdk, Vector2 } from '../../../assets/bundle/sdk';
import {
  ComponentConfiguration,
  ComponentsType,
  MeshConfiguration,
  SpriteConfiguration,
  TextConfiguration,
} from './custom-component.type';
import { Colliders, Transition } from './enums';
import { ICONS } from './icons';

export interface TagNote {
  /**
   * This is ID from database
   * @type {string}
   */
  id: string; //indicates id in DB
  label?: string;
  position: Vector3;
  normal?: Vector3;
  noteType: TagNoteTypes;
}

export interface Pointer {
  normal: Vector3;
  position: Vector3;
  object: Colliders;
}

export interface StartPlacingConfig {
  note: TagNote;
  autoFinishByClick: boolean;
  mobile?: boolean;
}

export interface MoveToSweepConfig {
  id: string;
  rotation: Vector2;
  translation: MpSdk.Sweep.Transition;
  /**
   * This is in ms
   * @type {number}
   */
  transitionTime: number;
}

export interface MoveToClosestSweepConfig {
  position: Vector3;
  rotation: Vector2;
  translation: Transition;
  /**
   * This is in ms
   * @type {number}
   */
  transitionTime: number;
}
export enum TagNoteTypes {
  INFO = 'INFO',
  INPROGRESS = 'INPROGRESS',
  RESOLVED = 'RESOLVED',
  PENDING = 'PENDING',
  UNRESOLVED = 'UNRESOLVED',
  A1 = 'A1',
  B1 = 'B1',
  C1 = 'C1',
  A2 = 'A2',
  B2 = 'B2',
  C2 = 'C2',
  PIN = 'PIN',
}

export class CustomSpriteComponent
  implements ComponentConfiguration<ComponentsType>
{
  position: Vector3;
  normal: Vector3;
  stemHeight: number;
  id: string;
  visible?: boolean | undefined;
  renderOrder?: number | undefined;
  opacity?: number | undefined;
  scale: Vector3;
  isCollider?: boolean | undefined;
  autoScale?: boolean | undefined;
  userData?: any;
  depthTest?: boolean | undefined;
  transparent?: boolean | undefined;
  lookAt?: boolean | undefined;
  objects: (SpriteConfiguration | TextConfiguration | MeshConfiguration)[];
  constructor(tagNote: TagNote) {
    this.position = tagNote.position;
    this.normal = tagNote.normal || new Vector3(0, 0, 0);
    this.stemHeight = 0;
    this.id = tagNote.id;
    this.scale = new Vector3(1, 1, 1);
    this.objects = [
      new SpriteConfiguration({
        icon: ICONS[tagNote.noteType],
      }),
    ];
  }
}
