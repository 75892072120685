import * as THREE from 'three';
import { Box3, Material, Mesh, Object3D, Scene } from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { disposeHierarchy, disposeNode } from '../helpers/three.helpres';
import { Dict } from '../public-api';
import { SceneComponentBase } from './scene-component-base';
export const customSceneType = 'mp.customSceneRenderer';
export function makeCustomSceneRenderer() {
  return new CustomScene();
}
export class CustomScene extends SceneComponentBase {
  inputs?: Dict<any> | undefined;
  events!: Dict<boolean>;
  private readonly _roomsGeometryRef: Set<Mesh> = new Set();
  public _sceneRef: Scene | undefined;
  private _THREERef: typeof THREE | undefined;
  private _renderedShadowMeshRef: Map<string, Mesh> = new Map();
  public _renderer: THREE.WebGLRenderer | undefined;
  override onInit() {
    this._THREERef = this.context.three;
    this._sceneRef = this.context.scene;
    const camera = this.context.camera;
    this._renderer = this.context.renderer;

    this._renderer.toneMapping = this._THREERef.ACESFilmicToneMapping;
    this._renderer.toneMappingExposure = 1;

    this._renderer.outputEncoding = this._THREERef.sRGBEncoding;

    this._renderer.shadowMap.enabled = true; //enable shadow
    this._renderer.shadowMap.type = this._THREERef.PCFShadowMap; //enable shadow

    this._sceneRef.traverse((sceneElement: Object3D) => {
      if (
        sceneElement.name === 'FallbackMesh' ||
        sceneElement.name === 'ModelMesh:undefined'
      ) {
        sceneElement.children.forEach((floorMesh) => {
          floorMesh.children.forEach((room) => {
            if (room.name.startsWith('RoomMesh')) {
              this._roomsGeometryRef.add(room as Mesh);
            }
          });
        });
      }
    }); //enable shadow

    new RGBELoader()
      .setPath('assets/maps/')
      .load('royal_esplanade_1k.hdr', (texture) => {
        if (!this._THREERef) return;
        texture.mapping = this._THREERef.EquirectangularReflectionMapping;
        if (this._sceneRef) {
          this._sceneRef.background = texture;
          this._sceneRef.environment = texture;
          this.context.renderer.render(this._sceneRef, camera);
        }
      });
  }

  renderMesh(shadowBox: Box3) {
    this._roomsGeometryRef.forEach((room) => {
      const roomBox = room.geometry.boundingBox;
      if (!roomBox) return;
      if (this._sceneRef)
        if (shadowBox.intersectsBox(roomBox)) {
          if (this._renderedShadowMeshRef.get(room.name)) return;
          const geometry = room.geometry.clone();

          geometry.computeVertexNormals(); //recalculate normal for shadow working correctly
          const material = new THREE.ShadowMaterial({
            opacity: 0.2,
            transparent: true,
          });

          const mesh = new THREE.Mesh(geometry, material);

          mesh.receiveShadow = true;
          mesh.castShadow = false;
          material.polygonOffset = true;
          material.polygonOffsetUnits = -10; //add polygon offset to resolve z-fighting problem
          this._renderedShadowMeshRef.set(room.name, mesh);

          this._sceneRef.add(mesh);
        } else {
          const shadowMeshRef = this._renderedShadowMeshRef.get(room.name);
          if (shadowMeshRef) {
            (shadowMeshRef.material as Material).dispose();
            shadowMeshRef.geometry.dispose();
            this._sceneRef.remove(shadowMeshRef);
            this._renderedShadowMeshRef.delete(room.name);
          }
        }
    });
  }
  override onDestroy() {
    if (super.onDestroy) super.onDestroy();
    this._renderedShadowMeshRef.forEach((shadowMesh) => {
      if (this._sceneRef) {
        disposeNode(shadowMesh);
        this._sceneRef.remove(shadowMesh);
      }
    }); //enable shadow
    this._renderedShadowMeshRef.clear();
    disposeHierarchy(this._sceneRef as THREE.Scene, (node) =>
      disposeNode(node)
    );

    this._renderer?.info?.programs?.forEach((program: any) => {
      program.destroy();
    });

    if (this._renderer) {
      this._renderer.renderLists.dispose();
      this._renderer.dispose();
      this._renderer?.clearDepth();
    }

    this._renderer = undefined;
    this._sceneRef = undefined;
    this._THREERef = undefined;
  }
}
