import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { SmartApiProviderEnum } from '@simOn/common/providers';
import { DevicesApiService } from '@simOn/device/data-access';
import { NotImportedDevice, NotImportedMasterDevice } from '@simOn/device/models';
import { Subject, concat, merge, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { DeleteWidgets } from '../widgets/widget.actions';
import { WidgetFacade } from '../widgets/widget.facade';
import {
  AddDevices,
  AddDevicesFailure,
  AddDevicesSuccess,
  ClearDeviceState,
  DeleteDevice,
  DeleteDeviceFailure,
  DeleteDeviceSuccess,
  GetDevice,
  GetDeviceFailure,
  GetDeviceSuccess,
  GetNotAssignedDevices,
  GetNotAssignedDevicesFailure,
  GetNotAssignedDevicesSuccess,
  LoadDevices,
  LoadDevicesFailure,
  LoadDevicesSuccess,
  UpdateDevice,
  UpdateDeviceFailure,
  UpdateDeviceSuccess
} from './devices.actions';
import { DevicesFacade } from './devices.facade';

@Injectable()
export class DevicesEffects {
  private readonly actions$ = inject(Actions);
  private readonly devicesFacade = inject(DevicesFacade);
  private readonly widgetFacade = inject(WidgetFacade);
  private readonly devicesApiService = inject(DevicesApiService);
  private readonly _destroySource: Subject<void> = new Subject<void>();

  initStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoadDevices),
      tap(() => this._destroySource.next()),
      switchMap(() =>
        this.devicesApiService.getDevicesStructure().pipe(
          map((data) => LoadDevicesSuccess({ devices: data })),
          catchError((error) => of(LoadDevicesFailure({ error })))
        )
      )
    )
  );
  clearState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ClearDeviceState),
        tap(() => this._destroySource.next())
      ),
    { dispatch: false }
  );

  addDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddDevices),
      switchMap((payload) =>
        this.devicesApiService.addDevices(payload.device).pipe(
          tap(() => {
            this.widgetFacade.loadWidgets();
            this.devicesFacade.loadDevicesStructure();
            // this.devicesFacade.loadDevicesProperties();
          }),
          mergeMap(() => of(AddDevicesSuccess())), //GetNotAssignedDevices()
          catchError((error) => of(AddDevicesFailure({ error })))
        )
      )
    )
  );

  getDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetDevice),
      mergeMap((result) => {
        return this.devicesApiService.getDevice(result.id).pipe(
          map((device) => {
            return GetDeviceSuccess({ device });
          }),

          catchError((error) => of(GetDeviceFailure({ error })))
        );
      })
    )
  );

  updateDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateDevice),
      mergeMap((payload) => {
        return this.devicesApiService.updateDevice(payload.updateDevice).pipe(
          mergeMap(() => concat([UpdateDeviceSuccess({ updateDevice: payload.updateDevice })])),
          catchError((error) => of(UpdateDeviceFailure({ error })))
        );
      })
    )
  );

  deleteDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteDevice),
      exhaustMap((payload) => {
        return this.devicesApiService.deleteDevice(payload.id).pipe(
          mergeMap(() => of(DeleteDeviceSuccess({ id: payload.id }), DeleteWidgets({ simlabDeviceId: payload.id }))),
          catchError((error) => of(DeleteDeviceFailure({ error })))
        );
      })
    )
  );

  UpdateDeviceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateDeviceSuccess),
      switchMap((d) => {
        this.widgetFacade.loadWidgets();
        return this.devicesFacade.getDeviceById$(d.updateDevice.rootComponentId).pipe(take(1));
      }),
      mergeMap((payload) => {
        return this.devicesApiService.getDevice(payload.simlabDeviceId).pipe(
          map((device) => GetDeviceSuccess({ device })),
          catchError((error) => of(UpdateDeviceFailure({ error })))
        );
      })
    )
  );

  getNotAssignedDevices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetNotAssignedDevices),
      switchMap(({ providers }) => {
        let notImportedDevices: NotImportedDevice[] = [];
        let importedProviders: SmartApiProviderEnum[] = [];
        if (providers.length) {
          return merge(
            ...providers.map(({ masterDeviceId, smartApiProvider }) =>
              this.devicesApiService.getNotAssignedDevices({ masterDeviceId, smartApiProvider }).pipe(
                map((unassignedDevices: NotImportedMasterDevice) => {
                  importedProviders.push(smartApiProvider);
                  notImportedDevices = [...notImportedDevices, ...unassignedDevices.notImportedDevices];
                  if (importedProviders.length === providers.length) {
                    return notImportedDevices;
                  }
                  return undefined;
                }),
                filter((notImportedDevices) => !!notImportedDevices),
                map((unassignedDevices) =>
                  GetNotAssignedDevicesSuccess({ unassignedDevices: unassignedDevices as NotImportedDevice[] })
                ),
                catchError((error) => of(GetNotAssignedDevicesFailure({ error })))
              )
            )
          );
        } else {
          return of(GetNotAssignedDevicesSuccess({ unassignedDevices: [] }));
        }
      })
    )
  );
}
