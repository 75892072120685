import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DrawerContainerComponent } from './components/drawer-container/drawer-container.component';
import { DrawerContentComponent } from './components/drawer-content.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { SidenavContainerComponent } from './components/sidenav-container/sidenav-container.component';
import { SidenavContentComponent } from './components/sidenav-content.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
@NgModule({
  declarations: [
    DrawerContainerComponent,
    SidenavContainerComponent,
    DrawerContentComponent,
    SidenavContentComponent,
    SidenavComponent,
    DrawerComponent
  ],
  imports: [CommonModule],
  exports: [
    DrawerContainerComponent,
    SidenavContainerComponent,
    DrawerContentComponent,
    SidenavContentComponent,
    SidenavComponent,
    DrawerComponent
  ]
})
export class SimSidenavModule {}
