import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'sim-modal',
  templateUrl: './sim-modal.component.html',
  styleUrls: ['./sim-modal.component.scss']
})
export class SimModalComponent {
  @Input() showCloseButton: boolean = true;
  @Input() darkMode: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @HostBinding() class = '';
  @Input()
  set modalWidth(newWidth: ModalSizeType) {
    this.class = newWidth;
  }
  get modalWidth() {
    return this._modalWidth;
  }
  private _modalWidth: ModalSizeType = 'medium';
}

export type ModalSizeType = 'medium' | 'mediumExtend' | 'large';
