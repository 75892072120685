import { Provider } from '@angular/core';
import { MatterportOauthService } from '@simOn/matterport/auth/services';
import { MatterportComponentController } from '@simOn/matterport/viewer/tokens';
import { AssetComponentsControllerService } from '../controllers-services/asset-components-controller.service';
import { SceneComponentsControllerService } from '../controllers-services/scene-components-controller.service';
import { TicketComponentControllerService } from '../controllers-services/ticket-component-controller.service';
import { WidgetControllerService } from '../controllers-services/widget-controller.service';

export function provideMatterport(): Provider[] {
  return [
    AssetComponentsControllerService,
    SceneComponentsControllerService,
    TicketComponentControllerService,
    WidgetControllerService,
    MatterportOauthService,
    {
      provide: MatterportComponentController,
      deps: [
        AssetComponentsControllerService,
        SceneComponentsControllerService,
        TicketComponentControllerService,
        WidgetControllerService
      ],
      useFactory: (
        asset: AssetComponentsControllerService,
        scene: SceneComponentsControllerService,
        ticket: TicketComponentControllerService,
        widget: WidgetControllerService
      ) => {
        return [asset, widget, scene, ticket];
      }
    }
  ];
}
