import { createAction, props } from '@ngrx/store';
import { UserFullAccessMapped } from '@simOn/privileges/element/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const loadPrivileges = createAction('[Privileges] Load Privileges');

export const loadPrivilegesSuccess = createAction(
  '[Privileges] Load Privileges Success',
  props<{ data: UserFullAccessMapped }>()
);

export const loadPrivilegesFailure = createAction('[Privileges] Load Privileges Failure', props<{ error: any }>());
export const ClearPrivilegesState = CLEAR_STATE_ACTION('Privileges');
