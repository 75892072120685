<div class="device-slider-container">
  <div class="device-slider-box">
    <div class="device-slider-value" *ngIf="showValue">
      <span [ngStyle]="{ 'font-size': unit && unit.length > 1 ? 'x-small' : 'inherit' }"
        >{{ slider?.value | number : '1.1-1' }}{{ unit }}</span
      >
    </div>
    <div class="slider-container" (mousedown)="isSliding = true" (mouseup)="isSliding = false">
      <mat-slider class="device-slider" fxFlex [min]="min()" [max]="max()" [step]="step()" color="accent">
        <input matSliderThumb #slider [formControl]="sliderFormControl" />
      </mat-slider>
    </div>
  </div>
</div>
