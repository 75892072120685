import { Injectable, OnDestroy } from '@angular/core';
import { ISubscription } from '../../../assets/bundle/sdk';
import { MatterportServiceBase } from '../base/matterport-base';
import { MatterportManagerService } from './matterport-manager.service';
import { MatterportTagsService } from './matterport-tags.service';

@Injectable()
export class MatterportSceneInitService
  extends MatterportServiceBase
  implements OnDestroy
{
  private _tagsSub!: ISubscription;
  constructor(
    private readonly matterportService: MatterportManagerService,
    private readonly tagsService: MatterportTagsService
  ) {
    super(matterportService);
  }

  override ngOnDestroy(): void {
    this._tagsSub?.cancel();
    super.ngOnDestroy();

    // console.log('MatterportSceneInitService - destroy');
  }

  protected _init(): void {
    if (!this.matterportService.showMattertags) {
      this._tagsSub = this.sdk.Mattertag.data.subscribe({
        onAdded: (index: string) => {
          if (!Object.values(this.tagsService.sceneNotes).includes(index)) {
            this.sdk.Mattertag.remove(index);
          }
        },
      });
    }
  }
}
