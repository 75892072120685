import { Injectable, InjectionToken, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UsersApiService } from '@simOn/user/preferences/data-access';
import { CreateUserProfileInterface, UserProfileInterface } from '@simOn/user/preferences/model';
import { Localstorage } from '@simOn/utils/localstorage';
import { BASE_URL } from '@simOn/utils/tokens';

import { BehaviorSubject, map } from 'rxjs';

export const IdentityUrl = new InjectionToken<string>('Identity url');

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private router = inject(Router);
  private identityUrl = inject(IdentityUrl);
  private userService = inject(UsersApiService);
  private originUrl = inject<string>(BASE_URL);
  backToNavigationUrl = new Localstorage('BackToNavigationUrl').load();

  public messages: string[] = [];

  createUserProfile!: CreateUserProfileInterface;
  userProfile$ = new BehaviorSubject<UserProfileInterface | null>(null);

  getUserEula(userGuid: string) {
    this.userService.getUserEnsureProfile(userGuid).subscribe((userProfile: UserProfileInterface) => {
      this.userProfile$.next(userProfile);

      if (userProfile.profileExists === false) {
        this.router.navigate(['eula'], { queryParams: { createUser: 'createUser' } }).then();
      } else if (userProfile.profileExists === true && userProfile.eulaAccepted === false) {
        this.router.navigate(['eula']).then();
      } else {
        const backToNavigationUrl = this.getBackToNavigationUrl();
        if (backToNavigationUrl) {
          this.router.navigateByUrl(backToNavigationUrl).then(
            () => {
              this.clearBackToNavigationUrl();
            },
            (error) => {
              this.clearBackToNavigationUrl();
              this.router.navigate(['/split-view/apartment-selection']).then();
            }
          );
        } else {
          this.router.navigate(['/split-view/apartment-selection']).then();
        }
      }
    });
  }

  register() {
    document.location.href = this.identityUrl + '/account/register?returnurl=' + this.originUrl;
  }

  simRegister() {
    return window.open(this.identityUrl + '/Account/Register?returnUrl=https://app.sim-on.com', '_self');
  }
  checkUserProfile(userGuid: string) {
    return this.userService.getUserEnsureProfile(userGuid).pipe(
      map((userProfile: UserProfileInterface) => {
        this.userProfile$.next(userProfile);
        return userProfile;
      })
    );
  }

  public clearMessages() {
    while (this.messages.length) {
      this.messages.pop();
    }
  }

  public addMessage(msg: string) {
    this.messages.push(msg);
  }

  public addError(msg: string | any) {
    this.messages.push('Error: ' + msg && msg.message);
  }

  private getBackToNavigationUrl(): string {
    return this.backToNavigationUrl.value as string;
  }

  private clearBackToNavigationUrl() {
    this.backToNavigationUrl.set(null);
  }
}
