import * as THREE from 'three';
import { BufferGeometry, Euler, Material, Mesh, Vector3 } from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { PlaneConfiguration } from '../..';
import { DeviceCanvasIconTexture } from '../helpers/canvas.render.helper';
import { ThreeObjectBase } from './three-object.base';
export class BlueprintComponent extends ThreeObjectBase {
  private _mesh!: Mesh;
  constructor(
    private readonly three: typeof THREE & { SVGLoader: typeof SVGLoader },
    private readonly configuration: PlaneConfiguration['config']
  ) {
    super();
    this.init(three, configuration);
  }
  init(threeContext: any, configuration: any): void {
    this._mesh = this.createObject(threeContext);
    this._texture = this.configuration.texture;
    this.rotation = new Euler(0, 0, 0);
    this._mesh.layers.set(3);

    configuration.position ?? ({ x: 0, y: 0, z: 0 } as Vector3);
  }

  private _getTexture(
    blueprintPath: PlaneConfiguration['config']['texture']
  ): Promise<THREE.Texture> {
    const image = new Image();
    return new Promise((resolve) => {
      if (image) {
        image.onload = (e) => {
          const canvasTextureIcon = new DeviceCanvasIconTexture(
            image,
            'rgba(255,255,255, 1)',
            4096,
            4096
          );
          const texture = new THREE.CanvasTexture(canvasTextureIcon.ctx.canvas);
          texture.minFilter = THREE.NearestFilter;
          texture.encoding = THREE.sRGBEncoding;
          resolve(texture);
        };
      }
      image.src = blueprintPath;
    });
  }
  private set _texture(blueprintPath: string) {
    (this._mesh.material as THREE.MeshBasicMaterial).dispose();
    const material = this._mesh.material as THREE.MeshBasicMaterial;
    this._getTexture(blueprintPath).then((texture: THREE.Texture) => {
      texture.minFilter = THREE.NearestFilter;

      texture.minFilter = THREE.NearestMipmapNearestFilter;
      material.map = texture;
      material.needsUpdate = true;
      this.configuration.texture = blueprintPath;
    });
  }

  set texture(blueprintPath: string) {
    if (blueprintPath !== this.configuration.texture) {
      this._texture = blueprintPath;
    }
  }

  private createObject(threeContext: typeof THREE): THREE.Mesh {
    const geometry = new threeContext.PlaneGeometry(1, 1);

    const canvasTextureIcon = new DeviceCanvasIconTexture(
      new Image(),
      'rgba(255,255,255, 1)',
      4096,
      4096
    );
    const texture = new THREE.CanvasTexture(canvasTextureIcon.ctx.canvas);
    texture.encoding = THREE.sRGBEncoding;
    const material = new threeContext.MeshBasicMaterial({
      map: texture,
      alphaTest: 0.2,
      polygonOffset: false,
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      side: THREE.FrontSide,
      toneMapped: false,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    });
    material.stencilWrite = true;
    material.stencilZPass = THREE.ReplaceStencilOp;
    return new threeContext.Mesh(geometry, material);
  }
  set position(position: Vector3) {
    throw new Error('Method not implemented.');
  }
  set scale(position: Vector3) {
    const { x, y, z } = position;
    this._mesh.scale.set(x, y, z);
  }
  set rotation(rotation: THREE.Euler) {
    const { x, y, z } = rotation;
    this._mesh.rotation.set(x, y, z);
  }
  show(): void {
    throw new Error('Method not implemented.');
  }
  hide(): void {
    throw new Error('Method not implemented.');
  }
  get object3D(): Mesh<BufferGeometry, Material | Material[]> {
    return this._mesh;
  }
}
