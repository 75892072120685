import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, inject } from '@angular/core';
import { BreakpointService } from '@simOn/ui/breakpoint';
import { SimColorPickerDirective } from './sim-color-picker.directive';
let uniqueIdCounter = 0;
@Component({
  selector: 'sim-color-picker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sim-color-picker.component.html',
  styleUrls: ['./sim-color-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-color-picker',
    '[id]': 'id'
  }
})
export class SimColorPickerComponent extends SimColorPickerDirective {
  private readonly breakpointService = inject(BreakpointService);
  @Input() id = `sim-color-picker-${uniqueIdCounter++}`;
  readonly isMobile$ = this.breakpointService.isMobile$;
  constructor() {
    super();
  }
}
