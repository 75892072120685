import { Pipe, PipeTransform } from '@angular/core';
import { AssetsImage } from '@simOn/ui/sim-footer';

@Pipe({
  name: 'imagePath',
  standalone: true
})
export class ImagePathPipe implements PipeTransform {
  transform(name: AssetsImage): string {
    return `./assets/images/logo/${name}`;
  }
}
