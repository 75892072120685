//TODO: should it throw on timeout?
//await waitUntil(_ =>  this._isOpening === true,timeoutInMS);
/**
 * @deprecated
 */
export function waitUntil(
  conditionFunction: any,
  message = 'msgNotSet',
  timeoutInMs = 9000
) {
  let stopByTimeout = false;
  setTimeout(() => {
    stopByTimeout = true;
  }, timeoutInMs);
  const poll = (resolve: any) => {
    if (conditionFunction()) resolve();
    else {
      if (stopByTimeout) {
        resolve();
      } else setTimeout(() => poll(resolve), 40);
    }
  };
  return new Promise(poll);
}
