export enum ErrorCode {
  'NOT_LOADED' = 'NOT_LOADED'
}
export type Languages  ="en" | "pl" | "cs" | "sk"
export type LocalizationMessage = {
  [lang in Languages]: {[error in ErrorCode]: string}
}

export const LOCALIZATION:LocalizationMessage = {
  pl: {
    NOT_LOADED: 'Sdk nie jest zainicjalizowane lub trwa inna transakcja'
  },
  en: {
    NOT_LOADED: 'Sdk is not initialize or another transaction is in progress'
  },
  cs:{
    NOT_LOADED: 'not ready yet'
  },
  sk:{
    NOT_LOADED: 'not ready yet'
  },
}
