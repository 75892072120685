import * as THREE from 'three';
import { Vector3 } from 'three';
import { SpriteConfiguration } from '../models/custom-component.type';
import { ThreeObjectBase } from './three-object.base';

export class SpriteComponent extends ThreeObjectBase {
  set rotation(position: THREE.Euler) {
    throw new Error('Method not implemented.');
  }
  private _sprite!: THREE.Sprite;
  constructor(
    private readonly three: typeof THREE,
    private readonly configuration: SpriteConfiguration['config']
  ) {
    super();
    this.init(three, configuration);
  }

  init(
    threeContext: typeof THREE,
    configuration: SpriteConfiguration['config']
  ): void {
    this._sprite = this._createObject(threeContext);

    this._icon = this.configuration.icon;
    this._sprite.layers.set(3);
    configuration.position ?? ({ x: 0, y: 0, z: 0 } as Vector3);
  }

  private set _icon(iconPath: string) {
    const map = this._loadMaterial(iconPath);
    map.encoding = THREE.sRGBEncoding;
    const material = this._sprite.material as THREE.SpriteMaterial;
    if (material.dispose) material.dispose();
    material.map = map;
    material.needsUpdate = true;
    this.configuration.icon = iconPath;
  }

  set icon(iconPath: string) {
    if (iconPath !== this.configuration.icon) {
      this._icon = iconPath;
    }
  }

  private _createObject(threeContext: typeof THREE): THREE.Sprite {
    const material = new threeContext.SpriteMaterial({
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      alphaTest: 0.2,
      toneMapped: false,
    });
    material.stencilWrite = true;
    material.stencilZPass = THREE.ReplaceStencilOp;
    return new threeContext.Sprite(material);
  }
  get object3D(): any {
    return this._sprite;
  }
  set position(position: Vector3) {
    this._sprite.position.set(position.x, position.y, position.z);
  }
  set scale(scale: Vector3) {
    const { x, y, z } = scale;
    this._sprite.scale.set(x, y, z);
  }

  hide() {
    if (this._sprite.visible) {
      this._sprite.visible = false;
    }
  }

  show() {
    if (!this._sprite.visible) {
      this._sprite.visible = true;
    }
  }

  private _loadMaterial(iconPath: string) {
    return (new this.three.TextureLoader() as THREE.TextureLoader).load(
      iconPath
    );
  }
}
