import { Quaternion } from './quaternion';
import { Vector } from './vector';

export class Transform {
  static get default(): Transform {
    return <Transform>{
      position: new Vector(0, 0, 0),
      rotation: new Quaternion(0, 0, 0, 1),
      scale: new Vector(1, 1, 1),
    };
  }

  readonly position: Vector;
  readonly rotation: Quaternion;
  readonly scale: Vector;

  constructor(deserialized: any) {
    if (
      typeof deserialized !== 'object' ||
      !('position' in deserialized) ||
      !('rotation' in deserialized) ||
      !('scale' in deserialized)
    ) {
      throw new Error('invalid parameters!');
    }

    this.position = Object.assign(new Vector(), deserialized.position);
    this.rotation = Object.assign(new Quaternion(), deserialized.rotation);
    this.scale = Object.assign(new Vector(), deserialized.scale);
  }
}
