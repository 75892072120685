import { Injectable, OnDestroy, inject } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { Asset } from '@simOn/asset';
import { NotificationService } from '@simOn/common/notification';
import { Devices } from '@simOn/device';
import { Room } from '@simOn/room';
import { Scene } from '@simOn/scene';
import { Space, UploadFileWithProgressFailure, UploadFileWithProgressSuccess } from '@simOn/space';
import { UserPreferences } from '@simOn/user';
import { ErrorMessages } from '@simOn/utils';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

const TOAST_INFO = {
  uploadError: $localize`:@@TOASTS_UPLOAD_ERROR:Failed to upload!`,
  addSuccess: $localize`:@@TOASTS_ADD_SUCCESS:Added successfully!`,
  updatePreferencesError: $localize`:@@TOASTS_UPDATE_USER_PREFERENCES_ERROR:Can't update User Preferences! Error occurred`,
  fetchDevicesToImportError: $localize`:@@TOASTS_FETCH_DEVICES_TO_IMPORT_ERROR:Can't fetch devices to import! Error occurred`,
  devicesImportError: $localize`:@@TOASTS_IMPORT_DEVICES_ERROR:Devices import failed!`,
  fetchSpaceInfoError: $localize`:@@TOASTS_FETCH_SPACE_INFO_ERROR:Can't fetch space info! Error occurred`,
  updateSpaceInfoError: $localize`:@@TOASTS_UPDATE_SPACE_INFO_ERROR:Can't update space info! Error occurred`,
  removeSpaceError: $localize`:@@TOASTS_REMOVE_SPACE_ERROR:Can't remove space! Error occurred`,
  addNewRoomError: $localize`:@@TOASTS_ADD_NEW_ROOM_ERROR:Can't add new room! Error occurred`,
  updateRoomError: $localize`:@@TOASTS_UPDATE_ROOM_ERROR:Can't update room! Error occurred`,
  deleteRoomError: $localize`:@@TOASTS_DELETE_ROOM_ERROR:Can't delete room! Error occurred`,
  addSceneTagError: $localize`:@@TOASTS_ADD_SCENE_TAG_ERROR:Can't add scenes's tag! Error occurred`,
  updateSceneTagError: $localize`:@@TOASTS_UPDATE_SCENE_TAG_ERROR:Can't update scenes's tag! Error occurred`,
  deleteSceneTagError: $localize`:@@TOASTS_DELETE_SCENE_TAG_ERROR:Can't delete scenes's tag! Error occurred`
};

@Injectable({
  providedIn: 'root'
})
export class StoreNotificationService implements OnDestroy {
  private readonly action$ = inject(ActionsSubject);
  private readonly notificationService = inject(NotificationService);
  private readonly _destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  init(): void {
    this.action$.pipe(ofType(Asset.AddAssetFailure), takeUntil(this._destroy$)).subscribe((action) => {
      this.sendPostNotifications(action);
    });

    this.action$
      .pipe(
        ofType(
          Asset.UploadHyperlinksFailure,
          UploadFileWithProgressFailure,
          Asset.UploadEventsSimplifiedFailure,
          Asset.UploadFileToBlobFailure
        ),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.notificationService.openErrorSnackBar(TOAST_INFO.uploadError);
      });

    this.action$
      .pipe(
        ofType(Asset.UploadHyperlinksSuccess, UploadFileWithProgressSuccess, Asset.UploadEventsSimplifiedSuccess),
        takeUntil(this._destroy$)
      )
      .subscribe(() => {
        this.notificationService.openSuccessSnackBar(TOAST_INFO.addSuccess);
      });

    this.action$
      .pipe(ofType(UserPreferences.UpdateUserPreferencesFailure), takeUntil(this._destroy$))
      .subscribe((action) => {
        this.notificationService.openErrorSnackBar(TOAST_INFO.updatePreferencesError);
      });

    this.action$
      .pipe(
        ofType(Scene.AddShortcutSceneFailure, Scene.UpdateShortcutSceneFailure, Scene.DeleteShortcutSceneFailure),
        takeUntil(this._destroy$)
      )
      .subscribe((action) => {
        this.sendShortCutScene(action);
      });

    this.action$
      .pipe(ofType(Room.AddRoomFailure, Room.UpdateRoomFailure, Room.DeleteRoomFailure), takeUntil(this._destroy$))
      .subscribe((action) => {
        this.roomNotification(action);
      });

    this.action$
      .pipe(
        ofType(Space.GetApartmentShortFailure, Space.UpdateApartmentFailure, Space.DeleteApartmentFailure),
        takeUntil(this._destroy$)
      )
      .subscribe((action) => {
        this.apartmentNotification(action);
      });

    this.action$.pipe(ofType(Devices.GetNotAssignedDevicesFailure), takeUntil(this._destroy$)).subscribe((action) => {
      this.notificationService.openErrorSnackBar(TOAST_INFO.fetchDevicesToImportError);
    });
    this.action$
      .pipe(
        ofType(Devices.AddDevicesFailure),
        tap(() => {
          this.notificationService.openErrorSnackBar(TOAST_INFO.devicesImportError);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  private apartmentNotification(action): void {
    switch (action.type) {
      case Space.GetApartmentShortFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.fetchSpaceInfoError);
        break;

      case Space.UpdateApartmentFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.updateSpaceInfoError);
        break;

      case Space.DeleteApartmentFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.removeSpaceError);
        break;
    }
  }

  private roomNotification(action): void {
    switch (action.type) {
      case Room.AddRoomFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.addNewRoomError);
        break;

      case Room.UpdateRoomFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.updateRoomError);
        break;

      case Room.DeleteRoomFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.deleteRoomError);
        break;
    }
  }

  private sendPostNotifications(action): void {
    switch (action.type) {
      case Asset.AddAssetFailure.type:
        this.notificationService.openErrorSnackBar(ErrorMessages['default']);
        break;
    }
  }

  private sendShortCutScene(action): void {
    switch (action.type) {
      case Scene.AddShortcutSceneFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.addSceneTagError);
        break;

      case Scene.UpdateShortcutSceneFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.updateSceneTagError);
        break;

      case Scene.DeleteShortcutSceneFailure.type:
        this.notificationService.openErrorSnackBar(TOAST_INFO.deleteSceneTagError);
        break;
    }
  }
}
