import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { CommandValuesType, paramJSON } from '@simOn/device/models';
import { RGBWValueParameter, SimColorPickerComponent } from '@simOn/ui/sim-color-picker';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule, SimColorPickerComponent, OverlayModule],
  template: `<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayHasBackdrop
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayOpen]="isOpen"
    (overlayOutsideClick)="isOpen = false"
    cdkConnectedOverlayPanelClass="color-picker-mobile"
    cdkConnectedOverlayLockPosition
    cdkConnectedOverlayBackdropClass="color-picker-backdrop"
  >
    <sim-color-picker (selectedColor)="selectedColor = $event"></sim-color-picker>
  </ng-template> `,
  styleUrls: ['./rgbw.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [CdkOverlayOrigin],
  host: {
    class: 'sim-rgbw'
  }
})
export class RGBWComponent extends PropertyBase<'RgbwColor', RGBWValueParameter, RGBWValueParameter> {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly el = inject(ElementRef);

  valueToCommand(params: RGBWValueParameter): { command: keyof CommandValuesType; parameters: string | number } {
    return {
      command: 'SetRgbwColor',
      parameters: JSON.stringify(this.valueToParameter(params))
    };
  }
  commandToValue(params: {
    command: keyof CommandValuesType;
    parameters: string | number | RGBWValueParameter;
  }): RGBWValueParameter {
    const paramToJson = paramJSON(params.parameters);
    return paramToJson;
  }
  private _isOpen = false;
  readonly origin: CdkOverlayOrigin = new CdkOverlayOrigin(this.el);

  set isOpen(value: boolean) {
    this._isOpen = value;
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  set propertyValue(value: RGBWValueParameter) {
    this.propertyDefinition = {
      ...this.propertyDefinition,
      values: {
        ...this.propertyDefinition.values,
        ...value
      }
    };
    this.partialCommand$.emit(this.valueToCommand(value));
  }

  get propertyValue(): RGBWValueParameter {
    return this.propertyDefinition.values;
  }

  get propertyValueAsStyle(): string {
    const { r, g, b, w } = this.propertyValue;
    return `rgb(${r},${g},${b})`;
  }

  set selectedColor(value: RGBWValueParameter) {
    if (this.isOpen) {
      this.isOpen = false;
      this.propertyValue = value;
    }
  }

  @HostBinding('style.--rgbw-background-color') get color() {
    return this.propertyValueAsStyle;
  }

  @HostListener('click')
  onClick() {
    this.isOpen = true;
  }

  valueToParameter(params: RGBWValueParameter): RGBWValueParameter {
    return { ...params };
  }
}
