import { NgModule } from '@angular/core';
import { UiHelperModule } from '@simOn/ui/ui-helper';
import { AnchorComponent } from './components/anchor/anchor.component';
import { ButtonLoaderComponent, SimButtonLoaderModule } from './components/button/button-loader.component';
import { ButtonComponent } from './components/button/button.component';
import { DirectionDirective } from './directives/direction.directive';
import { FireOnClickDirective } from './directives/fire-on-click.directive';

@NgModule({
  declarations: [ButtonComponent, AnchorComponent, FireOnClickDirective, DirectionDirective],
  imports: [UiHelperModule, SimButtonLoaderModule],
  exports: [
    ButtonComponent,
    AnchorComponent,
    ButtonLoaderComponent,
    FireOnClickDirective,
    DirectionDirective,
    UiHelperModule
  ]
})
export class SimButtonModule {}
