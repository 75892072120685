import { AnimationTriggerMetadata, animate, state, style, transition, trigger } from '@angular/animations';

export const wakeUpAnimation: AnimationTriggerMetadata = trigger('wakeUp', [
  state(
    'initial',
    style({
      transform: 'rotate(0deg) scale(1)'
    })
  ),
  state(
    'final',
    style({
      transform: 'rotate(720deg) scale(2)'
    })
  ),
  transition('initial <=> final', animate('3500ms ease-in-out'))
]);
