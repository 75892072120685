import { Injectable, OnDestroy } from '@angular/core';
import {
  Observable,
  Subject,
  debounceTime,
  defer,
  filter,
  first,
  firstValueFrom,
  fromEvent,
  switchMap,
} from 'rxjs';
import { Vector3 } from 'three';
import { MatterportServiceBase } from '../base/matterport-base';
import { Pointer } from '../models/dto';
import { IMatterportEvents } from '../models/events.interface';
const LONGPRESS_THRESHOLD = 200;

@Injectable()
export class MatterportEventsService
  extends MatterportServiceBase
  implements OnDestroy, IMatterportEvents
{
  private readonly _hold: Subject<Pointer> = new Subject<Pointer>();
  readonly matterportClick$ = defer(() => {
    return this.isOpen.pipe(
      filter((isOpen) => isOpen),
      switchMap(() =>
        fromEvent(this.renderer.domElement, 'pointerdown').pipe(
          switchMap((pointerDownEvenet) => {
            return fromEvent(this.renderer.domElement, 'pointerup').pipe(
              filter(
                (pointerUpEvent) =>
                  pointerUpEvent.timeStamp - pointerDownEvenet.timeStamp <
                  LONGPRESS_THRESHOLD
              ),
              debounceTime(100),
              switchMap(() => {
                return this._pointer.pipe(first());
              })
            );
          })
        )
      )
    );
  });

  readonly matterportDetectClick$ = defer(() => {
    return this.isOpen.pipe(
      filter((isOpen) => isOpen),
      switchMap(() =>
        fromEvent(this.renderer.domElement, 'pointerdown').pipe(
          switchMap(() => this._pointer.pipe(first()))
        )
      )
    );
  });

  readonly _pointer: Observable<Pointer> = new Observable<Pointer>(
    (subscriber) => {
      const pointerSubRef = this.sdk.Pointer.intersection.subscribe(
        (intersectionData) => {
          const pointer: Pointer = {
            normal: new Vector3(
              intersectionData.normal.x,
              intersectionData.normal.y,
              intersectionData.normal.z
            ),
            position: new Vector3(
              intersectionData.position.x,
              intersectionData.position.y,
              intersectionData.position.z
            ),
            object: intersectionData.object,
          };
          subscriber.next(pointer);
        }
      );
      return function unsubscribe() {
        pointerSubRef.cancel();
      };
    }
  );
  readonly pointer$ = defer(() =>
    this.isOpen.pipe(
      filter((isOpen) => isOpen),
      switchMap(() => this._pointer)
    )
  );
  readonly matterportHold$ = this._hold.asObservable();
  protected _init(): void {}

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
  fromMatterportEvent<T>(eventName: string): Observable<T> {
    return this.isOpen.pipe(
      filter((isOpen) => isOpen),
      switchMap(() => {
        return fromEvent<T>(this.renderer.domElement, eventName);
      })
    );
  }
  async emitValue() {
    const pointer = await firstValueFrom(this._pointer);
    this._hold.next(pointer);
  }
}
