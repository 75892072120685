import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo } from 'apollo-angular';
import { catchError, from, map, Observable, of } from 'rxjs';
import { BYPASS_LOG } from '../interceptors/authorization-token.interceptor';
import {
  GetModelTagsQuery,
  GetViewsQuery,
  GetVisibilityQuery,
  MatterportPrefetchData,
  MatterportTagsDocument,
  MatterportVisibility,
  ModelsDocument,
  ModelSort,
  ModelsQuery,
  ModelSweep,
  ModelSweeps,
} from '../models/graph_queries';
import {
  AuthorizationResponse,
  MatterportApi,
  ModelAccess,
} from '../models/matterport-api';
import { MatterportApiConfig } from '../models/matterport-api-config';

@Injectable()
export class MatterportApiService implements MatterportApi {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly apollo: Apollo,
    private readonly matterportApiConfig: MatterportApiConfig
  ) {}
  getModels$(
    query?: string,
    offset?: string,
    sortBy?: ModelSort
  ): Observable<ApolloQueryResult<ModelsQuery>> {
    return this.apollo.query<ModelsQuery>({
      query: ModelsDocument,
      variables: {
        query,
        offset,
        sortBy,
      },
    });
  }

  getTags$(id: string): Observable<ApolloQueryResult<GetModelTagsQuery>> {
    return this.apollo.watchQuery<GetModelTagsQuery>({
      query: MatterportTagsDocument,
      variables: {
        id,
      },
    }).valueChanges;
  }
  authorize$(code: string): Observable<AuthorizationResponse> {
    return this.httpClient.post<AuthorizationResponse>(
      `${this.matterportApiConfig.serverApiUrl}`,
      { code },
      { context: new HttpContext().set(BYPASS_LOG, true) }
    );
  }

  clearCache$() {
    return from(this.apollo.client.clearStore());
  }

  getModelViews$(id: string) {
    return this.apollo.query<GetViewsQuery>({
      query: MatterportPrefetchData,
      variables: {
        id,
      },
    });
  }
  getScanVisibility$(
    id: string
  ): Observable<ApolloQueryResult<GetVisibilityQuery>> {
    return this.apollo.query<GetVisibilityQuery>({
      query: MatterportVisibility,
      variables: {
        id,
      },
    });
  }

  /**
   * Returns altered panoLocation (x, y, z) -> (x, z, -y)
   * @param id scanId
   * @returns
   */
  getModelSweeps$(id: string): Observable<Array<ModelSweep>> {
    return this.apollo
      .query({
        query: ModelSweeps,
        variables: {
          id,
        },
      })
      .pipe(
        map((response: any) =>
          response.data.model.locations.map(
            (item: any) =>
              <ModelSweep>{
                id: item.pano.sweepUuid,
                //NOTE: we remap the position for our needs
                position: {
                  x: item.pano.position.x,
                  y: item.pano.position.z,
                  z: -item.pano.position.y,
                },
              }
          )
        )
      );
  }

  getModelAccess$(scanId: string): Observable<ModelAccess | never> {
    return this.httpClient
      .get(`https://my.matterport.com/api/v1/scanmodel/${scanId}/`)
      .pipe(
        map(() => ModelAccess.Public),
        catchError((response: any) => {
          if (response.status === 403 && 'code' in response.error) {
            if (response.error.code === 'user.unauthorized') {
              return of(ModelAccess.Private);
            }

            if (response.error.code === 'object.access.token.required') {
              return of(ModelAccess.Protected);
            }
          }

          throw new Error('Unknown scenario!');
        })
      );
  }

  // refreshToken$() {
  //   const code = sessionStorage.getItem('matterport-code');
  //   return this.httpClient.get<any>(
  //     `${this.matterportApiConfig.matterportApiUrl}/refresh?code=${code}`,
  //     { context: new HttpContext().set(BYPASS_LOG, true) }
  //   );
  // }
}
