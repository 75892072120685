<common-upload-image
  [id]="id"
  #upload
  (file)="uploadFile($event)"
  (uploadError)="showError($event)"
  [uploadDisabled]="uploadDisabled || (isLoading$ | async)!"
  (drop)="dropFile($event)"
  class="sim-place-image"
>
  <div *ngIf="!uploadDisabled" class="select-image" [ngStyle]="{ opacity: (isLoading$ | async) ? '0' : '1' }">
    <sim-icon sizeType="mega" color="white" icon="image"></sim-icon>
    <div i18n="@@GENERAL_DRAG_AND_DROP_IMAGE" class="center-description">Drag And Drop Image</div>
  </div>
  <ng-container *ngIf="uploadDisabled || (isLoading$ | async) === false; else spinner">
    <img
      class="sim-place-image"
      [src]="image ? image : imageUrl || defaultImageUrl"
      [ngClass]="{ 'dark-hover': upload?.value }"
      [ngClass]="uploadDisabled ? 'clear-filter' : 'dark'"
    />
  </ng-container>
</common-upload-image>

<ng-template #spinner>
  <sim-spinner></sim-spinner>
</ng-template>
