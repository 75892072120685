import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type DisplayContent = 'primary' | 'secondary';
@Component({
  selector: 'common-split-container',
  templateUrl: './sim-split-container.component.html',
  styleUrls: ['./sim-split-container.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimSplitContainerComponent implements OnDestroy, OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly _destroy$: Subject<void> = new Subject<void>();
  smallSizeDisplayContent: DisplayContent = 'secondary';

  ngOnInit(): void {
    this._routeObserver();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
  private _routeObserver() {
    this.route.queryParams.pipe(takeUntil(this._destroy$)).subscribe((params: Params) => {
      if (params['content']) {
        this.smallSizeDisplayContent = params['content'];
      }
    });
  }
}
