import { SmartApiProviderEnum } from '@simOn/common/providers';

export type SimlabErrorDto = {
  ErrorCode: ProblemType;
  HttpStatusCode: number;
  Messages: Array<string>;
  SmartApiProviderId: SmartApiProviderEnum;
  ThirdPartyApiProviderId: ThirdPartyApiProviderEnum;
  AuthorizationProvider: AuthorizationProviderEnum;
};

export const ProviderErrorType = {
  SmartApiProblem: 'SmartApiProblem', //y
  SmartApiAuthorization: 'SmartApiAuthorization', //y
  SmartApiTimeout: 'SmartApiTimeout', //y
  SmartApiAuthorizationProviderLoggedOut: 'SmartApiAuthorizationProviderLoggedOut',
  SmartApiAuthorizationProviderNoAuthorization: 'SmartApiAuthorizationProviderNoAuthorization'
} as const;

export enum ProblemType {
  Unknown = 'Unknown', //y
  BadGateway = 'BadGateway', //y
  InsufficientPermissions = 'InsufficientPermissions',
  ThirdPartyApiProblem = 'ThirdPartyApiProblem', //y
  InvalidCommandArguments = 'InvalidCommandArguments',
  PublicLinkExecuteCommandNotAllowed = 'PublicLinkExecuteCommandNotAllowed',

  InternalServerProblem = 'InternalServerProblem',
  SystemMaintenance = 'SystemMaintenance',
  MissingApartmentIdHeader = 'MissingApartmentIdHeader',
  ApartmentLimitReached = 'ApartmentLimitReached',
  ByteLimitReached = 'ByteLimitReached',
  DeviceLimitReached = 'DeviceLimitReached',
  SceneLimitReached = 'SceneLimitReached',
  UserLimitReached = 'UserLimitReached',
  ApartmentBlockedBySubscriptionLimit = 'ApartmentBlockedBySubscriptionLimit',
  UserBlockedBySubscriptionLimit = 'UserBlockedBySubscriptionLimit',
  SceneBlockedBySubscriptionLimit = 'SceneBlockedBySubscriptionLimit',
  DeviceBlockedBySubscriptionLimit = 'DeviceBlockedBySubscriptionLimit',

  WeatherNotAvailable = 'WeatherNotAvailable',
  InvalidSceneTrigger = 'InvalidSceneTrigger',

  MaximoError = 'MaximoError',
  MaximoReadOnlyElementError = 'MaximoReadOnlyElementError'
}

export enum ThirdPartyApiProviderEnum {
  None = 'None',
  AzureBlobStorage = 'AzureBlobStorage',
  OpenWeather = 'OpenWeather'
}

export enum AuthorizationProviderEnum {
  NONE = 'NONE',
  FIBARO = 'FIBARO',
  GOOGLE = 'GOOGLE',
  KNX = 'KNX',
  SAMSUNG_ST = 'SAMSUNG_ST'
}

export const RETRY_ERROR_CODE = [
  ProblemType.Unknown,
  ProviderErrorType.SmartApiProblem,
  ProblemType.BadGateway,
  ProblemType.ThirdPartyApiProblem,
  ProviderErrorType.SmartApiAuthorization,
  ProviderErrorType.SmartApiTimeout
];
