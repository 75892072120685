import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import {
  ApartmentAuthorizationConnectionRequestInterface,
  ApartmentAuthorizationConnectionsRequestInterface,
  IAuthorizationDeletionData,
  ProviderConnectionsStatus,
  SupportedProviderDisplayConfig,
  TryDeleteApartmentAuthorizationResponseInterface
} from '@simOn/provider/connection/models';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProvidersApiService {
  private readonly http = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getSupportedProviders(apartmentId?: string): Observable<SupportedProviderDisplayConfig<SmartApiProviderEnum>[]> {
    return this.http.get<SupportedProviderDisplayConfig<SmartApiProviderEnum>[]>(
      `${this._apiUrl}/Providers/GetSupportedProviders${apartmentId ? '?apartmentId=' + apartmentId : ''}`
    );
  }

  addApartmentAuthorizations(data: ApartmentAuthorizationConnectionsRequestInterface): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Providers/AddApartmentAuthorizations`, data);
  }

  getProviderConnectionStatus(params: HttpParams, controller: string): Observable<ProviderConnectionsStatus> {
    return this.http.get<ProviderConnectionsStatus>(`${this._apiUrl}/${controller}/GetProviderConnectionStatus`, {
      params
    });
  }

  tryDeleteApartmentAuthorization(
    tryDeleteApartmentAuthorizationRequest: ApartmentAuthorizationConnectionRequestInterface[]
  ): Observable<TryDeleteApartmentAuthorizationResponseInterface> {
    return this.http.post<TryDeleteApartmentAuthorizationResponseInterface>(
      `${this._apiUrl}/Providers/TryDeleteApartmentAuthorizations`,
      { apartmentAuthorizations: tryDeleteApartmentAuthorizationRequest }
    );
  }

  forceDeleteApartmentAuthorization(
    tryDeleteApartmentAuthorizationRequest: ApartmentAuthorizationConnectionRequestInterface[]
  ): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/Providers/ForceDeleteApartmentAuthorizations`, {
      apartmentAuthorizations: tryDeleteApartmentAuthorizationRequest
    });
  }

  loginApartmentAuthorization<T>(data: T, controller: string): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/${controller}/loginApartmentAuthorization`, data);
  }

  logoutApartmentAuthorization<T>(data: T, controller: string): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/${controller}/logoutApartmentAuthorization`, data);
  }

  countElementsToBeDeleted<T>(data: T, controller: string): Observable<IAuthorizationDeletionData> {
    return this.http.post<IAuthorizationDeletionData>(`${this._apiUrl}/${controller}/CountElementsToBeDeleted`, data);
  }

  deleteApartmentAuthorization<T>(data: T, controller: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/${controller}/DeleteApartmentAuthorization?${data}`);
  }

  addApartmentAuthorization<T>(data: T, controller: string): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/${controller}/AddApartmentAuthorization`, data);
  }
}
