import { Vector } from './vector';

type TQuaternion = [] | [number, number, number, number] | [Quaternion];
const radToDeg = 180.0 / Math.PI;
const degToRad = Math.PI / 180.0;
const kEpsilon = 0.000001;

export class Quaternion {
  x = 0;
  y = 0;
  z = 0;
  w = 0;

  get eulerAngles(): Vector {
    return this._getEulerAngles(this);
  }

  constructor(...initialValue: TQuaternion) {
    const instance = this._toArray(...initialValue);

    this.x = instance[0];
    this.y = instance[1];
    this.z = instance[2];
    this.w = instance[3];
  }

  /**
   * Performs inversion of given quaternion
   * @param point quaternion
   * @returns inverted quaternion
   */
  static inverse(point: Quaternion): Quaternion {
    return new Quaternion(-point.x, -point.y, -point.z, point.w);
  }

  /**
   * Creates Quaternion from euler angles using YXZ order
   * @param point euler angles
   * @returns quaternion
   */
  static euler(eulerAngles: Vector): Quaternion {
    const point = new Vector(eulerAngles).multiply(degToRad).divide(2);
    const c1 = Math.cos(point.y),
      s1 = Math.sin(point.y),
      c2 = Math.cos(point.x),
      s2 = Math.sin(point.x),
      c3 = Math.cos(point.z),
      s3 = Math.sin(point.z);

    return new Quaternion(
      s1 * c2 * s3 + c1 * s2 * c3,
      s1 * c2 * c3 - c1 * s2 * s3,
      c1 * c2 * s3 - s1 * s2 * c3,
      c1 * c2 * c3 + s1 * s2 * s3
    );
  }

  /**
   * Multiply two quaternions
   * @param rotation quaternion
   * @returns result of quaternions multiplication
   */
  static multiply(rotationA: Quaternion, rotationB: Quaternion): Quaternion {
    return new Quaternion(
      rotationA.w * rotationB.x +
        rotationA.x * rotationB.w +
        rotationA.y * rotationB.z -
        rotationA.z * rotationB.y,
      rotationA.w * rotationB.y +
        rotationA.y * rotationB.w +
        rotationA.z * rotationB.x -
        rotationA.x * rotationB.z,
      rotationA.w * rotationB.z +
        rotationA.z * rotationB.w +
        rotationA.x * rotationB.y -
        rotationA.y * rotationB.x,
      rotationA.w * rotationB.w -
        rotationA.x * rotationB.x -
        rotationA.y * rotationB.y -
        rotationA.z * rotationB.z
    );
  }

  /**
   * Are two quaternions equal to each other?
   * @param rotationA
   * @param rotationB
   * @returns
   */
  static equal(rotationA: Quaternion, rotationB: Quaternion): boolean {
    return Quaternion._equalUsingDot(
      Quaternion.dotProduct(rotationA, rotationB)
    );
  }

  /**
   * The dot product between two rotations.
   * @param rotationA
   * @param rotationB
   */
  static dotProduct(rotationA: Quaternion, rotationB: Quaternion): number {
    return (
      rotationA.x * rotationB.x +
      rotationA.y * rotationB.y +
      rotationA.z * rotationB.z +
      rotationA.w * rotationB.w
    );
  }

  /**
   * Rotate vector by quaternion
   * @param value initial vector
   * @returns vector rotated by quaternion
   */
  static multiplyByVector(rotation: Quaternion, point: Vector): Vector {
    const x = rotation.x * 2;
    const y = rotation.y * 2;
    const z = rotation.z * 2;
    const xx = rotation.x * x;
    const yy = rotation.y * y;
    const zz = rotation.z * z;
    const xy = rotation.x * y;
    const xz = rotation.x * z;
    const yz = rotation.y * z;
    const wx = rotation.w * x;
    const wy = rotation.w * y;
    const wz = rotation.w * z;

    return new Vector(
      (1 - (yy + zz)) * point.x + (xy - wz) * point.y + (xz + wy) * point.z,
      (xy + wz) * point.x + (1 - (xx + zz)) * point.y + (yz - wx) * point.z,
      (xz - wy) * point.x + (yz + wx) * point.y + (1 - (xx + yy)) * point.z
    );
  }

  /**
   * Is the dot product of two quaternions within tolerance for them to be considered equal?
   * @param dot
   * @returns
   */
  private static _equalUsingDot(dot: number): boolean {
    // Returns false in the presence of NaN values.
    return dot > 1.0 - kEpsilon;
  }

  private _toArray(...initialValue: TQuaternion): Array<number> {
    let x = 0,
      y = 0,
      z = 0,
      w = 1;
    if (Array.isArray(initialValue)) {
      if (
        initialValue.length === 1 &&
        typeof initialValue[0] === 'object' &&
        initialValue[0] instanceof Quaternion
      ) {
        x = initialValue[0].x;
        y = initialValue[0].y;
        z = initialValue[0].z;
        w = initialValue[0].w;
      } else if (
        initialValue.length === 4 &&
        typeof initialValue[0] === 'number' &&
        typeof initialValue[1] === 'number' &&
        typeof initialValue[2] === 'number' &&
        typeof initialValue[3] === 'number'
      ) {
        x = initialValue[0];
        y = initialValue[1];
        z = initialValue[2];
        w = initialValue[3];
      } else if (initialValue.length !== 0) {
        throw new Error('Invalid arguments!');
      }
    } else {
      throw new Error('Invalid arguments!');
    }
    return Array<number>(x, y, z, w);
  }

  /**
   * Multiply two quaternions
   * @param rotation quaternion
   * @returns result of quaternions multiplication
   */
  multiply(rotation: Quaternion): Quaternion {
    const tempRotation = new Quaternion(this);

    (this.x =
      tempRotation.w * rotation.x +
      tempRotation.x * rotation.w +
      tempRotation.y * rotation.z -
      tempRotation.z * rotation.y),
      (this.y =
        tempRotation.w * rotation.y +
        tempRotation.y * rotation.w +
        tempRotation.z * rotation.x -
        tempRotation.x * rotation.z),
      (this.z =
        tempRotation.w * rotation.z +
        tempRotation.z * rotation.w +
        tempRotation.x * rotation.y -
        tempRotation.y * rotation.x),
      (this.w =
        tempRotation.w * rotation.w -
        tempRotation.x * rotation.x -
        tempRotation.y * rotation.y -
        tempRotation.z * rotation.z);

    return this;
  }

  /**
   * Returns euler angles from quaternion using YXZ order
   * @param value quaternion
   * @returns return euler angles translated by YXZ order
   */
  private _getEulerAngles(value: Quaternion): Vector {
    const poleSum = value.x * value.w - value.y * value.z;
    if (this._doublesEqual(poleSum, 0.5)) {
      return new Vector(90, 0, 0);
    } else if (this._doublesEqual(poleSum, -0.5)) {
      return new Vector(-90, 0, 0);
    }
    const sqw = value.w * value.w;
    const sqx = value.x * value.x;
    const sqy = value.y * value.y;
    const _x = Math.asin(2 * value.x * value.w - 2 * value.y * value.z);
    const _y = Math.atan2(
      2 * value.x * value.z + 2 * value.y * value.w,
      1 - 2 * sqy - 2 * sqx
    );
    const _z =
      Math.PI -
      Math.atan2(
        2 * value.x * value.y + 2 * value.z * value.w,
        1 - 2 * sqy - 2 * sqw
      );
    return new Vector(
      this._normalizeRad(_x),
      this._normalizeRad(_y),
      this._normalizeRad(_z)
    );
  }
  private _normalizeRad(rad: number): number {
    let angle = rad * radToDeg;
    while (angle > 360) angle -= 360;
    while (angle < 0) angle += 360;
    return angle;
  }

  private _doublesEqual(d1: number, d2: number): boolean {
    const preciseness = 1e-13;
    return Math.abs(d1 - d2) < preciseness;
  }
}
