import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DEFAULT_USER_PREFERENCES, UserApartmentPreferencesInterface } from '@simOn/user/preferences/model';

export const selectUserPreferencesState = createFeatureSelector<UserApartmentPreferencesInterface>('userPreferences');

export const SelectSelectedApartmentUserPreferences = createSelector(
  selectUserPreferencesState,
  (allUserPreferences: UserApartmentPreferencesInterface) => {
    return allUserPreferences;
  }
);

export const SelectTemperatureUnit = createSelector(
  selectUserPreferencesState,
  (allUserPreferences: UserApartmentPreferencesInterface) => {
    return allUserPreferences.preferences?.units?.temperature || DEFAULT_USER_PREFERENCES.units.temperature;
  }
);

export const SelectEnergyUnit = createSelector(
  selectUserPreferencesState,
  (allUserPreferences: UserApartmentPreferencesInterface) => {
    return allUserPreferences.preferences?.units?.energy || DEFAULT_USER_PREFERENCES.units.energy;
  }
);
