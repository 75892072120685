import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CommandValuesType, ExecuteSceneValueParameter } from '@simOn/device/models';
import { PropertyBase } from '../directives/property-base.directive';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `<div i18n="@@GENERAL_PLAY" (click)="clickCommand()" class="ripple">Play</div> `,
  styleUrls: ['./scene.component.scss']
})
export class SceneComponent extends PropertyBase<'Scene', number, ExecuteSceneValueParameter> {
  set propertyValue(params: number) {
    throw new Error('Method not implemented.');
  }
  get propertyValue(): number {
    return this.propertyDefinition.values.value;
  }
  valueToParameter(value: number): ExecuteSceneValueParameter {
    return { value };
  }

  clickCommand(): void {
    this.partialCommand$.emit(this.valueToCommand(this.propertyDefinition.values.value));
  }

  get sceneName() {
    return this.propertyDefinition.values.value;
  }

  valueToCommand(params: number): { command: keyof CommandValuesType; parameters: string | number } {
    return {
      command: 'RunScene',
      parameters: JSON.stringify(this.valueToParameter(params))
    };
  }
  commandToValue(params: { command: keyof CommandValuesType; parameters: string | number }): number {
    throw new Error('Method not implemented.');
  }
}
