import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LastVisitedApiService } from '@simOn/user/last-visited/data-access';
import { catchError, map, of, switchMap } from 'rxjs';
import * as UsersActions from './users.actions';

@Injectable()
export class UsersEffects {
  private actions$ = inject(Actions);
  private lastVisitedApiService = inject(LastVisitedApiService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsersActions.initUsers),
      switchMap(() => of(UsersActions.loadUsersSuccess({ users: [] }))),
      catchError((error) => {
        console.error('Error', error);
        return of(UsersActions.loadUsersFailure({ error }));
      })
    )
  );

  addUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.AddApartmentUserState),
      switchMap((action) =>
        this.lastVisitedApiService.getUsersInApartment$().pipe(
          map((apartmentUsers) => {
            const apartmentUser = {
              ...apartmentUsers.find((user) => user.id === action.apartmentUser.userId)!,
              isActive: false
            };
            return UsersActions.AddApartmentUserStateSuccess({ apartmentUser });
          }),
          catchError((e) => {
            return of(UsersActions.AddApartmentUserStateFailure(e));
          })
        )
      )
    );
  });
  setApartmentUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.SetSelectedApartmentUsers),
      switchMap((action) =>
        this.lastVisitedApiService.getUsersInApartment$().pipe(
          map((apartmentUsers) => {
            const selectedApartmentUsers = apartmentUsers.map((user) => {
              return {
                ...user,
                isActive: action.selectedApartmentUsers.userIds.find((userId) => userId === user.id) ? true : false
              };
            });
            return UsersActions.SetSelectedApartmentUsersSuccess({ selectedApartmentUsers });
          }),
          catchError((e) => {
            return of(UsersActions.SetSelectedApartmentUsersFailure(e));
          })
        )
      )
    );
  });
}
