import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { SimInputComponent } from './sim-input.component';
import { SimIconComponent } from '@simOn/ui/sim-icon';

@NgModule({
  declarations: [SimInputComponent],
  imports: [CommonModule, FormsModule, NgxTrimDirectiveModule, SimIconComponent],
  exports: [SimInputComponent]
})
export class SimInputModule {}
