import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PublicLinkData, PublicLinkStartingScreen } from '@simOn/public-link/models';
import { API_URL } from '@simOn/utils/tokens';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicLinkService {
  private readonly _http = inject(HttpClient);
  private readonly _apiUrl = inject<string>(API_URL);

  private router = inject(Router);
  private _publicLink$ = new BehaviorSubject<string | null>(null);

  public publicLink$ = this._publicLink$.asObservable();
  public publicLink = this._publicLink$.value;
  public publicLinkStartingScreen: PublicLinkStartingScreen = PublicLinkStartingScreen.widgets;
  public publicLinkInteriorConfig: string | undefined;
  public publicLinkHideMenu: boolean = false;
  public publicLinkHideExitLabel: boolean = false;

  publicLinkUrl = this._publicLink$.value;
  readonly isPublicLinkModeActive$: Observable<boolean> = this._publicLink$.pipe(
    map((publicLink: string | null) => !!publicLink)
  );
  public apartmentId: string | null = null;

  constructor() {
    if (!window.location.href.includes('public')) {
      const publicLink = sessionStorage.getItem('publicLinkParams');
      if (!publicLink) return;
      const params: PublicLinkData = JSON.parse(publicLink);
      if (params) {
        this.publicLinkHideMenu = params.plhidemenu;
        this.publicLinkHideExitLabel = params.plhideexitlabel;
        this.publicLink = params.publicLink;
        this.publicLinkStartingScreen = params.startingScreen;
        this.setGuid(params.publicLink);
        this.goTo(params);
      }
    }
  }

  get publicLinkFromSessionStorage(): PublicLinkData {
    const publicLinkParams = sessionStorage.getItem('publicLinkParams') || '';
    return JSON.parse(publicLinkParams);
  }

  goTo(params: PublicLinkData) {
    const { url } = params;
    const apartmentId = url.split('id=')[1];
    const _url = url.split('?id=')[0];
    let queryParams = {};
    url
      .split('?')[1]
      .split('&')
      .forEach((param) => {
        const key = param.split('=')[0];
        const value = param.split('=')[1];
        queryParams = { ...queryParams, [key]: value };
      });
    setTimeout(() => {
      this.router.navigate([`space/${apartmentId}/${_url}`], { queryParams: queryParams });
    }, 0);
  }

  setSelectedApartmentId(apartmentId: string, local: boolean = true): void {
    this.apartmentId = apartmentId;
    // if (local) return;
    // throw 'NOT IMPLEMENTED!!!';

    // this.applicationFacade.setSelectedApartmentId(apartmentId, false);
  }
  selectGuid$(): Observable<string | null> {
    return this._publicLink$.asObservable();
  }

  getGuid(): string | null {
    return this._publicLink$.value;
  }
  setGuid(publicLink: string | null) {
    this._publicLink$.next(publicLink);
  }

  removeGuid(): void {
    if (this._publicLink$.value !== null) {
      this._publicLink$.next(null);
    }
    this.publicLink = null;
    this.apartmentId = null;

    sessionStorage.removeItem('publicLinkParams');
  }

  isPublicLinkModeActive(): boolean {
    return !!this._publicLink$.value;
  }

  getStartingScreenPublicLink(): number {
    return this.publicLinkStartingScreen;
  }

  setPublicLinkToLocalStorage(params: PublicLinkData) {
    sessionStorage.setItem('publicLinkParams', JSON.stringify(params));
  }

  isGuidValid$(guid: string, apartmentId: string): Observable<boolean> {
    return this.isPublicLinkGuidValid$(guid, apartmentId);
  }

  private isPublicLinkGuidValid$(publicLinkGuid: string, apartmentId: string): Observable<boolean> {
    // using /Access/GetUserFullAccesses as public Link Validator
    if (!apartmentId) return of(false);
    return this._http
      .get<{ isOwner: boolean; roomAccesses: unknown[] }>(
        `${this._apiUrl}/Access/GetUserFullAccesses?apartmentGuid=${apartmentId}&publicLink=${publicLinkGuid}`
      )
      .pipe(
        map(
          (userFullAccess: { isOwner: boolean; roomAccesses: unknown[] }) =>
            !!userFullAccess && (userFullAccess.roomAccesses.length !== 0 || userFullAccess.isOwner)
        ),
        catchError((error) => {
          console.log(error);
          return of(false);
        })
      );
  }
}

export function b64_to_utf8(value: string): any {
  let object = {};
  try {
    object = JSON.parse(decodeURIComponent(escape(window.atob(value))));
  } catch {}
  return object;
}
