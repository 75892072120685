import { createAction, props } from '@ngrx/store';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import { DeviceDto, PropertyValueChange } from '@simOn/device/models';
import { DeviceProperties } from '@simOn/device/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const LoadDeviceProperties = createAction(
  '[DeviceProperty] Load DeviceProperties',
  props<{ apartmentProviders: PoveterLocal[] }>()
);

export const LoadDevicePropertiesSuccess = createAction(
  '[DeviceProperty] Load DeviceProperties Success',
  props<{ data: DeviceProperties[]; allLoaded: boolean }>()
);

export const LoadDevicePropertiesFailure = createAction(
  '[DeviceProperty] Load DeviceProperties Failure',
  props<{ error: any; masterDeviceId: string }>()
);

export const LoadDevicePropertiesFailureForChangeStatusProvider = createAction(
  '[DeviceProperty] Load DeviceProperties Failure Status Provider',
  props<{ masterDeviceId: string }>()
);

export const ExecuteCommand = createAction(
  '[DeviceProperty] Execute Command',
  props<{ data: PropertyValueChange<any> }>()
);

export const ExecuteCommandSuccess = createAction(
  '[DeviceProperty] Execute Command Success',
  props<{ data: PropertyValueChange<any> }>()
);

export const ExecuteCommandFailure = createAction('[DeviceProperty] Execute Command Failure', props<{ error: any }>());

export const CheckDevicesAlarmStatus = createAction(
  '[DeviceProperty] Check Devices Alarm Status',
  props<{ devices: DeviceDto[] }>()
);
export const ClearDevicePropertiesState = CLEAR_STATE_ACTION('DeviceProperties');

export type PoveterLocal = {
  masterDeviceId: string;
  smartApiProvider: SmartApiProviderEnum;
  providerConnectionStatus: ProviderConnectionStatusEnum;
};

export enum ProviderConnectionStatusEnum {
  Unknown = 'Unknown',
  LoggedIn = 'LoggedIn',
  ConnectionLost = 'ConnectionLost',
  LoggedOut = 'LoggedOut'
}
