import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { ModalRef } from '@simOn/ui/sim-modal';

@Component({
  templateUrl: './missing-room-privileges-modal.component.html',
  styleUrls: ['./missing-room-privileges-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SimButtonModule]
})
export class MissingRoomPrivilegesModalComponent {
  private readonly _modalRef = inject<ModalRef<void>>(ModalRef);

  exit() {
    this._modalRef.emit({
      state: true
    });
    this._modalRef.close();
  }
}
