import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sim-modal-template',
  standalone: true,
  template: `<ng-content></ng-content>`,
  styleUrls: ['./modal-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalTemplateComponent {}
