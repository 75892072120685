import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { OpenIdConfiguration, provideAuth } from 'angular-auth-oidc-client';
import { TokenInjectorInterceptor } from 'simon-application';

export function provideAuthConfig(config: OpenIdConfiguration): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideAuth({ config }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInjectorInterceptor,
      multi: true
    }
  ]);
}
