import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TransformationInterface } from '@simOn/common/matterport';
import { DeviceDto, DeviceStructure, UpdateLocalWidgetItem, Widget, WidgetExtended } from '@simOn/device/models';
import { Observable, defer, filter, first, forkJoin, map, switchMap } from 'rxjs';
import { DevicesFacade } from '../device/devices.facade';
import { CheckDevicesAlarmStatus } from '../property/device-property.actions';
import {
  ClearWidgetState,
  UpdateWidget,
  addTransformation,
  addTransformationFailure,
  addTransformationSuccess,
  deleteTransformation,
  loadWidgets,
  updateTransformation
} from './widget.actions';
import {
  selectAllIdInRoom,
  selectAllWidgets,
  selectWidgetByDeviceId,
  selectWidgetById,
  selectWidgetsByIds,
  selectWidgetsBySimlabDeviceId,
  widgetsIsLoading,
  widgetsLoaded
} from './widget.selectors';
@Injectable({
  providedIn: 'root'
})
export class WidgetFacade {
  private readonly store = inject(Store);
  private readonly actions = inject(Actions);
  private readonly deviceFacade = inject(DevicesFacade);
  readonly widgets$: Observable<Widget[]> = this.store.select(selectAllWidgets);
  readonly widgetsLoaded$: Observable<boolean | undefined> = this.store
    .select(widgetsLoaded)
    .pipe(filter((loaded) => loaded!));
  readonly widgetsIsLoading$: Observable<boolean> = this.store.select(widgetsIsLoading);
  readonly clearAction = this.actions.pipe(ofType(ClearWidgetState));
  readonly checkDevicesAlarmStatus$ = this.actions.pipe(ofType(CheckDevicesAlarmStatus));
  readonly transformationAction$ = this.actions.pipe(ofType(addTransformationSuccess, addTransformationFailure));
  readonly widgetsWithName$: Observable<WidgetExtended[]> = this.widgets$.pipe(
    switchMap((widgets: Widget[]) =>
      forkJoin(
        widgets.map((widget: Widget) =>
          this.deviceFacade.getDeviceById$(widget.deviceId).pipe(
            filter((device) => !!device),
            first()
          )
        )
      ).pipe(
        map((devices: DeviceDto[]) =>
          widgets.map(
            (widget: Widget, index: number) =>
              ({
                ...widget,
                name: devices[index].name,
                simlabDeviceType: devices[index].simlabDeviceType,
                visible: devices[index].visible
              } as WidgetExtended)
          )
        )
      )
    )
  );
  readonly widgetFor3DWalk$: Observable<WidgetExtended[]> = this.widgetsWithName$.pipe(
    map((widgets: WidgetExtended[]) => {
      return widgets.filter((widget) => widget.visible && widget.showIn3D);
    })
  );
  readonly widgetsForScene$: Observable<WidgetExtended[]> = this.widgetsWithName$.pipe(
    map((widgets: WidgetExtended[]) =>
      widgets.filter((widget) => {
        return widget.visible && widget.showInScenes;
      })
    )
  );
  readonly widgetDashboard$: Observable<WidgetExtended[]> = this.widgetsWithName$.pipe(
    map((widgets: WidgetExtended[]) =>
      widgets.filter((widget: WidgetExtended) => widget.visible && widget.showInDashboard)
    )
  );

  readonly widgetById$ = (widgetId: string): Observable<Widget> => this.store.select(selectWidgetById(widgetId));

  readonly widgetsByIds$ = (...widgetsId: string[]) => this.store.select(selectWidgetsByIds(...widgetsId));

  readonly getAllWidgetsIdInRoom$ = (roomId: string) => this.store.select(selectAllIdInRoom(roomId));

  readonly widgetsForRoom$ = (...widgetsId: string[]): Observable<WidgetExtended[]> =>
    this.widgetsLoaded$.pipe(
      switchMap(() =>
        this.widgetsByIds$(...widgetsId).pipe(
          map((widgets) => widgets.filter((widget: Widget | undefined) => !!widget)),
          switchMap((widgets: (Widget | undefined)[]) =>
            forkJoin(
              widgets.map((widget: Widget | undefined) => {
                return this.deviceFacade.getDeviceById$(widget!.deviceId).pipe(
                  filter((device) => !!device),
                  first()
                );
              })
            ).pipe(
              map((devices: DeviceDto[]) =>
                widgets.map(
                  (widget: Widget | undefined, index: number) =>
                    ({
                      ...widget,
                      name: devices[index].name,
                      simlabDeviceType: devices[index].simlabDeviceType,
                      visible: devices[index].visible
                    } as WidgetExtended)
                )
              )
            )
          ),
          switchMap((widgets: WidgetExtended[]) =>
            this.deviceFacade.selectAllMainDevices$.pipe(
              first(),
              map((devices: DeviceStructure[]) => {
                const mainDevicesIds = devices.map((device) => device.id);

                return widgets.filter((widget) => {
                  return (
                    mainDevicesIds.includes(widget.deviceId) ||
                    (widget.visible && (widget.showIn3D || widget.showInDashboard || widget.showInScenes))
                  );
                });
              })
            )
          )
        )
      )
    );
  // readonly widgetsForRooms$: Observable<WidgetExtended[]> = this.widgetsWithName$.pipe(
  //   switchMap((widgets: WidgetExtended[]) =>
  //     this.deviceFacade.selectAllMainDevices$.pipe(
  //       first(),
  //       map((devices: DeviceStructure[]) => {
  //         const mainDevicesIds = devices.map((device) => device.id);
  //         return widgets.filter(
  //           (widget) =>
  //             mainDevicesIds.includes(widget.deviceId) ||
  //             (widget.visible && (widget.showIn3D || widget.showInDashboard || widget.showInScenes))
  //         );
  //       })
  //     )
  //   )
  // );
  readonly widgetFor3DWalkContext$ = (roomId: string): Observable<WidgetExtended[]> =>
    this.widgetFor3DWalk$.pipe(
      map((widgets: WidgetExtended[]) => {
        return widgets.filter((widget) => widget.roomId === roomId);
      })
    );

  readonly widgetByIdWithName$ = (widgetId: string): Observable<WidgetExtended> =>
    defer(() =>
      this.widgetById$(widgetId).pipe(
        switchMap((widget: Widget | undefined) => {
          return this.deviceFacade.getDeviceById$(widget!.deviceId).pipe(
            filter((device) => !!device),
            first(),
            map(
              (device: DeviceDto) =>
                ({
                  ...widget,
                  name: device.name,
                  simlabDeviceType: device.simlabDeviceType,
                  visible: device.visible,
                  propertyType: device.propertyType,
                  status: device.status
                } as WidgetExtended)
            )
          );
        })
      )
    );

  readonly widgetByDeviceIdWithName$ = (deviceId: string) =>
    defer(() =>
      this.getWidgetByDeviceId(deviceId).pipe(
        switchMap((widget: Widget | undefined) => {
          return this.deviceFacade.getDeviceById$(widget!.deviceId).pipe(
            filter((device) => !!device),
            first(),
            map(
              (device: DeviceDto) =>
                ({
                  ...widget,
                  name: device.name,
                  simlabDeviceType: device.simlabDeviceType,
                  visible: device.visible,
                  propertyType: device.propertyType,
                  status: device.status
                } as WidgetExtended)
            )
          );
        })
      )
    );

  readonly widgetsBySimlabDeviceId$ = (simlabDeviceId: string) =>
    defer(() =>
      this.getWidgetsBySimlabDeviceId$(simlabDeviceId).pipe(
        switchMap((widgets: Widget[]) => {
          return forkJoin(
            widgets.map((widget) =>
              this.deviceFacade.getDeviceById$(widget.deviceId).pipe(
                first(),
                map((device: DeviceDto) => {
                  if (!device) {
                    return {} as WidgetExtended;
                  }
                  return {
                    ...widget,
                    name: device.name,
                    simlabDeviceType: device.simlabDeviceType,
                    visible: device.visible,
                    propertyType: device.propertyType,
                    status: device.status
                  } as WidgetExtended;
                })
              )
            )
          );
        }),
        map((widgets) => widgets.filter((widget) => Object.keys(widget).length))
      )
    );

  readonly widgetsWithNameInSelectedRoom$ = (roomId: string): Observable<WidgetExtended[]> =>
    this.widgetsWithName$.pipe(
      map((widgets: WidgetExtended[]) => widgets.filter((widget: WidgetExtended) => widget.roomId === roomId))
    );
  readonly getWidgetsBySimlabDeviceId$ = (simlabDeviceId: string): Observable<Widget[]> =>
    this.store.select(selectWidgetsBySimlabDeviceId(simlabDeviceId));

  readonly getWidgetByDeviceId = (deviceId: string): Observable<Widget | undefined> =>
    this.store.select(selectWidgetByDeviceId(deviceId));
  readonly getWidgetById = (widgetId: string): Observable<Widget | undefined> =>
    this.store.select(selectWidgetById(widgetId));
  loadWidgets(): void {
    this.store.dispatch(loadWidgets());
  }

  updateWidget(widgets: UpdateLocalWidgetItem[]) {
    this.store.dispatch(UpdateWidget({ widgets }));
  }

  updateTransformation(data: {
    widgetId: string;
    widgetTransformationId: string;
    transformation: TransformationInterface;
  }) {
    this.store.dispatch(updateTransformation({ data }));
  }

  addTransformation(data: { widgetId: string; transformation: TransformationInterface }) {
    this.store.dispatch(addTransformation({ data }));
  }

  deleteTransformation(data: { widgetId: string; transformationId: string; temporary?: boolean }) {
    this.store.dispatch(deleteTransformation({ data }));
  }
  clearState() {
    this.store.dispatch(ClearWidgetState());
  }
}
