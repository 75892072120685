import { inject, Injectable } from '@angular/core';
import {
  ComponentConfiguration,
  ComponentsType,
  MatterportComponent,
  MatterportService,
  SpriteConfiguration
} from '@simlab/matterport';
import { TComponentController } from '@simOn/matterport/viewer/models';
import { TicketFacade } from '@simOn/ticket';
import { TTicketState } from '@simOn/ticket/element/models';
import { catchError, concatAll, defer, filter, iif, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { Vector3 } from 'three';
import { ComponentControllerBase } from './component-controller-base';

@Injectable()
export class TicketComponentControllerService extends ComponentControllerBase implements TComponentController {
  private readonly _ticketFacade = inject(TicketFacade);
  private readonly _matterportService = inject(MatterportService);
  readonly _nodes: Record<string, MatterportComponent> = {};

  override get mode(): string {
    return 'tickets';
  }
  public set selectedMode(value: string) {
    value === this.mode ? this._showAll() : this._hideAll();
  }

  readonly components$: Observable<void> = defer(() => this.selectedModel).pipe(
    switchMap(() =>
      this._ticketFacade.allTickets$.pipe(
        tap((tickets: TTicketState[]) => {
          this._removeDeletedNotes(tickets.filter((tickets) => !tickets.positionDeleted).map((ticket) => ticket.id!));
        }),
        concatAll(),
        filter((ticket: TTicketState) => {
          return !!ticket.transformation?.position && ticket.positionDeleted === false;
        }),
        mergeMap((ticket: TTicketState) => {
          return iif(
            () => !!this._nodes[ticket.id!],
            of(true).pipe(
              tap(() => this._updateState(ticket)),
              take(1)
            ),
            of(true).pipe(
              switchMap(() =>
                this._create3DComponent$(ticket).pipe(
                  tap((component: any) => {
                    this._nodes[ticket.id!] = component;
                  }),
                  take(1)
                )
              ),
              take(1),
              catchError((e) => {
                console.log(e);
                return of(e);
              })
            )
          ).pipe(
            take(1),
            catchError((e) => {
              console.log(e);
              return of(e);
            })
          );
        })
      )
    )
  );

  private _removeDeletedNotes(tickets: string[]) {
    const currentAssets = Object.keys(this._nodes);
    if (currentAssets.length > 0) {
      const collection = [...currentAssets.filter((x) => !tickets.includes(x))];
      collection.forEach((id) => {
        if (this._nodes[id]) {
          this._matterportService.deleteNote(id);
          delete this._nodes[id];
        }
      });
    }
  }

  private _updateState(ticket: TTicketState): void {
    this._matterportService.updatePositionWithOffset({
      id: ticket.id!,
      normal: ticket.transformation!.normalVector as Vector3,
      position: ticket.transformation!.position as Vector3,
      stemHeight: ticket.transformation!.stemLength || 0,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3
    });
  }
  private _create3DComponent$(ticket: TTicketState) {
    const component: ComponentConfiguration<ComponentsType> = {
      id: ticket.id!,

      normal: ticket.transformation!.normalVector as Vector3,
      stemHeight: ticket.transformation!.stemLength || 0,
      position: ticket.transformation!.position as Vector3,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3,

      userData: {
        type: 'tickets',
        noteId: ticket.id
      },
      autoScale: false,
      objects: [
        new SpriteConfiguration({
          icon: `/${$localize.locale}/assets/chat-note.png`
        })
      ]
    };
    return this._matterportService.addComponentWithOffset$(component).pipe(
      catchError((e) => {
        console.log(e);
        return of(e);
      })
    );
  }
}
