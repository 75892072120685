import { inject, Pipe, PipeTransform } from '@angular/core';
import { MediaApiService } from '@simOn/common/media';
import { getUrlWithSmallSuffix, SupportedImageTypes } from '@simOn/common/upload-image';
import { firstValueFrom, of } from 'rxjs';

@Pipe({ name: 'image', standalone: true })
export class ImagePipe implements PipeTransform {
  readonly mediaApiService = inject(MediaApiService);
  transform(value: string) {
    if (value) {
      const isValueEndsWithFileExtension: boolean = value.endsWith(
        SupportedImageTypes.find((supportedFile) => value.includes(supportedFile.fileExtension))?.fileExtension || ''
      );

      return isValueEndsWithFileExtension
        ? firstValueFrom(this.mediaApiService.generateBlobUrlForDownload(getUrlWithSmallSuffix(value)))
        : of(value);
    }
    return value;
  }
}
