<sim-modal [darkMode]="true" (close)="closeAction()" class="alert-modal">
  <sim-modal-header class="alert-header">
    <div class="alert-header__content">
      <sim-icon
        class="alert-header__content--icon"
        icon="sub-error"
        [color]="['#fff', 'transparent']"
        sizeType="mega"
      ></sim-icon>
      <span i18n="@@MODALS_DEVICE_WARNING_TITLE" class="alert-header__content--title">Warning!</span>
    </div>
  </sim-modal-header>
  <sim-modal-template class="alert-template">
    <div class="alert-template__content">
      <device-widget
        widgetDetailsId="device-warning-widget-details-modal"
        class="alert-template__content--widget"
        #widget
        [id]="data.device.id"
        [deviceId]="data.device.simlabDeviceId"
        [name]="data.device.name!"
        [transformations]="(data.device.visible && data.device.showIn3D && data.device.transformations) || undefined"
        [file]="data.device.file"
      >
        <ng-container *ngIf="widget.visible | async">
          <ng-container
            simPropertyComponentLoader
            #simPropertyComponentLoader="simPropertyComponentLoader"
            [userPreference]="(userPreferences | async)!.preferences"
            [deviceId]="data.device.deviceId"
          >
          </ng-container>
        </ng-container>
      </device-widget>
      <ng-container *ngIf="roomName; else masterRoomTemplate">
        <button id="device-warning-modal-room" class="alert-template__content__button" (click)="buttonClick()">
          <div
            *ngIf="roomColor && roomColor !== '#FFFFFF'"
            class="alert-template__content__button--color-container"
            [ngStyle]="{ 'background-color': roomColor }"
          ></div>
          <span class="alert-template__content__button--name sim-text-ellipsis">{{ roomName }}</span>
        </button>
      </ng-container>
    </div>
  </sim-modal-template>
  <sim-modal-footer class="alert-footer">
    <div class="alert-footer__content">
      <sim-checkbox id="device-warning-modal-postpone-checkbox" [formControl]="alertDelayControl"
        ><label i18n="@@MODALS_DEVICE_WARNING_POSTPONE_INFORMATION"
          >Postpone this warning (for next 1h).</label
        ></sim-checkbox
      >
      <span>{{ localStorageWarningInfo?.alertDate || today | date : 'medium' }}</span>
    </div>
  </sim-modal-footer>
</sim-modal>

<ng-template #masterRoomTemplate>
  <button
    id="master-device-warning-modal-room"
    class="alert-template__content__button"
    (click)="masterRoomInformation()"
  >
    Master Room
  </button>
</ng-template>
