// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MpSdk, Scene } from 'mpSdk';
import { Observable } from 'rxjs';
import { Color, Quaternion, Sprite, Vector3 } from 'three';
import { BlueprintComponent } from '../base/blueprint.base';
import { CustomComponent } from '../base/custom-component-base';
import { MeshComponent } from '../base/mesh.base';
import { SpriteComponent } from '../base/sprite.base';
import { TextComponent } from '../base/text.base';
import { ThreeObjectBase } from '../base/three-object.base';

export interface ICustomComponent {
  set selectedNote(noteId: string | undefined);
  get selectedNote(): string | undefined;
  readonly selectedNote$: Observable<string | undefined>;
  readonly componentClicked$: Observable<ClickEventEmitter>;
  addComponent$: (
    component: ComponentConfiguration<ComponentsType>
  ) => Observable<MatterportComponent<ComponentsTypeClass> | undefined>;
  addComponentWithOffset$: (
    component: ComponentConfiguration<ComponentsType>
  ) => Observable<MatterportComponent<ComponentsTypeClass> | undefined>;
  updateNote$: (
    component: Pick<
      ComponentConfiguration<ComponentsType>,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ) => Observable<void>;
  updateNote: (
    component: Pick<
      ComponentConfiguration<ComponentsType>,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ) => void;
  updatePositionWithOffset: (
    component: Pick<
      ComponentConfiguration<ComponentsType>,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) => void;
  updatePositionWithOffset$: (
    component: Pick<
      ComponentConfiguration<ComponentsType>,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) => Observable<void>;
  clearAllNotes: () => void;
  deleteNote: (noteId: string) => void;
  deleteNote$: (noteId: string) => Observable<void>;
  clearAllNotes$: () => Observable<void[]>;
}

export type Inputs = Transformation & {
  id: string;
  visible?: boolean;
  renderOrder?: number;
  opacity?: number;

  scale: Vector3;

  isCollider?: boolean;
  autoScale?: boolean;
  userData?:
    | (Record<string, any> & { type?: UComponentType | string })
    | undefined
    | null;
  depthTest?: boolean;
  transparent?: boolean;
  lookAt?: boolean;
  dollhouseView?: boolean;
};
export const ComponentTypes = ['portal', 'blueprint'] as const;
export type UComponentType = typeof ComponentTypes[number];
export type Transformation = {
  position: Vector3;
  rotation?: Quaternion;

  normal: Vector3;
  stemHeight: number;
};

export interface ComponentTextDefinition {
  text: string;
  value?: any;
  weight?: number;
  propertyType?: string;
  backgroundColor?: Color;
}

export type NoteComponentInterface = {
  object: MpSdk.Scene.IObject;
  node: MpSdk.Scene.INode;
  comp: MpSdk.Scene.IComponent & CustomComponent;
};
export enum ViewMode {
  INSIDE = 'mode.inside',
  OUTSIDE = 'mode.outside',
  DOLLHOUSE = 'mode.dollhouse',
  FLOORPLAN = 'mode.floorplan',
  TRANSITIONING = 'mode.transitioning',
}

export class NoteComponent implements NoteComponentInterface {
  constructor(
    public readonly object: MpSdk.Scene.IObject,
    public readonly node: MpSdk.Scene.INode,
    public readonly comp: MpSdk.Scene.IComponent & CustomComponent
  ) {}
}
export type ComponentsType =
  | SpriteConfiguration
  | TextConfiguration
  | MeshConfiguration
  | PlaneConfiguration;

export type ComponentsTypeClass =
  | SpriteComponent
  | TextComponent
  | MeshComponent
  | BlueprintComponent;
export interface MatterportComponent<
  T extends ComponentsTypeClass = ComponentsTypeClass
> {
  comp: MpSdk.Scene.IComponent & CustomComponent;
  node: Scene.INode;
  children: (T & ThreeObjectBase)[];
}

export type ClickEventEmitter = {
  id: string;
  userData: any;
};

export type NoteComponentClickData = {
  collider: Sprite;
  input: ClickEvent;
  normal: null;
  point: Vector3;
  hover?: boolean;
  userData?: any;
};
export type ClickEvent = {
  button: number;
  eventType: string;
  position: Vector3;
};

// export enum ObjectType {
//   'Sprite' = 'Sprite',
//   'Text' = 'Text',
// }

export type ComponentConfiguration<T extends ComponentsType> = Inputs & {
  objects: T[];
};
export type ThreeObject<T = ThreeObjectBase> = T extends { icon: string }
  ? SpriteConfiguration
  : TextConfiguration;

function generateClassInstance<
  T extends ThreeObjectBase,
  W extends ComponentsType
>(type: IConstructor<T>, three: any, configuration: W): T {
  return new type(three, configuration.config);
}
interface IConstructor<T> {
  new (...args: any[]): T;
}
export type ObjectConfig = { config: Partial<Omit<Inputs, 'id'>> };

export class SpriteConfiguration implements ObjectConfig {
  private _instance!: SpriteComponent;
  constructor(
    readonly config: {
      icon: string;
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) {}
  init(threeContext: any) {
    this._instance = generateClassInstance<
      SpriteComponent,
      SpriteConfiguration
    >(SpriteComponent, threeContext, this);
  }
  get instance() {
    return this._instance;
  }
}

export class PlaneConfiguration implements ObjectConfig {
  private _instance!: BlueprintComponent;
  constructor(
    readonly config: {
      texture: string;
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) {}
  init(threeContext: any) {
    this._instance = generateClassInstance<
      BlueprintComponent,
      PlaneConfiguration
    >(BlueprintComponent, threeContext, this);
  }
  get instance() {
    return this._instance;
  }
}

export class MeshConfiguration implements ObjectConfig {
  private _instance!: MeshComponent;
  constructor(
    readonly config: {
      icon: {
        url: string;
        color: string;
      };
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) {}
  init(threeContext: any) {
    this._instance = generateClassInstance<MeshComponent, MeshConfiguration>(
      MeshComponent,
      threeContext,
      this
    );
  }
  get instance() {
    return this._instance;
  }
}
export class TextConfiguration implements ObjectConfig {
  private _instance!: TextComponent;
  constructor(
    readonly config: {
      text: string;
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) {}
  init(threeContext: any) {
    this._instance = generateClassInstance<TextComponent, TextConfiguration>(
      TextComponent,
      threeContext,
      this
    );
  }
  get instance() {
    return this._instance;
  }
}
