import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimModalModule } from '@simOn/ui/sim-modal';
import { Observable } from 'rxjs';

export type OpenNewModalResult = { cancel: boolean; windowRef: Window | null | undefined; actionRef?: Observable<any> };
export type OpenNewModalData = { url: string | undefined; action?: () => Observable<any> };

@Component({
  templateUrl: './open-new-modal.component.html',
  standalone: true,
  imports: [CommonModule, SimModalModule, SimButtonModule],
  styleUrls: ['./open-new-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenNewModalComponent {
  private readonly dialogRef = inject(DialogRef);
  public readonly data = inject<OpenNewModalData>(DIALOG_DATA);

  close() {
    const result: OpenNewModalResult = { cancel: true, windowRef: undefined };
    this.dialogRef.close(result);
  }
  open() {
    let windowRef: Window | null | undefined;
    let actionRef: Observable<any> | undefined;

    if (this.data.url) windowRef = window.open(this.data.url);
    if (this.data.action) actionRef = this.data.action();

    const result: OpenNewModalResult = { cancel: false, windowRef, actionRef };

    this.dialogRef.close(result);
  }
}
