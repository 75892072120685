import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AssetResponseInterface } from '@simOn/asset/element/models';
import { TAsset } from '@simOn/asset/modifying/models';
import { CLEAR_STATE_REDUCER, State } from '@simOn/utils';
import {
  AddAsset,
  AddAssetFailure,
  AddAssetSuccess,
  ClearAssetState,
  DeleteAsset,
  DeleteAssetFailure,
  DeleteAssetSuccess,
  DeleteHyperlinks,
  DeleteHyperlinksFailure,
  GetAsset,
  GetAssetFailure,
  GetAssets,
  GetAssetsFailure,
  GetAssetsSuccess,
  GetAssetSuccess,
  RenameAssetFile,
  RenameAssetFileFailure,
  RenameAssetFileSuccess,
  SetSelectedId,
  UpdateAssetAction,
  UpdateAssetFailure,
  UpdateAssetSuccess,
  UploadFileWithProgress,
  UploadFileWithProgressFailure
} from './asset.actions';

export const assetFeatureKey = 'assets';

export const adapter: EntityAdapter<TAsset> = createEntityAdapter<TAsset>();
export const initialState: State<TAsset> = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});

export const reducer = createReducer(
  initialState,

  on(GetAssets, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(SetSelectedId, (state, { selectedId }) => {
    return {
      ...state,
      selectedId
    };
  }),

  on(GetAssetsSuccess, (state, action) => {
    return adapter.setAll(action.state, { ...state, isLoaded: true, isLoading: false });
  }),

  on(GetAssetsFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  //#region AddAsset
  on(AddAsset, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(AddAssetSuccess, (state, action) => {
    return adapter.addOne(action.state, { ...state, isLoaded: true, isLoading: false });
  }),

  on(AddAssetFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  //#endregion AddAsset

  //#region UpdateAsset
  on(UpdateAssetAction, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(UpdateAssetSuccess, (state, action) => {
    return adapter.updateOne(
      { id: action.state.id, changes: action.state },
      { ...state, isLoaded: true, isLoading: false }
    );
  }),

  on(UpdateAssetFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  //#endregion UpdateAsset

  //#region DeleteAsset

  on(DeleteAsset, (state, action) => {
    return adapter.removeOne(action.state.id, { ...state, isLoaded: true, isLoading: true });
  }),
  on(DeleteAssetSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false
    };
  }),

  on(DeleteAssetFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  //#endregion DeleteAsset

  //#region DeleteHyperlinks
  on(DeleteHyperlinks, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  // on(DeleteHyperlinksSuccess, (state, action) => {
  //   return adapter.updateOne({ id: action.state.id, changes: action.state }, { ...state, isLoaded: true, isLoading: false });
  // }),

  on(DeleteHyperlinksFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  //#endregion DeleteAsset

  //#region GetAsset
  on(GetAsset, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(GetAssetSuccess, (state, action) => {
    return adapter.updateOne(
      { id: action.state.id, changes: action.state },
      { ...state, isLoaded: true, isLoading: false }
    );
  }),

  on(GetAssetFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  //#endregion GetAsset

  on(UploadFileWithProgress, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(RenameAssetFile, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),

  on(RenameAssetFileSuccess, (state, action) => {
    const updatedAsset: Update<AssetResponseInterface> = {
      changes: {
        ...state.entities[action.state.assetId],
        attachedImages: state.entities[action.state.assetId]!.attachedImages.map((image) =>
          image.id === action.state.fileId
            ? {
                ...image,
                name: action.state.fileName
              }
            : image
        ),
        attachedDocuments: state.entities[action.state.assetId]!.attachedDocuments.map((document) =>
          document.id === action.state.fileId
            ? {
                ...document,
                name: action.state.fileName
              }
            : document
        )
      },
      id: action.state.assetId
    };
    return adapter.updateOne(updatedAsset, { ...state, isLoaded: true, isLoading: false });
  }),

  on(RenameAssetFileFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),

  on(UploadFileWithProgressFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  CLEAR_STATE_REDUCER(ClearAssetState, initialState)
);
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
