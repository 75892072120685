import { inject, Injectable } from '@angular/core';
import {
  ComponentConfiguration,
  ComponentsType,
  MatterportComponent,
  MatterportService,
  MeshComponent,
  MeshConfiguration,
  TextComponent,
  TextConfiguration
} from '@simlab/matterport';
import { SHORT_UNITS, UNIT_TRANSFORM } from '@simOn/common/units';
import { DevicesFacade, WidgetFacade } from '@simOn/device';
import {
  DEVICE_ERROR_STATUSES,
  DEVICE_STATUS_INFORMATION,
  DEVICE_TYPE_ICON,
  DeviceDto,
  Properties,
  PropertyIconDefinition,
  SimlabPropertyInterface,
  TWidgetShortcut,
  UNIT_TO_USER_PREF,
  UserPrefEnergyConsumption,
  Widget,
  WidgetShortcut,
  WidgetTransformation
} from '@simOn/device/models';
import { TComponentController } from '@simOn/matterport/viewer/models';
import { animatedIconUrl } from '@simOn/ui/sim-animated-icons';
import { UserPreferencesFacade } from '@simOn/user';
import { UserApartmentPreferencesInterface } from '@simOn/user/preferences/model';
import {
  catchError,
  combineLatest,
  defer,
  filter,
  forkJoin,
  iif,
  map,
  mergeAll,
  mergeMap,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { Vector3 } from 'three';
import { ComponentControllerBase } from './component-controller-base';

@Injectable()
export class WidgetControllerService extends ComponentControllerBase implements TComponentController {
  private readonly _widgetFacade = inject(WidgetFacade);
  private readonly _devicesFacade = inject(DevicesFacade);
  private readonly _matterportService = inject(MatterportService);
  private readonly _userPreferences = inject(UserPreferencesFacade);
  override get mode(): string {
    return 'widgets';
  }
  public set selectedMode(value: string) {
    value === this.mode ? this._showAll() : this._hideAll();
  }
  readonly _nodes: Record<string, MatterportComponent> = {};
  readonly _widgetUpdate: Subject<void> = new Subject<void>();
  readonly widgetAndDevice$: Observable<TWidgetShortcut<WidgetShortcut>[]> = this._widgetFacade.widgetFor3DWalk$.pipe(
    tap((widgets: Widget[]) => {
      this._clearDeletedWidgets(widgets);
      this._widgetUpdate.next();
    }),
    mergeAll(),
    filter((widget: Widget) => !!widget.transformations && widget.transformations.length > 0),
    mergeMap((widget: Widget) => {
      return combineLatest({
        widget: of(widget),
        device: this._devicesFacade.getFirstDeviceWithProperties$(widget.deviceId)
      }).pipe(takeUntil(this._widgetUpdate));
    }),

    map((widgetAndDevice: TWidgetShortcut) => {
      return widgetAndDevice.widget.transformations
        .map(
          (transformation: WidgetTransformation, index: number) =>
            ({
              ...widgetAndDevice,
              widget: {
                ...widgetAndDevice.widget,
                transformationId: transformation.id,
                transformation: transformation.transformation,
                icon: widgetAndDevice.device.icon
              }
            } as TWidgetShortcut<WidgetShortcut>)
        )
        .filter((widgetShortcut: TWidgetShortcut<WidgetShortcut>) => widgetShortcut.widget.transformation);
    })
  );
  readonly components$: Observable<void> = defer(() => this.selectedModel).pipe(
    switchMap(() =>
      this._userPreferences.preferences$.pipe(
        switchMap((userPreferences: UserApartmentPreferencesInterface) =>
          this.widgetAndDevice$.pipe(
            mergeMap((widgetAndDevice: TWidgetShortcut<WidgetShortcut>[]) => {
              const shortcuts$ = widgetAndDevice.map((shortcut: TWidgetShortcut<WidgetShortcut>) => {
                const id = shortcut.widget.transformationId || '';
                return iif(
                  () => !!this._nodes[id],
                  of(true).pipe(
                    tap(() => this._updateState(shortcut, userPreferences)),
                    take(1)
                  ),
                  of(false).pipe(
                    switchMap(() =>
                      this._create3DComponent$(shortcut, userPreferences).pipe(
                        tap((component: any) => {
                          this._nodes[id] = component;
                        }),
                        take(1)
                      )
                    ),
                    take(1),
                    catchError((e) => {
                      console.log(e);
                      return of(e);
                    })
                  )
                ).pipe(
                  take(1),
                  catchError((e) => {
                    console.log(e);
                    return of(e);
                  })
                );
              });
              return forkJoin(shortcuts$);
            })
          )
        )
      )
    ),
    map(() => undefined)
  );

  private _create3DComponent$(
    shortcut: TWidgetShortcut<WidgetShortcut>,
    userPreferences: UserApartmentPreferencesInterface
  ) {
    const text = this._getText(shortcut.device, userPreferences);
    const widget = shortcut.widget;
    const device = shortcut.device;
    const transformation = widget.transformation || { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 } };
    const component: ComponentConfiguration<ComponentsType> = {
      id: shortcut.widget.transformationId || '',
      normal: transformation.normalVector as Vector3,
      stemHeight: 0.001,
      position: transformation.position as Vector3,

      autoScale: false,
      depthTest: true,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3,
      userData: {
        type: 'widgets',
        widgetId: widget.id,
        transformationId: widget.transformationId,
        deviceId: device.id
      },
      objects: [
        new MeshConfiguration({
          icon: this._getIcon(device),
          transparent: true
        }),
        new TextConfiguration({
          text,
          position: { x: this._getTextXPositionOffset(text), y: -0.2, z: 0.01 } as Vector3,
          scale: { x: this._getTextScale(text), y: 0.6, z: 1 } as Vector3,
          visible: text.length > 0,
          transparent: true
        })
      ]
    };
    return this._matterportService.addComponentWithOffset$(component).pipe(
      catchError((e) => {
        console.log(e);
        return of(e);
      })
    );
  }
  private _getIcon(device: DeviceDto): { url: string; color: string } {
    const deviceStatus = device.isBlockedBySubscriptionLimit ? 'Blocked' : device.status;
    const deviceIcon =
      deviceStatus !== 'OK' && deviceStatus !== 'Unknown' ? DEVICE_STATUS_INFORMATION[deviceStatus]?.icon : device.icon;
    const devicePropertyType =
      deviceStatus !== 'OK' ? DEVICE_STATUS_INFORMATION[deviceStatus].propertyType : device.propertyType;
    const iconSetting = DEVICE_TYPE_ICON[devicePropertyType] as PropertyIconDefinition<Properties>;
    const icon = iconSetting.valueToIcon(device.property!, deviceIcon!);
    const color = iconSetting.valueToBGColor(device.property!);
    return {
      color: `#${color.getHexString()}B2`,
      url: animatedIconUrl(icon)
    };
  }

  private _updateState(
    shortcut: TWidgetShortcut<WidgetShortcut>,
    userPreferences: UserApartmentPreferencesInterface
  ): void {
    const id = shortcut.widget.transformationId || '';
    const component = this._nodes[id];
    const widget = shortcut.widget;
    const transformation = widget.transformation || { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 } };
    this._matterportService.updatePositionWithOffset({
      id,
      normal: transformation.normalVector as Vector3,
      position: transformation.position as Vector3,
      stemHeight: 0.00001,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3
    });
    const icon = this._getIcon(shortcut.device);
    (component.children.find((comp) => 'icon' in comp) as MeshComponent).icon = icon;
    const text = this._getText(shortcut.device, userPreferences);
    const textVisible = text.length > 0;
    const textComponent = component.children.find((comp) => 'text' in comp) as TextComponent;
    if (textVisible) {
      textComponent.show();
      textComponent.text = text;
      const textPosition = this._getTextXPositionOffset(text);
      textComponent.position = { x: textPosition, y: -0.2, z: 0.01 } as Vector3;
      const textScale = this._getTextScale(text);
      textComponent.scale = { x: textScale, y: 0.6, z: 1 } as Vector3;
    } else {
      textComponent?.hide();
    }
  }

  private _clearDeletedWidgets(widgets: Widget[]) {
    const widgetsIds = widgets

      .map((widget) =>
        widget.transformations
          .filter((widgetTransformation: WidgetTransformation) => widgetTransformation.transformation)
          .map((transformation) => transformation.id)
      )
      .flat();
    Object.keys(this._nodes).forEach((nodeId: string) => {
      if (widgetsIds.indexOf(nodeId) === -1) {
        if (this._nodes[nodeId]) {
          this._matterportService.deleteNote(nodeId);
          delete this._nodes[nodeId];
        }
      }
    });
  }
  private _getText(device: DeviceDto, userPreferences: UserApartmentPreferencesInterface) {
    const errorStatus: boolean = (typeof DEVICE_ERROR_STATUSES).includes(device.status);
    let componentText = '';

    if (!errorStatus && !!device) {
      componentText = this._generateComponentText(device, userPreferences);
    }
    return componentText;
  }

  private _getTextXPositionOffset(text: string) {
    const textLength = text.length < 5 ? 5 : text.length;
    return textLength * 0.2 + (textLength - 6) * -0.1;
  }

  private _getTextScale(text: string) {
    const textLength = text.length < 5 ? 5 : text.length;
    return textLength * 0.2;
  }

  private _generateComponentText(device: DeviceDto, userPreferences: UserApartmentPreferencesInterface): string {
    const supportedProperties: Properties[] = ['LightIntensity', 'Temperature', 'TurnedOn', 'EarthTremor'];
    if (!supportedProperties.includes(device.propertyType)) return '';
    const preferences = userPreferences?.preferences;
    let text = '';
    switch (device.propertyType) {
      case 'TurnedOn': {
        if (!device.power || device.power?.value === 0) return '';
        return preferences.showPowerAsPrice
          ? UserPrefEnergyConsumption(device.power, preferences)
          : `${device.power.value}${SHORT_UNITS[device.power.unit]}`;
      }
      case 'Temperature': {
        const temperature = device.property as SimlabPropertyInterface<'Temperature'>;
        const srcUnit = temperature.unit;
        const dstUnit = preferences.units[UNIT_TO_USER_PREF[srcUnit]!];
        const fn: (value: number) => number = UNIT_TRANSFORM[srcUnit]?.[dstUnit] as (value: number) => number;
        text = `${fn ? fn(temperature.values.value) : temperature.values.value}${SHORT_UNITS[dstUnit]}`;
        return text;
      }
      case 'LightIntensity': {
        const light = device.property as SimlabPropertyInterface<'LightIntensity'>;
        return `${light.values.value}${SHORT_UNITS[light.unit]}`;
      }
      case 'EarthTremor': {
        const earthTremor = device.property as SimlabPropertyInterface<'EarthTremor'>;
        return `${earthTremor.values.value}${SHORT_UNITS[earthTremor.unit]}`;
      }
    }
    return '';
  }
}
