import { createAction, props } from '@ngrx/store';
import { UserApartmentPreferencesInterface } from '@simOn/user/preferences/model';

export const GetUserPreferences = createAction('[User Preferences] Get Preferences');

export const GetUserPreferencesSuccess = createAction(
  '[User Preferences][Success] Get Preferences',
  props<{ userPreferences: UserApartmentPreferencesInterface }>()
);
export const GetUserPreferencesFailure = createAction(
  '[User Preferences][Failure] Get Preferences',
  props<{ error: Error }>()
);

export const UpdateUserPreferences = createAction(
  '[User Preferences] Update Preferences',
  props<{ userPreferences: UserApartmentPreferencesInterface }>()
);
export const UpdateUserPreferencesSuccess = createAction(
  '[User Preferences][Success] Update Preferences',
  props<{ userPreferences: UserApartmentPreferencesInterface }>()
);
export const UpdateUserPreferencesFailure = createAction(
  '[User Preferences][Failure] Update Preferences',
  props<{ error: Error }>()
);
