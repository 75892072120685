import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concat, of } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { ApartmentsApiService } from '@simOn/space/information/data-access';
import { ApartmentInterface } from '@simOn/space/information/models';
import {
  DeleteApartment,
  DeleteApartmentFailure,
  DeleteApartmentSuccess,
  GetApartment,
  GetApartmentFailure,
  GetApartmentShort,
  GetApartmentShortFailure,
  GetApartmentShortSuccess,
  GetApartmentSuccess,
  GetApartments,
  GetApartmentsFailure,
  GetApartmentsSuccess,
  SetSelectedId,
  UpdateApartment,
  UpdateApartmentFailure,
  UpdateApartmentSuccess,
  UpdateModelSweeps,
  UpdateModelSweepsFailure,
  UpdateModelSweepsSuccess,
  UploadFileWithProgress,
  UploadFileWithProgressCompleted,
  UploadFileWithProgressFailure,
  UploadFileWithProgressPending,
  UploadFileWithProgressStarted,
  UploadFileWithProgressSuccess
} from './space.actions';
import { GetSelectedApartment } from './space.selectors';

import { UploadApiService } from '@simOn/common/upload-queue/data-access';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SpaceFacade } from './space.facade';

@Injectable()
export class SpaceEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly apartmentApi = inject(ApartmentsApiService);
  private readonly mediaService = inject(UploadApiService);
  private readonly spaceFacade = inject(SpaceFacade);
  private readonly loggedUser = inject(OidcSecurityService).getUserData();
  getApartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetApartment),
      mergeMap(() =>
        this.apartmentApi.getUserApartment$().pipe(
          map((response) => GetApartmentSuccess({ apartment: response })),
          catchError((error) => of(GetApartmentFailure({ error: error })))
        )
      )
    )
  );

  getApartments = createEffect(() =>
    this.actions$.pipe(
      ofType(GetApartments),
      mergeMap(() =>
        this.apartmentApi.getUserApartments$().pipe(
          mergeMap((apartments: ApartmentInterface[]) => [GetApartmentsSuccess({ apartments })]),
          catchError((error) => of(GetApartmentsFailure({ error: error })))
        )
      )
    )
  );

  setSelectedApartmentId = createEffect(() =>
    this.actions$.pipe(
      ofType(SetSelectedId),
      distinctUntilChanged((prev, act) => prev.selectedId === act.selectedId),
      tap((action) => {
        if (action.selectedId) {
          this.spaceFacade.getApartment();
        } else {
          this.spaceFacade.leaveApartment();
        }
      })
    )
  );

  deleteApartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeleteApartment),
      mergeMap((response) =>
        this.apartmentApi.deleteApartment$(response.apartmentId).pipe(
          map((response) => DeleteApartmentSuccess({ apartment: response })),
          catchError((error) => of(DeleteApartmentFailure({ error: error })))
        )
      )
    )
  );

  updateApartment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateApartment),
      mergeMap((response) =>
        this.apartmentApi.updateApartment$(response.apartment).pipe(
          mergeMap(() => concat([UpdateApartmentSuccess({ apartment: response.apartment }), GetApartmentShort()])),
          catchError((error) => of(UpdateApartmentFailure({ error: error })))
        )
      )
    )
  );

  getApartmentShort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GetApartmentShort),
      mergeMap(() =>
        this.apartmentApi.getUserApartmentShort$().pipe(
          map((response) => GetApartmentShortSuccess({ apartment: response })),
          catchError((error) => of(GetApartmentShortFailure({ error: error })))
        )
      )
    )
  );

  uploadFileWithProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadFileWithProgress),
      withLatestFrom(this.store.select(GetSelectedApartment)),
      concatMap(([props, apartment]) =>
        this.mediaService.uploadFileWithProgress().pipe(
          takeUntil(this.actions$.pipe(ofType(UploadFileWithProgressSuccess))),
          concatMap((event) => [this.getActionFromHttpEvent(event, apartment!, props.fileName)]),
          catchError((error) => {
            return of(UploadFileWithProgressFailure(error));
          })
        )
      )
    )
  );
  updateModelSweeps$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateModelSweeps),
      switchMap((action) =>
        this.apartmentApi.updateMatterportModelSweeps$(action.state).pipe(
          map(() => UpdateModelSweepsSuccess({ state: action.state })),
          catchError((error) => of(UpdateModelSweepsFailure({ error: error })))
        )
      )
    )
  );

  private getActionFromHttpEvent(event: HttpEvent<any>, apartment: ApartmentInterface, fileName: string) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return UploadFileWithProgressPending({
          state: { total: event.total || 0, loaded: event.loaded, fileName: fileName }
        });
      case HttpEventType.ResponseHeader: {
        return UploadFileWithProgressCompleted();
      }
      case HttpEventType.Response: {
        if (event.status === 200) {
          const fileGuid: string = event.body;
          const body: ApartmentInterface = {
            ...apartment,
            photoUrl: fileGuid
          };
          return UploadFileWithProgressSuccess({ state: body, fileName: fileName });
        } else {
          return UploadFileWithProgressFailure({ state: apartment.name || '' });
        }
      }
      case HttpEventType.Sent:
        return UploadFileWithProgressStarted();

      case HttpEventType.DownloadProgress:
        return UploadFileWithProgressPending({
          state: { total: event.total || 0, loaded: event.loaded, fileName: fileName }
        });
      default:
        return UploadFileWithProgressFailure({ state: apartment.name || '' });
    }
  }
}
