import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UsersLeft } from '@simOn/subscription/space-limit/models';
import { ApartmentLimits, SubscriptionLimits } from '@simOn/ui/sim-data-usage';
import { API_URL } from '@simOn/utils/tokens';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionApiService {
  private readonly _httpClient = inject(HttpClient);
  private readonly _apiUrl = inject(API_URL);

  getUserApartmentsLeft$(): Observable<number> {
    return this._httpClient.get<number>(`${this._apiUrl}/Paywall/UserApartmentsLeft`);
  }

  getDevicesLeft$(): Observable<number> {
    return this._httpClient.get<number>(`${this._apiUrl}/Paywall/DevicesLeft`);
  }

  getBlobBytesLeft$(): Observable<number> {
    return this._httpClient.get<number>(`${this._apiUrl}/Paywall/BlobBytesLeft`);
  }

  getUsersLeft$(): Observable<UsersLeft> {
    return this._httpClient.get<UsersLeft>(`${this._apiUrl}/Paywall/UsersLeft`);
  }

  getScenesLeft$(): Observable<number> {
    return this._httpClient.get<number>(`${this._apiUrl}/Paywall/ScenesLeft`);
  }

  getApartmentLimitsInfo$(): Observable<ApartmentLimits> {
    return this._httpClient.get<ApartmentLimits>(`${this._apiUrl}/Paywall/GetApartmentLimitsInfo`);
  }

  getSubscriptionLimitsInfo$(): Observable<SubscriptionLimits> {
    return this._httpClient.get<SubscriptionLimits>(`${this._apiUrl}/Paywall/GetSubscriptionLimitsInfo`);
  }
}
