import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[simRawColor]'
})
export class RawColorDirective {
  // NOTE: This property will override color defined by ColorDirective
  @HostBinding('style.--raw-color')
  @Input()
  simRawColor!: string;
}
