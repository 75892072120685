// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ModelSweep } from '@simlab/matterport/api';
import { Transform, Vector } from '@simlab/matterport/transform';
import { Vector3 } from 'three';

export interface IPortals {
  createPortal: (payload: PortalConfiguration) => Promise<void>;
}
export class CreatePortal {
  constructor(
    readonly id: string,
    readonly currentPosition: Vector,
    readonly currentMtpSweeps: ModelSweep[],
    readonly currentMtpOffset: Transform,
    readonly otherMtpOffset: Transform,
    readonly otherMtpSweeps: ModelSweep[]
  ) {}
}
export type PortalConfiguration = Pick<
  CreatePortal,
  'otherMtpOffset' | 'otherMtpSweeps' | 'id'
> &
  Partial<CreatePortal> & { calculatedPosition?: Vector3 };
