import {
  ApartmentRequestEvents,
  PrivilegesEventsCb,
  SignalRService,
  TApartmentUser,
  TApartmentUsers
} from '@simOn/utils/signalR';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  firstValueFrom,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';

export const getApartmentEvent = (
  prevApartmentId: string | undefined,
  currApartmentId: string | undefined
): { event: ApartmentRequestEvents; apartmentId: string } => {
  if (prevApartmentId === undefined && currApartmentId !== undefined)
    return { event: 'UserEntersApartment', apartmentId: currApartmentId };
  if (prevApartmentId !== undefined && currApartmentId === undefined)
    return { event: 'UserLeavesApartment', apartmentId: prevApartmentId };
  throw Error('Unknown event');
};
export async function apartmentEventListener(
  signalR: SignalRService,
  apartmentId$: Observable<string | undefined>,
  loggedUser: Observable<{ sub: string }>,
  payloadFn: Partial<PrivilegesEventsCb>
) {
  {
    const destroy = new Subject<void>();
    const loggedUserId = (await firstValueFrom(loggedUser)).sub;
    signalR
      .listenOnEvent<TApartmentUsers>('CurrentApartmentUsers')
      .pipe(takeUntil(destroy))
      .subscribe((apartmentUsers) => {
        apartmentUsers.userIds.push(loggedUserId);
        payloadFn.CurrentApartmentUsers!(apartmentUsers);
      });
    signalR
      .listenOnEvent<TApartmentUser>('UserEnteredApartment')
      .pipe(takeUntil(destroy))
      .subscribe((apartmentUser) => {
        payloadFn.UserEnteredApartment!(apartmentUser);
      });
    signalR
      .listenOnEvent<TApartmentUser>('UserLeftApartment')
      .pipe(takeUntil(destroy))
      .subscribe((apartmentUser) => {
        payloadFn.UserLeftApartment!(apartmentUser);
      });
    signalR
      .listenOnEvent<TApartmentUser>('UserGrantAccessToApartment')
      .pipe(takeUntil(destroy))
      .subscribe((apartmentUser) => {
        payloadFn.UserGrantAccessToApartment!(apartmentUser);
      });
    signalR
      .listenOnEvent<TApartmentUser>('UserLostAccessToApartment')
      .pipe(takeUntil(destroy))
      .subscribe((apartmentUser) => {
        payloadFn.UserLostAccessToApartment!(apartmentUser);
      });

    signalR
      .startConnection()
      .pipe(
        switchMap(() => {
          return apartmentId$.pipe(
            startWith(undefined),
            distinctUntilChanged(),
            pairwise(),
            takeUntil(destroy),
            tap(([prevApartmentId, currApartmentId]: [string | undefined, string | undefined]) => {
              const { apartmentId, event } = getApartmentEvent(prevApartmentId, currApartmentId);
              firstValueFrom(signalR.sendEvent(event, apartmentId));

              if (event === 'UserLeavesApartment') {
                destroy.next();
              }
            })
          );
        })
      )
      .subscribe();
  }
}
