import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimButtonModule } from '@simOn/ui/sim-button';
import { SimIconModule } from '@simOn/ui/sim-icon';
import { ModalFooterComponent } from './components/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from './components/modal-header/modal-header.component';
import { ModalTemplateComponent } from './components/modal-template/modal-template.component';
import { ModalService } from './services/modal.service';
import { SimModalComponent } from './sim-modal.component';
@NgModule({
  declarations: [ModalHeaderComponent, ModalFooterComponent, SimModalComponent],
  exports: [ModalTemplateComponent, ModalHeaderComponent, ModalFooterComponent, SimModalComponent],
  imports: [ModalTemplateComponent, CommonModule, SimIconModule, SimButtonModule],
  providers: [ModalService]
})
export class SimModalModule {}
