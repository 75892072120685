import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CommandValuesType, ExecuteSceneValueParameter, paramJSON } from '@simOn/device/models';
import { SimFormModule } from '@simOn/ui/sim-form-filed';
import { SimInputModule } from '@simOn/ui/sim-input';
import { PropertyBase } from '../directives/property-base.directive';
@Component({
  standalone: true,
  imports: [CommonModule, SimInputModule, SimFormModule],
  template: `<sim-form-field>
    <sim-input
      i18n-placeholder="@@DEVICE_PROPERTIES_SET_VALUE"
      id="set-value-input"
      #input
      type="text"
      placeholder="Set value..."
      (focusChange)="sendCommand(input.value)"
      [value]="propertyValue"
    ></sim-input>
  </sim-form-field> `,
  styleUrls: ['./set-value.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'sim-set-value'
  }
})
export class SetValueComponent extends PropertyBase<'String', string, ExecuteSceneValueParameter> {
  valueToCommand(value: string): {
    command: keyof CommandValuesType;
    parameters: string | number;
  } {
    return {
      command: 'SetString',
      parameters: JSON.stringify(this.valueToParameter(value))
    };
  }
  commandToValue(params: {
    command: keyof CommandValuesType;
    parameters: string | number | ExecuteSceneValueParameter;
  }): string {
    const paramToJSON: ExecuteSceneValueParameter = paramJSON<ExecuteSceneValueParameter>(params.parameters);
    return paramToJSON.value.toString();
  }

  set propertyValue(value: string) {
    if (!this.propertyDefinition?.values) {
      console.error('Set property definition input');
      return;
    }
    this.propertyDefinition = {
      ...this.propertyDefinition,
      values: {
        ...this.propertyDefinition.values,
        value
      }
    };
  }

  get propertyValue(): string {
    return this.propertyDefinition?.values?.value!;
  }

  get propertyType() {
    return this.propertyDefinition.type;
  }

  valueToParameter(value: string): ExecuteSceneValueParameter {
    return { value };
  }

  sendCommand(inputValue: string | number | undefined): void {
    const value = inputValue?.toString() || '';
    this.propertyValue = value;

    this.partialCommand$.emit(this.valueToCommand(value));
  }
}
