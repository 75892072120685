import { IMAGE_SIZE_URL_SUFFIX } from './image-size';
import { SupportedImageTypes } from './supported-extensions.const';

export function getUrlWithSmallSuffix(url: string): string {
  let localExtension: string | undefined;
  for (let extension of SupportedImageTypes) {
    if (url.includes(extension.fileExtension)) {
      localExtension = extension.fileExtension;
      break;
    }
  }

  if (localExtension) {
    return url.replace(localExtension, IMAGE_SIZE_URL_SUFFIX.Medium + localExtension);
  } else {
    return url + IMAGE_SIZE_URL_SUFFIX.Medium;
  }
}
