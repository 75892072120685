import { InjectionToken } from '@angular/core';

/**
 * @description
 * Provide max image size and max document size in bytes
 *
 */
export const AttachmentSizesToken = new InjectionToken<{
  imgMaxSize: number;
  docMaxSize: number;
}>('Attachment sizes configuration');
