import { Directive, HostBinding, Input } from '@angular/core';

export type UiElementDirection = 'begin' | 'end';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'i[direction], sim-button-loader[direction]'
})
export class DirectionDirective {
  private _direction: UiElementDirection | undefined = undefined;
  @Input() set direction(value: UiElementDirection) {
    this._direction = value;
  }

  @HostBinding('class.sim-begin-direction')
  get uiBeginDirection(): boolean {
    return this._direction === 'begin';
  }

  @HostBinding('class.sim-end-direction')
  get uiEndDirection(): boolean {
    return this._direction === 'end';
  }
}
