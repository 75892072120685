import { AfterViewInit, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatterportApiFacadeService } from '../services/matterport-api-facade.service';

@Directive()
export class MatterportApiCallback implements AfterViewInit {
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _matterportApi: MatterportApiFacadeService
  ) {}
  ngAfterViewInit(): void {
    if ('code' in this._activatedRoute.snapshot.queryParams) {
      const code = this._activatedRoute.snapshot.queryParams['code'];
      this._matterportApi.sendWindowMessageToParent(code);
    }
  }
}
