import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { DeviceStructure } from '@simOn/device/models';
import { CLEAR_STATE_REDUCER, State } from '@simOn/utils';
import {
  AddDevices,
  AddDevicesFailure,
  AddDevicesSuccess,
  ClearDeviceState,
  DeleteDeviceSuccess,
  GetDevice,
  GetDeviceFailure,
  GetDeviceSuccess,
  LoadDevices,
  LoadDevicesFailure,
  LoadDevicesSuccess,
  RemoveDevicesAfterDeleteProvider
} from './devices.actions';

export const deviceStructureFeatureKey = 'devices_structure';
export const adapter: EntityAdapter<DeviceStructure> = createEntityAdapter<DeviceStructure>({
  sortComparer: (a, b) => compareOrderIndex(a, b)
});
function compareOrderIndex(a: DeviceStructure, b: DeviceStructure): number {
  const compareRoom = a.roomId.localeCompare(b.roomId);
  const compare = a.orderIndex! - b.orderIndex!;
  const compareOrder = () => {
    if (compare > 0) {
      return 1;
    } else if (compare < 0) {
      return -1;
    } else return 0;
  };
  return compareRoom || compareOrder();
}
export const initialState: State<DeviceStructure> = adapter.getInitialState({
  preventRefresh: false,
  isLoaded: false,
  isLoading: false
});

export const reducer = createReducer(
  initialState,
  on(LoadDevices, (state) => ({ ...state, isLoading: true })),
  on(LoadDevicesFailure, (state, action) => ({ ...state, isLoading: false, isLoaded: false, error: action.error })),
  on(LoadDevicesSuccess, (state, action) => {
    return adapter.upsertMany(action.devices, {
      ...state,
      isLoaded: true,
      isLoading: false
    });
  }),
  on(GetDevice, (state) => ({ ...state, isLoading: true })),
  on(GetDeviceFailure, (state, action) => ({ ...state, isLoading: false, isLoaded: false, error: action.error })),
  on(GetDeviceSuccess, (state, action) => {
    return adapter.upsertMany(action.device, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null
    });
  }),

  on(AddDevices, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(AddDevicesSuccess, (state, action) => {
    return { ...state, isLoaded: true, isLoading: false };
  }),
  on(DeleteDeviceSuccess, (state, action) => {
    return adapter.removeOne(action.id, { ...state, isLoaded: true, isLoading: false });
  }),
  on(AddDevicesFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false
    };
  }),
  on(RemoveDevicesAfterDeleteProvider, (state, action) => {
    const devicesIdsToRemove = Object.keys(state.entities).filter(
      (key: string) => action.masterDeviceId === state.entities[key]!.masterDeviceId
    );
    return adapter.removeMany(devicesIdsToRemove, {
      ...state,
      isLoaded: true,
      isLoading: false
    });
  }),

  CLEAR_STATE_REDUCER(ClearDeviceState, initialState)
);
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
