import { NavBarDataInterface } from '@simOn/common/containers';
import { SmartApiProviderEnum } from '@simOn/common/providers';
import { SpaceFacade } from '@simOn/space';
import { map } from 'rxjs';

export const DASHBOARD_PAGE_DATA: NavBarDataInterface = {
  headerTitle: $localize`:@@DASHBOARD_MAIN_TITLE:Dashboard`,
  shortTitle: $localize`:@@DASHBOARD_MAIN_TITLE:Dashboard`,
  navLinks: [
    { label: $localize`:@@DASHBOARD_DEVICES:Devices`, name: `Devices`, path: 'devices', icon: 'device' },
    { label: $localize`:@@DASHBOARD_ASSETS:Assets`, name: `Assets`, path: 'assets', icon: 'documents' },
    { label: $localize`:@@DASHBOARD_SCENES:Scenes`, name: `Scenes`, path: 'scenes', icon: 'scenes' },
    {
      label: $localize`:@@DASHBOARD_RESOURCES:Resources`,
      name: `Resources`,
      path: 'resources/maximo-links',
      icon: 'list'
    }
  ],
  activeLinkIndex: 0
};

export function DASHBOARD_PAGE_DATA_WITH_PRIVILEGES(space: SpaceFacade) {
  const navBar = { ...DASHBOARD_PAGE_DATA };

  return space.apartmentProviders$.pipe(
    map((providers) => providers.some((provider) => provider.smartApiProvider === SmartApiProviderEnum.MAXIMO)),
    map((canActiveResources: boolean) => {
      return {
        ...navBar,
        navLinks: [...navBar.navLinks.filter((navLink) => navLink.name !== 'Resources' || canActiveResources)]
      };
    })
  );
}
