/* eslint-disable @angular-eslint/no-host-metadata-property */
import { AnimationEvent } from '@angular/animations';
import { FocusOrigin } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { Observable, Subject, distinctUntilChanged, filter, mapTo, take } from 'rxjs';
import { DrawerMode } from '../../models/drawer-mode';
import { DrawerPosition } from '../../models/drawer-position';
import { DrawerResult } from '../../models/drawer-result';
import { DRAWER_CONTAINER } from '../../tokens/drawer-container.token';
import { drawerAnimations } from '../animations/drwaer-animations';
import { DrawerContainerComponent } from '../drawer-container/drawer-container.component';

@Component({
  selector: 'sim-drawer',
  templateUrl: './drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [drawerAnimations.transformDrawer],
  host: {
    class: 'ui-drawer',
    tabIndex: '-1',
    // must prevent the browser from aligning text based on value
    '[attr.align]': 'null',
    '[class.ui-drawer-top]': 'position === "top"',
    '[class.ui-drawer-bottom]': 'position === "bottom"',
    '[class.ui-drawer-right]': 'position === "right"',
    '[class.ui-drawer-over]': 'mode === "over"',
    '[class.ui-drawer-push]': 'mode === "push"',
    '[class.ui-drawer-side]': 'mode === "side"',
    '[class.ui-drawer-opened]': 'opened',
    '[@transform]': '_animationState',
    '(@transform.start)': '_animationStarted.next($event)',
    '(@transform.done)': '_animationEnd.next($event)'
  }
})
export class DrawerComponent implements AfterViewInit, OnDestroy {
  private readonly _elementRef = inject<ElementRef<HTMLElement>>(ElementRef<HTMLElement>);
  public container = inject<DrawerContainerComponent>(DRAWER_CONTAINER, { optional: true });
  private document = inject<Document>(DOCUMENT, { optional: true });
  /** Event emitted when the drawer's position changes. */
  @Output() readonly positionChanged = new EventEmitter<void>();

  /**
   * An observable that emits when the drawer mode changes. This is used by the drawer container to
   * to know when to when the mode changes so it can adapt the margins on the content.
   */
  readonly _modeChanged = new Subject<void>();

  /** The side that the drawer is attached to. */
  @Input()
  get position(): DrawerPosition {
    return this._position;
  }
  set position(value: DrawerPosition) {
    if (value !== this._position) {
      //NOTE: Static inputs in Ivy (angular new renderer) are set before the element is in the DOM.
      if (this._isAttached) {
        //TODO: finish here!
      }
    }

    this._position = value;
    this.positionChanged.emit();
  }
  private _position: DrawerPosition = 'left';

  /**
   * Whether the drawer is opened. We overload this because we trigger an event when it
   * starts or end.
   */
  @Input()
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: BooleanInput) {
    this.toggle(coerceBooleanProperty(value));
  }
  private _opened = false;
  // private _opened = true;

  /** Emits whenever the drawer has started animating. */
  readonly _animationStarted = new Subject<AnimationEvent>();

  /** Emits whenever the drawer is done animating. */
  readonly _animationEnd = new Subject<AnimationEvent>();

  /** Current state of the sidenav animation. */
  _animationState: 'open-instant' | 'open' | 'void' = 'void';

  @Output()
  readonly openedStart: Observable<void> = this._animationStarted.pipe(
    filter((e) => e.fromState !== e.toState && e.toState.indexOf('open') === 0),
    mapTo(undefined)
  );

  @Output()
  readonly closedStart: Observable<void> = this._animationStarted.pipe(
    filter((e) => e.fromState !== e.toState && e.toState === 'void'),

    mapTo(undefined)
  );

  /** How the sidenav was opened (keypress, mouse click etc.) */
  private _openedVia!: FocusOrigin | null;

  /** Mode of the drawer; one of 'over', 'push' or 'side'. */
  @Input()
  get mode(): DrawerMode {
    return this._mode;
  }
  set mode(value: DrawerMode) {
    this._mode = value;
    //  this._updateFocusTrapState();
    this._modeChanged.next();
  }
  private _mode: DrawerMode = 'side';

  get width(): number {
    return this._elementRef.nativeElement ? this._elementRef.nativeElement.offsetWidth || 0 : 0;
  }

  /** Event emitted when the drawer open state is changed. */
  @Output() readonly openedChange: EventEmitter<boolean> =
    // Note this has to be async in order to avoid some issues with two-bindings (see #8872).
    new EventEmitter<boolean>(/* isAsync */ true);

  /** Whether the view of the component has been attached. */
  private _isAttached!: boolean;

  constructor() {
    this._animationEnd
      .pipe(
        distinctUntilChanged((x, y) => {
          return x.fromState === y.fromState && x.toState === y.toState;
        })
      )
      .subscribe((event: AnimationEvent) => {
        const { fromState, toState } = event;

        if (
          (toState.indexOf('open') === 0 && fromState === 'void') ||
          (toState === 'void' && fromState.indexOf('open') === 0)
        ) {
          this.openedChange.emit(this._opened);
        }
      });
  }

  ngOnDestroy(): void {
    this._animationStarted.complete();
    this._animationEnd.complete();
    this._modeChanged.complete();
  }

  ngAfterViewInit(): void {
    this._isAttached = true;
  }

  toggle(open: boolean = !this.opened, openedVia?: FocusOrigin): Promise<DrawerResult> {
    // If the focus is currently inside the drawer content and we are closing the drawer,
    // restore the focus to the initially focused element (when the drawer opened).
    if (open && openedVia) {
      this._openedVia = openedVia;
    }

    const result = this._setOpen(
      open,
      /* restoreFocus */ !open && this._isFocusWithinDrawer(),
      this._openedVia || 'program'
    );

    if (!open) {
      this._openedVia = null;
    }
    return result;
  }

  /**
   * Toggles the opened state of the drawer.
   * @param isOpen Whether the drawer should open or close.
   * @param restoreFocus Whether focus should be restored on close.
   * @param focusOrigin Origin to use when restoring focus.
   */
  private _setOpen(
    isOpen: boolean,
    restoreFocus: boolean,
    focusOrigin: Exclude<FocusOrigin, null>
  ): Promise<DrawerResult> {
    this._opened = isOpen;

    if (isOpen) {
      this._animationState = 'open';
    } else {
      this._animationState = 'void';
      // if (restoreFocus) {
      //   this._restoreFocus(focusOrigin);
      // }
    }

    // this._updateFocusTrapState();

    //return Promise.resolve(isOpen ? 'open' : 'close');

    return new Promise<DrawerResult>((resolve) => {
      this.openedChange.pipe(take(1)).subscribe((opened: boolean) => resolve(opened ? 'open' : 'close'));
    });
  }

  /** Whether focus is currently within the drawer. */
  private _isFocusWithinDrawer(): boolean {
    const activeEl = this.document?.activeElement;
    return !!activeEl && this._elementRef.nativeElement.contains(activeEl);
  }
}
