import { Injectable, OnDestroy, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WidgetFacade } from '@simOn/device';
import { DeviceDto, WidgetExtended } from '@simOn/device/models';
import { RoomInterface } from '@simOn/room/models';
import { Subject, firstValueFrom, from, map, of, switchMap, tap } from 'rxjs';
import { ApplicationEventsDispatcher } from '../event-dispatcher/application-events-dispatcher.service';
import { SimDeviceWarningModalComponent } from './sim-device-warning-modal.component';
import {
  DeviceAlarms,
  SimDeviceWarningLocalStorage,
  SimDeviceWarningModalResult
} from './sim-device-warning-modal.model';

export const WARNING_ALERT_DELAY: number = 1;
export type WarningRoomDescription = { id: string; name: string | undefined; color: string | undefined };
const EMPTY_WARNING_ROOM: WarningRoomDescription = {
  id: 'warningRoom',
  name: undefined,
  color: undefined
};

@Injectable()
export class SimDeviceWarningModalService implements OnDestroy {
  private readonly dialog = inject(MatDialog);
  private readonly router = inject(Router);
  private readonly widgetFacade = inject(WidgetFacade);
  private readonly moveToTagService = inject(ApplicationEventsDispatcher);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private _isAlertOpen: boolean = false;
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  async filterDevicesAndOpenModal(devicesList: DeviceDto[], rooms: RoomInterface[]) {
    console.warn('CHECK THE _moveTo');
    const detectedAlarmsInDevices: DeviceDto[] = this._filterAlarmDevices(devicesList, true);
    this._deleteFromLocalStorage(devicesList);
    const detectedAlarmsFilterWithLocalStorage = detectedAlarmsInDevices.filter((device: DeviceDto) =>
      this._comparisonWithLocalStorage(device)
    );

    for (let alarm in detectedAlarmsFilterWithLocalStorage) {
      const detectedDevice = detectedAlarmsFilterWithLocalStorage[alarm];
      if (!this._isAlertOpen) {
        this._isAlertOpen = true;
        await firstValueFrom(
          this.widgetFacade.widgetsBySimlabDeviceId$(detectedDevice.simlabDeviceId).pipe(
            map((widgets: WidgetExtended[]) =>
              widgets.filter((widget) => widget.showIn3D || widget.showInDashboard || widget.showInScenes)
            ),
            switchMap((widgets: WidgetExtended[]) => {
              if (!widgets || widgets.length === 0) {
                return of(undefined);
              }
              const detectedWidget = widgets.find((widget) => widget.simlabDeviceId === detectedDevice.simlabDeviceId);
              if (!detectedWidget) {
                return this._openWarningModal(widgets[0], detectedDevice, EMPTY_WARNING_ROOM);
              }
              const { id, color, name } = rooms.find((room) => room.id === detectedDevice.roomId) || EMPTY_WARNING_ROOM;
              return this._openWarningModal(detectedWidget, detectedDevice, {
                id,
                color,
                name
              } as WarningRoomDescription);
            })
          )
        );
      }
    }
  }

  private _openWarningModal = (widget: WidgetExtended, detectedDevice: DeviceDto, room: WarningRoomDescription) =>
    from(this._openSelectRoomModal(widget, room)).pipe(
      switchMap((dialogRef) =>
        dialogRef.afterClosed().pipe(
          tap((modalResult: SimDeviceWarningModalResult | undefined) => {
            if (!modalResult) return;
            this._saveToLocalStorage(detectedDevice, modalResult.isAlertDelay || false);
            if (modalResult.action === 'buttonClick') {
              this._moveTo(widget, detectedDevice.apartmentId);
            }
            this._isAlertOpen = false;
          })
        )
      )
    );

  //TO DO CHECK AND THINK WHERE IT SHOULD BE SET
  private _moveTo(device: WidgetExtended, spaceId: string): void {
    if (!this.router.url.includes('/apartment')) {
      const markerPosition =
        device.transformations && device.transformations.length ? device.transformations[0].transformation : undefined;
      if (markerPosition) {
        this.router.navigate([`space/${spaceId}/apartment`], {
          state: { context: 'widgets', position: device.transformations[0].transformation }
        });
      } else {
        this.router.navigate([`space/${spaceId}/apartment`], {
          state: { context: 'widgets', alarmRoom: device.roomId }
        });
      }
    } else {
      this.moveToTagService.moveToDeviceTag$.next(device.id);
    }
  }

  private _filterAlarmDevices(devicesList: DeviceDto[], isDetected: boolean): DeviceDto[] {
    return devicesList.filter((device: DeviceDto) => {
      if (!device?.property?.type) return false;
      return (
        DeviceAlarms.includes(device.property.type) &&
        typeof device.property.values === 'object' &&
        'value' in device.property.values &&
        device.property.values['value'] === isDetected
      );
    });
  }

  private _saveToLocalStorage(device: DeviceDto, isDelay: boolean): void {
    let alertDate = new Date();
    if (isDelay) {
      alertDate.setHours(new Date().getHours() + WARNING_ALERT_DELAY);
    }
    localStorage.setItem(
      device.id,
      JSON.stringify({
        apartmentId: device.apartmentId,
        deviceId: device.id,
        alertDate: alertDate
      })
    );
  }

  private _comparisonWithLocalStorage(device: DeviceDto): boolean | DeviceDto {
    const localStorageDeviceAlarm: string | null = localStorage.getItem(device.id);
    if (localStorageDeviceAlarm) {
      const deviceAlarm: SimDeviceWarningLocalStorage = JSON.parse(localStorageDeviceAlarm);
      return (
        new Date(deviceAlarm.alertDate) <= new Date() &&
        deviceAlarm.apartmentId === device.apartmentId &&
        deviceAlarm.deviceId === device.id
      );
    } else {
      return device;
    }
  }

  private _deleteFromLocalStorage(devices: DeviceDto[]): void {
    const devicesToDelete: DeviceDto[] = this._filterAlarmDevices(devices, false);
    devicesToDelete.forEach((device: DeviceDto) => localStorage.removeItem(device.id));
  }

  private async _openSelectRoomModal(
    device: WidgetExtended,
    roomDescription: WarningRoomDescription
  ): Promise<MatDialogRef<SimDeviceWarningModalComponent, SimDeviceWarningModalResult>> {
    const SimDeviceWarningModalComponentRef = await import('./sim-device-warning-modal.component').then(
      (e) => e.SimDeviceWarningModalComponent
    );
    return this.dialog.open(SimDeviceWarningModalComponentRef, {
      panelClass: 'custom-modal-device-alert',
      maxHeight: 'min(700px, 100%)',
      width: 'min(600px, 100%)',
      disableClose: true,
      data: {
        device,
        roomDescription
      }
    });
  }
}
