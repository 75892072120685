<canvas
  id="color-picker-palette"
  #palette
  class="canvas"
  [width]="(isMobile$ | async) ? canvasMobileSize : canvasSize"
  [height]="canvasSize"
  (pointerdown)="onPointerDownPalette($event)"
  (pointermove)="onPointerMovePalette($event)"
  (pointerup)="onPointerUpPalette($event)"
  (pointerleave)="palettePointerDown = false"
>
</canvas>

<div #palettePointer class="slider-color__thumb"></div>

<div class="slider-color">
  <canvas #slider [height]="canvasSize" class="slider-color__slider" width="25"> </canvas>
  <input
    id="color-picker-slider"
    type="range"
    step="1"
    min="0"
    max="230"
    class="slider-color__slider-input"
    (input)="trackSlider($event)"
  />
  <div #sliderPointer class="slider-color__thumb"></div>
</div>
