import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SubscriptionInfoService } from '@simOn/subscription/space-limit/services';
import { catchError, map, of } from 'rxjs';

export const spaceLimitGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const subscriptionInfoService = inject(SubscriptionInfoService);
  return subscriptionInfoService.checkOrShowInfoUserApartmentsLeft$().pipe(
    map((canActivate: boolean) => {
      if (!canActivate) {
        router.navigate(['split-view/apartment-selection']);
      }
      return canActivate;
    }),
    catchError((error) => {
      console.error(error);
      router.navigate(['split-view/apartment-selection']);
      return of(false);
    })
  );
};
