import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { CLEAR_STATE_REDUCER, State } from '@simOn/utils';

import { Widget, WidgetTransformation } from '@simOn/device/models';
import {
  addTransformation,
  addTransformationFailure,
  addTransformationSuccess,
  ClearWidgetState,
  deleteTransformation,
  deleteTransformationFailure,
  deleteTransformationSuccess,
  DeleteWidgets,
  loadWidgets,
  loadWidgetsFailure,
  loadWidgetsSuccess,
  updateTransformation,
  updateTransformationFailure,
  updateTransformationSuccess
} from './widget.actions';
export const widgetFeatureKey = 'widget';

export const adapter: EntityAdapter<Widget> = createEntityAdapter<Widget>();
export const initialState: State<Widget> = adapter.getInitialState({
  isLoaded: false,
  isLoading: false
});
export const reducer = createReducer(
  initialState,
  on(loadWidgets, (state) => ({ ...state, isLoading: true })),
  on(loadWidgetsFailure, (state, action) => ({ ...state, isLoading: false, isLoaded: false, error: action.error })),
  on(loadWidgetsSuccess, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null
    });
  }),
  on(updateTransformation, (state) => ({ ...state, isLoading: true })),
  on(updateTransformationFailure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.error
  })),
  on(updateTransformationSuccess, (state, action) => {
    const updatedWidget = state.entities[action.data.widgetId];
    const update: Update<Widget> = {
      id: action.data.widgetId,
      changes: {
        transformations: [
          ...updatedWidget!.transformations.filter((transform) => transform.id !== action.data.widgetTransformationId),
          {
            ...updatedWidget!.transformations.find((transform) => transform.id === action.data.widgetTransformationId),
            transformation: action.data.transformation
          } as WidgetTransformation
        ].sort((a: WidgetTransformation, b: WidgetTransformation) => a.orderIndex - b.orderIndex)
      }
    };
    return adapter.updateOne(update, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null
    });
  }),
  on(addTransformation, (state) => ({ ...state, isLoading: true })),
  on(addTransformationFailure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.error
  })),
  on(addTransformationSuccess, (state, action) => {
    const updatedWidget = state.entities[action.data.widgetId];
    const update: Update<Widget> = {
      id: action.data.widgetId,
      changes: {
        transformations: [...updatedWidget!.transformations, action.data.transformation].sort(
          (a: WidgetTransformation, b: WidgetTransformation) => a.orderIndex - b.orderIndex
        )
      }
    };
    return adapter.updateOne(update, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null
    });
  }),
  on(deleteTransformation, (state) => ({ ...state, isLoading: true })),
  on(deleteTransformationFailure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    error: action.error
  })),
  on(deleteTransformationSuccess, (state, action) => {
    const updatedWidget = state.entities[action.data.widgetId];

    const update: Update<Widget> = {
      id: action.data.widgetId,
      changes: {
        transformations: updatedWidget!.transformations
          .filter((widgetTransformation: WidgetTransformation) => {
            if (action.data.temporary) return true;
            return widgetTransformation.id !== action.data.transformationId;
          })
          .map((widgetTransformation, index: number) => {
            return {
              ...widgetTransformation,
              orderIndex: index,
              transformation:
                action.data.temporary && widgetTransformation.id === action.data.transformationId
                  ? undefined
                  : {
                      ...widgetTransformation.transformation
                    }
            };
          }) as WidgetTransformation[]
      }
    };
    return adapter.updateOne(update, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null
    });
  }),
  on(DeleteWidgets, (state, action) => {
    const widgetToDelete = [
      ...Object.values(state.entities)
        .filter((widget: Widget | undefined) => widget!.simlabDeviceId === action.simlabDeviceId)
        .map((widget: Widget | undefined) => widget!.id)
    ];
    return adapter.removeMany(widgetToDelete, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null
    });
  }),

  CLEAR_STATE_REDUCER(ClearWidgetState, initialState)
);
export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
