import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { EnergyUnitEnum, TemperatureUnitEnum } from '@simOn/common/units';
import {
  DEFAULT_USER_PREFERENCES,
  UserApartmentPreferencesInterface,
  UserPreferencesInterface
} from '@simOn/user/preferences/model';
import { GetUserPreferences, UpdateUserPreferences, UpdateUserPreferencesSuccess } from './user-preferences.actions';
import {
  SelectEnergyUnit,
  SelectSelectedApartmentUserPreferences,
  SelectTemperatureUnit
} from './user-preferences.selectors';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesFacade {
  private readonly store = inject(Store);
  private _actions$ = inject(Actions);
  readonly successUserPreferencesUpdate = this._actions$.pipe(ofType(UpdateUserPreferencesSuccess));
  readonly preferences$: Observable<UserApartmentPreferencesInterface> = this.store
    .select(SelectSelectedApartmentUserPreferences)
    .pipe(
      map((preference: UserApartmentPreferencesInterface) => {
        if (!preference.preferences) {
          return {
            preferences: {
              ...DEFAULT_USER_PREFERENCES,
              languageMode: $localize.locale
            } as UserPreferencesInterface
          };
        }
        return preference;
      })
    );
  readonly temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(SelectTemperatureUnit);

  readonly energyUnit$: Observable<EnergyUnitEnum> = this.store.select(SelectEnergyUnit);

  updateUserPreferences(userPreferences: UserApartmentPreferencesInterface): void {
    this.store.dispatch(UpdateUserPreferences({ userPreferences }));
  }
  loadUserPreferences() {
    this.store.dispatch(GetUserPreferences());
  }
}
