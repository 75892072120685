import { DialogResponse, ModalService } from '@simOn/ui/sim-modal';
import { Observable, map, take } from 'rxjs';
import { MatterportTokenDescriptionModalComponent } from './matterport-token-description-modal.component';

export class TokenDescriptionModalOpener {
  static openMatterportTokenDescriptionModal(
    modalService: ModalService,
    actualHint?: string
  ): Observable<string | undefined> {
    const modalRef = modalService.createModal<MatterportTokenDescriptionModalComponent, string, string>(
      MatterportTokenDescriptionModalComponent,
      {
        hasBackdrop: true,
        backdropClass: 'ui-modal-backdrop',
        width: 'min(500px, 100%)',
        height: 'min(300px, 100%)'
      },
      actualHint || ''
    );
    return modalRef.events$.pipe(
      take(1),
      map((value: DialogResponse<string>) => {
        if (value.result) {
          return value.result;
        }
        return undefined;
      })
    );
  }
}
