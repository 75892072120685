import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
  viewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { SimIconModule } from '@simOn/ui/sim-icon';

let nextUniqueId = 0;

@Component({
  selector: 'sim-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDatepickerModule, MatNativeDateModule, SimIconModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatepickerComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    '[id]': 'id'
  }
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
  private readonly _dateAdapter = inject(DateAdapter);
  private readonly _cdr = inject(ChangeDetectorRef);
  readonly datePicker = viewChild(MatDatepicker);
  @HostBinding('class.disable') get isDisabled(): boolean {
    return this._disabled;
  }
  ngOnInit(): void {
    this._dateAdapter.setLocale(LOCALE_ID);
  }

  private _min?: number | Date | string;

  @Input() set min(value: number | Date | string | undefined) {
    this._min = value;
  }

  get min(): number | Date | string | undefined {
    return this._min;
  }

  private _max?: number | Date;

  @Input() set max(value: number | Date | undefined) {
    this._max = value;
  }

  get max(): number | Date | undefined {
    return this._max;
  }

  @Output() readonly valueChange: EventEmitter<string> = new EventEmitter<string>();
  /** Automatic uuid for control */
  protected _uid = `sim-datepicker-${nextUniqueId++}`;
  /** Information about focus */
  focused = false;
  /** Value section start*/
  @Input()
  get value(): string {
    return this._value;
  }

  set value(newValue: string) {
    this._value = newValue;
  }

  private _value!: string;
  /** value section end */

  /** Required section start*/

  get required(): boolean {
    return this._required;
  }
  @Input()
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  protected _required = false;
  /** Required section end*/

  /** Readonly section start */

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;
  /** Readonly section end */
  /** Disabled element section start */
  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this.focused) {
      this.focused = false;
    }
  }

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */

  get id(): string {
    return this._id;
  }
  @Input()
  set id(value: string) {
    this._id = value || this._uid;
  }
  protected _id: string = this._uid;

  protected _disabled = false;
  /** Disabled element section end */

  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }
  get placeholder() {
    return this._placeholder ?? '';
  }
  protected _placeholder?: string;
  @ViewChild('input', { static: false }) private _input!: ElementRef;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onChange: (value: any) => void = () => {};
  /**
   * Sets control value. Part of the ControlValueAccessor interface
   * required to integrate with Angular's core forms API.
   * @param value
   */
  writeValue(value: string): void {
    this._value = value;
    this._cdr.markForCheck();
  }

  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'. Depending on the status, it enables or disables the appropriate DOM element.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  /** Callback for the cases where the focused state of the input changes. */
  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
    }
  }
  emitChangeEvent(event: MatDatepickerInputEvent<any, any>) {
    this.value = event.value;
    this._onChange(this.value);
    this.valueChange.emit(this.value);
  }

  _handleKeydown($event: KeyboardEvent) {
    ($event.code === 'Space' || $event.code === 'Enter') && this.datePicker()?.open();
  }
}
