import { createAction, props } from '@ngrx/store';
import { SimlabErrorDto } from '@simOn/common/http';
import { SceneShortcutBodyInterface, TSceneState, TSimlabSceneInterfaceBase } from '@simOn/scene/element/models';
import { CLEAR_STATE_ACTION } from '@simOn/utils';

export const RunScene = createAction('[Scenes] Run Scene', props<{ sceneId: string }>());
export const RunSceneSuccess = createAction('[Scenes][Success] Run Scene');
export const RunSceneFailure = createAction('[Scenes][Failure] Run Scene', props<{ error: SimlabErrorDto }>());

export const GetScenes = createAction('[Scenes] Get Scenes');
export const GetScenesSuccess = createAction('[Scenes][Success] Get Scenes', props<{ scenes: TSceneState[] }>());
export const GetScenesFailure = createAction('[Scenes][Failure] Get Scenes', props<{ error: Error }>());

export const GetSimlabScenes = createAction('[Scenes] Get Simla Scenes');
export const GetSimlabScenesSuccess = createAction(
  '[Scenes][Success] Get Simla Scenes',
  props<{ scenes: TSceneState[] }>()
);
export const GetSimlabScenesFailure = createAction('[Scenes][Failure] Get Simla Scenes', props<{ error: Error }>());

export const DeleteScene = createAction('[Scenes] Delete Scenes', props<{ scene: string }>());
export const DeleteSceneSuccess = createAction('[Scenes][Success] Delete Scenes', props<{ sceneId: string }>());
export const DeleteSceneFailure = createAction('[Scenes][Failure] Delete Scenes', props<{ error: Error }>());

export const AddShortcutScene = createAction(
  '[Scenes] Add Short Scenes',
  props<{ shortcut: SceneShortcutBodyInterface }>()
);
export const AddShortcutSceneSuccess = createAction(
  '[Scenes][Success] Add Short Scenes',
  props<{ shortcut: SceneShortcutBodyInterface }>()
);
export const AddShortcutSceneFailure = createAction('[Scenes][Failure] Add Short Scenes', props<{ error: Error }>());

export const UpdateShortcutScene = createAction(
  '[Scenes] Update Short Scenes',
  props<{ shortcut: SceneShortcutBodyInterface }>()
);
export const UpdateShortcutSceneSuccess = createAction(
  '[Scenes][Success] Update Short Scenes',
  props<{ shortcut: SceneShortcutBodyInterface }>()
);
export const UpdateShortcutSceneFailure = createAction(
  '[Scenes][Failure] Update Short Scenes',
  props<{ error: Error }>()
);

export const DeleteShortcutScene = createAction(
  '[Scenes] Delete Short Scenes',
  props<{ shortcut: SceneShortcutBodyInterface }>()
);
export const DeleteShortcutSceneSuccess = createAction(
  '[Scenes][Success] Delete Short Scenes',
  props<{ sceneId: string }>()
);
export const DeleteShortcutSceneFailure = createAction(
  '[Scenes][Failure] Delete Short Scenes',
  props<{ error: Error }>()
);

export const UpsertScene = createAction('[Scenes] Upsert Scenes', props<{ scene: TSimlabSceneInterfaceBase }>());
export const UpsertSceneSuccess = createAction(
  '[Scenes] [Success] Upsert Scenes',
  props<{ scene: TSimlabSceneInterfaceBase }>()
);
export const UpsertSceneFailure = createAction('[Scenes] [Failure] Upsert Scenes', props<{ error: any }>());

export const ChangeSimlabSceneStatus = createAction(
  '[Scenes] Change SimlabScene Status',
  props<{ sceneId: string; isActive: boolean }>()
);
export const ChangeSimlabSceneStatusSuccess = createAction(
  '[Scenes] [Success] Change SimlabScene Status',
  props<{ sceneId: string; isActive: boolean }>()
);
export const ChangeSimlabSceneStatusFailure = createAction(
  '[Scenes] [Failure] Change SimlabScene Status',
  props<{ error: Error }>()
);

export const ClearSceneState = CLEAR_STATE_ACTION('Scene');
