import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
let uniqueIdCounter = 0;
@Component({
  selector: 'sim-switcher',
  templateUrl: './sim-switcher.component.html',
  styleUrls: ['./sim-switcher.component.scss'],
  host: {
    '[id]': 'id'
  }
})
export class SimSwitcherComponent implements OnInit, OnChanges {
  @Input() names: SwitcherNames = { left: $localize`:@@DEVICE_ON:ON`, right: $localize`:@@DEVICE_OFF:OFF` };
  @Input() switchState = false;
  @Input() id: string = `sim-switcher-${uniqueIdCounter++}`;

  @Output() action = new EventEmitter();

  value: boolean | undefined;

  ngOnInit() {
    this.value = this.switchState;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.value = this.switchState;
  }

  writeValue(value: boolean) {
    this.value = value;
    this.action.emit(value);
  }
}

export interface SwitcherNames {
  left: string;
  right: string;
}
