import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AssetPrivilegeTypeStringify,
  DevicePrivilegeTypeStringify,
  GeneralPrivilegeTypeStringify,
  PrivilegesStore,
  RoomPrivilegeTypeStringify,
  ScenePrivilegeTypeStringify,
  TicketPrivilegeTypeStringify
} from '@simOn/privileges/element/models';

export const privilegeState = createFeatureSelector<PrivilegesStore>('privileges');
export const getRoomsPrivileges = createSelector(privilegeState, (state) => state.room);
export const getDevicesPrivileges = createSelector(privilegeState, (state) => state.device);

export const getRoomAccess = (roomId: string) =>
  createSelector(getRoomsPrivileges, (state) => {
    return state?.access?.[roomId] || undefined;
  });
export const roomPrivileges = (roomId: string) =>
  createSelector(getRoomAccess(roomId), (state) => state?.privileges || []);
export const devicePrivileges = (deviceId: string) =>
  createSelector(privilegeState, (state) => state.device.access[deviceId]?.privileges || []);

export const assetPrivileges = (assetId: string) =>
  createSelector(privilegeState, (state) => state.asset.access[assetId]?.privileges || []);

export const ticketPrivileges = (ticketId: string) =>
  createSelector(privilegeState, (state) => state.ticket.access[ticketId]?.privileges || []);

export const scenePrivileges = (sceneId: string) =>
  createSelector(privilegeState, (state) => state.scene.access[sceneId]?.privileges || []);

export const isOwner = createSelector(privilegeState, (state) => state.isOwner);
export const generalPrivileges = createSelector(privilegeState, (state) => state.general);

export const hasGeneralAccessTo = (privilege: GeneralPrivilegeTypeStringify) =>
  createSelector(
    generalPrivileges,
    isOwner,
    (state: GeneralPrivilegeTypeStringify[], isOwner: boolean) => isOwner || state.includes(privilege)
  );

export const defaultRoomPrivileges = createSelector(privilegeState, (state) => state.room.access);

export const defaultTicketPrivileges = createSelector(privilegeState, (state) => state.ticket.default);

export const defaultScenePrivileges = createSelector(privilegeState, (state) => state.scene.default);
export const hasAssetAccessTo = (assetId: string, privilege: AssetPrivilegeTypeStringify) =>
  createSelector(assetPrivileges(assetId), isOwner, (state, isOwner: boolean) => isOwner || state.includes(privilege));

export const hasInRoomAccessTo = (roomId: string, privilege: RoomPrivilegeTypeStringify) =>
  createSelector(roomPrivileges(roomId), isOwner, (state, isOwner: boolean) => isOwner || state.includes(privilege));

export const hasTicketAccessTo = (ticketId: string, privilege: TicketPrivilegeTypeStringify) =>
  createSelector(
    ticketPrivileges(ticketId),
    isOwner,
    (state, isOwner: boolean) => isOwner || state.includes(privilege)
  );

export const hasSceneAccessTo = (sceneId: string, privilege: ScenePrivilegeTypeStringify) =>
  createSelector(scenePrivileges(sceneId), isOwner, (state, isOwner: boolean) => isOwner || state.includes(privilege));

export const hasDeviceAccessTo = (deviceId: string, privilege: DevicePrivilegeTypeStringify) =>
  createSelector(
    devicePrivileges(deviceId),
    isOwner,
    (state, isOwner: boolean) => isOwner || state.includes(privilege)
  );

export const privilegesLoaded = createSelector(
  privilegeState,
  (state: PrivilegesStore) => !!state.isLoaded && !state.isLoading
);
