/* eslint-disable @typescript-eslint/no-empty-function */
import { Units } from '@simlab/matterport/api';
import { Observable, Subject, first, takeUntil } from 'rxjs';
import {
  IAreaTool,
  MeasurementMode,
  TAreaMesh,
  TAreaMeshChange,
} from '../models/measurement-tool.interface';
import {
  FunctionResponse,
  IUnityInputs,
  IUnityResponse,
} from './matterport-access-base';

export class UnityAreaTool
  implements IUnityInputs<IAreaTool>, IUnityResponse<IAreaTool>
{
  readonly destroyer = new Subject<void>();
  readonly selectedArea$: Observable<string | undefined> =
    this.measurementTool.selectedArea$;
  readonly selectedAreaMode$: Observable<MeasurementMode> =
    this.measurementTool.selectedAreaMode$;
  readonly selectedAreaChange$: Observable<TAreaMeshChange | undefined> =
    this.measurementTool.selectedAreaChange$;
  constructor(readonly measurementTool: IAreaTool) {
    this.selectedArea$
      .pipe(takeUntil(this.destroyer))
      .subscribe((data) => this.onSelectedArea$({ status: 'Success', data }));
    this.selectedAreaMode$
      .pipe(takeUntil(this.destroyer))
      .subscribe((data) =>
        this.onSelectedAreaMode$({ status: 'Success', data })
      );
    this.selectedAreaChange$
      .pipe(takeUntil(this.destroyer))
      .subscribe((data) =>
        this.onSelectedAreaChange$({ status: 'Success', data })
      );
  }

  isTouchDevice = () => false;

  addAreas = (areas: TAreaMesh[]) => {
    try {
      const areaIds: string[] = [];
      areas.forEach((area) => areaIds.push(this.measurementTool.addArea(area)));
      this.onAddAreas({ status: 'Success', data: areaIds });
    } catch (error) {
      this.onAddAreas({ status: 'Failure', error });
    }
  };
  addArea = (area: TAreaMesh) => {
    try {
      const areaId = this.measurementTool.addArea(area);
      this.onAddArea({ status: 'Success', data: areaId });
    } catch (error) {
      this.onAddArea({ status: 'Failure', error });
    }
  };
  createArea = () => {
    try {
      const areaId = this.measurementTool.createArea();
      this.onCreateArea({ status: 'Success', data: areaId });
    } catch (error) {
      this.onCreateArea({ status: 'Failure', error });
    }
  };
  cancelAreaCreation = () => {
    try {
      this.measurementTool.cancelAreaCreation();
      this.onCancelAreaCreation({ status: 'Success' });
    } catch (error) {
      this.onCancelAreaCreation({ status: 'Failure', error });
    }
  };
  deleteArea = (areaId: string) => {
    try {
      this.measurementTool.deleteArea(areaId);
      this.onDeleteArea({ status: 'Success' });
    } catch (error) {
      this.onDeleteArea({ status: 'Failure', error });
    }
  };
  deleteSelectedArea = () => {
    try {
      this.measurementTool.deleteSelectedArea();
      this.onDeleteSelectedArea({ status: 'Success' });
    } catch (error) {
      this.onDeleteSelectedArea({ status: 'Failure', error });
    }
  };
  editSelectedArea = () => {
    try {
      this.measurementTool.editSelectedArea();
      this.onEditSelectedArea({ status: 'Success' });
    } catch (error) {
      this.onEditSelectedArea({ status: 'Failure', error });
    }
  };
  updateAreaColor = (id: string, color: string | undefined) => {
    try {
      this.measurementTool.updateAreaColor(id, color);
      this.onUpdateAreaColor({ status: 'Success' });
    } catch (error) {
      this.onUpdateAreaColor({ status: 'Failure', error });
    }
  };
  updateSelectedAreaColor = (color: string | undefined) => {
    try {
      this.measurementTool.updateSelectedAreaColor(color);
      this.onUpdateSelectedAreaColor({ status: 'Success' });
    } catch (error) {
      this.onUpdateSelectedAreaColor({ status: 'Failure', error });
    }
  };
  addListener = (
    listenerActionType: 'ADD_POINT' | 'REMOVE_POINT' | 'CREATION_POINT'
  ) => {
    try {
      this.measurementTool
        .addListener(listenerActionType)
        .pipe(first())
        .subscribe((e) => {
          this.onAddListener({ status: 'Success' });
          this.measurementTool.removeListener();
        });
    } catch (error) {
      this.onAddListener({ status: 'Failure', error });
    }
  };
  removeListener = () => {
    try {
      this.measurementTool.removeListener();
      this.onRemoveListener({ status: 'Success' });
    } catch (error) {
      this.onRemoveListener({ status: 'Failure', error });
    }
  };
  hideAreas = (omit?: string[] | undefined) => {
    try {
      this.measurementTool.hideAreas(omit);
      this.onHideAreas({ status: 'Success' });
    } catch (error) {
      this.onHideAreas({ status: 'Failure', error });
    }
  };
  showAreas = (omit?: string[] | undefined) => {
    try {
      this.measurementTool.showAreas(omit);
      this.onShowAreas({ status: 'Success' });
    } catch (error) {
      this.onShowAreas({ status: 'Failure', error });
    }
  };
  deleteAllAreas = () => {
    try {
      this.measurementTool.deleteAllAreas();
      this.onDeleteAllAreas({ status: 'Success' });
    } catch (error) {
      this.onDeleteAllAreas({ status: 'Failure', error });
    }
  };
  set surfaceSizeUnit(unit: Units) {
    this.measurementTool.surfaceSizeUnit = unit;
    this.onSurfaceSizeUnit({ status: 'Success' });
  }
  set selectedAreaMode(mode: MeasurementMode) {
    this.measurementTool.selectedAreaMode = mode;
    this.onSelectedAreaMode({ status: 'Success' });
  }
  get selectedAreaMode(): MeasurementMode {
    return this.measurementTool.selectedAreaMode;
  }
  set selectedArea(areaId: string | undefined) {
    this.measurementTool.selectedArea = areaId;
    this.onSelectedArea({ status: 'Success' });
  }
  get selectedArea(): string | undefined {
    return this.measurementTool.selectedArea;
  }
  undo = () => {
    this.measurementTool.undo();
    this.onUndo({ status: 'Success' });
  };
  redo = () => {
    this.measurementTool.redo();
    this.onRedo({ status: 'Success' });
  };
  finish = () => {
    try {
      this.measurementTool.finish();
      this.onFinish({ status: 'Success' });
    } catch (error) {
      this.onFinish({ status: 'Failure', error });
    }
  };
  get canRedo(): boolean {
    return this.measurementTool.canRedo;
  }
  get canUndo(): boolean {
    return this.measurementTool.canUndo;
  }
  set segmentsVisibility(visible: boolean) {
    this.measurementTool.segmentsVisibility = visible;
  }

  onSelectedArea$ = (args: FunctionResponse<string | undefined>) => {};
  onSelectedAreaMode$ = (args: FunctionResponse<MeasurementMode>) => {};
  onSelectedAreaChange$ = (
    args: FunctionResponse<TAreaMeshChange | undefined>
  ) => {};
  onAddArea = (args: FunctionResponse<string>) => {};
  onAddAreas = (args: FunctionResponse<string[]>) => {};

  onCreateArea = (args: FunctionResponse<string>) => {};
  onCancelAreaCreation = (args: FunctionResponse<void>) => {};
  onDeleteArea = (args: FunctionResponse<void>) => {};
  onDeleteSelectedArea = (args: FunctionResponse<void>) => {};
  onEditSelectedArea = (args: FunctionResponse<void>) => {};
  onUpdateAreaColor = (args: FunctionResponse<void>) => {};
  onUpdateSelectedAreaColor = (args: FunctionResponse<void>) => {};
  onAddListener = (
    args: FunctionResponse<Observable<string | undefined>>
  ) => {};
  onRemoveListener = (args: FunctionResponse<void>) => {};
  onHideAreas = (args: FunctionResponse<void>) => {};
  onShowAreas = (args: FunctionResponse<void>) => {};
  onDeleteAllAreas = (args: FunctionResponse<void>) => {};
  onSurfaceSizeUnit = (args: FunctionResponse<any>) => {};
  onSelectedAreaMode = (args: FunctionResponse<any>) => {};
  onSelectedArea = (args: FunctionResponse<any>) => {};
  onUndo = (args: FunctionResponse<void>) => {};
  onRedo = (args: FunctionResponse<void>) => {};
  onFinish = (args: FunctionResponse<void>) => {};
  onCanRedo = (args: FunctionResponse<any>) => {};
  onCanUndo = (args: FunctionResponse<any>) => {};
  onSegmentsVisibility = (args: FunctionResponse<any>) => {};
  onIsTouchDevice = (args: FunctionResponse<boolean>) => {};
}
