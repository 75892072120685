import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GraphQLModule } from './graphql.module';
import { AuthorizationService } from './services/authorization.service';
import { MatterportApiFacadeService } from './services/matterport-api-facade.service';
import { MatterportApiService } from './services/matterport-api.service';
@NgModule({
  declarations: [],
  providers: [
    AuthorizationService,
    MatterportApiFacadeService,
    MatterportApiService,
  ],
  imports: [CommonModule, GraphQLModule],
})
export class MatterportApiModule {}
