import { inject, Injectable } from '@angular/core';
import {
  ComponentConfiguration,
  ComponentsType,
  MatterportComponent,
  MatterportService,
  SpriteComponent,
  SpriteConfiguration
} from '@simlab/matterport';
import { AssetFacade } from '@simOn/asset';
import { AssetsCategory, AssetsCategoryInterface } from '@simOn/asset/element/models';
import { TAsset } from '@simOn/asset/modifying/models';
import { TComponentController } from '@simOn/matterport/viewer/models';
import { catchError, concatAll, defer, filter, iif, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { Vector3 } from 'three';
import { ComponentControllerBase } from './component-controller-base';

@Injectable()
export class AssetComponentsControllerService extends ComponentControllerBase implements TComponentController {
  private readonly _assetFacade = inject(AssetFacade);
  private readonly _matterportService = inject(MatterportService);
  readonly _mode = ['assets', 'events'];
  override get mode() {
    return this._mode;
  }
  public set selectedMode(value: string) {
    this.mode.includes(value) ? this._showAll() : this._hideAll();
  }

  readonly _nodes: Record<string, MatterportComponent> = {};
  readonly components$: Observable<void> = defer(() => this.selectedModel).pipe(
    switchMap(() =>
      this._assetFacade.selectAllAssets$.pipe(
        tap((assets: TAsset[]) => {
          this._removeDeletedAssets(assets.filter((asset) => !asset.positionDeleted).map((asset) => asset.id));
        }),
        concatAll(),
        filter((asset: TAsset) => {
          return !!asset && !!asset.transformation?.position && !asset.positionDeleted;
        }),

        mergeMap((asset: TAsset) => {
          return iif(
            () => !!this._nodes[asset.id],
            of(true).pipe(
              tap(() => this.updateState(asset)),
              take(1)
            ),
            of(true).pipe(
              switchMap(() =>
                this.create3DComponent$(asset).pipe(
                  tap((component: any) => {
                    this._nodes[asset.id] = component;
                  }),
                  take(1)
                )
              ),
              take(1),
              catchError((e) => {
                console.log(e);
                return of(e);
              })
            )
          ).pipe(
            take(1),
            catchError((e) => {
              console.log(e);
              return of(e);
            })
          );
        })
      )
    )
  );

  private _removeDeletedAssets(assets: string[]) {
    const currentAssets = Object.keys(this._nodes);
    if (currentAssets.length > 0) {
      const collection = [...currentAssets.filter((x) => !assets.includes(x))];
      collection.forEach((nodeId) => {
        if (this._nodes[nodeId]) {
          this._matterportService.deleteNote(nodeId);
          delete this._nodes[nodeId];
        }
      });
    }
  }

  removeDeletedAsset(id: string) {
    this._matterportService.deleteNote(id);

    if (this._nodes[id]) {
      delete this._nodes[id];
    }
  }

  create3DComponent$(asset: TAsset | TAsset) {
    const component: ComponentConfiguration<ComponentsType> = {
      id: asset.id,
      normal: asset.transformation!.normalVector as Vector3,
      stemHeight: asset.transformation!.stemLength!,
      position: asset.transformation!.position as Vector3,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3,

      userData: {
        type: 'assets',
        assetId: asset.id
      },
      autoScale: true,
      objects: [
        new SpriteConfiguration({
          icon: this._getIcon(asset as TAsset)
        })
      ]
    };
    return this._matterportService.addComponentWithOffset$(component).pipe(
      catchError((e) => {
        console.log(e);
        return of(e);
      })
    );
  }
  private _getIcon(asset: TAsset): string {
    const icon = AssetsCategory.find((assetCat: AssetsCategoryInterface) => assetCat.name === asset.category)?.icon;
    if (icon) {
      return `/${$localize.locale}/assets/images/CATEGORIES_device_assets/circle/${icon}`;
    } else {
      return `/${$localize.locale}/assets/images/ui/UNSUPPORTED.png`;
    }
  }
  updateState(asset: TAsset | TAsset) {
    const component = this._nodes[asset.id];

    this._matterportService.updatePositionWithOffset({
      id: asset.id,
      normal: asset.transformation!.normalVector as Vector3,
      position: asset.transformation!.position as Vector3,
      stemHeight: asset.transformation!.stemLength!,
      scale: { x: 0.14, y: 0.14, z: 0.14 } as Vector3
    });

    const icon = this._getIcon(asset as TAsset);
    (component.children.find((comp) => 'icon' in comp) as SpriteComponent).icon = icon;
  }
}
